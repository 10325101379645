import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import { fetchInvoiceAcountReportData } from "../../../../store/admin/reporting/invoiceAccountReport/action";
import InvoiceAccountReportFilter from "./InvoiceAccountReportFilter";
import InvoiceAccountReportList from "./InvoiceAccountReportList";


const InvoiceAccountReportScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(fetchInvoiceAcountReportData());
    })();
  }, []);



  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'relative' }}>
      <Loader />
      <header className="table-header">
        <h1>Invoice Account Report</h1>
        <InvoiceAccountReportFilter />
      </header>
      <InvoiceAccountReportList/>
    </div>
  );
};

export default InvoiceAccountReportScreen;
