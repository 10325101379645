
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';
import { IBondHolderFilter } from './../../../../components/admin/funder/bondholder/types.d';
import { Action } from "./actionType";


// States' definition
export interface IBondHolderState {
  isLoading: boolean;
  bondHolders: IBondHolder[];
  bondHolderFilter: IBondHolderFilter;
  totalCount: number
}
export interface State {
  bondHolder: IBondHolderState;
}

const bondHolder = (
  state: IBondHolderState = {
    isLoading: false,
    bondHolders: [],
    bondHolderFilter: {
      page: 1,
      pageSize: 50,
      sortField: 'date',
      sortOrder: 'desc',
    },
    totalCount: 0
  },
  action: Action
): IBondHolderState => {
  switch (action.type) {
    case 'FUNDER/SET_BONDHOLDER_FILTER':
      return { ...state, bondHolderFilter: action.bondHolderFilter }
    case 'FUNDER/SET_BONDHOLDER_LIST':
      const bondHolders = action.replace ? action.bondHolders : [...state.bondHolders, ...action.bondHolders]
      return { ...state, bondHolders, totalCount: action.totalCount }
    case "FUNDER/UPDATE_BONDHOLDER": {
      const bondHolders = [...state.bondHolders];
      const index = bondHolders.findIndex(
        (s) => s._id === action.bondHolder?._id
      );
      if (index > -1) {
        bondHolders[index] = action.bondHolder;
      } else {
        bondHolders.unshift(action.bondHolder);
      }
      return {
        ...state,
        bondHolders,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    case "FUNDER/DELETE_BONDHOLDER": {
      const stateObj = { ...state };
      const bondHolders: IBondHolder[] = stateObj.bondHolders.filter(
        (s) => s._id !== action.bondHolder._id
      );
      const filter = { ...stateObj.bondHolderFilter };
      const { page, pageSize } = filter;
      return {
        ...state,
        bondHolders,
        totalCount: stateObj.totalCount - 1,
        bondHolderFilter: {
          ...filter,
          page: Math.ceil((bondHolders.length / page!) * pageSize!),
        },
      };
    }
    default:
      return state;
  }
};

export default bondHolder;
