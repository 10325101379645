import { useEffect } from 'react'
import { useDispatch } from "react-redux";

import Loader from '../common/Loader';
import { getRTYields } from '../../../store/admin/rtYields/action';
import RTYieldsFilters from './rtYieldsFilters';
import RTYieldsList from './rtYieldsList';

const RTYieldsScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRTYields())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Daily Real Time Yields</h1>
        <RTYieldsFilters />
      </header>
      <RTYieldsList actions={['edit']} />
    </div>
  );
}
export default RTYieldsScreen;

