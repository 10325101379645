import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import React from 'react'
import { IGlossaryItem } from '../../../../store/content/actionTypes';

const GlossaryItem = (props: GlossaryItemProps) => {
  return (
    <div className="glossary-item">
      <div className="word-container">
        <div className="word">{props.glossaryItem.word}</div>
        <FontAwesomeIcon
          className="delete-btn"
          icon={faEdit}
          onClick={props.onEdit}
        />
      </div>
      <div className="description">{props.glossaryItem.description}</div>
      {/* <FontAwesomeIcon className="delete" icon={faTimes} /> */}
    </div>
  );
}

interface GlossaryItemProps {
  glossaryItem: IGlossaryItem
  onEdit: () => void
}

export default GlossaryItem
