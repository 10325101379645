import { TOtherConfigs } from "@solarforschools/sfs-core";
import { Action } from "./actionTypes";

export interface IOtherConfigState {
  isLoading: boolean;
  otherConfig: TOtherConfigs[];
}
const initialState = {
  isLoading: false,
  otherConfig: [],
};

const otherConfig = (
  state: IOtherConfigState = initialState,
  action: Action
) => {
  switch (action.type) {
    
    case "SET_OTHER_CONFIG":
      return { ...state, otherConfig: action.otherConfig };

    case "UPDATE_OTHER_CONFIG":
      const otherConfig: TOtherConfigs[] = [...state.otherConfig];
      const findOtherConfigIndex = otherConfig.findIndex(
        (otherConfig) => otherConfig._id === action.otherConfig._id
      );
      if (findOtherConfigIndex + 1) {
        otherConfig[findOtherConfigIndex] = action.otherConfig;
      }
      return { ...state, otherConfig };

    default:
      return state;
  }
};

export default otherConfig;
