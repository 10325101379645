import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

export const getTasksApi = (query: any): Promise<any> => {
    return axios.get(`${host}/task?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}

// export const createPeriod = (period: IBillingPeriod): Promise<any> => {
//     return axios.post(`${host}/billing/period`, period).then(res => res.data)
// }

// export const updatePeriod = (period: IBillingPeriod): Promise<any> => {
//     return axios.put(`${host}/billing/period/${period._id}`, period).then(res => res.data)
// }

// export const deletePeriod = (period: IBillingPeriod): Promise<any> => {
//     return axios.delete(`${host}/billing/period/${period._id}`).then(res => res.data)
// }
