import { GoTriangleDown, GoTriangleRight, GoTriangleUp } from "react-icons/go"
interface IProps {
    fontSize?: string;
}
export const DownTriangleIcon = (props: IProps) => {
    const { fontSize } = props;
    return <>
        <div className="card-content--icon">
            <GoTriangleDown style={{ fontSize }} className="down-icon" />
        </div>
    </>
}
export const UpTriangleIcon = (props: IProps) => {
    const { fontSize } = props;
    return <>
        <div className="card-content--icon">
            <GoTriangleUp style={{ fontSize }} className="up-icon" />
        </div>
    </>
}
export const RightTriangleIcon = (props: IProps) => {
    const { fontSize } = props;
    return <>
        <div className="card-content--icon">
            <GoTriangleRight style={{ fontSize }} className="right-icon" />
        </div></>
}

export const PipeDriveIcon = (props: { width: number, height: number }) => {
    const { width, height } = props
    return <>
        <div className="flex-row-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 254 254"
                fill="none"
            >
                <rect width="254" height="254" rx="127" fill="#017737" />
                <path
                    d="M196.68 105.85C196.68 140.21 174.51 163.71 142.81 163.71C127.74 163.71 115.32 157.5 110.67 149.74L110.89 157.72V206.93H78.53V79.69C78.53 77.92 78.09 77.47 76.09 77.47H65V50.43H92.05C104.46 50.43 107.57 61.07 108.45 65.73C113.33 57.53 126.18 48 144.36 48C175.62 47.99 196.68 71.26 196.68 105.85ZM163.65 106.07C163.65 87.67 151.68 75.04 136.6 75.04C124.19 75.04 110.22 83.24 110.22 106.3C110.22 121.37 118.64 137.11 136.16 137.11C149.02 137.1 163.65 127.79 163.65 106.07Z"
                    fill="white"
                />
            </svg>

        </div>
    </>
}