import React from 'react';
import { IPivotItemProps } from "@fluentui/react";

export const onRenderPivoteItemLink = (error?: boolean) => (
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
) => {
    if (!link || !defaultRenderer) {
        return null;
    }
    const tab = defaultRenderer({ ...link, itemIcon: undefined })
    if (!error) {
        return tab
    }
    return <div style={{ color: '#a4262c' }}>{tab}</div>
}