import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types";
import { IYield } from "@solarforschools/sfs-core/dist/solardb/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

export const getyieldsApi = (query: any): Promise<any> => {
  return axios.get(`${host}/yields?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}
export const createYieldsAPi = (data: IYield): Promise<any> => {
  return axios
    .post(`${host}/yields`, data)
    .then((res) => res.data);
};

export const updateYieldsApi = (data: IYield): Promise<any> => {
  return axios
    .put(`${host}/yields/${data._id}`, data)
    .then((res) => res.data);
};

export const deleteYieldsApi = (data: IYield): Promise<any> => {
  return axios
    .delete(`${host}/yields/${data._id}`)
    .then((res) => res.data);
};

export const deleteBulkYieldsApi = (data: IYield[]): Promise<any> => {
  return axios
    .put(`${host}/yields/bulk-delete`, data)
    .then((res) => res.data);
};

export const genYieldsAPi = (data: any): Promise<any> => {
  return axios
    .post(`${host}/yields/generate`, data)
    .then((res) => res.data);
};

export const addYieldsAPi = (data: any): Promise<any> => {
  return axios
    .post(`${host}/yields/add`, data)
    .then((res) => res.data);
};

export const exportYieldsApi = (query: any): Promise<any> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/yields/export?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}
