import { FontIcon, IColumn, IComboBoxOption, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IMeterReading } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { getSystemMetersApi } from "../../../store/client/system";
import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { meterReadingTypes, MeterTypes, ReadingTypesMap } from "./common";

export const getGeneralFields = (options: StringMap): IField[] => {
    options.meterReading.id = options?.meterReading.id?.toString() || null
    return [
        {
            label: "System",
            key: "id",
            type: "dropdown",
            extras: { required: true },
            options: options.systems as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
        },
        {
            label: "Name",
            key: "name",
            type: "string",
            extras: { disabled: true },
            placeholder: "system Name",
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "Meter Id",
            key: "mid",
            type: "dropdown",
            extras: { required: true },
            options: options.meters as IDropdownOption[],
            className: 'ms-Grid-col ms-lg4',
        },
        {
            label: "MSN",
            key: "MSN",
            type: "dropdown",
            extras: { required: true },
            options: options.msns as IDropdownOption[],
            className: 'ms-Grid-col ms-lg4',
        },
        {
            label: "Date",
            key: "date",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Meter Type",
            key: "type",
            type: "dropdown",
            extras: { required: true },
            options: MeterTypes as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3',
        },

        {
            label: "Reading",
            key: "r",
            type: "number",
            placeholder: "Reading",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Offset",
            key: "offset",
            type: "number",
            placeholder: "Offset",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            key: 'active',
            label: 'Active',
            type: 'checkbox',
            className: 'margin-top-lg ms-Grid-col ms-lg1',
        },
        {
            label: "Reading Type",
            key: "rType",
            type: "dropdown",
            extras: { required: true },
            options: meterReadingTypes as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
        },
    ];
}
export const validateMeterReading = async (params: IMeterReading) => {
    let errors: StringMap = {};
    const { name, id, date, MSN } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (!id) errors['id'] = requiredMessage;
    if (!date) errors['date'] = requiredMessage;
    if (id < 10000 && !MSN) errors['MSN'] = requiredMessage;
    return errors;
}
export const validateFillingReading = async (params: any) => {
    let errors: StringMap = {};
    const { id, mid } = params || {}
    if (!mid) errors['mid'] = requiredMessage;
    if (!id) errors['id'] = requiredMessage;
    return errors;
}

export const geFitReadingColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            key: 'id',
            name: 'Id',
            fieldName: 'id',
            minWidth: 60,
            maxWidth:100,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.id?.toString()} closeDelay={500}> {item.id}</TooltipHost>
        },
        {
            key: 'FiTGeneratorAccNR',
            name: 'Clinet_RG',
            fieldName: 'FiTGeneratorAccNR',
            minWidth: 100,
            maxWidth:150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.FiTGeneratorAccNR} closeDelay={500}> {item.FiTGeneratorAccNR}</TooltipHost>
        },
        {
            key: 'FiTAccreditation',
            name: 'FiT Accreditation',
            fieldName: 'FiTAccreditation',
            minWidth: 100,
            maxWidth:150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.FiTAccreditation} closeDelay={500}> {item.FiTAccreditation}</TooltipHost>
        },
        {
            name: 'Date',
            key: 'date',
            fieldName: 'date',
            minWidth: 80,
            maxWidth:150,
            ...isSortable,
            onRender: (item: IMeterReading) => <TooltipHost content={moment(item.date).format('YYYY-MM-DD')} closeDelay={500}> {moment(item.date).format('YYYY-MM-DD')}</TooltipHost>
        },
        {
            key: 'MSN',
            name: 'MSN',
            fieldName: 'MSN',
            minWidth: 100,
            maxWidth:150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.MSN} closeDelay={500}> {item.MSN}</TooltipHost>
        },
        {
            key: 'r',
            name: 'Reading',
            fieldName: 'r',
            minWidth: 100,
            maxWidth:150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.r?.toString()} closeDelay={500}> {item.r}</TooltipHost>
        }
    ];
}

export const initMeterReading = {
    name: "",
    MSN: "",
    r: 0,
    active: false,
    type: "G",
    rType: 4,
    mid: "",
    date: new Date(),
    id: null as unknown as number
}

export const getMetersOptions = async (filter: StringMap, onlyMid = false) => {
    const { meters: metersData } = await getSystemMetersApi(filter)
    const meters = metersData.map((m: any) => {
        const key = onlyMid ? m.mid : `${m.mid}--${m.type}--${m.MSN}`
        return {
            key,
            text: `${m.mid}-${m.deviceType}-${m.type}`
        }
    })
    const msns = metersData.map((m: any) => {
        const key = m.MSN
        return {
            key,
            text: key
        }
    })
    return { meters, msns }
}

export const initFilling = {
    id: null,
    mid: null,
    start: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    end: moment().subtract(1, 'days').format('YYYY-MM-DD')
}

export const getFillingFields = (options: StringMap): IField[] => {
    options.fillingOptions.id = options?.fillingOptions.id?.toString() || null
    return [
        {
            label: "Site Id",
            key: "id",
            type: "combobox",
            extras: { required: true },
            options: options.systems as IComboBoxOption[],
            className: 'ms-Grid-col ms-lg6',
        },
        {
            label: "Meter Id",
            key: "mid",
            type: "dropdown",
            extras: { required: true },
            options: options.meters as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
        },
        {
            label: "Start Date",
            key: "start",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "End Date",
            key: "end",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg6'
        }
    ];
}
