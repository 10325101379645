import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts/index";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { renderFeedInTarrif } from '../../utils/tileHelper';


export const FrontFooter = () => {
    const { feedInTarrif } = useContext(CardContext) as CardContextType;
    const [status, setStatus] = useState({ prevQuarter: "", isEqual: "" });
    const [renderData, setRenderData] = useState<any>({
        prevQuarter: "",
        currQuarter: "",
        prevQFitPayment: null,
        currQFitPayment: null
    })

    const getStatus = () => {
        if (feedInTarrif !== undefined) {
            const quarters = Object.keys(feedInTarrif.lastFourQuarters); // Always have 4 sorted array of quarter
            let prevQuarter = quarters[quarters.length - 2]; // Last Before elmenet is Previous Quarter
            let currQuarter = quarters[quarters.length - 1]; // Last element is Current Quarter

            let prevQFitPayment = renderFeedInTarrif(feedInTarrif.lastFourQuarters[prevQuarter].P)
            let currQFitPayment = renderFeedInTarrif(feedInTarrif.lastFourQuarters[currQuarter].P)

            setRenderData({ ...renderData, prevQuarter, currQuarter, prevQFitPayment, currQFitPayment })

            if (feedInTarrif.lastFourQuarters[prevQuarter].P > feedInTarrif.lastFourQuarters[currQuarter].P) {
                setStatus({ ...status, prevQuarter: "high", isEqual: "" });

            } else if (feedInTarrif.lastFourQuarters[prevQuarter].P < feedInTarrif.lastFourQuarters[currQuarter].P) {
                setStatus({ ...status, prevQuarter: "low", isEqual: "" });
            } else {
                setStatus({ ...status, isEqual: "yes", prevQuarter: "" });
            }
        }

    }


    useEffect(() => {
        getStatus()
    }, [feedInTarrif])



    const prevCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevQuarter === "high" ? "green" : "red"))
    const currCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevQuarter === "high" ? "red" : "green"))


    const prevMonthStyle = { color: "gray", fontSize: "0.85rem" }


    return <>
        <div className="flex-row-center" style={{ fontSize: "1rem", alignItems: "end", paddingTop: "1rem" }}>

            <div className="flex-column-center" >
                <div className="flex-row-center">
                    <div style={{ color: prevCountStyle }}>
                        {renderData?.prevQFitPayment}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevQuarter === "high"
                            ? <UpTriangleIcon />
                            : <DownTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{renderData?.prevQuarter}</div>
            </div>
            <div className="vs-style">
                vs
            </div>

            <div className="flex-column-center">
                <div className="flex-row-center" >
                    <div style={{ color: currCountStyle }}>
                        {renderData?.currQFitPayment}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevQuarter === "high"
                            ? <DownTriangleIcon />
                            : <UpTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{renderData?.currQuarter}</div>
            </div>
        </div>
    </>
}

export const BackFooter = () => {
    const { feedInTarrif } = useContext(CardContext) as CardContextType;
    Chart.register(...registerables);
    const [chartDetails, setChartDetails] = useState<any>({ labels: null, data: null })
    const getChartDetails = () => {
        const quarters = feedInTarrif && Object.keys(feedInTarrif?.lastFourQuarters); // Always have 4 sorted array of quarter

        let data = quarters?.map((quarter) => {
            const paymentData = feedInTarrif?.lastFourQuarters[quarter].P
            return paymentData
        })

        setChartDetails({ ...chartDetails, labels: quarters, data: data });
    }


    useEffect(() => {
        getChartDetails()
    }, [feedInTarrif])




    const datum = {
        labels: chartDetails.labels,
        datasets: [
            {
                data: chartDetails.data,
                borderColor: "#9552EA",
                fill: true,
                backgroundColor: "rgb(149, 82, 234,0.5)",
                pointRadius: 0,
                pointHitRadius: 0,
                borderWidth: 1,
                lineTension: 0,
            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Last 4 Quarters',
                color: "#243f61",
                align: 'end' as const,
                padding: {
                    bottom: 1,
                },
                font: {
                    weight: 'normal',
                    lineHeight: 1.2,
                },

            },
            tooltip: {
                // mode: "index" as const,
                intersect: false,
                // displayColors: false,
                // titleFont: {
                //     size: 10
                // },
                bodyFont: {
                    size: 10
                },
                padding: {
                    left: 3,
                    right: 3,
                    bottom: 5,
                    top: 5
                },

                position: "nearest" as const,
                callbacks: {
                    label: function (context: any) {
                        const datasetIndex = context.datasetIndex;
                        const dataset = context.chart.data.datasets[datasetIndex];
                        const xValue = context.chart.data.labels[context.dataIndex];
                        const yValue = renderFeedInTarrif(context.parsed.y);
                        return ` ${xValue}: ${yValue} `;
                    },
                    title: function () {
                        return null;
                    }
                },
            }
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        },

        maintainAspectRatio: false
    };


    return <>
        <div className="flex-row-center" style={{ height: "4rem", gap: "1.5rem", }}>
            <div style={{ height: "50px", width: "240px" }}>
                <Line data={datum} options={options as any} />
            </div>
        </div>

    </>

}

