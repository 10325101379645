import { ActionButton, FontIcon, IColumn, IDropdownOption, Link, TooltipHost } from "@fluentui/react";
import { numberToCurrencyFormat } from "../../../../utils/util";
import moment from "moment";
import { ITransaction } from "@solarforschools/sfs-core/dist/funder/transaction/types";
import { isSortable } from "../../common/utils";
import { IField } from "../../../dynamic-form/DynamicForm";
import { TransactionTabErrors } from "./types";
import { pyamentPreferencesTypes, TransactionStatus, TransactionTypes } from "../helper";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";
import bond from "@solarforschools/sfs-core/dist/funder/bond";
import { StringMap } from "../../common/types";
import { requiredMessage } from "../../common/common";
import { useDispatch } from "react-redux";
import { downloadTaxStatement } from "../../../../store/admin/funder/transaction/action";


export const getTransactionColumns = [
    {
        key: 'certNumber',
        name: 'Cert Number',
        fieldName: 'certNumber',
        minWidth: 70,
        maxWidth: 80,
        ariaLabel: "Cert Number",
        onRender: (item: ITransaction) => <TooltipHost content={item.certNumber?.toString() || ''} closeDelay={500}> <Link rel='noopener noreferrer' target='_blank' href={`/funder/bonds/${item.certNumber}`}>
            {item.certNumber}
        </Link></TooltipHost>,
        ...isSortable,
    },
    {
        key: 'period',
        name: 'Period',
        fieldName: 'period',
        minWidth: 60,
        maxWidth: 60,
        ariaLabel: "payment Period",
        ...isSortable,
    },
    {
        key: 'type',
        name: 'Type',
        fieldName: 'type',
        minWidth: 50,
        maxWidth: 50,
        ...isSortable,
    },
    {
        key: 'action',
        name: 'Payment By',
        fieldName: 'action',
        minWidth: 50,
        maxWidth: 50,
        ariaLabel: "payment By",
        ...isSortable,
    },
    {
        key: 'details.bondAmount',
        name: 'Order Value',
        fieldName: 'details.bondAmount',
        minWidth: 100,
        maxWidth: 100,
        ...isSortable,
        ariaLabel: "Order Value",
        onRender: (item: ITransaction) => numberToCurrencyFormat(item.details.bondAmount || 0)
    },
    {
        key: 'details.start',
        name: 'Interest Start Date',
        fieldName: 'details.start',
        minWidth: 100,
        maxWidth: 100,
        ...isSortable,
        ariaLabel: "Interest Start Date",
        onRender: (item: ITransaction) => item.details.start && moment(item.details.start).format('MMM DD, YYYY')
    },
    {
        key: 'details.end',
        name: 'Interest End Date',
        fieldName: 'details.end',
        minWidth: 100,
        maxWidth: 100,
        ...isSortable,
        ariaLabel: "Interest End Date",
        onRender: (item: ITransaction) => item.details.end && moment(item.details.end).format('MMM DD, YYYY')
    },
    {
        key: 'details.days',
        name: 'Days',
        fieldName: 'details.days',
        minWidth: 80,
        maxWidth: 80,
        ...isSortable,
        onRender: (item: ITransaction) => item.details.days || '--'
    },
    {
        key: 'details.amount',
        name: 'Interest',
        fieldName: 'details.amount',
        minWidth: 80,
        maxWidth: 80,
        ...isSortable,
        onRender: (item: ITransaction) => numberToCurrencyFormat(item.details.amount || 0)
    },
    {
        key: 'dueDate',
        name: 'Due Date',
        fieldName: 'dueDate',
        minWidth: 80,
        maxWidth: 80,
        ...isSortable,
        ariaLabel: "Due Date",
        onRender: (item: ITransaction) => item.dueDate && moment(item.dueDate).format('MMM DD, YYYY')
    },
    {
        key: 'bondHolder.userName',
        name: 'User Name',
        fieldName: 'bondHolder.userName',
        minWidth: 150,
        maxWidth: 200,
        ...isSortable,

        onRender: (item: ITransaction) => <TooltipHost content={item.bondHolder.userName || ''} closeDelay={500}> <Link rel='noopener noreferrer' target='_blank' href={`/funder/bond-holders/${item.bondHolder.userName}`}>
            {item.bondHolder.userName}
        </Link></TooltipHost>
    },
    {
        key: 'status',
        name: 'Status',
        fieldName: 'status',
        minWidth: 50,
        maxWidth: 50,
        ...isSortable,
        onRender: (item: ITransaction) => <TooltipHost content={item.status || ''} closeDelay={500}> {item.status}</TooltipHost>
    },
];

export const bondHolderStatus =
    [
        { key: "all", text: "All" },
        { key: "Open", text: "Open" },
        { key: "Closed", text: "Closed" },
        { key: "Deleted", text: "Deleted" },
    ]

export const getGeneralFields = ({ transaction, bonds }: { transaction: ITransaction, bonds: IBond[] }): IField[] => {
    return [
        transaction._id ? {
            key: 'certNumber',
            label: 'Certificate Number',
            type: 'string',
            placeholder: 'Enter Certificate Number',
            extras: { readOnly: true },
            className: 'ms-Grid-col ms-lg3',
        } : {
            key: 'certNumber',
            label: 'Certificate Number',
            type: 'dropdown',
            options: bonds.map(b => { return { key: b.certNumber.toString(), text: `${b.certNumber}-${b.userName}` } }),
            extras: { required: true, ...transaction?._id ? { disabled: true } : {} },
            className: 'ms-Grid-col ms-lg3',
        }, {
            key: 'type',
            label: 'Type',
            type: 'dropdown',
            options: TransactionTypes.filter(t => t.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3',
            extras: { required: true },
        }, {
            key: 'action',
            label: 'Action',
            type: 'dropdown',
            options: pyamentPreferencesTypes.filter(t => t.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3',
        },
        {
            key: 'period',
            label: 'Period',
            type: 'string',
            placeholder: 'Enter Period',
            extras: { readOnly: true },
            className: 'ms-Grid-col ms-lg3',
        }, {
            key: 'dueDate',
            label: 'Due Date',
            type: 'date',
            placeholder: 'Pick Due date',
            className: 'ms-Grid-col ms-lg2',
            extras: { isRequired: true }
        },
        {
            key: 'bondHolder.userName',
            label: 'Bond Holder',
            type: 'string',
            placeholder: 'Enter Bond Holder',
            extras: { readOnly: true },
            className: 'ms-Grid-col ms-lg3',
        }, {
            key: 'paymentReference',
            label: 'Payment Reference',
            type: 'string',
            placeholder: 'Payment Reference',
            extras: { readOnly: true },
            className: 'ms-Grid-col ms-lg3',
        }, {
            key: 'status',
            label: 'Status',
            type: 'dropdown',
            options: TransactionStatus.filter(t => t.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg2',
            extras: { required: true },
        },
        {
            label: "Is Test ",
            key: "isTestTransaction",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg',
        },
    ]
}
export const getTypeFields = ({ transaction }: any): IField[] => {
    return [{
        key: 'details.bondAmount',
        label: 'Bond Amount',
        type: 'number',
        placeholder: 'Bond Amount',
        extras: { readonly: true },
        className: 'ms-Grid-col ms-lg3',
    }, {
        key: 'details.rate',
        label: 'Interest Rate[%]',
        type: 'number',
        placeholder: 'Enter Interest Rate',
        extras: { readonly: true },
        className: 'ms-Grid-col ms-lg3',
    }, {
        key: 'details.amount',
        label: 'Interest Amount',
        type: 'number',
        className: 'ms-Grid-col ms-lg3'
    },
    {
        key: 'details.days',
        label: 'Days',
        type: 'number',
        step: "1",
        className: 'ms-Grid-col ms-lg3'
    },
    {
        key: 'details.start',
        label: 'Interest Start Date',
        type: 'date',
        placeholder: 'Pick date',
        className: 'ms-Grid-col ms-lg3',
        extras: { isRequired: transaction.type !== "repayment" }
    },
    {
        key: 'details.end',
        label: 'Interest End Date',
        type: 'date',
        placeholder: 'Pick date',
        className: 'ms-Grid-col ms-lg3',
        extras: { isRequired: transaction.type !== "repayment" }
    },
    ]
}

export const transactionTabs: TransactionTabErrors = {
    genearl: false,
    type: false,
}

export const initTrasnaction: ITransaction = {
    action: 'account',
    auditLog: [],
    bondHolder: {
        userName: '',
        email: '',
        name: ''
    },
    certNumber: 0,
    details: {
        amount: 0,
        bondAmount: 0,
        days: 0,
        end: new Date(),
        rate: 0,
        start: new Date()
    },
    dueDate: new Date(),
    fulfillmentDate: null,
    notes: '',
    paymentReference: '',
    period: '',
    status: 'Open',
    type: 'interest',
    bondId: "",
    bondOfferId: ""
} as unknown as ITransaction


export const getTabErrors = (errors: StringMap, transaction: ITransaction): TransactionTabErrors => {
    const tabErrors = { ...transactionTabs }
    Object.keys(errors).forEach(property => {
        if (getGeneralFields({ transaction, bonds: [] }).find(field => field.key === property)) {
            tabErrors.genearl = true;
        }
        if (getTypeFields({ transaction }).find(field => field.key === property)) {
            tabErrors.type = true;
        }

    })
    return tabErrors;
}

export const validateTransaction = (transaction: ITransaction) => {
    let errors: StringMap = {};
    const { certNumber, dueDate, details, type } = transaction
    if (!dueDate || !moment(dueDate).isValid()) errors["dueDate"] = requiredMessage
    if (type !== "repayment" && (!details.start || !moment(details.start).isValid())) errors["details.start"] = requiredMessage
    if (type !== "repayment" && (!details.end || !moment(details.end).isValid())) errors["details.end"] = requiredMessage
    if (!certNumber) errors["certNumber"] = requiredMessage
    if (!details.bondAmount) errors["details.bondAmount"] = requiredMessage
    return errors
}

export const defaultBondTransactionFilter = {
    page: 1,
    pageSize: 50,
    sortField: "certNumber",
    sortOrder: "desc",
    payment: "all",
    bondOfferId: "all",
}
