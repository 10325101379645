import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ActionButton, CheckboxVisibility, DetailsList, IColumn, ScrollablePane, ScrollbarVisibility, SelectionMode, TooltipHost } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { IGSSReport } from "../../../../store/admin/reporting/gssReport/reducer";
import { exportGSSReport, fetchGSSReportData, setGSSReportFilter } from "../../../../store/admin/reporting/gssReport/action";
import { DetailListStickyHeader } from "../../common/DetailListStickyHeader";
import { DropdownParams } from "../../common/types";
import { SetDetailListHeaderSortDirection } from "../../common/utils";
import { useState } from "react";
import { RenderListRow } from "../../common/RenderListRow";
import { headerGSSReportingRow } from "./Helper";


const GSSReportList = () => {
  const dispatch = useDispatch()
  const { GSSReportData, gssReportFilter } = useSelector<RootState, IGSSReport>((state: RootState) => state.web.gssReport);
  
  const [columns, setColumns] = useState<IColumn[]>([...headerGSSReportingRow]);

  const handleOnSort = (item: DropdownParams) => {
    const params = { ...gssReportFilter, ...{ sortField: item?.key, sortOrder: item?.text }, page: 1 };
    dispatch(setGSSReportFilter(params));
    dispatch(fetchGSSReportData());
  }

  const handleOnHeaderClick = (column?: IColumn): void => {
    if (column?.key === 'action') return
    const index = columns.findIndex(c => c.key === column?.key)
    const newColumns = SetDetailListHeaderSortDirection(column, columns)
    setColumns(newColumns)
    handleOnSort({ key: newColumns[index].key, text: newColumns[index].isSortedDescending ? 'desc' : 'asc' })
  };

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}>
            {GSSReportData.length > 0 && (<>Displaying &nbsp; {GSSReportData.length}</>)}
          </span>
        </div>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => dispatch(exportGSSReport())}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
      </div>

      {GSSReportData.length > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                items={GSSReportData}
                columns={columns}
                selectionMode={SelectionMode.none}
                onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                onRenderRow={(props) => RenderListRow(props!)}
              />
            </ScrollablePane>
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default GSSReportList;