import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../common/utils";

// Return the column Values
export const getEnvConfigColumns = (): IColumn[] => {
  return [
    {
      key: "Key",
      name: "Key",
      fieldName: "Key",
      minWidth: 75,
      maxWidth: 250,
      ...isSortable,
      onRender: (item) => {
        const value = item["EnvName"];
        return (
          <TooltipHost content={value} closeDelay={500}>
            {value}
          </TooltipHost>
        );
      },
    },
    {
      key: "Value",
      name: "Value",
      fieldName: "Value",
      minWidth: 45,
      maxWidth: 300,
      ...isSortable,
      onRender: (item) => {
        const value = item["EnvData"];
        return (
          <TooltipHost content={value} closeDelay={500}>
            {value}
          </TooltipHost>
        );
      },
    },
  ];
};
