import React, { useState } from "react";

const Textbox = ({
  onChange,
  placeholder,
  value,
  char = 0,
  maxChar = 0,
  width,
  height,
  margin,
  type = "text",
  style = {},
  containerStyle = {},
}: textBoxProps) => {
  const [
    textboxContainerStyle,
    setTextboxContainerStyle,
  ] = useState<React.CSSProperties>({
    display: "flex",
    border: "1px solid #cacaca",
    backgroundColor: "white",
    width,
    height,
    borderRadius: "0.4rem",
    overflow: "hidden",
    marginBottom: "1rem",
    margin: margin ? margin : margin,
    padding: "0.5rem 0.7rem",
    alignItems: "center",
    justifyContent: "space-between",
    ...containerStyle,
  });

  const textboxStyle: React.CSSProperties = {
    outline: "none",
    resize: "none",
    border: "none",
    width,
    height,
    padding: "0.5rem 0.7rem",
    fontSize: "1.5rem",
    color: "#243f61",
    fontWeight: "bold",
    ...style,
  };
  const spanStyle: React.CSSProperties = {
    color: "#d3d3d3",
  };
  if (maxChar > 0 && value && value?.length > maxChar) {
    value = value.substr(0, maxChar);
  }
  char = value ? value.length : 0;
  const [isFocus, setIsFocus] = useState(false);
  const setBorder = (isAdd: boolean) => {
    let border = "1px solid #cacaca";
    if (isAdd) {
      border = "1px solid #243f61";
    }
    setTextboxContainerStyle({
      ...textboxContainerStyle,
      border,
    });
  };
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    setBorder(true);
  };
  const handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    setBorder(false);
  };
  const handleMouseOver = (e: React.MouseEvent) => {
    setBorder(true);
  };
  const handleMouseOut = (e: React.MouseEvent) => {
    if (!isFocus) {
      setBorder(false);
    }
  };
  return (
    <div style={textboxContainerStyle}>
      <input
        type={type}
        style={textboxStyle}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        onMouseEnter={handleMouseOver}
        onMouseOut={handleMouseOut}
        onFocus={handleFocus}
        onBlur={handleFocusOut}
      />
      {maxChar > 0 ? (
        <span style={spanStyle}>{`${char}/${maxChar}`}</span>
      ) : null}
    </div>
  );
};

export interface textBoxProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  char?: number;
  maxChar?: number;
  width?: string;
  height?: string;
  margin?: string;
  type?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

export default Textbox;
