import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { IGenerateReport } from '../../../store/admin/generateReport/reducers'
import { generateReport, fetchRegions, fetchSchools, fetchTemplates, resetState } from '../../../store/admin/generateReport/action'
import { IDropdownOption } from 'office-ui-fabric-react'
import { GenReportButton, buildRegionOption, buildTemplateOption, defaultRegionsOption, defaultSchoolsOption, defaultTemplatesOption, dropdownLayoutStyles, errorsDetails, filerDropDownStyle, filterParams, regionDropDownStyle, schoolDropDownStyle, templateDropDownStyle } from './helper'
import { ChoiceGroup, ComboBox, Dropdown, IComboBoxOption } from '@fluentui/react'
import { dropdownStyles } from '../common/common'
import { ITemplate } from '@solarforschools/sfs-core/dist/types'

const SchoolsFilter = () => {
    const dispatch = useDispatch()

    const { schoolReports } = useSelector<RootState, IGenerateReport>((state: RootState) => state.web.generateReport)

    const [regionsFilter, setRegionsFilter] = useState<IDropdownOption[] | []>([])
    const [schoolsFilter, setSchoolsFilter] = useState<IComboBoxOption[] | []>([defaultSchoolsOption])
    const [templatesFilter, setTemplatesFilter] = useState<IDropdownOption[] | []>([defaultTemplatesOption])



    const [selectedRegion, setSelectedRegion] = useState("")
    const [selectedSchoolSlug, setSelectedSchoolSlug] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState("")
    const [selectedFilterParams, setSelectedFilterParams] = useState("")
    const [errors, setErrors] = useState(errorsDetails)

    useEffect(() => {
        dispatch(fetchRegions())
        return () => {
            dispatch(resetState())
        }
    }, [])


    useEffect(() => {
        if (schoolReports.regions && schoolReports.regions.length > 0 && Array.isArray(schoolReports.regions)) {
            let result = buildRegionOption(schoolReports.regions)
            result.unshift(defaultRegionsOption)
            setRegionsFilter(result)
        }

    }, [schoolReports.regions])


    useEffect(() => {
        if (schoolReports.schools && schoolReports.schools.length > 0 && Array.isArray(schoolReports.schools)) {
            const schools = schoolReports.schools
            let result = []
            for (const school of schools) {
                const schoolObj = { key: school.schoolSlug, text: school.schoolName }
                result.push(schoolObj)
            }
            result.unshift(defaultSchoolsOption)
            setSchoolsFilter(result)
        }

    }, [schoolReports.schools])


    useEffect(() => {
        if (schoolReports.templates && schoolReports.templates.length > 0 && Array.isArray(schoolReports.templates)) {
            let result = buildTemplateOption(schoolReports.templates)
            result.unshift(defaultTemplatesOption)
            setTemplatesFilter(result)
        }

    }, [schoolReports.templates])


    const handleRegionChange = (key: string, value: any) => {
        if (value === "SELECT" || value === undefined || value === "") {
            return
        }
        setSelectedRegion(value)
        setErrors({ ...errors, isRegionError: false, regionErrorMsg: "" })

        dispatch(fetchSchools(value))
        setSelectedTemplate("")
        dispatch(fetchTemplates(value))
    }

    const handleSchoolChange = (key: string | undefined, value: any) => {
        if (value === "SELECT" || value === undefined || value === "") {
            return
        }
        setSelectedSchoolSlug(value)
        setErrors({ ...errors, isSchoolError: false, schoolErrMsg: "" })
    }

    const handleTemplateChange = (key: string | undefined, value: any) => {
        if (value === "SELECT" || value === selectedTemplate) {
            return
        }

        setSelectedTemplate(value)
        setErrors({ ...errors, isTemplateError: false, templateErrMsg: "" })

    }

    const handleFilterParamsChange = (key: string, value: any) => {
        if (value === "SELECT" || value === selectedFilterParams) {
            return
        }

        setSelectedFilterParams(value)
    }
    const validateInput = () => {

        let valid = true
        if (selectedRegion === "") {
            setErrors({ ...errors, isRegionError: true, regionErrorMsg: "Please select a region" })
            valid = false
        }
        if (selectedRegion !== "" && (selectedSchoolSlug === undefined || selectedSchoolSlug === "" || selectedSchoolSlug === "SELECT")) {
            setErrors({ ...errors, isSchoolError: true, schoolErrMsg: "Please select a school" })
            valid = false

        }
        if ((selectedRegion !== "" && selectedSchoolSlug !== "") && (selectedTemplate === "")) {
            setErrors({ ...errors, isTemplateError: true, templateErrMsg: "Please select a template" })
            valid = false

        }
        return valid
    }

    const handleGeneratePdf = () => {

        const isValid = validateInput()

        if (!isValid) return

        if (schoolReports.templates) {

            const hyfenIndex = selectedTemplate.lastIndexOf("-")
            const extractedTemplateName = selectedTemplate.substring(0, hyfenIndex)
            const report: any = schoolReports.templates.find((r: ITemplate) => r.name === extractedTemplateName);
            const path = report.path.split("/root:/")[1];
            const dataset: "school" | "project" = "school"
            const upload = "S3:sfs-report"

            const args = {
                dataset,
                template: report.name,
                upload,
                path,
                schoolSlug: selectedSchoolSlug,
                filter: selectedFilterParams === "" ? "all" : selectedFilterParams,
                reportTemplate: report,
            }

            dispatch(generateReport(args, dataset))
        }

    }


    const FilterOptions = () => {

        if (regionsFilter.length === 0 || schoolsFilter.length === 0) return <></>

        return (
            <>
                <div style={dropdownLayoutStyles}>
                    <div style={regionDropDownStyle}>
                        <Dropdown
                            dropdownWidth="auto"
                            label="Choose a region"
                            options={regionsFilter}
                            styles={dropdownStyles}
                            onChange={(e, elm) => handleRegionChange("Region", elm?.key?.toString())}
                            selectedKey={selectedRegion || defaultRegionsOption.key}
                            defaultSelectedKey={selectedRegion || defaultRegionsOption.key}
                            className="inlineflex ms-Grid-col ms-sm"
                            required
                            errorMessage={errors.isRegionError ? errors.regionErrorMsg : undefined}
                        />
                    </div>
                    <div style={schoolDropDownStyle}>
                        <ComboBox
                            label="Choose a school"
                            placeholder='School name'
                            selectedKey={selectedSchoolSlug}
                            defaultSelectedKey={schoolsFilter[0].key}
                            allowFreeform={true}
                            autoComplete="on"
                            options={schoolsFilter}
                            dropdownMaxWidth={500}
                            useComboBoxAsMenuWidth
                            required
                            errorMessage={errors.isSchoolError ? errors.schoolErrMsg : ""}
                            onChange={(e, element) =>
                                handleSchoolChange("school", element?.key?.toString())
                            }
                        />
                    </div>

                    <div style={templateDropDownStyle}>
                        <Dropdown
                            label="Choose a template"
                            dropdownWidth="auto"
                            options={templatesFilter}
                            styles={dropdownStyles}
                            onChange={(e, elm) => handleTemplateChange("Template", elm?.key?.toString())}
                            selectedKey={selectedTemplate || defaultTemplatesOption.key}
                            defaultSelectedKey={selectedTemplate || defaultTemplatesOption.key}
                            required
                            errorMessage={errors.isTemplateError ? errors.templateErrMsg : ""}

                        />
                    </div>


                    <div style={filerDropDownStyle}>
                        <ChoiceGroup
                            className='inlineflex'
                            label="Filter Designs"
                            selectedKey={selectedFilterParams || filterParams[0].key}
                            options={filterParams}
                            onChange={(e, elm) => handleFilterParamsChange("FilterParams", elm?.key?.toString())}
                        />

                    </div>

                </div>
            </>
        )
    }

    if (!schoolReports.regions || schoolReports.regions.length === 0) return <></>

    return (
        <>
            <FilterOptions />
            <GenReportButton onClickFn={handleGeneratePdf} />
        </>
    )
}

export default SchoolsFilter