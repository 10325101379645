import { Action } from "./actionTypes";
import { ITaskType } from "@solarforschools/sfs-core/dist/taskType/types";
import { ITaskTypeFilter } from "../../../components/admin/taskType/types";

// States' definition
export interface ITaskTypeState {
  isLoading: boolean;
  taskTypes: ITaskType[];
  taskTypeFilter: ITaskTypeFilter;
  totalCount: number;
}
export interface State {
  taskTypeState: ITaskTypeState;
}

const taskType = (
  state: ITaskTypeState = {
    isLoading: false,
    taskTypes: [],
    taskTypeFilter: {
      page: 1,
      pageSize: 50,
      sortField: "Start",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): ITaskTypeState => {
  switch (action.type) {
    case "SET_TASKTYPE_FILTER":
      return { ...state, taskTypeFilter: action.taskTypeFilter };
    case "SET_TASKTYPE_LIST":
      const taskTypes = action.reset
        ? action.taskTypes
        : [...state.taskTypes, ...action.taskTypes];
      return { ...state, taskTypes, totalCount: action.totalCount };
    case "DELETE_TASKTYPE": {
      const stateObj = { ...state };
      const taskTypes: ITaskType[] = stateObj.taskTypes.filter(
        (s) => s.slug !== action.slug
      );
      const taskTypeFilter = { ...stateObj.taskTypeFilter };
      const { page, pageSize } = taskTypeFilter;
      return {
        ...state,
        taskTypes,
        totalCount: stateObj.totalCount - 1,
        taskTypeFilter: {
          ...taskTypeFilter,
          page: Math.ceil((taskTypes.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_TASKTYPE": {
      const taskTypes = [...state.taskTypes];
      const index = taskTypes.findIndex(
        (s) => s._id?.toString() === action.taskType?._id?.toString()
      );
      if (index > -1) {
        taskTypes[index] = action.taskType;
      } else {
        taskTypes.unshift(action.taskType);
      }
      return {
        ...state,
        taskTypes,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default taskType;
