import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IYield } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { methods } from "./common";
import { ILiveTimeSeries } from "@solarforschools/sfs-core/dist/solardb/types";

export const getGeneralFields = (options: StringMap): IField[] => {
    return [
        {
            label: "Id",
            key: "id",
            type: "number",
            extras: { required: true },
            placeholder: "system Id",
            className: 'ms-Grid-col ms-lg3'
        }, {
            label: "Name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "Date",
            key: "date",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "G",
            key: "data.G",
            type: "number",
            placeholder: "Generation",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "I",
            key: "data.I",
            type: "number",
            placeholder: "Import",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "E",
            key: "data.E",
            type: "number",
            placeholder: "Export",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "C",
            key: "data.C",
            type: "number",
            placeholder: "Consumption",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "SC",
            key: "data.SC",
            type: "number",
            placeholder: "Self Consumption",
            className: 'ms-Grid-col ms-lg2'
        },
    ];
}
export const getGenYieldFields = (options: StringMap): IField[] => {
    return [
        {
            label: "Method/Mode",
            key: "mode",
            type: "dropdown",
            extras: { required: true },
            options: methods as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
        },
        {
            label: "Start Date",
            key: "start",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "End Date",
            key: "end",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg6'
        }
    ];
}
export const getEditTSReadingFields = (options: StringMap): IField[] => {
    return [
        {
            label: "MSN",
            key: "meta.msn",
            extras: { required: true, readOnly: true },
            className: 'ms-Grid-col ms-lg6',
            type: "string"
        },
        {
            label: "Type",
            key: "meta.type",
            extras: { required: true, readOnly: true },
            className: 'ms-Grid-col ms-lg3',
            type: "string"
        },
        {
            label: "Date Time",
            key: "createdAt",
            extras: { required: true, readOnly: true },
            className: 'ms-Grid-col ms-lg6',
            type: "string"
        },
        {
            label: "Reading",
            key: "reading",
            type: "number",
            extras: { required: true, min: 0 },
            placeholder: "Enter Reading",
            step: "1",
            className: 'ms-Grid-col ms-lg4'
        },
    ];
}

export const getTsReadingsColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        // {
        //     key: 'systemId',
        //     name: 'System ID',
        //     fieldName: 'systemId',
        //     minWidth: 150,
        //     maxWidth: 150,
        //     ...isSortable,
        //     onRender: (item) => {
        //         if (item.isMeter === null) { return 'N/A' } else return <TooltipHost content={item.systemId.toString()} closeDelay={500}> {item.systemId}</TooltipHost>
        //     }
        // },
        // {
        //     key: 'name',
        //     name: 'System',
        //     fieldName: 'name',
        //     minWidth: 150,
        //     maxWidth: 250,
        //     ...isSortable,
        //     onRender: (item) => {
        //         if (item.isMeter === null) { return item.msn } else return <TooltipHost content={item.name.toString()} closeDelay={500}> {item.name}</TooltipHost>
        //     }
        // },
        {
            key: 'msn',
            name: 'MSN',
            fieldName: 'msn',
            minWidth: 150,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.msn} closeDelay={500}> {item.msn}</TooltipHost>
        },
        {
            key: 'type',
            name: 'Type',
            fieldName: 'meta.type',
            minWidth: 80,
            maxWidth: 80,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.type === "C" ? "EV" : item.type} closeDelay={500}> {item.type === "C" ? "EV" : item.type}</TooltipHost>
        },
        {
            name: 'Date',
            key: 'date',
            fieldName: 'date',
            minWidth: 150,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => {
                return <TooltipHost content={moment(item.date).format('YYYY-MM-DD')} closeDelay={500}> {moment(item.date).format('YYYY-MM-DD')}</TooltipHost>
            }
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (data) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(data)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

