import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IAdmin } from "../store/admin/reducer";
import { login } from "../store/admin/action";
import { ILoginCredentials } from "../store/admin/actionTypes";

const PrivateRoute = ({
  component: Component,
  web: { isAuthenticated, isLoading },
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated && isLoading ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

interface DispatchProps {
  login: (credentials: ILoginCredentials) => void;
}

interface StateProps {
  web: IAdmin;
}
interface IRootState {
  web: any;
}

interface OwnProps {
  component: React.ComponentType<any>;
}

type Props = StateProps & OwnProps & DispatchProps & RouteProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  web: state.web.admin,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    login: async (credentials: ILoginCredentials) => {
      await dispatch(login(credentials));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
