import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchBillingPeriods, setBillingPeriodFilter } from '../../../store/admin/period/action';
import { IBillingPeriodState } from '../../../store/admin/period/reducer';
import { comboBoxStyles } from '../common/common';
import { PeriodReadingYears } from './common';

const BillingPeriodFilters = (props: InvoiceFilterProps) => {
    const dispatch = useDispatch()
    const { periodFilter }: IBillingPeriodState = useSelector<RootState, IBillingPeriodState>((state: { web: { billingPeriod: IBillingPeriodState; }; }) => state.web.billingPeriod)

    const handleChange = async (key: string, value: string | undefined) => {
        const params = { ...periodFilter, [key]: value, page: 1 };
        dispatch(setBillingPeriodFilter(params));
        handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback((filters: any) => { dispatch(fetchBillingPeriods(filters)) }, [])

    const { cycle, period, year } = periodFilter

    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Cycle"
                    placeholder='Cycle'
                    type='text'
                    onChange={(e, value) => handleChange('cycle', value?.toUpperCase())}
                    value={cycle!}
                    className="ms-Grid-col ms-lg3"
                />
                <TextField
                    label="Period"
                    placeholder='Period'
                    type='text'
                    onChange={(e, value) => handleChange('period', value?.toUpperCase())}
                    value={period!}
                    className="ms-Grid-col ms-lg3"
                />
                <Dropdown
                    label="Year"
                    placeholder="Year"
                    selectedKey={year?.toString() || 'all'}
                    onChange={(e, item) => handleChange('year', item?.key?.toString() || 'all')}
                    options={[{ key: 'all', text: 'All' }, ...PeriodReadingYears()] as IDropdownOption[]}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg3"
                />
            </div>
        </div>
    );
}

interface InvoiceFilterProps {

}

export default BillingPeriodFilters;

