import { useEffect } from "react";

import { IEstablishment, ISystem } from "@solarforschools/sfs-core";
import SystemList from "../system/SystemList";
import { ITag, TagPicker } from "@fluentui/react";
import { deleteSchoolSystem, getSystems, updateSchoolSystem } from "../../../store/client/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ISystemState } from "../../../store/admin/system/reducer";
import { fetchSystemsData, setDetailListFilter } from "../../../store/admin/system/action";


const SchoolSystem = (props: { school: IEstablishment }) => {
  const dispatch = useDispatch()
  const { systemFilterParams, systems }: ISystemState = useSelector<RootState, ISystemState>((state) => state.web.system);
  const handleGetSystem = async (
    filter: string,
    selectedItems?: ITag[],
  ): Promise<ITag[]> => {
    try {
      const { systems } = await getSystems({ name: filter, action: 'find' })
      const selectedMap: { [key: string]: boolean } = {};

      return systems
        .filter((system: { slug: string | number; }) => !selectedMap[system.slug])
        .map((system: ISystem) => {
          return {
            key: system.id,
            name: `${system.id}-${system.name}(${system.provider})`,
            data: system,
          }
        })
    } catch (error) {
      return [] as ITag[]
    }
  }

  const handleAddSystem = async (items?: ITag[]) => {
    if (!items) return;
    await updateSchoolSystem(props.school.slug, [...systems.map(s => s.id), items[0].key])
    dispatch(fetchSystemsData({ schoolSlug: props.school.slug }))
  }

  const loadData = async () => {
    const params = { ...systemFilterParams, page: 1, schoolSlug: props.school.slug };
    dispatch(setDetailListFilter(params));
    dispatch(fetchSystemsData({ schoolSlug: props.school.slug }))
  }
  useEffect(
    () => {
      loadData();
    }, []);

  const handleOnRemoveSystem = async (system: ISystem) => {
    await deleteSchoolSystem(props.school.slug, system.id)
    dispatch(fetchSystemsData({ schoolSlug: props.school.slug }))
  }
  const extraActions = [{ key: 'delete', icon: 'Delete', title: "Delete System", action: handleOnRemoveSystem }]

  return (
    <>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-lg4"></div>
        <div className="ms-Grid-col ms-lg4">
          <TagPicker
            styles={{ root: { marginTop: 5 } }}
            inputProps={{ placeholder: 'System search' }}
            onResolveSuggestions={handleGetSystem}
            selectedItems={[]}
            selectionAriaLabel={'Selected systems'}
            onChange={handleAddSystem}
          />
        </div>
      </div>

      <SystemList actions={[]} extraActions={extraActions} />

    </>
  );
};

export default SchoolSystem;
