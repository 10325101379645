import React, { useContext } from "react";
import { CardContext, CardContextType } from "./";
import PlainFooter from "./footerVariants/PlainFooter";

import FlipFooter from "./footerVariants/FlipFooter";
interface ICardFooterProps {
    variant?: string;
    frontFooter?: () => JSX.Element
    backFooter?: () => JSX.Element
    isHaveFlipper?: boolean
}
const CardFooter = (props: ICardFooterProps) => {
    const { variant, frontFooter, backFooter, isHaveFlipper } = props;
    let Component;
    switch (variant) {
        case "PlainFooter": Component = <PlainFooter Footer={frontFooter} isHaveFlipper={isHaveFlipper} />; break;
        case "Flipper": Component = <FlipFooter frontFooter={frontFooter} backFooter={backFooter} />; break;
        default: Component = <></>
    }
    return <div>{Component}</div>
}

export default CardFooter;
