import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getAddYieldFields, getGenYieldFields, validateAddYield, validateGenYield } from "./Yieldhelper"
import { ISystem, IYield } from "@solarforschools/sfs-core/dist/solardb/types"
import { addYield, genYields } from "../../../store/admin/yields/action"
import { toast } from "react-toastify"
import { isLoading } from "../../../store/admin/action"
import { getSystemOptions } from "../common/common"
import moment from "moment"
import CustomTagPicker from "../common/CustomTagPicker"
import { ITag } from "office-ui-fabric-react"
import { getSystems } from "../../../store/client/system"
import SystemPicker from "../common/SystemPicker"


export const openAddYieldModal = (props?: any) => {
  return new Promise<IYield | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: IYield) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add Yield"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditYieldDialog
            {...props}
            onSave={(data: IYield | undefined) => callback(data)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditYieldDialog = (props: YieldsProps) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<IYield>({
    name: '',
    timeUnit: 'day',
    id: null as any,
    data: { G: 0, I: 0, E: 0, C: 0, SC: 0, T: 0 , EV: 0},
    date: moment().subtract(1, 'days').format('YYYY-MM-DD') as any
  })

  const [errors, setErrors] = useState<any>({});

  const fields = useMemo(() => getAddYieldFields({ data }), [data]);

  const handleOnSave = async () => {
    const errors = await validateAddYield(data);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    await dispatch(addYield(data))
    props.onSave(data)
  }
  const handleOnChangeInput = (key: string, value: any) => {
    const obj = _.cloneDeep(data);
    switch (key) {
      case 'start':
      case 'date':
        setData(_.set({ ...obj }, key, moment(value).format('YYYY-MM-DD')))
        break
      case 'selectedSystems':
        setData(_.set({ ...obj }, 'id', value[0]?.key))
        break
      default:
        setData(_.set({ ...obj }, key, value))
        break;
    }
  }

  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className={`ms-Grid-col ms-lg6 margin-top-xsm`}>
            <SystemPicker onChange={handleOnChangeInput} />
          </div>
          <DynamicForm
            fields={fields}
            data={data}
            onChange={handleOnChangeInput}
            errors={errors}
          />
        </div>
      </div>
      <div className="ms-Grid margin-top-lg" dir="ltr">
        <div className="ms-Grid-row">
          <small><i>*All values in Wh</i></small>
        </div>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface YieldsProps {
  onCancel?: () => void;
  onSave: (data?: IYield) => void;
  data?: IYield;
}

export default openAddYieldModal;
