import { ActionButton, Dropdown, IDropdownOption, Stack, Text } from '@fluentui/react';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Loader from "../../common/Loader";
import { IBondOfferState } from './../../../../store/admin/funder/bondOffer/reducer';
import BondOfferList from './BondOfferList';
import BondOfferFilters from './BondOfferFilters';
import { exportBondOffers, getBondBondoffersData, getBondOffers, setBondOfferFilter } from './../../../../store/admin/funder/bondOffer/action';
import { OpenAddBondOfferModal } from './AddBondOfferDialog';
import { initBondOffer } from './BondOfferHelper';
import { StringMap } from '../../common/types';
import { useParams } from 'react-router-dom';
import { PageSize } from '../../common/common';

const BondOfferScreen = () => {
  const dispatch = useDispatch()
  const { bondOffers, totalCount, bondOfferFilter }: IBondOfferState = useSelector<RootState, IBondOfferState>((state: RootState) => state.web.bondOffer)
  const params: StringMap = useParams() || {};
  useEffect(() => {
    if (Object.keys(params).length) {
      dispatch(setBondOfferFilter({ ...bondOfferFilter, bondOffer: params.id }))
    }

    dispatch(getBondOffers())
    dispatch(getBondBondoffersData())
  }, []);

  const handleAddBondOffer = async () => {
    try {
      await OpenAddBondOfferModal({ bondOffer: initBondOffer })
    } catch (error) {
      console.log(error)
    }
  }
  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...bondOfferFilter, page: 1, pageSize: size }
    dispatch(setBondOfferFilter(filters))
    dispatch(getBondOffers())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Bond Offers</h1>
        <BondOfferFilters />
      </header>
      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }} className="margin-top-md">
        <div style={{ flex: "20%" }}>
        <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={bondOfferFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        <ActionButton iconProps={{ iconName: 'DrillDown' }} onClick={() => dispatch(exportBondOffers())} >
          Export Bond Offers
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={() => handleAddBondOffer()}>
          Add Bond Offer
        </ActionButton>

      </div>
      <BondOfferList actions={["edit", "delete"]} />
    </div>
  );
}
export default BondOfferScreen;
