import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { CheckboxVisibility, CommandBar, IColumn, ICommandBarItemProps, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType } from '@fluentui/react';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { defaultBondFilter, getBondsColumns } from './BondHelper';
import { setBondsFilter, getBonds, downloadBondCertificate, createBondTransactions, deleteBond, processBondRepaymet, getBondBondoffers, getBondFilter, getBondBondHolders } from '../../../../store/admin/funder/bond/action';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import { OpenAddBondModal } from './AddBondDialog';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import { OpenRepaymentModal } from './BondRepaymentDialog';
import { isLoading } from '../../../../store/admin/action';
import { toast } from 'react-toastify';
import { getBondEmailPreview, sendBondEmailApi } from '../../../../store/client/funder/bond';
import { OpenEmailPreviewModal } from '../../common/EmailPreviewDialog';
import Pagination from '../../common/Pagination';
import { IBondFilter } from './types';

const BondsList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { bonds, totalCount, bondFilter }: IBondState = useSelector<RootState, IBondState>((state: RootState) => state.web.bonds)

  const handleOnDelete = async (data: IBond) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Bond",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to Bond?`,
      },
    });
    if (confirm) dispatch(deleteBond(data._id!?.toString()));
  };

  const genBondTransactions = async (bond: IBond) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Create Transaction",
        subText: `Do you want to create Transaction for this bond?`,
      },
      confirmBtnText: 'Generate',
    });
    if (confirm) dispatch(createBondTransactions(bond))

  };

  async function handleEditbond(bond: IBond) {
    const result = await OpenAddBondModal({
      bond,
      onCancel: () => null,
      onSave: () => null,
    });
    // if (result) {
    //   await dispatch(createUpdateSchool(result))
    //   const updated = [...props.schools];
    //   const index = props.schools.findIndex(x => x.slug === school.slug);
    //   updated[index] = result;
    //   props.onUpdated(updated);
    // }
  }

  const openEmailPreview = async (item: IBond, type: string) => {
    try {
      dispatch(isLoading(true))
      const { html } = await getBondEmailPreview(item._id!.toString(), { type })
      dispatch(isLoading(false))
      await OpenEmailPreviewModal({
        params: {
          data: [{ ...item }],
          title: 'Payment Preference',
          btnTitle: 'Send',
          html,
          sendAction: () => sendBondEmailApi(item._id as any, { type })
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }

  const subMenu = (bond: IBond) => {
    const items: any[] = []
    items.push({
      key: 'transaction',
      text: 'Create Transactions',
      ariaLabel: "Create Transactions",
      iconProps: { iconName: 'M365InvoicingLogo' },
      onClick: (e: any) => {
        e?.preventDefault();
        genBondTransactions(bond)
      },
    })
    if (bond.certificate?.id) {
      items.push({
        key: 'certificate',
        text: 'Send Certificate',
        ariaLabel: "Send Certificate to bond holder",
        iconProps: { iconName: 'Mail' },
        onClick: (e: any) => {
          e?.preventDefault();
          openEmailPreview(bond, "bond-welcome")
        },
      })
      items.push({
        key: 'resend-certificate',
        text: 'Re Send Certificate',
        ariaLabel: "Re Send Certificate to bond holder",
        iconProps: { iconName: 'Mail' },
        onClick: (e: any) => {
          e?.preventDefault();
          openEmailPreview(bond, "bond-resend-cert")
        },
      })
      items.push({
        key: 'download',
        text: 'Download Certificate',
        ariaLabel: "Download Certificate",
        iconProps: { iconName: 'DrillDown' },
        onClick: (e: any) => {
          e?.preventDefault();
          dispatch(downloadBondCertificate(bond))
        },
      })
    }
    items.push({
      key: 'repayment',
      text: 'Re Payment',
      ariaLabel: "Re Payment",
      iconProps: { iconName: 'Money' },
      onClick: (e: any) => {
        e?.preventDefault();
        OpenRepaymentModal({ bond: bond })
      },
    })
    return items
  }
  const getActions = (bond: IBond, actions: any, extraActions?: ICommandBarItemProps[]) => {
    let listActions: ICommandBarItemProps[] = []
    if (bond.status === "Open") listActions.push(
      {
        key: 'newItem',
        text: '',
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'CollapseMenu' },
        subMenuProps: {
          items: [...subMenu(bond)],
        },
      })
    for (const action of actions) {
      if (action === 'edit') listActions.push({
        key: 'edit',
        text: '',
        iconProps: { iconName: 'Edit' },
        onClick: (e) => {
          e?.preventDefault();
          handleEditbond(bond);
        },
      })
      if (action === 'delete') listActions.push({
        key: 'delete',
        text: '',
        iconProps: { iconName: 'Trash' },
        onClick: (e) => {
          e?.preventDefault();
          handleOnDelete(bond);
        },
      })
    }
    if (extraActions) {
      for (const action of extraActions) {
        listActions.push({
          key: action.key,
          text: '',
          iconProps: { iconName: action.icon },
          onClick: (e: any) => {
            e?.preventDefault();
            action?.action(bond);
          },
        })
      }
    }
    return listActions
  }
  const headerRow: IColumn[] = [...getBondsColumns, {
    key: "action",
    name: "Action",
    fieldName: "-",
    minWidth: 200,
    onRender: (bond: IBond) => {
      return (
        <CommandBar
          items={[...getActions(bond, actions, [])]}
          ariaLabel="Actions"
          primaryGroupAriaLabel="Actions"
        />
      )
    },
  }]
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...bondFilter, sortField: field, sortOrder: order };
    dispatch(setBondsFilter(params));
    dispatch(getBonds(params))
  }
  const loadNextPage = (p: IBondFilter) => {
    dispatch(setBondsFilter({ ...bondFilter, ...p }))
    dispatch(getBonds())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            {/* <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> */}
              <DetailsListWapper
                items={bonds}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={bonds.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            {/* </ScrollablePane> */}
          </div>
          <Sticky stickyPosition={StickyPositionType.Footer}>
            <Pagination
              totalRecords={totalCount}
              onPaginate={(page) => loadNextPage({ page })}
              currentPage={bondFilter?.page!}
              pageSize={bondFilter.pageSize!}
            />
          </Sticky>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default BondsList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

