

import { toast } from "react-toastify";
import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { host } from "../../config";
import { RootState } from '../../index';
import { isLoading } from "../action";
import { IBillingContract, ICreateInvoice, IInvoiceDocument, IInvoiceFilter } from "../../../components/admin/invoice/types";
import { DropdownParams } from "../../../components/admin/common/types";
import { SetInvoiceFilter } from "./actionTypes";
import { downloadFileFromStream, generateInvoiceFilename } from "../../../components/admin/common/utils";
import { exportBillingInvoiceApi, getInvoices } from "../../client/invoice";
import { getPeriodsApi } from "../../client/period";


// Action Creators

export const setInvoiceFilter = (invoiceFilter: IInvoiceFilter): SetInvoiceFilter => {
  return { type: "SET_INVOICE_FILTER", invoiceFilter };
};

export const setCycleFilter = (cycles: DropdownParams[]) => {
  return { type: "SET_CYCLE_FILTER", cycles };
};

export const setInvoiceList = (invoices: IInvoiceDocument[], reset: boolean = true) => {
  return { type: "SET_INVOICE_LIST", invoices, reset };
};

export const updateInvoiceList = (invoice: IInvoiceDocument) => {
  return { type: "BILLING/UPDATE_INVOICE", invoice };
};

export const setInvoiceTotalCount = (totalCount: number) => {
  return { type: 'SET_INVOICE_TOTALS_COUNT', totalCount }
}

export const deleteBillingInvoice = (invoiceId: string) => {
  return { type: 'BILLING/DELETE_INVOICE', invoiceId }
}


// export const fetchCycles = () => {
//   // Invoke API
//   return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>,): Promise<void> => {
//     return new Promise<void>((resolve) => {
//       try {
//         dispatch(isLoading(true));
//         axios
//           .get(`${host}/billing/cycles`)
//           .then((res) => {
//             if (res.status === 200) {
//               const cycles = [{ key: 'all', text: 'All' }]
//               for (let i = res.data.length - 1; i >= 0; i--) {
//                 cycles.push({ text: res.data[i], key: res.data[i] })
//               }
//               dispatch(setCycleFilter(cycles));
//               dispatch(isLoading(false));
//             }
//           })
//           .catch((err) => {
//             if (err?.response?.status === 404) {
//               toast.error(err.response.data.msg);
//             }
//             dispatch(isLoading(false));
//           });
//       } catch (e) {
//         console.log(e);
//         dispatch(isLoading(false));
//       }
//     });
//   };
// }

// TO:DO  need to convert in redux dispatch
export const fetchBillingPeriods = async () => {
  try {
    const data = await getPeriodsApi({ all: true })
    const periods: DropdownParams[] = []
    for (const p of data.periods) {
      periods.push({ key: p.Period, text: p.Period })
    }
    return periods
  } catch (error) {
    return []
  }
}
export const fetchBillingContracts = async () => {
  try {
    const { data } = await axios.get(`${host}/billing/contract`)
    const contracts: DropdownParams[] = [{ key: 'all', text: 'All' }]
    const contractRecords = data?.sort((a: IBillingContract, b: IBillingContract) => a?.customerName?.toLowerCase().localeCompare(b?.customerName?.toLowerCase() || ''))
    for (const c of contractRecords) {
      contracts.push({ key: c.number, text: `${c.customerName} (${c.number})` })
    }
    return contracts
  } catch (error) {
    return []
  }
}
export const fetchNewInvoiceData = (): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    try {
      const [periods, contracts] = await Promise.all([fetchBillingPeriods(), fetchBillingContracts()])
      const result: any = { periods, contracts }
      return result
    } catch (err) {
      console.log(err)
      const result: any = { periods: [], contracts: [] }
      return result
    }

  }
};

export const fetchInvoices = (
  params?: IInvoiceFilter,
  reset: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  let reqBody = params;
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        dispatch(isLoading(true));
        if (!reqBody) {
          const state = getSate()
          const invoiceFilter: IInvoiceFilter = state.web.invoice.invoiceFilter
          reqBody = { ...invoiceFilter }
        }
        const { data, totalCount } = await getInvoices(reqBody)
        dispatch(setInvoiceList(data, reset));
        dispatch(setInvoiceTotalCount(totalCount))
        dispatch(isLoading(false));
        resolve()
      } catch (e) {
        console.log(e);
      }
    })
  };
}

export const createInvoice = (params: ICreateInvoice): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  const reqBody = params;
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.post(`${host}/billing/invoice`, reqBody)
        // dispatch(fetchCycles())
        dispatch<any>(fetchInvoices({ ...getSate().web.invoice.invoiceFilter, page: 1 }))
        toast.success(res.data.msg, { autoClose: false, closeOnClick: true });
        dispatch(isLoading(false));
      } catch (e) {
        console.log(e)
        dispatch(isLoading(false));
      }
    });
  };
};

export const generateInvoice = (invoice: IInvoiceDocument): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API

  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.get(`${host}/billing/invoice/${invoice._id}`)
        dispatch(isLoading(false));
        if (res.status === 200) {
          toast.success('Invoice generated successfully');
          if (res.data.url) {
            window.open(res.data.url, '_blank');
          } else {
            downloadFileFromStream({ data: res.data, filename: generateInvoiceFilename(invoice), type: 'pdf' })
          }
        }
        if (res.data.invoice) dispatch(updateInvoiceList(res.data.invoice))
      } catch (e) {
        console.log(e)
        dispatch(isLoading(false));
      }
    });
  };
};

export const downloadInvoice = (invoice: IInvoiceDocument): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API

  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.get(`${host}/billing/invoice-url/${invoice._id}`)
        dispatch(isLoading(false));
        if (res.status === 200) {
          if (res.data.url) {
            window.open(res.data.url, '_blank');
          } else {
            downloadFileFromStream({ data: res.data, filename: generateInvoiceFilename(invoice), type: 'pdf' })
          }
        }
      } catch (e) {
        console.log(e)
        dispatch(isLoading(false));
      }
    });
  };
};
export const exportBillingInvoices =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const filters: IInvoiceFilter =
          getState().web.invoice.invoiceFilter;
        const data: any = await exportBillingInvoiceApi(filters)
        downloadFileFromStream({ data, filename: "inovices", type: 'xlsx' })

        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteInovice = (
  invoiceId: string,
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(isLoading(true));
        axios
          .delete(`${host}/billing/invoice/${invoiceId}`)
          .then((res) => {
            dispatch(isLoading(false));
            if (res.status === 200) {
              toast.success(res.data.msg);
              dispatch(deleteBillingInvoice(invoiceId))
            }
          })
          .catch((err) => {
            if (err?.response?.status === 404) {
              toast.error(err.response.data.msg);
            }
            dispatch(isLoading(false));
          });
      } catch (e) {
        console.log(e);
      }
    });
  };
}
