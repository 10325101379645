import { Dropdown, Stack, TextField } from "@fluentui/react";
import _ from "lodash";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { changeFilters, fetchAllBlogs } from "../../../store/admin/blog/action";
import { IBlogs } from "../../../store/admin/blog/reducers";
import { BlogCategories, BlogRegion, Languages, Sites } from "../common/common";
import { StringMap } from "../common/types";

const BlogFilters = (props: BlogFilterProps) => {
  const dispatch = useDispatch();
  const { blogFilter } = useSelector<RootState, IBlogs>(
    (state) => state.web.blogs
  );
  const setFilters = (filters: StringMap) => dispatch(changeFilters(filters));
  const stackTokens = { childrenGap: 30 };

  const handleChange = (key: string, value: string | undefined) => {
    const newFilters = { ...blogFilter, [key]: value, page: 1 };
    setFilters(newFilters);
    handleUpdateFilters(newFilters);
  };

  const handleUpdateFilters = useCallback(
    (filters: any) => {
      dispatch(fetchAllBlogs(filters));
    },
    []
  );

  return (
    <Stack tokens={stackTokens}>
      <Stack.Item align="center">
        <Stack horizontal horizontalAlign="center" tokens={stackTokens}>
          <p style={{ marginTop: "1rem" }}></p>
        </Stack>
        <Stack horizontal horizontalAlign="center" tokens={stackTokens}>
          <TextField
            label="Title"
            placeholder="Blog Title"
            type="text"
            onChange={(_, value) => handleChange("title", value!)}
            value={blogFilter.title}
          />
          <TextField
            label="Keywords"
            placeholder="Keywords"
            type="text"
            onChange={(_, value) => handleChange("keyword", value!)}
            value={blogFilter.keyword}
          />
          <Dropdown
            label="Live"
            style={{ minWidth: 100 }}
            selectedKey={
              blogFilter.live === undefined || blogFilter.live === "all"
                ? "all"
                : blogFilter.live === "true"
                  ? "true"
                  : "false"
            }
            options={[
              { key: "all", text: "All" },
              { key: "true", text: "True" },
              { key: "false", text: "False" },
            ]}
            onChange={(_, option) =>
              handleChange("live", option?.key?.toString() || "all")
            }
            className="ms-Grid-col"
          />
          <Dropdown
            label="Region"
            style={{ minWidth: 100 }}
            selectedKey={blogFilter.country || "all"}
            options={BlogRegion}
            onChange={(_, option) =>
              handleChange("country", option?.key?.toString() || "all")
            }
            className="ms-Grid-col ms-lg2"
          />
          <Dropdown
            label="Language"
            style={{ minWidth: 200 }}
            selectedKey={blogFilter.lang || "all"}
            options={Languages}
            onChange={(_, option) =>
              handleChange("lang", option?.key?.toString() || "all")
            }
            className="ms-Grid-col"
          />
          <Dropdown
            label="Category"
            style={{ minWidth: 100 }}
            selectedKey={blogFilter.category || "all"}
            options={BlogCategories}
            onChange={(e, option) => {
              handleChange("category", option?.key?.toString() || "all");
            }}
          />
          {/* <Dropdown
            label="Sites"
            style={{ minWidth: 100 }}
            selectedKey={blogFilter.sites || "all"}
            options={Sites}
            onChange={(_, option) =>
              handleChange("sites", option?.key?.toString() || "all")
            }
            className="ms-Grid-col"
          /> */}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

interface BlogFilterProps { }

export default BlogFilters;
