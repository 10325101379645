import React, { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../context/socket";

function CoreLogs() {
  const { coreSocket } = useContext(SocketContext);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [logs, setLogs] = useState<string[]>([]);
  const logPrefixMatcher =
    /(((20[012]\d|19\d\d)|(1\d|2[0123]))-((0[0-9])|(1[012]))-((0[1-9])|([12][0-9])|(3[01])).(..):(..):(..).(\[.+\].+:))/gm;
  const handleLogs = (data: any) => {
    setLogs((logs) => [...logs, ...data.split("\n")]);
  };

  useEffect(() => {
    if (bottomRef && bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [logs]);

  useEffect(() => {
    coreSocket.emit("REQUEST_LOGS", "LOGS");
    coreSocket.on("SERVER_LOGS", handleLogs);
    return () => {
      coreSocket.off("SERVER_LOGS");
    };
  }, []);

  return (
    <div className="logs-container">
      {logs.map((log, i) => {
        const matchedGroup = logPrefixMatcher.exec(log);
        return (
          <div key={i}>
            {/* <b>{matchedGroup ? matchedGroup[0] : ""}</b>
            {log.substring(matchedGroup ? matchedGroup[0].length : 0)} */}
            <p>{log}</p>
            <br />
          </div>
        );
      })}
      <div key={"dummy"} ref={bottomRef} />
    </div>
  );
}

export default CoreLogs;
