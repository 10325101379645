import { ChoiceGroup, Dropdown, IDropdownOption, ITag, TagPicker, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchTasks, setTaskFilter } from '../../../store/admin/task/action';
import { ITaskState } from '../../../store/admin/task/reducer';
import { getUsers } from '../../../store/client';
import { comboBoxStyles } from '../common/common';
import { StringMap } from '../common/types';
import { TaskStatus, TaskTypes } from './common';

const TaskFilters = (props: TaskFilterProps) => {
    const dispatch = useDispatch()
    const { taskFilter }: ITaskState = useSelector<RootState, ITaskState>((state: { web: { task: ITaskState; }; }) => state.web.task)
    const [owners, setOwners] = useState<ITag[]>([])
    const handleChange = async (key: string, value: string | undefined) => {
        const params = { ...taskFilter, [key]: value, page: 1 };
        dispatch(setTaskFilter(params));
        handleUpdateFilters(params)
    }

    useEffect(() => {
        return () => {
            if (props.taskFilter) {
                dispatch(setTaskFilter(props.taskFilter));
                handleUpdateFilters(props.taskFilter)
            }
        };
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUpdateFilters = useCallback((filters: any) => { dispatch(fetchTasks(filters)) }, [])


    const handleGetUsers = async (
        filter: string,
    ): Promise<ITag[]> => {
        try {
            const users = await getUsers({ userName: filter })
            const selectedMap: { [key: string]: boolean } = {};
            return users
                .filter(user => !selectedMap[user.userName])
                .map(user => {
                    return {
                        key: user.userName,
                        name: user.userEmail,
                        data: user,
                    }
                })
        } catch (error) {
            return [] as ITag[]
        }

    }

    const handleSelectUser = async (items?: ITag[]) => {
        //if (!items) return;
        handleChange('owner', items ? items[0]?.name : '')
        setOwners(items || [])
    }
    const { status, name, taskType } = taskFilter

    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Name"
                    placeholder='Task Name'
                    type='text'
                    onChange={(e, value) => handleChange('name', value)}
                    value={name!}
                    className="ms-Grid-col ms-lg4"
                />
                {!props.hidden?.includes('taskType') &&
                    <ChoiceGroup
                        className="inlineflex ms-Grid-col ms-lg4"
                        label="TaskType"
                        selectedKey={taskType || "all"}
                        onChange={(e, option) => handleChange("taskType", option?.key)}
                        options={TaskTypes}
                    />
                }
                <Dropdown
                    label="Status"
                    placeholder="Status"
                    selectedKey={status || 'all'}
                    onChange={(e, item) => handleChange('status', item?.key?.toString() || 'all')}
                    options={TaskStatus as IDropdownOption[]}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg3"
                />
            </div>
            <div className="ms-Grid-row">
                <TagPicker
                    styles={{ root: { marginTop: 5 } }}
                    inputProps={{ placeholder: 'Owner search' }}
                    onResolveSuggestions={handleGetUsers}
                    selectedItems={owners}
                    itemLimit={1}
                    selectionAriaLabel={'Selected members'}
                    onChange={handleSelectUser}
                    className="ms-Grid-col ms-lg6 margin-top-md"
                />
            </div>
        </div>
    );
}

interface TaskFilterProps {
    taskFilter?: StringMap;
    hidden?: string[]
}

export default TaskFilters;

