import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { fetchBillingReadings, fetchContracts, fetchPeriods, fetchSites } from "../../../store/admin/billing/action";
import ReadingList from './ReadingList';
import Loader from '../common/Loader';
import ReadingFilter from './ReadingFilter';

const Index = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSites())
    dispatch(fetchPeriods())
    dispatch(fetchContracts())
    dispatch(fetchBillingReadings())
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'relative' }}>
      <Loader />
      <header className="table-header">
        <h1>Billing Readings</h1>
        <ReadingFilter />
      </header>
      <ReadingList />
    </div>
  )
}
export default Index;

