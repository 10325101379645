import { DefaultButton, Pivot, PivotItem, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { ITaskType } from "@solarforschools/sfs-core/dist/taskType/types"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import { getUserSkillsApi } from "../../../store/client/userSkill"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { onRenderPivoteItemLink } from "../common/PivotItemRenderLink"
import { getGeneralFields, initTaskType, TaskTypeTabs, validateTaskType } from "./taskTypeHelper"
import slugify from "slugify"
import { createUpdateTaskType } from "../../../store/admin/taskType/action"
import { IUserSkill } from "@solarforschools/sfs-core/dist/user/types"


export const openTaskTypeModal = (props?: any) => {
  return new Promise<ITaskType | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (taskType?: ITaskType) => {
      resolve(taskType);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add Edit TaskType"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditTaskTypeDialog
            {...props}
            onSave={(taskType: ITaskType | undefined) => callback(taskType)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditTaskTypeDialog = (props: TaskTypeProps) => {
  const dispatch = useDispatch();

  const [taskType, setTaskType] = useState<ITaskType>(_.cloneDeep(props.taskType || initTaskType as ITaskType))
  const [errors, setErrors] = useState<any>({});
  const [tabErrors, setTabErrors] = useState({ ...TaskTypeTabs });
  const [skills, setSkills] = useState<IUserSkill[]>([] as IUserSkill[])

  useEffect(() => {
    const loadData = async () => {
      const { userSkills: skills } = await getUserSkillsApi({}) || [];
      setSkills(skills)
    }
    loadData()
  }, [])

  const generalFields = useMemo(() => getGeneralFields({ skills, taskType }), [skills, taskType]);

  const handleOnSave = async () => {
    const errors = await validateTaskType(taskType);
    if (Object.keys(errors).length) {
      // const tabErrors = getTabErrors(errors);
      setErrors(errors);
      // setTabErrors(tabErrors);
      return;
    }
    await dispatch(createUpdateTaskType(taskType))
    props.onSave(taskType)
  }

  const onPivotLinkClick = async (props: any) => { };


  const handleOnChangeInput = (key: string, value: any) => {
    // if (errors[key]) {
    //   const err = { ...errors };
    //   delete err[key];
    //   //const tabErrors = getTabErrors(err);
    //   setErrors(err);
    //   //setTabErrors(tabErrors);
    // }
    let data: ITaskType = _.cloneDeep(taskType);
    if (!data.skillsRequired) data.skillsRequired = []
    switch (key) {
      case 'skillsRequired':
        if (value?.selected) {
          if (!data.skillsRequired.find((p: any) => p === value?.key)) {
            data.skillsRequired.push(value?.key)
          }
        } else {
          data.skillsRequired = data.skillsRequired.filter((p: any) => p !== value?.key)
        }
        setTaskType(data)
        break;
      case 'name':
        if (!data._id) {
          data.slug = slugify(value, {
            replacement: '-',
            remove: /[*+~.()'"!:@]/g,
            lower: true,
            strict: true,
            trim: true
          })
        }
        setTaskType(_.set({ ...data }, key, value))
        break;
      default:
        setTaskType(_.set({ ...data }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <Pivot linkSize="large" onLinkClick={(item) => onPivotLinkClick(item)}>
          <PivotItem
            key="general"
            headerText="General"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.general)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={generalFields}
                data={taskType}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
          </PivotItem>
          <PivotItem
            key="inputs"
            headerText="Inputs"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.inputs)}
          >
          </PivotItem>
          <PivotItem
            key="tools"
            headerText="Tools"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.tools)}
          >
          </PivotItem>
          <PivotItem
            key="outputs"
            headerText="Outputs"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.outputs)}
          >
          </PivotItem>
        </Pivot>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface TaskTypeProps {
  onCancel?: () => void;
  onSave: (taskType: ITaskType) => void;
  taskType?: ITaskType;
}

export default openTaskTypeModal;
