import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { IBondOffer } from '@solarforschools/sfs-core/dist/funder/bond-offer/types';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { CheckboxVisibility, CommandBar, IColumn, ICommandBarItemProps, ScrollablePane, ScrollbarVisibility, SelectionMode } from '@fluentui/react';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { IBondOfferState } from './../../../../store/admin/funder/bondOffer/reducer';
import { setBondOfferFilter, getBondOffers, deleteBondOffer, genBondOfferTransaction } from './../../../../store/admin/funder/bondOffer/action';
import { getBondOfferColumns } from './BondOfferHelper';
import { OpenAddBondOfferModal } from './AddBondOfferDialog';
import { isLoading } from '../../../../store/admin/action';
import { reGenrateCertificate } from '../../../../store/admin/funder/bond/action';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import { toast } from 'react-toastify';
import { OpenGenCertificateModal } from "./GenerateCertificateDialog"
import { OpenBondOfferRepaymentModal } from "./BondOfferRepayment"
import { getBondEmailPreview, getBondsApi, sendBondEmailApi } from '../../../../store/client/funder/bond';
import { OpenEmailPreviewModal } from '../../common/EmailPreviewDialog';
import Pagination from '../../common/Pagination';
import { IBondOfferFilter } from './types';

const BondOfferList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { bondOffers, totalCount, bondOfferFilter }: IBondOfferState = useSelector<RootState, IBondOfferState>((state: RootState) => state.web.bondOffer)

  const handleOnDelete = async (data: IBondOffer) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete BondOffer",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete bond offer?`,
      },
    });
    if (confirm) dispatch(deleteBondOffer(data._id!?.toString()));
  };

  const handleCreateTransaction = async (data: IBondOffer) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Create Transactions",
        closeButtonAriaLabel: "Close",
        subText: `Do you want to create Transaction for all bond in this bond offer?`,
      },
      confirmBtnText: "Create",
    });
    if (confirm) dispatch(genBondOfferTransaction(data._id!?.toString()));
  };

  async function handleEditbondOffer(bondOffer: IBondOffer) {
    const result = await OpenAddBondOfferModal({
      bondOffer,
      onCancel: () => null,
      onSave: () => null,
    });
    // if (result) {
    //   await dispatch(createUpdateSchool(result))
    //   const updated = [...props.schools];
    //   const index = props.schools.findIndex(x => x.slug === school.slug);
    //   updated[index] = result;
    //   props.onUpdated(updated);
    // }
  }
  async function handleGenCert(bond: IBond) {
    await dispatch(reGenrateCertificate(bond))
  };

  const GenCertificate = async (item: IBondOffer) => {
    try {
      await OpenGenCertificateModal({
        params: {
          offerId: item._id,
          title: 'Generate Certificate',
          btnTitle: 'Generate',
          sendAction: handleGenCert
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }

  async function handleRepayment(item: IBondOffer) {
    await OpenBondOfferRepaymentModal({
      params: {
        bondOffer: item
      }
    })
  };

  const openEmailPreview = async (item: IBondOffer, type = "bond-welcome") => {
    try {
      dispatch(isLoading(true))
      const { bonds } = await getBondsApi({ bondOffer: item._id?.toString(), status: "Open", all: true })
      if (!bonds || !bonds.length) {
        toast.error(`No Bond Found with offer "${item.name}"`)
        return
      }
      const { html } = await getBondEmailPreview(bonds[0]._id!.toString(), { type })
      dispatch(isLoading(false))
      await OpenEmailPreviewModal({
        params: {
          data: [...bonds],
          title: 'Send Certificate',
          btnTitle: 'Send',
          html,
          sendAction: (bond: any) => sendBondEmailApi(bond._id as any, { type })
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }

  const subMenu = (bondOffer: IBondOffer) => {
    const items: any[] = []
    if (bondOffer.status === "Open") {
      items.push({
        key: 'transaction',
        text: 'Create Transactions',
        ariaLabel: "Create Transactions for all bonds",
        iconProps: { iconName: 'M365InvoicingLogo' },
        onClick: (e: any) => {
          e?.preventDefault();
          handleCreateTransaction(bondOffer)
        },
      })
      items.push({
        key: 'genCert',
        text: 'Generate Certificate',
        ariaLabel: "Generate Certificates for all bonds",
        iconProps: { iconName: 'DrillDown' },
        onClick: (e: any) => {
          e?.preventDefault();
          GenCertificate(bondOffer)
        },
      })
      items.push({
        key: 'certificate',
        text: 'Send Certificate',
        ariaLabel: "Send Certificate to bond holders",
        iconProps: { iconName: 'Mail' },
        onClick: (e: any) => {
          e?.preventDefault();
          openEmailPreview(bondOffer, "bond-welcome")
        },
      })
      items.push({
        key: 'repayment',
        text: 'Re Payment',
        ariaLabel: "Re Payment",
        iconProps: { iconName: 'Money' },
        onClick: (e: any) => {
          e?.preventDefault();
          handleRepayment(bondOffer)
        },
      })
    }
    return items
  }
  const getActions = (bondOffer: IBondOffer, actions: any, extraActions?: ICommandBarItemProps[]) => {
    let listActions: ICommandBarItemProps[] = []
    if (bondOffer.status === "Open") listActions.push(
      {
        key: 'newItem',
        text: '',
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'CollapseMenu' },
        subMenuProps: {
          items: [...subMenu(bondOffer)],
        },
      })
    for (const action of actions) {
      if (action === 'edit') listActions.push({
        key: 'edit',
        text: '',
        iconProps: { iconName: 'Edit' },
        onClick: (e) => {
          e?.preventDefault();
          handleEditbondOffer(bondOffer);
        },
      })
      if (action === 'delete') listActions.push({
        key: 'delete',
        text: '',
        iconProps: { iconName: 'Trash' },
        onClick: (e) => {
          e?.preventDefault();
          handleOnDelete(bondOffer);
        },
      })
    }
    if (extraActions) {
      for (const action of extraActions) {
        listActions.push({
          key: action.key,
          text: '',
          iconProps: { iconName: action.icon },
          onClick: (e: any) => {
            e?.preventDefault();
            action?.action(bondOffer);
          },
        })
      }
    }
    return listActions
  }
  const headerRow: IColumn[] = [...getBondOfferColumns, {
    key: "action",
    name: "Action",
    fieldName: "-",
    minWidth: 200,
    onRender: (bondOffer: IBondOffer) => {
      return (
        <CommandBar
          items={[...getActions(bondOffer, actions, [])]}
          ariaLabel="Actions"
          primaryGroupAriaLabel="Actions"
        />
      )
    },
  }]
  const [columns] = useState<IColumn[]>(headerRow)




  const handleOnSort = (field: string, order: string) => {
    const params = { ...bondOfferFilter, sortField: field, sortOrder: order };
    dispatch(setBondOfferFilter(params));
    dispatch(getBondOffers(params))
  }
  const loadNextPage = (p: IBondOfferFilter) => {
    dispatch(setBondOfferFilter({ ...bondOfferFilter, ...p }))
    dispatch(getBondOffers())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={bondOffers}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={bondOffers.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={bondOfferFilter?.page!}
            pageSize={bondOfferFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default BondOfferList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

