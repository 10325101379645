import axios from "axios";
import { generateQueryString } from "../../../utils/util";
import { host } from "../../config";

let GetPipedriveOrgAPiAbortController: AbortController

export const getPipedriveOrgApi = (params: any): Promise<any> => {
  if (GetPipedriveOrgAPiAbortController) GetPipedriveOrgAPiAbortController.abort()
  GetPipedriveOrgAPiAbortController = new AbortController()
  const queryStr = generateQueryString({ ...params });
  return axios.get(`${host}/pipedrive/organization?${queryStr}`, { signal: GetPipedriveOrgAPiAbortController.signal }).then((res) => res.data);
};

export const getPipedriveOrgByIdApi = (orgId: number): Promise<any> => {
  return axios.get(`${host}/pipedrive/organization/${orgId}`).then((res) => res.data);
};
