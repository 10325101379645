import { DetailsList, IColumn, SelectionMode } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import { IStage } from "@solarforschools/sfs-core/dist/school/types";
import moment from 'moment';


export const StageListing = ({
    stage
}: Props) => {
    const headerRow: IColumn[] = [
        {
            key: "Name",
            name: "name",
            fieldName: "name",
            minWidth: 50,
            maxWidth: 100,
        },
        {
            key: "time",
            name: "Date",
            fieldName: "date",
            minWidth: 150,
            onRender: (item: { date: moment.MomentInput; }) => {
                return (
                    moment(item.date).format('YYYY-MM-DD')
                )
            }
        },
        {
            key: "active",
            name: "Active",
            fieldName: "active",
            minWidth: 70,
        },
    ];
    return (

        <div>
            <DetailsList
                items={stage || []}
                columns={headerRow}
                selectionMode={SelectionMode.none}
                compact={true}
            />
        </div>

    );
};

interface OwnProps {
    stage: IStage[];
}

type Props = OwnProps;

