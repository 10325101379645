import { useContext, useEffect, useState } from "react";
import { Chart, Line } from "react-chartjs-2"
import { CardContext, CardContextType } from "../../layouts";
import { quarters, conversionInY, tooltipConversion } from "../../utils/tileHelper";

export const FrontContent = () => {


    const { dealByOfferSent } = useContext(CardContext) as CardContextType;
    const [chartData, setChartData] = useState<{ count: number[], labels: string[], noOfSchools: number[] }>({ count: [], labels: [], noOfSchools: [] })


    useEffect(() => {
        const firstFourQuarterData = dealByOfferSent?.slice(0, 4)?.reverse()
        const count = firstFourQuarterData?.map((elm) => elm.count)
        const chartLables = firstFourQuarterData?.map((elm) => {
            return `${(quarters as any)[elm.quarter]}-${elm.year}`
        })
        const noOfSchools = firstFourQuarterData?.map((elm) => elm.noOfSchools)
        setChartData({ count: count as number[], labels: chartLables as string[], noOfSchools: noOfSchools as number[] })
    }, [dealByOfferSent])


    const data = {
        labels: chartData.labels,
        datasets: [
            {
                type: 'line' as const,
                label: "No of schools",
                borderWidth: 1,
                data: chartData.noOfSchools,
                backgroundColor: "#243f61",
                borderColor: "#909090",
                hoverBackgroundColor: "#243f61",
            },
            {
                type: 'bar' as const,
                label: "Offers sent",
                borderWidth: 0,
                data: chartData.count,
                backgroundColor: "#ffcb30",
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                title: {
                    display: false,
                }
            }
        },
        barThickness: 20,
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 10,
                    border: "red",
                    font: {
                        size: 12
                    }
                },
                align: 'end' as "end" | "start" | "center" | undefined
            },
            title: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const datasetIndex = context.datasetIndex;
                        const dataset = context.chart.data.datasets[datasetIndex];
                        const yValue = context.parsed.y;
                        return `${dataset.label}: ${yValue} `;
                    },
                },
            }

        }
    };

    return (
        <>

            <div className="flex-column-center">

                <div style={{ height: "150px", width: "270px" }}>
                    <Chart type="bar" data={data} options={options} />
                </div>
            </div>

        </>
    )

}

export const BackContent = () => {


    const { dealByOfferSent } = useContext(CardContext) as CardContextType;
    const [chartData, setChartData] = useState<{ data: number[], labels: string[] }>({ data: [], labels: [] })


    useEffect(() => {
        const firstFourQuarterData = dealByOfferSent?.slice(0, 4)?.reverse()
        const chartData = firstFourQuarterData?.map((elm) => elm.size * 1000) // multiply by 1000 to convert to Wp
        const chartLables = firstFourQuarterData?.map((elm) => {
            return `${(quarters as any)[elm.quarter]}-${elm.year}`
        })
        setChartData({ data: chartData as number[], labels: chartLables as string[] })
    }, [dealByOfferSent])


    const data = {
        labels: chartData.labels,
        datasets: [
            {
                label: "Size",
                data: chartData.data,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56"
                ],
                borderColor: "#909090",
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56"
                ],
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Size'
                },
                ticks: {
                    callback: (value: any) => conversionInY(value, ["Wp", "KWp", "MWp", "GWp", "TWp", "PWp"])
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return tooltipConversion(context, ["Wp", "KWp", "MWp", "GWp", "TWp", "PWp"])
                    },
                },
            }
        }
    };

    return (
        <>
            <div className="flex-column-center" style={{ marginTop: "1rem" }}>
                <div style={{ height: "130px", width: "250px" }}>
                    <Line data={data} options={options} />
                </div>
            </div>

        </>
    )

}
