import {
    DefaultButton,
    PrimaryButton,
    Stack,
    Sticky,
    StickyPositionType,
} from "@fluentui/react";
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility, SelectionMode } from "office-ui-fabric-react";
import { CSSProperties, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "../../../store";
import ModalDialog from "../common/ModalDialog";
import { openGenerateInvoice } from "./GenerateInvoiceDialog";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { INVOICE_DATA, getInvoiceGeneralFields, getSofsExpenseColumn, invoiceDialogLayout, validateInvoiceData } from "./Helper";
import { StringMap } from "../common/types";
import DetailsListWapper from "../../common/DetailsListWapper";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { IFinalResult, ISofsExpenses } from "./types"


export const openGetInvoiceDataDialog = (sofsExpenses: ISofsExpenses[]) => {
    return new Promise<any[] | undefined>((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget);
        const callback = (result?: any) => {
            resolve(result);
            ReactDOM.unmountComponentAtNode(mountTarget);
            mountTarget.remove();
        };

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Tax Invoice"}
                    onDismiss={() => callback(undefined)}
                >
                    <GetInvoiceDataDialog
                        sofsExpenses={sofsExpenses}
                        onCancel={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        );
    });
};

interface IProps {
    sofsExpenses: ISofsExpenses[]
    onCancel: () => void
}

const GetInvoiceDataDialog = (props: IProps) => {

    const tableLayout: CSSProperties = { position: "relative", minHeight: "40vh", maxWidth: "400px", margin: "0 auto" }
    const headerStyle: CSSProperties = { textAlign: "center", margin: "1rem" }
    const { sofsExpenses } = props

    const [invoiceData, setInvoiceData] = useState<Record<string, string | Date>>(INVOICE_DATA)
    const [errors, setErrors] = useState<StringMap>({})
    const [columns] = useState(getSofsExpenseColumn())



    function buildSofsExpenseWithInvoiceData() {
        let result = [], finalResult: IFinalResult = { invoice: [], sofsExpenses: [] }
        for (const key in invoiceData) {
            result.push({ name: key, value: invoiceData[key] })
        }
        finalResult.invoice = result
        finalResult.sofsExpenses = sofsExpenses
        return finalResult
    }


    const handleOnNext = async () => {

        const errors: StringMap = validateInvoiceData(invoiceData)
        setErrors(errors)
        if (Object.keys(errors).length) return;

        const finalResult: IFinalResult = buildSofsExpenseWithInvoiceData()

        const result: undefined | "cancel" | "close" = await openGenerateInvoice(finalResult)
        if (result === undefined || result === "cancel") return
        else if (result === "close") props.onCancel()
        else return
    }


    const handleChangeInput = (key: string, value: any) => {
        let tempValue;

        if (typeof value === "string") tempValue = value.trim()
        else tempValue = value

        if (tempValue || tempValue.length > 0) setErrors({ ...errors, [key]: "" })
        setInvoiceData({ ...invoiceData, [key]: value })
    }

    const renderSofsExpensesVariable = (
        <>
            <h3 style={headerStyle}>
                SOFS Expenses
            </h3>
            <div style={tableLayout}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsListWapper
                        columns={columns}
                        onRenderDetailsHeader={DetailListStickyHeader}
                        selectionMode={SelectionMode.none}
                        compact={true}
                        items={sofsExpenses}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </ScrollablePane>
            </div>

        </>
    )
    const renderInvoiceDataForms = (
        <>
            <h3 style={headerStyle}>
                Invoice Form
            </h3>
            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row'>
                    <DynamicForm
                        fields={getInvoiceGeneralFields}
                        onChange={handleChangeInput}
                        data={invoiceData}
                        errors={errors}
                    />
                </div>
            </div>
        </>
    )


    return (
        <div>

            {renderInvoiceDataForms}

            {sofsExpenses.length > 0 && renderSofsExpensesVariable}

            <Sticky stickyPosition={StickyPositionType.Footer}>
                <div style={invoiceDialogLayout}>
                    <Stack horizontal horizontalAlign="center" className="margin-top-xs">
                        <Stack.Item>
                            {
                                sofsExpenses.length > 0 && <PrimaryButton
                                    text="Next"
                                    onClick={handleOnNext}
                                    className="btn-primary"
                                />
                            }
                            <DefaultButton onClick={props.onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </div>
            </Sticky>
        </div>
    );
};

export default GetInvoiceDataDialog;

