import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFiles } from '../../../store/admin/generateReport/action'
import { RootState } from '../../../store'
import { IGenerateReport } from '../../../store/admin/generateReport/reducers'
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility, SelectionMode, Text } from '@fluentui/react'
import DetailsListWapper from '../../common/DetailsListWapper'
import { getFileColumns } from './helper'
import { DetailListStickyHeader } from '../common/DetailListStickyHeader'
import { IFileDetails } from './types'

const displayLimit = 50 // Fetch file limit

interface IDisplayFilesProps {
    reportType: "project" | "school"
}

const DisplayFiles = React.memo((props: IDisplayFilesProps) => {
    const { reportType } = props
    const dispatch = useDispatch()

    const { fileDetails } = useSelector<RootState, IGenerateReport>((state: RootState) => state.web.generateReport)
    const [isLoadMore, setIsLoadMore] = useState(true)
    const [page, setPage] = useState(1)
    const [columns] = useState(getFileColumns())
    const [items, setItems] = useState<any>([])

    useEffect(() => {
        dispatch(fetchFiles(page, reportType))
        //-> clear all the files
        // return () => {
        //     setItems([])
        // }
    }, [])



    useEffect(() => {
        setItems((fileDetails as IFileDetails).files)
        if (((fileDetails as IFileDetails).totalPages === 0) || ((fileDetails as IFileDetails).totalPages === (page * displayLimit)) || (fileDetails as IFileDetails).totalPages < (page * displayLimit)) {
            setIsLoadMore(false)
        } else {
            setIsLoadMore(true)
        }
    }, [fileDetails])

    useEffect(() => {
        if ((fileDetails as IFileDetails).totalPages >= page)
            dispatch(fetchFiles(page, reportType))
    }, [page])


    const loadNextPage = () => {
        setPage((prevPage) => prevPage + 1)
    }
    const handleSort = (field: string, order: string) => {
        if (field !== "date") return
        let result = []
        const inData = (fileDetails as IFileDetails).files
        if (order === "asc") {
            result = inData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        } else {
            result = inData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        }
        setItems(result)
    }

    if (items?.length === 0) {
        return (
            <div className='text-center' style={{ marginTop: "2rem" }}>
                <Text variant='mediumPlus'> No Files Found </Text>
            </div>)
    }

    return (
        <>
            {
                items?.length > 0 && (
                    <>
                        <div
                            className='text-center generated-doc-line' style={{ margin: "1rem 0 1rem 0", color: "#243f61" }}>
                            <Text variant='large'>
                                Generated Documents ({reportType})
                            </Text>
                        </div>
                        <div>
                            <Text variant='medium'>
                                Displaying  {(fileDetails as IFileDetails)?.files?.length}  / {(fileDetails as IFileDetails)?.totalPages}
                            </Text>
                        </div>
                        <div className='data-list-container' >
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                <DetailsListWapper
                                    items={items}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    onRenderDetailsHeader={DetailListStickyHeader}
                                    compact={true}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    canLoadMore={isLoadMore}
                                    onRequestLoadMore={loadNextPage}
                                    onSort={(field, order) => handleSort(field, order)}
                                />
                            </ScrollablePane>
                        </div>
                    </>
                )
            }
        </>
    )
})

export default DisplayFiles