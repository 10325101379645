import React from "react";

const Select = ({ label, options, value, onChange }: selectProps) => {
  return (
    <div>
      <label>
        {label}
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default Select;

type options = {
  value: string;
  label: string;
};
export interface selectProps {
  label: string;
  options: options[];
  value: string;
  onChange?: (event: any) => void;
}
