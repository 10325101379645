import { IBillingPeriod } from "@solarforschools/sfs-core/dist/billing/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

let GetPeriodAPiAbortController: AbortController

export const getPeriodsApi = (query: any): Promise<any> => {
  if (GetPeriodAPiAbortController) {
    GetPeriodAPiAbortController.abort(); // Tell the browser to abort request
  }
  GetPeriodAPiAbortController = new AbortController();
  return axios
    .get(
      `${host}/billing/period?${generateQueryString(
        query as ParsedUrlQueryInput
      )}`, { signal: GetPeriodAPiAbortController.signal }
    )
    .then((res) => res.data);
};

export const createPeriodApi = (period: IBillingPeriod): Promise<any> => {
  return axios.post(`${host}/billing/period`, period).then((res) => res.data);
};

export const updatePeriodApi = (period: IBillingPeriod): Promise<any> => {
  return axios
    .put(`${host}/billing/period/${period._id}`, period)
    .then((res) => res.data);
};

export const deletePeriodApi = (period: IBillingPeriod): Promise<any> => {
  return axios
    .delete(`${host}/billing/period/${period._id}`)
    .then((res) => res.data);
};
