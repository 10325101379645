import { Dropdown, IDropdownOption, Stack, Text } from '@fluentui/react'
import { ActionButton } from '@fluentui/react/lib/Button'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectManagers, getProjects, getProjectWorkflows, setProjectsFilter } from '../../../store/admin/projects/action'
import Loader from '../common/Loader'
import { openProjectDialog } from './ProjectDialog'
import ProjectFilters from './ProjectFilters'
import ProjectList from './ProjectList'
import { createProject } from '../../../store/client'
import { RootState } from '../../../store'
import { IProjectState } from '../../../store/admin/projects/reducer'
import { initProject } from './ProjectHelper'
import { projectNameChange } from './helper'
import { PageSize } from '../common/common'

const ProjectScreen = (props: ProjectScreenProps) => {
  const dispatch = useDispatch()
  const { projects, count, workflows, projectFilter } = useSelector<RootState, IProjectState>(state => state.web.project)


  useEffect(() => {
    dispatch(getProjects())
    dispatch(getProjectManagers())
    dispatch(getProjectWorkflows())
  }, [])

  const handleCreateNewProject = async () => {
    try {
      const project = await openProjectDialog({ project: { ...initProject, projectType: { _id: workflows[0]._id!, name: workflows[0].name } } })
      if (!project) return;
      const res = await createProject(project);
      dispatch(getProjects())
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...projectFilter, page: 1, pageSize: size }
    dispatch(setProjectsFilter(filters))
    dispatch(getProjects())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>{projectNameChange["Projects"]}</h1>
        <ProjectFilters />
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{ width: '100%' }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {count > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={projectFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {count}
              </>}
          </span>
          <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleCreateNewProject}>
            {projectNameChange["Add Project"]}
          </ActionButton>
        </Stack>
      </header>
      <ProjectList />
    </div>
  )
}


interface ProjectScreenProps {

}

export default ProjectScreen