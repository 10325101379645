import React from 'react'
import { Bar, Chart } from 'react-chartjs-2'
export interface IStackedColumnWithLineChartData {
    type?: 'line' | 'bar';
    borderColor?: string,
    borderWidth?: number,
    hoverBackgroundColor?: string,
    label: string;
    data: number[];
    backgroundColor: string;
}

interface IChartProps {
    chartLabels?: string[]
    chartData?: IStackedColumnWithLineChartData[]
    value?: number
}

export const StackedColumnWithLineChart = (props: IChartProps) => {
    const { chartLabels, chartData } = props

    if (chartData?.length === 0 || chartLabels?.length === 0) return <></>

    const options = {
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
                //! labels
                // labels: {
                //     boxWidth: 10,
                //     border: "red",
                //     font: {
                //         size: 11
                //     }
                // },
                // align: 'start' as "end" | "start" | "center" | undefined
            },
        },
        barThickness: 20,
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const labels = chartLabels;

    const data = {
        labels,
        datasets: chartData as IStackedColumnWithLineChartData[]
    };


    return (
        <div>
            <div className="flex-column-center">
                <div style={{ height: "160px", width: "280px", paddingTop:"0.5rem" }}>
                    <Chart
                        type='bar'
                        data={data}
                        options={options}
                    />
                </div>
            </div>
        </div>
    )
}
