import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { IBondHolderState } from './../../../../store/admin/funder/bondHolder/reducer';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { getBondHolderColumns } from './BondHolderHelper';
import { CheckboxVisibility, IColumn, ScrollablePane, ScrollbarVisibility, SelectionMode } from '@fluentui/react';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { setBondHolderFilter, getBondHolders, deleteBondHolder } from './../../../../store/admin/funder/bondHolder/action';
import { openBondHolderModal } from './BondHolderDialog';
import { OpenEmailPreviewModal } from '../../common/EmailPreviewDialog';
import { getBondHolderEmailPreview, getBondHoldersApi, sendBondholderPaymentPreferenceEmailApi } from '../../../../store/client/funder/bondHolder';
import { isLoading } from './../../../../store/admin/action';
import { toast } from 'react-toastify';
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';
import Pagination from '../../common/Pagination';
import { IBondHolderFilter } from './types';

const BondHolderList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { bondHolders, totalCount, bondHolderFilter }: IBondHolderState = useSelector<RootState, IBondHolderState>((state: RootState) => state.web.bondHolder)

  const handleOnDelete = async (data: IBondHolder) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete BondHolder",
        closeButtonAriaLabel: "Close",
        subText: `Are you sure you want to delete this bondholder?`,
      },
    });
    if (confirm) dispatch(deleteBondHolder(data));
  };

  const handleOnEdit = async (data: IBondHolder) => {
    await openBondHolderModal({ bondHolder: data })
  };

  const openEmailPreview = async (item: IBondHolder) => {
    try {
      dispatch(isLoading(true))
      const { html } = await getBondHolderEmailPreview({ userName: item.userName })
      dispatch(isLoading(false))
      await OpenEmailPreviewModal({
        params: {
          data: [{ ...item }],
          title: 'Payment Preference',
          btnTitle: 'Send',
          html,
          sendAction: sendBondholderPaymentPreferenceEmailApi
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }

  const headerRow = getBondHolderColumns({
    actions: [
      {
        icon: 'PublicEmail',
        onClick: openEmailPreview,
        title: "Send activation email link to update account re-payment preferences",
        ariaLabel: "Send activation email link to update account re-payment preferences"
      },
      {
        icon: 'Edit',
        onClick: handleOnEdit,
        title: "Edit Bondholder",
        ariaLabel: "Edit Bondholder"
      },
      {
        icon: "Trash",
        onClick: handleOnDelete,
        title: "Dlete Bondholder",
        ariaLabel: "Delete Bondholder"
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...bondHolderFilter, sortField: field, sortOrder: order };
    dispatch(setBondHolderFilter(params));
    dispatch(getBondHolders(params))
  }
  const loadNextPage = (p: IBondHolderFilter) => {
    dispatch(setBondHolderFilter({ ...bondHolderFilter, ...p }))
    dispatch(getBondHolders())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={bondHolders}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={bondHolders.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={bondHolderFilter?.page!}
            pageSize={bondHolderFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default BondHolderList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

