import { useContext } from "react";
import { CardContext, CardContextType } from "../";
import { MdFlipCameraAndroid } from "react-icons/md";


interface IPlainFooterProps {
    Footer?: () => JSX.Element;
    isHaveFlipper?: boolean;
}
const PlainFooter = (props: IPlainFooterProps) => {
    const { setIsFlip, isflip } = useContext(CardContext) as CardContextType;
    const Footer = props?.Footer;
    const isHaveFlipper = props?.isHaveFlipper

    if (!Footer || !isHaveFlipper) return null;

    if (!isHaveFlipper)
        return (
            <div style={{ textAlign: 'center', margin: "1rem 0" }}>
                <Footer />
            </div>
        )

    const handleFlip = () => {
        setIsFlip(!isflip)
    }

    return <>
        <div className="card-flip">
            <div className="card-flip-inner">
                <div className="card-flip-front flex-row-center">
                    <Footer />
                    <div style={{ fontSize: "1.15rem", position: "absolute", bottom: 0, right: 0, paddingRight: "0.25rem", color: "#243f61", cursor: "pointer" }}>
                        <MdFlipCameraAndroid onClick={handleFlip} title="Flip Tile" />
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default PlainFooter;