import {
  ActionButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { EnvData, IEnvConfig } from "@solarforschools/sfs-core";
import { useState } from "react";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { getEnvConfigColumns } from "./EnvConfigHelper";
import DetailsListWrapper from "../../common/DetailsListWapper";
import { IEnvConfigState } from "../../../store/admin/envConfig/reducer";
import { openEnvConfigDialog } from "./EnvConfigDialog";
import { useDispatch, useSelector } from "react-redux";
import { updateEnvConfig } from "../../../store/admin/envConfig/actions";
import { isEqual } from "lodash";
import { RootState } from "../../../store";
import { IEnvConfigDataValue } from "@solarforschools/sfs-core";

const EnvConfigLists = () => {
  const data = useSelector<RootState, IEnvConfigState>(
    (state) => state.web.envConfig as IEnvConfigState
  );
  const { isLoading, envConfigList } = data;

  const envLength: number =
    envConfigList[0] && Object.keys(envConfigList[0].data).length;
  const dispatch = useDispatch();

  //Desired Nested Object format
  var convertedObj: IProps[] = [{ EnvName: "", EnvData: "" }];

  const envConfigObj: EnvData = envConfigList[0]?.data;

  //If data is there, then convert the object to array of objects In IProps format
  if (envConfigList && envConfigList[0]?.data) {
    convertedObj = Object.keys(envConfigObj).map((key) => ({
      EnvName: key,
      EnvData: envConfigObj[key as IEnvConfigDataValue],
    }));
  }

  //Open Modal window
  const handleEditEnvConfig = async () => {
    const openModalData = await openEnvConfigDialog(convertedObj);

    //if there is data from the modal then dispatch the modified object
    if (openModalData?.length) {
      //convert back to object from array of object
      var mapped = Object.assign(
        {},
        ...openModalData.map((item) => ({ [item.EnvName]: item.EnvData }))
      );
      const { id, _id, type } = envConfigList[0];
      const resData: IEnvConfig = { id, _id, type, data: mapped };
      const isSameAfterEdit = isEqual(resData, envConfigList[0]);

      // If not the same data from the modal, dispatch the updated data to updateEnvCOnfig redux thunk
      if (!isSameAfterEdit) dispatch(updateEnvConfig(resData));
    }
  };

  //Get the Column Values from getEnvConfigColumn helper function
  const [columns] = useState(getEnvConfigColumns());

  if (envConfigList.length === 0) return <></>

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        style={{ width: "85%", marginLeft: "1rem" }}
      >
        <Text>Environment Variables: {envLength}</Text>
        <ActionButton
          title={"Edit Hosts"}
          iconProps={{ iconName: "Edit" }}
          onClick={() => handleEditEnvConfig()}
        >
          Edit Environment
        </ActionButton>
      </Stack>
      <div
        className="data-list-container"
        style={{ maxWidth: "95%", margin: "0.25rem 1rem" }}
      >
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsListWrapper
            items={convertedObj}
            onRenderDetailsHeader={DetailListStickyHeader}
            columns={columns}
            selectionMode={SelectionMode.none}
            compact={true}
            isLoading={isLoading}
          />
        </ScrollablePane>
      </div>
    </>
  );
};

export default EnvConfigLists;

export interface IProps {
  EnvName: string;
  EnvData: string;
}
