import { toast } from "react-toastify";
import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import {
  IComboxBoxOptions,
  ISchoolReportData,
  ISchoolReportForm,
  SetDailyYields,
  SetInvoiceCycle,
  SetLiveSchool,
  SetLiveSchoolRegions,
  SetLiveSchoolTrusts,
  SetReportData,
  SetSchoolPvamPriceData,
  SetSchoolReportForm,
  SetSiteName,
} from "./actionTypes";
import { host } from "../../../config";
import { RootState } from "../../../index";
import { isLoading } from "../../action";
import { downloadFileFromStream } from "../../../../components/admin/common/utils";
import { getSchoolReportDataApi } from "../../../client/reporting/school";
import moment from "moment";
import _ from "lodash";

// Action Creators

export const setLiveSchoolsTrust = (options: IComboxBoxOptions[]): SetLiveSchoolTrusts => {
  return { type: "SET_LIVE_SCHOOL_TRUSTS", options };
};
export const setLiveSchoolRegions = (options: IComboxBoxOptions[]): SetLiveSchoolRegions => {
  return { type: "SET_LIVE_SCHOOL_REGIONS", options };
};
export const setLiveSchools = (options: IComboxBoxOptions[]): SetLiveSchool => {
  return { type: "SET_LIVE_SCHOOL", options };
};

export const setInvoiceCycle = (
  options: IComboxBoxOptions[]
): SetInvoiceCycle => {
  return { type: "SET_INVOICE_CYCLE", options };
};

export const setSchoolReportForm = (
  payload: ISchoolReportForm
): SetSchoolReportForm => {
  return { type: "SET_SCHOOL_FORM", payload };
};

export const setSiteName = (options: any[]): SetSiteName => {
  return { type: "SET_SITE_NAME", options };
};

export const setSchoolReportData = (
  data: ISchoolReportData[]
): SetReportData => {
  return { type: "SET_REPORT_DATA", data };
};

export const setSchoolInvoicePriceData = (
  data: any[]
): SetSchoolPvamPriceData => {
  return { type: "SET_SCHOOL_PVAM_PRICE_DATA", pvamPrices: data };
};

export const setDailyYields = (
  data: any[]
): SetDailyYields => {
  return { type: "SET_DAILY_YIELDS_DATA", dailyYields: data };
};

export const fetchInvoicePriceData = (
  filters: ISchoolReportForm,
  isShowLoader: boolean = true
) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        // if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.post(`${host}/reports/school/invoicePrice`, filters);
        dispatch(setSchoolInvoicePriceData(res.data.data));
        // if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        // if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const fetchExcelBuffer = (
  data: ISchoolReportData[],
  headers: string[],
  isCompare: boolean,
  isShowLoader: boolean = true
) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.post(
          `${host}/reports/school/excel`,
          {
            data,
            headers,
            isCompare,
          },
          { responseType: "blob" }
        );
        downloadFileFromStream({
          data: res.data,
          filename: `live-schools-${data.length}`,
          type: "xlsx",
        });
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const fetchReportData = (
  filters: ISchoolReportForm,
  isShowLoader: boolean = true
) => {
  // Invoke API
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await getSchoolReportDataApi(filters)
        const schoolReportData = calcSavings({ dailyYields: res.dailyYields, pvamPrices: getState().web.reports.pvamPrices, data: res.data })
        dispatch(setSchoolReportData(schoolReportData));
        dispatch(setDailyYields(res.dailyYields));
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const fetchSites = (schools: string[], isShowLoader: boolean = true) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (schools.length > 0) {
          if (isShowLoader) dispatch(isLoading(true));
          const res = await axios.post(`${host}/reports/school/sites`, {
            schools,
          });

          if (res.status === 200) {
            const sites = [{ key: "all", text: "Select All Sites", contractId: '' }];
            for (const s of res.data.sites) {
              sites.push({
                key: s.id,
                text: `${s.name.substring(0, 10)}... / ${s.id}`,
                contractId: s.contractId || ''
              });
            }
            dispatch(setSiteName(sites));
          }
          if (isShowLoader) dispatch(isLoading(false));
          resolve();
        } else {
          dispatch(setSiteName([]));
        }
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const fetInvoiceCycle = (isShowLoader: boolean = true) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.get(`${host}/reports/school/invoice-cycle`);

        if (res.status === 200) {
          const invoiceCycle = [];
          for (const i of res.data.invoiceCycle) {
            invoiceCycle.push({ key: i.Cycle, text: i.Cycle });
          }
          dispatch(setInvoiceCycle(invoiceCycle));
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const fetchLiveSchools = (isShowLoader: boolean = true) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.get(`${host}/reports/school/live`);
        if (res.status === 200) {
          const liveSchools = [{ key: "all", text: "Select All Schools", country: 'all', trust: "all" }];
          const liveSchoolTrusts = [{ key: "all", text: "Select All Trusts", country: 'all' }, { key: "-", text: "Blank", country: 'all' }];
          const liveSchoolRegions = [{ key: "all", text: "Select All Regions" }];
          for (const s of res.data.schools) {
            liveSchools.push({ key: s.slug, text: s.name, country: s.country, trust: s.meta.trust });
            if (s.meta.trust)
              liveSchoolTrusts.push({ key: s.meta.trust, text: s.meta.trust, country: s.country })
            liveSchoolRegions.push({ key: s.country, text: s.country === "--" ? "Other" : s.country })
          }
          dispatch(setLiveSchools(liveSchools));
          dispatch(setLiveSchoolsTrust(_.uniqBy(liveSchoolTrusts, 'key')))
          dispatch(setLiveSchoolRegions(_.uniqBy(liveSchoolRegions, 'key')))
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};


// calc saving and update in redux store
const calcSavings = ({ dailyYields, pvamPrices, data }: any) => {
  const schoolReportData = data.map((item: { siteId: { toString: () => any; }; contractId: { toString: () => any; }; }) => {

    // filter daily yields for specific sys
    const { data: yields } = dailyYields?.find((s: any) => s._id.toString() === item.siteId.toString()) || {}
    // prices for specific contract
    const { prices } = pvamPrices?.find((s: any) => s._id.toString() === item.contractId?.toString()) || {}
    if (yields && prices && item) {
      const savings = yields.map((y: any) => {
        // cehck exact price for that day
        const price = prices.find((p: any) => moment(y.date).isBetween(moment(p.start), moment(p.end)))
        if (price) {
          const sc = (y.data?.SC || 0) / 1000 // convert wh to kwh


          //calc savig
          const pricePaid = price.pricePaid / 100 // price of grid
          const ppa = price.ppa / 100 // price of sfs
          const s = (sc * pricePaid - sc * ppa)
          return s
        } else {
          return 0
        }
      }).reduce((sum: any, current: any) => sum + current, 0)

      return { ...item, savings }
    }
    return item
  })
  return schoolReportData
}
