import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { getTaskTypes } from '../../../store/admin/taskType/action';

import Loader from '../common/Loader';
import TaskTypeFilters from './TaskTypeFilters';
import TaskTypeList from './TaskTypeList';

const TaskTypeScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTaskTypes())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Task Types</h1>
        <TaskTypeFilters />
      </header>
      <TaskTypeList actions={['add', 'edit', 'delete']} />
    </div>
  );
}
export default TaskTypeScreen;

