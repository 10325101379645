import {
  ComboBox,
  DefaultButton,
  IComboBoxOption,
  Label,
  PrimaryButton,
  Stack,
  Sticky,
  StickyPositionType,
  TextField,
} from "@fluentui/react";
import { useMemo, useState } from "react";
import ReactDOM from "react-dom";
import "easymde/dist/easymde.min.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { comboBoxStyles } from "../common/common";
import ModalDialog from "../common/ModalDialog";
import { StringMap } from "../common/types";
import {
  descriptionOptions,
  getGeneralFields,
  shortSummaryOptions,
  validateBlog,
} from "./BlogHelper";
import { Regions } from "./../common/common";
import { IBlogs } from "../../../store/admin/blog/reducers";
import FileUpload from "../../learning/Content/formElements/FileUpload";
import { imagePlaceholder } from "../../learning/Content/config";
import {
  deleteBlogImageApi,
  uploadBlogImageApi,
} from "../../../store/client/blog";
import { cteateUpdateBlog } from "../../../store/admin/blog/action";
import { ConfirmDialog } from "../common/ConfirmDialog";
import slugify from "slugify";
import { MdeEditor } from "../common/MdeEditor";
import moment from "moment";
import { IBlog } from "@solarforschools/sfs-core/dist/blog/types";

export const OpenBlogModal = (props: { params: any }) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement("div");
    document.body.appendChild(mountTarget);
    const callback = (result?: { blog: IBlog }) => {
      resolve(result);
      ReactDOM.unmountComponentAtNode(mountTarget);
      mountTarget.remove();
    };
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Add Edit Blog"}
          onDismiss={() => callback(undefined)}
        // containerClassName="modal-size-md"
        >
          <AddEditBlog
            {...props}
            onSave={(blog) => callback({ blog })}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    );
  });
};

export const AddEditBlog = ({ params, onCancel, onSave }: Props) => {
  const dispatch = useDispatch();
  const { sites, categories } = useSelector<RootState, IBlogs>(
    (state) => state.web.blogs
  );
  const [blog, setBlog] = useState<IBlog>({ ...params } as IBlog);
  const [errors, setErrors] = useState<any>({});
  const [imageUrl, setImageUrl] = useState<string>(imagePlaceholder);

  const blogFields = useMemo(() => getGeneralFields({ blog }), [blog]);

  const handleOnChangeInput = (key: string, item: any) => {
    switch (key) {
      case "country":
      case "site":
      case "categorySlugs":
        let data = (blog[key as string as keyof IBlog] as any[]) || [];
        if (item?.selected) {
          if (!data?.find((s) => s === item?.key)) {
            data.push(item.key);
          }
        } else {
          data = data?.filter((s) => s !== item?.key) as string[];
        }
        setBlog({ ...blog, [key]: data });
        break;
      case "title":
        {
          let slug = blog.slug;
          if (!blog._id) {
            slug = slugify(item, {
              replacement: "-",
              remove: /[*+~.()'"!:@]/g,
              lower: true,
              strict: true,
              trim: true,
            });
          }
          setBlog({ ...blog, [key]: item, slug });
        }

        break;
      case "slug":
        if (!blog._id) {
          setBlog({
            ...blog,
            slug: slugify(item, {
              replacement: "-",
              remove: /[*+~.()'"!:@]/g,
              lower: true,
              strict: true,
              trim: true,
            }),
          });
        }
        break;
      case "date":
        setBlog({
          ...blog,
          date: moment(item).format('YYYY-MM-DD') as unknown as Date
        });

        break;
      default:
        setBlog({ ...blog, [key]: item });
    }
  };
  const handleOnSave = async () => {
    const errors: StringMap = await validateBlog(blog);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    await dispatch(cteateUpdateBlog({ ...blog }));
    return onSave({ ...blog });
  };

  const handleImageUpload = async (file: File | null) => {
    let fileContent = {
      file: file,
      fileName: file?.name,
    };

    if (file) {
      const res: any = await uploadBlogImageApi(fileContent);
      if (res?.data?.s3URL?.Location) {
        handleOnChangeInput("images", [
          ...blog.images,
          { name: res?.data?.s3URL?.Location.split("/").pop(), imageUrl:res?.data?.s3URL?.Location},
        ]);
      }
    }
    setImageUrl(imagePlaceholder);
  };

  const handleOnDeleteImage = async (key: number) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Image",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete?`,
      },
    });
    if (confirm) {
      const image = blog.images![key].name as string;
      await deleteBlogImageApi(image);
      let data = [...blog.images] || [];
      data = data.filter((d) => d.name !== image);
      handleOnChangeInput("images", data);
    }
  };

  return (
    <>
      <div className="edit-record">
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <DynamicForm
              fields={blogFields}
              data={blog}
              errors={errors}
              onChange={handleOnChangeInput}
            />
            <ComboBox
              label="Regions"
              placeholder="Select Regions"
              selectedKey={blog.country}
              multiSelect
              autoComplete={"on"}
              options={
                Regions.filter((s) => s.key !== "all") as IComboBoxOption[]
              }
              onChange={(e, item) => handleOnChangeInput("country", item)}
              className="ms-Grid-col ms-lg3"
              errorMessage={errors?.country}
              styles={comboBoxStyles}
            />
            <ComboBox
              label="Sites"
              placeholder="Select Sites"
              selectedKey={blog.site}
              multiSelect
              autoComplete={"on"}
              options={
                sites.map((s) => {
                  return { key: s.value, text: s.name };
                }) as IComboBoxOption[]
              }
              onChange={(e, item) => handleOnChangeInput("site", item)}
              className="ms-Grid-col ms-lg3"
              errorMessage={errors?.site}
              styles={comboBoxStyles}
            />
            <ComboBox
              label="Blog Categories"
              placeholder="Select Categories"
              selectedKey={blog.categorySlugs}
              multiSelect
              autoComplete={"on"}
              options={
                categories.map((s) => {
                  return { key: s.value, text: s.name };
                }) as IComboBoxOption[]
              }
              onChange={(e, item) => handleOnChangeInput("categorySlugs", item)}
              errorMessage={errors?.categorySlugs}
              className="ms-Grid-col ms-lg3"
              styles={comboBoxStyles}
            />
          </div>
          <div className="ms-Grid-row">
            <Label className="margin-top-md">Short Summary</Label>
            <MdeEditor
              options={shortSummaryOptions}
              onChange={handleOnChangeInput}
              value={blog.shortSummary}
              field={"shortSummary"}
            />
          </div>
          <div className="ms-Grid-row">
            <div
              className="ms-Grid-col ms-lg2"
              style={{ height: "200px", width: "200px" }}
            >
              <FileUpload
                name="+ Add Image"
                url={imageUrl}
                width="100%"
                height="100%"
                fileType="image/*"
                onChangeCallback={handleImageUpload}
                isDirectUpload={true}
              />
            </div>
            <div className="ms-Grid-col ms-lg10">
              {blog?.images &&
                blog?.images!.map((image: any, i: number) => {
                  return (
                    <div className="ms-Grid-row">
                      <div
                        className="ms-Grid-col ms-lg2 margin-top-md image-area"
                        style={{ height: "100px", width: "100px" }}
                      >
                        <img
                          key={i}
                          src={`${image.imageUrl}`}
                          alt={"NoImage"}
                        />
                        <button
                          className="remove-image"
                          onClick={() => handleOnDeleteImage(i)}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </button>
                      </div>
                      <Label className="ms-Grid-col ms-lg10 margin-top-lg">
                        {image.imageUrl}
                      </Label>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="ms-Grid-row margin-top-lg">
            <Label className="margin-top-md">Description</Label>
            <MdeEditor
              options={descriptionOptions}
              onChange={handleOnChangeInput}
              value={blog.description}
              field={"description"}
            />
          </div>
        </div>

        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Stack horizontal horizontalAlign="center">
            <Stack.Item>
              <PrimaryButton
                text="Save"
                onClick={handleOnSave}
                className="btn-primary"
              />
              <DefaultButton onClick={onCancel} text="Cancel" />
            </Stack.Item>
          </Stack>
        </Sticky>
      </div>
    </>
  );
};

interface OwnProps {
  params?: IBlog;
  onCancel: () => void;
  onSave: (blog: IBlog) => void;
}

type Props = OwnProps;
