import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn, Selection } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import DetailsListWapper from '../../common/DetailsListWapper';
import Pagination from '../common/Pagination';
import { ITSReadingState } from '../../../store/admin/tsReading/reducer';
import { getTSReadings, setTSReadingFilter } from '../../../store/admin/tsReading/action';
import { getTsReadingsColumns } from './TSReadinghelper';
import { ILiveTimeSeries } from '@solarforschools/sfs-core/dist/solardb/types';
import { openEditTSReadingModal } from './EditTSReadingDialog';
import { TSReadingViewDialog } from './TSReadingViewDialog';

const TSReadingsList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { tsReadings, totalCount, tsReadingFilter }: ITSReadingState = useSelector<RootState, ITSReadingState>((state: RootState) => state.web.tsReadings)
  const [selectedList, setSelectedList] = useState<any>([])

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedList(selection.getSelection())
    }
  })

  // Call the Modal Component
  const handleDetailView = async (data: any) => {

    const openModalData = await TSReadingViewDialog({ data });
  }

  const headerRow = getTsReadingsColumns({

    actions: [
      {
        icon: 'RedEye',
        onClick: handleDetailView,
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...tsReadingFilter, sortField: field, sortOrder: order };
    dispatch(setTSReadingFilter(params));
    dispatch(getTSReadings(params))
  }
  const loadNextPage = (p: any) => {
    dispatch(setTSReadingFilter({ ...tsReadingFilter, ...p }))
    dispatch(getTSReadings())
  }

  return (
    <>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={tsReadings}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={tsReadings.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={tsReadingFilter?.page!}
            pageSize={tsReadingFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default TSReadingsList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

