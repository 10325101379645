import { ActionButton } from "@fluentui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { uploadLogFile } from "../../store/admin/action";
import IAdmin from "../../store/admin/reducer";
import { State } from "../../store/admin/reducer";

const Log = () => {
  const { admin } = useSelector<RootState, State>((state) => state.web);

  const isSettingsUser = admin.user?.permission?.includes("settings");
  const dispatch = useDispatch();
  const handleDownloadLog = () => {
    dispatch(uploadLogFile());
  };
  return (
    <div>
      {isSettingsUser && (
        <ActionButton
          onClick={handleDownloadLog}
          iconProps={{ iconName: "OpenFile" }}
          style={{ color: "#fff" }}
          title="Uploads log files to sharepoint"
          allowDisabledFocus
        >
          Log
        </ActionButton>
      )}
    </div>
  );
};

export default Log;
