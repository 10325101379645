import React, { CSSProperties, useEffect } from 'react'
import Loader from '../common/Loader'
import List from './List'
import { useDispatch } from 'react-redux'
import { fetchLiveSchools, fetchSupporters } from '../../../store/admin/supporters/action'

const Supporters = () => {
    const dispatch = useDispatch()
    const layoutStyles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
    }

    useEffect(() => {
        dispatch(fetchSupporters())
        dispatch(fetchLiveSchools())
    }, [])

    return (
        <div style={layoutStyles} className='team' >
            <Loader />
            <div>
                <header className="text-center">
                    <h1>Supporters</h1>
                </header>
            </div>

            <List />
        </div>
    )
}

export default Supporters