

import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts/index";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { IRegistrations } from "@solarforschools/sfs-core/dist/adminDashboard/types";
export const FrontFooter = () => {
    const { registrationData } = useContext(CardContext) as CardContextType;
    const prevMonthStyle = { color: "gray", fontSize: "0.85rem" }
    const [status, setStatus] = useState({ prevMonth: "", isEqual: "" });

    function getStatus() {
        if (registrationData !== undefined) {
            const { lastTwelveMonths } = registrationData
            if (lastTwelveMonths[lastTwelveMonths.length - 2].count > lastTwelveMonths[lastTwelveMonths.length - 1].count) {
                setStatus({ ...status, prevMonth: "high", isEqual: "" });
            } else if (lastTwelveMonths[lastTwelveMonths.length - 2].count < lastTwelveMonths[lastTwelveMonths.length - 1].count) {
                setStatus({ ...status, prevMonth: "low", isEqual: "" });
            } else {
                setStatus({ ...status, isEqual: "yes", prevMonth: "" });
            }

        }
    }

    useEffect(() => {
        getStatus()
    }, [registrationData])

    if (registrationData === undefined)
        return <></>

    const { lastTwelveMonths } = registrationData
    const prevDate = lastTwelveMonths[lastTwelveMonths.length - 2].month + " " + lastTwelveMonths[lastTwelveMonths.length - 2].year
    const currDate = lastTwelveMonths[registrationData?.lastTwelveMonths.length - 1].month + " " + lastTwelveMonths[lastTwelveMonths.length - 1].year
    const prevDateCount = lastTwelveMonths[lastTwelveMonths.length - 2].count
    const currDateCount = lastTwelveMonths[lastTwelveMonths.length - 1].count

    const prevCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevMonth === "high" ? "green" : "red"))
    const currCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevMonth === "high" ? "red" : "green"))

    return <>
        <div className="flex-row-center" style={{ fontSize: "1rem", alignItems: "end", paddingTop: "1rem" }}>

            <div className="flex-column-center" >
                <div className="flex-row-center">
                    <div style={{ color: prevCountStyle }}>
                        {prevDateCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevMonth === "high"
                            ? <UpTriangleIcon />
                            : <DownTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{prevDate}</div>
            </div>
            <div className="vs-style">
                vs
            </div>

            <div className="flex-column-center">
                <div className="flex-row-center" >
                    <div style={{ color: currCountStyle }}>
                        {currDateCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevMonth === "high"
                            ? <DownTriangleIcon />
                            : <UpTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{currDate}</div>
            </div>
        </div>
    </>
}

export const BackFooter = () => {
    const { registrationData } = useContext(CardContext) as CardContextType;
    Chart.register(...registerables);
    const labels = (registrationData as IRegistrations)?.lastTwelveMonths.map(el => el.month)
    const data = (registrationData as IRegistrations)?.lastTwelveMonths.map((el) => el.count)


    const datum = {
        labels,
        datasets: [
            {
                data,
                borderColor: "#9552EA",
                fill: true,
                backgroundColor: "rgb(149, 82, 234,0.5)",
                pointRadius: 0,
                pointHitRadius: 0,
                borderWidth: 1,
                lineTension: 0,
            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Last 12 Months',
                color: "#243f61",
                align: 'end' as const,
                padding: {
                    bottom: 1,
                },
                font: {

                    weight: 'normal',
                    lineHeight: 1.2,
                },

            },
            tooltip: {
                // mode: "index" as const,
                intersect: false,
                // displayColors: false,
                // titleFont: {
                //     size: 10
                // },
                bodyFont: {
                    size: 10
                },
                padding: {
                    left: 3,
                    right: 3,
                    bottom: 5,
                    top: 5
                },

                position: "nearest" as const,
                callbacks: {
                    label: function (context: any) {
                        const datasetIndex = context.datasetIndex;
                        const dataset = context.chart.data.datasets[datasetIndex];
                        const xValue = context.chart.data.labels[context.dataIndex];
                        const yValue = context.parsed.y;
                        return ` ${xValue}: ${yValue} `;
                    },
                    title: function () {
                        return null;
                    }
                },
            }
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        },

        maintainAspectRatio: false
    };

    return <>
        <div className="flex-row-center" style={{ height: "4rem", gap: "1.5rem", }}>
            <div style={{ height: "50px", width: "240px" }}>
                <Line data={datum} options={options as any} />
            </div>
        </div>

    </>



}
