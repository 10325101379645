import { DefaultButton, Pivot, PivotItem, PrimaryButton, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import { IBondOffer } from "@solarforschools/sfs-core/dist/funder/bond-offer/types"
import _ from "lodash"
import moment from "moment"
import { JSXElementConstructor, Key, ReactElement, ReactNodeArray, ReactPortal, useMemo, useState } from "react"

import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../../store"
import { createUpdateBondOffer } from "../../../../store/admin/funder/bondOffer/action"
import DynamicForm from "../../../dynamic-form/DynamicForm"
import ModalDialog from "../../common/ModalDialog"
import { onRenderPivoteItemLink } from "../../common/PivotItemRenderLink"
import BondOfferBonds from "./BondOfferBonds"
import { bondOfferTabs, getBatchFields, getOfferFields, getTabErrors, validateBondOffer } from "./BondOfferHelper"



export const OpenAddBondOfferModal = (props: OwnProps) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add/Edit Bond Offer"}
                    onDismiss={() => callback(undefined)}
                // containerClassName="modal-size-md"
                >
                    <AddBondOfferDialog
                        {...props}
                        onSave={bondOffer => callback(bondOffer)}
                        onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export const AddBondOfferDialog = ({
    bondOffer,
    onSave,
    onCancel,
}: Props) => {
    const dispatch = useDispatch()
    const [offer, setOffer] = useState<IBondOffer>(_.cloneDeep(bondOffer));

    const [errors, setErrors] = useState<any>({});
    const [tabErrors, setTabErrors] = useState({ ...bondOfferTabs });

    // Get tab fields
    const offerFields = useMemo(() => getOfferFields({ bondOffer }), [offer]);
    const batchFields = useMemo(() => getBatchFields({ bondOffer }), [offer]);


    const onPivotLinkClick = async (props: any) => {

    };

    const handleOnChangeInput = (key: string, value: any) => {
        let bondOffer: IBondOffer = _.cloneDeep(offer);
        if (errors[key]) {
            const err = { ...errors };
            delete err[key];
            const tabErrors = getTabErrors(err, bondOffer);
            setErrors(err);
            setTabErrors(tabErrors);
        }
        switch (key) {
            case "offer.interestRate":
                bondOffer = _.set({ ...bondOffer }, key, value);
                bondOffer.batch.interestRate = value
                break;
            case "offer.lifetime":
                bondOffer = _.set({ ...bondOffer }, key, value);
                break;
            case "issueDate":
            case "offer.startDate":
            case "offer.endDate":
            case "batch.interestStartDate":
            case "batch.interestEndDate":
            case "batch.repaymentDate":
            case "batch.annualPaymentDate":
            case "batch.processDate":
                const date = moment(value).format("YYYY-MM-DD")
                bondOffer = _.set({ ...bondOffer }, key, date);
                break;

            default:
                bondOffer = _.set({ ...bondOffer }, key, value);
                break;
        }
        setOffer(bondOffer);
    };

    const handleSave = async (overrideBond = false) => {
        const errors = await validateBondOffer(offer);
        if (Object.keys(errors).length) {
            const tabErrors = getTabErrors(errors, offer);
            setErrors(errors);
            setTabErrors(tabErrors);
            return;
        }
        dispatch(createUpdateBondOffer(offer, overrideBond))
        // onSave!(offer);
    };

    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <Pivot linkSize="large" onLinkClick={(item) => onPivotLinkClick(item)}>
                    <PivotItem
                        key="offer"
                        headerText="Offer Details"
                        onRenderItemLink={onRenderPivoteItemLink(tabErrors.offer)}
                    >
                        <div className="ms-Grid-row">
                            <DynamicForm
                                fields={offerFields}
                                data={offer}
                                onChange={handleOnChangeInput}
                                errors={errors}
                            />
                        </div>
                        <div className='ms-Grid-row'>
                            <TextField
                                label="Interest Comment"
                                placeholder=""
                                onChange={(e: any, value: any) => handleOnChangeInput('interestComment', value)}
                                multiline={true}
                                rows={1}
                                className='ms-Grid-col ms-lg6'
                                value={offer.interestComment} />
                            <TextField
                                label="Interest Paid Comment"
                                placeholder=""
                                onChange={(e: any, value: any) => handleOnChangeInput('interestPaidComment', value)}
                                multiline={true}
                                rows={1}
                                className='ms-Grid-col ms-lg6'
                                value={offer.interestPaidComment} />
                        </div>
                        <div className='ms-Grid-row'>
                            <TextField
                                label="Notes"
                                placeholder=""
                                onChange={(e: any, value: any) => handleOnChangeInput('notes', value)}
                                multiline={true}
                                rows={3}
                                className='ms-Grid-col ms-lg12'
                                value={offer.notes} />
                        </div>
                    </PivotItem>
                    <PivotItem
                        key="batch"
                        headerText="Offer Batch"
                        onRenderItemLink={onRenderPivoteItemLink(tabErrors.batch)}
                    >
                        <div className="ms-Grid-row">
                            <DynamicForm
                                fields={batchFields}
                                data={offer}
                                onChange={handleOnChangeInput}
                                errors={errors}
                            />
                        </div>
                    </PivotItem>

                    {offer._id &&
                        <PivotItem
                            key="bonds"
                            headerText="Bonds"
                            onRenderItemLink={onRenderPivoteItemLink(tabErrors.batch)}
                        >
                            <BondOfferBonds bondOffer={offer} ></BondOfferBonds>
                        </PivotItem>
                    }
                    <PivotItem key="log" headerText="Audit Log">
                        <table style={{ width: "100%" }}>
                            <thead style={{ backgroundColor: "#2e2e2e", color: 'white' }}>
                                <tr>
                                    <th style={{ padding: "5px" }}> Action </th>
                                    <th style={{ padding: "5px" }} >Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {offer.auditLog?.map((a: { action: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; time: moment.MomentInput }, i: Key | null | undefined) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ padding: "5px" }}> {a.action}</td>
                                            <td style={{ padding: "5px" }}>{moment(a.time).format("MMM DD YYYY")}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                </Pivot>
            </div>
            {offer._id && (
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg6 margin-top-md">
                        <h5>Warning</h5>
                        <p>On click 'save and override bond', following will be over written for each open bond belong to this bondoffer</p>
                        <ul>
                            <li>Bond offer name, title,  interest rate,  interestStartDate,  repaymentDate, annualPaymentDate</li>
                            <li>If bond offer is test one. all Bonds belong to this bond offer are also marked as test bonds</li>
                            <li>Regenerate transactions if necessary</li>
                            <li>Regenerate bond certificates if necessary</li>
                        </ul>
                    </div>
                </div>
            )}
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>
                        {offer._id &&
                            <PrimaryButton
                                text="Save And Override Bond"
                                onClick={() => handleSave(true)}
                                className="btn-primary"
                            />}
                        <PrimaryButton
                            text="Save"
                            onClick={() => handleSave()}
                            className="btn-primary"
                        />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    onCancel?: () => void
    bondOffer: IBondOffer,
    onSave?: (bondOffer: IBondOffer) => void
}

type Props = OwnProps;
