import { Action } from "./actionTypes";
import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types";
import { IUserFunctionFilter } from "../../../components/admin/userFunction/types";

// States' definition
export interface IUserFunctionState {
  isLoading: boolean;
  userFunctions: IUserFunction[];
  userFunctionFilter: IUserFunctionFilter;
  totalCount: number;
}
export interface State {
  userFunctionState: IUserFunctionState;
}

const userFunction = (
  state: IUserFunctionState = {
    isLoading: false,
    userFunctions: [],
    userFunctionFilter: {
      page: 1,
      pageSize: 50,
      sortField: "Start",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): IUserFunctionState => {
  switch (action.type) {
    case "SET_USER_FUNCTION_FILTER":
      return { ...state, userFunctionFilter: action.userFunctionFilter };
    case "SET_USER_FUNCTION_LIST":
      const userFunctions = action.reset
        ? action.userFunctions
        : [...state.userFunctions, ...action.userFunctions];
      return { ...state, userFunctions, totalCount: action.totalCount };
    case "DELETE_USER_FUNCTION": {
      const stateObj = { ...state };
      const userFunctions: IUserFunction[] = stateObj.userFunctions.filter(
        (s) => s.slug !== action.slug
      );
      const userFunctionFilter = { ...stateObj.userFunctionFilter };
      const { page, pageSize } = userFunctionFilter;
      return {
        ...state,
        userFunctions,
        totalCount: stateObj.totalCount - 1,
        userFunctionFilter: {
          ...userFunctionFilter,
          page: Math.ceil((userFunctions.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_USER_FUNCTION": {
      const userFunctions = [...state.userFunctions];
      const index = userFunctions.findIndex(
        (s) => s.slug === action.userFunction?.slug
      );
      if (index > -1) {
        userFunctions[index] = action.userFunction;
      } else {
        userFunctions.unshift(action.userFunction);
      }
      return {
        ...state,
        userFunctions,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default userFunction;
