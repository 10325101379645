

import {
    ActionButton,
    DefaultButton,
    Label,
    PrimaryButton,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
} from "@fluentui/react";
import { TextField } from "office-ui-fabric-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { CategoryOptions, getGeneralFields, uploadImage, validateDetails } from "./utils/helper";
import { IoImagesOutline } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { ITeam } from "@solarforschools/sfs-core/dist/team/types";
import { toast } from "react-toastify";
import { createTeamMember } from "../../../store/admin/team/action";
import { isLoading } from "../../../store/admin/action";
import { ITeam as ITeamRr } from '../../../store/admin/team/reducer';

// Open the modal dialog - return promise(Await to resolve data)
export const openDialog1 = () => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget);
        const callback = (result?: IProps[]) => {
            resolve(result);
            ReactDOM.unmountComponentAtNode(mountTarget);
            mountTarget.remove();
        };

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add team member"}
                    onDismiss={() => callback(undefined)}
                // containerClassName="modal-size-md"
                >
                    <CreateTeamMember
                        onCancel={() => callback(undefined)}
                        onSave={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        );
    });
};

const CreateTeamMember = (props: IProps) => {

    const { data: teamData }: ITeamRr = useSelector<RootState, ITeamRr>((state: RootState) => state.web.team);

    const [errors, setErrors] = useState<any>({});
    const [fields, setFields] = useState(getGeneralFields.filter(elm => elm.key !== "order"));
    const dispatch = useDispatch()

    const [tempPics, setTempPics] = useState<any>("");
    const [selectedFiles, setSelectedFiles] = useState<any>("");
    const [data, setData] = useState<Partial<ITeam>>({})

    useEffect(() => {
        let tempFields = [...fields]
        const findIndex = tempFields.findIndex(elm => elm.key.toLowerCase() === "category")
        if (findIndex !== -1) {
            tempFields[findIndex]["selectedKey"] = CategoryOptions[0].key
            setFields(tempFields)
            setData({ isActive: true })
        }
    }, [])


    const handleOnChangeInput = (key: string, item: any) => {

        if (key === "category") {
            const findObj = fields.find(elm => elm.key === "category")
            if (findObj) findObj.selectedKey = item
            setData({ ...data, [key]: item })
            return;
        }
        setData({ ...data, [key]: item })
    }

    const handleS3Upload = async (files: any) => {
        if (!files || files.length === 0) return
        dispatch(isLoading(true))
        const images: string = await uploadImage(files,data["email"] ? data["email"] : "")
        dispatch(isLoading(false))
        return images
    }


    const handleOnSave = async () => {

        const error = await validateDetails(data);
        setErrors(error)

        if (Object.keys(error).length) return;

        if (!tempPics) {
            toast.error("Upload team member pic")
            return
        }

        if (data["isActive"] === undefined) data["isActive"] = false;

        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Confirm Save",
                closeButtonAriaLabel: "Close",
                subText: `Do you want to save the data ?`,
            },
            confirmBtnText: "Save",
        });

        if (isConfirmed) {
            let uploadedImage = await handleS3Upload(selectedFiles);
            if (!uploadedImage) {
                toast.error("Error in uploading image")
                return;
            }

            data["pic"] = `team/${uploadedImage}`
            data["order"] = teamData.length > 0 ? (teamData[teamData.length - 1]?.order + 1) : 1
            dispatch(createTeamMember(data))
            props.onSave(); // on save
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files[0]) {
            let newUrl = URL.createObjectURL(e.target.files[0]);
            setTempPics(newUrl);
            setSelectedFiles([...selectedFiles, e.target.files[0]])
        }

    }

    const handleDelete = () => {
        setTempPics("")
    }

    return (
        <div>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <DynamicForm
                        fields={fields}
                        data={data}
                        errors={errors}
                        onChange={handleOnChangeInput}
                    />
                </div>
            </div>
            <div style={{ margin: "1rem 0 0 0.5rem" }}>
                <Label>Pic</Label>
            </div>


            <label>
                <div style={{ marginLeft: "1rem", width: "fit-content", cursor: "pointer", border: "1px solid lightgrey", padding: "0.25rem 1rem" }}>
                    <IoImagesOutline /> Choose a Pic
                </div>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => handleImageChange(e)}
                    style={{ display: "none" }}
                />
            </label>

            <div style={{ width: 200, height: 200, margin: "auto", position: "relative" }}>
                {
                    tempPics ? (
                        <>
                            <div
                                style={{
                                    position: "absolute",
                                    top: -10,
                                    cursor: "pointer",
                                    right: -10,
                                    color: "red"
                                }}
                                onClick={handleDelete}
                            >
                                <IoIosCloseCircle size="1.25rem" />
                            </div>
                            <img
                                src={tempPics}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                alt={`picture`}
                            />
                        </>
                    ) : <div className="flex-row-center" style={{ opacity: "0.6", background: "lightgrey", height: "100%" }}>
                        <Text variant="medium">Upload image to preview</Text>
                    </div>

                }
            </div>




            <Sticky stickyPosition={StickyPositionType.Footer}>
                <div
                    style={{
                        display: "flex",
                        marginTop: "1rem",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Stack horizontal horizontalAlign="center" className="margin-top-xs">
                        <Stack.Item>

                            <PrimaryButton
                                text="Save"
                                onClick={handleOnSave}
                                className="btn-primary"
                            />
                            <DefaultButton onClick={props.onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </div>
            </Sticky>
        </div>
    );
};

export default CreateTeamMember;

interface IProps {
    onCancel: () => void;
    onSave: () => void;
}


