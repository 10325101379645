import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import learning, { State as ContentState } from "./content/reducer";
import web, { State as WebState } from "./admin/reducer";
import thunk from "redux-thunk";

export interface RootState {
  learning: ContentState;
  web: WebState;
}

export default createStore(
  combineReducers<RootState>({
    learning,
    web,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);
