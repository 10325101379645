export const statusOptions: any[] = [
    { key: "all", text: "All" },
    { key: "true", text: "True" },
    { key: "false", text: "False" },
];

export const userRefferences = [
    { key: 'internet search', text: 'Internet search' },
    { key: 'another school told me', text: 'Another school told me' },
    { key: 'a friend told me', text: 'A friend told me' },
    { key: 'advertising', text: 'Advertising' },
    { key: 'facebook', text: 'Facebook' },
    { key: 'twitter', text: 'Twitter' },
    { key: 'linked in', text: 'Linked In' },
    { key: 'email', text: 'Email' },
    { key: 'eco schools', text: 'Eco Schools' },
    { key: "independent school monthly", text: 'Independent School Monthly' },
    { key: 'other', text: 'Other' },
]