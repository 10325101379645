
import { Action } from "./actionType";
import { IBondFilter } from './../../../../components/admin/funder/bond/types.d';
import { defaultBondFilter } from "../../../../components/admin/funder/bond/BondHelper";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";

// States' definition
export interface IBondState {
  isLoading: boolean;
  bonds: IBond[];
  bondFilter: IBondFilter;
  totalCount: number;
  bondOffers: [];
  bondHolders: [];
  auditLogs: any[];
  periods: any[];
}
export interface State {
  bond: IBondState;
}

const bonds = (
  state: IBondState = {
    isLoading: false,
    bonds: [],
    bondFilter: defaultBondFilter,
    totalCount: 0,
    bondOffers: [],
    bondHolders: [],
    auditLogs: [],
    periods: []
  },
  action: Action
): IBondState => {
  switch (action.type) {
    case 'FUNDER/SET_BONDS_FILTER':
      return { ...state, bondFilter: action.bondFilter }
    case 'FUNDER/SET_BONDS_LIST':
      const bonds = action.replace ? action.bonds : [...state.bonds, ...action.bonds]
      return { ...state, bonds, totalCount: action.totalCount }
    case "FUNDER/BOND_SET_BONDOFFER_FILTER":
      return { ...state, bondOffers: action.bondOffers as [] }
    case "FUNDER/BOND_SET_BONDHOLDER_FILTER":
      return { ...state, bondHolders: action.bondHolders as [] }
    case "FUNDER/UPDATE_BOND": {
      const bonds = [...state.bonds]
      const index = bonds.findIndex(s => s._id?.toString() === action.bond?._id?.toString())
      if (index > -1) {
        bonds[index] = action.bond
      } else {
        bonds.unshift(action.bond)
      }
      return { ...state, bonds, totalCount: index > -1 ? state.totalCount : state.totalCount + 1 }
    }
    case 'FUNDER/BOND_SET_BONDOAUDITLOG_FILTER':
      return { ...state, auditLogs: action.auditLogs, periods: action.periods }
    default:
      return state;
  }
};

export default bonds;
