import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { isSortable } from "../../common/utils";
import { IBondOffer } from '@solarforschools/sfs-core/dist/funder/bond-offer/types';
import { numberToCurrencyFormat } from "../../../../utils/util";
import moment from "moment";
import { IField } from "../../../dynamic-form/DynamicForm";
import { BondOfferTabErrors } from "./types";
import { StringMap } from "../../common/types";
import { BondCurrencies } from "../helper";
import { requiredMessage } from "../../common/common";


export const getBondOfferColumns = [
    {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 150,
        maxWidth: 250,
        ...isSortable,
        onRender: (item: IBondOffer) => <TooltipHost content={item.name || ''} closeDelay={500}> {item.name}</TooltipHost>
    },
    {
        key: 'title',
        name: 'Title',
        fieldName: 'title',
        minWidth: 150,
        ...isSortable,
        onRender: (item: IBondOffer) => <TooltipHost content={item.title || ''} closeDelay={500}> {item.title}</TooltipHost>
    },
    {
        key: 'isTestOffer',
        name: 'Is Test Offer',
        fieldName: 'isTestOffer',
        minWidth: 80,
        ...isSortable,
        onRender: (item: IBondOffer) => {
            console.log(item.isTestOffer === true)
            return <p>{item.isTestOffer === true ? 'true' : 'false'}</p>
        }
    },
    {
        key: 'batch.interestStartDate',
        name: 'Interest Start Date',
        fieldName: 'batch.interestStartDate',
        minWidth: 100,
        ...isSortable,
        onRender: (item: IBondOffer) => moment(item.batch.interestStartDate).format('MMM DD, YYYY')
    },
    {
        key: 'batch.interestEndDate',
        name: 'Interest End Date',
        fieldName: 'batch.interestEndDate',
        minWidth: 100,
        ...isSortable,
        onRender: (item: IBondOffer) => item.batch.interestEndDate && moment(item.batch.interestEndDate).format('MMM DD, YYYY')
    },
    {
        key: 'batch.amount',
        name: 'Amount',
        fieldName: 'batch.amount',
        minWidth: 100,
        ...isSortable,
        onRender: (item: IBondOffer) => numberToCurrencyFormat(item.batch?.amount || 0)
    }
];

export const bondHolderStatus =
    [
        { key: "all", text: "All" },
        { key: "Open", text: "Open" },
        { key: "Closed", text: "Closed" },
        { key: "Deleted", text: "Deleted" },
    ]

export const getOfferFields = ({ }): IField[] => {
    return [{
        key: 'name',
        label: 'Name',
        type: 'string',
        placeholder: 'Enter Bond offer Name',
        extras: { required: true },
        className: 'ms-Grid-col ms-lg6',
    }, {
        key: 'title',
        label: 'Title (Display on certificate)',
        type: 'string',
        placeholder: 'Enter Title',
        extras: { required: true },
        className: 'ms-Grid-col ms-lg6',
    }, {
        key: 'issueDate',
        label: 'Default Issue Date',
        type: 'date',
        placeholder: 'Pick issue date',
        className: 'ms-Grid-col ms-lg4',
        // extras: { isRequired: true }
    },
    {
        key: 'offer.startDate',
        label: 'Offer Start Date',
        type: 'date',
        placeholder: 'Pick start date',
        className: 'ms-Grid-col ms-lg4',
        extras: { isRequired: true }
    }, {
        key: 'offer.endDate',
        label: 'Offer End Date',
        type: 'date',
        placeholder: 'Pick End date',
        className: 'ms-Grid-col ms-lg4',
        extras: { isRequired: true }
    }, {
        key: 'offer.minInvestment',
        label: 'Minimum Investment',
        type: 'number',
        placeholder: 'Enter Min Investment',
        step: "1",
        className: 'ms-Grid-col ms-lg4',
    }, {
        key: 'offer.maxInvestment',
        label: 'Maximum Investment',
        type: 'number',
        placeholder: 'Enter Max Investment',
        step: "1",
        className: 'ms-Grid-col ms-lg4',
    }, {
        key: 'offer.interestRate',
        label: 'Interest Rate[%]',
        type: 'number',
        placeholder: 'Enter Interest Rate',
        step: "1",
        className: 'ms-Grid-col ms-lg4',
        extras: { required: true },
    }, {
        key: 'offer.targetVolume',
        label: 'Target Volum[GBP]',
        type: 'number',
        placeholder: 'Enter Target volum',
        step: "1",
        className: 'ms-Grid-col ms-lg4',
        extras: { required: true },
    }, {
        key: 'offer.lifetime',
        label: 'LifeTime[Years]',
        type: 'number',
        placeholder: 'Enter Life time',
        step: "1",
        className: 'ms-Grid-col ms-lg4',
        extras: { required: true },
    }, {
        key: 'status',
        label: 'Status',
        type: 'dropdown',
        options: bondHolderStatus.filter(t => t.key !== 'all') as IDropdownOption[],
        className: 'ms-Grid-col ms-lg4',
        extras: { required: true },
    }, {
        key: 'offer.currency',
        label: 'Currency',
        type: 'dropdown',
        options: BondCurrencies as IDropdownOption[],
        className: 'ms-Grid-col ms-lg4',
    }, {
        key: 'offer.security',
        label: 'Security',
        type: 'dropdown',
        options: BondSecurties as IDropdownOption[],
        className: 'ms-Grid-col ms-lg4',
        extras: { required: true },
    },
    {
        label: "Is Test Offer",
        key: "isTestOffer",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1 margin-top-lg',
    },
    ]
}
export const getBatchFields = ({ }): IField[] => {
    return [{
        key: 'batch.paymentPeriod',
        label: 'Interest Payment Period [months]',
        type: 'number',
        step: "1",
        placeholder: 'Interest Payment Period [months]',
        extras: { required: true },
        className: 'ms-Grid-col ms-lg3',
    }, {
        key: 'batch.interestRate',
        label: 'Interest Rate[%]',
        type: 'number',
        placeholder: 'Enter Interest Rate',
        step: "1",
        className: 'ms-Grid-col ms-lg3',
    }, {
        key: 'batch.interestStartDate',
        label: 'Interest Start Date',
        type: 'date',
        placeholder: 'Pick date',
        className: 'ms-Grid-col ms-lg3',
        extras: { isRequired: true }
    },
    {
        key: 'batch.interestEndDate',
        label: 'Interest End Date',
        type: 'date',
        placeholder: 'Pick date',
        className: 'ms-Grid-col ms-lg3',
        extras: { isRequired: true }
    }, {
        key: 'batch.annualPaymentDate',
        label: 'Annual Interest Repayment Date',
        type: 'date',
        ariaLabel: "Interest will transfer on this date every period",
        placeholder: 'Pick date',
        className: 'ms-Grid-col ms-lg3',
        extras: { isRequired: true }
    }, {
        key: 'batch.repaymentDate',
        label: 'Repayment Date',
        type: 'date',
        placeholder: 'Pick date',
        className: 'ms-Grid-col ms-lg3',
        extras: { isRequired: true }
    }, {
        key: 'batch.amount',
        label: 'Batch Amount (aggregated)',
        type: 'number',
        placeholder: 'Batch Amount (aggregated)',
        step: "1",
        className: 'ms-Grid-col ms-lg3',
    }, {
        key: 'batch.processDate',
        label: 'Process Date',
        type: 'date',
        placeholder: 'Pick date',
        className: 'ms-Grid-col ms-lg3',
    },
    ]
}

export const BondSecurties = [{ key: 'unsecured', text: "Unsecured" }, { key: 'secured', text: "Secured" }]

export const bondOfferTabs: BondOfferTabErrors = {
    offer: false,
    batch: false,
}

export const validateBondOffer = (bondOffer: IBondOffer) => {
    let errors: StringMap = {};
    const { name, title, offer, batch, issueDate } = bondOffer
    if (!offer.startDate || !moment(offer.startDate).isValid()) errors["offer.startDate"] = requiredMessage
    if (!offer.endDate || !moment(offer.endDate).isValid()) errors["offer.endDate"] = requiredMessage
    if (!name) errors["name"] = requiredMessage
    if (!title) errors["title"] = requiredMessage
    if (!offer.interestRate || isNaN(offer.interestRate)) errors["offer.interestRate"] = requiredMessage
    if (!offer.targetVolume) errors["offer.targetVolume"] = requiredMessage
    if (!offer.lifetime) errors["offer.lifetime"] = requiredMessage
    if (!offer.security) errors["offer.security"] = requiredMessage

    if (!batch.interestRate) errors["batch.interestRate"] = requiredMessage
    if (!batch.paymentPeriod) errors["batch.paymentPeriod"] = requiredMessage
    if (!batch.interestStartDate || !moment(batch.interestStartDate).isValid()) errors["batch.interestStartDate"] = requiredMessage
    if (!batch.interestEndDate || !moment(batch.interestEndDate).isValid()) errors["batch.interestEndDate"] = requiredMessage
    if (!batch.annualPaymentDate || !moment(batch.annualPaymentDate).isValid()) errors["batch.annualPaymentDate"] = requiredMessage
    if (!batch.repaymentDate || !moment(batch.repaymentDate).isValid()) errors["batch.repaymentDate"] = requiredMessage
    return errors
}

export const getTabErrors = (errors: StringMap, bondOffer: IBondOffer): BondOfferTabErrors => {
    const tabErrors = { ...bondOfferTabs }
    Object.keys(errors).forEach(property => {
        if (getOfferFields({ bondOffer }).find(field => field.key === property)) {
            tabErrors.offer = true;
        }
        if (getBatchFields({ bondOffer }).find(field => field.key === property)) {
            tabErrors.batch = true;
        }

    })
    return tabErrors;
}

export const initBondOffer: IBondOffer = {
    auditLog: [],
    batch: {
        amount: 0,
        interestStartDate: moment().format("YYYY-MM-DD") as unknown as Date,
        interestEndDate: moment().format("YYYY-MM-DD") as unknown as Date,
        repaymentDate: moment().format("YYYY-MM-DD") as unknown as Date,
        interestRate: 0,
        annualPaymentDate: moment().format("YYYY-MM-DD") as unknown as Date,
        paymentPeriod: 0,
        processDate: moment().format("YYYY-MM-DD") as unknown as Date
    },
    issueDate: moment().format("YYYY-MM-DD") as unknown as Date,
    name: "",
    notes: "",
    offer: {
        startDate: moment().format("YYYY-MM-DD") as unknown as Date,
        endDate: moment().format("YYYY-MM-DD") as unknown as Date,
        minInvestment: 0,
        maxInvestment: 0,
        targetVolume: 0,
        interestRate: 0,
        lifetime: 0,
        security: "",
        currency: "GBP"
    },
    status: "Open",
    title: ""
}