import React, { useState } from "react";
import FileUpload from "../formElements/FileUpload";
import { imagePlaceholder } from "../config";
import Textarea from "../formElements/Textarea";
import {
  ContentItems,
  IContentItems,
} from "../../../../store/content/actionTypes";
import { IContent } from "../../../../store/content/reducer";
import { getImageURL } from "../util";

const CTen = ({ content, seq, dispatchContent }: props) => {
  const [containerStyle, setContainerStyle] = useState<React.CSSProperties>({
    display: "flex",
  });

  const [paragraphStyle, setParagraphStyle] = useState<React.CSSProperties>({
    textAlign: "justify",
    margin: "0.5rem",
  });

  const handleFileChange = (file: File | null, sequence: number) => {
    let contentItem: IContentItems = {
      type: ContentItems.IMAGE,
      sequence,
      text: "",
      file: file,
      fileName: file?.name,
    };
    dispatchContent(contentItem, sequence);
  };

  const handleTextareaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    sequence: number
  ) => {
    addContent(ContentItems.PARAGRAPH, e.target.value, sequence);
  };

  const addContent = (
    contentType: ContentItems,
    value: string,
    sequence: number
  ) => {
    let contentItem: IContentItems = {
      type: contentType,
      sequence,
      text: value,
    };

    dispatchContent(contentItem, sequence);
  };

  return (
    <>
      <section style={containerStyle}>
        <FileUpload
          name="+ Add Visual"
          margin="0"
          url={getImageURL(content, seq[0])}
          onChangeCallback={(file: File | null) =>
            handleFileChange(file, seq[0])
          }
        />
        <Textarea
          margin="0 0 0 1rem"
          placeholder="Sentence about image"
          value={
            content.contentTemplate?.items?.find(
              (item) => item.sequence === seq[1]
            )?.text
          }
          maxChar={500}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleTextareaChange(e, seq[1])
          }
        />
      </section>
      <section style={containerStyle}>
        <Textarea
          margin="0 1rem 0 0"
          placeholder="Sentence about image"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleTextareaChange(e, seq[2])
          }
          maxChar={500}
          value={
            content.contentTemplate?.items?.find(
              (item) => item.sequence === seq[2]
            )?.text
          }
        />
        <FileUpload
          name="+ Add Visual"
          margin="0"
          url={getImageURL(content, seq[3])}
          onChangeCallback={(file: File | null) =>
            handleFileChange(file, seq[3])
          }
        />
      </section>
    </>
  );
};

export interface props {
  content: IContent;
  seq: number[];
  dispatchContent: (contentItem: IContentItems, sequence: number) => void;
}

export default CTen;
