import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { IWorkflowFilter } from "../../../components/admin/workflow/types";
import { toast } from "react-toastify";
import { ITaskType } from "@solarforschools/sfs-core/dist/taskType/types";
import { ITaskTypeFilter } from "../../../components/admin/taskType/types";
import { createTaskTypeAPi, deleteTaskTypeAPi, getTaskTypesApi, updateTaskTypeAPi } from "../../client/taskType";

// Action Creators

export const setTaskTypeFilter = (taskTypeFilter: IWorkflowFilter) => {
  return { type: "SET_TASKTYPE_FILTER", taskTypeFilter };
};

export const setTaskTypeList = (
  taskTypes: ITaskType[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_TASKTYPE_LIST", taskTypes, totalCount, reset };
};

export const updateTaskTypeList = (taskType: ITaskType) => {
  return { type: "UPDATE_TASKTYPE", taskType };
};

export const delTaskType = (slug: string) => {
  return { type: "DELETE_TASKTYPE", slug };
};

export const getTaskTypes =
  (params?: ITaskTypeFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: ITaskTypeFilter =
            getState().web.taskType.taskTypeFilter;
          reqBody = { ...filters };
        }
        const { taskTypes, totalCount } = await getTaskTypesApi(reqBody);
        dispatch(setTaskTypeList(taskTypes, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateTaskType =
  (params: ITaskType) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { taskType, msg } = params._id
          ? await updateTaskTypeAPi(params)
          : await createTaskTypeAPi(params);
        dispatch(updateTaskTypeList(taskType));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteTaskType =
  (taskType: ITaskType) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteTaskTypeAPi(taskType);
        dispatch(delTaskType(taskType.slug))
        toast.success(msg)
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
