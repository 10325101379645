import React, { useEffect, useState } from "react";

import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import { changeOrder, fetchContents } from "../../../store/content/action";
import Screen from "./Screen";
import Label from "./formElements/Label";

const RearrangeScreens = ({ content, fetchContents, changeOrder }: Props) => {
  useEffect(() => {
    fetchContents();
  }, [fetchContents]);
  const module = content.allModules?.find(
    (module) => module._id === content.screen?.moduleID
  );
  const screens =
    module && module.units.find((unit) => unit._id === content.screen?.unitID);

  const [dragId, setDragId] = useState("");
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const from = Number(dragId);
    const to = Number(e.currentTarget.id);

    if (content.screen?.unitID && from !== to) {
      changeOrder(content.screen?.unitID, from, to);
    }
  };

  return (
    <div className="content-view">
      <div className="instructions">
        Click and drag the screens to rearrange the order they should be
        displayed, click the pencil to edit a screen
      </div>
      <div className="view-screens">
        {screens &&
          module &&
          screens.contents.map((screen, index) => (
            <Screen
              module={module}
              currentScreen={screen}
              index={index}
              handleDrag={handleDrag}
              handleDrop={handleDrop}
            />
          ))}
        {screens?.contents.length === 0 ? (
          <Label name="Add some content to see it here" fontSize="2rem" />
        ) : null}
      </div>
    </div>
  );
};

interface DispatchProps {
  fetchContents: () => void;
  changeOrder: (unitID: string, from: number, to: number) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    fetchContents: async () => {
      await dispatch(fetchContents());
    },
    changeOrder: async (unitID: string, from: number, to: number) => {
      await dispatch(changeOrder(unitID, from, to));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(RearrangeScreens);
