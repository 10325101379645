import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardHeader from '../layouts/CardHeader'

const Projects = (props: Partial<IAdminDashboard>) => {

    if (props.activeProjects === undefined) return <></>
    
    const chartLabels: string[] = Object.keys(props?.activeProjects?.activeProjectsStages).map(label => label.charAt(0).toUpperCase() + label.substr(1));
    const chartData: number[] = Object.values(props?.activeProjects?.activeProjectsStages)
    const projectCount = props?.activeProjects?.count
    return (
        <div className="dashboard-layout--projects">
            <Card>
                <CardHeader title="Projects" iconName="Projects" />
                <CardContent variant="LineChart" labels={chartLabels} data={chartData} value={projectCount} />
            </Card>
        </div>
    )
}

export default Projects