import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../../common/utils";

export const headerGSSReportingRow: IColumn[] = [
  {
    key: "Site name",
    name: "Site name",
    fieldName: "Site name",
   minWidth: 120,
    data: "string",
    ...isSortable,
  },
  {
    key: "Generation(kWh)",
    name: "Generation(kWh)",
    fieldName: "Generation(kWh)",
    minWidth: 90,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["Generation(kWh)"]} closeDelay={500}>{item["Generation(kWh)"]}</TooltipHost>
  },
  {
    key: "Import(kWh)",
    name: "Import(kWh)",
    fieldName: "Import(kWh)",
   minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["Import(kWh)"]} closeDelay={500}>{item["Import(kWh)"]}</TooltipHost>
  },
  {
    key: "Export(kWh)",
    name: "Export(kWh)",
    fieldName: "Export(kWh)",
   minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["Export(kWh)"]} closeDelay={500}>{item["Export(kWh)"]}</TooltipHost>
  },
  {
    key: "Consumption(kWh)",
    name: "Consumption(kWh)",
    fieldName: "Consumption(kWh)",
   minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["Consumption(kWh)"]} closeDelay={500}>{item["Consumption(kWh)"]}</TooltipHost>
  },
  {
    key: "SelfConsumption(kWh)",
    name: "SelfConsumption(kWh)",
    fieldName: "SelfConsumption(kWh)",
   minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["SelfConsumption(kWh)"]} closeDelay={500}>{item["SelfConsumption(kWh)"]}</TooltipHost>
  },
  {
    key: "Target(kWh)",
    name: "Target(kWh)",
    fieldName: "Target(kWh)",
   minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["Target(kWh)"]} closeDelay={500}>{item["Target(kWh)"]}</TooltipHost>
  },
  {
    key: "PV consumption ratio %",
    name: "PV consumption ratio %",
    fieldName: "PV consumption ratio %",
    minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["PV consumption ratio %"]} closeDelay={500}>{item["PV consumption ratio %"]}</TooltipHost>
  },
  {
    key: "PV export ratio %",
    name: "PV export ratio %",
    fieldName: "PV export ratio %",
    minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["PV export ratio %"]} closeDelay={500}>{item["PV export ratio %"]}</TooltipHost>
  },
  {
    key: "PV supply ratio %",
    name: "PV supply ratio %",
    fieldName: "PV supply ratio %",
    minWidth: 120,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item["PV supply ratio %"]} closeDelay={500}>{item["PV supply ratio %"]}</TooltipHost>
  },
  
];
