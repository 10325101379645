import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardFooter from '../layouts/CardFooter'
import CardHeader from '../layouts/CardHeader'
import { BackContent as LiveMetersBackContent, FrontContent as LiveMetersFrontContent } from './contents/LiveMetersFlipContent'
import { Footer as LiveMetersFrontFooter } from './footer/LiveMetersFooter'

const LiveMeters = (props: Partial<IAdminDashboard>) => {

    if(props?.liveMeters === undefined) return <></>
     
    return (
        <div className="dashboard-layout--liveMeters">
            <Card liveMetersData={props?.liveMeters} >
                <CardHeader
                    title="Live Meters"
                    iconName="LiveMeters"
                />
                <CardContent variant='Flipper' frontContent={LiveMetersFrontContent} backContent={LiveMetersBackContent} />
                <CardFooter variant='PlainFooter' frontFooter={LiveMetersFrontFooter} isHaveFlipper={true} />
            </Card>
        </div>
    )
}

export default LiveMeters
