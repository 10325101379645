import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { generateQueryString } from "../../utils/util"
import { host } from "../config"

export const getPipelinesApi = (query: any = {}): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/pipelines?${queryStr}`).then(res => res.data)
}
export const getPipelinesStagesApi = (query: any = {}): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/pipelines/stages?${queryStr}`).then(res => res.data)
}

export const getPipeLineDealByIdApi = (dealId: number): Promise<any> => {
  return axios.get(`${host}/pipeline-deal/${dealId}`).then(res => res.data)
}
