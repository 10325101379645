import { IInvoiceAccountReportFilter } from "../../../../components/admin/reporting/invoiceAccountReporting/types";
import {
  Action,
} from "./actionTypes";
import moment from "moment";

// States' definition
export interface IInvoiceAccountReport {
  invoiceAccountReportFilter: IInvoiceAccountReportFilter;
  invoiceAccountReportData: any[];
  totalCount: number;
}
export interface State {
  invoiceAccountReport: IInvoiceAccountReport;
}

const invoiceAccountReport = (
  state: IInvoiceAccountReport = {
    invoiceAccountReportFilter: {
      cycle: 'all',
      customerName: '',
      contractNumber: '',
      page: 1,
      pageSize: 50,
      sortField: 'date',
      sortOrder: 'desc',
      isGenerated: 'all',
    },
    invoiceAccountReportData: [],
    totalCount: 0
  },
  action: Action
): IInvoiceAccountReport => {
  switch (action.type) {
    case "REPORT/SET_INVOICE_ACCOUNT_REPORT_FILTER": {
      return { ...state, invoiceAccountReportFilter: action.invoiceAccountReportFilter };
    }
    case "REPORT/SET_INVOICE_ACCOUNT_REPORT_LIST": {
      const invoiceAccountReportData = action.reset ? action.invoiceAccountReportData : [...state.invoiceAccountReportData, ...action.invoiceAccountReportData]
      return { ...state, invoiceAccountReportData };
    }
    case 'REPORT/SET_INVOICE_ACCOUNT_REPORT_TOTALS_COUNT': {
      return { ...state, totalCount: action.totalCount }
    }
    default:
      return state;
  }
};

export default invoiceAccountReport;
