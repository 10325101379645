import { ComboBox, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchProducts, setProductFilter } from '../../../store/admin/product/action';
import { IProduct } from '../../../store/admin/product/reducer';
import { getTaskTypes, setTaskTypeFilter } from '../../../store/admin/taskType/action';
import { ITaskTypeState } from '../../../store/admin/taskType/reducer';
import { Regions } from '../common/common';
import { TaskTypes } from '../task/common';

const TaskTypeFilters = (props: TaskTypeFilterProps) => {
    const dispatch = useDispatch()
    const { taskTypeFilter }: ITaskTypeState = useSelector<RootState, ITaskTypeState>((state) => state.web.taskType)
    const handleOnChangeInput = (
        value?: any,
        key?: string
    ) => {
        const params = { ...taskTypeFilter, page: 1, [key!]: value };
        dispatch(setTaskTypeFilter(params));
        switch (key) {
            case 'region':
                dispatch(getTaskTypes(params));
                break;
            default:
                if (value?.length === 0 || (value && value.length > 3)) {
                    dispatch(getTaskTypes(params));
                }
                break;
        }
    }

    const { name, region, taskType } = taskTypeFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Name"
                    placeholder="Enter Name"
                    type="text"
                    onChange={(e, value) => handleOnChangeInput(value, "name",)}
                    value={name!}
                    className="ms-Grid-col ms-lg4"
                />
                <ComboBox
                    label="Region"
                    selectedKey={region ? region : "all"}
                    options={Regions}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChangeInput(item?.key, 'region')}
                    className="ms-Grid-col ms-lg2"
                />
                 <ComboBox
                    label="Type"
                    selectedKey={taskType|| "all"}
                    options={TaskTypes}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChangeInput(item?.key, 'taskType')}
                    className="ms-Grid-col ms-lg2"
                />
            </div>
        </div>
    );
}

interface TaskTypeFilterProps {

}

export default TaskTypeFilters;

