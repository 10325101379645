import _ from 'lodash';
import { Action } from "./actionTypes";
import { IInvoiceDocument, IInvoiceFilter } from '../../../components/admin/invoice/types';
import { DropdownParams } from '../../../components/admin/common/types';

// States' definition
export interface IInvoice {
  isLoading: boolean;
  invoices: IInvoiceDocument[];
  invoiceFilter: IInvoiceFilter;
  cycleFilter: DropdownParams[];
  totalCount: number
}
export interface State {
  invoice: IInvoice;
}

const invoice = (
  state: IInvoice = {
    isLoading: false,
    invoices: [],
    invoiceFilter: {
      cycle: 'all',
      customerName: '',
      contractNumber: '',
      page: 1,
      pageSize: 50,
      sortField: 'date',
      sortOrder: 'desc',
      isGenerated: 'all',
    },
    cycleFilter: [],
    totalCount: 0
  },
  action: Action
): IInvoice => {
  switch (action.type) {
    case 'SET_CYCLE_FILTER':
      return { ...state, cycleFilter: action.cycles }
    case 'SET_INVOICE_FILTER':
      return { ...state, invoiceFilter: action.invoiceFilter }
    case 'SET_INVOICE_LIST':
      const invoices = action.reset ? action.invoices : [...state.invoices, ...action.invoices]
      return { ...state, invoices }
    case 'SET_INVOICE_TOTALS_COUNT': {
      return { ...state, totalCount: action.totalCount }
    }
    case 'BILLING/DELETE_INVOICE': {
      const stateObj = { ...state }
      const invoices: IInvoiceDocument[] = stateObj.invoices.filter(s => s._id?.toString() !== action.invoiceId.toString())
      const invoiceFilter = { ...stateObj.invoiceFilter }
      const { page, pageSize } = invoiceFilter
      return { ...state, invoices, totalCount: stateObj.totalCount - 1, invoiceFilter: { ...invoiceFilter, page: Math.ceil(invoices.length / page! * pageSize!) } }
    }
    case 'BILLING/UPDATE_INVOICE': {
      const invoices = [...state.invoices]
      const index = invoices.findIndex(s => s._id?.toString() === action.invoice?._id?.toString())
      if (index > -1) {
        invoices[index] = action.invoice
      } else {
        invoices.unshift(action.invoice)
      }
      return { ...state, invoices, totalCount: index > -1 ? state.totalCount : state.totalCount + 1 }
    }
    default:
      return state;
  }
};

export default invoice;
