import { Action } from "./actionTypes";
import { ITaskFilter } from "../../../components/admin/task/types";
import { DefaultTaskFilters } from "../../../components/admin/task/common";
import { ITask } from "@solarforschools/sfs-core/dist/task/types";


// States' definition
export interface ITaskState {
  isLoading: boolean;
  tasks: ITask[];
  taskFilter: ITaskFilter;
  totalCount: number
}
export interface State {
  task: ITaskFilter;
}

const task = (
  state: ITaskState = {
    isLoading: false,
    tasks: [],
    taskFilter: DefaultTaskFilters,
    totalCount: 0
  },
  action: Action
): ITaskState => {
  switch (action.type) {
    case 'SET_TASK_FILTER':
      return { ...state, taskFilter: action.taskFilter }
    case 'SET_TASK_LIST':
      const tasks = action.reset ? action.tasks : [...state.tasks, ...action.tasks]
      return { ...state, tasks, totalCount: action.totalCount }
    // case 'BILLING/DELETE_PERIOD': {
    //   const stateObj = { ...state }
    //   const periods: IBillingPeriod[] = stateObj.periods.filter(s => s._id?.toString() !== action.periodId.toString())
    //   const periodFilter = { ...stateObj.periodFilter }
    //   const { page, pageSize } = periodFilter
    //   return { ...state, periods, totalCount: stateObj.totalCount - 1, periodFilter: { ...periodFilter, page: Math.ceil(periods.length / page! * pageSize!) } }
    // }
    case 'UPDATE_TASK_LIST': {
      const tasks = [...state.tasks]
      const index = tasks.findIndex(s => s._id?.toString() === action.task?._id?.toString())
      if (index > -1) {
        tasks[index] = action.task
      } else {
        tasks.unshift(action.task)
      }
      return { ...state, tasks, totalCount: index > -1 ? state.totalCount : state.totalCount + 1 }
    }
    default:
      return state;
  }
};

export default task;
