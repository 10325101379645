import axios, { Axios, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { isLoading } from "../action";
import {
  ISetFiles,
  ISetProjects,
  ISetProjectsForCalc,
  ISetRegions,
  ISetRegionsForCalc,
  ISetRegionsForProject,
  ISetSchools,
  ISetTemplates,
  ISetTemplatesForCalc,
  ISetTemplatesForProject,
} from "./actionTypes";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { host } from "../../config";
import {
  GenerateReportParams,
  IFileDetails,
  IFilterProjects,
  IFilterSchools,
} from "../../../components/admin/generateReport/types";
import { ITemplate } from "@solarforschools/sfs-core/dist/types";
import { Sites } from "@solarforschools/sfs-core/dist/msgraph/types";
// import download from "downloadjs";

// Action Creators
const setRegions = (payload: string[]): ISetRegions => {
  return { type: "SET_REGIONS", payload };
};

const setSchools = (payload: IFilterSchools[]): ISetSchools => {
  return { type: "SET_SCHOOLS", payload };
};

const setRegionsForCalc = (payload: string[]): ISetRegionsForCalc => {
  return { type: "SET_REGIONS_FOR_CALC", payload };
};

const setProjectsForCalc = (
  payload: IFilterProjects[]
): ISetProjectsForCalc => {
  
  return { type: "SET_PROJECTS_FOR_CALC", payload };
};

const setTemplatesForCalc = (payload: ITemplate[]): ISetTemplatesForCalc => {
  return { type: "SET_TEMPLATES_FOR_CALC", payload };
};

export const resetState = () => {
  return {
    type: "RESET_STATE",
  };
};

export const setTemplates = (payload: ITemplate[]): ISetTemplates => {
  return {
    type: "SET_TEMPLATES",
    payload,
  };
};

export const setFiles = (payload: IFileDetails | {}): ISetFiles => {
  return {
    type: "SET_FILES",
    payload,
  };
};
export const setRegionsForProject = (
  payload: string[]
): ISetRegionsForProject => {
  return {
    type: "SET_REGIONS_FOR_PROJECT",
    payload,
  };
};
export const setProjects = (payload: IFilterProjects[]): ISetProjects => {
  
  return {
    type: "SET_PROJECTS",
    payload,
  };
};

export const setTemplatesForProject = (
  payload: ITemplate[]
): ISetTemplatesForProject => {
  return {
    type: "SET_TEMPLATES_FOR_PROJECT",
    payload,
  };
};

// Redux Thunk (API)
export const fetchRegions = () => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/generate/report/regions`;

      const response: AxiosResponse = await axios.get(url);

      if (response.status === 200) {
        dispatch(setRegions(response.data));
        dispatch(isLoading(false));
        return;
      }
      toast.error("Error in fetching regions");
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error in fetching regions ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const fetchSchools = (region: string) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      dispatch(isLoading(true));

      const url = `${host}/generate/report/schools/${region}`;

      const response: AxiosResponse = await axios.get(url);

      if (response.status === 200) {
        dispatch(setSchools(response.data));

        dispatch(isLoading(false));
        return;
      }
      toast.error("Error in fetching schools");
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error in fetching schools ", err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

export const fetchTemplates = (region: string, isCalc = false) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      dispatch(isLoading(true));
      const site = "school";
      const url = !isCalc
        ? `${host}/report/templates/${site}/${region}`
        : `${host}/report/templates/calc/${region}`;

      const response = await axios.get(url);

      if (response.status === 200) {
        !isCalc
          ? dispatch(setTemplates(response.data))
          : dispatch(setTemplatesForCalc(response.data));
        dispatch(isLoading(false));
        return;
      }
      toast.error("Error in fetching schools");
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error in fetching templates ", err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

export const fetchFiles = (page: number, reportType: "project" | "school") => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    const url = `${host}/generate/report/files/${reportType}?page=${page}`;

    try {
      //   dispatch(isLoading(true));
      const response: AxiosResponse = await axios.get(url);

      if (response.status === 200) {
        dispatch(setFiles(response.data));
        dispatch(isLoading(false));
        return;
      }
      toast.error("Something went wrong");
      //   dispatch(isLoading(false));
    } catch (err) {
      console.log("Error in fetching files ", err);
      toast.error("Something went wrong");
      // dispatch(isLoading(false));
    }
  };
};

export const generateReport = (
  params: GenerateReportParams,
  reportType: "project" | "school"
) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    toast.success("Report is generating please wait... don't refresh the page");

    dispatch(isLoading(true));
    let url = `${host}/report/generate`;
    url += `?format=stream`;

    const token = localStorage.getItem("token");
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers,
    })
      .then((res) => {
        if (!res.ok) {
          toast.error("Failed to download report");
          return null;
        }
        return res.blob();
      })
      .then((data) => {
        dispatch(isLoading(false));
        if (data) {
          toast.success("Report downloaded successfully");
          dispatch(fetchFiles(1, reportType));
        }
        //! downloadingFiles
        // const blob = new Blob([data], { type: "application/zip" });
        // download(blob, `${params.schoolSlug}.zip`, "application/zip");
      })
      .catch((err) => {
        dispatch(isLoading(false));
        console.log("Error generating report ", err);
        toast.error("Something went wrong in generating report");
      });
  };
};

export const generateReportForCalc = ({
  region,
  projectSlug,
  reportTemplate,
}: {
  region: string;
  projectSlug: string;
  reportTemplate: ITemplate;
}) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      toast.success(
        "Report is generating please wait... don't refresh the page"
      );

      dispatch(isLoading(true));
      let url = `${host}/report/template/calculator`;
      const params = { region, projectSlug, reportTemplate };

      const response = await axios.post(url, params);
      dispatch(isLoading(false));

      if (response.status === 200) {
        toast.success("Calculator generated successfully");
        const { msg } = response.data;
        const link = document.createElement("a");
        link.href = msg;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("Something went wrong in generating calculator report");
        console.log("Error: ", response);
      }
    } catch (err) {
      dispatch(isLoading(false));
      toast.error("Something went wrong");
      console.log("Error: ", err);
    }
  };
};

export const fetchRegionsForProject = (isCalc = false) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/generate/report/project/regions`;

      const response: AxiosResponse = await axios.get(url);

      if (response.status === 200) {
        !isCalc
          ? dispatch(setRegionsForProject(response.data))
          : dispatch(setRegionsForCalc(response.data));
        dispatch(isLoading(false));
        return;
      }
      toast.error("Error in fetching regions");
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error in fetching regions ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const fetchProjects = (region: string, isCalc = false) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      dispatch(isLoading(true));

      const url = `${host}/generate/report/project/${region}`;

      const response: AxiosResponse = await axios.get(url);
      

      if (response.status === 200) {
        !isCalc
          ? dispatch(setProjects(response.data))
          : dispatch(setProjectsForCalc(response.data));
        dispatch(isLoading(false));
        return;
      }
      toast.error("Error in fetching schools");
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error in fetching schools ", err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

export const fetchTemplatesForProject = (region: string) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      dispatch(isLoading(true));
      const site = "project";
      const url = `${host}/report/templates/${site}/${region}`;
      const response: AxiosResponse = await axios.get(url);
      if (response.status === 200) {
        dispatch(isLoading(false));
        dispatch(setTemplatesForProject(response.data));
        return;
      }
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    } catch (error) {
      console.log("Error in fetching templates", error);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const updateReportTemplatesInCache = (site: Sites) => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/report/templates/update-cache`;
      const { data }: AxiosResponse = await axios.patch(url, { site });
      toast.success(data.msg);
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      dispatch(isLoading(false));
    }
  };
};
