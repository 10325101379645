import {
    PrimaryButton,
    Stack,
    Sticky,
    StickyPositionType,
} from "@fluentui/react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "../../../store";
import ModalDialog from "../common/ModalDialog";
import { IComments } from "@solarforschools/sfs-core/dist/comment/types";
import { commentsTextDiv, renderNormalTextDiv, renderPicsArray } from "./Helpers";


export const openViewComment = (props: IComments) => {
    return new Promise<any | undefined>((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget);
        const callback = (result?: any) => {
            resolve(result);
            ReactDOM.unmountComponentAtNode(mountTarget);
            mountTarget.remove();
        };

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"View Comment"}
                    onDismiss={() => callback(undefined)}
                // containerClassName="modal-size-large"
                >
                    <ViewCommentDialog
                        comment={props}
                        onCancel={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        );
    });
};


interface IProps {
    comment: IComments;
    onCancel: () => void
}

const ViewCommentDialog = (props: IProps) => {
    const { comment } = props

    return (
        <div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <div
                    style={{
                        maxWidth: "50vw",
                        margin: "0 auto"
                    }}
                >

                    <div style={{ display: "flex", flexDirection: "column", marginTop: "0.5rem" }}>

                        {renderNormalTextDiv("schoolName", comment?.schoolName)}

                        {renderNormalTextDiv("type", comment?.type)}

                        {renderNormalTextDiv("userName", comment?.userName)}

                        {commentsTextDiv("commentText", comment?.commentText)}

                        <>
                            {renderPicsArray("pics", comment.pics)}
                        </>

                        <div className="flex-row-center" style={{ justifyContent: "space-between", marginTop: "0.75rem" }}>
                            {renderNormalTextDiv("createdAt", comment.createdAt)}
                            {renderNormalTextDiv("updatedAt", comment.updatedAt)}
                        </div>

                    </div>
                    <Stack horizontal horizontalAlign="center" className="margin-top-xs">
                        <Stack.Item>
                            <PrimaryButton
                                text="Close"
                                onClick={props.onCancel}
                                className="btn-primary"
                            />
                        </Stack.Item>
                    </Stack>
                </div>
            </Sticky>
        </div>
    );
};

export default ViewCommentDialog;


