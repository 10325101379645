import { Action } from "./actionTypes";
import { IBlog } from "@solarforschools/sfs-core/dist/blog/types";
import { StringMap } from "../../../components/admin/common/types";
import { IBlogfilter } from "../../../components/admin/blog/types";

// States' definition
export interface IBlogs {
  isLoading: boolean;
  blogs: IBlog[];
  count: number;
  blogFilter: IBlogfilter;
  isFetching: boolean;
  sites: any[];
  categories: any[]
}
export interface State {
  blogs: IBlogs;
}

const blogs = (
  state: IBlogs = {
    isLoading: false,
    blogs: [],
    blogFilter: {
      pageSize:50,
      page:1,
      sortField:"name",
      sortOrder:"asc"
    },
    count: 0,
    isFetching: false,
    sites: [],
    categories: []
  },
  action: Action
): IBlogs => {
  switch (action.type) {
    case "SET_ALL_BLOGS":
      return {
        ...state,
        blogs: action.replace
          ? action.blogs
          : [...state.blogs!, ...action.blogs],
      };
    case "SET_TOTAL_BLOGS_COUNT":
      return { ...state, count: action.count };
    case "SET_BLOG_FILTERS":
      return { ...state, blogFilter: action.blogFilters };
    case "SET_BLOG_SITES":
      return { ...state, sites: action.sites };
    case "SET_BLOG_CATEGORIES":
      return { ...state, categories: action.categories };
    default:
      return state;
  }
};

export default blogs;
