import React from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import loader from "../../../lotties/loader.json";
import { RootState } from '../../../store';
import { IAdmin } from '../../../store/admin/reducer';

export default function Loader() {
  const admin: IAdmin = useSelector<RootState, IAdmin>((state) => state.web.admin)
  const navBarWidth = 200;
  return (
    <>
      {admin.isLoading && (
        <div className="popup-overlay"
        // style={{ left: navBarWidth, width: `calc(100vw - ${navBarWidth}px)` }}
        >
          <span>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loader,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={400}
              width={400}
            />
          </span>
        </div>
      )}</>
  );
}
