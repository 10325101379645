import {
    DefaultButton,
    IBasePickerSuggestionsProps,
    ITag,
    Label,
    PrimaryButton,
    Stack,
    Sticky,
    StickyPositionType,
    TagPicker,
    Text,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { toast } from "react-toastify";
import { isLoading } from "../../../store/admin/action";
import { fetchLiveSchools, insertASupporter } from "../../../store/admin/supporters/action";
import { IInitialState } from "../../../store/admin/supporters/reducer";
import { descriptionOptions, getGeneralFields, uploadImage, validateDetails } from "./helper";
import { useBoolean } from '@fluentui/react-hooks';
import { ISchools } from "@solarforschools/sfs-core/dist/supporters/types";
import { ISupporters } from "@solarforschools/sfs-core/dist/supporters/types";
import { IoImagesOutline } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { MdeEditor } from "../common/MdeEditor";

// Open the modal dialog - return promise(Await to resolve data)
export const openAddSupporterDialog = () => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget);
        const callback = (result?: IProps[]) => {
            resolve(result);
            ReactDOM.unmountComponentAtNode(mountTarget);
            mountTarget.remove();
        };

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add supporter to school"}
                    onDismiss={() => callback(undefined)}
                // containerClassName="modal-size-md"
                >
                    <CreateSupporter
                        onCancel={() => callback(undefined)}
                        onSave={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        );
    });
};

const CreateSupporter = (props: IProps) => {


    const { liveSchools } = useSelector<RootState, IInitialState>((state) => state.web.supporters)
    const [tagPicker] = useBoolean(false);

    const dispatch = useDispatch()
    const [errors, setErrors] = useState<any>({});
    const [fields] = useState(getGeneralFields);

    const [tempPics, setTempPics] = useState<any>("");
    const [selectedFiles, setSelectedFiles] = useState<any>("");
    const [data, setData] = useState<Partial<ISupporters>>({})

    useEffect(() => {
        dispatch(fetchLiveSchools())
    }, [])

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested schools',
        noResultsFoundText: 'No schools found',
    };


    const handleOnChangeInput = (key: string, item: any) => {
        setData({ ...data, [key]: item })
    }

    const handleS3Upload = async (files: any) => {
        if (!files || files.length === 0) return
        dispatch(isLoading(true))
        const images: string = await uploadImage(files)
        dispatch(isLoading(false))
        return images
    }


    const handleOnSave = async () => {

        const error = await validateDetails(data);
        setErrors(error)

        if (Object.keys(error).length) return;

        if (!data["schools"] || data["schools"].length === 0) {
            toast.error("Add supporter's school")
            return
        }

        if (!data["description"]) {
            toast.error("Supporter description cannot be empty")
            return
        }

      
        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Confirm Save",
                closeButtonAriaLabel: "Close",
                subText: `Do you want to save the data ?`,
            },
            confirmBtnText: "Save",
        });
        if (isConfirmed) {
            let uploadedImage = await handleS3Upload(selectedFiles);
            if (selectedFiles?.length > 0 && !uploadedImage) {
                toast.error("Error in uploading image")
                return;
            }

            data["icon"] = uploadedImage ? `supporters/${uploadedImage}` : ""


            dispatch(insertASupporter(data))
            props.onSave(); // on save
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files[0]) {
            let newUrl = URL.createObjectURL(e.target.files[0]);
            setTempPics(newUrl);
            setSelectedFiles([...selectedFiles, e.target.files[0]])
        }

    }

    const handleDelete = () => {
        setTempPics("")
    }

    const listContainsTagList = (tag: ISchools, tagList?: ISchools[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
    };

    const filterSuggestedTags = (filterText: string, tagList: ISchools[]): ISchools[] => {
        return filterText
            ? liveSchools.filter(
                tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
            )
            : [];
    };


    const getTextFromItem = (item: ISchools) => item.name as string;


    return (
        <div>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <DynamicForm
                        fields={fields}
                        data={data}
                        errors={errors}
                        onChange={handleOnChangeInput}
                    />
                    <div>
                        <Label>Supporter's school</Label>
                        <TagPicker
                            onResolveSuggestions={filterSuggestedTags as any}
                            getTextFromItem={getTextFromItem as any}
                            pickerSuggestionsProps={pickerSuggestionsProps}
                            disabled={tagPicker}
                            onChange={(items?: ITag[]) => handleOnChangeInput('schools', items)}
                            inputProps={{ placeholder: "Choose a school" }}
                        />
                    </div>
                </div>


                <div className="ms-Grid-row" style={{ maxWidth: "40rem" }}>
                    <Label className="margin-top-md">Description</Label>
                    <MdeEditor
                        options={descriptionOptions}
                        onChange={handleOnChangeInput}
                        value={data.description}
                        field={"description"}
                    />
                </div>
            </div>

            <div style={{ margin: "1rem 0 0 0.5rem" }}>
                <Label>Supporter's Logo</Label>
            </div>


            <label style={{ display: "block", width: "fit-content" }}>
                <div style={{ marginLeft: "1rem", cursor: "pointer", border: "1px solid lightgrey", padding: "0.25rem 1rem" }}>
                    <IoImagesOutline /> Choose a Logo
                </div>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => handleImageChange(e)}
                    style={{ display: "none" }}
                />
            </label>

            <div style={{ width: 200, height: 200, margin: "auto", position: "relative" }}>
                {
                    tempPics ? (
                        <>
                            <div
                                style={{
                                    position: "absolute",
                                    top: -10,
                                    cursor: "pointer",
                                    right: -10,
                                    color: "red"
                                }}
                                onClick={handleDelete}
                            >
                                <IoIosCloseCircle size="1.25rem" />
                            </div>
                            <img
                                src={tempPics}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                alt={`picture`}
                            />
                        </>
                    ) : <div className="flex-row-center" style={{ opacity: "0.6", background: "lightgrey", height: "100%" }}>
                        <Text variant="medium">Upload logo to preview</Text>
                    </div>
                }
            </div>



            <Sticky stickyPosition={StickyPositionType.Footer}>
                <div
                    style={{
                        display: "flex",
                        marginTop: "1rem",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Stack horizontal horizontalAlign="center" className="margin-top-xs">
                        <Stack.Item>

                            <PrimaryButton
                                text="Save"
                                onClick={handleOnSave}
                                className="btn-primary"
                            />
                            <DefaultButton onClick={props.onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </div>
            </Sticky>
        </div>
    );
};


interface IProps {
    onCancel: () => void;
    onSave: () => void;
}


