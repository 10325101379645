import {
  CheckboxVisibility,
  ScrollablePane,
  ScrollbarVisibility,
} from "@fluentui/react";
import { SelectionMode } from "@fluentui/react/lib/index";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IBlogs } from "../../../store/admin/blog/reducers";
import {
  changeFilters,
  deleteBlog,
  fetchAllBlogs,
} from "../../../store/admin/blog/action";
import DetailsListWrapper from "../../common/DetailsListWapper";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { getBlogColumn } from "./BlogHelper";
import { websiteHost } from "../../../store/config";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { OpenBlogModal } from "./AddEditBlogDialog";
import { IBlog } from "@solarforschools/sfs-core/dist/blog/types";
import Pagination from "../common/Pagination";

const BlogList = () => {
  const dispatch = useDispatch();
  const { blogs, count, isFetching, blogFilter } = useSelector<RootState, IBlogs>(
    (state) => state.web.blogs
  );

  const handleSort = (sortField: string, sortOrder: string) => {
    setFormElement({sortField, sortOrder})
  };

  const handleOnDelete = async (blog: IBlog) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Blog",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ?`,
      },
    });
    if (confirm) dispatch(deleteBlog(blog._id!));
  };

  const handleOnEdit = (blog: IBlog) => OpenBlogModal({ params: blog })

  const [columns] = useState(
    getBlogColumn({
      actions: [
        {
          icon: "PageLink",
          ariaLabel: "Link",
          onClick: (item: IBlog) =>
            window.open(`${websiteHost}/admin/blogs/${item._id}`),
        },

        {
          icon: "SingleColumnEdit",
          ariaLabel: "Edit Blog",
          onClick: handleOnEdit,
        },
        {
          icon: "Trash",
          ariaLabel: "Delete Blog",
          onClick: handleOnDelete,
        },
      ],
    })
  );
  const setFormElement = (p:any) => {
    const params = { ...blogFilter, ...p };
    dispatch(changeFilters(params));
    dispatch(fetchAllBlogs(true));
  };

  return (
    <>
      <div className="data-list-container">
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsListWrapper
            items={blogs!}
            columns={columns}
            selectionMode={SelectionMode.none}
            onRenderDetailsHeader={DetailListStickyHeader}
            compact={true}
            checkboxVisibility={CheckboxVisibility.always}
            isLoading={isFetching}
            canLoadMore={blogs.length < count}
            // onRequestLoadMore={() => dispatch(loadNextPage())}
            onSort={handleSort}
          />
        </ScrollablePane>
      </div>
      <Pagination
        totalRecords={count}
        onPaginate={(page) => setFormElement({page})}
        currentPage={blogFilter?.page!}
        pageSize={blogFilter.pageSize!}
      />
    </>
  );
};

export default BlogList;
