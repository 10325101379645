import { Checkbox, ComboBox, DatePicker, Dropdown, IComboBoxOption } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { isLoading } from '../../../store/admin/action';
import { getMeterReadings, setMeterReadingFilter } from '../../../store/admin/meterReading/action';
import { IMeterReadingState } from '../../../store/admin/meterReading/reducer';
import { comboBoxStyles, dropdownStyles, ReadingTypes } from '../common/common';
import { Providers } from '../system/common';
import { MeterTypes } from './common';
import { getMetersOptions } from './helper';
import SystemPicker from '../common/SystemPicker';
import SchoolPicker from '../common/SchoolPicker';

const MeterFilters = (props: MeterFilterProps) => {
    const dispatch = useDispatch()
    const { meterReadingFilter }: IMeterReadingState = useSelector<RootState, IMeterReadingState>((state) => state.web.meterReading)
    const [meters, setMeters] = useState<any>([])
    const handleOnChange = async (
        key?: any,
        item?: any
    ) => {
        let filterParams = { ...meterReadingFilter };
        let filters = {}
        switch (key) {
            case "meterType":
                let types = filterParams.type || [];
                if (item?.selected) {
                    if (!types?.find((s) => s === item?.key)) {
                        types.push(item.key);
                    }
                } else {
                    types = types?.filter((s) => s !== item?.key) as string[];
                }
                filters = { type: types };
                break;
            case "selectedSchool":
                filters = { ...filters, schoolSlug: item[0]?.key }
                break;
            case 'selectedSystems':
                filters = { ...filters, system: item[0]?.key }
                break
            default:
                filters = { [key as string]: item };
                break;
        }
        dispatch(isLoading(true))
        let params = { ...meterReadingFilter, page: 1, ...filters };
        if (key === 'selectedSchool') {
            const { meters } = await getMetersOptions({ systemId: params.system || 'all', schoolSlug: params.schoolSlug || 'all' })
            setMeters(meters)

        } else if (key === 'selectedSystems') {
            params = { ...params, mid: 'all' }
            const { meters } = await getMetersOptions({ systemId: params.system || 'all', schoolSlug: params.schoolSlug || 'all' })
            setMeters(meters)
        }
        dispatch(isLoading(false))
        dispatch(setMeterReadingFilter(params));
        dispatch(getMeterReadings(params));
    }

    const params = { stage: ['Operate', 'Build', 'Developed', 'Funded', 'Managed'], live: true }

    const { schoolSlug, system, start, end, provider, type, rType, mid, startEnd } = meterReadingFilter || {}
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
                    <SchoolPicker filters={params} onChange={handleOnChange} />
                </div>
                <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
                    <SystemPicker filters={{ schoolSlug }} onChange={handleOnChange} />
                </div>
                <ComboBox
                    label="Meter"
                    placeholder="Choose Meter"
                    selectedKey={mid?.toString() || "all"}
                    allowFreeform={true}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChange("mid", item?.key?.toString() || 'all')}
                    options={[{ key: 'all', text: 'All' }, ...meters]}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <ComboBox
                    label="Provider"
                    placeholder="Choose"
                    selectedKey={provider || "all"}
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={Providers as IComboBoxOption[]}
                    onChange={(e, item?: IComboBoxOption) =>
                        handleOnChange("provider", item?.key?.toString() || 'all')
                    }
                    className="ms-Grid-col ms-lg1"
                    styles={comboBoxStyles}
                />
                <ComboBox
                    label="Meter Type"
                    selectedKey={type! || null}
                    placeholder="Select"
                    multiSelect
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={
                        MeterTypes.filter((s) => s.key !== "all") as IComboBoxOption[]
                    }
                    onChange={(e, item) => handleOnChange('meterType', item)}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg2"
                />
            </div>
            <div className="ms-Grid-row">
                <DatePicker
                    label="Start Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(moment(start).format('YYYY-MM-DD'))!}
                    onSelectDate={(date: any) => handleOnChange('start', moment(date).format('YYYY-MM-DD'))}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <DatePicker
                    label="End Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(moment(end).format('YYYY-MM-DD'))!}
                    onSelectDate={(date: any) => handleOnChange('end', moment(date).format('YYYY-MM-DD'))}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <Dropdown
                    label="Reading Type"
                    selectedKey={rType || 'all'}
                    options={ReadingTypes}
                    onChange={(e, item) => handleOnChange('rType', item?.key)}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg3"
                />

                <Checkbox
                    label="Show Start End Only"
                    checked={startEnd}
                    onChange={(e, value) => handleOnChange("startEnd", value)}
                    className="ms-Grid-col ms-lg3 margin-top-lg"
                />

            </div>
        </div>
    );
}

interface MeterFilterProps {

}
export default MeterFilters;
