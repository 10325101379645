import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { getTransactionColumns } from './TransactionHelper';
import { ActionButton, CheckboxVisibility, CommandBar, IColumn, ICommandBarItemProps, ScrollablePane, ScrollbarVisibility, SelectionMode } from '@fluentui/react';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { IBondTransationState } from './../../../../store/admin/funder/transaction/reducer';
import { setBondTransactionFilter, getBondTransactions, deleteTransaction, downloadTaxStatement } from './../../../../store/admin/funder/transaction/action';
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';
import { ITransaction } from '@solarforschools/sfs-core/dist/funder/transaction/types';
import { OpenAddTransactionModal } from './AddTransactionDialog';
import { toast } from 'react-toastify';
import { isLoading } from '../../../../store/admin/action';
import { getBondTransactionsApi, getBondTransactionEmailPreview, sendBondTransactionEmailApi } from '../../../../store/client/funder/transaction';
import { OpenEmailPreviewModal } from '../../common/EmailPreviewDialog';
import { IBondTransactionFilter, ITransactionEmailParams } from './types';
import { OpenPaymentProcessByTransactionModal } from './ProcessPaymentByTidDialog';
import { OpenCreateTaxStatementModal } from './CreateTaxStatementDialog';
import { isSortable } from '../../common/utils';
import Pagination from '../../common/Pagination';

const BondTransactionList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { transactions, totalCount, transactionFilter }: IBondTransationState = useSelector<RootState, IBondTransationState>((state: RootState) => state.web.transaction)

  const handleOnDelete = async (data: ITransaction) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Transaction",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to Tranaction`,
      },
    });
    if (confirm) dispatch(deleteTransaction(data._id?.toString()!));

  };

  async function handlePaymentStatementEmail(params: ITransactionEmailParams) {
    try {
      const { transactionId, transactionType, actionType = "payment-statement" } = params
      if ((!transactionFilter.period || transactionFilter.period === 'all') && !transactionId) {
        toast.error("Select a payment period!")
        return
      }
      dispatch(isLoading(true))
      const filter: any = transactionId ? { transactionId } : { ...transactionFilter, all: true, status: "Open", actionType, transactionType, isTestTransaction: transactionFilter.isTestTransaction }
      const { transactions } = await getBondTransactionsApi(filter)
      if (!transactions || !transactions.length) {
        toast.error("No open transaction found!")

        return
      } else {
        const { period, action, bondOfferId, type: transactionType } = transactions[0]
        const { html } = await getBondTransactionEmailPreview(transactions[0]._id!.toString(), { period, bondOfferId: bondOfferId.toString(), transactionType, paymentBy: action, action, type: transactionType, actionType })
        dispatch(isLoading(false))
        await OpenEmailPreviewModal({
          params: {
            data: [...transactions],
            title: 'Payment Statement',
            btnTitle: 'Send',
            html,
            sendAction: () => sendBondTransactionEmailApi(transactions[0]._id!.toString(), { period, bondOfferId: bondOfferId.toString(), transactionType, paymentBy: action, action, type: transactionType, actionType })
          }
        })
      }
      return
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong!")
    } finally {
      dispatch(isLoading(false))
    }
  }

  const subMenu = (transaction: ITransaction) => {
    const items: any[] = []

    items.push({
      key: 'send-statement',
      text: `Send ${transaction.type} statement`,
      ariaLabel: `Send ${transaction.type} statement`,
      iconProps: { iconName: 'M365InvoicingLogo' },
      onClick: (e: any) => {
        e?.preventDefault();
        handlePaymentStatementEmail({
          transactionType: transaction.type,
          transactionId: transaction._id,
          actionType: 'payment-statement'
        })
      },
    })
    if (transaction.status === 'Closed')
      if (transaction.type === "interest") {
        items.push({
          key: 'create-tax-statement',
          text: `Create tax Statement`,
          ariaLabel: `Create tax Statement`,
          iconProps: { iconName: 'DrillDown' },
          onClick: (e: any) => {
            e?.preventDefault();
            OpenCreateTaxStatementModal({ params: { transactionId: transaction._id } })
          },
        })
      }
    items.push({
      key: 'send-confirmation',
      text: `Send ${transaction.type} Confimration`,
      ariaLabel: `Send ${transaction.type} Confimration`,
      iconProps: { iconName: 'DrillDown' },
      onClick: (e: any) => {
        e?.preventDefault();
        handlePaymentStatementEmail({
          transactionType: transaction.type,
          transactionId: transaction._id,
          actionType: 'payment-processed'
        })
      },
    })
    if (transaction.status === 'Open' && (transaction.type === "repayment" && transaction.action === "account"))
      items.push({
        key: 'process-payment',
        text: 'Process Payment',
        ariaLabel: `Process ${transaction.type}`,
        iconProps: { iconName: 'AllCurrency' },
        onClick: (e: any) => {
          e?.preventDefault();
          OpenPaymentProcessByTransactionModal({
            transaction
          })
        },
      })
    return items
  }

  async function handleEditTransaction(transaction: ITransaction) {
    const result = await OpenAddTransactionModal({
      onCancel: () => null,
      onSave: () => null,
      transaction
    });
  }


  const getActions = (transaction: ITransaction, actions: any, extraActions?: ICommandBarItemProps[]) => {
    let listActions: ICommandBarItemProps[] = []
    listActions.push(
      {
        key: 'newItem',
        text: '',
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'CollapseMenu' },
        subMenuProps: {
          items: [...subMenu(transaction)],
        },
      })
    for (const action of actions) {
      if (action === 'edit') listActions.push({
        key: 'edit',
        text: '',
        iconProps: { iconName: 'Edit' },
        onClick: (e) => {
          e?.preventDefault();
          handleEditTransaction(transaction);
        },
      })
      if (action === 'delete') listActions.push({
        key: 'delete',
        text: '',
        iconProps: { iconName: 'Trash' },
        onClick: (e) => {
          e?.preventDefault();
          handleOnDelete(transaction);
        },
      })
    }
    return listActions
  }
  const headerRow: IColumn[] = [...getTransactionColumns,
  {
    key: 'taxStatementLink',
    name: 'tax Statement',
    fieldName: 'taxStatementLink',
    minWidth: 70,
    maxWidth: 100,
    ...isSortable,
    ariaLabel: "tax Statement",
    onRender: (t: ITransaction) => {
      return (
        <span>
          {t.status === "Closed" && t.type === "interest" && t.taxStatementLink && (
            <ActionButton iconProps={{ iconName: 'DrillDown' }} onClick={() => dispatch(downloadTaxStatement(t))} >
              Download
            </ActionButton>
          )}
        </span>
      );
    }
  }, {
    key: "action",
    name: "Action",
    fieldName: "-",
    minWidth: 200,
    onRender: (transaction: ITransaction) => {
      return (
        <CommandBar
          items={[...getActions(transaction, actions, [])]}
          ariaLabel="Actions"
          primaryGroupAriaLabel="Actions"
        />
      )
    },
  }]
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...transactionFilter, sortField: field, sortOrder: order };
    dispatch(setBondTransactionFilter(params));
    dispatch(getBondTransactions(params))
  }
  const loadNextPage = (p: IBondTransactionFilter) => {
    dispatch(setBondTransactionFilter({ ...transactionFilter, ...p }))
    dispatch(getBondTransactions())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            {/* <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> */}
            <DetailsListWapper
              items={transactions}
              columns={columns}
              selectionMode={SelectionMode.none}
              onRenderDetailsHeader={DetailListStickyHeader}
              compact={true}
              checkboxVisibility={CheckboxVisibility.hidden}
              canLoadMore={transactions.length < totalCount}
              // onRequestLoadMore={loadNextPage}
              onSort={(field, order) => handleOnSort(field, order)}
            />
            {/* </ScrollablePane> */}
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={transactionFilter?.page!}
            pageSize={transactionFilter.pageSize!}
          />
        </>

      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default BondTransactionList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

