// Action Creators

import { IBondOffer } from "@solarforschools/sfs-core/dist/funder/bond-offer/types";
import { toast } from "react-toastify";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../..";
import { downloadFileFromStream } from "../../../../components/admin/common/utils";
import { IBondOfferFilter } from "../../../../components/admin/funder/bondOffer/types";
import {
  bondOfferRepaymetAPi,
  createBondOfferAPi,
  deleteBondOfferApi,
  exportBondOffersApi,
  genBondOfferTransactiobsApi,
  getBondOffersApi,
  updateBondOfferApi,
} from "../../../client/funder/bondOffer";
import { isLoading, setAuthToken } from "../../action";
import {
  DeleteBondOffer,
  SetBondOfferBondOffers,
  SetBondOfferFilter,
  SetBondOfferList,
  UpdateBondOffer,
} from "./actionType";
import axios from "axios";
import { clearTokenParams } from "../../../../utils/util";

export const setBondOfferFilter = (
  bondOfferFilter: IBondOfferFilter
): SetBondOfferFilter => {
  return { type: "FUNDER/SET_BONDOFFER_FILTER", bondOfferFilter };
};

export const setBondOffersList = (
  bondOffers: IBondOffer[],
  totalCount: number,
  replace: boolean = true
): SetBondOfferList => {
  return { type: "FUNDER/SET_BONDOFFER_LIST", bondOffers, totalCount, replace };
};

export const updateBondOffer = (bondOffer: IBondOffer): UpdateBondOffer => {
  return { type: "FUNDER/UPDATE_BONDOFFER", bondOffer };
};

export const delBondOffer = (bondOffer: IBondOffer): DeleteBondOffer => {
  return { type: "FUNDER/DELETE_BONDOFFER", bondOffer };
};

export const setBondOfferBondoffers = (
  bondOffers: any[]
): SetBondOfferBondOffers => {
  return { type: "FUNDER/BONDOFFER_SET_BONDOFFER_FILTER", bondOffers };
};

export const getBondOffers =
  (params?: IBondOfferFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      // Add token in axios header, if authorization is undefined
      let isThereToken = axios.defaults.headers.common["authorization"];
      if (isThereToken === undefined) {
        let token = localStorage.getItem("token") as string;
        setAuthToken(token);
      }
      clearTokenParams();

      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IBondOfferFilter =
            getState().web.bondOffer.bondOfferFilter;
          reqBody = { ...filters };
        }
        const { bondOffers, totalCount } = await getBondOffersApi(reqBody);
        dispatch(setBondOffersList(bondOffers, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const exportBondOffers =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const filters: IBondOfferFilter =
          getState().web.bondOffer.bondOfferFilter;
        const data: any = await exportBondOffersApi(filters);
        downloadFileFromStream({
          data,
          filename: "export-bondoffers",
          type: "xlsx",
        });
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error("Something happend wrong");
      }
    };

export const getBondBondoffersData =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { bondOffers } = await getBondOffersApi({ all: "true" });
        dispatch(
          setBondOfferBondoffers([
            { key: "all", text: "All" },
            ...bondOffers.map((b) => ({ key: b._id, text: b.name })),
          ])
        );
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error("Something went wrong");
      }
    };

export const deleteBondOffer =
  (offerId: string) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { bondOffer, msg } = await deleteBondOfferApi(offerId);
        updateBondOffer(bondOffer);
        if (msg) toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        toast.error(e?.response?.data?.msg || "Something went wrong");
        dispatch(isLoading(false));
      }
    };

export const createUpdateBondOffer =
  (data: IBondOffer, overrideBond = false) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { bondOffer, msg } = data._id
          ? await updateBondOfferApi(data, overrideBond)
          : await createBondOfferAPi(data);
        const { bondOffers, totalCount } = getState().web.bondOffer;
        if (!data._id) {
          dispatch(
            setBondOffersList([bondOffer, ...bondOffers], totalCount + 1, true)
          );
        } else {
          const index = bondOffers.findIndex(
            (x) => x._id?.toString() === bondOffer._id?.toString()
          );
          const offers = [...bondOffers];
          offers[index] = bondOffer;
          dispatch(setBondOffersList([...offers], totalCount, true));
        }
        toast.success(msg);
        dispatch(isLoading(false));
        return;
      } catch (err: any) {
        console.log(err);
        if (err?.response?.data?.msg) toast.error(err.response.data.msg);
        dispatch(isLoading(false));
      }
    };

export const genBondOfferTransaction =
  (offerId: string) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { bondOffer, msg } = await genBondOfferTransactiobsApi(offerId);
        updateBondOffer(bondOffer);
        if (msg) toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        toast.error(e?.response?.data?.msg || "Something went wrong");
        dispatch(isLoading(false));
      }
    };

export const rePayBondOfferBonds =
  (data: any, id: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { bondOffer, msg } = await bondOfferRepaymetAPi({
          ...data,
          _id: id,
        });
        updateBondOffer(bondOffer);
        if (msg) toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        toast.error(e?.response?.data?.msg || "Something went wrong");
        dispatch(isLoading(false));
      }
    };
