import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardFooter from '../layouts/CardFooter'
import CardHeader from '../layouts/CardHeader'

import DesignsCardContent from "./contents/DesignsCardContent"
import { BackFooter as DesignsBackFooter, FrontFooter as DesignsFrontFooter } from './footer/DesignsFlipFooter'



const Designs = (props: Partial<IAdminDashboard>) => {

    if(props?.designs === undefined) return <></>

    return (
        <div className="dashboard-layout--designs">
            <Card designsData={props?.designs}>
                <CardHeader
                    title="Designs"
                    iconName="Designs"
                />
                <CardContent title="Overall Designs" contents={DesignsCardContent} />
                <CardFooter variant='Flipper' frontFooter={DesignsFrontFooter} backFooter={DesignsBackFooter} />
            </Card>
        </div>
    )
}

export default Designs;