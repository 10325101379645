import { ComboBox, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getUserFunctions, setUserFunctionFilter } from '../../../store/admin/userFunction/action';
import { IUserFunctionState } from '../../../store/admin/userFunction/reducer';
import { Regions } from '../common/common';
import { statusOptions } from '../user/common';

const UserFunctionFilters = (props: UserFunctionFilterProps) => {
    const dispatch = useDispatch()
    const { userFunctionFilter }: IUserFunctionState = useSelector<RootState, IUserFunctionState>((state) => state.web.userFunction)
    const handleOnChangeInput = (
        value?: any,
        key?: string
    ) => {
        const params = { ...userFunctionFilter, page: 1, [key!]: value };
        dispatch(setUserFunctionFilter(params));
        switch (key) {
            case 'country':
            case 'status':
                dispatch(getUserFunctions(params));
                break;
            default:
                if (value?.length === 0 || (value && value.length > 3)) {
                    dispatch(getUserFunctions(params));
                }
                break;
        }
    }

    const { name, country, role, status } = userFunctionFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Name/Slug"
                    placeholder="Enter Name/Slug"
                    type="text"
                    onChange={(e, value) => handleOnChangeInput(value, "name",)}
                    value={name!}
                    className="ms-Grid-col ms-lg4"
                />
                <TextField
                    label="Role"
                    placeholder="Enter Role"
                    type="text"
                    onChange={(e, value) => handleOnChangeInput(value, "role",)}
                    value={userFunctionFilter.role!}
                    className="ms-Grid-col ms-lg4"
                />
                <ComboBox
                    label="Region"
                    selectedKey={country ? country : "all"}
                    options={Regions}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChangeInput(item?.key, 'country')}
                    className="ms-Grid-col ms-lg2"
                />
                <ComboBox
                    label="Status"
                    selectedKey={status ? status : "all"}
                    options={statusOptions}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChangeInput(item?.key, 'status')}
                    className="ms-Grid-col ms-lg2"
                />
            </div>
        </div>
    );
}

interface UserFunctionFilterProps {

}

export default UserFunctionFilters;

