import { useEffect, useState } from 'react';
import { ActionButton, CheckboxVisibility, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { IOpportunity } from '../../../store/admin/opportunity/reducer';
import { deleteOpportunity, getOpportunities, setOpportunityFilter, exportOpportunities } from '../../../store/admin/opportunity/action';
import { OpenOpportunityModal } from './AddOpportunity';
import DetailsListWapper from '../../common/DetailsListWapper';
import { getOpportunitiesColumns } from './helper';
import { IOpportunity as IOpportunityDocument } from "@solarforschools/sfs-core/dist/opportunity/type";
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { getSchoolApi } from '../../../store/client/school';
import { StringMap } from '../common/types';
import { getOpportunityApi } from '../../../store/client/opportunity';
import { PageSize } from '../common/common';
import Pagination from '../common/Pagination';

const OpportunityList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const params: StringMap = useParams();
  const { opportunities, totalCount, opportunityFilter }: IOpportunity = useSelector<RootState, IOpportunity>(
    (state: RootState) => state.web.opportunity
  );

  const handleOnDelete = async (opportunity: IOpportunityDocument) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Opportunity",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${opportunity}`,
      },
    });
    if (confirm) dispatch(deleteOpportunity(opportunity));
  };

  const handleOnEdit = async (opportunity: IOpportunityDocument) => {
    window.history.replaceState(null, "", `/opportunities/${opportunity.type}/${opportunity.linkedTo}`)
    await OpenOpportunityModal({ params: opportunity })
    window.history.replaceState(null, "", `/opportunities`)
  };

  const headerRow = getOpportunitiesColumns({
    actions: [
      {
        title: 'Edit Opportunity',
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        title: 'Delete Opportunity',
        onClick: handleOnDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)

  const loadData = async () => {
    if (params.type && params.linkedto) {
      let { opportunity }: any = await getOpportunityApi(params.linkedto, params.type)
      if (opportunity)
        await OpenOpportunityModal({ params: opportunity })
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  const handleOnSort = (field: string, order: string) => {
    const params = { ...opportunityFilter, sortField: field, sortOrder: order };
    dispatch(setOpportunityFilter(params));
    dispatch(getOpportunities(params))
  }
  const loadNextPage = (p: any) => {
    dispatch(setOpportunityFilter({ ...opportunityFilter, ...p }))
    dispatch(getOpportunities())
  }
  const handleOnExport = () => {
    dispatch(exportOpportunities({ ...opportunityFilter, all: true }))
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...opportunityFilter, page: 1, pageSize: size }
    dispatch(setOpportunityFilter(filters))
    dispatch(getOpportunities())
  };

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}>
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={opportunityFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={handleOnExport}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
      </div>
      {totalCount ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={opportunities}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={opportunities.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={opportunityFilter?.page!}
            pageSize={opportunityFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  );
};

export default OpportunityList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

