import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
  Separator,
  Stack,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import { TextField } from "office-ui-fabric-react";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch } from "react-redux";
import store from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { defaultLangList, getGeneralAddressesFields, getGeneralFirstFields, getGeneralFundingDetailsFields, getGeneralLangList, getGeneralLinksMailsFields, getGeneralPVGISOthersFields, getGeneralSecondFields, validateLangList, validateNewRegionConfigDetails } from "./SettingsHelper";
import { defaultRegionConfig } from "./SettingsHelper";
import { openCreateModal } from "./CreateModal";
import { FundingItem } from "@solarforschools/sfs-core/dist/calculator/types";
import { openCreateFundingModal } from "./CreateFundingModal";
import { createRegionConfig } from "../../../store/admin/settings/action";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { ILanguageConfiguration } from "@solarforschools/sfs-core";


export const openDialog = () => {
  return new Promise<string | undefined>((resolve, reject) => {
    const mountTarget = document.createElement("div");
    document.body.appendChild(mountTarget);
    const callback = () => {
      resolve("Success");
      ReactDOM.unmountComponentAtNode(mountTarget);
      mountTarget.remove();
    };

    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Add region"}
          onDismiss={() => callback()}
        >
          <CreateNewRegionDialog
            onCancel={() => callback()}
            onSave={() => callback()}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    );
  });
};


const CreateNewRegionDialog = (props: any) => {


  const [regionConfig, setRegionConfig] = useState(defaultRegionConfig)
  const [langList, setLangList] = useState<ILanguageConfiguration>(defaultLangList)
  const [errors, setErrors] = useState<any>({});
  const [langListErr, setLangListErr]  = useState<any>({});

  const dispatch = useDispatch()


  const handleChangeLangListInput = (key: string, value: any) => {
    setLangList({ ...langList, [key]: value } as ILanguageConfiguration)
  }

  const handleChangeInput = (key: string, value: any) => {
    let modifiedKey: string | string[];

    modifiedKey = key.match(/\./g) ? key.split(".") : key

    if (Array.isArray(modifiedKey) && modifiedKey.length === 3) {
      modifiedKey = modifiedKey[2]
    } else if (Array.isArray(modifiedKey) && modifiedKey.length === 2) {
      modifiedKey = modifiedKey[1]
    }


    switch (modifiedKey) {
      case "order":
      case "country":
      case "id":
        setRegionConfig({ ...regionConfig, [modifiedKey]: value }); break;

      case "lat":
      case "lng":
      case "zoom":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, MAP: { ...regionConfig.data.MAP, [modifiedKey]: value } } }); break;

      case "TWITTER":
      case "FACEBOOK":
      case "LINKEDIN":
      case "INSTAGRAM":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, SOCIALMEDIALINKS: { ...regionConfig.data.SOCIALMEDIALINKS, [modifiedKey]: value } } }); break;


      case "team":
      case "info":
      case "registration":
      case "bonds":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, EMAILS: { ...regionConfig.data.EMAILS, [modifiedKey]: value } } }); break;

      case "azimuth":
      case "angle":
      case "loss":
      case "db":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, PVGIS: { ...regionConfig.data.PVGIS, [modifiedKey]: value } } }); break;


      case "site":
      case "drive":
      case "path":
        setRegionConfig({
          ...regionConfig, data: {
            ...regionConfig.data, CustomerFolder: {
              ...regionConfig.data.CustomerFolder, [modifiedKey]: value
            }
          }
        }); break;

      case "contact":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, PHONE_NUMBERS: { ...regionConfig.data.PHONE_NUMBERS, [modifiedKey]: value } } }); break;

      default:
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, [modifiedKey as any]: value } }); break

    }
  }

  //create model for FundingModal
  const handleCreateFundingModal = async () => {
    const fundingLists = regionConfig.data.Funding;

    const openModal: FundingItem[] | undefined = await openCreateFundingModal(fundingLists as any)

    if (openModal)
      setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, Funding: isEmpty(openModal) ? [] : openModal } })
  }

  //create modal for LANGUAGES, HOSTS, ADDRESS, FundingModel
  const handleCreateModal = async (name: string) => {
    let fieldValues: any;

    if (name === "LANGUAGES") {
      fieldValues = regionConfig.data.LANGUAGES
    } else if (name === "HOSTS") {
      fieldValues = regionConfig.data.HOSTS
    } else if (name === "ADDRESS") {
      fieldValues = regionConfig.data.ADDRESS
    } else if (name === "FundingModel") {
      fieldValues = regionConfig.data.FundingModel
    }

    const openModal = await openCreateModal(
      { data: fieldValues })
    const resFromModal = openModal?.data.filter(e => e !== "")
    if (resFromModal !== undefined) {
      setRegionConfig(
        {
          ...regionConfig,
          data: {
            ...regionConfig.data,
            [name]: resFromModal
          }
        })
    }


  }


  const formValidation = () =>{
    const errors = validateNewRegionConfigDetails(regionConfig)
    const errorsLangList =  validateLangList(langList)
    setLangListErr(errorsLangList)
    setErrors(errors)

    if (Object.keys(errors).length || Object.keys(errorsLangList).length) return false;
    
    
    if (regionConfig?.data?.Funding?.length === 0) {
      toast.error("Funded By and Funding Model cannot be empty")
      return false;
    }

    return true
  }

  const handleOnSave = async () => {

   const hasAllValues = formValidation()
   
   if(!hasAllValues) return

    const isConfirmed = await ConfirmDialog({
      dialogContentProps: {
        title: "Save region",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to save this region ?`,
      },
      confirmBtnText: "Save",
    });
    if (isConfirmed) {
      dispatch(createRegionConfig(regionConfig, langList))
      props.onSave(""); // on save
    }
  };


  return (
    <div>

      <div className='ms-Grid' dir="ltr">
        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralFirstFields(true)}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />
          <div style={{ cursor: "pointer", marginTop: '1.75rem' }}>

            <ActionButton
              title={'Edit Hosts'}
              iconProps={{ iconName: "Edit" }}
              onClick={() => handleCreateModal("HOSTS")}
            />
          </div>

        </div>

        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralSecondFields(true)}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />
          <div>
            <ActionButton
              title={'Edit Language'}
              style={{ marginTop: "1rem" }}
              iconProps={{ iconName: "Edit" }}
              onClick={() => handleCreateModal("LANGUAGES")}
            />
          </div>
        </div>

        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Funding Lists</Separator>
          </div>
        </div>

        <div className='ms-Grid-row'>
          <div style={{ display: "flex" }} >

            <DynamicForm
              fields={getGeneralFundingDetailsFields}
              data={regionConfig}
              onChange={handleChangeInput}
              errors={errors}
            />
            <ActionButton
              title={'Edit Funding'}
              iconProps={{ iconName: "Edit" }}
              onClick={() => handleCreateModal("FundingModel")}
            />

            <div className='center-table' style={{ marginLeft: "5rem", marginTop: "1rem", flexBasis: "500px" }}>
              <table>
                <thead >
                  <th> Funded By</th>
                  <th> Funding Model</th>
                  <th> Default</th>
                </thead>
                <tbody>
                  {
                    regionConfig?.data?.Funding && regionConfig?.data?.Funding?.map((el: any) => {
                      return <tr >
                        <td> {el?.FundedBy}</td>
                        <td> {el?.FundingModel}</td>
                        <td >
                          {String(el?.default) === "true" ? "True" : el?.default === undefined ? "" : "False"}
                        </td>
                      </tr>
                    })
                  }

                </tbody>
              </table>
              <div className='center-table-edit'>

                <ActionButton
                  title={'Edit Funding'}
                  iconProps={{ iconName: "Edit" }}
                  onClick={handleCreateFundingModal}
                />

              </div>

            </div>

          </div>

        </div>
        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Address</Separator>
          </div>
        </div>


        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralAddressesFields}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />

          <ActionButton
            title={'Edit Address'}
            iconProps={{ iconName: "Edit" }}
            onClick={() => handleCreateModal("ADDRESS")}
          />


        </div>



        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Links & Mails</Separator>
          </div>
        </div>

        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralLinksMailsFields}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />

        </div>


        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>PVGIS</Separator>
          </div>
        </div>

        <div className="ms-Grid-row margin-top-md">
          <DynamicForm
            fields={getGeneralPVGISOthersFields}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />
        </div>


        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Language List</Separator>
          </div>
        </div>

        <div className="ms-Grid-row margin-top-md">
          <DynamicForm
            fields={getGeneralLangList}
            data={langList}
            onChange={handleChangeLangListInput}
            errors={langListErr}
          />
        </div>
        
        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}></div>

      </div>


      <Sticky stickyPosition={StickyPositionType.Footer}>
        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >


          <Stack horizontal horizontalAlign="center" className="margin-top-xs">
            <Stack.Item>

              <PrimaryButton
                text="Save"
                onClick={handleOnSave}
                className="btn-primary"
              />

              <DefaultButton onClick={props.onCancel} text="Cancel" />
            </Stack.Item>
          </Stack>
        </div>
      </Sticky>
    </div>
  );
};
