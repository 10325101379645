import { IDetailsRowFieldsProps, DetailsRowFields, IDetailsRowProps, DetailsRow } from "@fluentui/react"

const renderRowFields = (props: IDetailsRowFieldsProps) => {
    return (
      <span data-selection-disabled={true}>
        <DetailsRowFields {...props} />
      </span>
    );
  }
  export const RenderListRow = (props: IDetailsRowProps) => {
    return <DetailsRow rowFieldsAs={renderRowFields} {...props} />;
  }