import { ThunkDispatch } from "redux-thunk";
import { ObjectId } from "mongodb";
import { RootState } from "./../../index";
import { isLoading } from "../action";
import { IBlog } from "@solarforschools/sfs-core/dist/blog/types";
import {
  SetAllBlogs,
  SetBlogCategories,
  SetBlogSites,
  SetFilters,
  SetNewSkipCount,
  SetSort,
  SetTotalBlogsCount,
} from "./actionTypes";
import { getBlogs, deleteOneBlog, getBlogSitesAndCategoriesApi, updateBlogApi } from "../../client/blog";
import { StringMap } from "../../../components/admin/common/types";
import { createBlogAPi } from './../../client/blog';

const limit = 50;

// Action Creators
export const setAllBlogs = (blogs: IBlog[], replace: boolean): SetAllBlogs => {
  return { type: "SET_ALL_BLOGS", blogs, replace };
};

export const setTotalBlogsCount = (count: number): SetTotalBlogsCount => {
  return { type: "SET_TOTAL_BLOGS_COUNT", count };
};

export const setNewSkipCount = (skip: number): SetNewSkipCount => {
  return { type: "SET_NEW_SKIP_COUNT", skip };
};

export const setSort = (sort: string, order: string): SetSort => {
  return { type: "SET_BLOG_SORT", sort: { sort, order } };
};

export const changeFilters = (filters: StringMap): SetFilters => {
  return { type: "SET_BLOG_FILTERS", blogFilters: filters };
};

export const setCategories = (categories: any): SetBlogCategories => {
  return { type: "SET_BLOG_CATEGORIES", categories };
};

export const setSites = (sites: any): SetBlogSites => {
  return { type: "SET_BLOG_SITES", sites };
};

export const fetchAllBlogs =
  (replace: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { blogFilter,} = getState().web.blogs;
        replace && dispatch(isLoading(true));
        const response = await getBlogs(blogFilter);
        dispatch(setAllBlogs(response.blogs, replace));
        dispatch(setTotalBlogsCount(response.count));
        dispatch(isLoading(false));
      } catch (e: any) {
        dispatch(isLoading(false));
      }
    };

// export const loadNextPage =
//   () =>
//     async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
//       let { skip } = getState().web.blogs;
//       const newSkip = skip + limit;
//       dispatch(setNewSkipCount(newSkip));
//       dispatch(fetchAllBlogs(false));
//     };

// export const sortBlogs =
//   (sort: string, order: string) =>
//     async (dispatch: ThunkDispatch<any, any, any>) => {
//       dispatch(setSort(sort, order));
//       dispatch(fetchAllBlogs(true));
//     };

export const deleteBlog =
  (blogId: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        await deleteOneBlog(blogId);
        dispatch(fetchAllBlogs(true));
        dispatch(isLoading(false));
      } catch (e: any) {
        dispatch(isLoading(false));
      }
    };

export const setBlogCatsAndSites =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { sites, categories } = await getBlogSitesAndCategoriesApi()
        dispatch(setCategories(categories))
        dispatch(setSites(sites))
        dispatch(isLoading(false));
      } catch (e: any) {
        dispatch(isLoading(false));
      }
    };

export const cteateUpdateBlog = (blog: IBlog) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  try {
    dispatch(isLoading(true));
    const apiCall = blog._id ? updateBlogApi : createBlogAPi;
    const { blog: saved } = await apiCall(blog);
    const { blogs, count } = getState().web.blogs
    if (!blog._id) {
      dispatch(setAllBlogs([saved, ...blogs], true));
      dispatch(setTotalBlogsCount(count + 1))
    } else {
      const index = blogs.findIndex(x => x._id === blog._id);
      const updatedBlogs = [...blogs];
      updatedBlogs[index] = saved;
      dispatch(setAllBlogs(updatedBlogs, true));
    }
    dispatch(isLoading(false));
    return saved;
  } catch (e: any) {
    dispatch(isLoading(false));
  }
}
