import React, { useContext } from "react"
import { CardContext, CardContextType } from "../";
import { MdFlipCameraAndroid } from "react-icons/md";

interface IFlipFooterProps {
    frontContent?: () => JSX.Element
    backContent?: () => JSX.Element
    title?: string
    hasFooter?: boolean
}

const FlipContent = (props: IFlipFooterProps) => {
    const { isflip, setIsFlip } = useContext(CardContext) as CardContextType;
    const FrontContent = props.frontContent
    const BackContent = props.backContent

    const handleFlip = () => {
        setIsFlip(!isflip)
    }

    if (FrontContent && BackContent)
        return (<>
            <div className={`card-flip ${isflip ? "flipped" : ""}`} style={!props.hasFooter ? { height: "10rem" } : { height: "5.9rem" }}>
                <div className="card-flip-inner">
                    <div className="card-flip-front flex-column-center">
                        <FrontContent />
                        {props?.title && <p style={{ fontSize: "0.85rem", color: "#243f61" }}>{props?.title}</p>}

                        {
                            !props.hasFooter && <div style={{ fontSize: "1.15rem", position: "absolute", bottom: 0, right: 0, paddingRight: "0.25rem", color: "#243f61", cursor: "pointer" }}>
                                <MdFlipCameraAndroid onClick={handleFlip} title="Flip Tile" />
                            </div>
                        }



                    </div>
                    <div className="card-flip-back">
                        <BackContent />
                        {
                            !props.hasFooter && <div style={{ fontSize: "1.15rem", position: "absolute", bottom: 0, right: 0, paddingRight: "0.25rem", color: "#243f61", cursor: "pointer" }}>
                                <MdFlipCameraAndroid onClick={handleFlip} title="Flip Tile" />
                            </div>
                        }

                    </div>

                </div>
            </div>
        </>)
    else return <></>
}

export default FlipContent