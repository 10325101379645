import { useDispatch, useSelector } from "react-redux";
import { IInvoiceReport } from "../../../../store/admin/reporting/invoice/reducer";
import { RootState } from "../../../../store";
import { ActionButton, DetailsList, ScrollablePane, ScrollbarVisibility, SelectionMode, TooltipHost } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { exportInvoiceReport } from "../../../../store/admin/reporting/invoice/action";


const InvoiceReportList = () => {
  const dispatch = useDispatch()
  const { invoiceReportData } = useSelector<RootState, IInvoiceReport>((state: RootState) => state.web.invoiceReport);

  const getheaderColumn = () => {
    if (invoiceReportData.length > 0) {
      const c = Object.keys(invoiceReportData[0]).map(d => ({
        key: d,
        name: d,
        fieldName: d,
        minWidth: 90,
        data: "string",
        ariaLabel: d,
        onRender: (item: any) => {
          return <TooltipHost content={item[d]} closeDelay={500}>{item[d]}</TooltipHost>
        }
      }))
      return c
    }
    return []

  }
  const columns = [...getheaderColumn()]

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}>
            {invoiceReportData.length > 0 && (<>Displaying &nbsp; {invoiceReportData.length}</>)}
          </span>
        </div>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => dispatch(exportInvoiceReport())}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
      </div>

      {invoiceReportData.length > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                items={invoiceReportData}
                columns={columns}
                selectionMode={SelectionMode.none}
                compact={true}
              />
            </ScrollablePane>
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default InvoiceReportList;