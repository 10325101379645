import {
  CheckboxVisibility, DetailsList, IColumn, ScrollablePane,
  ScrollbarVisibility,
  Shimmer
} from "@fluentui/react";
import { SelectionMode } from "@fluentui/react/lib/index";
import { IProject } from "@solarforschools/sfs-core/dist/project/types";
import { useEffect, useState } from "react";
import InView from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store";
import { deleteProject, getProjects, setProjectsFilter, sortProjects } from '../../../store/admin/projects/action';
import { IProjectState } from "../../../store/admin/projects/reducer";
import { getProject } from "../../../store/client";
import { getOpportunityApi } from "../../../store/client/opportunity";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { RenderListRow } from "../common/RenderListRow";
import { DropdownParams, StringMap } from "../common/types";
import { SetDetailListHeaderSortDirection } from "../common/utils";
import { OpenOpportunityModal } from "../opportunity/AddOpportunity";
import { initOpportunity } from "../opportunity/helper";
import { openProjectDialog } from "./ProjectDialog";
import { getProjectColumns } from "./ProjectHelper";
import { IProjectsFilter } from "./types";
import { projectNameChange } from "./helper";
import Pagination from "../common/Pagination";

const ProjectList = () => {
  const dispatch = useDispatch();
  const params: StringMap = useParams();
  const { projects, projectFilter, count } = useSelector<RootState, IProjectState>(state => state.web.project)

  const loadData = async () => {
    if (params.slug) {
      const project: any = await getProject(params.slug)
      if (project) {
        handleEditProject(project)
      }
    }
  }

  useEffect(() => {
    loadData();
  }, [params.slug])

  const handleOnDelete = async (project: IProject) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: `Delete ${projectNameChange['Project']}`,
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete`,
      },
    });
    if (confirm) dispatch(deleteProject(project.slug))
  }
  const handleClickAddOpportunity = async (project: IProject) => {
    let { opportunity, totals }: any = await getOpportunityApi(project.slug, 'Project')
    if (!opportunity) opportunity = initOpportunity(project, 'Project', totals)
    await OpenOpportunityModal({ params: opportunity })
  }

  const handleEditProject = async (project: IProject) => {
    window.history.replaceState(null, "", `/projects/${project.slug}`)
    await openProjectDialog({ project })
    window.history.replaceState(null, "", `/projects`)
  }

  const [columns, setColumns] = useState(getProjectColumns({
    actions: [
      {
        icon: "Money",
        ariaLabel: 'Create/Edit Opportunity',
        onClick: handleClickAddOpportunity
      },

      {
        icon: 'Edit',
        ariaLabel: 'Edit Project',
        onClick: handleEditProject,
      },
      {
        icon: "Trash",
        ariaLabel: 'Delete Project',
        onClick: handleOnDelete
      }
    ]
  }))

  const handleOnSort = (item: DropdownParams) => {
    const params = { ...projectFilter, ...{ sortField: item?.key, sortOrder: item?.text } };
    dispatch(setProjectsFilter(params as IProjectsFilter));
    dispatch(getProjects());
  }

  const handleOnHeaderClick = (column?: IColumn): void => {
    if (column?.key === 'action') return
    const index = columns.findIndex(c => c.key === column?.key)
    const newColumns = SetDetailListHeaderSortDirection(column, columns)
    setColumns(newColumns)
    handleOnSort({ key: newColumns[index].key, text: newColumns[index].isSortedDescending ? 'desc' : 'asc' })
  };

  const loadNextPage = (p: any) => {
    const params = { ...projectFilter, ...p }
    dispatch(setProjectsFilter(params))
    dispatch(getProjects())
  }
  // const onRenderMissingItem = (index?: number) => {
  //   if (projects.length < count) {
  //     return (
  //       <InView as="div" onChange={(inView: any) => inView && loadNextPage && loadNextPage()}>
  //         <Shimmer width='100%' />
  //       </InView>
  //     )
  //   }
  //   return <></>
  // }

  return (
    <>
      <div className="data-list-container">
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            items={projects}
            columns={columns}
            selectionMode={SelectionMode.none}
            onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
            onRenderDetailsHeader={DetailListStickyHeader}
            compact={true}
            checkboxVisibility={CheckboxVisibility.always}
            onRenderRow={(props) => RenderListRow(props!)}
          // onRenderMissingItem={onRenderMissingItem}
          />
        </ScrollablePane>
      </div>
      <Pagination
        totalRecords={count}
        onPaginate={(page) => loadNextPage({ page })}
        currentPage={projectFilter?.page!}
        pageSize={projectFilter.pageSize!}
      />
    </>
  );
};

interface ProjectListProps {

}


export default ProjectList;
