import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../common/utils";
import moment from "moment";
import { awsUrlHost, host } from "../../../store/config";
import { IField } from "../../dynamic-form/DynamicForm";
import { StringMap } from "../common/types";
import { ISupporters } from "@solarforschools/sfs-core/dist/supporters/types";
import { get } from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";

// To render in detailsList Table
export const getSupporters = (
    params: {
        actions: {
            icon: string,
            ariaLabel?: string,
            onClick: (data: any) => any
        }[]
    }
): IColumn[] => {
    return [
        {
            key: "icon",
            name: "Icon",
            fieldName: "Icon",
            minWidth: 70,
            maxWidth: 80,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                const value = item["icon"];
                const name = item["name"] + " icon";
                let imageURL = "";
                if (value) imageURL = `${awsUrlHost}/${value}`
                return (
                    <TooltipHost content={name} closeDelay={500}>
                        {
                            imageURL?.length > 0 && <img src={imageURL} alt={`${name}-icon`} style={{ width: "50px", height: "50px" }} />
                        }
                    </TooltipHost>
                );
            },
        },
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 180,
            maxWidth: 220,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                const value = item["name"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "description",
            name: "Description",
            fieldName: "Description",
            minWidth: 350,
            maxWidth: 450,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {

                const value = item["description"] || "";
                return (
                    <TooltipHost content="description" closeDelay={500}>
                        <ReactMarkdown children={value} />
                    </TooltipHost>
                );
            },
        },
        {
            key: "schools",
            name: "Schools",
            fieldName: "Schools",
            minWidth: 300,
            maxWidth: 350,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {

                const value = item["schools"].join(", ");
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },

        {
            key: "createdBy",
            name: "Created By",
            fieldName: "Created BY",
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                const value = item["createdBy"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "createdAt",
            name: "Created At",
            fieldName: "Created At",
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                const value = item["createdAt"] ? moment(item["createdAt"]).local().format('DD MMM YYYY, HH:mm:ss') : ""
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "action",
            name: "Action",
            fieldName: "-",
            ariaLabel: "View",
            isResizable: true,
            minWidth: 100,
            maxWidth: 125,
            onRender: (data) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; ariaLabel?: string, onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(data)} />;
                        })}
                    </div>
                );
            },
        },
    ];
};


export const getGeneralFields: IField[] = [
    {
        label: "Supporter name",
        key: "name",
        type: "string",
        extras: { required: true },
        placeholder: "Enter supporter name",
        className: "ms-Grid-col  ms-lg3",
    }, {
        label: "Amount",
        key: "amount",
        type: "number",
        extras: { required: true },
        placeholder: "Enter amount",
        className: "ms-Grid-col ms-lg",
    },
    {
        label: "Currency",
        key: "currency",
        type: "string",
        placeholder: "Enter $, € symbol",
        extras: { required: true },
        className: "ms-Grid-col ms-md",
    }
];

export const validateDetails = async (data: Partial<ISupporters>, isEdit = false) => {

    const errors: StringMap = {};
    let fields = getGeneralFields;

    if (isEdit) fields = getGeneralFields.filter(elm => elm.key !== "description" && elm.key !== "designation" && elm.key !== "email")


    fields.forEach((field) => {
        const value = get(data, field.key);
        if (field.label.toLowerCase() === "amount") {
            if (!value?.toString()) {
                errors[field.key] = `${field.label} cannot be empty`;
            } else if (value === 0) {
                errors[field.key] = `${field.label} cannot be zero`;
            }
        } else {
            if ((!value || value.length === 0)) {
                errors[field.key] = `${field.label} cannot be empty`;
            }
        }
    });
    return errors;
};


export const uploadImage = async (
    files: any,
    isEdit = false,
    imageKey: string = ""
) => {

    try {
        const formData = new FormData();
        formData.append("images", files[0]);

        if (!isEdit) {
            const res = await axios.post(
                `${host}/supporters/upload/image`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            return res.data.msg;
        } else {
            if (imageKey) {
                axios.delete(
                    `${host}/supporter/image?image=${imageKey}`
                );
            }
            const response2 = await axios.post(
                `${host}/supporters/upload/image`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response2.data.msg;
        }
    } catch (e: any) {
        console.log(e);
        toast.error("Something went wrong in uploading image");
        return;
    }
};


export const descriptionOptions = { minHeight: '100px', maxHeight: '100px', hideIcons: ['side-by-side', 'separator-2', 'separator-1', 'fullscreen', 'guide', 'image'], autofocus: true, uniqueId: "supporter-description", }
