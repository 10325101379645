import { toast } from "react-toastify";
import axios from "axios";
import { isLoading } from "../action";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../..";
import { host } from "../../config";
import { IResComments } from "@solarforschools/sfs-core/dist/comment/types";
import { ICommentsType } from "@solarforschools/sfs-core/dist/comment/types";
import { IComments } from "@solarforschools/sfs-core/dist/comment/types";
import { ICommentsfilter } from "../../../components/admin/comments/type";
import { getCommentsAPi } from "../../client/comment";

export const setComments = (
  data: IResComments & { isSortFirstTime: boolean }
) => {
  return { type: "SET_COMMENTS", payload: data };
};

export const setCommentFilter = (commentFilter: ICommentsfilter) => {
  return { type: "SET_COMMENT_FILTERS", commentFilter };
};

export const setCommentsType = (data: ICommentsType) => {
  return { type: "SET_COMMENTS_TYPES", payload: data };
};

export const deleteComment = (data: string) => {
  return { type: "DELETE_COMMENT", payload: data };
};

export const updateComment = (data: IComments) => {
  return { type: "UPDATE_COMMENT", payload: data };
};

export const fetchComments = (
  filter: ICommentsfilter
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function fetchConfigThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const data = await getCommentsAPi(filter)
      dispatch(setComments(data));
    } catch (err) {
      console.log("Error ", err);

    } finally { dispatch(isLoading(false)); }
  };
};

export const fetchCommentsType = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchConfigThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${host}/users/comments/types`);
      if (res.status === 200) {
        dispatch(setCommentsType(res.data));
        dispatch(isLoading(false));
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      dispatch(isLoading(false));
    }
  };
};

export const removeComment = (data: string) => {
  return async function (dispatch: ThunkDispatch<any, any, any>) {
    try {
      dispatch(isLoading(true));
      const urlName = `${host}/users/comments/update/${data}`;
      const res = await axios.put(urlName);
      if (res.status === 200) {
        dispatch(deleteComment(res.data));
        toast.success("Comment deleted successfully");
        dispatch(isLoading(false));
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      dispatch(isLoading(false));
    }
  };
};

export const updateCommentData = (data: IComments) => {
  return async function (dispatch: ThunkDispatch<any, any, any>) {
    try {
      dispatch(isLoading(true));
      const reqBody = data;
      const urlName = `${host}/users/comments/update/`;
      const res = await axios.put(urlName, reqBody);
      if (res.status === 200) {
        dispatch(updateComment(res.data));
        toast.success("Comment Updated successfully");
        dispatch(isLoading(false));
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Something went wrong");

      dispatch(isLoading(false));
    }
  };
};
