import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IUserFunctionFilter } from "../../../components/admin/userFunction/types";
import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types";
import { createUserFunctionAPi, deleteUserFunctionApi, getuserFunctionsApi, updateUserFunctionApi } from "../../client/userFunction";

// Action Creators

export const setUserFunctionFilter = (userFunctionFilter: IUserFunctionFilter) => {
  return { type: "SET_USER_FUNCTION_FILTER", userFunctionFilter };
};

export const setUserFunctionList = (
  userFunctions: IUserFunction[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_USER_FUNCTION_LIST", userFunctions, totalCount, reset };
};

export const updateUserFunctionList = (userFunction: IUserFunction) => {
  return { type: "UPDATE_USER_FUNCTION", userFunction };
};

export const delUserFunction = (slug: string) => {
  return { type: "DELETE_USER_FUNCTION", slug };
};

export const getUserFunctions =
  (params?: IUserFunctionFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IUserFunctionFilter =
            getState().web.userFunction.userFunctionFilter;
          reqBody = { ...filters };
        }
        const { userFunctions, totalCount } = await getuserFunctionsApi(reqBody);
        dispatch(setUserFunctionList(userFunctions, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateUserFunction =
  (params: IUserFunction) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { userFunction, msg } = params._id
          ? await updateUserFunctionApi(params)
          : await createUserFunctionAPi(params);
        dispatch(updateUserFunctionList(userFunction));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteUserFunction =
  (userFunction: IUserFunction) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteUserFunctionApi(userFunction);
        dispatch(delUserFunction(userFunction.slug));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
