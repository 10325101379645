

import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts/index";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";


export const FrontFooter = () => {
    const { pipeDrive } = useContext(CardContext) as CardContextType;
    const [status, setStatus] = useState({ prevMonth: "", isEqual: "" });


    function getStatus() {
        if (pipeDrive !== undefined) {
            const { lastTwoMonths } = pipeDrive.leads
            if (lastTwoMonths[lastTwoMonths.length - 2].count > lastTwoMonths[lastTwoMonths.length - 1].count) {
                setStatus({ ...status, prevMonth: "high", isEqual: "" });
            } else if (lastTwoMonths[lastTwoMonths.length - 2].count < lastTwoMonths[lastTwoMonths.length - 1].count) {
                setStatus({ ...status, prevMonth: "low", isEqual: "" });
            } else {
                setStatus({ ...status, isEqual: "yes", prevMonth: "" });
            }

        }
    }
    useEffect(() => {
        getStatus()
    }, [pipeDrive])


    if (pipeDrive === undefined) {
        return <></>
    }


    const prevMonthStyle = { color: "gray", fontSize: "0.85rem" }
    const { lastTwoMonths } = pipeDrive.leads
    const prevMonth = lastTwoMonths.length - 2;
    const currMonth = lastTwoMonths.length - 1;

    const prevDate = lastTwoMonths[prevMonth].month + " " + lastTwoMonths[prevMonth].year
    const currDate = lastTwoMonths[currMonth].month + " " + lastTwoMonths[currMonth].year
    const prevDateCount = lastTwoMonths[prevMonth].count
    const currDateCount = lastTwoMonths[currMonth].count


    const prevCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevMonth === "high" ? "green" : "red"))
    const currCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevMonth === "high" ? "red" : "green"))

    return <>
        <div className="flex-row-center" style={{ fontSize: "1rem", alignItems: "end", paddingTop: "1rem" }}>

            <div className="flex-column-center" >
                <div className="flex-row-center">
                    <div style={{ color: prevCountStyle }}>
                        {prevDateCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevMonth === "high"
                            ? <UpTriangleIcon />
                            : <DownTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{prevDate}</div>
            </div>
            <div className="vs-style">
                vs
            </div>

            <div className="flex-column-center">
                <div className="flex-row-center" >
                    <div style={{ color: currCountStyle }}>
                        {currDateCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevMonth === "high"
                            ? <DownTriangleIcon />
                            : <UpTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{currDate}</div>
            </div>
        </div>
    </>
}

export const BackFooter = () => {
    const { pipeDrive } = useContext(CardContext) as CardContextType;
    const [status, setStatus] = useState({ prevMonth: "", isEqual: "" });



    function getStatus() {
        if (pipeDrive !== undefined) {
            const { lastTwoMonths } = pipeDrive.deals;
            const prevMonth = lastTwoMonths.length - 2
            const currMonth = lastTwoMonths.length - 1

            if (lastTwoMonths[prevMonth].count > lastTwoMonths[currMonth].count) {
                setStatus({ ...status, prevMonth: "high", isEqual: "" });
            } else if (lastTwoMonths[prevMonth].count < lastTwoMonths[currMonth].count) {
                setStatus({ ...status, prevMonth: "low", isEqual: "" });
            } else {
                setStatus({ ...status, isEqual: "yes", prevMonth: "" });
            }

        }
    }
    useEffect(() => {
        getStatus()
    }, [pipeDrive?.deals])


    if (pipeDrive === undefined)
        return <></>



    const prevMonthStyle = { color: "gray", fontSize: "0.85rem" }
    const { lastTwoMonths } = pipeDrive.deals;
    const prevMonth = lastTwoMonths.length - 2
    const currMonth = lastTwoMonths.length - 1



    const prevDate = lastTwoMonths[prevMonth].month + " " + lastTwoMonths[prevMonth].year
    const currDate = lastTwoMonths[currMonth].month + " " + lastTwoMonths[currMonth].year
    const prevDateCount = lastTwoMonths[prevMonth].count
    const currDateCount = lastTwoMonths[currMonth].count


    const prevCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevMonth === "high" ? "green" : "red"))
    const currCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevMonth === "high" ? "red" : "green"))

    return <>
        <div className="flex-row-center" style={{ fontSize: "1rem", alignItems: "end", paddingTop: "1rem", marginTop: "0.5rem" }}>

            <div className="flex-column-center" >
                <div className="flex-row-center">
                    <div style={{ color: prevCountStyle }}>
                        {prevDateCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevMonth === "high"
                            ? <UpTriangleIcon />
                            : <DownTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{prevDate}</div>
            </div>
            <div className="vs-style">
                vs
            </div>

            <div className="flex-column-center">
                <div className="flex-row-center" >
                    <div style={{ color: currCountStyle }}>
                        {currDateCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevMonth === "high"
                            ? <DownTriangleIcon />
                            : <UpTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{currDate}</div>
            </div>
        </div>
    </>
}


