
import { SetBondTransactionFilter, SetBondTransactionList, SetDeleteTransaction, SetTransactionBondOffers, SetTransactionBonds, SetTransactionLogs, SetTransactionPeriods, UpdateTransactionList } from './actionType';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../..';
import { isLoading } from '../../action';
import { IBondTransactionFilter } from './../../../../components/admin/funder/transaction/types.d';
import { createTransactionAPi, deleteTransactionApi, downloadBondCertificateApi, exportBondTransactionApi, getBondTransactionFilterApi, getBondTransactionsApi, reinvestTransactionApi, updateTransactionApi } from '../../../client/funder/transaction';
import { ITransaction, ITransactionCreateUpdate } from '@solarforschools/sfs-core/dist/funder/transaction/types';
import { getBondOffersApi } from '../../../client/funder/bondOffer';
import { toast } from 'react-toastify';
import { downloadFileFromStream } from '../../../../components/admin/common/utils';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';

// Action Creators

export const setBondTransactionFilter = (transactionFilter: IBondTransactionFilter): SetBondTransactionFilter => {
  return { type: "FUNDER/SET_TRANSACTION_FILTER", transactionFilter };
};

export const setTransactionBondoffers = (bondOffers: any[]): SetTransactionBondOffers => {
  return { type: "FUNDER/TRANSACTION_SET_BONDOFFER_FILTER", bondOffers };
};

export const setTransactionPeriods = (periods: any[]): SetTransactionPeriods => {
  return { type: "FUNDER/TRANSACTION_SET_PERIODS_FILTER", periods };
};

export const setTransactionBonds = (bonds: IBond[]): SetTransactionBonds => {
  return { type: "FUNDER/TRANSACTION_SET_BONDS_FILTER", bonds };
};

export const setTransactionLogs = (logs: any[]): SetTransactionLogs => {
  return { type: "FUNDER/TRANSACTION_SET_LOGS_FILTER", logs };
};

export const delTransaction = (id: any): SetDeleteTransaction => {
  return { type: "FUNDER/TRANSACTION_DELETE", id };
};

export const updateTransactionList = (
  transaction: ITransaction,
): UpdateTransactionList => {
  return { type: "FUNDER/UPDATE_TRANSACTION", transaction };
};

export const setBondTransactionsList = (
  transactions: ITransaction[],
  totalCount: number,
  replace: boolean = true
): SetBondTransactionList => {
  return { type: "FUNDER/SET_TRANSACTION_LIST", transactions, totalCount, replace };
};

export const getBondTransactions =
  (params?: IBondTransactionFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IBondTransactionFilter =
            getState().web.transaction.transactionFilter;
          reqBody = { ...filters };
        }
        const { transactions, totalCount } = await getBondTransactionsApi(reqBody);
        dispatch(setBondTransactionsList(transactions, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const getTransactionsBondoffers =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { bondOffers } = await getBondOffersApi({ all: "true" });
        dispatch(setTransactionBondoffers([{ key: 'all', text: 'All' }, ...bondOffers.map(b => ({ key: b._id, text: b.name, isTestOffer: b.isTestOffer }))]));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error('Something happend wrong')
      }
    };

export const getTransactionsFilter =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { periods, auditLogs, bonds }: any = await getBondTransactionFilterApi();
        dispatch(setTransactionPeriods([{ key: 'all', text: 'All' }, ...periods.map((b: any) => ({ key: b, text: b }))]));
        dispatch(setTransactionLogs([{ key: 'all', text: 'All' }, ...auditLogs.map((b: any) => ({ key: b, text: b }))]));
        dispatch(setTransactionBonds([...bonds.bonds]));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error('Something happend wrong')
      }
    };

export const exportTransactions = () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));

      const filters: IBondTransactionFilter = getState().web.transaction.transactionFilter;
      const res = await exportBondTransactionApi(filters)
      downloadFileFromStream({ data: res.data, filename: "Bond-Transactions", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something happend wrong')
    }
  };

export const deleteTransaction =
  (id: string) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteTransactionApi(id);
        delTransaction(id)
        if (msg) toast.success(msg)
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const reInvestTransaction =
  (data: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await reinvestTransactionApi(data);
        if (msg) toast.success(msg)
        dispatch(isLoading(false));
        dispatch(getBondTransactions())
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const saveTransaction = (data: ITransactionCreateUpdate) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  try {
    dispatch(isLoading(true));
    const apiCall = data.transactionId ? updateTransactionApi : createTransactionAPi;
    const { msg, transaction } = await apiCall(data);
    dispatch(updateTransactionList(transaction))
    dispatch(isLoading(false));
    toast.success(msg)
    return transaction;
  } catch (e: any) {
    dispatch(isLoading(false));
    const errors: any = e.response?.data?.errors && Array.isArray(e.response?.data?.errors) ? e.response?.data?.errors : e.errors ? e.errors : []
    if (errors.length) {
      const result: any = {}
      for (const error of e.response?.data?.errors) {
        result[error.param] = error.msg
      }
      return { success: false, errors: result }
    }
  }
}

export const downloadTaxStatement = (transaction: ITransaction) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const res = await downloadBondCertificateApi(transaction._id)
      downloadFileFromStream({ data: res.data, filename: transaction.paymentReference, type: 'pdf' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something went wrong')
    }
  };
