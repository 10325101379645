import { Text } from '@fluentui/react';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, Polygon } from '@react-google-maps/api';
import { IEstablishment } from '@solarforschools/sfs-core'
import { CSSProperties, useEffect, useState } from 'react'
import { Spinner } from '@fluentui/react/lib/Spinner';
import { useJsApiLoader } from "@react-google-maps/api";
import { googleMapsApiKey } from "../common/common";
import { mapLibs } from "./common";
import { websiteHost } from '../../../store/config';
import { irelandCoords, legendContent, markerColorBasedOnStages } from './helper';
import MapControl from './MapControl';
import { ControlPosition } from './MapSearch';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdOutlineHideImage, MdOutlineImage } from 'react-icons/md';
interface IMapViewSchoolsProp {
    schoolsDetails: IEstablishment[]
}
interface IMarkers {
    lat: number;
    lng: number;
    name: string;
    slug: string;
    color: string;
}
interface IinfoWindowData {
    index: number
    name: string;
    slug: string;
}

const btnStyle = {
    margin: 5,
    padding: 5,
    fontSize: "15px",
    cursor: "pointer"
}
interface ILabelToggleButtonProps {
    showLabels: boolean;
    toggleLabels: () => void;
}

const MapViewSchools = (props: IMapViewSchoolsProp) => {
    const { schoolsDetails } = props;

    const loadScript = useJsApiLoader({
        googleMapsApiKey,
        libraries: mapLibs,
    } as any);

    const [markers, setMarkers] = useState<IMarkers[]>([])
    const [mapRef, setMapRef] = useState<google.maps.Map>();
    const [infoWindowData, setInfoWindowData] = useState<IinfoWindowData>()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hideLegend, setHideLegend] = useState<boolean>(false);
    const [showLabels, setShowLabels] = useState<boolean>(true);
    const [isSatellite, setIsSatellite] = useState(false);

    const [hideIreland, setHideIreland] = useState(false); // for screen shot purposes only

    useEffect(() => {
        setIsOpen(false)
    }, [schoolsDetails])


    const buildLatLng = async () => {

        let schoolsLength = schoolsDetails.filter(e => e !== null).length;
        let tempRes: IMarkers[] = []

        for (let i = 0; i < schoolsLength; i++) {
            const name = schoolsDetails[i]?.name || ""
            const slug = schoolsDetails[i]?.slug || ""
            const schLoc = schoolsDetails[i]?.address?.location?.coordinates || [0, 0]
            if (schLoc[0] === 0 || schLoc[1] === 0) continue;
            const schStage = schoolsDetails[i]?.activeStage?.name
            let color;

            // add colors based on stage of schools
            if (schStage) {
                if (schoolsDetails[i]?.activeStage?.active) {
                    color = markerColorBasedOnStages[schStage] || "orange" // Fallback is "orange"
                } else {
                    color = "orange"
                }
            } else {
                color = "orange" // marker orange color if school don't have a stage
            }

            let tempobj = { lat: schLoc[1], lng: schLoc[0], name, slug, color }
            tempRes.push(tempobj)
        }

        setMarkers(tempRes)
        await new Promise((resolve) => setTimeout(resolve, 500));
        setIsLoading(false)
    }

    useEffect(() => {
        buildLatLng()
        setIsLoading(true)
    }, [schoolsDetails])


    if (!schoolsDetails) return (
        <div className='flex-row-center'>
            <Text variant='mediumPlus'>
                Schools' data not found!
            </Text>
        </div>
    )
    if (!markers || markers.length === 0)
        return (
            <div className='flex-row-center'>
                <Text variant='mediumPlus'>
                    Schools' markers not found!
                </Text>
            </div>
        )

    if (isLoading) {
        return (
            <div style={{ height: "100%" }} className='flex-row-center'>
                <Spinner label="Please Wait..." ariaLive="assertive" labelPosition="left" />
            </div>
        )
    }

    const onLoad = (map: google.maps.Map) => {
        setMapRef(map)
        const bounds = new window.google.maps.LatLngBounds();
        markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }))
        map.fitBounds(bounds)
    }

    const handleMarkerClick = (index: number, lat: number, lng: number, name: string, slug: string) => {
        mapRef?.panTo({ lat, lng })
        setInfoWindowData({ index, name, slug })
        setIsOpen(true)
    }
    {/* WEB-2704 */ }
    // const calculator = function (markers: any, numStyles: any) {
    //     var index = 0;
    //     var count = markers.length;
    //     var dv = count;
    //     while (dv !== 0) {
    //         dv = parseInt((dv / 10 as any), 10);
    //         index++;
    //     }
    //     index = Math.min(index, numStyles);
    //     return {
    //         text: count,
    //         index: index,
    //         title: "School"
    //     };
    // };
    const mapContainerStyle: CSSProperties = {
        marginTop: "1rem",
        height: "70vh",
        width: "100%"
    }


    const mapStyles = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: showLabels ? "on" : "off" }],
        }]


    const toggleLabels = () => {
        setShowLabels(!showLabels);
    };

    const toggleIreland = () => {
        setHideIreland(!hideIreland)
    }

    const HideIrelandRegion = () => {
        if(!hideIreland) return <></>
        return <Polygon
        paths={irelandCoords}
        options={{
            fillColor: "#8AB4F8",
            fillOpacity: 1,
            strokeWeight: 0
        }}
    /> 
    }


    return (
        <div>
            <Text variant="medium">
                Schools Marked : <span style={{ fontWeight: 600 }}>{markers.length}</span>
            </Text>

            {!loadScript?.isLoaded ? <></> : (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    onLoad={onLoad}
                    options={{
                        styles: mapStyles as google.maps.MapTypeStyle[]
                    }}
                    onMapTypeIdChanged={() => {
                        const type = mapRef?.getMapTypeId()
                        if (type === "satellite" || type === "hybrid") setIsSatellite(true);
                        else setIsSatellite(false)
                    }}
                >
                    
                    <HideIrelandRegion />
                    

                    {/* WEB-2704 */}
                    {/* <MarkerClusterer
                        minimumClusterSize={3}
                        calculator={calculator}
                        onClusteringEnd={(clusterer) => {
                            clusterer.clusters.map((cluster, index) => {
                                let imageName, color;
                                if (cluster.markers.length < 10) {
                                    imageName = "locator1"
                                    color = "black"
                                } else if (cluster.markers.length >= 10 && cluster.markers.length < 50) {
                                    imageName = "locator2"
                                    color = "#fff"
                                } else if (cluster.markers.length >= 50 && cluster.markers.length < 150) {
                                    imageName = "locator3"
                                    color = "#fff"
                                } else if (cluster.markers.length >= 150 && cluster.markers.length <= 250) {
                                    imageName = "locator4"
                                    color = "#fff"
                                }
                                else {
                                    imageName = "locator5"
                                    color = "#fff"
                                }
                                return cluster.clusterIcon.styles = [
                                    {
                                        url: require(`../../../images/${imageName}.png`).default,
                                        height: 65, width: 65,
                                        anchorText: [-7, -8],
                                        textColor: color,
                                    }]
                            })
                        }}

                    >
                        {(clusterer) => {
                            return markers.map(({ lat, lng, name, slug, color }, index) => (
                                <Marker
                                    key={index}
                                    position={{ lat, lng }}
                                    onMouseOver={async () => {
                                        await new Promise((resolve) => setTimeout(resolve, 500))
                                        handleMarkerClick(index, lat, lng, name, slug);
                                    }}
                                    icon={{
                                        url: `https://maps.google.com/mapfiles/ms/icons/${color}.png`,
                                        scaledSize: new window.google.maps.Size(22, 22)
                                    }}
                                    animation={window.google.maps.Animation.DROP}
                                    clusterer={clusterer}
                                >
                                    {
                                        isOpen && (infoWindowData?.index === index && (
                                            <InfoWindow
                                                key={`infowindow-${name}`}
                                                position={
                                                    { lat, lng }
                                                }
                                                onCloseClick={() => { setIsOpen(false) }}
                                            >
                                                <>
                                                    {
                                                        infoWindowData?.slug && <a href={`${websiteHost}/schools/${infoWindowData.slug}/analysis`}
                                                            target="_blank" rel="noopener noreferrer">
                                                            {infoWindowData.name}
                                                        </a>
                                                    }
                                                </>
                                            </InfoWindow>
                                        ))
                                    }
                                </Marker>
                            ))
                        }
                        }
                    </MarkerClusterer> */}
                    {
                        markers?.map(({ lat, lng, name, slug, color }, index) => (
                            <Marker
                                key={index}
                                position={{ lat, lng }}
                                onMouseOver={async () => {
                                    await new Promise((resolve) => setTimeout(resolve, 500))
                                    handleMarkerClick(index, lat, lng, name, slug);
                                }}
                                icon={{
                                    url: `https://maps.google.com/mapfiles/ms/icons/${color}.png`,
                                    scaledSize: new window.google.maps.Size(22, 22)
                                }}
                                animation={window.google.maps.Animation.DROP}
                            >
                                {
                                    isOpen && (infoWindowData?.index === index && (
                                        <InfoWindow
                                            key={`infowindow-${name}`}
                                            position={
                                                { lat, lng }
                                            }
                                            onCloseClick={() => { setIsOpen(false) }}
                                        >
                                            <>
                                                {
                                                    infoWindowData?.slug && <a href={`${websiteHost}/schools/${infoWindowData.slug}/analysis`}
                                                        target="_blank" rel="noopener noreferrer">
                                                        {infoWindowData.name}
                                                    </a>
                                                }
                                            </>
                                        </InfoWindow>
                                    ))
                                }
                            </Marker>
                        ))
                    }
                    <MapControl position={ControlPosition.TOP_RIGHT} zIndex={1}>
                        <ToggleButton
                            hideLegend={hideLegend}
                            setHideLegend={setHideLegend}
                        />
                    </MapControl>
                    <MapControl position={ControlPosition.BOTTOM_RIGHT} zIndex={1}>
                        <RenderLegends hideLegend={hideLegend} isSatellite={isSatellite} />
                    </MapControl>
                    <MapControl position={ControlPosition.TOP_RIGHT} zIndex={1}>
                        <LabelToggleButton
                            showLabels={showLabels}
                            toggleLabels={toggleLabels}
                        />
                    </MapControl>
                    <MapControl position={ControlPosition.TOP_RIGHT} zIndex={1}>
                        <HideIrelandButton
                            showLabels={hideIreland}
                            toggleLabels={toggleIreland}
                        />
                    </MapControl>
                </GoogleMap>
            )}
        </div>
    )
}
export default MapViewSchools

const RenderLegends = ({ hideLegend, isSatellite }: { hideLegend: boolean, isSatellite: boolean }) => {
    const legendStyle: React.CSSProperties = {
        background: "none",
        padding: "1rem",
        backgroundColor: isSatellite ? "rgba(0,0,0, 0.5)" : "rgba(255,255,255, 0.5)",
        borderRadius: "5px"
    };
    const nameStyle: React.CSSProperties = {
        fontSize: "0.8rem",
        color: isSatellite ? "white" : "black"
    };
    const imgStyle: React.CSSProperties = {
        height: "20px"
    };
    const legendContentStyle: React.CSSProperties = {
        display: "flex",
        alignItems: "center",
        gap: "5px"
    };

    if (hideLegend) return <></>;

    const renderContent = (key: number, legendMarkerColor: string, legendName: string) => {
        const imgSrc = `https://maps.google.com/mapfiles/ms/icons/${legendMarkerColor}.png`;
        return (
            <div key={key} style={legendContentStyle}>
                <div>
                    <img src={imgSrc} style={imgStyle} />
                </div>
                <div style={nameStyle}>- {legendName}</div>
            </div>
        );
    };
    return (
        <div style={legendStyle}>
            {Object.entries(legendContent).map((marker, key) => {
                const [legendName, legendMarkerColor] = marker;
                return renderContent(key, legendMarkerColor, legendName);
            })}
        </div>
    );
};

const ToggleButton = ({ hideLegend, setHideLegend }: { hideLegend: boolean, setHideLegend: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const btnStyle = {
        margin: 5,
        padding: 5,
        fontSize: "15px",
        cursor: "pointer",
    };
    const flexRowCenter: CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
    return (
        <button style={btnStyle} onClick={() => setHideLegend(!hideLegend)}>
            


            {
                hideLegend ? (
                    <>
                        <div style={{ ...flexRowCenter, gap: "0.25rem" }}>
                            <div style={flexRowCenter}>
                                <FaEye />
                            </div>
                            <div>
                                Legend
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ ...flexRowCenter, gap: "0.25rem" }}>
                            <div style={flexRowCenter}>
                                <FaEyeSlash />
                            </div>
                            <div>
                                Legend
                            </div>
                        </div>
                    </>
                )
            }
        </button>
    );
};

function LabelToggleButton({ showLabels, toggleLabels }: ILabelToggleButtonProps) {

    const flexRowCenter: CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

    return (
        <button onClick={toggleLabels} style={btnStyle}>
            {
                showLabels ? (
                    <>
                        <div style={{ ...flexRowCenter, gap: "0.25rem" }}>
                            <div style={flexRowCenter}>
                                <FaEye />
                            </div>
                            <div>
                                Labels
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ ...flexRowCenter, gap: "0.25rem" }}>
                            <div style={flexRowCenter}>
                                <FaEyeSlash />
                            </div>
                            <div>
                                Labels
                            </div>
                        </div>
                    </>
                )
            }
        </button>
    );
}


function HideIrelandButton({ showLabels, toggleLabels }: ILabelToggleButtonProps) {

    const flexRowCenter: CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

    return (
        <button onClick={toggleLabels} style={{...btnStyle}}>
            {
                showLabels ? (
                    <>
                        <div style={{ ...flexRowCenter, gap: "0.25rem" }}>
                            <div style={flexRowCenter}>
                                <MdOutlineImage size={15}/>
                            </div>
                            
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ ...flexRowCenter, gap: "0.25rem" }}>
                            <div style={flexRowCenter}>
                                <MdOutlineHideImage size={15}/>
                            </div>
                           
                        </div>
                    </>
                )
            }
        </button>
    );
}