import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import DetailsListWapper from '../../common/DetailsListWapper';
import { IFitReportingState } from '../../../store/admin/fitReporting/reducer';
import { getFitReportingData, setFitReportingFilter } from '../../../store/admin/fitReporting/action';
import { geFitReadingColumns } from './helper';


const FitReportReadingList = () => {
  const dispatch = useDispatch();
  const { fitReadings, totalCount, fitReportingFilter }: IFitReportingState = useSelector<RootState, IFitReportingState>((state: RootState) => state.web.fitReport)

  const headerRow = geFitReadingColumns({
    actions: [
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...fitReportingFilter, page: 1, sortField: field, sortOrder: order };
    dispatch(setFitReportingFilter(params));
    dispatch(getFitReportingData(params))
  }
  const loadNextPage = () => {
    const { page = 0 } = fitReportingFilter
    dispatch(setFitReportingFilter({ ...fitReportingFilter, page: page + 1 }))
    dispatch(getFitReportingData(undefined, false))
  }
  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={fitReadings}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={fitReadings?.length < totalCount}
                onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default FitReportReadingList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

