

import { ActionButton, DefaultButton, PrimaryButton, Stack, TextField } from '@fluentui/react'
import React, { useState } from 'react'
import ReactDOM from "react-dom"
import { Provider } from 'react-redux'
import store from '../../../store'
import ModalDialog from '../common/ModalDialog'

interface IProps {
    data: string[]
}


export const openCreateModal = (props: IProps) => {
    return new Promise<IProps | undefined>((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (data?: IProps) => {
            resolve(data);
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title="Edit Folder"
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-sm"}
                >

                    <CreateModal
                        data={props.data}
                        onSave={(data: IProps) => callback(data)}
                        onCancel={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}




// added types
const CreateModal = (props: ICreateModalProps) => {

    const resData = props.data;
    const [tempRes, setTempRes] = useState<string[]>(resData)
    const handleInput = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { value } = e.target as HTMLInputElement;
        const temp = [...tempRes];
        temp[index] = value;
        setTempRes(temp);
    }

    const handleAddClick = () => {
        const temp = [...tempRes];
        temp.push("");
        setTempRes(temp);
    }

    const handleRemoveClick = (index: number) => {
        const temp = [...tempRes];
        temp.splice(index, 1);
        setTempRes(temp);

    }

    const handleOnSave = () => {
        props.onSave({ data: tempRes })
    }


    return (
        <div>
            <h2 style={{ textAlign: "center", marginBottom: "0.5rem" }}>List of Values</h2>
            <div className='modalCenter'>
                {
                   tempRes && tempRes?.map((el: string, i: number) => {
                        return <div key={i} className="modalCenter--row">

                            <TextField
                                onChange={e => handleInput(e, i)}
                                placeholder='Enter Value' value={el}
                                style={{ maxWidth: "400px", margin: "0.25rem" }}
                                className="nestedModalTextBox"
                            />

                            <ActionButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={() => handleRemoveClick(i)}
                                className="deleteBtn"
                            />
                        </div>

                    })
                }
                <div style={{ textAlign: "center" }}>
                    <DefaultButton onClick={handleAddClick} text="Add Column" />
                </div>
            </div>
            <Stack horizontal horizontalAlign="center" className="margin-top-md">
                <Stack.Item>
                        <PrimaryButton
                            text="Save"
                            onClick={handleOnSave}
                            className="btn-primary"
                        />
                    <DefaultButton onClick={props.onCancel} text="Cancel" />
                </Stack.Item>
            </Stack>
        </div>
    )
}

export default CreateModal
interface ICreateModalProps extends IProps {
    onSave: (data: IProps) => void;
    onCancel: () => void;
}