import { Action } from "./actionTypes";
import { IHistory, ITranslations } from "../../../components/admin/translations/utils/types";

import { toast } from "react-toastify";

const initialHistoryState:IHistory = {
  hasLoadMore: true,
  isLoading: true,
  pageNumber: 1,
  totalCount: 0,
  data: [],
};

const initialState: ITranslations = {
  filter: {
    value: "",
    lang: ["en", "en-gb"],
  },
  pageNumber: 1,
  totalCount: 0,
  isPanelOpen: false,
  hasLoadMore: true,
  data: [],
  pendingDrafts: [],
  pendingDraftsCount: 0,
  history: initialHistoryState,
  isLoading: true,
};

const translations = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_FILTER":
      const filterOptions = action.payload;
      const { value = "", lang = [] } = filterOptions;

      return {
        ...state,
        filter: {
          ...state.filter,
          value,
          lang,
        },
      };

    case "SET_TRANSLATION_HISTORY":
      let {
        data: hPayloadData,
        page: hCurrentPage,
        totalCount: hCount,
      } = action.payload;
      let hasLoadMore = true,
        hPage = 1;
      let overallHistoryCount = hPayloadData.length + state.history.data.length;
      let isLimitReached = overallHistoryCount === hCount;

      if (
        hPayloadData.length === 0 ||
        isLimitReached ||
        overallHistoryCount > hCount
      )
        hasLoadMore = false;
      else {
        hPage = hCurrentPage + 1; // there is some more page
      }
      return {
        ...state,
        history: {
          hasLoadMore,
          isLoading: false,
          pageNumber: hPage,
          totalCount: hCount,
          data: [...state.history.data, ...hPayloadData],
        },
      };

    case "SET_PENDING_APPROVAL_DRAFTS":
      const data = action.payload;

      ;

      return {
        ...state,
        isLoading: false,
        pendingDrafts: data,
      };

    case "SET_PENDING_APPROVAL_DRAFTS_COUNT":
      const count = action.payload;

      return {
        ...state,
        pendingDraftsCount: count,
      };

    case "IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "RESET_PENDING_APPROVAL_DRAFTS":
      return {
        ...state,
        pendingDrafts: [],
      };
      
    case "RESET_ALL":
      return {
        ...initialState,
      };

    case "SET_TRANSLATIONS":
      const {
        data: payloadData,
        page: currentPage,
        totalCount,
        hasToReset,
      } = action.payload;

      let isLoadMore = true,
        page = 1;

      let overallDataCount = hasToReset
        ? payloadData.length
        : state.data.length + payloadData.length;

      let hasMaxLimit = overallDataCount === totalCount;

      if (
        payloadData.length === 0 ||
        hasMaxLimit ||
        overallDataCount > totalCount
      )
        isLoadMore = false;
      else page = currentPage + 1; // have next page count

      if (hasToReset) {
        return {
          ...state,
          hasLoadMore: isLoadMore,
          totalCount,
          pageNumber: page,
          data: payloadData,
          isLoading: false,
        };
      }

      return {
        ...state,
        hasLoadMore: isLoadMore,
        pageNumber: page,
        totalCount,
        isLoading: false,
        data: [...state.data, ...payloadData],
      };

    case "DELETE_TRANSLATION":
      const docId = action.payload;
      let tempData = [...state.data];
      const findDataIndex = tempData.findIndex((data) => data.id === docId);
      if (findDataIndex !== -1) {
        tempData = tempData.filter((elm) => elm.id !== docId);
        toast.success("Translation Deleted");
      } else {
        toast.error("Translation Delete Failed");
      }

      return {
        ...state,
        data: tempData,
        totalCount: state.totalCount - 1,
      };

    case "SET_PANEL_OPEN":
      const isOpen = action.payload;

      // If panel is closed then we reset the history state
      if (!isOpen) {
        return {
          ...state,
          history: initialHistoryState,
          isPanelOpen: isOpen,
        };
      }

      return {
        ...state,
        isPanelOpen: isOpen,
      };

    default:
      return state;
  }
};

export default translations;
