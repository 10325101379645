import { CommandBar, FontIcon, IButtonProps, IColumn, ICommandBarItemProps, TooltipHost } from "@fluentui/react";
import { ISystem } from "@solarforschools/sfs-core";
import moment from "moment";
import { isSortable } from "../common/utils";

export const getSystemColumns = [{
  key: "id",
  name: "ID",
  fieldName: "id",
  minWidth: 50,
  maxWidth: 100,
  data: "number",
  className: "text-right",
  ...isSortable,
  onRender: (item: ISystem) => <TooltipHost content={item.id?.toString()} closeDelay={500}>{item.id}    </TooltipHost>
},
{
  key: "name",
  name: "Name",
  fieldName: "name",
  minWidth: 150,
  data: "string",
  ...isSortable,
  onRender: (item: ISystem) => {
    return (
      <TooltipHost content={item.name} closeDelay={500}>
        {item.name}
      </TooltipHost>
    );
  },
},
{
  key: "provider",
  name: "Provider",
  fieldName: "provider",
  minWidth: 50,
  data: "string",
  ...isSortable,
},
{
  key: "pricePaid",
  name: "Price Paid",
  fieldName: "pricePaid",
  minWidth: 50,
  data: "number",
  className: "text-right",
  ...isSortable,
  onRender: (item: ISystem) => item.pricePaid ? Math.round(item.pricePaid * 100 * 100) / 100 : null
},
{
  key: "priceSolar",
  name: "Price Solar",
  fieldName: "priceSolar",
  minWidth: 50,
  maxWidth: 90,
  data: "number",
  className: "text-right",
  ...isSortable,
  onRender: (item: ISystem) => item.priceSolar ? Math.round(item.priceSolar * 100 * 100) / 100 : null
},
{
  key: "priceExport",
  name: "Price Export",
  fieldName: "priceExport",
  minWidth: 50,
  maxWidth: 90,
  data: "number",
  className: "text-right",
  ...isSortable,
  onRender: (item: ISystem) => item.priceExport ? Math.round(item.priceExport * 100 * 100) / 100 : null
},
{
  key: "peakPower",
  name: "Peak Power",
  fieldName: "peakPower",
  minWidth: 100,
  maxWidth: 120,
  data: "number",
  className: "text-right",
  ...isSortable,
},
{
  key: "dataStart",
  name: "Data Start",
  fieldName: "dataStart",
  minWidth: 90,
  data: "date",
  ...isSortable,
  onRender: (item: ISystem) => {
    return item.dataStart
      ? moment(item.dataStart).format("YYYY-MM-DD")
      : item.dataStart;
  },
},
{
  key: "ownedBy",
  name: "Owned By",
  fieldName: "ownedBy",
  minWidth: 60,
  data: "string",
  ...isSortable,
},
{
  key: "billedBy",
  name: "Billed By",
  fieldName: "billedBy",
  minWidth: 60,
  data: "string",
  ...isSortable,
},
{
  key: "active",
  name: "Status",
  fieldName: "active",
  minWidth: 50,
  data: "boolean",
  ...isSortable,
},
];
