import React from "react";
import { io } from "socket.io-client";
import { host as CORE_URL, websiteHost as WEB_URL } from "../store/config";
let token = "";
if (localStorage.token) {
  token = localStorage.token;
}

const getSocket = (url: string) => {
  const token = localStorage.token; // get jwt token from local storage or cookie
  if (token) {
    return io(url, {
      query: { token },
    });
  }
  return io(url);
};

export const coreSocket = getSocket(CORE_URL!);

export const webSocket = getSocket(WEB_URL!);
export const socket = { coreSocket, webSocket };
export const SocketContext = React.createContext(socket);
