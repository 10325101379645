import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchFtpFolders,
  fetchNewSites, fetchPvamSystemByProvider, fetchSchools,
  fetchSystems, fetchSystemsData, fetchSystemServices, getSystemCountries
} from "../../../store/admin/system/action";
import Loader from "../common/Loader";
import SystemList from "./SystemList";
import { isLoading } from "../../../store/admin/action";
import SystemFilter from "./SystemFilter";


const SystemScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(isLoading(true));
      await dispatch(fetchSystemsData(undefined, false))
      await dispatch(isLoading(false));
      await dispatch(getSystemCountries())
      // await dispatch(fetchSystems(undefined, false));
      await dispatch(fetchSchools(false));
      dispatch(fetchNewSites(false)); // fetch new sites from pvam in background
      dispatch(fetchPvamSystemByProvider('solaredge', false)); // fetch new sites from pvam in background
      dispatch(fetchFtpFolders(false)); // fetch ftp folder list from s3
      dispatch(fetchSystemServices());// fetch services
    })();
  }, []);



  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'relative' }}>
      <Loader />
      <header className="table-header">
        <h1>Systems Configuration</h1>
        <SystemFilter />
      </header>
      <SystemList actions={['add', 'export', 'addSE', 'dp', 'edit', 'delete']} />
    </div>
  );
};

export default SystemScreen;
