import { useContext } from "react";
import { CardContext, CardContextType } from "../../layouts/index";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

export const FrontFooter = () => {
    const { teacherPortalActivity } = useContext(CardContext) as CardContextType;
    Chart.register(...registerables);
    const monthLabels = teacherPortalActivity?.created?.lastSixMonths?.map(elm => `${elm?._id?.month}, ${elm?._id?.year}` ) || []
    const values = teacherPortalActivity?.created?.lastSixMonths?.map(elm => elm?.count) || []
   

    const datum = {
        labels: monthLabels,
        datasets: [
            {
                label:"count",
                data: values,
                borderColor: "#9552EA",
                fill: true,
                backgroundColor: "rgb(149, 82, 234,0.5)",
                pointRadius: 0,
                pointHitRadius: 0,
                borderWidth: 1,
                lineTension: 0,
            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Last 6 Months',
                color: "#243f61",
                align: 'end' as const,
                padding: {
                    bottom: 1,
                    top:10
                },
                font: {
                    weight: 'normal',
                    lineHeight: 1,
                },

            },
            tooltip: {
                intersect: false,
                bodyFont: {
                    size: 10
                },
                padding: {
                    left: 3,
                    right: 3,
                    bottom: 5,
                    top: 5
                },
                position: "nearest" as const,
               
            }
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        },

        maintainAspectRatio: false
    };

    return <>
        <div className="flex-row-center" style={{ height: "4rem", gap: "1.5rem", }}>
            <div style={{ height: "50px", width: "240px" }}>
                <Line data={datum} options={options as any} />
            </div>
        </div>

    </>



}
export const BackFooter = () => {
    const { teacherPortalActivity } = useContext(CardContext) as CardContextType;
    Chart.register(...registerables);
    const monthLabels = teacherPortalActivity?.duration?.lastSixMonths?.map(elm => `${elm?._id?.month}, ${elm?._id?.year}` ) || []
    const values = teacherPortalActivity?.duration?.lastSixMonths?.map(elm => {
        if(elm?.count && elm?.count !== 0) {
            const _temp = elm?.count / 60 // convert to minutes
            return +(_temp?.toFixed(2))
        }
        else return 0
    }) || []

    const datum = {
        labels: monthLabels,
        datasets: [
            {   
                label:"Time",
                data: values,
                borderColor: "#9552EA",
                fill: true,
                backgroundColor: "rgb(149, 82, 234,0.5)",
                pointRadius: 0,
                pointHitRadius: 0,
                borderWidth: 1,
                lineTension: 0,
            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Last 6 Months',
                color: "#243f61",
                align: 'end' as const,
                padding: {
                    bottom: 1,
                    top:10
                },
                font: {
                    weight: 'normal',
                    lineHeight: 1,
                },

            },
            tooltip: {
                intersect: false,
                bodyFont: {
                    size: 10
                },
                padding: {
                    left: 3,
                    right: 3,
                    bottom: 5,
                    top: 5
                },

                position: "nearest" as const
            }
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        },

        maintainAspectRatio: false
    };

    return <>
        <div className="flex-row-center" style={{ height: "4rem", gap: "1.5rem", }}>
            <div style={{ height: "50px", width: "240px" }}>
                <Line data={datum} options={options as any} />
            </div>
        </div>

    </>



}