import {
  Action,
} from "./actionTypes";
import { IGSSReportFilter } from "../../../../components/admin/reporting/gssReporting/types";
import moment from "moment";
import { IDatafeedFilter } from "../../../../components/admin/reporting/datafeed/types";

// States' definition
export interface IDatafeed {
  datafeedFilter: IDatafeedFilter;
  schools: any[];
  data: any[];
}
export interface State {
  datafeed: IDatafeed;
}

const datafeedReport = (
  state: IDatafeed = {
    datafeedFilter: {
      schools: [],
      range:7,
      interval: "30",
      sortField: "name",
      sortOrder: "asc"
    },
    schools: [],
    data: [],
  },
  action: Action
): IDatafeed => {
  switch (action.type) {
    case "REPORT/SET_DATAFEED_LIVESCHOOLS": {
      return { ...state, schools: action.schools };
    }
    case "REPORT/SET_DATA_IN_DATAFEED": {
      return { ...state, data: action.data };
    }
    case "REPORT/SET_DATFEED_FILTER": {
      return { ...state, datafeedFilter: action.datafeedFilter };
    }
    default:
      return state;
  }
};

export default datafeedReport;
