
import { ActionButton, DefaultButton, PrimaryButton, Stack } from "@fluentui/react"
import { useState } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "../../../store"
import ModalDialog from "../common/ModalDialog"
import cloneDeep from "lodash/cloneDeep"
import { Checkbox, Label, TextField } from "office-ui-fabric-react"
import { toast } from "react-toastify"



export const openCreatePanelPowerModal = (props: PanelPowerItem[]) => {

    return new Promise<PanelPowerItem[] | undefined>((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (data?: PanelPowerItem[]) => {

            resolve(data);
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        const data = props;
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title="Edit Panel Power Lists"
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-sm"}
                >

                    <CreatePanelPowerModal
                        data={data}
                        onSave={(data: PanelPowerItem[]) => callback(data)}
                        onCancel={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export const CreatePanelPowerModal = (props: ICreateFundingModal) => {

    const resData = props.data;

    const [tempRes, setTempRes] = useState<PanelPowerItem[]>(cloneDeep(resData))


    // added types
    const handleInput = (e: React.FormEvent<HTMLElement | HTMLInputElement | HTMLTextAreaElement> | undefined, i: number) => {
        const lists: Record<string, any> = [...tempRes];
        const { name } = e?.target as HTMLInputElement;
        if (lists[i].portrait === undefined) { lists[i].portrait = { unitHeight: 0, unitWidth: 0 } }

        switch (name) {
            case "value": {
                let { value } = e?.target as HTMLInputElement;
                lists[i][name] = value ? parseFloat(value) : null
                break
            }
            case "portrait.unitHeight": {
                let { value } = e?.target as HTMLInputElement;
                lists[i].portrait[name.split(".")[1]] = value ? parseFloat(value) : null
                break
            }
            case "portrait.unitWidth": {
                let { value } = e?.target as HTMLInputElement;
                lists[i].portrait[name.split(".")[1]] = value ? parseFloat(value) : null
                break
            }
            default: {
                let { value } = e?.target as HTMLInputElement;
                lists[i][name] = value
            }
        }
        setTempRes(lists as PanelPowerItem[])
    }
    const handleRemoveClick = (i: number) => {
        const list = [...tempRes]
        list.splice(i, 1);
        setTempRes(list)
    }

    const handleAddClick = () => {
        setTempRes([...tempRes, { value: 0, label: "", portrait: { unitHeight: 0, unitWidth: 0 } }])
    }

    const handleOnSave = () => {
        if (tempRes.some((el) => el.value === null || el.value < 1 || el.label === "" || el.portrait.unitHeight === null || el.portrait.unitWidth === null || el.portrait.unitHeight < 0 || el.portrait.unitWidth < 0)) {
            toast.error("Please ceck all rows and fill all fields")
            return
        }
        props.onSave(tempRes)
    }

    return <div>

        <h2 style={{ textAlign: "center", margin: "1rem" }}>List Of Panel Power Options</h2>
        <div className="fundingModal">
            <table className="fundingModal-table">
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Value</th>
                        <th>Unit Height</th>
                        <th>Unit Width</th>
                        <th>Delete</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        tempRes && tempRes.map((el: PanelPowerItem, i: number) => {

                            const { value, label, portrait } = el;
                            return <tr key={i}>
                                <td>
                                    <TextField
                                        value={label}
                                        name="label"
                                        placeholder="Enter Label"
                                        className="fundingModalTextBox"
                                        onChange={e => handleInput(e, i)}
                                    />

                                </td>
                                <td>
                                    <TextField
                                        value={value.toString()}
                                        name="value"
                                        type="number"
                                        placeholder="Enter Value"
                                        className="fundingModalTextBox"
                                        onChange={e => handleInput(e, i)}
                                    />

                                </td>
                                <td>
                                    <TextField
                                        value={portrait?.unitHeight?.toString() || ""}
                                        name="portrait.unitHeight"
                                        type="number"
                                        placeholder="Enter Value"
                                        className="fundingModalTextBox"
                                        onChange={e => handleInput(e, i)}
                                    />

                                </td>
                                <td>
                                    <TextField
                                        value={portrait?.unitWidth?.toString() || ""}
                                        name="portrait.unitWidth"
                                        type="number"
                                        placeholder="Enter Value"
                                        className="fundingModalTextBox"
                                        onChange={e => handleInput(e, i)}
                                    />

                                </td>
                                <td>
                                    <ActionButton
                                        iconProps={{ iconName: 'Delete' }}
                                        onClick={() => handleRemoveClick(i)}
                                        className="deleteBtn"

                                    />
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>

            <DefaultButton text="Add Row" onClick={handleAddClick}
                style={{ margin: "1rem 0 0 0" }}
            />
        </div>
        <Stack horizontal horizontalAlign="center" className="margin-top-md">
            <Stack.Item>
                {resData &&
                    <PrimaryButton
                        text="Save"
                        onClick={handleOnSave}
                        className="btn-primary"
                    />}
                <DefaultButton onClick={props.onCancel} text="Cancel" />
            </Stack.Item>
        </Stack>


    </div>
}

interface ICreateFundingModal {
    data: PanelPowerItem[]
    onSave: (data: PanelPowerItem[]) => void
    onCancel: () => void
}

interface PanelPowerItem {
    value: number
    label: string,
    portrait: {
        unitHeight: number,
        unitWidth: number
    }
}
