import * as React from 'react';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  ContextualMenu,
  Modal,
  IDragOptions,
  IIconProps,
  ScrollbarVisibility,
  ScrollablePane,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { ReactNode } from 'react';

const ModalDialog = ({
  isModalOpen = false,
  isDraggable = false,
  keepInBounds = true,
  children,
  title,
  isBlocking = true,
  onDismiss,
  containerClassName,
  headerClassName,
  bodyClassName,
  isModeless = false,
  onDismissed
}: Props
) => {

  // Normally the drag options would be in a constant, but here the toggle can modify keepInBounds
  const dragOptions = React.useMemo(
    (): IDragOptions => ({
      moveMenuItemText: 'Move',
      closeMenuItemText: 'Close',
      menu: ContextualMenu,
      keepInBounds,
    }),
    [keepInBounds],
  );



  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onDismiss={onDismiss}
        isBlocking={isBlocking}
        isModeless={isModeless}
        containerClassName={containerClassName ? containerClassName : contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
        onDismissed={onDismissed}
      >
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} className={contentStyles.container}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <div className={headerClassName ? headerClassName : contentStyles.header}>
              <span >{title}</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={onDismiss}
              />
            </div>
          </Sticky>
          <div className={bodyClassName ? bodyClassName : contentStyles.body}>
            {children}
          </div>
        </ScrollablePane>
      </Modal>
    </>
  );
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    height: '100%',
    width: '100%'
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      background: `#243F61`,
      color: theme.palette.white,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '5px 12px 5px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.whiteTranslucent40,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};




interface Props {
  isModalOpen: boolean,
  isDraggable?: boolean,
  keepInBounds?: boolean,
  children: ReactNode,
  title: string,
  isBlocking?: boolean
  onDismiss: () => void
  containerClassName?: string
  headerClassName?: string,
  bodyClassName?: string,
  isModeless?: boolean,
  onDismissed?: () => void
}

export default ModalDialog;
