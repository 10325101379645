import { useEffect, useMemo, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, useDispatch } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import {
  showPopup,
  setBlock,
  addBlock,
  isEditPopup,
  editBlockAPI,
} from "../../../store/content/action";
import { IBlock, IScreen } from "../../../store/content/actionTypes";
import { toast } from "react-toastify";
import {
  Checkbox,
  ICheckboxStyles,
  LabelBase,
  TextField,
} from "@fluentui/react";
import Label from "./formElements/Label";
import { Languages } from "./util"

const Popup = (props: Props) => {
  const { type, showPopup, setBlock, addBlock, content } = props;
  const dispatch = useDispatch();
  const disableFinalQuizInput = useMemo(
    () => !!content.units?.find((unit) => unit.isFinalQuiz),
    [content.units]
  );
  const disableTakeAction = useMemo(
    () => !!content.modules?.find((module) => module.isTakeAction),
    [content.modules]
  );

  let checkboxStyle: ICheckboxStyles = {
    text: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#243f61",
    },
  };
  useEffect(() => {
    const editContent = content?.editContent;
    if (editContent && props.content.isEditPopup) {
      const { id } = editContent;
      if (type?.toLowerCase() === "module") {
        const modules = content?.modules;
        const res = modules?.find((elm) => elm.id === id);
        res && setBlock(res);
      } else if (type?.toLowerCase() === "unit") {
        const units = content?.units;
        const res = units?.find((elm) => elm.id === id);
        res && setBlock(res);
      }
    }

    return () => {
      if (props.content?.isEditPopup) setBlock(null)
    }
  }, []);

  const insertInputValidation = () => {

    if (content.block?.name === undefined || content.block?.name?.trim() === "") {
      toast.error(`Enter ${type} name.`);
      return false;
    }
    else if (type !== "Unit" && content?.block?.language === undefined || content?.block?.language?.trim() === "") {
      toast.error(`Enter ${type} language.`);
      return false;
    }
    else if (!content.block?.file) {
      toast.error(`Choose a picture for ${type} "${content.block?.name}".`);
      return false;
    }
    else if (!content.block?.animationFile && type !== "Unit") {
      toast.error(`Choose a picture for ${type} "${content.block?.name}".`)
      return false;
    }
    else if (
      (content.block?.primaryColor === undefined ||
        content.block?.secondaryColor === undefined ||
        content.block?.accentColor === undefined ||
        content.block?.accentColorTwo === undefined) &&
      type !== "Unit"
    ) {
      toast.error(`All Primary / Secondary / Accent colors are mandatory.`);
      return false;
    }
    return true
  }

  const editInputValidation = () => {

    if (content.block?.name === undefined || content.block?.name?.trim() === "") {
      toast.error(`Enter ${type} name.`);
      return false;
    }
    else if (type !== "Unit" &&  content?.block?.language === undefined || content?.block?.language?.trim() === "") {
      toast.error(`Enter ${type} language.`);
      return false;
    }
    else if (!content.block?.file && !content.block?.url) {
      toast.error(`Choose a file for ${type} "${content.block?.name}".`);
      return false;
    }
    else if (!content.block?.animationFile && !content.block?.animationURL && type !== "Unit") {
      toast.error(`Choose a picture for ${type} "${content.block?.name}".`)
      return false;
    }
    else if (
      (content.block?.primaryColor === undefined ||
        content.block?.secondaryColor === undefined ||
        content.block?.accentColor === undefined ||
        content.block?.accentColorTwo === undefined) &&
      type !== "Unit"
    ) {
      toast.error(`All Primary / Secondary / Accent colors are mandatory.`);
      return false;
    }
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //! Edit
    if (props.content?.isEditPopup) {
      const isValid = editInputValidation()
      if (!isValid) return
      dispatch(editBlockAPI(type, content.block as IBlock, content.screen?.moduleID))
      dispatch(isEditPopup(false))
      return
    }

    //! Add
    const isValid = insertInputValidation()
    if (!isValid) return
    if (content.block && content.screen)
      addBlock(content.block, content.screen);
  };

  return (
    <>
      <section className="add-block-popup">
        <header className="popup-header">
          <h3>{`${content.isEditPopup ? "Edit" : "Add"} ${type}`}</h3>
          <span
            className="close-icon"
            onClick={(e) => {
              e.stopPropagation();
              showPopup(false);
              dispatch(isEditPopup(false));
            }}
          >
            &times;
          </span>
        </header>
        <form className="block-elements" style={{ display: "block" }} onSubmit={(e) => handleSubmit(e)}>
          <div className="block-element">
            <input
              type="text"
              id="blockName"
              placeholder={`${type} Name`}
              value={props.content.block?.name}
              onChange={(e) => {
                setBlock({ ...content.block, name: e.target.value });
              }}
            />
          </div>


          <div className="block-element">
            <Label name={`${type} cover`} fontSize="1.5rem" />
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={(e) => {
                e.stopPropagation();
                if (e.target.files?.length)
                  setBlock({ ...content.block, file: e.target.files[0] });
              }}
            />
          </div>
          {type === "Module" ? (
            <>

              <div className="block-element">
                <Label name={`Animation`} fontSize="1.5rem" />
                <input
                  className="form-control"
                  type="file"
                  id="animationFile"
                  onChange={(e) => {
                    e.stopPropagation();
                    if (e.target.files?.length)
                      setBlock({
                        ...content.block,
                        animationFile: e.target.files[0],
                      });
                  }}
                />
              </div>
              <div className="block-element">
                <Checkbox
                  label="Take Action Module"
                  styles={checkboxStyle}
                  disabled={disableTakeAction}
                  onChange={(e, isChecked) =>
                    setBlock({ ...content.block, isTakeAction: isChecked })
                  }
                  checked={content.block?.isTakeAction}
                />
              </div>
              <div className="block-element">
                <Checkbox
                  label="Premium Content"
                  styles={checkboxStyle}
                  onChange={(e, isChecked) =>
                    setBlock({ ...content.block, isPremium: isChecked })
                  }
                  checked={content.block?.isPremium}
                />
              </div>
              <div className="color-picker-row">
                <div className="color-picker-group">
                  <Label name={"Primary Color"} fontSize="1.5rem" />
                  <input
                    type="color"
                    value={props?.content.block?.primaryColor}
                    onChange={(e) => {
                      setBlock({
                        ...content.block,
                        primaryColor: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="color-picker-group">
                  <Label name={"Secondary Color"} fontSize="1.5rem" />
                  <input
                    type="color"
                    value={props?.content.block?.secondaryColor}
                    onChange={(e) => {
                      setBlock({
                        ...content.block,
                        secondaryColor: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="color-picker-group">
                  <Label name={"Accent Color"} fontSize="1.5rem" />
                  <input
                    type="color"
                    value={props?.content.block?.accentColor}
                    onChange={(e) => {
                      setBlock({
                        ...content.block,
                        accentColor: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="color-picker-group">
                  <Label name={"Accent Color 2"} fontSize="1.5rem" />
                  <input
                    type="color"
                    value={props?.content.block?.accentColorTwo}
                    onChange={(e) => {
                      setBlock({
                        ...content.block,
                        accentColorTwo: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
          {type === "Unit" ? (
            <>
              <div className="block-element">
                <Checkbox
                  label="Is this a unit quiz"
                  styles={checkboxStyle}
                  onChange={(e, isChecked) =>
                    setBlock({ ...content.block, isQuiz: isChecked })
                  }
                  checked={content.block?.isQuiz}
                />
              </div>
              {content.block?.isQuiz ? (
                <div className="block-element">
                  <Label name="How many questions long" fontSize="1.5rem" />
                  <input
                    type="number"
                    id="questionNumber"
                    placeholder={`No.`}
                    value={content.block.questionLength}
                    onChange={(e) => {
                      setBlock({
                        ...content.block,
                        questionLength: parseInt(e.target.value),
                      });
                    }}
                  />
                </div>
              ) : null}
              <div className="block-element">
                <Checkbox
                  label="Final quiz"
                  styles={checkboxStyle}
                  disabled={disableFinalQuizInput}
                  onChange={(e, isChecked) =>
                    setBlock({ ...content.block, isFinalQuiz: isChecked })
                  }
                  checked={content.block?.isFinalQuiz}
                />
              </div>
              <div className="block-element">
                <Checkbox
                  label="Premium Content"
                  styles={checkboxStyle}
                  onChange={(e, isChecked) =>
                    setBlock({ ...content.block, isPremium: isChecked })
                  }
                  checked={content.block?.isPremium}
                />
              </div>
              {
                console.log("props.content.block?.prerequisites ", props.content.block?.prerequisites)
              }
              <div className="block-element">
                <Label name="Prerequisite Units" fontSize="1.5rem" />
                <Dropdown
                  style={{ width: "90%" }}
                  placeholder="Select options"
                  selectedKeys={content.block?.prerequisites}
                  onChange={(event, item?: IDropdownOption): void => {
                    if (!item) return;
                    let prerequisites = content.block?.prerequisites || [];
                    if (item.selected) {
                      prerequisites = [...prerequisites, item.key as string];
                    } else {
                      prerequisites = prerequisites.filter(
                        (key) => key !== item.key
                      );
                    }
                    setBlock({ ...content.block, prerequisites });
                  }}
                  multiSelect
                  defaultValue={props.content.block?.prerequisites || []}
                  options={
                    content.units?.map((unit) => ({
                      key: unit.id,
                      text: unit.name,
                    }))!
                  }
                />
              </div>
              <div className="block-element">
                <textarea
                  className="warning-msg"
                  placeholder="Warning Message"
                  value={props.content.block?.warningMessage}
                  onChange={(e) => {
                    setBlock({
                      ...content.block,
                      warningMessage: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          ) : null}

          {
            type?.toLowerCase() === "module" && <>
              <br />
              <LabelBase
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#243f61",
                }}
              >
                Language
              </LabelBase>
              <Dropdown
                style={{
                  marginTop: "0.5rem"
                }}
                selectedKey={props.content.block?.language || "all"}
                options={Languages}
                onChange={(e, item) =>
                  setBlock({ ...content.block, language: item?.key as string })
                }
                className="ms-Grid-col ms-lg2"

              />
            </>
          }

          <br />
          {
            type?.toLowerCase() === "module" && <div>
              <Checkbox
                label="Is Active"
                styles={checkboxStyle}
                onChange={(e, isChecked) =>
                  setBlock({ ...content.block, isActive: isChecked })
                }
                checked={props.content.block?.isActive ? true : false}
              />
            </div>
          }


          <br />

          {
            type?.toLowerCase() === "module" && <div style={{ maxWidth: "500px" }}>
              <LabelBase
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#243f61",
                }}
              >
                Description
              </LabelBase>
              <TextField
                rows={3}
                multiline={true}
                style={{
                  fontSize: "1rem",
                  color: "#243f61",
                }}
                onChange={(e: any) => {
                  setBlock({ ...content.block, description: e.target.value });
                }}
                value={props.content?.block?.description}
              />
            </div>
          }

          <div className="block-element" style={{ textAlign: "center" }}>
            <button type="submit">
              {
                props.content.isEditPopup ? "Update" : "Add"
              }
            </button>
          </div>
        </form>
      </section>
      <div className="block-popup-overlay"></div>
    </>
  );
};
interface DispatchProps {
  showPopup: (isShow: boolean) => void;
  setBlock: (block: IBlock | null) => void;
  addBlock: (block: IBlock, screen: IScreen) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  type: string;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => {
  return {
    content: state.learning.content,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    showPopup: async (isShow: boolean) => {
      await dispatch(showPopup(isShow));
    },
    setBlock: async (block: IBlock | null) => {
      await dispatch(setBlock(block));
    },
    addBlock: async (block: IBlock, screen: IScreen) => {
      await dispatch(addBlock(block, screen));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Popup);
