import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../..";
import { AnyAction } from "redux";
import axios from "axios";
import { host } from "../../config";
import { toast } from "react-toastify";
import { IAnalysis } from "@solarforschools/sfs-core";
import {
  IResArrOfObj,
  IResCapexOpex,
  IResult,
  ISchools,
  IUpdatedDesign,
  IsendThisToGenerateInvoice,
} from "../../../components/admin/costSummary/types";
import { IDesingsBasedOnSchool } from "@solarforschools/sfs-core/dist/design/types";
import { ObjectId } from "mongodb";

import { InvoiceData } from "@solarforschools/sfs-core/dist/analysis/types";
import { isLoading } from "../action";
import { IResetOpex } from "./actionTypes";
import { downloadFileFromStream } from "../../../components/admin/common/utils";

//! Below are Action Creators
export const fetchRegionSchools = (payload: ISchools[]) => {
  return {
    type: "SET_SCHOOLS",
    payload,
  };
};

// !For Dynamic Region
export const fetchRegion = (regions: string[]) => {
  return {
    type: "SET_REGIONS",
    payload: regions,
  };
};

export const fetchSchoolDesigns = (payload: IDesingsBasedOnSchool[]) => {
  return {
    type: "SET_DESIGNS",
    payload,
  };
};

export const fetchDesignRes = (payload: IAnalysis[]) => {
  return {
    type: "SET_DESIGN_RESULT",
    payload,
  };
};

export const fetchARegionCapexOpex = (payload: IResCapexOpex) => {
  return {
    type: "SET_REGION_CAPEX_OPEX",
    payload,
  };
};

export const updateDesignResult = (payload: IUpdatedDesign) => {
  return {
    type: "UPDATE_DESIGN_RESULT",
    payload,
  };
};

export const updateDesignResultInvoice = (payload: InvoiceData[]) => {
  return {
    type: "UPDATE_DESIGN_RESULT_INVOICE",
    payload,
  };
};

export const resetState = () => {
  return {
    type: "RESET_STATE",
  };
};

export const resetOpex = (): IResetOpex => {
  return {
    type: "RESET_OPEX",
  };
};

//! Below are Thunk Middlewares
export const fetchRegionBasedSchools = (
  countryName: string
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(
        `${host}/cost/summary/schools/${countryName}`
      );
      if (res.status === 200) {
        dispatch(fetchRegionSchools(res?.data));
        dispatch(isLoading(false));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong in fetching schools");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const fetchAllRegions = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${host}/cost/summary/regions`);
      if (res.status === 200) {
        dispatch(isLoading(false));
        dispatch(fetchRegion(res?.data));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong in fetching regions");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const fetchDesignsBasedSchool = (
  slug: string
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${host}/cost/summary/designs/${slug}`);
      if (res.status === 200) {
        dispatch(isLoading(false));
        dispatch(fetchSchoolDesigns(res?.data));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong in fetching designs");
      }
    } catch (err) {
      console.log(err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

export const fetchDesignResult = ({
  slug,
  designs,
}: {
  slug: string;
  designs: string;
}): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(
        `${host}/cost/summary/designs/result/${slug}/${designs}`
      );
      if (res.status === 200) {
        dispatch(isLoading(false));
        dispatch(fetchDesignRes(res?.data));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong in fetching desings' result");
      }
    } catch (err) {
      console.log(err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

export const fetchRegionCapex = (
  regionName: string,
  designId: string
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${host}/cost/summary/capex/opex/${regionName}/${designId}`
      );
      if (res.status === 200) dispatch(fetchARegionCapexOpex(res.data));
      else toast.error("Something went wrong in fetching region CAPEX");
    } catch (err) {
      console.log(err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

export const updateActuals = (
  slug: string,
  designId: ObjectId,
  data: IResult,
  isActualDesign: boolean
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));

      const url = `${host}/cost/summary/actuals/${slug}/${designId}`;
      const res = await axios.put(url, { data, isActualDesign });
      if (res.status === 200) {
        dispatch(isLoading(false));
        toast.success("CAPEX, OPEX Actuals Modified");
        dispatch(updateDesignResult(res.data));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong in Updating Actuals");
      }
    } catch (err) {
      console.log(err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

export const saveInvoiceData = (
  invoiceData: IsendThisToGenerateInvoice
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const res = await axios.post(
        `${host}/cost/summary/save/invoice`,
        invoiceData
      );
      if (res.status === 200) {
        dispatch(isLoading(false));
        toast.success("Report Generated Successfully");
        dispatch(updateDesignResultInvoice(res?.data));
      } else {
        dispatch(isLoading(false));
        toast.error("Error in Generating Report");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const exportCostSummary = (data: IResArrOfObj[]) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(isLoading(true));
      const url = `${host}/cost/summary/export`;
      const response = await axios.post(
        url,
        { data },
        {
          responseType: "arraybuffer",
        }
      );
      if (response.status === 200) {
        downloadFileFromStream({
          data: response.data,
          filename: "cost-summary",
          type: "xlsx",
        });
        dispatch(isLoading(false));
        toast.success("Exported successfully");
        return;
      }
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    } catch (err) {
      dispatch(isLoading(false));
      toast.error("Something went wrong");
      console.error("Something went wrong", err);
    }
  };
};
