import { IColumn, IDropdownOption, Link, TooltipHost } from "@fluentui/react";
import { isSortable } from "../../common/utils";
import moment from "moment";
import { numberToCurrencyFormat } from "../../../../utils/util";
import { BondTabErrors, IBondFilter } from "./types";
import { StringMap } from "../../common/types";
import { IField } from "../../../dynamic-form/DynamicForm";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";
import { BondCurrencies, pyamentPreferencesTypes } from "../helper";
import { requiredMessage } from "../../common/common";
import { host } from "../../../../store/config";
import { toast } from "react-toastify";

export const getBondsColumns: IColumn[] = [
    {
        key: 'certNumber',
        name: 'CertNumber',
        fieldName: 'certNumber',
        minWidth: 150,
        maxWidth: 150,
        ...isSortable,
        onRender: (item: IBond) => <TooltipHost content={item.certNumber?.toString() || ''} closeDelay={500}> <Link rel='noopener noreferrer' target='_blank' href={`/funder/bonds/${item.certNumber}`}>
            {item.certNumber}
        </Link></TooltipHost>
    },
    {
        key: 'bondOfferName',
        name: 'bond Offer',
        fieldName: 'bondOfferName',
        minWidth: 100,
        maxWidth: 200,
        ...isSortable,
        onRender: (item: IBond) => <TooltipHost content={item.bondOfferName || ''} closeDelay={500}><Link rel='noopener noreferrer' target='_blank' href={`/funder/bond-offers/${item.bondOfferId}`}>
            {item.bondOfferName}
        </Link></TooltipHost>
    },
    {
        key: 'type',
        name: 'Type',
        fieldName: 'type',
        minWidth: 80,
        maxWidth: 80,
        ...isSortable,
        onRender: (item: IBond) => <TooltipHost content={item.type || ''} closeDelay={500}> {item.type}</TooltipHost>
    },
    {
        key: 'status',
        name: 'Status',
        fieldName: 'status',
        minWidth: 80,
        maxWidth: 80,
        ...isSortable,
        onRender: (item: IBond) => <TooltipHost content={item.status || ''} closeDelay={500}> {item.status}</TooltipHost>
    },
    {
        key: 'bondHolder',
        name: 'Bond Holder',
        fieldName: 'userName',
        minWidth: 100,
        maxWidth: 200,
        ...isSortable,
        onRender: (item: IBond) => {
            return (<TooltipHost content={item.userName || ''} closeDelay={500}>
                <Link rel='noopener noreferrer' target='_blank' href={`/funder/bond-holders/${item.userName}`}>
                    {item.userName}
                </Link>
            </TooltipHost>)
        }
    },
    {
        key: 'bondDetails.issueDate',
        name: 'Issue Date',
        fieldName: 'bondDetails.issueDate',
        minWidth: 100,
        maxWidth: 100,
        ...isSortable,
        onRender: (item: IBond) => moment(item.bondDetails.issueDate).format('MMM DD, YYYY')
    },
    {
        key: 'bondDetails.interestStartDate',
        name: 'Interest Start Date',
        fieldName: 'bondDetails.interestStartDate',
        minWidth: 100,
        maxWidth: 100,
        ...isSortable,
        onRender: (item: IBond) => moment(item.bondDetails.interestStartDate).format('MMM DD, YYYY')
    },
    {
        key: 'order.value',
        name: 'Order',
        fieldName: 'order.value',
        minWidth: 90,
        maxWidth: 100,
        ...isSortable,
        onRender: (item: IBond) => numberToCurrencyFormat(item.order?.value || 0)
    },
    {
        key: 'bondDetails.repaymentDate',
        name: 'Repayment Date',
        fieldName: 'bondDetails.repaymentDate',
        minWidth: 100,
        maxWidth: 120,
        ...isSortable,
        onRender: (item: IBond) => item.bondDetails?.repaymentDate && moment(item.bondDetails?.repaymentDate).format('MMM DD, YYYY')
    }
];

export interface UserTabErrors {
    general: boolean
    schools: boolean
}

export const bondHolderStatus =
    [
        { key: "all", text: "All" },
        { key: "Open", text: "Open" },
        { key: "Closed", text: "Closed" },
        { key: "Deleted", text: "Deleted" },
    ]
export const defaultBondFilter: IBondFilter = {
    page: 1,
    pageSize: 50,
    sortField: 'certNumber',
    sortOrder: 'desc',
    bondOffer: 'all',
    paymentPreference: "all",
    type: "all",
    status: "all",
    period: "all",
    auditLog: "all",
    email: undefined,
    name: undefined,
}

export const bondTypes = [
    { key: "all", text: "All" },
    { key: "ISA", text: "ISA" },
    { key: "non-ISA", text: "Non ISA" }
]

export const bondTabs: BondTabErrors = {
    general: false,
    order: false,
    detail: false,
    paymentPreference: false,
    certificate: false,
    transactions: false,
    audiLog: false
}

export const getGeneralFields = ({ bond, bondOffers = [], bondHolders = [] }: { bond: IBond, bondOffers?: IDropdownOption[], bondHolders?: IDropdownOption[] }): IField[] => {
    return [
        {
            key: 'bondOfferId',
            label: 'Bond Offer',
            type: 'combobox',
            options: bondOffers.filter(b => b.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
            extras: { required: true }
        }, {
            key: 'bondOfferTitle',
            label: 'Bond Offer Title',
            type: 'string',
            placeholder: 'Enter Bond offer title[Display on Certifciate]',
            extras: { required: true, readOnly: "readOnly" },
            className: 'ms-Grid-col ms-lg6',
        }, {
            key: 'userName',
            label: 'Bond Holder',
            type: 'combobox',
            options: bondHolders.filter(b => b.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
            extras: { required: true, ...bond?._id ? { readOnly: "readOnly" } : {} },
        }, {
            key: 'certNumber',
            label: 'Certificate Number[Auto Generated]',
            type: 'number',
            placeholder: 'Enter CertNumber',
            step: "1",
            extras: { readOnly: true },
            className: 'ms-Grid-col ms-lg4',
        }, {
            key: 'ethexId',
            label: 'Ethex ID',
            type: 'string',
            placeholder: 'Enter Ethex ID',
            className: 'ms-Grid-col ms-lg4',
        }, {
            key: 'type',
            label: 'Type',
            type: 'dropdown',
            options: bondTypes.filter(t => t.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg4',
            extras: { required: true },
        }, {
            key: 'status',
            label: 'Status',
            type: 'dropdown',
            options: bondHolderStatus.filter(t => t.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg2',
            extras: { required: true },
        },
        {
            label: "*Is Test Bond",
            key: "isTestBond",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg',
        },
    ]
}

export const getOrderFields = ({ bond }: { bond: IBond }): IField[] => {
    return [
        {
            key: 'order.units',
            label: 'No Of Bonds',
            type: 'string',
            placeholder: 'Enter Unit',
            className: 'ms-Grid-col ms-lg6',
        }, {
            key: 'order.unitPrice',
            label: 'Unit Price',
            type: 'number',
            step: "1",
            placeholder: 'Enter Unit Price',
            className: 'ms-Grid-col ms-lg6',
        }, {
            key: 'order.value',
            label: 'Value[Auto Calculated]',
            type: 'number',
            placeholder: 'Order Value',
            className: 'ms-Grid-col ms-lg6',
            extras: { readOnly: "readOnly" },
        },
        {
            key: 'order.date',
            label: 'Order Date',
            type: 'date',
            placeholder: 'Pick Order date',
            className: 'ms-Grid-col ms-lg6',
            extras: { isRequired: true }
        },
    ]
}

export const getDetailFields = ({ bond }: { bond: IBond }): IField[] => {
    return [
        {
            key: 'bondDetails.bondAmount',
            label: 'Bond Amount[GBP]',
            type: 'number',
            step: "1",
            placeholder: 'Enter Bond Amount',
            className: 'ms-Grid-col ms-lg6',
            extras: { required: true }
        }, {
            key: 'bondDetails.interestRate',
            label: 'Interest Rate[%]',
            type: 'number',
            step: "0.01",
            placeholder: 'Enter Interest Rate',
            className: 'ms-Grid-col ms-lg6',
            extras: { required: true }
        },
        {
            key: 'bondDetails.issueDate',
            label: 'Issue Date',
            type: 'date',
            className: 'ms-Grid-col ms-lg6',
            extras: { isRequired: true }
        },

        {
            key: 'bondDetails.interestStartDate',
            label: 'Interest Start Date',
            type: 'date',
            className: 'ms-Grid-col ms-lg6',
            extras: { isRequired: true }
        },
        {
            key: 'bondDetails.annualPaymentDate',
            label: 'Annual Interest Payment Date',
            type: 'date',
            className: 'ms-Grid-col ms-lg6',
            extras: { isRequired: true }
        },
        {
            key: 'bondDetails.repaymentDate',
            label: 'Repayment Date',
            type: 'date',
            className: 'ms-Grid-col ms-lg6',
            extras: { isRequired: true }
        }, {
            key: 'bondDetails.currency',
            label: 'Currency',
            type: 'dropdown',
            options: BondCurrencies as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3',
            extras: { isRequired: true }
        }
    ]
}

export const getpaymentFields = ({ bond }: { bond: IBond }): IField[] => {
    return [
        {
            key: 'paymentPreference',
            label: 'Interest Payment Preference',
            type: 'dropdown',
            options: pyamentPreferencesTypes.filter(p => p.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
        }, {
            key: 'repaymentPreference',
            label: 'Re-payment Preference',
            type: 'dropdown',
            options: pyamentPreferencesTypes.filter(p => p.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
        }
    ]
}

export const getTabErrors = (errors: StringMap, bond: IBond): BondTabErrors => {
    const tabErrors = { ...bondTabs }
    Object.keys(errors).forEach(property => {
        if (getGeneralFields({ bond }).find(field => field.key === property)) {
            tabErrors.general = true;
        }
        if (getOrderFields({ bond }).find(field => field.key === property)) {
            tabErrors.order = true;
        }
        if (getDetailFields({ bond }).find(field => field.key === property)) {
            tabErrors.detail = true;
        }
        if (getpaymentFields({ bond }).find(field => field.key === property)) {
            tabErrors.paymentPreference = true;
        }
    })
    return tabErrors;
}

export const validateBond = (bond: IBond) => {
    let errors: StringMap = {};
    const { certNumber, userName, bondOfferId, type, bondDetails, order } = bond
    if (bond._id && (!certNumber || isNaN(certNumber))) errors.certNumber = requiredMessage
    if (!bondDetails.issueDate || !moment(bondDetails.issueDate).isValid()) errors["bondDetails.issueDate"] = requiredMessage
    if (!userName) errors["userName"] = requiredMessage
    if (!bondOfferId) errors["bondOfferId"] = requiredMessage
    if (!type) errors["type"] = requiredMessage
    if (!bondDetails.interestRate || isNaN(bondDetails.interestRate)) errors["bondDetails.interestRate"] = requiredMessage
    if (!bondDetails.interestStartDate || !moment(bondDetails.interestStartDate).isValid()) errors["bondDetails.interestStartDate"] = requiredMessage
    if (!bondDetails.repaymentDate || !moment(bondDetails.repaymentDate).isValid()) errors["bondDetails.repaymentDate"] = requiredMessage
    if (!order.units || isNaN(order.units)) errors["order.units"] = requiredMessage
    if (!order.unitPrice || isNaN(order.unitPrice)) errors["order.unitPrice"] = requiredMessage
    if (!order.value || isNaN(order.value)) errors["order.value"] = requiredMessage
    if (!order.date || !moment(order.date).isValid()) errors["order.date"] = requiredMessage
    if (bond.type === "ISA" && !['reinvest', "ethex", "donate"].includes(bond.repaymentPreference)) errors["paymentPreference"] = "For Isa payment preference can only be ethex, reinvest or donate!"
    if (bond.type === "ISA" && !['reinvest', "ethex", "donate"].includes(bond.repaymentPreference)) errors["repaymentPreference"] = "For Isa re-payment preference can only be ethex, reinvest or donate!"
    return errors
}

export const validateImportBond = (data: any) => {
    let errors: StringMap = {};
    const { bondOffer, bondType } = data
    if (!bondType) errors["bondType"] = requiredMessage
    if (!bondOffer) errors["bondOffer"] = requiredMessage

    return errors
}

export const validateBondRepayment = (data: any) => {
    let errors: StringMap = {};
    const { dueDate } = data
    if (!dueDate) errors["dueDate"] = requiredMessage
    const endDate = moment().add(3, 'M')
    if (moment(dueDate).isAfter(endDate)) {
        errors["dueDate"] = `Future due date should with in three month!`
        toast.error(errors["dueDate"])
    }
    return errors
}

export const validateBondTransfer = (data: any) => {
    let errors: StringMap = {};
    const { userName } = data
    if (!userName || userName === 'all') errors["userName"] = requiredMessage
    return errors
}

export const initBond: IBond = {
    accepted: false,
    auditLog: [],
    bondDetails: {
        interestRate: 0,
        interestStartDate: new Date(moment().format("YYYY-MM-DDT00:00:00.000Z")),
        issueDate: new Date(moment().format("YYYY-MM-DDT00:00:00.000Z")),
        repaymentDate: new Date(moment().format("YYYY-MM-DDT00:00:00.000Z")),
        annualPaymentDate: new Date(moment().format("YYYY-MM-DDT00:00:00.000Z")),
        bondAmount: 0,
        currency: 'GBP'
    },
    bondOfferId: "" as any,
    bondOfferName: "",
    bondOfferTitle: "",
    certNumber: 0,
    certificate: {
        path: "",
        name: "",
        id: ""
    },
    ethexId: "",
    order: {
        date: new Date(),
        units: 0,
        unitPrice: 1,
        value: 0
    },
    paymentPreference: "account",
    repaymentPreference: "account",
    status: "Open",
    token: "",
    type: "ISA",
    userName: "",
    notes: ""
}
