import _ from 'lodash';
import { Action } from "./actionTypes";
import { IInvoicePriceFilter } from '../../../components/admin/invoicePrices/types';
import { IInvoicePriceDocument } from '@solarforschools/sfs-core';
import InvoicePriceFilters from '../../../components/admin/invoicePrices/InvoicePriceFilter';

// States' definition
export interface IInvoicePrice {
  isLoading: boolean;
  invoicePrices: IInvoicePriceDocument[];
  invoicePriceFilter: IInvoicePriceFilter;

  totalCount: number
}
export interface State {
  invoicePrice: IInvoicePrice;
}

const invoicePrice = (
  state: IInvoicePrice = {
    isLoading: false,
    invoicePrices: [],
    invoicePriceFilter: {
      customerName: '',
      customerNumber: '',
      page: 1,
      pageSize: 50,
      sortField: 'date',
      sortOrder: 'desc',
      isGenerated: 'all',
    },
    totalCount: 0
  },
  action: Action
): IInvoicePrice => {
  switch (action.type) {

    case 'SET_INVOICE_PRICE_FILTER':
      return { ...state, invoicePriceFilter: action.invoicePriceFilter }
    case 'SET_INVOICE_PRICE_LIST':
      return { ...state, invoicePrices: action.invoicePrices }
    case 'SET_INVOICE_PRICE_TOTALS_COUNT': {
      return { ...state, totalCount: action.totalCount }
    }case 'BILLING/DELETE_INVOICE_PRICE': {
      const stateObj = { ...state }
      const invoicePrices: IInvoicePriceDocument[] = stateObj.invoicePrices.filter(s => s._id?.toString() !== action.id.toString())
      const filter = { ...stateObj.invoicePriceFilter }
      const { page, pageSize } = filter
      return { ...state, invoicePrices, totalCount: stateObj.totalCount - 1, invoicePriceFilter: { ...filter, page: Math.ceil(invoicePrices.length / page! * pageSize!) } }
    }
    case 'BILLING/UPDATE_INVOICE_PRICE': {
      const invoicePrices = [...state.invoicePrices]
      const index = invoicePrices.findIndex(s => s._id?.toString() === action.invoicePrice?._id?.toString())
      if (index > -1) {
        invoicePrices[index] = action.invoicePrice
      } else {
        invoicePrices.unshift(action.invoicePrice)
      }
      return { ...state, invoicePrices, totalCount: index > -1 ? state.totalCount : state.totalCount + 1 }
    }
    default:
      return state;
  }
};

export default invoicePrice;
