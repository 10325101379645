import { Dropdown } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getProjectWrokflows, setWorkflowFilter } from '../../../store/admin/workflow/action';
import { IWorkflowState } from '../../../store/admin/workflow/reducer';
import { comboBoxStyles, Regions, WorkflowTypes } from '../common/common';
import { stages } from '../schools/common';

const WorkflowFilters = (props: FilterProps) => {
    const dispatch = useDispatch()
    const { workflowFilter }: IWorkflowState = useSelector<RootState, IWorkflowState>((state) => state.web.projectWorkflow)

    const handleChange = async (key: string, value: string | undefined) => {
        const params = { ...workflowFilter, [key]: value, page: 1 };
        dispatch(setWorkflowFilter(params));
        handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback((filters: any) => { dispatch(getProjectWrokflows(filters)) }, [])

    const { stage, type, region } = workflowFilter

    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <Dropdown
                    label="Stage"
                    placeholder="Select Stage"
                    selectedKey={stage || 'all'}
                    options={stages || []}
                    onChange={(e, item) => handleChange('stage', item?.key as string)}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <Dropdown
                    label="Region"
                    placeholder="Select task"
                    selectedKey={region || 'all'}
                    options={Regions}
                    onChange={(e, item) => handleChange('region', item?.key as string)}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <Dropdown
                    label="Type"
                    placeholder="Select Type"
                    selectedKey={type || 'all'}
                    options={WorkflowTypes}
                    onChange={(e, item) => handleChange('type', item?.key as string)}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg3"
                />
            </div>
        </div>
    );
}

interface FilterProps {

}

export default WorkflowFilters;

