import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchInvoices, setInvoiceFilter } from '../../../store/admin/invoice/action';
import { IInvoice } from '../../../store/admin/invoice/reducer';
import { FundingOptions, comboBoxStyles } from '../common/common';
import InvoiceCyclePicker from '../common/InvoiceCyclePicker';

const InvoiceFilters = (props: InvoiceFilterProps) => {
  const dispatch = useDispatch()
  const invoice: IInvoice = useSelector<RootState, IInvoice>((state: { web: { invoice: IInvoice; }; }) => state.web.invoice)

  const handleChange = async (key: string, value: string | undefined | any) => {
    let params = { ...invoice.invoiceFilter, page: 1 };
    switch (key) {
      case 'selectedCycle':
        params = { ...params, cycle: value[0]?.key }
        break
      default:
        params = { ...params, [key]: value }
        break;
    }
    dispatch(setInvoiceFilter(params));
    handleUpdateFilters(params)
  }

  const handleUpdateFilters = useCallback((filters: any) => { dispatch(fetchInvoices(filters)) }, [])

  const { cycle, customerName, contractNumber, funder } = invoice.invoiceFilter

  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{ minWidth: "700px", maxWidth: "1300px" }}
    >
      <div className="ms-Grid-row">
        <TextField
          label="Customer Name"
          placeholder='Customer Name'
          type='text'
          onChange={(e, value) => handleChange('customerName', value)}
          value={customerName!}
          className="ms-Grid-col ms-lg3"
        />
        <TextField
          label="Contract Num"
          placeholder='Contract Number'
          type='text'
          onChange={(e, value) => handleChange('contractNumber', value)}
          value={contractNumber!}
          className="ms-Grid-col ms-lg2"
        />
        <div className="ms-Grid-col ms-lg4 margin-top-xsm">
          <InvoiceCyclePicker selectedItems={[{ key: 'all', name: 'All' }]} onChange={handleChange} />
        </div>
        {/* <Dropdown
          label="Cycle"
          placeholder="Choose Cycle"
          selectedKey={cycle || 'all'}
          onChange={(e, item) => handleChange('cycle', item?.key?.toString() || 'all')}
          options={invoice.cycleFilter as IDropdownOption[]}
          styles={comboBoxStyles}
          className="ms-Grid-col ms-lg3"
        /> */}
        <Dropdown
          label="Funder"
          placeholder="Choose Funder"
          selectedKey={funder || 'all'}
          onChange={(e, item) => handleChange('funder', item?.key?.toString() || 'all')}
          options={FundingOptions as IDropdownOption[]}
          styles={comboBoxStyles}
          className="ms-Grid-col ms-lg3"
        />
      </div>
    </div>
  );
}

interface InvoiceFilterProps {

}

export default InvoiceFilters;

