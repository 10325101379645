import {
  Action,
} from "./actionTypes";
import { IGSSReportFilter } from "../../../../components/admin/reporting/gssReporting/types";
import moment from "moment";

// States' definition
export interface IGSSReport {
  gssReportFilter: IGSSReportFilter;
  GSSReportData: any[];
}
export interface State {
  invoiceReport: IGSSReport;
}

const gssReport = (
  state: IGSSReport = {
    gssReportFilter: {
      system: "all",
      ownedBy: "GSS1",
      periodType: "month",
      year:parseFloat(moment().format("YYYY")),
      month:moment().format("MMM"),
      from:moment().subtract(1, "month").format("YYYY-MM-DD"),
      to:moment().format("YYYY-MM-DD"),
      sortField:"Site name",
      sortOrder:"asc"
    },
    GSSReportData: [],
  },
  action: Action
): IGSSReport => {
  switch (action.type) {
    case "REPORT/SET_GSS_REPORT_LIST": {
      return { ...state, GSSReportData: action.GSSReportData };
    }
    case "REPORT/SET_GSS_REPORT_FILTER": {
      return { ...state, gssReportFilter: action.gssReportFilter };
    }
    default:
      return state;
  }
};

export default gssReport;
