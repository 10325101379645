import _ from 'lodash';
import { Action } from "./actionTypes";
import { IProduct as IProductDocument } from "@solarforschools/sfs-core/dist/product/type";
import { IProductFilter } from '../../../components/admin/product/types';

// States' definition
export interface IProduct {
  isLoading: boolean;
  products: IProductDocument[];
  productFilter: IProductFilter;
  totalCount: number
}
export interface State {
  product: IProduct;
}

const product = (
  state: IProduct = {
    isLoading: false,
    products: [],
    productFilter: {
      title: "",
      region: "all",
      // currency: "all",
      // revenueVar: undefined,
      // recurring: undefined,
      page: 1,
      pageSize: 50,
      sortField: 'title',
      sortOrder: 'asc'
    },
    totalCount: 0
  },
  action: Action
): IProduct => {
  switch (action.type) {
    case 'SET_PRODUCT_FILTER':
      return { ...state, productFilter: action.productFilter }
    case 'SET_PRODUCT_LIST':
        return { ...state, products: action.products }
    case 'SET_PRODUCT_TOTAL_COUNT': {
      return { ...state, totalCount: action.totalCount }
    }
    default:
      return state;
  }
};

export default product;
