import { IPortfolio } from "@solarforschools/sfs-core/dist/types";
import { toast } from "react-toastify";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../..";
import { downloadFileFromStream } from "../../../../components/admin/common/utils";
import { IPortfolioFilter } from "../../../../components/admin/funder/portfolio/types";
import { exportPortfolioApi, getPortfolioApi, getReGeneratePortfolioApi } from "../../../client/funder/portfolio";
import { isLoading } from "../../action";

// Action Creators

export const setPortfolioFilter = (portfolioFilter: IPortfolioFilter) => {
  return { type: "FUNDER/SET_PORTFOLIO_FILTER", portfolioFilter };
};

export const setPortfolioList = (
  portfolioes: IPortfolio[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "FUNDER/SET_PORTFOLIO_LIST", portfolioes, totalCount, reset };
};

export const getPortfolioes =
  (params?: IPortfolioFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IPortfolioFilter =
            getState().web.portfolio.portfolioFilter;
          reqBody = { ...filters };
        }
        
        const { portfolioes, totalCount } = await getPortfolioApi(reqBody);
        dispatch(setPortfolioList(portfolioes, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const exportPortfolioes = (params?: IPortfolioFilter) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const filters: IPortfolioFilter =
          getState().web.portfolio.portfolioFilter;
        reqBody = { ...filters };
      }
      const res = await exportPortfolioApi(reqBody)
      downloadFileFromStream({ data: res.data, filename: "portfolio", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something happend wrong')
    }
  };

export const reGeneratePortfolio = () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      getReGeneratePortfolioApi()
      toast.success("Processing started!")
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something happend wrong')
    }
  };
