import { FontIcon, IColumn, Text, TextField, TooltipHost } from "@fluentui/react";
import { isSortable } from "../common/utils";
import { lowerCase, startCase, upperCase } from "lodash";
import moment from "moment";
import axios from "axios";
import { host } from "../../../store/config";
import { toast } from "react-toastify";

// Sort Array Of Objects
export const sortObj = (obj1: any, obj2: any) => {
    if (obj1.text > obj2.text) {
        return 1
    }
    else if (obj1.text < obj2.text) {
        return -1
    }
    else {
        return 0
    }
}

// To render in detailsList Table
export const getComments = (
    params: {
        actions: {
            icon: string,
            ariaLabel?: string,
            onClick: (comment: any) => any
        }[]
    }
): IColumn[] => {
    return [
        {
            key: "schoolName",
            name: "School Name",
            fieldName: "School Name",
            minWidth: 300,
            maxWidth: 300,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                let schoolName = item['schoolName']
                let value;
                const firstChar = schoolName.charAt(0);
                const restOfStr = schoolName.slice(1);
                if (firstChar.match(/^[^a-zA-Z0-9]+$/)) {
                    value = firstChar + restOfStr
                } else {
                    value = firstChar.toUpperCase() + restOfStr
                }
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "commentText",
            name: "Comment",
            fieldName: "Comment",
            minWidth: 300,
            maxWidth: 480,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                const value = item["commentText"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },

        {
            key: "userName",
            name: "Created By",
            fieldName: "Created By",
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {

                let userName = item["userName"]
                let value;
                if (userName === "na")
                    value = upperCase(userName);
                else
                    value = startCase(userName);
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },

        {
            key: "createdAt",
            name: "Created At",
            fieldName: "Created At",
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                const value = item["createdAt"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "updatedAt",
            name: "Updated At",
            fieldName: "Updated At",
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            isSortedDescending: false,
            onRender: (item) => {
                const value = item["updatedAt"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "action",
            name: "Action",
            fieldName: "-",
            ariaLabel: "View",
            isResizable: true,
            minWidth: 100,
            maxWidth: 125,
            onRender: (comment) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; ariaLabel?: string, onClick: (arg0: any) => void; }) => {

                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(comment)} />;
                        })}
                    </div>
                );
            },
        },


    ];
};


// Render String Text
export const renderNormalTextDiv = (key: string, values: any) => {
    if (typeof values !== "string" || !values) return
    let finalValues;
    if (key === "updatedAt" || key === "createdAt") finalValues = moment(values).format("DD-MM-YYYY HH:mm") //24hr format
    else finalValues = startCase(lowerCase(values))

    return (
        <div style={{ marginBottom: "5px" }}>
            <Text variant="mediumPlus">
                <span style={{ fontWeight: 600 }}>
                    {startCase(key)} </span> -
                {(values === "NA" || values === "na") ? "Not Applicable" : finalValues}
            </Text>

        </div>
    )
}

// Render Pics Array
export const renderPicsArray = (key: string, values: any) => {
    if (!Array.isArray(values) || !values || values.length == 0) return

    else {
        return <div style={{ marginBottom: "0.15rem" }}>

            <div>
                <Text variant="mediumPlus">
                    <span style={{ fontWeight: 600, paddingBottom: "0.5rem" }}>
                        Pics
                    </span>
                </Text>
                <div className="flex-row-center" style={{ overflow: "scroll", maxWidth: "38rem", height: "15rem", flexWrap: "wrap" }} >
                    {
                        values?.map((value: string, i: number) => {
                            return <>
                                <img src={value} style={{ width: "10rem", height: "10rem", marginRight: "1rem" }} alt={`pics${i}`} />
                            </>
                        })
                    }
                </div>

            </div>
        </div>
    }
}

// Render Comment Text Area
export const commentsTextDiv = (key: string, values: any) => {
    if (!values) return
    return <><TextField
        multiline
        label={startCase(key)}
        type='text'
        rows={6}
        defaultValue={values}
        disabled
    />
    </>
}

// Upload Image to S3
export const uploadImage = async (files: any, slug: string | undefined) => {
    if (!slug) {
        toast.error("Something went wrong in uploadImage image");
        return;
    }
    try {
        const formData = new FormData();
        toast.success(`New Image Uploading Please Wait...`);
        for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i])
        }

        const res = await axios.post(`${host}/users/comments/upload/image/${slug}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
        );

        if (res.data.msg === "Image not found!" || res.data?.msg?.length === 0) {
            toast.error("Something went wrong in uploadImage image")
        } else {
            toast.success("New Image Uploaded...");
            return res.data.msg;
        }

    } catch (e) {
        console.log(e);
        toast.error("Something went wrong in uploading image")
        return null;
    }
};
