import axios from "axios";
import { toast } from "react-toastify";
import { host } from "../../../../store/config";
import { IField } from "../../../dynamic-form/DynamicForm";
import { IDropdownOption } from "@fluentui/react";
import { StringMap } from "../../common/types";
import { ITeam } from "@solarforschools/sfs-core/dist/team/types";
import { get } from "lodash";

export const CategoryOptions = [
  { key: "Select an option", text: "Select an option" },
  { key: "Management", text: "Management" },
  { key: "UK Team", text: "UK Team" },
  { key: "DE Team", text: "DE Team" },
  { key: "India/IT Team", text: "India/IT Team" },
];

export const getGeneralFields: IField[] = [
  {
    label: "Name",
    key: "name",
    type: "string",
    extras: { required: true },
    placeholder: "Enter name",
    className: "ms-Grid-col  ms-lg3",
  },
  {
    label: "Designation",
    key: "designation",
    type: "string",
    extras: { required: true },
    placeholder: "Enter designation",
    className: "ms-Grid-col ms-lg4",
  },
  {
    label: "Description",
    key: "description",
    type: "multiLineText",
    rows: 4,
    placeholder: "Enter description",
    extras: { required: true },
    className: "ms-Grid-col ms-lg4",
  },
  {
    label: "Email",
    key: "email",
    type: "string",
    extras: { required: true },
    placeholder: "Enter email address",
    className: "ms-Grid-col ms-lg3",
  },
  {
    label: "Location",
    key: "location",
    type: "string",
    extras: { required: true },
    placeholder: "Enter location",
    className: "ms-Grid-col ms-lg3",
  },
  {
    label: "Category",
    key: "category",
    type: "dropdown",
    extras: { required: true },
    selectedKey: CategoryOptions[0].key,
    options: CategoryOptions as IDropdownOption[],
    className: "ms-Grid-col ms-lg4",
  },
  {
    label: "Is Active",
    key: "isActive",
    type: "checkbox",
    extras: { required: true },
    className: "ms-Grid-col ms-lg2 team-checkbox",
  },
  {
    label: "Order",
    key: "order",
    type: "number",
    extras: { required: true },
    placeholder: "Enter order",
    className: "ms-Grid-col",
  },
];

const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateDetails = async (data: Partial<ITeam>, isEdit = false) => {
  const errors: StringMap = {};
  let fields = getGeneralFields;
  if(isEdit) fields = getGeneralFields.filter(elm => elm.key !== "description" && elm.key !== "designation" && elm.key !== "email")
  else fields = getGeneralFields.filter(elm => elm.key !== "order")

  fields.forEach((field) => {
    const value = get(data, field.key);

    if (field.label.toLowerCase() === "email") {
      if (!value || value.length === 0) {
        errors[field.key] = `${field.label} cannot be empty`;
      } else {
        const res = validateEmail(value);

        if (!res) {
          errors[field.key] = `${field.label} is invalid`;
        }
      }

      return errors;
    }
    else if (field.label.toLowerCase() === "category") {
      if (!value || value.length === 0) {
        errors[field.key] = `${field.label} cannot be empty`;
      } else {
        if (value.toLowerCase() === "select an option") {
          errors[field.key] = `Choose a category`;
        }
      }

      return errors;
    }

    else {
      if((!value || value.length === 0)) {
        if (field.key.toLowerCase() === "isactive") {
          return;
        }
        errors[field.key] = `${field.label} cannot be empty`;
      }
    }
  });
  return errors;
};

export const uploadImage = async (
  files: any,
  email: string | undefined,
  isEdit = false,
  imageKey: string = ""
) => {
  if (!email) {
    toast.error("Something went wrong in uploadImage");
    return;
  }

  if (isEdit) {
    if (!imageKey) {
      toast.error("Something went wrong in editingImage");
      return;
    }
  }

  try {
    const formData = new FormData();
    formData.append("images", files[0]);

    if (!isEdit) {
      const res = await axios.post(
        `${host}/team/upload/image?email=${email}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return res.data.msg;
    } else {
      const response1 = await axios.delete(
        `${host}/team/image?image=${imageKey}`
      );
      if (response1.status === 200) {
        const response2 = await axios.post(
          `${host}/team/upload/image?email=${email}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        return response2.data.msg;
      } else {
        toast.error("Error in editing image");
      }
    }
  } catch (e: any) {
    console.log(e);
    if (
      e.response.data.msg === "Invalid email address" ||
      e.response.data.msg === "User not found"
    ) {
      toast.error("Invalid email address");
      return;
    }
    toast.error("Something went wrong in uploading image");
    return;
  }
};
