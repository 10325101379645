import { toast } from "react-toastify";
import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { host } from "../../config";
import { RootState } from "../../index";
import { isLoading } from "../action";
import {
  IBillingContract,
  IInvoiceFilter,
} from "../../../components/admin/invoice/types";
import { DropdownParams } from "../../../components/admin/common/types";
import { IBillingPeriod } from "@solarforschools/sfs-core/dist/billing/types";
import {
  updatePeriodApi,
  createPeriodApi,
  getPeriodsApi,
  deletePeriodApi,
} from "../../client/period";
import { IBillingPeriodFilter } from "../../../components/admin/period/types";

// Action Creators

export const setBillingPeriodFilter = (periodFilter: IBillingPeriodFilter) => {
  return { type: "BILLING/SET_PERIOD_FILTER", periodFilter };
};

export const setBillingPeriodList = (
  periods: IBillingPeriod[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "BILLING/SET_PERIOD_LIST", periods, totalCount, reset };
};

export const updateBillingPeriodList = (period: IBillingPeriod) => {
  return { type: "BILLING/UPDATE_PERIOD", period };
};

export const delBillingPeriod = (periodId: string) => {
  return { type: "BILLING/DELETE_PERIOD", periodId };
};

export const fetchBillingContracts = async () => {
  try {
    const { data } = await axios.get(`${host}/billing/contract`);
    const contracts: DropdownParams[] = [{ key: "all", text: "All" }];
    const contractRecords = data?.sort(
      (a: IBillingContract, b: IBillingContract) =>
        a?.customerName
          ?.toLowerCase()
          .localeCompare(b?.customerName?.toLowerCase() || "")
    );
    for (const c of contractRecords) {
      contracts.push({
        key: c.number,
        text: `${c.customerName} (${c.number})`,
      });
    }
    return contracts;
  } catch (error) {
    return [];
  }
};

export const fetchBillingPeriods = (
  params?: IBillingPeriodFilter,
  reset: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  let reqBody = params;
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>,
    getSate
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        dispatch(isLoading(true));
        if (!reqBody) {
          const periodFilter: IInvoiceFilter =
            getSate().web.billingPeriod.periodFilter;
          reqBody = { ...periodFilter };
        }
        const { periods, totalCount } = await getPeriodsApi(reqBody);
        dispatch(setBillingPeriodList(periods, totalCount, reset));
        dispatch(isLoading(false));
        resolve();
      } catch (e) {
        console.log(e);
        dispatch(isLoading(false));
      }
    });
  };
};

export const createBillingPeriod =
  (billingPeriod: IBillingPeriod) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      const { period, msg } = billingPeriod._id
        ? await updatePeriodApi(billingPeriod)
        : await createPeriodApi(billingPeriod);
      dispatch(fetchBillingPeriods(undefined, true));
      toast.success(msg);
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const deleteBillingPeriod =
  (period: IBillingPeriod) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { msg } = await deletePeriodApi(period);
      dispatch(delBillingPeriod(period._id!.toString()));
      toast.success(msg);
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };
