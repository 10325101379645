
import { SetCertificateBonOdffers, SetCertificateFilter } from './actionType';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../..';
import { isLoading } from '../../action';
import { getBondsApi } from '../../../client/funder/bond';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import { ICertificateFilter } from '../../../../components/admin/funder/certificate/types';
import { getBondOffersApi } from '../../../client/funder/bondOffer';
import { toast } from 'react-toastify';

// Action Creators

export const setCertificateFilter = (certificateFilter: ICertificateFilter): SetCertificateFilter => {
  return { type: "FUNDER/SET_CERTIFICATES_FILTER", certificateFilter };
};

export const setCertificatesList = (
  bonds: IBond[],
  totalCount: number,
  replace: boolean = true
) => {
  return { type: "FUNDER/SET_CERTIFICATES_LIST", bonds, totalCount, replace };
};


export const setCertificateBondoffers = (bondOffers: any[]): SetCertificateBonOdffers => {
  return { type: "FUNDER/CERTIFICATES_SET_BONDOFFER_FILTER", bondOffers };
};

export const getCertificates =
  (params?: ICertificateFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: ICertificateFilter =
            getState().web.certificates.certificateFilter;
          reqBody = { ...filters };
        }
        const { bonds, totalCount } = await getBondsApi(reqBody);
        dispatch(setCertificatesList(bonds, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        dispatch(isLoading(false));
      }
    };

    export const geCertifcateBondoffers =
    () =>
      async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
        try {
          dispatch(isLoading(true));
          const { bondOffers } = await getBondOffersApi({ all: "true" });
          dispatch(setCertificateBondoffers([{ key: 'all', text: 'All' }, ...bondOffers.map(b => ({ key: b._id, text: b.name }))]));
          dispatch(isLoading(false));
        } catch (e: any) {
          console.log(e);
          dispatch(isLoading(false));
          toast.error('Something went wrong')
        }
      };