import { IDropdownOption } from "@fluentui/react";
import { IProduct } from "@solarforschools/sfs-core/dist/product/type";
import { validateProductId } from "../../../store/admin/product/action";
import { IField } from "../../dynamic-form/DynamicForm";
import { currencies, Regions, requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { ScallingTypes } from "./common";

// const regionsCodes = Regions.map(r => r.key)

export const getProductFields = (options: StringMap): IField[] => [
    {
        label: "ProductId",
        key: "productId",
        type: "string",
        extras: { required: true },
        placeholder: "Product Id ",
        className: 'ms-Grid-col ms-lg4'
    }, {
        label: "Title",
        key: "title",
        type: "string",
        extras: { required: true },
        placeholder: "Product title",
        className: 'ms-Grid-col ms-lg4'
    },
    {
        label: "Region",
        key: "region",
        type: "dropdown",
        extras: { required: true },
        options: Regions.filter(c => c.key !== 'all') as IDropdownOption[],
        className: 'ms-Grid-col ms-lg4'
    },
    {
        label: "Scaling Type",
        key: "scalingType",
        type: "dropdown",
        extras: { required: true },
        options: ScallingTypes.filter(c => c.key !== 'all') as IDropdownOption[],
        className: 'ms-Grid-col ms-lg4'
    },
    {
        label: "Currency",
        key: "currency",
        type: "dropdown",
        extras: { required: true },
        options: currencies.filter(c => c.key !== 'all') as IDropdownOption[],
        className: 'ms-Grid-col ms-lg4'
    },
    {
        label: "Revenue Fix",
        key: "revenueFix",
        type: "number",
        step: "1",
        placeholder: "Revenue Fix ",
        className: 'ms-Grid-col ms-lg4'
    },
    {
        label: "Revenue Var",
        key: "revenueVar",
        type: "number",
        step: "1",
        placeholder: "Revenue Var",
        className: 'ms-Grid-col ms-lg4'
    },
    {
        label: "Recurring",
        key: "recurring",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg4 margin-top-lg'
    }
];
export const validateProduct = async (product: IProduct) => {
    let errors: StringMap = {};
    const { productId, title, region, currency, recurring, scalingType } = product || {}
    if (!productId) errors['produtId'] = requiredMessage;
    if (!region) errors['region'] = requiredMessage;
    if (!title) errors['title'] = requiredMessage;
    if (!currency) errors['currency'] = requiredMessage;
    if (!scalingType) errors['scalingType'] = requiredMessage;
    if (recurring === undefined) errors['recurring'] = requiredMessage;
    if (!Object.keys(errors).length) {
        errors = await validateProductId(product) as unknown as StringMap
    }
    return errors;
}

export const initProduct = {
    productId: '',
    title: '',
    description: '',
    region: 'GB',
    currency: 'GBP',
    revenueFix: 0,
    revenueVar: 0,
    recurring: false,
    scalingType: 'SystemSize'
}