import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { IBuildResultObject } from "./types";
import { Chart, registerables } from "chart.js";

interface ICBSLineChartProps {
    data: IBuildResultObject[];
}

const CBSLineChart = (props: ICBSLineChartProps) => {
    const { data: cbsData } = props;

    const [chartLabels, setChartLabels] = useState<string[]>([]);
    const [chartData, setChartData] = useState<number[]>([]);

    useEffect(() => {
        if (cbsData !== undefined) {
            let labels = cbsData.map((result) => result.name);
            let data = cbsData.map((result) => {
                if (typeof result.actualPerKWP === "number") return result.actualPerKWP;
                else return 0;
            });
            setChartData(data);
            setChartLabels(labels);
        }
    }, [cbsData]);

    if (chartData === undefined || chartData.length === 0) return <></>;

    Chart.register(...registerables);

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: "Count",
                data: chartData,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59",
                ],
                borderColor: "#909090",
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59",
                ],
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125,
            },
        ],
    };
    let options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                },
            },
        },
    };

    return (
        <>
            <div style={{ height: "130px", width: "280px" }}>
                <Line data={data} options={options} />
            </div>
        </>
    );
};

export default CBSLineChart;
