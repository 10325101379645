import { ICommentsType } from "@solarforschools/sfs-core/dist/comment/types";
import { Action } from "./actionTypes";
import { IResComments } from "@solarforschools/sfs-core/dist/comment/types";
import { IComments as IUniqueComment } from "@solarforschools/sfs-core/dist/comment/types";
import { toast } from "react-toastify";
import { ICommentsfilter } from "../../../components/admin/comments/type";

export interface IComments {
  commentsDetails: IResComments;
  commentsType: ICommentsType;
  commentFilter: ICommentsfilter;
  isLoading: boolean;
}

const initialState: IComments = {
  commentsDetails: {
    total: 0,
    comments: [],
    pageCount: 0,
  },
  isLoading: false,
  commentsType: { types: [] },
  commentFilter: {
    page: 1,
    pageSize: 50,
    type: "all",
    schoolName: ""
  }
};

const comments = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_COMMENTS":
      return {
        ...state,
        commentsDetails: action.payload,
        isLoading: false,
      };

    case "SET_COMMENT_FILTERS": {
      return { ...state, commentFilter: action.commentFilter }
    }

    case "SET_COMMENTS_TYPES":
      return {
        ...state,
        commentsType: action.payload,
        isLoading: false,
      };

    case "UPDATE_COMMENT":
      const updateComment = action.payload;
      const idToUpdate = updateComment._id;
      let stateCommentsData = state.commentsDetails.comments;

      // Find if id is there in state
      const isThereInState = stateCommentsData.find(
        (obj: IUniqueComment) => obj._id === idToUpdate
      );

      if (!isThereInState) {
        toast.error("Something went wrong");
        return state;
      }

      // If id is there in state, update the comment
      const updatedComments = stateCommentsData.map((obj: IUniqueComment) => {
        if (obj._id === idToUpdate) {
          return updateComment;
        }
        return obj;
      });

      // create new commentsDetails object to update it
      const updatedCommentsDetails = {
        total: state.commentsDetails.total,
        comments: updatedComments,
        pageCount: state.commentsDetails.pageCount,
      };

      return { ...state, commentsDetails: updatedCommentsDetails };

    case "DELETE_COMMENT":
      const _id = action.payload;
      let stateComments = state.commentsDetails.comments;
      let stateTotal = state.commentsDetails.total;
      let statePageCount = state.commentsDetails.pageCount;

      const foundObject = stateComments.find(
        (obj: IUniqueComment) => obj._id === _id
      );
      let newCommentsDetails;

      if (foundObject) {
        newCommentsDetails = {
          total: stateTotal - 1,
          pageCount: statePageCount - 1,
          comments: stateComments.filter((comment: any) => comment._id !== _id),
        };
      } else {
        newCommentsDetails = state.commentsDetails;
        toast.error("Something went wrong");
      }

      return {
        ...state,
        commentsDetails: newCommentsDetails,
      };

    default:
      return state;
  }
};

export default comments;
