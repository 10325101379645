import { ThunkDispatch } from "redux-thunk";

import { RootState } from "../../../index";
import { isLoading } from "../../action";
import { downloadFileFromStream } from "../../../../components/admin/common/utils";
import { IGSSReportFilter } from "../../../../components/admin/reporting/gssReporting/types";
import { getGSSReportDataApi, getGSSReportExportApi } from "../../../client/reporting/gssReport";

// Action Creators
export const setGSSReportFilter = (gssReportFilter: IGSSReportFilter) => {
  return { type: "REPORT/SET_GSS_REPORT_FILTER", gssReportFilter };
};

export const setGSSReportData = (
  GSSReportData: any[]
) => {
  return { type: "REPORT/SET_GSS_REPORT_LIST", GSSReportData };
};



export const fetchGSSReportData = (isShowLoader: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(setGSSReportData([]))
      if (isShowLoader) dispatch(isLoading(true));
      const { gssReportFilter } = getState().web.gssReport;
      const params = { ...gssReportFilter };
      const { data } = await getGSSReportDataApi(params);
      await dispatch(setGSSReportData(data));
      if (isShowLoader) dispatch(isLoading(false));
      return data
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const exportGSSReport = () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { gssReportFilter } = getState().web.gssReport;
      const params = { ...gssReportFilter };
      const data = await getGSSReportExportApi(params);
      downloadFileFromStream({ data, filename: "GSS-report", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };