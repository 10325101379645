import _ from 'lodash';
import { Action } from "./actionTypes";
import { IOpportunity as IOpportunityDocument } from "@solarforschools/sfs-core/dist/opportunity/type";
import { IOpportunityFilter } from '../../../components/admin/opportunity/types';
import moment from 'moment';

// States' definition
export interface IOpportunity {
  isLoading: boolean;
  opportunities: IOpportunityDocument[];
  opportunityFilter: IOpportunityFilter;
  totalCount: number
}
export interface State {
  opportunity: IOpportunity;
}

const opportunity = (
  state: IOpportunity = {
    isLoading: false,
    opportunities: [],
    opportunityFilter: {
      name: '',
      type: "all",
      country: "all",
      page: 1,
      pageSize: 50,
      sortField: 'title',
      sortOrder: 'asc',
      month: moment().format("MMM"),
      periodType: "all",
      year: moment().format("YYYY"),
      from: moment().subtract(1, "months").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      quarter: "1",
    },
    totalCount: 0
  },
  action: Action
): IOpportunity => {
  switch (action.type) {
    case 'SET_OPPORTUNITY_FILTER':
      return { ...state, opportunityFilter: action.opportunityFilter }
    case 'SET_OPPORTUNITY_LIST':
      const opportunities = action.reset
        ? action.opportunities
        : [...state.opportunities, ...action.opportunities];
      return { ...state, opportunities, totalCount: action.totalCount };

    case "DELETE_OPPORTUNITY": {
      const stateObj = { ...state };
      const opportunities = stateObj.opportunities.filter(
        (s) => s._id?.toString() !== action.opportunity._id?.toString()
      );
      const opportunityFilter = { ...stateObj.opportunityFilter };
      const { page, pageSize } = opportunityFilter;
      return {
        ...state,
        opportunities,
        totalCount: stateObj.totalCount - 1,
        opportunityFilter: {
          ...opportunityFilter,
          page: Math.ceil((opportunities.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_OPPORTUNITY": {
      const opportunities = [...state.opportunities];
      const index = opportunities.findIndex(
        (s) => s.linkedTo === action.opportunity.linkedTo && s.type === action.opportunity.type
      );
      if (index > -1) {
        opportunities[index] = action.opportunity;
      } else {
        opportunities.unshift(action.opportunity);
      }
      return {
        ...state,
        opportunities,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default opportunity;
