import { TextField } from '@fluentui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getUserSkills, setUserSkillFilter } from '../../../store/admin/userSkill/action';
import { IUserSkillState } from '../../../store/admin/userSkill/reducer';

const UserSkillFilters = (props: UserFunctionFilterProps) => {
  const dispatch = useDispatch()
  const { userSkillFilter }: IUserSkillState = useSelector<RootState, IUserSkillState>((state) => state.web.userSkill)
  const handleOnChangeInput = (
    value?: any,
    key?: string
  ) => {
    const params = { ...userSkillFilter, page: 1, [key!]: value };
    dispatch(setUserSkillFilter(params));
    switch (key) {
      default:
        if (value?.length === 0 || (value && value.length > 3)) {
          dispatch(getUserSkills(params));
        }
        break;
    }
  }

  const { name } = userSkillFilter
  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{ minWidth: "700px", maxWidth: "1300px" }}
    >
      <div className="ms-Grid-row">
        <TextField
          label="Name"
          placeholder="Enter Name"
          type="text"
          onChange={(e, value) => handleOnChangeInput(value, "name",)}
          value={name!}
          className="ms-Grid-col ms-lg4"
        />
      </div>
    </div>
  );
}

interface UserFunctionFilterProps {

}

export default UserSkillFilters;

