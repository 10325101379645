import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useRef } from "react";

interface CustomTagPickerProps {
   placeholder?: string;
   label?: string;
   tooltip?: string;
   itemLimit?: number;
   selectionAriaLabel?: string;
   selectedItems: ITag[];
   onResolveSuggestions: (filter: string, selectedItems?: ITag[]) => ITag[] | PromiseLike<ITag[]>;
   onChange: (items?: ITag[]) => void
}

const CustomTagPicker: FunctionComponent<CustomTagPickerProps> = ({
   placeholder,
   label,
   tooltip,
   itemLimit,
   selectionAriaLabel,
   selectedItems,
   onResolveSuggestions,
   onChange
}) => {
   const tagRef = useRef<any | null>(null);
   return (
      <>
         <Text className='' style={{ fontSize: "14px", fontWeight: 600, color: "black" }}>{label} {tooltip && (<FontIcon title={tooltip || ''} iconName="info" style={{ color: 'blue', fontSize: 15, cursor: "pointer" }} />)} </Text>
         <TagPicker
            styles={{ root: { marginTop: 5 } }}
            inputProps={{ placeholder: placeholder || "Select" }}
            onResolveSuggestions={onResolveSuggestions}
            selectedItems={selectedItems}
            itemLimit={itemLimit || 1}
            selectionAriaLabel={selectionAriaLabel}
            onChange={onChange}
            componentRef={tagRef}
         />
      </>

   );
}

export default CustomTagPicker;
