import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import { exportDailYields, getYields, setYieldsFilter } from '../../../store/admin/yields/action';
import YieldsFilters from './YieldsFilters';
import YieldsList from './YieldsList';
import { Stack, ActionButton, Text, IDropdownOption, Dropdown } from '@fluentui/react';
import { RootState } from '../../../store';
import { IYieldsState } from '../../../store/admin/yields/reducer';
import openGenYieldModal from './GenYieldsDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import openAddYieldModal from './AddYieldsDialog';
import { PageSize } from '../common/common';

const YieldsScreen = () => {
  const dispatch = useDispatch()
  const { yields, totalCount, yieldsFilter }: IYieldsState = useSelector<RootState, IYieldsState>((state: RootState) => state.web.yields)
  useEffect(() => {
    dispatch(getYields())
  }, []);

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...yieldsFilter, page: 1, pageSize: size }
    dispatch(setYieldsFilter(filters))
    dispatch(getYields())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Daily Yields</h1>
        <YieldsFilters />
      </header>
      <Stack tokens={{ padding: '0 15px' }} horizontal verticalAlign='center'>
        <span
          style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
        >
          {totalCount > 0 &&
            <>
              Displaying&nbsp;
              <Dropdown
                selectedKey={yieldsFilter.pageSize?.toString() || "50"}
                options={PageSize}
                styles={{ dropdown: { width: "80px" } }}
                onChange={handleOnChangePageSize}
              />
              &nbsp;/ {totalCount}
            </>}
        </span>
        <div style={{ display: 'flex', flex: 1 }} />
        <ActionButton
          allowDisabledFocus
          // style={{ textAlign: 'right' }}
          onClick={() => dispatch(exportDailYields())}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={async () => {
          await openGenYieldModal({})
          dispatch(getYields())
        }}>
          Generate Yields
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={async () => {
          await openAddYieldModal({})
          dispatch(getYields())
        }}>
          Add Yield
        </ActionButton>
      </Stack>
      <YieldsList actions={['edit']} />
    </div>
  );
}
export default YieldsScreen;

