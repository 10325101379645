import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { toast } from "react-toastify";
import { ParsedUrlQueryInput } from "querystring";
import {
  ISystemFilter,
  SetFilterSchool,
  SetSystemFilterParams,
  SetSystemFilter,
  ISchoolFilter,
  ISystemFilterParams,
  SetSystemList,
} from "./actionTypes";
import { host } from "../../config";
import { IDPParams, IPvamSite } from '../../../components/admin/system/types';
import { RootState } from './../../index';
import { generateQueryString } from "../../../utils/util";
import { downloadFileFromStream } from "../../../components/admin/common/utils";
import { ISystem } from "@solarforschools/sfs-core";
import { IAddSystemParams } from "../../../components/admin/common/types";
import { isLoading } from "../action";
import { getSystemCountriesApi, getSystems } from "../../client/system";
import { getSystemFilter } from "../billing/action";
import { countries } from "../../../components/admin/common/common";
import moment from "moment";
import { IMeter } from "@solarforschools/sfs-core/dist/solardb/types";
import { getSchoolFiltersApi } from "../../client/school";

// Action Creators

export const setSchoolFilter = (schools: ISchoolFilter[]): SetFilterSchool => {
  return { type: "SET_SCHOOLS_FILTER", schools };
};

export const setFtpFolders = (folders: string[]) => {
  return { type: "GET_FTP_FOLDERS", folders };
};

export const setSystemFilterParams = (params: ISystemFilterParams): SetSystemFilterParams => {
  return { type: "SET_SYSTEM_FILTER_PARAMS", params };
};

export const setSystemFilter = (systems: ISystemFilter[]): SetSystemFilter => {
  return { type: "SET_SYSTEM_FILTER", systems };
};

export const setSystemList = (systemList: ISystem[], reset = true): SetSystemList => {
  return { type: "SET_SYSTEMS_LIST", systemList, reset };
};

export const updateSystemList = (system: ISystem) => {
  return { type: "SYSTEM/UPDATE_SYSTEM", system };
};

export const deleteSystem = (id: number | string) => {
  return { type: 'DELETE_SYSTEM', id }
}

export const getNewSitesFromPvam = (pvamSites: IPvamSite[]) => {
  return { type: 'GET_NEW_SITES_FROM_PVAM', pvamSites }
}

export const getSeSystemFromPvam = (pavamSESystems: any[]) => {
  return { type: 'GET_SE_SYSTEM_FROM_PVAM', pavamSESystems }
}

export const setSystemsTotalCount = (totalCount: number) => {
  return { type: 'SET_SYSTEMS_TOTALS_COUNT', totalCount }
}

export const setSelectedSystem = (selectedSystem?: ISystem | null) => {
  return { type: 'SET_SELECTED_SYSTEM', selectedSystem }
}
export const setEpm = (epm?: number[]) => {
  return { type: 'SET_ENERGY_PER_MONTH', epm }
}

export const setFilterSystemCountries = (countries?: any[]) => {
  return { type: 'SYSTEM/SET_COUNTRIES_FILTER', countries }
}

export const setSystemServices = (services: string[]) => {
  return { type: "SET_SYSTEM_SERVICES", services };
};

export const updateSystem = (system: ISystem): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  const reqBody = system;
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = system._id as unknown as string === 'new' ? await axios
          .post(`${host}/system`, reqBody) :
          await axios
            .put(`${host}/system/${system.id}`, reqBody)

        dispatch(isLoading(false));
        if (res.status === 200) {
          toast.success(res.data.msg, { autoClose: false, closeOnClick: true });
          dispatch(updateSystemList(res.data.system))
        }
        resolve()
      } catch (e) {
        dispatch(isLoading(false));
        resolve()
      }
    });
  };
};


export const fetchSchools = (isShowLoader: boolean = true): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  const reqBody = { all: true, stage: ['Operate', 'Build', 'Developed', 'Funded', 'Managed'], live: true }
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.get(`${host}/school?${generateQueryString(reqBody as ParsedUrlQueryInput)}`)
        const schools = [{ key: "all", text: "All" }];
        res.data.schools.forEach((s: { slug: string; name: string; }) => {
          schools.push({ key: s.slug!, text: s.name });
        })
        dispatch(setSchoolFilter(schools));
        if (isShowLoader) dispatch(isLoading(false));
        resolve(res.data);
      } catch (e) {
        console.log(e);
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      }
    })
  }
};

export const fetchFtpFolders = (isShowLoader: boolean = true): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const { data } = await axios.get(`${host}/system/ftp-folders`) || []
        dispatch(setFtpFolders(data?.data.map((f: string) => { return { key: f, text: f } })));
        if (isShowLoader) dispatch(isLoading(false));
        resolve(data.data);
      } catch (e) {
        console.log(e);
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      }
    })
  }
};

export const fetchFtpFile = (meter: IMeter, isShowLoader: boolean = true): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const { data } = await axios.get(`${host}/system/ftp-file?${generateQueryString(meter as unknown as ParsedUrlQueryInput)}`) || []
        if (isShowLoader) dispatch(isLoading(false));
        resolve(data);
      } catch (e) {
        console.log(e);
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      }
    })
  }
};



export const fetchSystems = (schoolSlug?: string | number, isShowLoader: boolean = true) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => RootState
) => {
  try {
    if (isShowLoader) dispatch(isLoading(true));
    const { systems } = await getSystems({ schoolSlug })
    const systemFilters: any = await getSystemFilter(systems)
    dispatch(setSystemFilter(systemFilters));
    if (isShowLoader) dispatch(isLoading(false));
  } catch (e) {
    console.log(e);
    if (isShowLoader) dispatch(isLoading(false));
  }
}

export const setDetailListFilter = (
  params: ISystemFilterParams,
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(setSystemFilterParams(params));
      } catch (e) {
      }
    })
  };
}

export const fetchSystemsData = (params?: ISystemFilterParams, reset: boolean = true) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => RootState
) => {
  try {
    dispatch(isLoading(true))
    if (!params) {
      const systemFilterParams: ISystemFilterParams = getState().web.system.systemFilterParams
      params = { ...systemFilterParams }
    }
    const { systems, totalCount } = await getSystems({ action: 'find', ...params })
    dispatch(setSystemList(systems, reset));
    dispatch(setSystemsTotalCount(totalCount))
    dispatch(isLoading(false))
  } catch (e) {
    console.log(e);
    dispatch(isLoading(false))
  }
}

export const exportSystems = (
  isShowLoader: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const state = getSate()
        const systemFilterParams: ISystemFilterParams = state.web.system.systemFilterParams
        const reqBody = { ...systemFilterParams }
        try {
          dispatch(isLoading(true));
          const res = await axios.get(`${host}/system/download?${generateQueryString(reqBody as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' })
          downloadFileFromStream({ data: res.data, filename: "systems", type: 'xlsx' })
          if (isShowLoader) dispatch(isLoading(false));
        } catch (error) {
          console.log(error);
          if (isShowLoader) dispatch(isLoading(false));
        }
      } catch (e) {
        if (isShowLoader) console.log(e);
      }
    })
  };
}


export const deleteASystem = (
  id: number | string,
  isShowLoader: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.delete(`${host}/system/${id}`)

        if (res.status === 200) {
          toast.success(res.data.msg);
          dispatch(deleteSystem(id))
          // dispatch<any>(fetchSystemsData(undefined, false))
          if (isShowLoader) dispatch(isLoading(false));
        }
      }
      catch (e: any) {
        console.log(e);
        if (e?.response?.status === 404) {
          toast.error(e.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      };
    })
  }
}


export const fetchNewSites = (
  isShowLoader: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.get(`${host}/system/new-sites`)
        if (res.status === 200) {
          dispatch(getNewSitesFromPvam(res.data))
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve(res.data)
      } catch (e) {
        console.log(e);
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      }
    });
  };
}

export const fetchPvamSystemByProvider = (provider: String,
  isShowLoader: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.get(`${host}/system/pvam-system?provider=${provider}`)
        if (res.status === 200) {
          dispatch(getSeSystemFromPvam(res.data))
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve(res.data)
      } catch (e) {
        console.log(e);
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      }
    });
  };
}

export const fetchEnergyDetails = (
  country?: string,
  isEpm?: boolean,
  isShowLoader: boolean = true
): ThunkAction<Promise<number[]>, {}, {}, AnyAction> => {
  const reqBody = { country: country, isEpm }
  // Invoke API
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<number[]> => {
    if (isShowLoader) dispatch(isLoading(true));
    return axios
      .post(`${host}/yields/energyDetails`, reqBody)
      .then((res) => {
        if (res.status === 200) {
          if (isShowLoader) dispatch(isLoading(false));
          dispatch(setEpm(res.data.epm));
          return res.data.epm
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      });

  };
}

export const fetchSiteMeters = (siteId: number, showLoader: boolean = true): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    if (showLoader) dispatch(isLoading(true));
    return axios
      .get(`${host}/system/meter/${siteId}`,)
      .then((res) => {
        if (res.status === 200) {
          if (showLoader) dispatch(isLoading(false));
          return res.data
        }
      })
      .catch((err) => {
        console.log(err);
        if (showLoader) dispatch(isLoading(false));
      });

  }
};

export const fetchSEMeters = (systemId: number, apikey: string, showLoader: boolean = true): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    if (showLoader) dispatch(isLoading(true));
    try {
      const res = await axios.get(`${host}/system/se-inventory/${systemId}?apikey=${apikey}`)
      return res.data.data
    } catch (err) {
      console.log(err);
      if (showLoader) dispatch(isLoading(false));
      return
    }

  }
};

export const fetchSESystem = (systemId: number, apikey: string, showLoader: boolean = true): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    if (showLoader) dispatch(isLoading(true));
    try {
      const res = await axios.get(`${host}/system/se-system/${systemId}?apikey=${apikey}`)
      return res.data.data
    } catch (err) {
      console.log(err);
      if (showLoader) dispatch(isLoading(false));
      return
    }

  }
};

export const fetchPvamSitePanels = (siteId: number, showLoader: boolean = true): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    if (showLoader) dispatch(isLoading(true));
    try {
      const res = await axios.get(`${host}/system/panel/${siteId}`)
      return res.data.data
    } catch (err) {
      console.log(err);
      if (showLoader) dispatch(isLoading(false));
      return
    }

  }
};

export const fetchNewSiteData = (addSystemParams: IAddSystemParams): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  const { provider, pvamSESystem, pvamSite, seParams } = addSystemParams || {}
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    dispatch(isLoading(true))
    const promises = [dispatch(fetchEnergyDetails(pvamSite?.country || 'GB', true, false)),
    dispatch<any>(fetchPvamSitePanels(pvamSite?.siteId || pvamSESystem?.siteId || seParams?.id, false))]
    if (provider === 'solaredge') {
      promises.push(dispatch<any>(fetchSEMeters(seParams?.id!, seParams?.apiKey!, false)))
      if (!pvamSESystem) promises.push(dispatch<any>(fetchSESystem(seParams?.id!, seParams?.apiKey!, false)))
    } else {
      promises.push(dispatch<any>(fetchSiteMeters(pvamSite.siteId, false)))
    }
    try {
      const [epm, panels, meters, seSystem] = await Promise.all(promises);
      const result: any = { epm, meters, panels, seSystem: pvamSESystem || seSystem }
      dispatch(isLoading(false))
      return result
    } catch (err) {
      dispatch(isLoading(false))
    }

  }
}

export const systemDP = (
  params: IDPParams,
  isShowLoader: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const { status, data } = await axios.put(`${host}/system/dp/${params.systemId}`, params)
        if (status === 200) {
          if (isShowLoader) dispatch(isLoading(false));
        }
        resolve(data)
      }
      catch (e: any) {
        console.log(e)
        toast.error(e?.response?.data?.msg || e?.message || 'Error occure');
        if (isShowLoader) dispatch(isLoading(false));
      };
    })
  }
}

export const getSystemCountries = (schoolSlug?: string | number, isShowLoader: boolean = true) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => RootState
) => {
  try {
    if (isShowLoader) dispatch(isLoading(true));
    const data = await getSystemCountriesApi({ schoolSlug })
    const filterData = data.map((d: any) => { return { key: d, text: d } })
    dispatch(setFilterSystemCountries([{ key: 'all', text: 'All' }, ...filterData]));
    if (isShowLoader) dispatch(isLoading(false));
  } catch (e) {
    console.log(e);
    if (isShowLoader) dispatch(isLoading(false));
  }
}


export const fetchSystemServices = (
  query?: any,
  isShowLoader: boolean = true
) => {
  // Invoke
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const { services } = await getSchoolFiltersApi(query);
        console.log(services)
        dispatch(setSystemServices(services))

        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

