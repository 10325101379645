import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IUser } from "@solarforschools/sfs-core";
import { IUserFilter } from "../../../components/admin/user/types";
import {
  createUserAPi,
  deleteUserApi,
  getUsersApi,
  updateUserApi,
} from "../../client/user";
import { getUserSkillsApi } from "../../client/userSkill";

// Action Creators

export const setUserFilter = (userFilter: IUserFilter) => {
  return { type: "SET_USER_FILTER", userFilter };
};

export const setUserSkills = (skills: any) => {
  return { type: "SET_USER_SKILLS", skills };
};

export const setUserPermissions = (permissions: any) => {
  return { type: "SET_USER_PERMISSIONS", permissions };
};

export const setUserList = (
  users: IUser[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_USER_LIST", users, totalCount, reset };
};

export const updateUserList = (user: IUser) => {
  return { type: "UPDATE_USER", user };
};

export const delUser = (user: IUser) => {
  return { type: "DELETE_USER", user };
};

export const getUsers =
  (params?: IUserFilter, reset: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const filters: IUserFilter = getState().web.user.userFilter;
        reqBody = { ...filters };
      }
      const { users, totalCount } = await getUsersApi(reqBody);
      dispatch(setUserList(users, totalCount, reset));
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const createUpdateuser =
  (params: IUser) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      const { user, msg } = params._id
        ? await updateUserApi(params)
        : await createUserAPi(params);
      dispatch(updateUserList(user));
      toast.success(msg);
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const deleteUser =
  (data: IUser) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { msg } = await deleteUserApi(data);
      dispatch(delUser(data));
      toast.success(msg);
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const getUserPermissionsSkills =
  (params?: any) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let filters = params;
      if (!filters) {
        filters = { active: true, all: true };
      }
      const { userSkills }: any = await getUserSkillsApi(filters);
      const permissions = userSkills
        .filter((s: { isPermission: any }) => s.isPermission)
        .map((s: any) => {
          return {
            key: s.id,
            text: s.name,
          };
        });
      const skills = userSkills
        .filter((s: { isSkill: any }) => s.isSkill)
        .map((s: any) => {
          return {
            key: s.id,
            text: s.name,
          };
        });
      dispatch(setUserSkills(skills));

      dispatch(setUserPermissions(permissions));
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };
