import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store';
import { IActLogInitial } from '../../../store/admin/activityLogger/reducers';
import Pagination from '../common/Pagination';
import { CheckboxVisibility, DetailsList, ScrollablePane, ScrollbarVisibility, Text } from '@fluentui/react';
import { SelectionMode } from 'office-ui-fabric-react';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { RenderListRow } from '../common/RenderListRow';
import { headerRow } from './helper';
import DetailsListWapper from '../../common/DetailsListWapper';
import { fetchActivities } from '../../../store/admin/activityLogger/actions';

const Lists = () => {
  const { data, totalCount, pageNumber, filter, limit } = useSelector<RootState, IActLogInitial>((state) => state.web.activityLogger);

  const [columns] = useState(headerRow)
  const dispatch = useDispatch()

  const setFormElement = ({ page }: { page: number }) => {
    if (page === pageNumber) return

    dispatch(fetchActivities({ page, ...filter }))
  };


 const getCount = pageNumber !== 1 ? ((pageNumber -1) *  limit) + data?.length : data?.length

  return (
    <>
      { data.length > 0 && (<span style={{ margin: "0 0 10px 10px" }}>Displaying: {getCount} / {totalCount} </span>) }
      {
        data.length > 0 ? (
          <>
            <div className='data-list-container'>
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsListWapper
                  items={data}
                  columns={columns}
                  selectionMode={SelectionMode.multiple}
                  //    onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
                  onRenderDetailsHeader={DetailListStickyHeader}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  onRenderRow={(props) => RenderListRow(props!)}
                // onRenderMissingItem={onRenderMissingItem}
                />
              </ScrollablePane>
            </div>

            <Pagination
              totalRecords={totalCount}
              onPaginate={(page) => setFormElement({ page })}
              currentPage={pageNumber}
              pageSize={20}
            />
          </>
        ) : <Text variant='medium' style={{ textAlign: "center" }}>No data found</Text>
      }

    </>
  )
}

export default Lists