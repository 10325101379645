import moment from 'moment'
import { StringMap } from '../common/types'


export const PeriodReadingYears = () => {
    const years: StringMap[] = []
    let current = moment().year()
    while (current > 2018) {
        years.push({ key: current, text: current })
        current--
    }
    return years
}

export const TaskStatus = [
    { key: 'all', text: 'All' },
    { key: 'active', text: 'Active' },
    { key: 'locked', text: 'Locked' },
    { key: 'done', text: 'Done' },
    { key: 'closed', text: 'Closed' },
    { key: 'cancelled', text: 'Cancelled' },
]
export const TaskTypes = [
    { key: 'all', text: 'All' },
    { key: 'Project', text: 'Project' },
    { key: 'School', text: 'School' }
]

export const DefaultTaskFilters = {
    page: 1,
    pageSize: 50,
    sortField: 'Start',
    sortOrder: 'desc',
}
export const stageStatuses = [
    { key: 'all', text: 'All' },
    { key: 'active', text: 'Active' },
    { key: 'inactive', text: 'Inactive' }
]
