import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts";
import { IoWarningOutline } from "react-icons/io5"
import { ILiveMetersConditions } from "@solarforschools/sfs-core/dist/adminDashboard/types";
import { BsCheckCircle, BsExclamationTriangle } from "react-icons/bs"
import { BsCircle } from "react-icons/bs"
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateLiveMeter } from "../../../../../store/admin/adminDashboard/actions";
import { DefaultButton, FocusTrapZone, Layer, Overlay, Popup, Text } from "@fluentui/react";
import uniq from "lodash/uniq";

export const FrontContent = () => {

    const { liveMetersData } = useContext(CardContext) as CardContextType;
    const [isOpen, setIsOpen] = useState(false);


    const greenColor = { color: "green" };
    const redColor = { color: "red", gap: "0.5rem" };
    const fontStyle = { fontSize: "2.5rem", margin: "0.75rem" };


    const showPopup = (e: any) => {
        setIsOpen(true)
    }

    const hidePopup = (e: any) => {
        setIsOpen(false)
    }

    if (!liveMetersData) return <></>

    const getSuccessMessage = () => {
        return (<span style={greenColor}> QA Passed</span>)
    }

    const getErrorMessage = () => {
        return (
            <span style={redColor} className="flex-row-center blink-warning">
                <IoWarningOutline />
                <div> QA Failed</div>
            </span>
        )
    }

    const isQAPassed = liveMetersData?.conditions?.every((e: ILiveMetersConditions) => e.status === "pass");
    let overAllFailedMeters = []
    overAllFailedMeters.push(...liveMetersData.falsyMeters.condition1)
    overAllFailedMeters.push(...liveMetersData.falsyMeters.condition2)
    overAllFailedMeters.push(...liveMetersData.falsyMeters.condition3)
    const uniqueFailedMeters = uniq(overAllFailedMeters).sort()


    return <>
        <div className="card-content flex-column-center" >
            <div className="card-content--count" style={fontStyle}>
                {
                    isQAPassed ? getSuccessMessage() : getErrorMessage()
                }
            </div>
            <Text variant="medium" className="solar-blue"> Based on conditions</Text>
            {
                overAllFailedMeters &&
                    overAllFailedMeters.length > 0 ?
                    <span className="show-more-animation" onClick={showPopup}>Show More </span> : ""
            }
        </div>



        {
            isOpen && (
                <Layer>
                    <Popup
                        className="popup-root"
                        role="dialog"
                        aria-modal="true"
                        onDismiss={hidePopup}
                    >
                        <Overlay onClick={hidePopup} />
                        <FocusTrapZone>
                            <div role="document" className="popup-content solar-blue">

                                <h2 className="flex-row-center" style={{ marginTop: "0.5rem", marginBottom: "0.5rem", gap: "0.5rem", color: "red" }}>
                                    <BsExclamationTriangle />
                                    <div>Failed Meters</div>
                                </h2>
                                <div className="flex-row-center" style={{ justifyContent: "space-between", margin: "0 1rem 0 1rem" }}>
                                    <p>
                                        Date: <span style={{ fontWeight: "bold" }}> {moment().format("DD/MM/YYYY")}  </span>
                                    </p>
                                    <p>
                                        Count : <span style={{ fontWeight: "bold" }}>{uniqueFailedMeters.length}</span>
                                    </p>
                                </div>


                                <div className=" meters ">
                                    {uniqueFailedMeters.map(e => {
                                        return (<div className="text-center">
                                            {e}
                                        </div>
                                        )
                                    })}
                                </div>
                                <div className="flex-row-center">
                                    <DefaultButton className="button" onClick={hidePopup}>Okay</DefaultButton>
                                </div>
                            </div>
                        </FocusTrapZone>
                    </Popup>
                </Layer>
            )
        }
    </>
}





export const BackContent = () => {

    const { liveMetersData } = useContext(CardContext) as CardContextType;
    const [liveMetersConditions, setLiveMetersConditions] = useState<ILiveMetersConditions[]>([])
    const dispatch = useDispatch()

    const getStatus = () => {
        if (liveMetersData !== undefined) {
            const { conditions } = liveMetersData;
            setLiveMetersConditions(conditions)
        }
    }
    const liveMetersConditionsName: Record<number, string> = {
        1: "Check Overall Meters",
        2: "Check Yesterday's Readings",
        3: "Check Today's Readings",
    }

    useEffect(() => {
        getStatus()
    }, [liveMetersData])

    const handleUpdateStatus = (updateCondition: Partial<ILiveMetersConditions>) => {
        const today = moment().format("YYYY-MM-DD");
        updateCondition["date"] = today;
        if (updateCondition["status"] === "pass") {
            updateCondition["status"] = "fail"
        }
        else {
            updateCondition["status"] = "pass"
        }
        dispatch(updateLiveMeter(updateCondition))
    }

    const outerStyle = { minHeight: "100%", alignItems: "flex-start", maxWidth: "max-content", margin: "0 auto" }
    const headingStyle = { width: "100%", margin: "0.5rem" }
    const rowStyle = { columnGap: "0.5rem", marginBottom: "0.25rem", cursor: "pointer" }


    if (!liveMetersData || liveMetersConditions.length === 0) return <></>

    return <>

        <div className="flex-column-center" style={outerStyle}>
            <p className="solar-blue flex-row-center" style={headingStyle}>  Conditions </p>
            {
                liveMetersConditions.map((condition) => {
                    const { id, status } = condition
                    return (
                        <div key={id} className="flex-row-center solar-blue" style={rowStyle} onClick={() => handleUpdateStatus(condition)}>
                            <span className="flex-row-center" title="Click To Change">
                                {
                                    status === "pass" ?
                                        <BsCheckCircle color="green" cursor="pointer" /> :
                                        <BsCircle color="red" cursor="pointer" />
                                }
                            </span>
                            <div style={status === "pass" ? { color: "green" } : { color: "red" }} >{liveMetersConditionsName[id]}</div>

                        </div>
                    )
                })
            }
        </div>
    </>
}