import { ComboBox, DatePicker, IComboBoxOption } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../store';
import { isLoading } from '../../../store/admin/action';
import { setRTYieldsFilter, getRTYields } from '../../../store/admin/rtYields/action';
import { IRTYieldState } from '../../../store/admin/rtYields/reducer';
import { getSystems } from '../../../store/client/system';
import { comboBoxStyles, dropdownStyles } from '../common/common';
import { Providers } from '../system/common';
import { intervals } from './common';
import SystemPicker from '../common/SystemPicker';
import { IYieldsFilter } from '../yields/types';

const RTYieldsFilters = (props: RTYieldFilterProps) => {
    const dispatch = useDispatch()
    const { rtYieldsFilter }: IRTYieldState = useSelector<RootState, IRTYieldState>((state) => state.web.rtYields)


    const handleOnChange = async (
        key?: any,
        value?: any
    ) => {
        let params = { ...rtYieldsFilter, page: 1 }
        switch (key) {
            case 'selectedSystems':
                params = { ...params, system: value[0]?.key };

                break

            default:
                params = _.set({ ...params }, key, value)
                break;
        }
        dispatch(setRTYieldsFilter(params));
        dispatch(getRTYields(params));
    }



    const { start, end, provider, interval } = rtYieldsFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <div className={`ms-Grid-col ms-lg4 margin-top-xsm`}>
                    <SystemPicker onChange={handleOnChange} />
                </div>
                <DatePicker
                    label="Start Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(moment(start).format('YYYY-MM-DD'))!}
                    onSelectDate={(date: any) => handleOnChange('start', moment(date).format('YYYY-MM-DD'))}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <DatePicker
                    label="End Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(moment(end).format('YYYY-MM-DD'))!}
                    onSelectDate={(date: any) => handleOnChange('end', moment(date).format('YYYY-MM-DD'))}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <ComboBox
                    label="Interval"
                    placeholder="Choose"
                    selectedKey={interval || '15'}
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={intervals as IComboBoxOption[]}
                    onChange={(e, item?: IComboBoxOption) =>
                        handleOnChange("interval", item?.key?.toString() || '15')
                    }
                    className="ms-Grid-col ms-lg2"
                    styles={comboBoxStyles}
                />
            </div>
        </div>
    );
}

interface RTYieldFilterProps {

}

export default RTYieldsFilters;

