import { ComboBox, DefaultButton, IComboBox, IComboBoxStyles, ITooltipHostStyles, Icon, Label, PrimaryButton, Text, TextField, TooltipHost } from '@fluentui/react'
import React, { useEffect } from 'react'
import { buildFilterLang, languageOptions } from './utils/helper'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { toast } from 'react-toastify';
import { fetchTranslations, setFilterOptions } from '../../../store/admin/translations/actions';
import { useHistory } from 'react-router-dom';
import { ITranslations } from './utils/types';
import { IAdmin } from '../../../store/admin/reducer';
import { useId } from '@fluentui/react-hooks';

const Filter = () => {

    const { filter: { lang, value }, pendingDraftsCount: count } = useSelector<RootState, ITranslations>((state) => state.web.translations);
    const admin: IAdmin = useSelector<RootState, IAdmin>(
        (state: RootState) => state.web.admin
    );
    const hasTranslApprovePermission = admin.user?.permission?.includes("translationApprover")
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };


    const dispatch = useDispatch()
    const history = useHistory()
    const comboBoxStyles: Partial<IComboBoxStyles> = {
        container: { width: "20rem" },
    };



    const dispatchFetch = (params: string[]) => {
        let fetchLang = buildFilterLang(params)
        dispatch(fetchTranslations({ page: 1, ...fetchLang, hasToReset: true, value }))
    }

    const handleDropDownChange = (e: React.FormEvent<IComboBox>, item: string) => {
        if (item === "en") return

        let tempLang = [...lang]

        if (tempLang.includes(item)) {
            const filtedElm = lang.filter(el => el !== item)
            tempLang = [...filtedElm]
        } else {
            tempLang.push(item)
        }

        //! Select only three languages
        if (tempLang.length > 3) {
            toast.error("Select three languages")
            return
        }

        dispatch(setFilterOptions({ value, lang: [...tempLang] }))
        dispatchFetch(tempLang) // API Call

    }

    const shouldChangeAnimation = hasTranslApprovePermission && count
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', color: "var(--solar-blue)" } };
    const toolTipContent: string = "Max of 3 Languages can be selected"

    useEffect(() => {

        if (!hasTranslApprovePermission) return
        // bouncing animation
        const changeWidth = () => {
            let root = document.querySelector(':root') as HTMLElement;
            if (count > 0) root.style.setProperty('--animation-name', 'floating');
            else root.style.setProperty('--animation-name', '');
        }
        changeWidth()
    }, [shouldChangeAnimation])

    return (
        <>
            <div className='filter-layout'>
                <div style={{ marginBottom: "3px" }}>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Label style={{ color: "var(--solar-blue)" }}>Select language</Label>
                        </div>
                        <div>
                            <TooltipHost
                                content={toolTipContent}
                                id={tooltipId}
                                calloutProps={calloutProps}
                                styles={hostStyles}
                            >
                                <Icon
                                    iconName='Info'
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </TooltipHost>
                        </div>
                    </div>
                    <ComboBox
                        placeholder="Language"
                        multiSelect
                        allowFreeform={true}
                        autoComplete={"on"}
                        selectedKey={lang}
                        options={languageOptions}
                        onChange={(e, item) => handleDropDownChange(e, item?.key as string)}
                        styles={comboBoxStyles}
                    />
                </div>
                
                <div className='value-filter'>
                    <SearchByValue />
                </div>

            </div>
            {
                hasTranslApprovePermission &&
                <div  className='tr-pending-btn-outer'>
                    <div className='tr-pending-btn'>
                        <button
                            data-count={count}
                            onClick={() => {
                                if (count === 0) return
                                history.push('/translations/pending');
                            }}>
                            Pending Approval
                        </button>
                    </div>
                </div>
            }
        </>

    )
}
export default Filter

const SearchByValue = () => {


    const dispatch = useDispatch()
    const { filter: { lang, value } } = useSelector<RootState, ITranslations>((state) => state.web.translations);

    const handleClick = () => {
        let fetchLang = buildFilterLang(lang)
        dispatch(fetchTranslations({ page: 1, ...fetchLang, value, hasToReset: true }))
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value: changeValue } = (e.target as HTMLInputElement);
        dispatch(setFilterOptions({ value: changeValue, lang }))
    }

    const handleClearValue = () => {
        if (!value.trim()) return

        let fetchLang = buildFilterLang(lang)
        dispatch(setFilterOptions({ value: "", lang }))
        dispatch(fetchTranslations({ page: 1, ...fetchLang, value: "", hasToReset: true }))
    }

    return (
        <>
            <div className='flex-row-center search-field' >
                <TextField
                    placeholder="Enter text to search"
                    value={value}
                    onChange={handleChange}
                    style={{
                        width: "20rem"
                    }}
                />
                <PrimaryButton
                    text="Search"
                    onClick={handleClick}
                    className="btn-primary"
                />
                <DefaultButton text='Clear' onClick={handleClearValue} />
            </div>
        </>
    )
}