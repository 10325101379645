
import { SetBondHolderFilter, SetBondHolderList, UpdateBondHolderList } from './actionType';
import { IBondHolderFilter } from './../../../../components/admin/funder/bondholder/types.d';
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../..';
import { isLoading } from '../../action';
import { createBondHolderAPi, exportBondHoldersApi, getBondHoldersApi, updateBondHolderApi } from '../../../client/funder/bondHolder';
import { downloadFileFromStream } from '../../../../components/admin/common/utils';
import { toast } from 'react-toastify';
import { deleteBondHolderApi } from './../../../client/funder/bondHolder';
import bondHolder from './reducer';

// Action Creators

export const setBondHolderFilter = (bondHolderFilter: IBondHolderFilter): SetBondHolderFilter => {
  return { type: "FUNDER/SET_BONDHOLDER_FILTER", bondHolderFilter };
};

export const setBondHoldersList = (
  bondHolders: IBondHolder[],
  totalCount: number,
  replace: boolean = true
): SetBondHolderList => {
  return { type: "FUNDER/SET_BONDHOLDER_LIST", bondHolders, totalCount, replace };
};

export const updateBondHolderList = (
  bondHolder: IBondHolder,
): UpdateBondHolderList => {
  return { type: "FUNDER/UPDATE_BONDHOLDER", bondHolder };
};

export const delBondHolder = (bondHolder: IBondHolder) => {
  return { type: "FUNDER/DELETE_BONDHOLDER", bondHolder };
}

export const getBondHolders =
  (params?: IBondHolderFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IBondHolderFilter =
            getState().web.bondHolder.bondHolderFilter;
          reqBody = { ...filters };
        }
        const { bondHolders, totalCount } = await getBondHoldersApi(reqBody);
        dispatch(setBondHoldersList(bondHolders, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const exportBondHolders = (params?: IBondHolderFilter) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const filters: IBondHolderFilter =
          getState().web.bondHolder.bondHolderFilter;
        reqBody = { ...filters };
      }
      const res = await exportBondHoldersApi(reqBody)
      dispatch(isLoading(false));
      downloadFileFromStream({ data: res.data, filename: "Bond-Holders", type: 'xlsx' })
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something happend wrong')
    }
  };

export const saveBondHolder = (bondholder: IBondHolder) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  try {
    dispatch(isLoading(true));
    const apiCall = bondholder._id ? updateBondHolderApi : createBondHolderAPi;
    const { msg, bondHolder } = await apiCall(bondholder);
    dispatch(updateBondHolderList(bondHolder))
    dispatch(isLoading(false));
    toast.success(msg)
    return bondHolder;
  } catch (e: any) {
    dispatch(isLoading(false));
    const errors: any = e.response?.data?.errors && Array.isArray(e.response?.data?.errors) ? e.response?.data?.errors : e.errors ? e.errors : []
    if (errors.length) {
      const result: any = {}
      for (const error of e.response?.data?.errors) {
        result[error.param] = error.msg
      }
      return { success: false, errors: result }
    }
  }
}

export const deleteBondHolder =
  (data: IBondHolder) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteBondHolderApi(data);
        dispatch(delBondHolder(data));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        dispatch(isLoading(false));
        if (e.response?.data?.msg) toast.error(e.response?.data?.msg)
      }
    };
