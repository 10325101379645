import React, { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Dropdown, Stack, Sticky, StickyPositionType, TextField } from '@fluentui/react';
import { initSystem, Providers, setTargetMonthlyYield } from './common'
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../store';
import { ISystemState } from '../../../store/admin/system/reducer';
import { DropdownParams, IAddSystemParams, ISEParams, StringMap } from '../common/types';
import { dropdownStyles } from '../common/common';
import { IPVAMSystem } from "@solarforschools/sfs-core/dist/PVAM/types"
import { fetchEnergyDetails, fetchNewSiteData } from '../../../store/admin/system/action';
import { ISystem } from '@solarforschools/sfs-core';
import { validateAddSystem } from './SystemHelper';
import ReactDOM from 'react-dom';
import ModalDialog from '../common/ModalDialog';
import { initOrsisSystemApi } from '../../../store/client/system';
import { toast } from 'react-toastify';
import { isLoading } from './../../../store/admin/action';


export const OpenAddSystemDialog = (props: any) => {
  return new Promise<ISystem | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: any) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add New System"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <AddSystemDialog
            {...props}
            onCancel={() => callback(undefined)}
            onSave={(system: ISystem) => callback(system)}
          />
        </ModalDialog>
      </Provider>
      ,
      mountTarget
    )
  })
}

export const AddSystemDialog = ({
  onCancel,
  onSave
}: Props) => {
  const dispatch = useDispatch();

  const systemState: ISystemState = useSelector<RootState, ISystemState>((state: RootState) => state.web.system)
  const pvamSeSystems: IPVAMSystem[] = systemState.seSystems || []
  const newSites = systemState.newSites ? _.orderBy(systemState.newSites, 'site', 'asc') : []
  const sites = newSites.map(s => { return { key: s.siteId.toString(), text: `${s.site || s.siteName} (${s.siteId.toString()})` } })
  const [seSystems] = useState<IPVAMSystem[]>([...pvamSeSystems])
  const [addSystemParams, setAddSystemParams] = useState<IAddSystemParams>({
    provider: "engynious"
  })
  const [errors, setErrors] = useState<any>({});

  const handleOnChangeInput = (type: string, item: any) => {
    const addsystem = _.cloneDeep(addSystemParams);
    switch (type) {
      case 'system':
        if (item && item !== 'select') {
          addsystem.pvamSESystem = { ...seSystems.find(s => s.systemId.toString() === item.key) } as IPVAMSystem;
          addsystem.seParams = { id: addsystem.pvamSESystem.systemId, apiKey: addsystem.pvamSESystem.apiKey }
        } else {
          addsystem.pvamSESystem = {} as IPVAMSystem;
          addsystem.seParams = { id: null, apiKey: null } as unknown as ISEParams;
        }
        break;
      case 'provider':
        addsystem.provider = item?.key || 'engynious'
        break;
      case 'id':
        addsystem.seParams = { ...addsystem?.seParams, id: item } as unknown as ISEParams
        break;
      case 'apiKey':
        addsystem.seParams = { ...addsystem?.seParams, apiKey: item } as unknown as ISEParams
        break;
      default:
        const site = newSites.find(s => s.siteId?.toString() === item.key)
        const system = pvamSeSystems?.find(s => s.provider === 'solaredge' && s.siteId === site?.siteId)
        addsystem.pvamSite = site
        addsystem.pvamSESystem = system
        addsystem.seParams = { id: system?.systemId || null, apiKey: system?.apiKey || null } as unknown as ISEParams;
        break;
    }
    setAddSystemParams(addsystem);
  }

  const handleOnSave = async () => {
    const errors: StringMap = validateAddSystem(addSystemParams);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    dispatch(isLoading(true))
    let system: any = null
    // if (addSystemParams.provider === 'orsis') {

    //   system = await initOrsisSystemApi()
    //   system._id = 'new'
    // } else {
    const data: any = await dispatch(fetchNewSiteData(addSystemParams));
    system = await initSystem(addSystemParams, data);
    // }
    if (system) {
      // if country is selected repull epm data
      if (system.schools.length) {
        const energytarget: any = await dispatch(fetchEnergyDetails(system.country, true, false))
        if (energytarget) {
          system.target.yieldPerMonth = setTargetMonthlyYield(energytarget);
        }
      }
      dispatch(isLoading(false))
      onSave(system as ISystem)
    } else {
      dispatch(isLoading(false))
      toast.error('No system found')
    }
  }

  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <section className='margin-top-md'>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-lg9">
              <Dropdown
                label="Sites"
                options={sites}
                onChange={(e, item) => handleOnChangeInput('site', item as DropdownParams)}
                styles={dropdownStyles}
                errorMessage={errors?.pvamSite}
              />
            </div>
            <div className="ms-Grid-col ms-lg3">
              <Dropdown
                label="Provider"
                selectedKey={addSystemParams.provider || 'engynious'}
                options={Providers.filter(p => !['all'].includes(p.key))}
                onChange={(e, item) => handleOnChangeInput('provider', item as DropdownParams)}
                styles={dropdownStyles}
              />
            </div>
          </div>
          {addSystemParams.provider === 'solaredge' && (
            <>
              {seSystems.length > 0 &&
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-lg12">
                    <Dropdown
                      label="System"
                      selectedKey={addSystemParams.pvamSESystem?.systemId?.toString() || 'select'}
                      options={[{ key: 'select', text: 'Select' }, ...seSystems.map((s: { systemId: number; name: string; }) => { return { key: s.systemId.toString(), text: s.name }; })]}
                      onChange={(e, item) => handleOnChangeInput('system', item as DropdownParams)}
                      styles={dropdownStyles}
                    />
                  </div>
                </div>}
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg3">
                  <TextField
                    label="System Id"
                    placeholder="SE System Id"
                    type="number"
                    step="1"
                    onChange={(e, value) => handleOnChangeInput('id', value && parseFloat(value))}
                    value={addSystemParams?.seParams?.id?.toString()}
                    errorMessage={errors?.systemId}
                  />
                </div>
                <div className="ms-Grid-col ms-lg9">
                  <TextField
                    label="System Apikey"
                    placeholder="SE System apiKey"
                    onChange={(e, value) => handleOnChangeInput('apiKey', value)}
                    value={addSystemParams?.seParams?.apiKey?.toString()}
                    errorMessage={errors?.apiKey}
                  />
                </div>
              </div>
            </>
          )}
          {addSystemParams.provider === 'orsis' && (
            <div className="ms-Grid-row">
              {/* <div className="ms-Grid-col ms-lg3">
                <TextField
                  label="System Id"
                  placeholder="Orsis System Id"
                  type="text"
                  onChange={(e, value) => handleOnChangeInput('id', value)}
                  value={addSystemParams?.seParams?.id?.toString()}
                  errorMessage={errors?.systemId}
                />
              </div> */}
            </div>
          )}

        </section>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item className="margin-top-lg">
            <PrimaryButton text="Select" onClick={() => handleOnSave()} className='btn-primary' />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface OwnProps {
  onCancel: () => void
  onSave: (system: ISystem) => void
}

type Props = OwnProps;

