import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardFooter from '../layouts/CardFooter'
import CardHeader from '../layouts/CardHeader'
import { BackContent as SchoolsBackContent, FrontContent as SchoolsFrontContent } from './contents/SchoolsFlipContent'
import { BackFooter as SchoolsBackFooter, FrontFooter as SchoolsFrontFooter } from './footer/SchoolsFlipFooter'

const Schools = (props: Partial<IAdminDashboard>) => {

    if(props?.activeSchools === undefined) return <></>
    return (
        <div className="dashboard-layout--schools">
            <Card activeSchoolsData={props?.activeSchools} >
                <CardHeader
                    title="Live Schools"
                    iconName="Schools"
                />
                <CardContent variant='Flipper' title="Overall Live Schools" frontContent={SchoolsFrontContent} backContent={SchoolsBackContent} />
                <CardFooter variant='Flipper' frontFooter={SchoolsFrontFooter} backFooter={SchoolsBackFooter} />
            </Card>
        </div>
    )
}

export default Schools
