import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IYieldsFilter } from "../../../components/admin/yields/types";
import { ILiveTimeSeries } from "@solarforschools/sfs-core/dist/solardb/types";
import { getyieldsApi, genYieldsAPi, exportYieldsApi } from "../../client/yields";
import { downloadFileFromStream } from "../../../components/admin/common/utils";
import { ITSReadingFilter } from "../../../components/admin/TSReading/types";
import { exportTSReadingApi, getTSReadingsApi, swapIAndETSReadingsApi, updateTSReadingApi } from "../../client/tsReading";

// Action Creators

export const setTSReadingFilter = (tsReadingFilter: ITSReadingFilter) => {
  return { type: "SET_TSREADING_FILTER", tsReadingFilter };
};

export const setTSReadingList = (
  tsReadings: ILiveTimeSeries[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_TSREADING_LIST", tsReadings, totalCount, reset };
};

export const updateTSReadingList = (tsReading: ILiveTimeSeries) => {
  return { type: "UPDATE_TSREADING", tsReading };
};

export const swapIAndETSReadings =
  (payload: any, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const filters: ITSReadingFilter =
          getState().web.tsReadings.tsReadingFilter;
        await swapIAndETSReadingsApi(payload)
        const { tsReadings, totalCount } = await getTSReadingsApi(filters);
        dispatch(setTSReadingList(tsReadings, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const getTSReadings =
  (params?: ITSReadingFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: ITSReadingFilter =
            getState().web.tsReadings.tsReadingFilter;
          reqBody = { ...filters };
        }
        const { tsReadings, totalCount } = await getTSReadingsApi(reqBody);
        dispatch(setTSReadingList(tsReadings, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const updateTSReading =
  (params: ILiveTimeSeries) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { tsReading, msg } = await updateTSReadingApi(params)
        dispatch(updateTSReadingList(tsReading));

        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
export const genYields =
  (params: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { msg } = await genYieldsAPi(params)
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const exportTSReading = () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));

      const filters: ITSReadingFilter =
        getState().web.tsReadings.tsReadingFilter;

      const data: string = await exportTSReadingApi({ ...filters, all: true });
      downloadFileFromStream({ data, filename: "timeseries-reading", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

