import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { faEye, faEnvelope, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteInovice } from '../../../store/admin/invoice/action';

import { ConfirmDialog } from '../common/ConfirmDialog';
import DetailsListWapper from '../../common/DetailsListWapper';
import { ICreateInvoicePrice, IInvoicePriceFilter } from './types';
import { isLoading } from '../../../store/admin/action';
import { toast } from 'react-toastify';
import { getInvoicePriceColumns } from './helper';
import { OpenEmailPreviewModal } from '../common/EmailPreviewDialog';
import Pagination from '../common/Pagination';
import { createInvoicePrice, deleteInovicePrice, downloadInvoicePrice, fetchInvoicePrices, setInvoicePriceFilter } from '../../../store/admin/invoicePrice/action';
import { IInvoicePrice } from '../../../store/admin/invoicePrice/reducer';
import { IInvoicePriceDocument } from '@solarforschools/sfs-core';
import { getInvoicePriceEmailPreview, getOneInvocePriceAPI, saveInvoicePriceEmailAsDraft } from '../../../store/client/invoicePrice';

const InvoicePriceList = () => {
  const dispatch = useDispatch()
  const { invoicePrices, totalCount, invoicePriceFilter }: IInvoicePrice = useSelector<RootState, IInvoicePrice>((state: RootState) => state.web.invoicePrice)

  const openEmailPreview = async (param: any) => {
    try {
      dispatch(isLoading(true))
      const { data = [] } = { data: [await getOneInvocePriceAPI(param._id)] }
      if (!data.length) {
        dispatch(isLoading(false))
        return
      }
      const { html }: any = await getInvoicePriceEmailPreview({ id: data[0]._id })
      dispatch(isLoading(false))
      await OpenEmailPreviewModal({
        params: {
          data: [...data],
          title: 'Invoice Price Email Preview',
          btnTitle: 'Save Draft',
          html,
          sendAction: saveInvoicePriceEmailAsDraft
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }

  const handleInvoicePriceDownload = (item: IInvoicePriceDocument) => {
    dispatch(downloadInvoicePrice(item!))
  }

  const handleGenerateInvoice = async (item: IInvoicePriceDocument) => {
    dispatch(createInvoicePrice({ contractId: item.contract.customerNr, year: item.year, funder: item.contract.funder } as ICreateInvoicePrice))
  }

  const handleDelete = async (item: IInvoicePriceDocument) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete inovice Price",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${item.contract.customerNr}`,
      },
    });
    if (confirm) handleOnDelete(item._id?.toString()! as string);
  }

  const headerRow: IColumn[] = getInvoicePriceColumns({
    actions: [
      {
        title: 'Email',
        icon: faEnvelope,
        name: 'email',
        onClick: openEmailPreview,
      },
      {
        title: 'View Invoice',
        icon: faEye,
        name: 'view-invoice',
        onClick: handleInvoicePriceDownload,
      },
      {
        title: 'Generate Invoice',
        icon: faPlus,
        onClick: handleGenerateInvoice,
      },
      {
        icon: faTrash,
        title: 'Delete Invoice',
        onClick: handleDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)

  const handleOnDelete = (invoiceId: string) => {
    dispatch(deleteInovicePrice(invoiceId));
  };

  const loadNextPage = (p: IInvoicePriceFilter) => {
    dispatch(setInvoicePriceFilter({ ...invoicePriceFilter, ...p }))
    dispatch(fetchInvoicePrices())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={invoicePrices}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                onSort={(field, order) => {
                  const params = { ...invoicePriceFilter, sortField: field, sortOrder: order };
                  dispatch(setInvoicePriceFilter(params));
                  dispatch(fetchInvoicePrices(params))
                }}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={invoicePriceFilter?.page!}
            pageSize={invoicePriceFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
}

export default InvoicePriceList;

