import React, { useEffect, useState } from 'react'
import Card from '../layouts'
import CardHeader from '../layouts/CardHeader'
import CardContent from '../layouts/CardContent'
import { IBarChartData } from '../layouts/contentVariants/BarChart'
interface IData {
    year: number
    quarter: number
    avgDays: number
}
export const DealOfferAvgDays = ({ data }: { data: IData[] }) => {
    const [xAxis, setXaxis] = useState<string[]>([])
    const [chartData, setChartData]  = useState<IBarChartData[]>([])


    useEffect(() => {
        if (data && data?.length > 0) {
            const oneYearData = data?.slice(0, 4)?.reverse() // first 4 quaters   
            setXaxis(oneYearData?.map((elm) => `Q${elm.quarter}-${elm.year}`))
            const avgDays = oneYearData?.map(elm => elm.avgDays)
            setChartData([{
                label:"Avg Days",
                data: avgDays,
                backgroundColor: "#243f61",
            }])
        }
    }, [data])

    if (!data || data?.length === 0) return <></>

    return (
        <div className="dashboard-layout--pipedrive-deal-offer-avg-days">
            <Card>
                <CardHeader title='Deals Offer Sent (Avg days)' iconName='PipeDrive' />
                <CardContent variant='BarChart' labels={xAxis} barChartData={chartData} />
            </Card>
        </div>
    )
}
