import React from "react";
import "./CTemplate.css";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import { RootState } from "../../../../store/";
import { changeContentTemplate } from "../../../../store/content/action";
import Textbox from "../formElements/Textbox";
import FileUpload from "../formElements/FileUpload";
import { imagePlaceholder } from "../config";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import {
  ContentItems,
  IContentItems,
  IContentTemplate,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { getName } from "../util";

const C9 = ({ content, changeContentTemplate, handleSubmit }: Props) => {
  let moduleID: string, unitID: string;
  if (content.screen?.moduleID && content.screen?.unitID) {
    moduleID = content.screen.moduleID;
    unitID = content.screen.unitID;
  }

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const handleFileChange = (file: File | null) => {
    let contentItem: IContentItems = {
      type: ContentItems.IMAGE,
      sequence: 2,
      text: "",
      file: file,
      fileName: file?.name,
    };
    dispatchContent(contentItem, 2);
  };

  const handleTextboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    addContent(ContentItems.TITLE, e.target.value);
  };

  const addContent = (contentType: ContentItems, value: string) => {
    const sequence = 1;
    let contentItem: IContentItems = {
      type: contentType,
      sequence,
      text: value,
    };

    dispatchContent(contentItem, sequence);
  };

  const dispatchContent = (contentItem: IContentItems, sequence: number) => {
    let contentTemplate: IContentTemplate = {
      isEditing: !!content.contentTemplate?.isEditing,
      contentId: content.contentTemplate?.contentId,
      module: moduleID,
      unit: unitID,
      template: TemplateItems.C9,
      order: 0,
      items:
        content.contentTemplate?.items !== undefined
          ? content.contentTemplate?.items
          : [],
    };

    let newContent = contentTemplate.items?.filter(
      (temp) => temp.sequence !== sequence
    );

    if (newContent) {
      newContent.push(contentItem);
      contentTemplate.items = newContent;
    }

    changeContentTemplate(contentTemplate);
  };
  const { submitButtonText, imageURL } = getName(content, 2);
  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div id="content-container">
          <Textbox
            placeholder="Enter the topic here"
            maxChar={30}
            onChange={handleTextboxChange}
            width="100%"
            value={
              content.contentTemplate?.items?.find(
                (item) => item.sequence === 1
              )?.text
            }
          />
        </div>
        <FileUpload
          name="+ Add Visual"
          url={imageURL}
          width="100%"
          height="100%"
          onChangeCallback={handleFileChange}
        />
      </aside>
      <aside>
        <p>
          Use the textboxes on the screen to add your content. Drag and drop
          relevant images (or .json animations) into the area encased in dotted
          lines, or click "add visual" where appropriate.
        </p>
        <Button
          width="20%"
          height="8%"
          name={submitButtonText}
          fontSize="1.5rem"
          onClick={(e: React.MouseEvent<HTMLButtonElement> | null) =>
            handleSubmit(e, 2)
          }
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeContentTemplate: (contentTemplate: IContentTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    noOfItems: number
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeContentTemplate: async (contentTemplate: IContentTemplate) => {
      await dispatch(changeContentTemplate(contentTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(C9);
