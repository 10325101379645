import { useState } from 'react';
import { ActionButton, CheckboxVisibility, Dropdown, IDropdownOption, IconButton, ScrollablePane, ScrollbarVisibility, TooltipHost } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { isSortable } from '../common/utils';

import DetailsListWapper from '../../common/DetailsListWapper';
import { IBillingPeriodState } from '../../../store/admin/period/reducer';
import moment from 'moment';
import { IBillingPeriod } from '@solarforschools/sfs-core/dist/billing/types';
import { deleteBillingPeriod, fetchBillingPeriods, setBillingPeriodFilter } from '../../../store/admin/period/action';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { OpenAddPeriodModal } from './AddPeriodDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBillingPeriodFilter } from './types';
import Pagination from '../common/Pagination';
import { PageSize } from '../common/common';

const BillingPeriodList = () => {
  const dispatch = useDispatch()
  const { periods, totalCount, periodFilter }: IBillingPeriodState = useSelector<RootState, IBillingPeriodState>((state: RootState) => state.web.billingPeriod)

  const headerRow: IColumn[] = [
    {
      key: 'Cycle',
      name: 'Cycle',
      fieldName: 'Cycle',
      minWidth: 150,
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.Cycle} closeDelay={500}> {item.Cycle}</TooltipHost>
    },
    {
      key: 'Period',
      name: 'Period',
      fieldName: 'Period',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.Period} closeDelay={500}> {item.Period}</TooltipHost>
    },
    {
      key: 'Start',
      name: 'Start',
      fieldName: 'Start',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => item.Start ? moment(item.Start).format('YYYY-MM-DD') : ''
    },
    {
      key: 'End',
      name: 'End',
      fieldName: 'End',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => item.End ? moment(item.End).format('YYYY-MM-DD') : ''
    },
    {
      key: 'Previous',
      name: 'Previous',
      fieldName: 'Previous',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => item.Previous
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: '-',
      ariaLabel: "Action",
      minWidth: 150,
      maxWidth: 300,
      // className: 'text-right',
      // headerClassName: 'text-right',
      onRender: (item: IBillingPeriod, index?: number) => {
        return (
          <>
            <span className="action-icons">
              <FontAwesomeIcon
                title="Edit Period"
                onClick={async () => { await OpenAddPeriodModal({ period: item }) }}
                icon={faPencilAlt}
              />
            </span>
            <IconButton iconProps={{ iconName: 'RemoveFromTrash' }}
              onClick={async () => handleOnDelete(item)}
              title="Delete" ariaLabel="Delete" />
          </>
        )
      },
    }
  ];

  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnDelete = async (period: IBillingPeriod) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Period",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${period.Cycle}`,
      },
    });
    if (confirm) dispatch(deleteBillingPeriod(period));
  };

  const handleOnSort = (field: string, order: string) => {
    const params = { ...periodFilter, sortField: field, sortOrder: order };
    dispatch(setBillingPeriodFilter(params));
    dispatch(fetchBillingPeriods(params))
  }

  const loadNextPage = (p: IBillingPeriodFilter) => {
    dispatch(setBillingPeriodFilter({ ...periodFilter, ...p }))
    dispatch(fetchBillingPeriods())
  }
  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...periodFilter, page: 1, pageSize: size }
    dispatch(setBillingPeriodFilter(filters))
    dispatch(fetchBillingPeriods())
  };

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={periodFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>

        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () => await OpenAddPeriodModal({})}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp; Add Period
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={periods}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={periods.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={periodFilter?.page!}
            pageSize={periodFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
}

export default BillingPeriodList;

