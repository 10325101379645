import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { CardContext, CardContextType } from "../../layouts";
//import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";

export const FrontContent = () => {
    const { activeSchoolsData } = useContext(CardContext) as CardContextType;
    const [activeSchoolsCount, setActiveSchoolsCount] = useState(activeSchoolsData?.count)
    //   const [status, setStatus] = useState({ prevMonth: "", isEqual: "" });
    //  let countColor;


    const getStatus = () => {
        if (activeSchoolsData !== undefined) {
            setActiveSchoolsCount(activeSchoolsData?.count)
        }

        // if (activeSchoolsData !== undefined) {
        //     if (activeSchoolsData.lastSixMonths[4].count > activeSchoolsData.lastSixMonths[5].count) {
        //         setStatus({ ...status, prevMonth: "high", isEqual: "" });
        //     } else if (activeSchoolsData.lastSixMonths[4].count < activeSchoolsData.lastSixMonths[5].count) {
        //         setStatus({ ...status, prevMonth: "low", isEqual: "" });
        //     } else {
        //         setStatus({ ...status, isEqual: "yes", prevMonth: "" });
        //     }
        // }

    }

    useEffect(() => {
        getStatus()
    }, [activeSchoolsData])

    //countColor = (status?.isEqual === "yes") ? { color: "#FFCB2F" } : ((status?.prevMonth === "high") ? { color: "red" } : { color: "green" })


    return <>

        <div className="card-content">
            <div className="card-content--count" style={{ color: "#243f61" }}>
                {activeSchoolsCount !== undefined && new Intl.NumberFormat('en-GB').format(activeSchoolsCount)}
            </div>
            {/* <div>
                {status?.isEqual === "yes"
                    ? <RightTriangleIcon fontSize="2rem" />
                    : status?.prevMonth === "high"
                        ? <DownTriangleIcon fontSize="2rem" />
                        : <UpTriangleIcon fontSize="2rem" />}
            </div> */}
        </div>

    </>
}





export const BackContent = () => {
    const { activeSchoolsData } = useContext(CardContext) as CardContextType;
    const labels = activeSchoolsData?.regions.map(el => el.country)
    const data = activeSchoolsData?.regions.map(el => el.count)
    const chartData = {
        labels,
        datasets: [
            {
                label: "Count",
                data,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                borderColor: "#909090",
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },


        },
        scales: {

            x: {
                ticks: {
                    display: false
                }
            }
        }
    };

    return <>
        <div className="flex-column-center">
            <div style={{ height: "90px", width: "250px" }}>
                <Line data={chartData} options={options} />
            </div>
        </div>

    </>
}