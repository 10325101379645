export const pyamentPreferencesTypes =
    [
        { key: "all", text: "All" },
        { key: "account", text: "Account" },
        { key: "ethex", text: "Ethex" },
        { key: "cheque", text: "Cheque" },
        { key: "reinvest", text: "Re Invest" },
        { key: "donate", text: "Donate" }
    ]

export const BondCurrencies = [{ key: 'GBP', text: "GBP" }]

export const TransactionTypes = [{ key: 'all', text: "All" }, { key: "interest", text: "Interest" }, { key: "repayment", text: "Repayment" }]

export const TransactionStatus =
    [
        { key: "all", text: "All" },
        { key: "Open", text: "Open" },
        { key: "Closed", text: "Closed" },
        { key: "Accrued", text: "Accrued" },
        { key: "Cancelled", text: "Cancelled" },
    ]
