import React from "react";
import {
  ContentItems,
  DeleteType,
  IAnswer,
  IContentTemplate,
  IDBContentItems,
  IDBModule,
  IDeleteParams,
  IItems,
  IQuestionTemplate,
  IScreen,
  QuestionItems,
  Screens,
  TemplateItems,
} from "../../../store/content/actionTypes";
import Label from "./formElements/Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import {
  changeScreen,
  deleteContents,
  changeContentTemplate,
  changeQuestionTemplate,
} from "../../../store/content/action";
import { ConfirmDialog } from "../../admin/common/ConfirmDialog";

const Screen = ({
  currentScreen: { _id, answer, items, created, template },
  module: { name, _id: moduleID },
  index,
  handleDrag,
  handleDrop,
  changeScreen,
  deleteContents,
  changeContentTemplate,
  changeQuestionTemplate,
  content,
}: Props) => {
  const sortedItems = items.sort((a, b) => a.sequence - b.sequence);
  const labelStyle = { cursor: "grab" };

  const options: string[] = [];
  const getComponent = (item: IItems) => {
    switch (item.type) {
      case ContentItems.TITLE:
        return <Label name={item.text} fontSize="1.5rem" style={labelStyle} />;
      case ContentItems.IMAGE:
        if (item.url.indexOf(".json") !== -1) {
          //TODO Lottie loading is pending.
        }
        return <img src={item.url} alt={name} width="200px" />;
      case ContentItems.PARAGRAPH:
        return <p>{item.text}</p>;
      case ContentItems.SUBHEADING:
        return <Label name={item.text} fontSize="1rem" style={labelStyle} />;
      case ContentItems.VIDEO:
        return (
          <p>
            Video ID / URL is <i>{item.text}</i>
          </p>
        );
      case ContentItems.VIDEO_SOURCE:
        return (
          <p>
            <b>
              Video Source: <i>{item.text}</i>
            </b>
          </p>
        );
      case QuestionItems.QUESTION:
        return (
          <>
            <Label name="Q:" fontSize="2rem" style={{ ...labelStyle }} />
            <p style={{ fontSize: "2rem" }}>{item.text}</p>
          </>
        );
      case QuestionItems.OPTIONS:
        if (item.text) {
          options.push(item.text);
        } else if (item.texts) {
          options.push(item.texts.join(","));
        }
        break;
      case QuestionItems.CATEGORY:
        return (
          <Label
            name={`Category: ${item.text}`}
            fontSize="1rem"
            style={{ ...labelStyle }}
          />
        );
    }
  };

  const currentModule = content.allModules?.find(
    (module) => module._id === moduleID
  );
  const currentUnit = currentModule?.units?.find((unit) => {
    return unit.contents.find((content) => content._id === _id);
  });

  const handleEdit = () => {
    if (template.indexOf("C") !== -1) {
      changeScreen({
        currentScreen: Screens.ADDING_CONTENT,
        title: `${template} Template Selected`,
        cTemplate: template,
        isQuiz: content.screen?.isQuiz,
        moduleID: moduleID,
        unitID: currentUnit?._id,
        template,
      });
      changeContentTemplate(getContentTemplate());
    } else if (template.indexOf("Q") !== -1) {
      changeScreen({
        currentScreen: Screens.ADDING_QUESTION,
        title: `${template} Template Selected`,
        qTemplate: template,
        isQuiz: content.screen?.isQuiz,
        moduleID: moduleID,
        unitID: currentUnit?._id,
        template,
      });
      changeQuestionTemplate(getQuestionTemplate());
    }
  };

  const getQuestionTemplate = (): IQuestionTemplate => {
    const currentContent = currentUnit?.contents.find(
      (content) => content._id === _id
    );
    const questionTemplate: IQuestionTemplate = {
      contentId: _id,
      isEditing: true,
      module: moduleID,
      unit: currentUnit?._id,
      template,
      items: currentContent?.items,
      answer: currentContent?.answer,
    };
    if (template === TemplateItems.Q5 || template === TemplateItems.Q9) {
      const answerOrder = questionTemplate.answer?.texts;
      questionTemplate.items?.forEach((item) => {
        console.log("Running");
        item.order = answerOrder?.findIndex((val) => val === item.text);
      });
    }
    return questionTemplate;
  };

  const getContentTemplate = (): IContentTemplate => {
    const contentTemplate: IContentTemplate = {
      contentId: _id,
      isEditing: true,
      module: moduleID,
      unit: currentUnit?._id,
      template,
      items: currentUnit?.contents.find((content) => content._id === _id)
        ?.items,
      glossary: currentUnit?.contents.find((content) => content._id === _id)
        ?.glossary,
    };
    return contentTemplate;
  };

  const handleDelete = async (contentId: string) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Confirm Delete",
        closeButtonAriaLabel: "Close",
        subText: `Are you sure you want to delete ?`,
      },
      confirmBtnText: "OK",
    });
    const params: IDeleteParams = {
      type: DeleteType.CONTENT,
      moduleId: content.screen?.moduleID,
      unitId: content.screen?.unitID,
      contentId,
    };
    if (confirm) {
      deleteContents(params);
    }
  };

  const getAnswer = (answer: IAnswer) => {
    const {
      text = undefined,
      options = undefined,
      texts = undefined,
      tips = undefined,
    } = answer;
    const ans = text || options?.join(",") || texts?.join(",");
    return (
      <div className="question-answers">
        {ans ? (
          <>
            <Label name="Answer" fontSize="1rem" style={labelStyle} /> : {ans}
          </>
        ) : null}
        {tips ? (
          <p>
            <Label name="Correct!" fontSize="1rem" style={labelStyle} />:<br />
            {tips}
          </p>
        ) : null}
      </div>
    );
  };

  const getOptions = () => {
    return options.length > 0 ? (
      <ol>
        {options.map((option) => (
          <li>{option}</li>
        ))}
      </ol>
    ) : null;
  };

  return (
    <div
      className="content-single-screen"
      draggable={true}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      id={index.toString()}
    >
      <span className="content-order">{`${index} | ${template}`}</span>
      <Label name={name} fontSize="2rem" style={labelStyle} />
      {sortedItems.map((item) => getComponent(item))}
      {getOptions()}
      {answer ? getAnswer(answer) : null}
      <div className="screen-content-edit">
        <FontAwesomeIcon
          title="Edit"
          color="#243f61"
          icon={faEdit}
          size="2x"
          onClick={handleEdit}
        />
      </div>
      <div className="screen-content-delete">
        <FontAwesomeIcon
          title="Delete"
          color="red"
          icon={faTrashAlt}
          size="2x"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(_id);
          }}
        />
      </div>
    </div>
  );
};

interface DispatchProps {
  changeScreen: (screen: IScreen) => void;
  deleteContents: (params: IDeleteParams) => void;
  changeContentTemplate: (content: IContentTemplate) => void;
  changeQuestionTemplate: (questionTemplate: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  module: IDBModule;
  currentScreen: IDBContentItems;
  index: number;
  handleDrag: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeScreen: async (screen: IScreen) => {
      await dispatch(changeScreen(screen));
    },
    deleteContents: async (params: IDeleteParams) => {
      await dispatch(deleteContents(params));
    },
    changeContentTemplate: async (contents: IContentTemplate) => {
      await dispatch(changeContentTemplate(contents));
    },
    changeQuestionTemplate: async (question: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(question));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
