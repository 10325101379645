import { ChoiceGroup, Dropdown, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import { getBonds, setBondsFilter } from '../../../../store/admin/funder/bond/action';
import { RootState } from '../../../../store';
import { StringMap } from '../../common/types';
import { dropdownStyles } from '../../common/common';
import { pyamentPreferencesTypes } from '../helper';
import { bondHolderStatus, bondTypes } from '../bond/BondHelper';
import { ICertificateState } from '../../../../store/admin/funder/certificate/reducer';
import { getCertificates, setCertificateFilter } from '../../../../store/admin/funder/certificate/action';
import BondOfferPicker from '../../common/BondOfferPicker';


const CertificateFilters = () => {
  const dispatch = useDispatch()
  const { certificateFilter, bondOffers }: ICertificateState = useSelector<RootState, ICertificateState>((state) => state.web.certificates)
  const setFilters = (filters: StringMap) => dispatch(setCertificateFilter(filters));

  const handleOnChange = async (
    key: string,
    value?: any
  ) => {
    let params = { ...certificateFilter, page: 1, [key]: value };
    switch (key) {
      case 'selectedBondOffer':
        params = { ...params, bondOffer: value[0]?.key }
        break;
      default:
        params = { ...certificateFilter, page: 1, [key]: value };
        break;
    }
    setFilters(params);
    handleUpdateFilters(params)
  }

  const handleUpdateFilters = useCallback(
    (filters: any) => {
      dispatch(getCertificates(filters));
    },
    []
  );



  const { name, email, type, status, paymentPreference, bondOffer } = certificateFilter
  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{ minWidth: "700px", maxWidth: "1300px" }}
    >
      <div className="ms-Grid-row">
        <div className={`ms-Grid-col ms-lg4 margin-top-xsm`}>
          <BondOfferPicker filters={{}} onChange={handleOnChange} />
        </div>
        <TextField
          label="Certificate Number"
          placeholder="Enter Certificate Number"
          onChange={(e, value) => handleOnChange('certNumber', value || '')}
          className="ms-Grid-col ms-lg4"
          value={name!} />
        <TextField
          label="Bond Holder"
          placeholder="Enter User Name"
          onChange={(e, value) => handleOnChange('email', value || '')}
          className="ms-Grid-col ms-lg4"
          value={email!} />
      </div>
      <div className="ms-Grid-row">
        <Dropdown
          dropdownWidth='auto'
          label="Payment Preferences"
          onChange={(e, item) => handleOnChange("paymentPreference", item?.key?.toString())}
          selectedKey={paymentPreference || 'all'}
          options={pyamentPreferencesTypes}
          styles={dropdownStyles}
          className="inlineflex ms-Grid-col ms-lg4"
        />
        <ChoiceGroup
          className="inlineflex ms-Grid-col ms-lg4"
          label="Type"
          selectedKey={type || "all"}
          onChange={(e, option) => handleOnChange("type", option?.key)}
          options={bondTypes}
        />
        <ChoiceGroup
          className="inlineflex ms-Grid-col ms-lg4"
          label="Status"
          selectedKey={status || "all"}
          onChange={(e, option) => handleOnChange("status", option?.key)}
          options={bondHolderStatus}
        />

      </div>
    </div>
  );
}

export default CertificateFilters;

