import { useEffect, useMemo, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ITag, Separator, Stack, Sticky, StickyPositionType, TagPicker, Text } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { IOpportunity } from '@solarforschools/sfs-core/dist/opportunity/type';
import { Provider, useDispatch } from 'react-redux';
import _ from 'lodash';
import ModalDialog from '../common/ModalDialog';
import { IStage } from '@solarforschools/sfs-core/dist/school/types';
import ReactDOM from 'react-dom';
import store from '../../../store';
import { createLeadApi, getPipedriveLeadLabelsApi, getPipedriveLeadSourcesApi } from '../../../store/client/pipedrive/lead';
import { getPipedriveContactApi, getPipedriveContactLabelsApi, getPipedriveContactTypesApi } from '../../../store/client/pipedrive/contact';
import { AnyError } from 'mongodb';
import { getContactFields, getLeadFields, isNewContact, validateAddLead } from './helper';
import DynamicForm, { IField } from '../../dynamic-form/DynamicForm';
import { StringMap } from '../common/types';
import { IAddLead } from './type';
import { toast } from 'react-toastify';


export const OpenPipedriveLeadModal = (props: { params: any }) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: { data: any }) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Add Edit Lead"}
          onDismiss={() => callback(undefined)}
          containerClassName="modal-size-sm"
        >
          <AddPipedriveLead {...props} onSave={(data) => callback({ data })} onCancel={() => callback(undefined)} />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

export const AddPipedriveLead = ({
  params,
  onCancel,
  onSave,
}: Props) => {
  const dispatch = useDispatch();
  const [lead, setLead] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [sourses, setSoureces] = useState<any>([])
  const [labels, setLabels] = useState<any>([])
  const [contactTypes, setContactTypes] = useState<any>([])
  const [contactLabels, setContactLabels] = useState<any>([])

  const [contact, setContact] = useState<ITag[]>([])

  // 
  const [payload, setPayload] = useState<IAddLead>({ isNewContact: false, slug: params.school.slug, title: params.school.name })

  const contactFilds = useMemo(() => getContactFields({ payload, contactTypes, contactLabels }), [payload, contactTypes, contactLabels]);
  const leadFields = useMemo(() => getLeadFields({ payload, labels, sourses }), [payload, labels, sourses]);

  const handleGetPipedrvieContacts = async (
    filter: string,
  ): Promise<ITag[]> => {
    try {
      const { items } = await getPipedriveContactApi({ search: filter })
      console.log(items)
      const selectedMap: { [key: string]: boolean } = {};
      return items
        .filter((contact: { item: { id: string | number; }; }) => !selectedMap[contact.item.id])
        .map((contact: { item: { id: any; primary_email: any; }; }) => {
          return {
            key: contact.item.id,
            name: contact.item.primary_email,
          }
        })
    } catch (error) {
      return [] as ITag[]
    }

  }


  const handleSelectPipedriveContact = async (items?: ITag[]) => {
    //if (!items) return;
    handleOnChangeInput('contact', items ? items[0] : undefined)
    setContact(items || [])
  }
  const loadData = async () => {
    try {
      const promises: any[] = []
      promises.push(getPipedriveLeadSourcesApi())
      promises.push(getPipedriveLeadLabelsApi())
      promises.push(getPipedriveContactTypesApi())
      // promises.push(getPipedriveContactLabelsApi())
      const [sources = [], labels = [], contactTypes = []] = await Promise.all(promises)
      setContactTypes(contactTypes.map((c: { id: { toString: () => any; }; label: any; }) => ({ key: c.id.toString(), text: c.label })))
      // setContactLabels(contactLabels.map((c: { id: { toString: () => any; }; label: any; }) => ({ key: c.id.toString(), text: c.label })))

      setSoureces(sources.map((p: AnyError) => ({ key: p.name, text: p.name })))
      setLabels(labels.map((p: { id: any; name: any; }) => ({ key: p.id, text: p.name })))

    } catch (error) {

    }
  }

  useEffect(() => {
    loadData();
  }, [])



  const handleOnChangeInput = async (key: string, value: any) => {
    const data = { ...payload, [key]: value }
    setPayload(data)
  }
  const handleOnSave = async () => {
    const errors: StringMap = await validateAddLead(payload);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    try {
      const data = await createLeadApi({ ...payload });
      toast.success(data.msg)
      onSave(data)
    } catch (error) {
      toast.error('Something happennd wrong')
    }
  };

  return (
    <>
      <div className="edit-record">
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            {!payload.isNewContact &&
              <div className={`margin-top-md margin-bottom-md ms-Grid-col ms-lg6`}>
                <Text className='' style={{ fontSize: "14px", fontWeight: 600, color: "black" }}>Existing Email</Text>
                <TagPicker
                  styles={{ root: { marginTop: 5 } }}
                  inputProps={{ placeholder: "Enter Email" }}
                  onResolveSuggestions={handleGetPipedrvieContacts}
                  selectedItems={contact.length > 0 ? [{ name: contact[0].name, key: contact[0].key }] : []}
                  itemLimit={1}
                  selectionAriaLabel={`Selected`}
                  onChange={handleSelectPipedriveContact}
                />
              </div>}
            <DynamicForm
              fields={isNewContact as IField[]}
              data={payload}
              onChange={handleOnChangeInput}
              errors={errors}
            />
          </div>
          {payload.isNewContact &&
            <>
              <div className="ms-Grid-row margin-top-md">
                <div className="ms-Grid-col ms-lg12">
                  <Separator>New Contact Informtion</Separator>
                </div>
              </div>
              <div className="ms-Grid-row">
                <DynamicForm
                  fields={contactFilds}
                  data={payload}
                  onChange={handleOnChangeInput}
                  errors={errors}
                />
              </div>
            </>
          }

          <div className="ms-Grid-row margin-top-md">
            <div className="ms-Grid-col ms-lg12">
              <Separator>Lead Informtion</Separator>
            </div>
          </div>
          <div className="ms-Grid-row">
            <DynamicForm
              fields={leadFields}
              data={payload}
              onChange={handleOnChangeInput}
              errors={errors}
            />
          </div>
        </div>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Stack horizontal horizontalAlign="center">
            <Stack.Item>
              <PrimaryButton
                text="Save"
                onClick={handleOnSave}
                className="btn-primary"
              />
              <DefaultButton onClick={onCancel} text="Cancel" />
            </Stack.Item>
          </Stack>
        </Sticky>
      </div>

    </>
  );
};

interface OwnProps {
  params?: any;
  onCancel: () => void;
  onSave: (data: any) => void;
}

type Props = OwnProps;
