import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useState } from "react";
import { getSystems } from "../../../store/client/system";
import { ISystem } from "@solarforschools/sfs-core";
import CustomTagPicker from "./CustomTagPicker";
import { getInvoicesCyclesApi } from "../../../store/client/invoice";

interface CyclePickerProps {
    filters?: { [key: string]: any; }
    selectedItems?: ITag[]
    label?: string;
    name?: string;
    onChange: (key: string, items?: ITag[]) => void
}

const InvoiceCyclePicker: FunctionComponent<CyclePickerProps> = ({
    filters = {},
    selectedItems,
    label = "Cycle",
    name = "selectedCycle",
    onChange
}) => {
    const [selected, setSelected] = useState<ITag[]>(selectedItems || [])
    const handleGetSystems = async (
        filter: string,
        selectedItems?: ITag[],
    ): Promise<ITag[]> => {
        try {
            const cycles = await getInvoicesCyclesApi({ search: filter, ...filters })
            let data = cycles?.length ? cycles.map((s: any) => ({ key: s, name: s })) : []
            return data
        } catch (error) {
            return [] as ITag[]
        }
    }
    const handleOnChangeInput = async (key: string, value: any) => {
        (value.length) ? setSelected(value) : setSelected([])
        onChange(key, value)
    }

    return (
        <CustomTagPicker
            itemLimit={1}
            label={label}
            placeholder="Choose Cycle"
            selectionAriaLabel="Choose Cycle"
            selectedItems={selected}
            onResolveSuggestions={handleGetSystems}
            onChange={(items?: ITag[]) => handleOnChangeInput(name, items)} />
    );
}

export default InvoiceCyclePicker;
