import { useContext } from "react";
import { CardContext, CardContextType } from "../../layouts/index";
import { Line } from "react-chartjs-2";
import { getMonthNameFromWeekNumber } from "../../utils/tileHelper";

export const FrontContent = () => {
    const { teacherPortalActivity } = useContext(CardContext) as CardContextType;

    const labels = teacherPortalActivity?.created?.lastOneMonthWeekBased?.map(e => {
        const week = e?._id.week || 0
        const year = e?._id?.year || 0
        const month = getMonthNameFromWeekNumber(week)
        return `${week}th week, ${year} (${month})`
    } ) || []
    const values = teacherPortalActivity?.created?.lastOneMonthWeekBased?.map(e => e?.count) || []

    const data = {
        labels,
        datasets: [
            {
                label: "count",
                data: values,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                borderColor: "#909090",
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
            tooltip: {

            }

        },
        scales: {
            y: {

            },
            x: {
                ticks: {
                    display: false
                }
            }
        }
    };

    return (
        <>
            <div className="flex-column-center">
                <div style={{ height: "90px", width: "250px" }}>
                    <Line data={data as any} options={options} />
                    <span style={{ fontSize: "0.85rem", color: "#243f61" }}> User activity</span>
                </div>
            </div>
        </>
    )
}

export const BackContent = () => {

    const { teacherPortalActivity } = useContext(CardContext) as CardContextType;

    const labels = teacherPortalActivity?.duration?.lastOneMonthWeekBased?.map(e => {
        const week = e?._id.week || 0
        const year = e?._id?.year || 0
        const month = getMonthNameFromWeekNumber(week)
        return `${week}th week, ${year} (${month})`
    }) || []
    const values = teacherPortalActivity?.duration?.lastOneMonthWeekBased?.map(e => {
        if(e?.count) {
            const _temp = e?.count / 60 // convert to minutes
            return _temp?.toFixed(2)
        }
            
        else return 0
    }) || []

    const data = {
        labels,
        datasets: [
            {
                label: "Time",
                data: values,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                borderColor: "#909090",
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
            tooltip: {

            }

        },
        scales: {
            y: {

            },
            x: {
                ticks: {
                    display: false
                }
            }
        }
    };

    return (
        <>
            <div className="flex-column-center">
                <div style={{ height: "90px", width: "250px" }}>
                    <Line data={data} options={options} />
                    <span style={{ fontSize: "0.85rem", color: "#243f61" }}>  Overall time spent (minutes) </span>
                </div>
            </div>
        </>
    )
}