import { IChoiceGroupOption } from "@fluentui/react";
import { ILanguage, TLangType } from "./types";

export const Language: ILanguage[] = [
  { key: "EN", text: "en" },
  { key: "IN", text: "en-in" },
  { key: "GB", text: "en-gb" },
  { key: "DE", text: "de" },
  { key: "ES", text: "es" },
  { key: "NZ", text: "en-nz" },
  { key: "CS", text: "cs" },
  { key: "IE", text: "en-ie" },
];

export const languageOptions = [
  { key: "en", text: "English (en)" },
  { key: "en-in", text: " English - India (en-IN)" },
  { key: "en-gb", text: "English - UK (en-GB)" },
  { key: "de", text: "German (DE)" },
  { key: "es", text: "Spanish (ES)" },
  { key: "en-nz", text: "English - New Zealand (en-NZ)" },
  { key: "cs", text: "Czech (CS)" },
  { key: "en-ie", text: "English - Ireland (en-IE)" },
  { key: "en-co", text: "English - Colombia (en-CO)" }
];

export const languageMap: any = {
  en: "English (en)",
  "en-in": " English - India (en-IN)",
  "en-gb": "English - UK (en-GB)",
  de: "German (DE)",
  es: "Spanish (ES)",
  "en-nz": "English - New Zealand (en-NZ)",
  cs: "Czech (CS)",
  "en-ie": "English - Ireland (en-IE)",
  "en-co": "English - Colombia (en-CO)"
};

export type TLanguage =
  | "en"
  | "en-in"
  | "en-gb"
  | "de"
  | "es"
  | "en-nz"
  | "cs"
  | "en-ie";


export const removeThisKeys: TLangType[] = ["_id", "key", "en", "updatedAt"];

export const isLangValid = (lang: string) => {
  return Language.find((elm) => elm.text === lang);
};


// Helper function to convert array of strings to object (used to fetch translations)
export const buildFilterLang = (lang: string []) => {
  let fetchLang: any = {};

  lang.forEach((elm: string, index: number) => {
    fetchLang[`lang${index + 1}`] = elm;
  });

  return fetchLang
};
