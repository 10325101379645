import { Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility, Text } from '@fluentui/react';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Loader from "../../common/Loader";
import CertificateList from './CertificateList';
import CertificateFilters from './CertificateFilters';
import { ICertificateState } from '../../../../store/admin/funder/certificate/reducer';
import { geCertifcateBondoffers, getCertificates, setCertificateFilter } from '../../../../store/admin/funder/certificate/action';
import { PageSize } from '../../common/common';


const CertificateScreen = () => {
  const dispatch = useDispatch()
  const { bonds, totalCount , certificateFilter}: ICertificateState = useSelector<RootState, ICertificateState>((state: RootState) => state.web.certificates)

  useEffect(() => {
    dispatch(geCertifcateBondoffers())
    dispatch(getCertificates())
  }, []);

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...certificateFilter, page: 1, pageSize: size }
    dispatch(setCertificateFilter(filters))
    dispatch(getCertificates())
  };


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Certificates</h1>
        <CertificateFilters />
      </header>
      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }} className="margin-top-md">
        <div style={{ flex: "20%" }}>
        <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}>
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={certificateFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>

      </div>
      <CertificateList actions={[]} />
    </div>
  );
}
export default CertificateScreen;
