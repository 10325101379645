import Block from "./Block";

const Template = ({ isQuiz }: templateProps) => {
  const question = {
    id: "Question",
    name: "Question",
    url:
      "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/question.png",
  };
  const content = {
    id: "Content",
    name: "Content",
    url:
      "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/content.png",
  };
  let templates = [question, content];
  if (isQuiz) {
    templates = [question];
  }
  return (
    <div className="template">
      {templates.map((template) => {
        const { id, name, url } = template;
        return <Block id={id} name={name} url={url} />;
      })}
    </div>
  );
};

export interface templateProps {
  isQuiz: boolean;
}

export default Template;
