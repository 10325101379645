import { FontIcon, IColumn, Link, PrimaryButton, Text, TooltipHost, mergeStyles } from "@fluentui/react"
import { isSortable } from "../common/utils"
import { BsFileEarmarkPdfFill, BsFileEarmarkPptFill, BsFillFileEarmarkFill } from "react-icons/bs"
import { CSSProperties } from "react"
import axios from "axios"
import moment from "moment"
import { toast } from "react-toastify"
import { host } from "../../../store/config"
import { IFilterProjects } from "./types"
import { ITemplate } from "@solarforschools/sfs-core/dist/types"


export const designStages = [
    { key: "PUBLIC", value: "Public" },
    { key: "INITIAL", value: "Initial" },
    { key: "OFFER", value: "Offer" },
    { key: "DEVELOPMENT", value: "Development" },
    { key: "AS_BUILT", value: "As Built" },
]

export const defaultRegionsOption = { key: "SELECT", text: "Regions" }
export const defaultSchoolsOption = { key: "SELECT", text: "Schools" }
export const defaultProjectsOption = { key: "SELECT", text: "Projects" }
export const defaultDesignsOption = { key: "SELECT", text: "Designs" }
export const defaultTemplatesOption = { key: "SELECT", text: "Templates" }
export const defaultFilterParamsOption = { key: "SELECT", text: "Select a filter parameter" }

export const filterParams = [
    { key: "all", text: "All" },
    { key: "selected", text: "Selected" },
    { key: "show", text: "Show" },
]


export const reportTypes = [
    { key: "school", text: "School" },
    { key: "project", text: "Project" },
    { key: "calculator", text: "Calculator" },
]

export const errorsDetails = {
    isRegionError: false, regionErrorMsg: "",
    isSchoolError: false, schoolErrMsg: "",
    isTemplateError: false, templateErrMsg: "",
    isDesignError: false, designErrMsg: ""
}


export const compareFn = (a: any, b: any) => {
    if (a > b) return -1
    else if (a < b) return 1
    else return 0
}

const iconStyle: CSSProperties = { fontSize: "1.5rem", color: "#243f61", paddingLeft: "0.5rem", cursor: "pointer" }

export const dropdownLayoutStyles: CSSProperties = { display: "flex", marginTop: "0.5rem", maxWidth: "90vw", margin: "0 auto" }
export const regionDropDownStyle: CSSProperties = { minHeight: "5.5rem", width: "15vw" }
export const schoolDropDownStyle: CSSProperties = { minHeight: "5.5rem", width: "20vw", marginLeft: "1rem" }
export const templateDropDownStyle: CSSProperties = { minHeight: "5.5rem", width: "15vw", marginLeft: "1rem" }
export const filerDropDownStyle: CSSProperties = { minHeight: "5.5rem", width: "20vw", marginLeft: "1rem" }

const getFileIcons = (iconName: string): JSX.Element => {

    const fileIcons: Record<string, JSX.Element> = {
        pdf: <BsFileEarmarkPdfFill style={iconStyle} />,
        pptx: <BsFileEarmarkPptFill style={iconStyle} />,
        ppt: <BsFileEarmarkPptFill style={iconStyle} />,
    }

    if (fileIcons[iconName] === undefined) {
        return <BsFillFileEarmarkFill style={iconStyle} />
    }
    return fileIcons[iconName]
}



function getFileExtn(fileName: string) {
    const getDotIndex = fileName.lastIndexOf(".")
    const getExtnName = fileName.substring(getDotIndex + 1)
    return [getExtnName]
}

const openFile = (fileUrl: string) => {

    const newTab = window.open(fileUrl, '_blank');
    if (newTab) {
        newTab.focus();
    }
}
const downloadPdf = async (fileUrl: string, fileName: string) => {

    try {
        const url = `${host}/generate/report/download`
        const response = await axios.post(url, { fileName, url: fileUrl })
        if (response.status === 200) {
            toast.success("File downloaded successfully")
            const a = document.createElement("a");
            a.id = "download-generated-report"
            a.href = response.data
            a.download = fileName;
            document.body.appendChild(a)
            a.click()
            return
        } else {
            toast.error("Error in downloading file")
            console.log("Err ", response)
        }

    } catch (err) {
        toast.error("Error in downloading file")
        console.log("Err ", err)
    }
}

export const getFileColumns = (): IColumn[] => {
    const iconClass = mergeStyles({
        fontSize: 15,
        height: 15,
        width: 15,
        cursor: "pointer"
    });

    return [
        {
            key: "date",
            name: "Created Date",
            fieldName: "date",
            minWidth: 75,
            maxWidth: 100,
            ...isSortable,
            onRender: (item) => {
                const value = item["date"] || new Date()

                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {moment(value).format("DD MMM YYYY")}
                    </TooltipHost>
                );
            },
        },
        {
            key: "fileName",
            name: "File Name",
            fieldName: "fileName",
            minWidth: 700,
            maxWidth: 900,
            ...isSortable,
            onRender: (item) => {
                const value = item["fileName"] || "abc.pdf";
                const [fileExtn] = getFileExtn(value)

                return (
                    <TooltipHost content={value} closeDelay={500}>
                        <div style={{ display: "flex", gap: "0.5rem" }}>
                            <div className="flex-row-center" aria-label="Open File" title="Open File">
                                {
                                    getFileIcons(fileExtn)
                                }
                            </div>
                            <div>
                                {value}
                            </div>
                        </div>
                    </TooltipHost>
                );
            },
        },
        {
            key: "size",
            name: "File Size",
            fieldName: "size",
            minWidth: 75,
            maxWidth: 100,
            ...isSortable,
            onRender: (item) => {
                const value = item["size"]

                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "url",
            name: "Action",
            fieldName: "url",
            minWidth: 75,
            maxWidth: 100,
            ...isSortable,
            onRender: (item) => {
                const url = item["url"]
                const fileNameValue = item["fileName"]

                return (
                    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>

                        <div onClick={() => openFile(url)} >
                            <FontIcon aria-label="Compass" iconName="RedEye" className={iconClass} style={{ color: "#243f61" }} />
                        </div>
                        <div onClick={() => downloadPdf(url, fileNameValue)}>
                            <FontIcon aria-label="Compass" iconName="Download" className={iconClass} />
                        </div>
                    </div>
                );
            },
        },
    ];
};
export const buildRegionOption = (regions: string[]) => {

    let result = []
    for (const region of regions.sort(compareFn)) {
        const regionObj = { key: region, text: (region === "--") ? "OTHERS" : region }
        result.push(regionObj)
    }

    return result

}
export const buildProjectOption = (projects: IFilterProjects[]) => {

    let result = []
    for (const project of projects) {
        const projectObj = { key: project.projectSlug, text: project.projectName }
        result.push(projectObj)
    }
    return result
}

export const buildTemplateOption = (templates: ITemplate[]) => {
    let result = []
    let id = 1;
    for (const template of templates) {
        const templateObj = { key: `${template.name}-${id}`, text: `${template.displayName} - ${template.region}` }
        id++;
        result.push(templateObj)
    }
    return result
}

// Common component
export const GenReportButton = ({ onClickFn }: { onClickFn: () => void }) => {
    const btnStyle: CSSProperties = { maxWidth: "10rem" }

    return (
        <div className='flex-row-center'>
            <PrimaryButton
                text="Generate"
                onClick={onClickFn}
                allowDisabledFocus
                style={btnStyle}
                title='Click to generate report'
            />
        </div>
    )
}


export const ViewCalculatorTemplate = () => {
    const pathName = (process.env.REACT_APP_WEBSITE_HOST === "local" || process.env.REACT_APP_WEBSITE_HOST === "beta") ? "SfSWebsiteBeta" : "SfSWebsiteData"
    const url = `https://solarforschools.sharepoint.com/${pathName}/temp/school`

    return (
        <>
            <div className="flex-row-center" style={{ marginTop: "2rem" }}>
                <Text variant="medium">
                    <Link href={url} target="_blank">Click here </Link>  to view files
                </Text>
            </div>

        </>
    )
}