import { ActionButton, CheckboxVisibility, FontIcon, IColumn, ScrollablePane, ScrollbarVisibility, SelectionMode } from "@fluentui/react";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { downloadBondCertificate } from "../../../../store/admin/funder/bond/action";
import { getCertificates, setCertificateFilter } from "../../../../store/admin/funder/certificate/action";
import { ICertificateState } from "../../../../store/admin/funder/certificate/reducer";
import DetailsListWapper from "../../../common/DetailsListWapper";
import { DetailListStickyHeader } from "../../common/DetailListStickyHeader";
import { getCertificateColumns } from "./CertificateHelper";
import Pagination from "../../common/Pagination";
import { ICertificateFilter } from "./types";


const CertificateList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { bonds, totalCount, certificateFilter }: ICertificateState = useSelector<RootState, ICertificateState>((state: RootState) => state.web.certificates)

  const headerRow: IColumn[] = [...getCertificateColumns, {
    key: "action",
    name: "Action",
    fieldName: "-",
    minWidth: 100,
    onRender: (bond: IBond) => {
      return (
        <span>
          {bond.certificate?.id && (
            <ActionButton iconProps={{ iconName: 'DrillDown' }} onClick={() => dispatch(downloadBondCertificate(bond))} >
              Download Certificate
            </ActionButton>
          )}
        </span>
      );
    },
  },]
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...certificateFilter, sortField: field, sortOrder: order };
    dispatch(setCertificateFilter(params));
    dispatch(getCertificates(params))
  }
  const loadNextPage = (p: ICertificateFilter) => {
    const { page = 0 } = certificateFilter
    dispatch(setCertificateFilter({ ...certificateFilter, ...p }))
    dispatch(getCertificates())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={bonds}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={bonds.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field: string, order: string) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={certificateFilter?.page!}
            pageSize={certificateFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default CertificateList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

