import { Stack } from "@fluentui/react";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";
import { ITransaction } from "@solarforschools/sfs-core/dist/funder/transaction/types";
import { useEffect, useState } from "react";
import { getBondTransactionsApi } from "../../../../store/client/funder/transaction";
import TransactionList from "../transaction/TransactionList";
import { useDispatch } from "react-redux";
import { defaultBondTransactionFilter } from "../transaction/TransactionHelper";
import { getTransactionsBondoffers, getTransactionsFilter, getBondTransactions } from "../../../../store/admin/funder/transaction/action";
import { IBondFilter } from "./types";
import { IBondTransactionFilter } from "../transaction/types";


const BondTransactions = (props: Props) => {
  const dispatch = useDispatch();

  const loadData = async () => {
    const params: IBondTransactionFilter = { ...defaultBondTransactionFilter, page: 1, bondId: props.bond._id!.toString() };
    dispatch(getTransactionsBondoffers())
    dispatch(getTransactionsFilter())
    dispatch(getBondTransactions(params))
  }

  useEffect(() => {
    loadData();
  }, [])



  return (
    <Stack>
      <TransactionList actions={["edit", "delete"]} />
    </Stack>
  );
}

interface Props {
  bond: IBond;
  onUpdate?: (key: string, value: any) => void
}

export default BondTransactions;
