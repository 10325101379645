import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { generateQueryString } from "../../utils/util"
import { host } from "../config"

let GetSysAPiAbortController: AbortController

export const getSystems = (query?: any): Promise<any> => {
    // if (GetSysAPiAbortController && query) {
    //     GetSysAPiAbortController.abort(); // Tell the browser to abort request
    // }
    GetSysAPiAbortController = new AbortController();
    return axios.get(`${host}/system?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetSysAPiAbortController.signal }).then(res => res.data)
}
export const getSystemApi = (id: number): Promise<any> => {
    return axios.get(`${host}/system/getone/${id}`).then(res => res.data)
}

export const updateSchoolSystem = async (schoolSlug: string, systemsIds: (number | string)[]) => {
    return axios.put(`${host}/school/system/${schoolSlug}`, systemsIds).then(res => res.data)
};

export const deleteSchoolSystem = async (schoolSlug: string, systemsId: number | string) => {
    return axios.delete(`${host}/school/system/${schoolSlug}/${systemsId}`,).then(res => res.data)
};

export const fetchEnergyDetailsfromPVGIS = async (country: string, isEpm: boolean,) => {
    return axios.post(`${host}/yields/energyDetails`, { country, isEpm }).then(res => res.data)
}

export const getSystemCountriesApi = (query: ParsedUrlQueryInput): Promise<any> => {
    return axios.get(`${host}/system/country?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}
export const getSystemMetersApi = (query: ParsedUrlQueryInput): Promise<any> => {
    return axios.get(`${host}/system/schools/meters/?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}

export const initOrsisSystemApi = (query = {}) => {
    return axios.get(`${host}/system/init-orsis/?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}