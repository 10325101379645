import React, { useState } from "react";

const Textarea = ({
  onChange,
  placeholder,
  value,
  char = 0,
  maxChar = 0,
  width,
  height,
  margin,
  style = {},
  containerStyle = {},
  disabled,
}: textAreaProps) => {
  const [textAreaContainerStyle, setTextAreaContainerStyle] =
    useState<React.CSSProperties>({
      display: "flex",
      flexDirection: "column",
      border: "1px solid #cacaca",
      backgroundColor: "white",
      width,
      height,
      borderRadius: "0.4rem",
      overflow: "hidden",
      margin: margin ? margin : "",
      ...containerStyle,
    });
  const [textareaStyle, setTextareaStyle] = useState<React.CSSProperties>({
    outline: "none",
    resize: "none",
    border: "none",
    width,
    height: width,
    padding: "0.5rem",
    fontSize: "1rem",
    color: "#243f61",
    ...style,
  });
  const [isFocus, setIsFocus] = useState(false);
  const spanStyle: React.CSSProperties = {
    alignSelf: "flex-end",
    color: "#d3d3d3",
  };
  if (value && value?.length > maxChar) {
    value = value.substr(0, maxChar);
  }
  char = value ? value.length : 0;
  const setBorder = (isAdd: boolean) => {
    let border = "1px solid #cacaca";
    if (isAdd) {
      border = "1px solid #243f61";
    }
    setTextAreaContainerStyle({
      ...textAreaContainerStyle,
      border,
    });
  };
  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocus(true);
    setBorder(true);
  };
  const handleFocusOut = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocus(false);
    setBorder(false);
  };
  const handleMouseOver = (e: React.MouseEvent) => {
    setBorder(true);
  };
  const handleMouseOut = (e: React.MouseEvent) => {
    if (!isFocus) {
      setBorder(false);
    }
  };
  return (
    <div style={textAreaContainerStyle}>
      <textarea
        style={textareaStyle}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        onMouseEnter={handleMouseOver}
        onMouseOut={handleMouseOut}
        onFocus={handleFocus}
        onBlur={handleFocusOut}
        disabled={disabled}
      />
      <span style={spanStyle}>{`${char}/${maxChar}`}</span>
    </div>
  );
};

export interface textAreaProps {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value?: string;
  char?: number;
  maxChar?: number;
  width?: string;
  height?: string;
  margin?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
}

export default Textarea;
