import {
  IContentTemplate,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import { addContentTemplate } from "../../../../store/content/action";
import { toast } from "react-toastify";
import C1 from "./C1";
import C10 from "./C10";
import C11 from "./C11";
import C2 from "./C2";
import C3 from "./C3";
import C4 from "./C4";
import C5 from "./C5";
import C6 from "./C6";
import C7 from "./C7";
import C8 from "./C8";
import C9 from "./C9";

const ContentTemplate = ({ content, name, addContentTemplate }: Props) => {
  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    noOfItems: number
  ) => {
    if (content.contentTemplate) {
      if (content.contentTemplate.items?.length !== noOfItems) {
        return toast.error(`Enter all the values`);
      }
      addContentTemplate(content.contentTemplate);
    }
  };

  const renderTemplate = () => {
    switch (name) {
      case TemplateItems.C1:
        return <C1 handleSubmit={handleSubmit} />;
      case TemplateItems.C2:
        return <C2 handleSubmit={handleSubmit} />;
      case TemplateItems.C3:
        return <C3 handleSubmit={handleSubmit} />;
      case TemplateItems.C4:
        return <C4 handleSubmit={handleSubmit} />;
      case TemplateItems.C5:
        return <C5 handleSubmit={handleSubmit} />;
      case TemplateItems.C6:
        return <C6 handleSubmit={handleSubmit} />;
      case TemplateItems.C7:
        return <C7 handleSubmit={handleSubmit} />;
      case TemplateItems.C8:
        return <C8 handleSubmit={handleSubmit} />;
      case TemplateItems.C9:
        return <C9 handleSubmit={handleSubmit} />;
      case TemplateItems.C10:
        return <C10 handleSubmit={handleSubmit} />;
      case TemplateItems.C11:
        return <C11 handleSubmit={handleSubmit} />;
    }
  };
  return <>{renderTemplate()}</>;
};
interface DispatchProps {
  addContentTemplate: (contentTemplate: IContentTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  name: string | undefined;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    addContentTemplate: async (contentTemplate: IContentTemplate) => {
      await dispatch(addContentTemplate(contentTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(ContentTemplate);
