import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IUser } from "@solarforschools/sfs-core";
import { validateUserApi } from "../../../store/client/user";
import { IField } from "../../dynamic-form/DynamicForm";
import { Regions, requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { userRefferences } from "./common";

export const getGeneralFields = (options: StringMap): IField[] => {
    return [
        {
            label: "User name",
            key: "userName",
            type: 'string',
            extras: { required: true },
            placeholder: "User Name",
            className: 'ms-Grid-col ms-lg3'
        }, {
            label: "User Email",
            key: "userEmail",
            type: "string",
            extras: { required: true, ...options?.user?._id ? { disabled: true } : {} },
            placeholder: "User Email",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Phone",
            key: "userPhone",
            type: "string",
            placeholder: "User Phone",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Region",
            key: "country",
            type: "dropdown",
            extras: { required: true },
            options: Regions.filter(c => c.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg1'
        },
        {
            label: "User Role /Job Title",
            key: "userRole",
            type: 'dropdown',
            className: 'ms-Grid-col ms-lg2',
            options: options?.roles,
        },
        {
            key: 'admin',
            label: 'Admin',
            type: 'checkbox',
            className: 'margin-top-lg ms-Grid-col ms-lg1',
        },
        {
            key: 'active',
            label: 'Active',
            type: 'checkbox',
            className: 'margin-top-lg ms-Grid-col ms-lg1',
        },
        {
            key: 'funderAdmin',
            label: 'Funder Admin',
            type: 'checkbox',
            className: 'margin-top-lg ms-Grid-col ms-lg1',
        },
        {
            key: 'hasBhAccount',
            label: 'Bond Holder',
            type: 'checkbox',
            extras: { disabled: true },
            className: 'margin-top-lg ms-Grid-col ms-lg1',
        },
        {
            label: "Activation Token",
            key: "activationToken",
            type: "string",
            extras: { disabled: true },
            placeholder: "Activation Token",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Embedid",
            key: "embedId",
            type: "string",
            extras: { disabled: true },
            placeholder: "EmbedId",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Reset Password Token",
            key: "resetPasswordToken",
            type: "string",
            extras: { disabled: true },
            placeholder: "resetPasswordToken",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Where did you hear about Solar for Schools?",
            key: "userReference",
            type: 'dropdown',
            className: 'ms-Grid-col ms-lg2',
            options: userRefferences,
        },

    ];
}
export const validateUser = async (params: IUser) => {
    let errors: StringMap = {};
    const fields = ['userEmail', 'userName', 'country']
    const { permission, userEmail } = params || {}
    if (!permission || permission.length === 0) errors['skills'] = requiredMessage;
    for (const f of fields) {
        if (!params[`${f}` as keyof IUser]) errors[f as string] = requiredMessage
    }
    const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!mailformat.test(userEmail)) errors['userEmail' as string] = 'Invalid Email'

    if (!Object.keys(errors).length) {
        errors = await validateUserApi(params) as unknown as StringMap
    }
    return errors;
}

export const getUserColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'userName',
            minWidth: 150,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.userName} closeDelay={500}> {item.userName}</TooltipHost>
        },
        {
            key: 'userEmail',
            name: 'email',
            fieldName: 'userEmail',
            minWidth: 150,
            ...isSortable,
            onRender: (item: IUser) => <TooltipHost content={item.userEmail} closeDelay={500}> {item.userEmail}</TooltipHost>
        },
        {
            key: 'Phone',
            name: 'Phone',
            fieldName: 'phone',
            minWidth: 150,
            ...isSortable,
            onRender: (item: IUser) => <TooltipHost content={item.userPhone || ''} closeDelay={500}> {item.userPhone || ''}</TooltipHost>
        },
        {
            key: 'country',
            name: 'Country',
            fieldName: 'country',
            minWidth: 80,
            ...isSortable
        },
        {
            key: 'active',
            name: 'Active',
            fieldName: 'active',
            minWidth: 80,
            ...isSortable
        },
        {
            key: 'admin',
            name: 'Admin',
            fieldName: 'admin',
            minWidth: 80,
            ...isSortable
        },
        {
            key: 'userRole',
            name: 'Role',
            fieldName: 'userRole',
            minWidth: 120,
            ...isSortable
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (data) => {
                const { permission, active } = data
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            if (action.icon === "FileSymLink") {
                                if (active && permission.includes("teacher"))
                                    return <FontIcon
                                        iconName={action.icon}
                                        onClick={() => action.onClick(data)} />;
                                else return <></>
                            }

                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(data)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export interface UserTabErrors {
    general: boolean
    schools: boolean
}

export const getUserTabErrors = (errors: StringMap): UserTabErrors => {
    const taberrors = {
        general: false,
        schools: false,
        analysis: false,
    };
    // generalFields.forEach(field => {
    //     if (errors[field.key]) {
    //         taberrors.general = true;
    //     }
    // })
    // if (!errors.schools) {
    //   taberrors.schools = true;
    // }
    return taberrors;
}
export const initUser: IUser = {
    userName: '',
    userEmail: '',
    country: 'GB',
    active: false,
    embedId: '',
    activationToken: '',
    login: {
        failedAttempts: undefined,
        lastAttempt: undefined,
        lastResetPasswordAttempt: undefined,
        lastSuccess: undefined
    },
    permission: ['member'],
    schools: [],
    skills: [],
    userPhone: null,
    userRole: null,
    userReference: null,
    password: "",
    admin: false,
    funderAdmin: false,
}
