import {
    DefaultButton,
    PrimaryButton,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
} from "@fluentui/react";
import { TextField } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import { IComments } from "@solarforschools/sfs-core/dist/comment/types";
import { renderNormalTextDiv, uploadImage } from "./Helpers";
import { IoIosCloseCircle } from "react-icons/io";
import { IoImagesOutline } from "react-icons/io5";


export const openEditComment = (props: IComments) => {
    return new Promise<any | undefined>((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget);
        const callback = (editedComment?: IComments) => {
            resolve(editedComment);
            ReactDOM.unmountComponentAtNode(mountTarget);
            mountTarget.remove();
        };

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Edit Comment"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-lg"
                >
                    <ViewCommentDialog
                        comment={props}
                        onCancel={() => callback(undefined)}
                        onSave={(comment: IComments) => callback(comment)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        );
    });
};

interface IProps {
    comment: IComments;
    onCancel: () => void;
    onSave: (comment: IComments) => void;
}

const ViewCommentDialog = (props: IProps) => {
    const { comment } = props;

    const [text, setText] = useState("");
    const [tempPics, setTempPics] = useState<any>(comment?.pics || []);
    const [defaultPicsLength] = useState(comment?.pics?.length)
    const [selectedFiles, setSelectedFiles] = useState<any>([])


    useEffect(() => {
        if (comment) {
            if (comment.type === "roof" && comment.pics === undefined) {
                setTempPics([])
            }
        }
    }, [comment])


    const handleS3Upload = async (files: any) => {
        if (!files || files.length === 0) return
        const images: string[] = await uploadImage(files, comment.slug)
        return images
    }

    const handleOnSave = async () => {
        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: " Save Comment",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to save this comment ?`,
            },
            confirmBtnText: "Save",
        });
        if (isConfirmed) {

            let uploadedImages = await handleS3Upload(selectedFiles);

            if (text) {
                comment["commentText"] = text;
            }

            if (tempPics) {
                if (tempPics.length > 0) {

                    let oldPics = [...tempPics]
                    let picsFileName = [];

                    let filteredOldPics = oldPics.filter((e: any) => {
                        if (!e.includes("blob:")) return true
                    })

                    if (filteredOldPics) {
                        if (filteredOldPics.length > 0) {
                            for (let i = 0; i < filteredOldPics.length; i++) {
                                let splittedName = filteredOldPics[i].split("/")
                                let fileName = splittedName[splittedName.length - 1];
                                picsFileName.push(fileName)
                            }
                            comment["pics"] = picsFileName;
                        } else {
                            comment["pics"] = []
                        }

                        if (uploadedImages) {
                            if (uploadImage.length > 0 && comment["pics"]) {
                                comment["pics"] = [...comment['pics'], ...uploadedImages]
                            }
                        }

                    } else {

                        if (uploadedImages) {
                            if (uploadImage.length > 0) {
                                comment["pics"] = uploadedImages
                            }
                        }

                    }
                } else {
                    comment["pics"] = []
                }

            }
            //Dispatch update call or send update comment in props and call dispatch in calling functions by fulfilling promise
            props.onSave(comment);
        }
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setText((e.target as HTMLInputElement).value);
    };

    if (!comment) return <></>;
    if (comment.type === undefined) return <></>

    const handleDelete = (index: number) => {
        if (tempPics) {
            let res = [...tempPics];
            res.splice(index, 1);
            setTempPics(res);
            if (defaultPicsLength !== undefined) {
                if ((index + 1) > defaultPicsLength) {
                    let newIndex = index - defaultPicsLength;
                    let result = [...selectedFiles];
                    result.splice(newIndex, 1);
                    setSelectedFiles(result);
                }
            } else {
                let result = [...selectedFiles];
                result.splice(index, 1);
                setSelectedFiles(result);

            }
           
        }
    };

    const handleImageChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let newUrl = URL.createObjectURL(e.target.files[0]);
            setTempPics([...tempPics, newUrl]);
            setSelectedFiles([...selectedFiles, e.target.files[0]])
        }

    };

    return (
        <div className="editComment">
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <div
                    style={{
                        maxWidth: "40vw",
                        margin: "0 auto",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {renderNormalTextDiv("type", comment.type)}

                        {
                            (comment?.type === "school" || comment?.type === "ANALYSIS") ? <></> : (

                                <>
                                    <Text variant="mediumPlus">
                                        <span style={{ fontWeight: 600 }}>Pics</span>
                                    </Text>

                                    <label
                                        className="custom-file-upload flex-row-center"
                                        style={{ gap: "0.25rem" }}
                                    >
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            onChange={handleImageChange}
                                            style={{ color: "transparent" }}
                                        />
                                        <IoImagesOutline /> Choose a Pic
                                    </label>
                                </>
                            )
                        }

                        {
                            (comment?.type === "school" || comment?.type === "ANALYSIS") ? <></> : (
                                <>
                                    {tempPics?.length > 0 ? (
                                        <>
                                            <div style={{ marginBottom: "0.75rem" }}>
                                                <div style={{ marginBottom: "0.75rem" }}>
                                                    <div>
                                                        <div
                                                            className="flex-row-center"
                                                            style={{
                                                                overflow: "scroll",
                                                                maxWidth: "38rem",
                                                                height: "15rem",
                                                                flexWrap: "wrap",
                                                                gap: "1rem",
                                                                padding: "0.5rem",
                                                            }}
                                                        >
                                                            {tempPics?.map((value: string, i: number) => {
                                                                return (
                                                                    <>
                                                                        <div id="editComment_container">
                                                                            <div
                                                                                id="closeBtn"
                                                                                onClick={() => handleDelete(i)}
                                                                            >
                                                                                <IoIosCloseCircle size="1.25rem" />
                                                                            </div>
                                                                            <img
                                                                                src={value}
                                                                                style={{
                                                                                    width: "10rem",
                                                                                    height: "10rem",
                                                                                    marginRight: "1rem",
                                                                                }}
                                                                                alt={`pics${i}`}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <Text variant="medium" className="flex-row-center">
                                            No Image Found, Please Upload
                                        </Text>
                                    )}
                                </>
                            )
                        }

                        <div>
                            <TextField
                                multiline
                                label="Comment Text"
                                placeholder="Enter Comment Text"
                                type="text"
                                rows={6}
                                //  className="ms-Grid-col ms-lg3"
                                defaultValue={comment?.commentText}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <Stack horizontal horizontalAlign="center" className="margin-top-xs">
                        <Stack.Item>
                            <PrimaryButton
                                text="Save"
                                onClick={handleOnSave}
                                className="btn-primary"
                            />
                            <DefaultButton onClick={props.onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </div>
            </Sticky>
        </div>
    );
};

export default ViewCommentDialog;
