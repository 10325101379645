import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProjectWrokflows } from "../../../store/admin/workflow/action";
import Loader from "../common/Loader";
import WorkflowFilters from "./Workflowfilters";
import WorkflowList from "./WorkflowList";


const WorkflowScreen = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProjectWrokflows())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Project Workflows</h1>
        <WorkflowFilters/>
      </header>
      <WorkflowList />
    </div>
  )
}
export default WorkflowScreen;

