import { toast } from "react-toastify";
import axios, { AxiosRequestConfig } from "axios";
import { isLoading } from "../action";
import { IRegionConfig } from "@solarforschools/sfs-core";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../..";
import { host } from "../../config";
import { ILanguageConfiguration } from "@solarforschools/sfs-core";

export const setRegionConfigsList = (regionConfigList: IRegionConfig[]) => {
  return { type: "SET_REGION_CONFIG", regionConfigList };
};

export const updateRegionConfigList = (regionConfigData: IRegionConfig) => {
  return { type: "UPDATE_REGION_CONFIG", regionConfigList: regionConfigData };
};

export const setCostResults = (payload: Record<string, number>) => {
  return {
    type: "SET_COST_RESULTS",
    payload,
  };
};

export const setNewRegionConfig = (regionConfig: IRegionConfig) => {
  return {
    type: "SET_NEW_REGION_CONFIG",
    payload: regionConfig,
  };
};

// Thunk Middleware

export const updateRegionConfig = (
  regionConfig: IRegionConfig
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => {
    try {
      dispatch(isLoading(true));
      const res: AxiosResponse<any> | undefined =
        regionConfig._id &&
        (await axios.put(`${host}/configs/region`, regionConfig));
      res?.data.msg &&
        toast.success("Region Configuration Updated Successfully");
      if (res?.data.hasOwnProperty("error")) {
        toast.error("Something went wrong")
        dispatch(isLoading(false));
        return;
      }
      dispatch(updateRegionConfigList(res?.data.msg));
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error is ", err);
      toast.error("Something went wrong")
      dispatch(isLoading(false));
    }
  };
};

export const fetchRegionConfig = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchConfigThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${host}/configs/region/`);
      if (res.status === 200) {
        dispatch(setRegionConfigsList(res.data));
        dispatch(isLoading(false));
      }
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error ", err);
      dispatch(isLoading(false));
    }
  };
};

export const fetchRegionCostsResults = () => {
  return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    try {
      const res = await axios.get(`${host}/configs/region/cost/results`);
      if (res.status === 200) {
        dispatch(setCostResults(res.data));
        return;
      }
      console.log("Error: " + res);
    } catch (err) {
      console.log("Error ", err);
    }
  };
};

export const createRegionConfig = (
  regionConfig: IRegionConfig,
  langList: ILanguageConfiguration
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => {
    try {
      dispatch(isLoading(true));
      const payload = { ...regionConfig, languageList: langList}
      const res = await axios.post(`${host}/configs/region/new`, payload);
      dispatch(isLoading(false));

      if (res.status === 200) {
        toast.success("New region added");
       
        dispatch(setNewRegionConfig(res?.data.data));
      } else {
        toast.error("Something went wrong ");
        console.log(res.data)
      }
    } catch (err) {
      console.log("Error is ", err);
      toast.error("Something went wrong ");
      dispatch(isLoading(false));
    }
  };
};

interface AxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
}
