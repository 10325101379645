import React from "react";
import {
  IDropdownOption,
  TextField,
  Dropdown,
  Checkbox,
  DatePicker,
  IComboBoxOption,
  ComboBox,
  Toggle,
  ChoiceGroup,
  ITextFieldProps,
  Text,
  memoizeFunction,
  ITheme,
  getTheme
} from "@fluentui/react";
import _ from "lodash";
import { dropdownStyles } from "../admin/common/common";

function DynamicForm<T>(props: DynamicFormProps<T>) {

  // const getDescriptionStyles = memoizeFunction((theme: ITheme) => ({
  //   root: { color: theme.palette.green },
  // }));
  // const onRenderDescription = (props: ITextFieldProps): JSX.Element => {
  //   console.log(props.description)
  //   const theme = getTheme();
  //   return (
  //     <Text variant="small" styles={getDescriptionStyles(theme)}>
  //       {props.description}
  //     </Text>
  //   );
  // };




  return (
    <>
      {props.fields.map((field, i) => {

        const value = _.get(props.data, field.key, "");

        const errorMessage = _.get(props.errors, field.key, undefined);

        const className =
          (field.className || "") +
          (Object.keys(props.errors as any).length ? " error" : "");

        let commonProps = {
          label: field.label,
          key: field.key,
          className,
          disabled: field.disabled,
          readOnly: field.readOnly,
          ...field.extras,
        };
        if (['combobox', 'dropdown'].includes(field.type)) {
          if (commonProps?.multiSelect) {
            commonProps.selectedKeys = field.selectedKeys || value
          } else {
            commonProps.selectedKey = field.selectedKey || value
          }
        }
        switch (field.type) {
          case "number":
            return (
              <TextField
                {...commonProps}
                type="number"
                placeholder={field.placeholder}
                onChange={(e, value) =>
                  props.onChange(field.key, value)
                }
                onBlur={() => {
                  if (value) {
                    props.onChange(field.key, +value)
                  }
                }}
                value={value}
                errorMessage={errorMessage}
                step={field.step}
                ariaLabel={field?.ariaLabel || ''}
                description={field.description || ''}
                onWheel={field.handleWheel || ''}
              />
            );
          case "multiLineText":
            return (
              <TextField
                {...commonProps}
                multiline
                rows={field.rows || 0}
                placeholder={field.placeholder}
                onChange={(e, value) =>
                  props.onChange(field.key, value)
                }
                value={value}
                errorMessage={errorMessage}
                ariaLabel={field?.ariaLabel || ''}
              />
            )
          case "dropdown":
            return (
              <Dropdown
                {...commonProps}
                options={field.options!}
                onChange={(e, item) => {

                  props.onChange(field.key, commonProps.multiSelect ? item : item?.key)
                }
                }


                errorMessage={errorMessage}
                styles={dropdownStyles}
                ariaLabel={field?.ariaLabel || ''}
              />
            );
          case "combobox":
            return (
              <ComboBox
                {...commonProps}
                options={field.options!}
                onChange={(e, item) => props.onChange(field.key, commonProps.multiSelect ? item : item?.key)}
                styles={dropdownStyles}
                errorMessage={errorMessage}
                ariaLabel={field?.ariaLabel || ''}
              />
            );
          case "checkbox":
            return (
              <Checkbox
                checked={value}
                {...commonProps}
                onChange={(e, value) => props.onChange(field.key, value)}
                ariaLabel={field?.ariaLabel || ''}
              />
            );
          case "switch":
            return (
              <Toggle
                {...commonProps}
                checked={value}
                onChange={(e, value) => props.onChange(field.key, value)}
                ariaLabel={field?.ariaLabel || ''}
              />
            );
          case "date":
            return (
              <DatePicker
                {...commonProps}
                value={value ? new Date(value!) : null}
                onSelectDate={(date) => props.onChange(field.key, date)}
                styles={dropdownStyles}
                ariaLabel={field?.ariaLabel || ''}

              />
            );
          case "ChoiceGroup":
            return (
              <ChoiceGroup
                {...commonProps}
                selectedKey={value}
                onChange={(e: any, value: string | number | boolean | IDropdownOption<any> | IComboBoxOption | Date | null | undefined) => props.onChange(field.key, value)}
              />
            );
          default:
            return (

              <div>
                <TextField
                  {...commonProps}
                  placeholder={field.placeholder}
                  onChange={(e, value) => {
                    props.onChange(field.key, value);
                  }}
                  value={value}
                  errorMessage={errorMessage}
                  ariaLabel={field?.ariaLabel || ''}
                // onRenderDescription={onRenderDescription}
                />


              </div>
            );
        }
      })}
    </>
  );
}

export type FieldType =
  | "ChoiceGroup"
  | "multiLineText"
  | "string"
  | "number"
  | "dropdown"
  | "checkbox"
  | "combobox"
  | "date"
  | "switch";

export interface IField {
  label: string;
  type: FieldType;
  placeholder?: string;
  key: string;
  disabled?: boolean;
  options?: IDropdownOption[] | IComboBoxOption[];
  step?: string;
  rows?: number;
  className?: string;
  selectedKey?: string;
  selectedKeys?: string[];
  extras?: any;
  readOnly?: boolean;
  ariaLabel?: string;
  description?: string;
  handleWheel?: () => void
}

interface DynamicFormProps<T> {
  fields: IField[];
  data: T;
  errors: { [key: string]: string | undefined };
  onChange: (
    key: string,
    value: string | number | boolean | Date | null | undefined | IDropdownOption | IComboBoxOption
  ) => void;
  onBlur?: (
    key: string,
    value: string | number | boolean | Date | null | undefined | IDropdownOption | IComboBoxOption
  ) => void;

}

export default DynamicForm;
