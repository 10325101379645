import { Action } from "./actionTypes";
import { IWorkflow } from "@solarforschools/sfs-core/dist/workflow/types";
import { IWorkflowFilter } from "../../../components/admin/workflow/types";

// States' definition
export interface IWorkflowState {
  isLoading: boolean;
  workflows: IWorkflow[];
  workflowFilter: IWorkflowFilter;
  totalCount: number;
}
export interface State {
  projectWorkflow: IWorkflowState;
}

const projectWorkflow = (
  state: IWorkflowState = {
    isLoading: false,
    workflows: [],
    workflowFilter: {
      page: 1,
      pageSize: 50,
      sortField: "Start",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): IWorkflowState => {
  switch (action.type) {
    case "PROJECT/SET_WORKFLOW_FILTER":
      return { ...state, workflowFilter: action.workflowFilter };
    case "PROJECT/SET_WORKFLOW_LIST":
      const workflows = action.reset
        ? action.workflows
        : [...state.workflows, ...action.workflows];
      return { ...state, workflows, totalCount: action.totalCount };
    case "PROJECT/DELETE_WORKFLOW": {
      const stateObj = { ...state };
      const workflows: IWorkflow[] = stateObj.workflows.filter(
        (s) => s._id?.toString() !== action.wrokflowId.toString()
      );
      const workflowFilter = { ...stateObj.workflowFilter };
      const { page, pageSize } = workflowFilter;
      return {
        ...state,
        workflows,
        totalCount: stateObj.totalCount - 1,
        workflowFilter: {
          ...workflowFilter,
          page: Math.ceil((workflows.length / page!) * pageSize!),
        },
      };
    }
    case "PROJECT/UPDATE_WORKFLOW": {
      const workflows = [...state.workflows];
      const index = workflows.findIndex(
        (s) => s._id?.toString() === action.workflow?._id?.toString()
      );
      if (index > -1) {
        workflows[index] = action.workflow;
      } else {
        workflows.unshift(action.workflow);
      }
      return {
        ...state,
        workflows,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default projectWorkflow;
