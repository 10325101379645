import { Dropdown, PrimaryButton } from '@fluentui/react'
import { debounce } from 'lodash'
import { useCallback, useState } from 'react'
import { dropdownStyles } from '../common/common'
import { toast } from 'react-toastify'
import { dropDown, syncTranslation, uploadTranslation } from './helper'

const OptionsFilter = () => {
    const [optionName, setOptionName] = useState("")
    const [isDisabled, setIsDisabled] = useState(false)
    const handleChange = async (key: string, value: any) => {
        setOptionName(value);
    };

    const handleClick = () => {
        if (optionName === "Select Options" || optionName === "") {
            toast.error("Error, Select an Valid Option to Sync");
            return;
        }
        else {
            toast.success(`${optionName} is Started, Don't refresh the page`);
            setIsDisabled(true);
            if (optionName === "Sync Translation") {
                syncTranslation(optionName, setIsDisabled)
            } else if (optionName === "Upload Translation") {
                uploadTranslation(optionName, setIsDisabled)
            }

            //   window.history.replaceState(null, "", `/settings/scripts/${optionName}`);
            //  executeScript(optionName);
        }
    };

    const debounceClick = useCallback(debounce(handleClick, 1000), [optionName])
    return (
        <div className="center-dropDown" style={{ maxWidth: "100vw" }}>
            <Dropdown
                dropdownWidth="auto"
                label="Select an Option to Sync"
                onChange={(e, el) => {
                    handleChange("scripts", el?.key?.toString());
                }}
                options={dropDown}
                styles={dropdownStyles}
                defaultSelectedKey={dropDown[0].key}
                className="inlineflex ms-Grid-col ms-lg3"
            />

            <div className="scripts-save" >
                {
                    !isDisabled ? <PrimaryButton
                        text="Sync"
                        onClick={debounceClick}
                        className="btn-primary"
                    // disabled={isDisabled}
                    /> : <p style={{ margin: "1.4rem" }}>Running...</p>
                }

            </div>

        </div >
    )
}

export default OptionsFilter