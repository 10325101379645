
import { IQuartelyleadOrDealBySources } from '@solarforschools/sfs-core/dist/adminDashboard/types'
import { useEffect, useState } from 'react'
import Card from '../layouts'
import CardHeader from '../layouts/CardHeader'
import CardContent from '../layouts/CardContent'
import { IStackedColumnWithLineChartData } from '../layouts/contentVariants/StackedColumnWithLineChart'

interface IDataset {
    label: string;
    data: number[];
    backgroundColor: string;
}

const Deals = ({ DealFromSorces }: { DealFromSorces: IQuartelyleadOrDealBySources[] }) => {

    const [xAxis, setXaxis] = useState<string[]>([])
    const [chartData, setChartData] = useState<IDataset[]>([{ label: "", data: [], backgroundColor: "" }])

    const bgColors = ['rgb(255, 99, 132)', 'rgb(75, 192, 192)', 'rgb(53, 162, 235)',  "#BE61CA", "#EC6B56","#F13C59",   "#FFCE56","#cc65fe", "#243f61"]


    useEffect(() => {
        const fourQuaterData = DealFromSorces?.slice(0, 4)?.reverse() // first 4 quaters
        setXaxis(fourQuaterData?.map((elm) => `Q${elm.quarter}-${elm.year}`))

        //! Get sources 
        let sourcesObj: any = {} // refrence
        fourQuaterData?.forEach(elm => {
            const { sources } = elm
            sources?.forEach(el => {
                if (sourcesObj[el?.source] === undefined) sourcesObj[el?.source] = 1
            })
        })
        const sources = Object.keys(sourcesObj) // have sources
        //! Get sources 

         let _noOfSchools: number[] = []
         // aggregate no of schools
        fourQuaterData?.forEach((elm)=>{
            const sources = elm?.sources || []
            const noOfSchools = sources.reduce((acc, curr: any) => acc + curr.noOfSchools, 0) || 0
            _noOfSchools.push(noOfSchools)
        })
        
        //! Chart data
        const data: IStackedColumnWithLineChartData[] = sources.map((elm, index) => {

            // find source counts
            const dataSets = fourQuaterData.map((el) => el.sources.find(e => e.source === elm)?.count || 0)

            return {
                label: elm,
                data: dataSets,
                backgroundColor: bgColors[index]
            }
        })
        data.unshift({
            type: 'line',
            label: 'No of schools',
            data: _noOfSchools,
            backgroundColor: "#243f61",
            borderWidth: 1,
            borderColor: "#909090",
            hoverBackgroundColor: "#243f61"
    })
        setChartData(data)

    }, [DealFromSorces])


    if (DealFromSorces === undefined || DealFromSorces?.length === 0) return <></>


    return (
        <div className="dashboard-layout--pipedrive-web-deals">
            <Card>
                <CardHeader title='Deals' iconName='PipeDrive' />
                <CardContent variant='StackedBarLineChart' stackedBarLineChartData={chartData} labels={xAxis} />
            </Card>
        </div>
    )
}

export default Deals
