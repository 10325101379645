import {
  ITag,
  TagPicker,
  Text
} from "@fluentui/react";
import {
  IEstablishment
} from "@solarforschools/sfs-core/dist/school/types";
import _ from "lodash";
import "office-ui-fabric-react/dist/css/fabric.css";
import { getSchoolsMetaApi } from "../../../store/client/school";



const MetaSelection = ({ school, onUpdate, type, label, className, placeholder }: IMetaProps) => {

  const handleGetMetaOfSchools = async (
    filter: string,
    selectedItems?: ITag[],
  ): Promise<ITag[]> => {
    try {
      const schools = await getSchoolsMetaApi(type, filter)
      let data = schools.length ? schools.map((d: any) => ({ key: d.data, name: d.data, })) : [{ key: filter, name: filter }]
      return data
    } catch (error) {
      return [] as ITag[]
    }
  }

  const handleAddMetaToSchool = async (items?: ITag[]) => {
    onUpdate(`meta.${type}`, items?.length && items[0].name ? items[0].name : "")
  }

  const value = _.get(school, ["meta", type]) || ""
  return (
    <div className={`${className} margin-top-md margin-bottom-md`}>
      <Text className='' style={{ fontSize: "14px", fontWeight: 600, color: "black" }}>{label}</Text>
      <TagPicker
        styles={{ root: { marginTop: 5 } }}
        inputProps={{ placeholder }}
        onResolveSuggestions={handleGetMetaOfSchools}
        selectedItems={value.length > 0 ? [{ name: value, key: value }] : []}
        itemLimit={1}
        selectionAriaLabel={`Selected ${label}`}
        onChange={handleAddMetaToSchool}
      />
    </div>
  );
};

interface IMetaProps {
  onUpdate: (key: string, value: any) => void;
  school: IEstablishment;
  type: string;
  label: string;
  placeholder: string;
  className: string
}
export default MetaSelection;
