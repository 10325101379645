import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IActLogInitial } from '../../../store/admin/activityLogger/reducers';
import { fetchActivities, setFilter } from '../../../store/admin/activityLogger/actions';
import { ComboBox, DatePicker, DefaultButton, Dropdown } from '@fluentui/react';
import { comboBoxStyles, dropdownStyles } from '../common/common';
import moment from 'moment';
import { useEffect } from 'react';

const Filter = () => {

    const dispatch = useDispatch()
    const { filter: { type0, type1, type2, user, date }, options: { type0: Type0, type1: Type1, type2: Type2, users: Users } } = useSelector<RootState, IActLogInitial>((state) => state.web.activityLogger);



    const handleChange = (key: string, value: string | undefined) => {
        if (key !== "date")
            if (["choose module", "choose topic", "choose activity", "choose user"].includes(value?.toLowerCase() as string)) return

        let selectedTypes

        switch (key) {
            case "type0":
                selectedTypes = { type0: value as string, type1, type2, user, date }; break;
            case "type1":
                selectedTypes = { type0, type1: value as string, type2, user, date }; break;
            case "type2":
                selectedTypes = { type0, type1, type2: value as string, user, date }; break;
            case "user":
                selectedTypes = { type0, type1, type2, user: value as string, date }
                break;
            case "date":
                const selectedDate = moment(value).format("YYYY-MM-DD");
                selectedTypes = { type0, type1, type2, user, date: selectedDate }; break;
            default:
                return
        }
        dispatch(setFilter(selectedTypes))
        dispatch(fetchActivities(selectedTypes))
    }


    const handleClearValue = () => {
        if (type0 || type1 || type2 || user || date) {
            dispatch(setFilter({
                type0: "",
                type1: "",
                type2: "",
                user: "",
                date: ""
            }))
            dispatch(fetchActivities({}))
        }
    }


    return (
        <>
            <div style={{ display: "flex", margin: "2rem" }}>
                <Dropdown
                    dropdownWidth="auto"
                    onChange={(e, el) => {
                        handleChange("type0", el?.key?.toString());
                    }}
                    options={Type0}
                    styles={dropdownStyles}
                    selectedKey={type0 || Type0[0]?.key}
                    className="inlineflex ms-Grid-col ms-lg"
                />
                <Dropdown
                    dropdownWidth="auto"
                    onChange={(e, el) => {
                        handleChange("type1", el?.key?.toString());
                    }}
                    options={Type1}
                    styles={dropdownStyles}
                    selectedKey={type1 || Type1[0]?.key}
                    className="inlineflex ms-Grid-col ms-lg"
                />

                <ComboBox
                    selectedKey={type2 || null}
                    placeholder="Select activity"
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={Type2}
                    onChange={(e, el) => {
                        handleChange("type2", el?.key?.toString());
                    }}
                    styles={comboBoxStyles}
                    className="inlineflex ms-Grid-col ms-lg3"
                />


                <ComboBox
                    selectedKey={user || null}
                    placeholder="Select user"
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={Users}
                    onChange={(e, el) => {
                        handleChange("user", el?.key?.toString());
                    }}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg2"
                />

                <DatePicker
                    placeholder="Select a date"
                    ariaLabel="Select a date"
                    value={date ? new Date(date) : undefined}
                    onSelectDate={(date: any) => handleChange('date', date)}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg2"
                />

                <DefaultButton text='Clear' onClick={handleClearValue} />

            </div>
        </>
    )
}

export default Filter