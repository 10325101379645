import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useState } from "react";
import { getSystems } from "../../../store/client/system";
import { ISystem } from "@solarforschools/sfs-core";
import CustomTagPicker from "./CustomTagPicker";

interface SystemPickerProps {
    filters?: { [key: string]: any; }
    selectedItems?: ITag[]
    onChange: (key: string, items?: ITag[]) => void
}

const SystemPicker: FunctionComponent<SystemPickerProps> = ({
    filters = {},
    selectedItems,
    onChange
}) => {
    const [selectedSystems, setSelectedSystems] = useState<ITag[]>(selectedItems || [])
    const handleGetSystems = async (
        filter: string,
        selectedItems?: ITag[],
    ): Promise<ITag[]> => {
        try {
            const { systems } = await getSystems({ name: filter, system: 'all', ...filters })
            let data = systems?.length ? systems.map((s: ISystem) => ({ key: s.id, name: `${s.name}(${s.id} ${s.provider})` })) : []
            return data
        } catch (error) {
            return [] as ITag[]
        }
    }
    const handleOnChangeInput = async (key: string, value: any) => {
        (value.length) ? setSelectedSystems(value) : setSelectedSystems([])
        onChange(key, value)
    }

    return (
        <CustomTagPicker
            itemLimit={1}
            label="System"
            placeholder="Choose System"
            selectionAriaLabel="Choose System"
            selectedItems={selectedSystems}
            onResolveSuggestions={handleGetSystems}
            onChange={(items?: ITag[]) => handleOnChangeInput('selectedSystems', items)} />
    );
}

export default SystemPicker;
