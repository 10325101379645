import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import ModalDialog from "../common/ModalDialog";
import { useEffect, useState } from "react";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { ActionButton, ComboBox, DefaultButton, IComboBoxOption, PrimaryButton, Stack, Text, TextField } from "@fluentui/react";
import { cloneDeep } from "lodash";
import { IRegionCapex } from "@solarforschools/sfs-core/dist/settings/types";
import { IRegionConfigState } from "../../../store/admin/settings/reducer";

const defaultOption = { key: 'Select', text: "Select An Option" }
const costResName = "selectedCostResult"
const defaultCapex = [
    { headName: "Partner Fee", headValue: "", items: [] },
    { headName: "Education Setup", headValue: "", items: [] },
    { headName: "SfS Development Fee", selectedCostResult: "", headValue: "", items: [] }, //-> Only sfsDevFee, fundFee have selectedCostResult
    { headName: "Fundraising Fee", selectedCostResult: "", headValue: "", items: [] }, //-> Only sfsDevFee, fundFee have selectedCostResult
    { headName: "Contribution / Grant", headValue: "", items: [] }
]
const ignoreHeadNames = ["Partner Fee", "Education Setup", "SfS Development Fee", "Fundraising Fee", "Contribution / Grant"]

export const openAddCapexModal = (regionCapex: IRegionCapex[] | undefined) => {

    return new Promise<IRegionCapex[] | undefined | undefined>((resolve, reject) => {

        const mountTarget = document.createElement("div");

        document.body.appendChild(mountTarget)
        let isClearedAll = false; // To check if the all elements are cleared

        const callback = (result?: IRegionCapex[] | undefined) => {
            resolve(result);
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }

        const handleDismiss = () => {
            if (isClearedAll) {
                callback([]) // clear all
                return
            }
            callback(undefined)
        }

        const clearAll = (res: Boolean) => {
            if (res) isClearedAll = true;
            else isClearedAll = false;
        }

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add Capex"}
                    onDismiss={() => handleDismiss()}
                >
                    <AddCapex
                        capex={regionCapex}
                        onCancel={() => callback(undefined)}
                        onSave={(capexData: IFilteredCapex[]) => callback(capexData)}
                        clearAll={(res: Boolean) => clearAll(res)}
                    />
                </ModalDialog>

            </Provider >,
            mountTarget
        )
    })

}

interface IProps {
    capex: IRegionCapex[] | undefined
    onCancel: () => void
    onSave: (capexData: IFilteredCapex[]) => void
    clearAll: (res: Boolean) => void
}
// Add or Modify the Capex, Opex actuals
const AddCapex = (props: IProps) => {

    const { regionCostResults } = useSelector<RootState, IRegionConfigState>((state) => state.web.regionConfig)

    const { capex } = props
    const [capexValues, setCapexValues] = useState<IDefaultCapex[] | IFilteredCapex[] | []>([]);
    const [initialCapexValues, setInitialCapexValues] = useState<IDefaultCapex[] | IFilteredCapex[] | []>([]);
    const [buildCostResults, setBuildCostResults] = useState<IComboBoxOption[] | []>([])
    const redColor = { color: "red" }

    function buildDesignCostDropdown() {
        if (regionCostResults) {
            const costResultsObj = regionCostResults;
            let result = []
            const costResultskeys = Object.keys(costResultsObj)
            for (let key = 0; key < costResultskeys.length; key++) {
                result.push({ key: costResultskeys[key], text: costResultskeys[key] })
            }
            result.unshift(defaultOption)
            setBuildCostResults(result)
        }
    }

    useEffect(() => {
        // If Capex is there in region
        if (capex !== undefined && Array.isArray(capex) && capex.length > 0) {
            // merge default and remove duplicates
            const cloneDeepCapex = cloneDeep(capex)
            const mergedCapex = [...cloneDeepCapex, ...defaultCapex]
            const uniqueObjects = new Map();
            const filteredData: IFilteredCapex[] = [];
            mergedCapex.forEach(obj => {
                const key = `${obj.headName}`;
                if (!uniqueObjects.has(key)) {
                    uniqueObjects.set(key, obj);
                    filteredData.push(obj);
                }
            });
            setInitialCapexValues(filteredData)
            setCapexValues(filteredData)
        } else {
            setCapexValues(defaultCapex) // If not take defaultCapex values
        }

        buildDesignCostDropdown() // build dynamic cost result dropdown
    }, [])

    // Add Capex values
    const handleAddCapex = () => {
        // Add defaultCapex to inital addition
        if (capexValues.length === 0) {
            const newValues = { headName: "", selectedCostResult: "", headValue: "", items: [], error: "" };
            setCapexValues([...defaultCapex, newValues]);

        } else {
            const newValues = { headName: "", selectedCostResult: "", headValue: "", items: [], error: "" };
            setCapexValues([...capexValues, newValues] as IFilteredCapex[]);
        }
    };


    const handleCapexHeadChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, name: string, isDropDown = false) => {
        const eValue = (e.target as HTMLInputElement).value;

        const copiedValues: any = [...capexValues];

        if (!isDropDown) {
            copiedValues[index][name] = eValue;
            if (eValue) copiedValues[index]["error"] = ""
        } else {
            copiedValues[index][costResName] = name
            if (name) copiedValues[index]["error"] = ""
        }

        setCapexValues(copiedValues);
    };


    function isHeadValid(res: IFilteredCapex[] | IDefaultCapex[], isDropDown: boolean = false) {

        let errorMsg, emptyIndex;

        if (!isDropDown) {
            errorMsg = "Head Name Cannot Be Empty"
            emptyIndex = res.findIndex((el) => el.headName.trim() === "")
        } else {
            errorMsg = "Please select a valid option"
            emptyIndex = res.findIndex((el) => el?.selectedCostResult === "" || el.selectedCostResult === "Select")
        }

        if (emptyIndex === -1) return true //-> Valid
        res[emptyIndex]["error"] = errorMsg
        setCapexValues(res)
        return false //-> Invalid
    }
    function isSubHeadValid(res: IFilteredCapex[] | IDefaultCapex[], isDropDown: boolean = false) {

        let errorMsg, errorIndex: Record<string, number> = {}, emptyIndex
        if (!isDropDown) {
            errorMsg = "Item Name Cannot Be Empty"
        } else {
            errorMsg = "Please select a valid option"
        }

        for (let outer = 0; outer < res.length; outer++) {
            const { items } = res[outer]
            if (items && items.length > 0) {
                if (!isDropDown) {
                    emptyIndex = items.findIndex((el) => el.subHeadName.trim() === "")

                } else {
                    emptyIndex = items.findIndex((el) => el?.selectedCostResult === "" || el.selectedCostResult === "Select")
                }
                if (emptyIndex !== -1) {
                    errorIndex["outerIndex"] = outer
                    errorIndex["innerIndex"] = emptyIndex
                    break;
                }
            }
        }

        if (Object.keys(errorIndex).length === 0) return true //-> Valid
        res[errorIndex.outerIndex]["items"][errorIndex.innerIndex]["error"] = errorMsg
        setCapexValues(res)
        return false
    }

    const handleSaveCapex = () => {
        const result = [...capexValues];

        const isValidHeadName = isHeadValid(result);
        if (!isValidHeadName) return

        const isHeadDropDownValid = isHeadValid(result, true);
        if (!isHeadDropDownValid) return

        const isValidSubHeadName = isSubHeadValid(result)
        if (!isValidSubHeadName) return

        const isSubHeadDropDownValid = isSubHeadValid(result, true)
        if (!isSubHeadDropDownValid) return

        const finalRes = result.map((el, index) => {
            let { headName, headValue, items, id, selectedCostResult } = el;
            let newItems: IItems[] | [] = [];

            if (Array.isArray(items) && items.length === 0) {
                headValue = (headValue === "" || headValue === "-") ? "-" : +headValue;
            } else {
                const result = calcVal(index)
                headValue = (result === "number") ? +result : result;
            }

            if (items.length > 0) {
                newItems = (items as IItems[]).map((item) => {
                    let { subHeadName, subHeadValue, id, selectedCostResult } = item;
                    subHeadValue = (subHeadValue === "" || subHeadValue === "-") ? "-" : +subHeadValue;
                    if (id === undefined) return { subHeadName, subHeadValue, selectedCostResult };
                    else return { subHeadName, subHeadValue, id, selectedCostResult }
                });
            }
            if (id === undefined) {
                let tempObj: any = { headName, headValue, items: newItems }
                if (selectedCostResult) tempObj["selectedCostResult"] = selectedCostResult
                return tempObj;
            }
            else {
                let tempObj: any = { id, headName, headValue, items: newItems }
                if (selectedCostResult) tempObj["selectedCostResult"] = selectedCostResult
                return tempObj;
            }
        });
        return finalRes
    };


    const removeHeader = (index: number) => {
        const copiedValues = [...capexValues];
        copiedValues.splice(index, 1);
        setCapexValues(copiedValues as IFilteredCapex[]);
    };

    const handleAddSubCapex = (index: number) => {
        const copiedValues: any = [...capexValues];
        const newSubHeader = { subHeadName: "", selectedCostResult: "", subHeadValue: "", error: "" };
        if (copiedValues[index][costResName] !== undefined) {

            // remove the dropdown named - selectedCostResult
            const filteredValues = copiedValues.map((el: any, i: number) => {
                if (i === index) {
                    const { selectedCostResult, ...rest } = el
                    rest["error"] = ""
                    return rest
                } else {
                    return el
                }
            })
            filteredValues[index]["items"].push(newSubHeader);
            setCapexValues(filteredValues as IFilteredCapex[])

        } else {
            copiedValues[index]["items"].push(newSubHeader);
            setCapexValues(copiedValues as IFilteredCapex[]);
        }

    };

    const handleSubHeaderChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, outerIndex: number, innerIndex: number, name: string, isDropDown = false) => {
        const enteredValue = (e.target as HTMLInputElement).value;
        const copiedValues: any[] = [...capexValues];
        if (!isDropDown) {
            copiedValues[outerIndex]["items"][innerIndex][name] = enteredValue;
            if (enteredValue) copiedValues[outerIndex]["items"][innerIndex]["error"] = ""
        } else {
            copiedValues[outerIndex]["items"][innerIndex][costResName] = name;
            if (name) copiedValues[outerIndex]["items"][innerIndex]["error"] = ""
        }


        //  name === "subHeadName" ? enteredValue : +enteredValue;
        setCapexValues(copiedValues);
    };

    const handleRemoveSubHeader = (outerIndex: number, innerIndex: number) => {
        const copiedValues = [...capexValues];

        if (copiedValues[outerIndex]["items"].length === 1) {
            let capexObject = copiedValues[outerIndex]
            let keyValues = Object.entries(capexObject)
            let findCostResName = initialCapexValues[outerIndex]?.selectedCostResult
            if (capexObject["id"])
                keyValues.splice(2, 0, [costResName, findCostResName ? findCostResName : ""])
            else
                keyValues.splice(1, 0, [costResName, findCostResName ? findCostResName : ""])

            let newObj = Object.fromEntries(keyValues)
            copiedValues[outerIndex] = newObj as IFilteredCapex
        }
        copiedValues[outerIndex]["items"].splice(innerIndex, 1);
        setCapexValues(copiedValues as IFilteredCapex[]);
    };

    const calcVal = (index: number) => {
        const copiedValues: any = [...capexValues];
        let emptyStrings = 0
        if (copiedValues[index]["items"].length === 0) return 0;
        const sumOfItems = copiedValues[index]["items"].reduce((acc: number, curr: IItems) => {
            if (curr.subHeadValue === "" || curr.subHeadValue === "-") emptyStrings++;
            acc =
                acc +
                (curr.subHeadValue === "" || curr.subHeadValue === "-"
                    ? 0
                    : +curr.subHeadValue);
            return acc;
        }, 0);
        if (sumOfItems === 0) {
            if (emptyStrings === copiedValues[index]["items"].length) return "-"
            else return sumOfItems
        }
        return sumOfItems;
    };


    const handleOnSave = async () => {

        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Save Capex",
                closeButtonAriaLabel: "Close",
                subText: `Do you want to save this capex data ?`
            },
            confirmBtnText: "Save"
        });

        const result = handleSaveCapex()

        if (result === undefined) return

        if (isConfirmed) props.onSave(result)
    }



    const handleClearAllCapex = async () => {
        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Clear All Capex",
                closeButtonAriaLabel: "Close",
                subText: `Do you want to clear all this capex data ?`
            },
            confirmBtnText: "Clear"
        });
        if (isConfirmed) {
            props.clearAll(true)
            setCapexValues([])
        }

    }

    const renderErrorMessage = (errorMsg: string | undefined) => {
        if (errorMsg)
            return <div style={{ color: "red", textAlign: "center" }}>
                {errorMsg !== "" && errorMsg}
            </div>
    }

    const renderCapexValues = () => {
        return (
            <div >
                {
                    (capexValues as IFilteredCapex[]).map((valueObj: IFilteredCapex, index: number) => {
                        const errorMsg = valueObj?.error

                        // Filterout items, error, id 
                        const headObj = Object.keys(valueObj).filter((el) => el !== "items" && el !== "error" && el !== "id");

                        // Render headName and selectedCostResult
                        return (
                            <div key={index} >
                                <div className="flex-row-center" style={{ gap: "0.5rem", marginBottom: "0.5rem" }}>
                                    {index + 1}) {headObj.map((el: string, innerIndex: number) => {
                                        if (el === "selectedCostResult") {
                                            return <ComboBox
                                                key={innerIndex}
                                                options={buildCostResults}
                                                selectedKey={valueObj[el] || defaultOption.key}
                                                defaultSelectedKey={valueObj[el] || defaultOption.key}
                                                onChange={(e: any, elm: any) => handleCapexHeadChange(e, index, elm?.key, true)}
                                                style={errorMsg && errorMsg === "Please select a valid option" ? { border: "0.05rem solid red" } : {}}
                                                allowFreeform
                                                autoComplete="on"
                                                required
                                            />
                                        }
                                        //! textField -> only for headName, headValue(textField) is commented
                                        else if (el === "headName") {
                                            return (
                                                <div key={innerIndex} >
                                                    <TextField
                                                        type={"text"}
                                                        placeholder={`Enter Head`}
                                                        name={el}
                                                        style={(errorMsg && errorMsg === "Head Name Cannot Be Empty") ? { border: "0.05rem solid red", width: "400px" } : { width: "400px" }}
                                                        value={
                                                            el === "headName"
                                                                ? valueObj[el]
                                                                : valueObj["items"].length > 0
                                                                    ? calcVal(index)
                                                                    : valueObj[el as keyof IFilteredCapex]
                                                        }
                                                        disabled={ignoreHeadNames.includes(valueObj[el]) && true}
                                                        // if default capex removed ->   disabled={ el === "headValue" && valueObj["items"].length > 0 && true}
                                                        onChange={(e) => handleCapexHeadChange(e, index, el)}
                                                    />
                                                </div>
                                            );
                                        }
                                        //! Dynamic textField -> headName, headValue
                                        // else {
                                        //     return (
                                        //         <div key={innerIndex} >
                                        //             <TextField
                                        //                 type={el === "headName" ? "text" : "number"}
                                        //                 placeholder={el === "headName" ? `Enter Head` : `Enter budget total`}
                                        //                 name={el}
                                        //                 style={el === "headName" ? ((errorMsg && errorMsg === "Head Name Cannot Be Empty") ? { border: "0.05rem solid red", width: "400px" } : { width: "400px" }) : { width: "400px" }}
                                        //                 value={
                                        //                     el === "headName"
                                        //                         ? valueObj[el]
                                        //                         : valueObj["items"].length > 0
                                        //                             ? calcVal(index)
                                        //                             : valueObj[el as keyof IFilteredCapex]
                                        //                 }
                                        //                 disabled={el === "headName" ? (ignoreHeadNames.includes(valueObj[el]) && true) : (valueObj["items"].length > 0 && true)}
                                        //                 // if default capex removed ->   disabled={ el === "headValue" && valueObj["items"].length > 0 && true}
                                        //                 onChange={(e) => handleCapexHeadChange(e, index, el)}
                                        //             />
                                        //         </div>
                                        //     );
                                        // }

                                    })}

                                    {/* render delete, add subCapex button */}
                                    {
                                        !ignoreHeadNames.includes(valueObj.headName) && <>
                                            <ActionButton
                                                iconProps={{ iconName: 'Delete', style: redColor }}
                                                onClick={() => removeHeader(index)}>
                                            </ActionButton>

                                            <ActionButton
                                                iconProps={{ iconName: 'Add' }}
                                                onClick={() => handleAddSubCapex(index)}>
                                                Add Items
                                            </ActionButton>
                                        </>
                                    }
                                </div>

                                {/* render error message */}

                                {renderErrorMessage(errorMsg)}

                                {/* render subHeadName, selectedCostResult from items */}

                                <div style={{ background: '#eee', maxWidth: "fit-content", margin: "0 auto" }}>
                                    {valueObj["items"].length > 0 &&
                                        (valueObj["items"] as IItems[]).map((itemObj, ind: number) => {
                                            const errorMsg = itemObj["error"] // Store the error message
                                            const subHeadBox = Object.keys(itemObj).filter(el => el !== "error" && el !== "id"); // Filter out error messages

                                            return (
                                                <>
                                                    <div key={ind} className="flex-row-center" style={{ gap: "0.5rem" }}>
                                                        {ind + 1} )   {subHeadBox.map((el, inInnerIndex) => {

                                                            if (el === "selectedCostResult") {
                                                                return <ComboBox
                                                                    key={inInnerIndex}
                                                                    options={buildCostResults}
                                                                    selectedKey={itemObj[el as keyof IItems] as string | undefined || defaultOption.key}
                                                                    defaultSelectedKey={itemObj[el as keyof IItems] as string | undefined || defaultOption.key}
                                                                    style={(errorMsg && errorMsg === "Please select a valid option") ? { border: "0.05rem solid red" } : {}}
                                                                    onChange={(e: any, elm: any) =>
                                                                        handleSubHeaderChange(e, index, ind, elm?.key, true)
                                                                    }
                                                                    allowFreeform
                                                                    autoComplete="on"
                                                                    required
                                                                />
                                                            }
                                                            //! Only subHeadName textField, subHeadValue textField are commented out
                                                            else if (el === "subHeadName") {
                                                                return (
                                                                    <span key={inInnerIndex}>
                                                                        <div className={"flex-column-center"}>
                                                                            <TextField
                                                                                type={"text"}
                                                                                placeholder={`Enter Item`}
                                                                                //  style={{ width: "200px" }}
                                                                                style={(errorMsg && errorMsg === "Item Name Cannot Be Empty") ? { border: "0.05rem solid red", width: "200px" } : { width: "200px" }}
                                                                                name={el}
                                                                                value={itemObj[el as keyof IItems] as string | undefined}
                                                                                onChange={(e) =>
                                                                                    handleSubHeaderChange(e, index, ind, el)
                                                                                }
                                                                            />

                                                                        </div>
                                                                    </span>
                                                                );
                                                            }
                                                            //! Dynamic text fields -> subHeadName, subHeadValue
                                                            // else {
                                                            //     return (
                                                            //         <span key={inInnerIndex}>
                                                            //             <div className={el === "subHeadName" ? "flex-column-center" : ""}>
                                                            //                 <TextField
                                                            //                     type={el === "subHeadName" ? "text" : "number"}
                                                            //                     placeholder={
                                                            //                         el === "subHeadName"
                                                            //                             ? `Enter Item`
                                                            //                             : `Enter budget total`
                                                            //                     }
                                                            //                     //  style={{ width: "200px" }}
                                                            //                     style={el === "subHeadName" ? ((errorMsg && errorMsg === "Item Name Cannot Be Empty") ? { border: "0.05rem solid red", width: "200px" } : { width: "200px" }) : { width: "200px" }}
                                                            //                     name={el}
                                                            //                     value={itemObj[el as keyof IItems] as string | undefined}
                                                            //                     onChange={(e) =>
                                                            //                         handleSubHeaderChange(e, index, ind, el)
                                                            //                     }
                                                            //                 />

                                                            //             </div>
                                                            //         </span>
                                                            //     );
                                                            // }

                                                        })}
                                                        <ActionButton
                                                            iconProps={{ iconName: 'Delete', style: redColor }}
                                                            onClick={() => { handleRemoveSubHeader(index, ind) }}>
                                                        </ActionButton>
                                                    </div>
                                                    {renderErrorMessage(errorMsg)}
                                                </>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        )
    }


    return (
        <div>
            <h1 style={{ textAlign: "center" }}>Enter Capex Details</h1>

            <div className="flex-row-center" style={{ justifyContent: "flex-end" }}>
                <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleAddCapex}>Add Head</ActionButton>
                {
                    capexValues.length > 0 &&
                    <ActionButton iconProps={{ iconName: 'Delete', style: redColor }}
                        onClick={handleClearAllCapex}
                        style={redColor}>
                        Clear All Capex
                    </ActionButton>
                }
            </div>

            <div>
                {capexValues.length === 0 ?
                    <div className="text-center">
                        <Text variant="mediumPlus">
                            No data to display
                        </Text>
                    </div> :
                    renderCapexValues()}
            </div>

            <br />

            <Stack horizontal horizontalAlign="center">
                <Stack.Item >
                    {capexValues.length > 0 && (
                        <>
                            <PrimaryButton
                                text="Save"
                                onClick={handleOnSave}
                                className="btn-primary"
                            />
                            <DefaultButton onClick={props.onCancel} text="Cancel" />
                        </>
                    )}

                </Stack.Item>
            </Stack>

        </div >
    )
}

export default openAddCapexModal

interface IDefaultCapex {
    id?: string
    headName: string;
    headValue: string;
    items: never[];
    error?: string;
    selectedCostResult?: string
}
interface IFilteredCapex {
    id?: string;
    headName: string;
    headValue: string | number;
    items: never[] | IItems[]
    error?: string
    selectedCostResult?: string
}

interface IItems {
    id?: string;
    subHeadName: string;
    subHeadValue: number | string
    error?: string
    selectedCostResult?: string
}