import { useEffect, useState } from 'react'
import geojson from "../../utils/geojson.json";
import { geoMercator, geoPath } from 'd3-geo';
import chroma from "chroma-js";
import { IAdminDashboard } from '@solarforschools/sfs-core';

interface IRenderDetails {
    country: string;
    count: number;
    color: string;
}
interface IRegionBasedActiveSchools {
    country: string;
    count: number;
}
const MapContent = (props: Partial<IAdminDashboard>) => {

    const activeSchoolsData: any = props.activeSchools
    const [renderDetails, setRenderDetails] = useState<IRenderDetails[]>([])

    const width = 290;
    const height = width * 0.55;
    const projection = geoMercator().fitExtent(
        [
            [0, 0],
            [width * 0.9, height * 0.9],
        ],
        geojson as any
    );
    const path = geoPath().projection(projection);

    useEffect(() => {
        const countriesDetails = activeSchoolsData?.regions?.map((e: IRegionBasedActiveSchools) => e)
        const colorsLength = countriesDetails ? countriesDetails.length + 1 : 0;
        let colors = chroma.scale("YlOrRd")
            .mode('lch').colors(colorsLength)

        let renderThis = countriesDetails?.map((e: IRegionBasedActiveSchools, i: number) => {
            if (e.country === "--") {
                return {
                    country: "CO",
                    count: e.count,
                    color: colors[i],
                }
            } else {
                return {
                    country: e.country,
                    count: e.count,
                    color: colors[i],
                }
            }
        })
        setRenderDetails(renderThis)
    }, [activeSchoolsData])

    if (renderDetails.length === 0 || activeSchoolsData?.length === 0) return <></>

    const countriesName = renderDetails.map(e => e.country)

    const filteredGeoJSON = geojson.features.filter((el) => {
        if (!countriesName.includes(el.properties.iso_a2)) {
            return el
        }
    })

    return (
        <div className='flex-row-center'>
            <svg width={width} height={height}>
                <g className="geojson-layer">
                    {
                        renderDetails.map((detail: IRenderDetails) => {
                            const geoJSON = geojson.features.filter((el: any) => el.properties.iso_a2 === detail.country)

                            return (
                                <path
                                    key={geoJSON[0].properties.iso_a2}
                                    d={path(geoJSON[0] as any) as any}
                                    fill={detail.color}
                                    strokeWidth="0.5"
                                    strokeOpacity="1"
                                    stroke="#636363"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <title>
                                        {detail.country} - {detail.count}
                                    </title>
                                </path>
                            )
                        })
                    }
                    {
                        filteredGeoJSON.map((el) => {
                            return (
                                <path
                                    key={el.properties.iso_a2}
                                    d={path(el as any) as any}
                                    fill="#243f61"
                                    stroke="#243f61"
                                    strokeWidth="1"
                                    strokeOpacity="0.5"
                                />
                            )

                        })

                    }
                </g>
            </svg>

        </div>
    )
}

export default MapContent