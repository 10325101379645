import { ComboBox, DefaultButton, IComboBoxOption, Label, PrimaryButton, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import store, { RootState } from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getGeneralFields, validateFaq } from "./FaqHelper"
import { isLoading } from "../../../store/admin/action"
import { getuserFunctionsApi } from "../../../store/client/userFunction"
import { toast } from "react-toastify"
import { comboBoxStyles } from "../common/common"
import { IFaq } from '@solarforschools/sfs-core/dist/faq/types';
import { IFaqState } from '../../../store/admin/faqs/reducer';
import { createUpdateFaq } from "../../../store/admin/faqs/action"
import sites from "@solarforschools/sfs-core/dist/PVAM/sites"
import { MdeEditor } from "../common/MdeEditor"


export const OpenFaqModal = (props?: any) => {
  return new Promise<IFaq | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: IFaq) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add/Edit Faq"
          onDismiss={() => callback(undefined)}
          containerClassName="modal-size-sm"
        >
          <EditFaqDialog
            {...props}
            onSave={(data: IFaq | undefined) => callback(data)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditFaqDialog = (props: IFaqProps) => {
  const dispatch = useDispatch();
  const { sites, categories }: IFaqState = useSelector<RootState, IFaqState>((state) => state.web.faqs)
  const [faq, setFaq] = useState<IFaq>(_.cloneDeep(props.faq as IFaq))
  const [errors, setErrors] = useState<any>({});
  // const [roles, setRoles] = useState<any>([])

  const generalFields = useMemo(() => getGeneralFields({ faq }), [faq]);

  const handleOnSave = async () => {
    const errors = await validateFaq(faq);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    await dispatch(createUpdateFaq(faq))
    props.onSave(faq)
  }

  const handleOnChange = (key: string, item: any) => {
    switch (key) {
      case 'sections':
        let sections = faq[key as string as keyof IFaq] as any[] || []
        if (item?.selected) {
          if (!sections?.find((s) => s.value === item?.key)) {
            sections.push(categories.find(c => c.value === item.key));
          }
        } else {
          sections = sections?.filter((s) => s.value !== item?.key) as string[];
        }
        setFaq({ ...faq, [key]: sections })
        break
      case 'site':
        let data = faq[key as string as keyof IFaq] as any[] || []
        if (item?.selected) {
          if (!data?.find((s) => s === item?.key)) {
            data.push(item.key);
          }
        } else {
          data = data?.filter((s) => s !== item?.key) as string[];
        }
        setFaq({ ...faq, [key]: data })
        break
      case 'country':
        let regions = faq.country && !_.isArray(faq.country) ? faq.country = [faq.country] : faq[key as string as keyof IFaq] as any[] || []
        if (item?.selected) {
          if (!regions?.find((s) => s === item?.key)) {
            regions.push(item.key);
          }
        } else {
          regions = regions?.filter((s) => s !== item?.key) as string[];
        }
        setFaq({ ...faq, [key]: regions })
        break
      default:
        setFaq(_.set({ ...faq }, key, item))
        break;
    }
  }

  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <DynamicForm
            fields={generalFields}
            data={faq}
            onChange={handleOnChange}
            errors={errors}
          />
          <ComboBox
            label="Sites"
            selectedKey={faq?.site || null}
            placeholder="Select"
            multiSelect
            allowFreeform={true}
            autoComplete={"on"}
            options={
              sites.map(s => { return { key: s.value, text: s.name } }) as IComboBoxOption[]
            }
            onChange={(e, item) => handleOnChange("site", item)}
            styles={comboBoxStyles}
            className="ms-Grid-col ms-lg12"
            errorMessage={errors?.site}
          />
          <ComboBox
            label="Categories"
            selectedKey={faq?.sections?.map(s => s.value) || null}
            placeholder="Select"
            multiSelect
            allowFreeform={true}
            autoComplete={"on"}
            options={
              categories.map(s => { return { key: s.value, text: s.name } }) as IComboBoxOption[]
            }
            onChange={(e, item) => handleOnChange("sections", item)}
            styles={comboBoxStyles}
            className="ms-Grid-col ms-lg12"
            errorMessage={errors?.sections}
          />
          <TextField
            label="Sub Category"
            placeholder=""
            onChange={(e, value) => handleOnChange('subCategory', value || '')}
            className="ms-Grid-col ms-lg12"
            value={faq.subCategory!} />
        </div>
        <div className='ms-Grid-row margin-top-lg'>
          <Label className="margin-top-md">Answer</Label>
          <MdeEditor onChange={handleOnChange} value={faq.ans} field={'ans'} />
        </div>
      </div>

      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface IFaqProps {
  onCancel?: () => void;
  onSave: (data?: IFaq) => void;
  faq?: IFaq;
}

export default OpenFaqModal;
