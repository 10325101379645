import { isLoading } from "../action";
import {
  ISupporters,
  ISchools,
} from "@solarforschools/sfs-core/dist/supporters/types";
import { Action } from "./actionTypes";

export interface IInitialState {
  data: ISupporters[];
  isLoading: boolean;
  liveSchools: ISchools[];
}

const initialState: IInitialState = {
  data: [],
  isLoading: true,
  liveSchools: [],
};

const supporters = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_SUPPORTERS":
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case "SET_LIVE_SCHOOLS":
      return {
        ...state,
        liveSchools: action.payload,
      };

    case "UPDATE_SUPPORTER":
      const updatedData = action.payload;
      console.log("Data ",state.data)
      console.log("Up data ", updatedData)
      const findUpdateIdIndex = state.data.findIndex(
        (elm) =>
          elm.supporterId.toString() === updatedData?.supporterId?.toString()
      );

      if (findUpdateIdIndex !== -1) {
        const data = [...state.data];

        const newSchools = updatedData["currSchools"]?.map(
          (elm: ISchools) => elm.name
        );

        delete updatedData["prevSchools"];
        delete updatedData["currSchools"];
        delete updatedData["schools"];

        
        const newData = {
          ...data[findUpdateIdIndex],
          ...updatedData,
          schools: newSchools
        }

        data[findUpdateIdIndex] = newData
       
        return {
          ...state,
          data,
        };
      } else return state;
    case "DELETE_SUPPORTER":
      const deleteId = action.payload;
      const findId = state.data.find(
        (elm) => elm.supporterId.toString() === deleteId
      );

      if (findId) {
        const result = state.data.filter(
          (elm) => elm.supporterId.toString() !== deleteId
        );
        return {
          ...state,
          data: result,
        };
      }
      return state;
    default:
      return state;
  }
};

export default supporters;
