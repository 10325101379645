import {
  ActionButton,
  IColumn,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { isEqual, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateOtherConfigData } from "../../../store/admin/otherConfig/action";
import { IOtherConfigState } from "../../../store/admin/otherConfig/reducer";
import DetailsListWapper from "../../common/DetailsListWapper";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import {
  ConvertedObjectType,
  findResponseDataType,
  IResultTableFormat,
  responseFormat,
  tableFormat,
} from "./helper";
import { openOtherConfigDialog } from "./OtherConfigDialog";
import {
  getArrayColumns,
  getObjectColumns,
  getArrOfObjColumns,
} from "./OtherConfigHelper";
import { isLoading as isLoader } from '../../../store/admin/action';

const OtherConfigLists = () => {
  const otherConfigData = useSelector<RootState, IOtherConfigState>(
    (state) => state.web.otherConfig
  );
  const { isLoading, otherConfig } = otherConfigData;
  const otherConfigId = otherConfig && otherConfig[0]?.id;
  let otherConfigLen: number =
    otherConfig[0] && Object.keys(otherConfig[0].data).length;
  const [convArrOfObj, setConvArrOfObj] = useState([]);
  const dispatch = useDispatch();
  const [isArray, setIsArray] = useState(false);

  const [dynamicColumns, setDynamicColumns] = useState<IColumn[]>([]);

  const [prevDynamicColumns, setPrevDynamicColumn] = useState<IColumn[]>([]);

  useEffect(() => {
    if (otherConfig[0]) {
      findResponseDataType(otherConfig[0]);
      const formatedOutput = tableFormat(otherConfig[0]);
      setConvArrOfObj(formatedOutput.data as []);
    }
  }, [otherConfig]);



  //Trigger on every Config Id change
  useEffect(() => {
    if (otherConfig[0]?.data) {
      if (
        Array.isArray(otherConfig[0]?.data) &&
        typeof otherConfig[0]?.data[0] === "string"
      ) {
        setDynamicColumns(getArrayColumns());
        setIsArray(true);
      } else if (
        Array.isArray(otherConfig[0]?.data) &&
        typeof otherConfig[0]?.data[0] === "object" &&
        !Array.isArray(otherConfig[0]?.data[0])
      ) {
        setDynamicColumns(getArrOfObjColumns(otherConfig[0]?.data));
        setIsArray(false);
      } else {
        setDynamicColumns(getObjectColumns());
        setIsArray(false);
      }
    }
  }, [otherConfigId]);

  //Trigger on every dynamicColumns change
  useEffect(() => {
    setPrevDynamicColumn(dynamicColumns);
  }, [dynamicColumns]);

  const handleEditOtherConfig = async () => {
    // const openModal = await openOtherConfigDialog(convertedArrOfObj);
    const openModal = await openOtherConfigDialog(convArrOfObj);
    const { _id, id, type } = otherConfig[0];
    let getFinalObject;
    if (openModal) {
      getFinalObject = { _id, id, type, data: openModal };
      const finalData = responseFormat(getFinalObject);
      const isSameAfterEdit = isEqual(finalData, otherConfig[0]);
      if (!isSameAfterEdit) dispatch(updateOtherConfigData(finalData));
    }
  };


  //Sorting if fields are unique type and not null
  const handleSort = (field: string, order: string) => {
    dispatch(isLoader(true))
    const fieldName = field; //Field Name
    let fieldType;
    let afterSort: any = []; //Output

    let occuranceOfField = 0; //Store the field's values count (store only if value is not null)
    let typesOfFieldValues = []; //Store all the fields types
    const itemsLength = convArrOfObj.length; //ConvArrOfObj is the items to be rendered

    for (let i = 0; i < itemsLength; i++) {
      const field: string | boolean | number = convArrOfObj[i][fieldName];
      typesOfFieldValues.push(typeof field);
      //Used to convert boolean and number to string -> to count the occurance
      const finalField =
        typeof field === "number" || field === "boolean"
          ? String(field)
          : field;
      if (finalField) {
        occuranceOfField++;
      }
    }

    //Condition to check whether fields have value(should not have null) with unique type
    if (
      itemsLength > 0 &&
      occuranceOfField === itemsLength &&
      new Set(typesOfFieldValues).size === 1
    ) {
      fieldType = typeof convArrOfObj[0][fieldName];
      if (order === "desc") {
        if (fieldType === "string") {
          afterSort = sortBy(convArrOfObj, (field) =>
            (field[fieldName] as "").toLowerCase()
          ).reverse();
        } else if (fieldType === "number") {
          afterSort = sortBy(
            convArrOfObj,
            (field) => field[fieldName]
          ).reverse();
        } else if (fieldType === "boolean") {
          afterSort = convArrOfObj.sort(
            (a: any, b: any) => a[fieldName] - b[fieldName]
          );
        }
      } else {
        if (fieldType === "string") {
          afterSort = sortBy(convArrOfObj, (field) =>
            (field[fieldName] as "").toLowerCase()
          );
        } else if (fieldType === "number") {
          afterSort = sortBy(convArrOfObj, (field) => field[fieldName]);
        } else if (fieldType === "boolean") {
          afterSort = convArrOfObj.sort(
            (a: any, b: any) => b[fieldName] - a[fieldName]
          );
        }
      }
      setTimeout(() => {
        setConvArrOfObj(afterSort);
      }, 100);
    }
    dispatch(isLoader(false))
  };

  if (otherConfig.length === 0) return <></>

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        style={{ width: "85%", marginLeft: "1rem" }}
      >
        <Text>Configuration Variables: {otherConfigLen}</Text>
        <ActionButton
          title={"Edit Hosts"}
          iconProps={{ iconName: "Edit" }}
          onClick={() => handleEditOtherConfig()}
        >
          Edit Configuration
        </ActionButton>
      </Stack>

      <div
        className={
          isArray ? "data-array-list-container" : "data-list-container"
        }
      >
        {otherConfig[0]?.data &&
          isEqual(sortBy(dynamicColumns), sortBy(prevDynamicColumns)) ? (
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsListWapper
              items={convArrOfObj as any[]}
              onRenderDetailsHeader={DetailListStickyHeader}
              columns={dynamicColumns}
              selectionMode={SelectionMode.none}
              compact={true}
              isLoading={isLoading}
              onSort={(field, order) => handleSort(field, order)}
            />
          </ScrollablePane>
        ) : null}
      </div>
    </>
  );
};

export default OtherConfigLists;
