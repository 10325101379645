import { ChoiceGroup, ComboBox, Dropdown, IComboBoxOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getUsers, setUserFilter } from '../../../store/admin/users/action';
import { IUserState } from '../../../store/admin/users/reducer';
import { comboBoxStyles, dropdownStyles, Regions } from '../common/common';
import { StringMap } from '../common/types';
import { statusOptions } from './common';

const UserFilters = (props: UserFilterProps) => {
    const dispatch = useDispatch()
    const { userFilter, permissions }: IUserState = useSelector<RootState, IUserState>((state) => state.web.user)
    const setFilters = (filters: StringMap) => dispatch(setUserFilter(filters));

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        const params = { ...userFilter, page: 1, [key]: value };
        setFilters(params);
        handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback(
        (filters: any) => {
            dispatch(getUsers(filters));
        },
        []
    );

    const { email, name, region, status, isAdmin, permission } = userFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="User Name"
                    placeholder="User Name"
                    onChange={(e, value) => handleOnChange('name', value || '')}
                    className="ms-Grid-col ms-lg3"
                    value={name!} />
                <TextField
                    label="User Email"
                    placeholder="User Email"
                    onChange={(e, value) => handleOnChange('email', value || '')}
                    className="ms-Grid-col ms-lg4"
                    value={email!} />
                <Dropdown
                    dropdownWidth='auto'
                    label="Region"
                    onChange={(e, item) => handleOnChange("region", item?.key?.toString())}
                    selectedKey={region || 'all'}
                    options={Regions}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg2"
                />
                <ComboBox
                    label="Permissions"
                    selectedKey={permission! || null}
                    placeholder="Select"
                    // multiSelect
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={
                        [{ key: 'all', text: 'All' }, ...permissions] as IComboBoxOption[]
                    }
                    onChange={(e, item) => handleOnChange('permission', item?.key?.toString())}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg3"
                />
                <ChoiceGroup
                    className="inlineflex ms-Grid-col ms-lg4"
                    label="Status/Active"
                    selectedKey={status || "all"}
                    onChange={(e, option) => handleOnChange("status", option?.key,)}
                    options={statusOptions}
                />
                <ChoiceGroup
                    className="inlineflex ms-Grid-col ms-lg4"
                    label="IsAdmin"
                    selectedKey={isAdmin || "all"}
                    onChange={(e, option) => handleOnChange("isAdmin", option?.key,)}
                    options={statusOptions}
                />

            </div>
        </div>
    );
}

interface UserFilterProps {

}

export default UserFilters;

