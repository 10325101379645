import { Stack } from "@fluentui/react";
import { IBondHolder } from "@solarforschools/sfs-core/dist/funder/bond-holder/types";
import { IBondOffer } from "@solarforschools/sfs-core/dist/funder/bond-offer/types";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";
import { ITransaction } from "@solarforschools/sfs-core/dist/funder/transaction/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { getBondBondHolders, getBondBondoffers, getBonds, setBondsFilter, setBondsList } from "../../../../store/admin/funder/bond/action";
import { IBondState } from "../../../../store/admin/funder/bond/reducer";
import { getBondsApi } from "../../../../store/client/funder/bond";
import { defaultBondFilter } from "../bond/BondHelper";
import BondList from "../bond/BondList";
import { IBondFilter } from "../bond/types";

const BondHolderBonds = (props: Props) => {
  const dispatch = useDispatch();

  const loadData = async () => {
    const params: IBondFilter = { ...defaultBondFilter, page: 1, email: props.bondHolder.userName };
    dispatch(setBondsFilter(params));
    dispatch(setBondsFilter(params));
    dispatch(getBonds(params))
    dispatch(getBondBondoffers())
    dispatch(getBondBondHolders())
  }

  useEffect(() => {
    loadData();
  }, [])


  return (
    <Stack>
      <BondList
        actions={["edit", "delete"]}
      />
    </Stack>
  );
}

interface Props {
  bondHolder: IBondHolder;
  onUpdate?: (key: string, value: any) => void
}

export default BondHolderBonds;
