
import { IProductFilter } from "../../../components/admin/product/types";
import { IProduct as IProductDocument } from "@solarforschools/sfs-core/dist/product/type";
import axios from "axios";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../..";
import { generateQueryString } from "../../../utils/util";
import { host } from "../../config";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { getProductsApi } from "../../client/product";


// Action Creators

export const setProductFilter = (productFilter: IProductFilter) => {
  return { type: "SET_PRODUCT_FILTER", productFilter };
};


export const setProductList = (products: IProductDocument[]) => {
  return { type: "SET_PRODUCT_LIST", products };
};

export const setProductTotalCount = (totalCount: number) => {
  return { type: 'SET_PRODUCT_TOTAL_COUNT', totalCount }
}


export const fetchProducts =
  (query?: any,
    isShowLoader: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const productFilter: IProductFilter = query || getState().web.product.productFilter;
        const params = { ...productFilter };

        const data: any = await getProductsApi(params);
        await dispatch(setProductTotalCount(data.totalCount));
        await dispatch(setProductList(data.products));
        if (isShowLoader) dispatch(isLoading(false));
        return data
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateProduct = (
  product: IProductDocument,
  isShowLoader: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const url = product._id ? `${host}/product/${product._id}` : `${host}/product`
        const res = product._id ? await axios.put(url, { ...product }) : await axios.post(url, { ...product });
        toast.success(res.data.msg);
        await dispatch<any>(fetchProducts());
        if (isShowLoader) dispatch(isLoading(false));
        resolve(res.data);
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const deleteProduct = (
  _id: string,
  isShowLoader: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.delete(`${host}/product/${_id}`)
        toast.success(res.data.msg);
        dispatch<any>(fetchProducts());
        if (isShowLoader) dispatch(isLoading(false));
        resolve(res.data)
      } catch (e) {
        if (isShowLoader) dispatch(isLoading(false));
        console.log(e);
        // toast.error(e.message);
        reject(e)
      }
    });
  };
};

export const validateProductId = (
  product: IProductDocument
) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res: any = await axios.put(`${host}/product/validate/product`, {
        ...product,
      });

      resolve(res.data);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};