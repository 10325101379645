
import { IBondOffer } from "@solarforschools/sfs-core/dist/funder/bond-offer/types";
import { IBondOfferFilter } from "../../../../components/admin/funder/bondOffer/types";
import { IBondHolderFilter } from './../../../../components/admin/funder/bondholder/types.d';
import { Action } from "./actionType";


// States' definition
export interface IBondOfferState {
  isLoading: boolean;
  bondOffers: IBondOffer[];
  bondOfferFilter: IBondOfferFilter;
  totalCount: number
  bondOffersFilterData:any[]
}
export interface State {
  bondOffer: IBondOfferState;
}

const bondOffer = (
  state: IBondOfferState = {
    isLoading: false,
    bondOffers: [],
    bondOfferFilter: {
      page: 1,
      pageSize: 50,
      sortField: '_id',
      sortOrder: 'desc',
    },
    totalCount: 0,
    bondOffersFilterData:[]
  },
  action: Action
): IBondOfferState => {
  switch (action.type) {
    case "FUNDER/BONDOFFER_SET_BONDOFFER_FILTER":
      return { ...state, bondOffersFilterData: action.bondOffers as [] }
    case 'FUNDER/SET_BONDOFFER_FILTER':
      return { ...state, bondOfferFilter: action.bondOfferFilter }
    case 'FUNDER/SET_BONDOFFER_LIST':
      const bondOffers = action.replace ? action.bondOffers : [...state.bondOffers, ...action.bondOffers]
      return { ...state, bondOffers, totalCount: action.totalCount }
      case "FUNDER/DELETE_BONDOFFER": {
        return { ...state, bondOffers: state.bondOffers.filter(b => b._id?.toString() !== action.bondOffer._id?.toString()), totalCount: state.totalCount - 1 }
      }
    default:
      return state;
  }
};

export default bondOffer;
