import { ComboBox, IComboBoxStyles, IDropdownStyles } from "@fluentui/react";
import SchoolPicker from "../../common/SchoolPicker";
import SystemPicker from "../../common/SystemPicker";
import { useDispatch, useSelector } from "react-redux";
import { IInvoiceReport } from "../../../../store/admin/reporting/invoice/reducer";
import { RootState } from "../../../../store";
import InvoiceCyclePicker from "../../common/InvoiceCyclePicker";
import {  fetchInvoiceReportData, setInvoiceReportFilter } from "../../../../store/admin/reporting/invoice/action";



const InvoiceReportFilter = () => {
    const dispatch = useDispatch();

    const { invoiceReportFilter }: IInvoiceReport = useSelector<RootState, IInvoiceReport>((state) => state.web.invoiceReport);
    const handleOnChange = (
        key: string,
        value?: any,
    ) => {
        let params = { ...invoiceReportFilter };

        switch (key) {
            case "selectedSchool":
                params = { ...params, schoolSlug: value[0]?.key }
                break;
            case 'selectedSystems':
                params = { ...params, system: value[0]?.key }
                break
            case 'selectedCycle':
                params = { ...params, invoiceCycle: value[0]?.key }
                break
            case 'compareInvoiceCycle':
                params = { ...params, compareInvoiceCycle: value[0]?.key }
                break
            default: {
                params = { ...params, [key]: value }
            }
        }
        dispatch(setInvoiceReportFilter(params));
        if (params.compareInvoiceCycle && params.invoiceCycle) {
            dispatch(fetchInvoiceReportData());
        }

    };

    const params = { all: true, live: true }

    const {
        schoolSlug,
        system,
        ownedBy,
        invoiceCycle,
        compareInvoiceCycle
    } = invoiceReportFilter;
    return (
        <div
            className="ms-Grid ms-Grid-row"
            dir="ltr"
            style={{ width: "-webkit-fill-available", minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
                <SchoolPicker selected={[{ key: schoolSlug, name: schoolSlug }]} filters={params} onChange={handleOnChange} />
            </div>
            <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
                <SystemPicker selectedItems={[{ key: system, name: system }]} filters={{ schoolSlug }} onChange={handleOnChange} />
            </div>
            <ComboBox
                label="OwnedBy"
                placeholder="Select OwnedBy"
                allowFreeform={true}
                autoComplete={"on"}
                selectedKey={ownedBy || 'CBS'}
                options={[
                    { key: "School", text: "School" },
                    { key: "CBS", text: "CBS" },
                    { key: "GSS1", text: "GSS1" },
                    { key: "GSS2", text: "GSS2" },
                ]}
                className="ms-Grid-col ms-lg2"
                onChange={(e, item) => handleOnChange("ownedBy", item?.key)}
            />
            <div className="ms-Grid-col ms-lg2 margin-top-xsm">
                <InvoiceCyclePicker selectedItems={[{ key: invoiceCycle, name: invoiceCycle }]} onChange={handleOnChange} />
            </div>
            <div className="ms-Grid-col ms-lg2 margin-top-xsm">
                <InvoiceCyclePicker label="Compare Cycle" name="compareInvoiceCycle" selectedItems={[{ key: compareInvoiceCycle, name: compareInvoiceCycle }]} onChange={handleOnChange} />
            </div>
        </div>
    );
};

export default InvoiceReportFilter;
