import { Text } from "@fluentui/react";
import { FaMapMarkerAlt, FaSchool, FaSolarPanel, FaUsers } from "react-icons/fa"
import { MdElectricBolt, MdSolarPower, MdOutlineEnergySavingsLeaf, MdOutlineCurrencyPound } from "react-icons/md"
import { AiOutlinePound } from "react-icons/ai"
import { BsSpeedometer } from "react-icons/bs"
import { PipeDriveIcon } from "../utils/Icons";
import { IoAnalyticsOutline } from "react-icons/io5";

interface ICardHeaderProps {
    title: string;
    iconName: string;
}

const CardHeader = (props: ICardHeaderProps) => {
    const { title, iconName } = props
    const IconStyle = {
        fontSize: "1.5rem", color: "#243f61"
    }

    const Icons: Record<string, JSX.Element> = {
        Schools: <FaSchool style={IconStyle} />,
        Designs: <FaSolarPanel style={IconStyle} />,
        Users: <FaUsers style={IconStyle} />,
        Projects: <MdSolarPower style={IconStyle} />,
        CarbonSavings: <MdOutlineEnergySavingsLeaf style={IconStyle} />,
        Electricity: <MdElectricBolt style={IconStyle} />,
        Tarrif: <MdOutlineCurrencyPound style={IconStyle} />,
        PipeDrive: <PipeDriveIcon width={30} height={28} />,
        LiveMeters: <BsSpeedometer style={IconStyle} />,
        Map: <FaMapMarkerAlt style={IconStyle} />,
        Ethex: <AiOutlinePound style={IconStyle} />,
        TeacherPortal: <IoAnalyticsOutline style={IconStyle} />
    }
    return (<>
        <div className="card-header">
            <div>
                <div >
                    <Text className="card-header--title" >{title}</Text>
                </div>
            </div>
            <div style={{ marginRight: "0.25rem", marginTop: "0.25rem" }}>{Icons[iconName]}</div>
        </div>
    </>)
}

export default CardHeader;