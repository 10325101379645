import React, { CSSProperties, useEffect } from 'react'
import Loader from '../common/Loader'
import { useDispatch } from 'react-redux'
import { fetchAllRegions } from '../../../store/admin/costSummary/actions'
import CostSummaryFilter from './CostSummaryFilter'

const CostSummary = () => {

    const dispatch = useDispatch()

    const overAllLayout: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
    }


    useEffect(() => {
        dispatch(fetchAllRegions())
    }, [])

    return (
        <div style={overAllLayout}>
            <Loader />
            <div>
                <header className="table-header">
                    <h1>Cost Summary</h1>
                </header>
            </div>
            <CostSummaryFilter />
        </div>
    )
}

export default CostSummary
