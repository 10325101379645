import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { IMeterReading } from "@solarforschools/sfs-core/dist/solardb/types";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

export const getMeterReadingsApi = (query: any): Promise<any> => {
  return axios.get(`${host}/meter-reading?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}
export const createMeterReadingAPi = (data: IMeterReading): Promise<any> => {
  return axios
    .post(`${host}/meter-reading`, data)
    .then((res) => res.data);
};

export const updateMeterReadingApi = (data: IMeterReading): Promise<any> => {
  return axios
    .put(`${host}/meter-reading`, data)
    .then((res) => res.data);
};

export const fillMeterReadingApi = (data: any): Promise<any> => {
  return axios
    .post(`${host}/meter-reading/fill`, data)
    .then((res) => res.data);
};

export const deleteMeterReadingApi = (data: IMeterReading): Promise<any> => {
  return axios
    .delete(`${host}/meter-reading/${data._id}`)
    .then((res) => res.data);
};

export const getFitReadingsApi = (query: any): Promise<any> => {
  return axios.get(`${host}/meter-reading/fit?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}

export const exportFitReadingsApi = (query: any): Promise<any> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/meter-reading/fit-export?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}

export const exportReadingsApi = (query: any): Promise<any> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/meter-reading/export?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}


export const importMeterReadingApi = (formData: FormData): Promise<any[]> => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(`${host}/meter-reading/import-reading`, formData, config).then(res => res.data)
}
export const deleteMultipeMeterReadingApi = (data: IMeterReading[]): Promise<any> => {
  return axios
    .put(`${host}/meter-reading/delete/bulk`, { ids: data.map(d => d._id) })
    .then((res) => res.data);
};
