import { IColumn, TooltipHost } from "@fluentui/react";
import { IPortfolio } from "@solarforschools/sfs-core/dist/types";
import moment from "moment";
import { isSortable } from "../../common/utils";

export const getPotfolioColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'school',
            name: 'School',
            fieldName: 'school',
            minWidth: 200,
            maxWidth: 300,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.school} closeDelay={500}> {item.school}</TooltipHost>
        },
        {
            key: 'owner',
            name: 'owner',
            fieldName: 'owner',
            minWidth: 80,
            maxWidth: 80,
            ...isSortable
        },
        {
            key: 'commissioned',
            name: 'Commissioned',
            fieldName: 'commissioned',
            minWidth: 100,
            ...isSortable,
            onRender: (item: any) => <TooltipHost closeDelay={500}> {item.commissioned === "TBD" && item.commissioned || moment(item.commissioned).format("YYYY-MM-DD") as any}</TooltipHost>
        },
        {
            name: 'yearlyPerformance',
            key: 'yearlyPerformance',
            fieldName: 'yearlyPerformance',
            minWidth: 120,
            ...isSortable,
            onRender: (item: IPortfolio) => ((item.yearlyPerformance || 0) * 100).toFixed(3)
        },
        {
            name: 'lifetimePerformance',
            key: 'lifetimePerformance',
            fieldName: 'lifetimePerformance',
            minWidth: 120,
            ...isSortable,
            onRender: (item: IPortfolio) => ((item.lifetimePerformance || 0) * 100).toFixed(3)
        },
        {
            name: 'yearlyT',
            key: 'yearlyT',
            fieldName: 'yearlyT',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IPortfolio) => <TooltipHost content={item.yearlyT?.toString() || ''} closeDelay={500}> {item.yearlyT}</TooltipHost>
        },
        {
            name: 'lifetimeG',
            key: 'lifetimeG',
            fieldName: 'lifetimeG',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IPortfolio) => <TooltipHost content={item.lifetimeG?.toString() || ''} closeDelay={500}> {item.lifetimeG}</TooltipHost>
        },
        {
            name: 'lifetimeT',
            key: 'lifetimeT',
            fieldName: 'lifetimeT',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IPortfolio) => <TooltipHost content={item.lifetimeT?.toString() || ''} closeDelay={500}> {item.lifetimeT}</TooltipHost>
        },
        {
            name: 'yearlyG',
            key: 'yearlyG',
            fieldName: 'yearlyG',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IPortfolio) => <TooltipHost content={item.yearlyG?.toString() || ''} closeDelay={500}> {item.yearlyG}</TooltipHost>
        },
        {
            name: 'yearlyT',
            key: 'yearlyT',
            fieldName: 'yearlyT',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IPortfolio) => <TooltipHost content={item.yearlyT?.toString() || ''} closeDelay={500}> {item.yearlyT}</TooltipHost>
        },
        {
            name: 'yearlyStartDate',
            key: 'yearlyStartDate',
            fieldName: 'yearlyStartDate',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IPortfolio) => <TooltipHost content={item.yearlyStartDate ? moment(item.yearlyStartDate).format("YYYY-MM-DD") : ''} closeDelay={500}> {item.yearlyStartDate ? moment(item.yearlyStartDate).format("YYYY-MM-DD") : ''}</TooltipHost>
        },
        {
            name: 'yearlyEndDate',
            key: 'yearlyEndDate',
            fieldName: 'yearlyEndDate',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IPortfolio) => <TooltipHost content={item.yearlyEndDate ? moment(item.yearlyEndDate).format("YYYY-MM-DD") : ''} closeDelay={500}> {item.yearlyEndDate ? moment(item.yearlyEndDate).format("YYYY-MM-DD") : ''}</TooltipHost>
        },

    ];
}

