import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { toast } from "react-toastify";
import { validateWorkflowIdAPi } from "../../../store/client/workflow";
import { IField } from "../../dynamic-form/DynamicForm";
import { Regions, requiredMessage, WorkflowTypes } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { stages } from "../schools/common";
import { IWorkflow, IWorkflowTask } from "@solarforschools/sfs-core/dist/workflow/types";

export const Relationships = [
    { key: "all", text: "All" },
    { key: "FS", text: "Finish to Start" },
    { key: "SS", text: "Start to Start" },
    { key: "FF", text: "Finish to Finish" }];

export const getWrokflowFields = (options: StringMap): IField[] => {
    return [
        {
            label: "ID",
            key: "id",
            type: "string",
            extras: { required: true, ...options.workflow._id ? { disabled: true } : {} },
            placeholder: "Workflow ID",
            className: 'ms-Grid-col ms-lg2'
        }, {
            label: "Name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "Name",
            className: 'ms-Grid-col ms-lg2'
        }, {
            label: "Stage",
            key: "stage",
            type: "dropdown",
            extras: { required: true },
            options: stages.filter(s => s.key !== 'all'),
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Type",
            key: "type",
            type: "dropdown",
            extras: { required: true },
            options: WorkflowTypes.filter(w => w.key !== 'all'),
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Region",
            key: "region",
            type: "dropdown",
            extras: { required: true },
            options: Regions.map((r: any) => { return { ...r, key: r.key.toLowerCase() } }),
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Active",
            key: "active",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg'
        },

    ]
};
export const validateWorkflow = async (workflow: IWorkflow) => {
    let errors: StringMap = {};
    const {
        id,
        name,
        stage,
        type,
        region,
        tasks
    } = workflow || {}
    if (!id) errors['id'] = requiredMessage;
    if (!name) errors['name'] = requiredMessage;
    if (!stage) errors['stage'] = requiredMessage;
    if (!type) errors['type'] = requiredMessage;
    if (!region) errors['region'] = requiredMessage;
    if (!tasks || tasks.length === 0) {
        toast.error('Tasks can not be empty!')
        errors['tasks'] = requiredMessage;
    }
    if (!Object.keys(errors).length) {
        errors = await validateWorkflowIdAPi(workflow) as unknown as StringMap
    }
    return errors;
}

export const initWorkflow: IWorkflow = {
    id: '',
    name: '',
    regions: [],
    region: 'all',
    active: false,
    type: 'School',
    stage: 'Prospect',
    tasks: [],
}
export const getWorkflowColumns = (params: {
    actions: {
        icon: string,
        onClick: (workflow: IWorkflow) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'id',
            name: 'Id',
            fieldName: 'id',
            minWidth: 150,
            maxWidth: 300,
            ...isSortable,
            onRender: (item: IWorkflow) => <TooltipHost content={item.id} closeDelay={500}> {item.id}</TooltipHost>
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IWorkflow) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            key: 'type',
            name: 'Type',
            fieldName: 'type',
            minWidth: 100,
            maxWidth: 200,
            ...isSortable
        },
        {
            key: 'active',
            name: 'Active',
            fieldName: 'active',
            minWidth: 100,
            maxWidth: 200,
            ...isSortable
        },
        {
            key: 'region',
            name: 'Region',
            fieldName: 'region',
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IWorkflow) => item.region
        },
        {
            key: 'regions',
            name: 'Regions',
            fieldName: 'regions',
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IWorkflow) => item.regions.join(',')
        },
        {
            key: 'stage',
            name: 'Stage',
            fieldName: 'stage',
            minWidth: 100,
            maxWidth: 200,
            ...isSortable
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (workflow: IWorkflow) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: IWorkflow) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(workflow)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export const getWorkflowTasksColumns = (params: {
    actions: {
        icon: string,
        onClick: (task: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'id',
            name: 'Id(Task)',
            fieldName: 'id',
            minWidth: 150,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.id} closeDelay={500}> {item.id}</TooltipHost>
        },
        {
            name: 'Predecessor',
            key: 'relationship.predecessor',
            fieldName: 'relationship.predecessor',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IWorkflowTask) => <TooltipHost content={item.relationship?.predecessor || ''} closeDelay={500}> {item.relationship.predecessor}</TooltipHost>
        },
        {
            key: 'relationship.relationship',
            name: 'Relationship',
            fieldName: 'relationship.relationship',
            ariaLabel: "Relationship",
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.relationship?.relationship} closeDelay={500}> {item.relationship?.relationship}</TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (workflowtask) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(workflowtask)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}
