import { ChoiceGroup, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IBondHolderState } from './../../../../store/admin/funder/bondHolder/reducer';
import { getBondHolders, setBondHolderFilter } from './../../../../store/admin/funder/bondHolder/action';
import { RootState } from '../../../../store';
import { StringMap } from '../../common/types';
import { bondHolderStatus } from './BondHolderHelper';

const BondHolderFilters = (props: IBondHolderFilterProps) => {
    const dispatch = useDispatch()
    const { bondHolderFilter }: IBondHolderState = useSelector<RootState, IBondHolderState>((state) => state.web.bondHolder)
    const setFilters = (filters: StringMap) => dispatch(setBondHolderFilter(filters));

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        const params = { ...bondHolderFilter, page: 1, [key]: value };
        setFilters(params);
        if (value.length === 0 || value.length > 2 || (key === "country" && value.length > 1))
            handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback(
        (filters: any) => {
            dispatch(getBondHolders(filters));
        },
        []
    );

    const { name, email, status, country, } = bondHolderFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Name"
                    placeholder="Enter Bond Holder Name"
                    onChange={(e, value) => handleOnChange('name', value || '')}
                    className="ms-Grid-col ms-lg5"
                    value={name!} />
                <TextField
                    label="Email/UserName"
                    placeholder="Enter Email"
                    onChange={(e, value) => handleOnChange('email', value || '')}
                    className="ms-Grid-col ms-lg5"
                    value={email!} />
                <TextField
                    label="Country"
                    placeholder="Enter Country"
                    onChange={(e, value) => handleOnChange('country', value || '')}
                    className="ms-Grid-col ms-lg2"
                    value={country!} />
                {/* <Dropdown
                    dropdownWidth='auto'
                    label="Region"
                    onChange={(e, item) => handleOnChange("country", item?.key?.toString())}
                    selectedKey={country || 'all'}
                    options={Regions}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg2"
                /> */}
                <ChoiceGroup
                    className="inlineflex ms-Grid-col ms-lg6"
                    label="Status"
                    selectedKey={status || "all"}
                    onChange={(e, option) => handleOnChange("status", option?.key)}
                    options={bondHolderStatus}
                />

            </div>
        </div>
    );
}

interface IBondHolderFilterProps {

}

export default BondHolderFilters;

