import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardFooter from '../layouts/CardFooter'
import CardHeader from '../layouts/CardHeader'
import RegistrationsCardContent from "./contents/RegistrationsCardContent"
import { BackFooter as RegistrationBackFooter, FrontFooter as RegistrationFrontFooter } from './footer/RegistrationsFlipFooter'

const Registrations = (props: Partial<IAdminDashboard>) => {

    if(props?.registrations === undefined) return <></>
    
    return (
        <div className="dashboard-layout--users">
            <Card registrationData={props?.registrations}>
                <CardHeader title="Registrations" iconName="Users" />
                <CardContent title="Overall Registrations" contents={RegistrationsCardContent} />
                <CardFooter variant='Flipper' frontFooter={RegistrationFrontFooter} backFooter={RegistrationBackFooter} />
            </Card>
        </div>
    )
}

export default Registrations