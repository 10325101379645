/* eslint-disable react/jsx-no-undef */
import { useEffect, useState } from 'react';
import { ComboBox, IComboBoxOption, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider } from 'react-redux';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import ReactDOM from 'react-dom';
import store from '../../../store';
import ModalDialog from '../common/ModalDialog';
import { Relationships } from './helper';
import { IWorkflowTask } from '@solarforschools/sfs-core/dist/workflow/types';
import _ from 'lodash';
import { IWorkflow } from "@solarforschools/sfs-core/dist/workflow/types";
import { getTaskTypesApi } from '../../../store/client/taskType';
import { ITaskType } from '@solarforschools/sfs-core/dist/taskType/types';
import { comboBoxStyles, requiredMessage } from '../common/common';

export const OpenWrokflowTaskModal = (props: any) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add Edit Workflow Task"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-md"
                >
                    <WorkflowTaskDialog {...props} onSave={(workflow: IWorkflow) => callback(workflow)} onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export function WorkflowTaskDialog(props: Props) {
    const [errors, setErrors] = useState<any>({});
    const [workflowTask, setWorkflowTask] = useState<IWorkflowTask>({ id: '', relationship: { predecessor: null, relationship: 'FS' } });
    const [taskTypes, setTaskTypes] = useState<any>([]);
    const [predecessors, setPredecessors] = useState<any>([]);

    const loadData = async () => {
        const { taskTypes }: any = await getTaskTypesApi({ taskType: props.workflow?.type, all: true })
        const data = taskTypes.map((t: ITaskType) => { return { key: t.slug, text: t.name } })
        setTaskTypes(data)
        const slugs: string[] = props.workflow?.tasks.map((t: IWorkflowTask) => t.id) || []
        const predecessors = data.filter((t: any) => slugs.includes(t.key))
        setPredecessors(predecessors)
    }

    useEffect(() => {

        loadData();
    }, [])

    const handleOnChangeInput = (key: string, value: any) => {
        const workflowT = _.set({ ...workflowTask }, key, value)
        setWorkflowTask(workflowT)
    };

    const handleOnSave = async () => {
        if (!workflowTask.id) {
            setErrors({ id: requiredMessage });
            return;
        }
        const tasks = [...props.workflow?.tasks || []]
        tasks.push(workflowTask)
        props.onSave({ ...props.workflow, tasks } as IWorkflow)
    };

    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <section className='margin-top-md'>
                    <div className="ms-Grid-row">
                        <ComboBox
                            label="Id"
                            placeholder="Select task"
                            selectedKey={workflowTask?.id || null}
                            options={taskTypes || [] as IComboBoxOption[]}
                            onChange={(e, item) => handleOnChangeInput('id', item?.key)}
                            styles={comboBoxStyles}
                            errorMessage={errors?.id}
                            className="ms-Grid-col ms-lg4"
                        />
                        <ComboBox
                            label="Predecessor"
                            placeholder="Select task"
                            selectedKey={workflowTask?.relationship.predecessor || null}
                            options={[{ key: null, text: 'Select' }, ...predecessors].filter((t: { key: string; }) => t.key !== workflowTask.id) || [] as IComboBoxOption[]}
                            onChange={(e, item) => handleOnChangeInput('relationship.predecessor', item?.key)}
                            styles={comboBoxStyles}
                            className="ms-Grid-col ms-lg4"
                        />
                        <ComboBox
                            label="RelationShip"
                            placeholder="Select Relationship"
                            selectedKey={workflowTask.relationship.relationship || null}
                            options={Relationships.filter(r => r.key !== 'all') as IComboBoxOption[]}
                            onChange={(e, item) => handleOnChangeInput('relationship.relationship', item?.key)}
                            styles={comboBoxStyles}
                            className="ms-Grid-col ms-lg4"
                        />
                    </div>
                </section>
            </div>
            <div className="ms-Grid" dir="ltr">
                <section className='margin-top-md'>
                    <div className="ms-Grid-row">

                    </div>
                </section>
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item className="margin-top-lg">
                        <PrimaryButton text="Save" onClick={handleOnSave} className='btn-primary' />
                        <DefaultButton onClick={props.onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
}

interface OwnProps {
    workflow?: IWorkflow;
    workflowTask?: IWorkflowTask;
    onCancel?: () => void;
    onSave: (workflow: IWorkflow) => void
}

type Props = OwnProps;
