import React from "react";
import "./CTemplate.css";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import { RootState } from "../../../../store/";
import { changeContentTemplate } from "../../../../store/content/action";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import {
  ContentItems,
  IContentItems,
  IContentTemplate,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import CFlip from "./CFlip";

const C6 = ({ content, changeContentTemplate, handleSubmit }: Props) => {
  let moduleID: string, unitID: string;
  if (content.screen?.moduleID && content.screen?.unitID) {
    moduleID = content.screen.moduleID;
    unitID = content.screen.unitID;
  }

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const handleTextbox1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    addContent(ContentItems.TITLE, e.target.value, 1);
  };

  const handleTextarea1Change = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    addContent(ContentItems.PARAGRAPH, e.target.value, 2);
  };

  const addContent = (
    contentType: ContentItems,
    value: string,
    sequence: number
  ) => {
    let contentItem: IContentItems = {
      type: contentType,
      sequence,
      text: value,
    };

    dispatchContent(contentItem, sequence);
  };

  const dispatchContent = (contentItem: IContentItems, sequence: number) => {
    let contentTemplate: IContentTemplate = {
      isEditing: !!content.contentTemplate?.isEditing,
      contentId: content.contentTemplate?.contentId,
      module: moduleID,
      unit: unitID,
      template: TemplateItems.C6,
      order: 0,
      items:
        content.contentTemplate?.items !== undefined
          ? content.contentTemplate?.items
          : [],
    };

    let newContent = contentTemplate.items?.filter(
      (temp) => temp.sequence !== sequence
    );

    if (newContent) {
      newContent.push(contentItem);
      contentTemplate.items = newContent;
    }

    changeContentTemplate(contentTemplate);
  };

  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, height: "50%", paddingTop: 0 }}
        >
          <Textbox
            placeholder="Enter the topic here"
            maxChar={30}
            onChange={handleTextbox1Change}
            width="100%"
            value={
              content.contentTemplate?.items?.find(
                (item) => item.sequence === 1
              )?.text
            }
          />
          <Textarea
            placeholder="Enter your content here"
            value={
              content.contentTemplate?.items?.find(
                (item) => item.sequence === 2
              )?.text
            }
            onChange={handleTextarea1Change}
            maxChar={500}
            width="100%"
            height="25%"
          />
        </div>
        <div id="sub-heading">
          <CFlip
            template={TemplateItems.C6}
            content={content}
            seq={[3, -1, 4, 5]}
            dispatchContent={dispatchContent}
          />
        </div>
        <div id="sub-heading">
          <CFlip
            template={TemplateItems.C6}
            content={content}
            seq={[6, -1, 7, 8]}
            dispatchContent={dispatchContent}
          />
        </div>
      </aside>
      <aside>
        <p>
          Use the textboxes on the screen to add your content. Drag and drop
          relevant images (or .json animations) into the area encased in dotted
          lines, or click "add visual" where appropriate.
        </p>
        <Button
          width="20%"
          height="8%"
          name="Submit"
          fontSize="1.5rem"
          onClick={(e: React.MouseEvent<HTMLButtonElement> | null) =>
            handleSubmit(e, 8)
          }
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeContentTemplate: (contentTemplate: IContentTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    noOfItems: number
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeContentTemplate: async (contentTemplate: IContentTemplate) => {
      await dispatch(changeContentTemplate(contentTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(C6);
