import { Action } from "./actionTypes";
import { IFaq } from '@solarforschools/sfs-core/dist/faq/types';
import { IFaqFilter } from "../../../components/admin/faqs/types";

// States' definition
export interface IFaqState {
  isLoading: boolean;
  faqs: IFaq[];
  faqFilter: IFaqFilter;
  totalCount: number;
  sites: any[];
  categories: any[]
}
export interface State {
  faqs: IFaqState;
}

const faqs = (
  state: IFaqState = {
    isLoading: false,
    faqs: [],
    faqFilter: {
      page: 1,
      pageSize: 50,
      sortField: "date",
      sortOrder: "desc",
    },
    totalCount: 0,
    sites: [],
    categories: []
  },
  action: Action
): IFaqState => {
  switch (action.type) {
    case "SET_FAQ_FILTER":
      return { ...state, faqFilter: action.faqFilter };
    case "SET_FAQ_CATEGORIES":
      return { ...state, categories: action.categories };
    case "SET_FAQ_SITES":
      return { ...state, sites: action.sites };
    case "SET_FAQ_LIST":
      const faqs = [...action.faqs];
      return { ...state, faqs, totalCount: action.totalCount };
    default:
      return state;
  }
};

export default faqs;
