import { IAdminDashboard } from '@solarforschools/sfs-core'
import CardContent from '../layouts/CardContent'
import CardHeader from '../layouts/CardHeader'
import Card from "../layouts/index"
import { BackContent, FrontContent } from './contents/CarbonFlipContent'

const CarbonSavings = (props: Partial<IAdminDashboard>) => {
    
    if(props?.carbonSavings === undefined) return <></>

    const overallCarbonSaved = props.carbonSavings
    
    return (
        <div className="dashboard-layout--carbonSavings">
            <Card carbonSavings={overallCarbonSaved} >
                <CardHeader title='Carbon Savings' iconName='CarbonSavings' />
                <CardContent variant='Flipper' frontContent={FrontContent} backContent={BackContent} hasFooter={false} />
            </Card>
        </div>
    )
}

export default CarbonSavings

