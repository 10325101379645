import { useState } from 'react';
import { ActionButton, CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';

import DetailsListWapper from '../../common/DetailsListWapper';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getWorkflowColumns } from './helper';
import { OpenWrokflowModal } from './WorkflowDialog';
import { IWorkflowState } from '../../../store/admin/workflow/reducer';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { IWorkflow } from "@solarforschools/sfs-core/dist/workflow/types";
import { deleteProjectWrokflow, getProjectWrokflows, setWorkflowFilter } from '../../../store/admin/workflow/action';

const WorkflowList = () => {
  const dispatch = useDispatch()
  const { workflows, totalCount, workflowFilter }: IWorkflowState = useSelector<RootState, IWorkflowState>((state: RootState) => state.web.projectWorkflow)

  const handleOnDelete = async (workflow: IWorkflow) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete wrokflow",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${workflow.name}`,
      },
    });
    if (confirm) dispatch(deleteProjectWrokflow(workflow));
  };

  const handleOnEdit = async (projectWorkflow: IWorkflow) => {
    await OpenWrokflowModal({ projectWorkflow })

  };

  const headerRow = getWorkflowColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        onClick: handleOnDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...workflowFilter, page: 1, sortField: field, sortOrder: order };
    dispatch(setWorkflowFilter(params));
    dispatch(getProjectWrokflows(params))
  }

  const loadNextPage = () => {
    const { page = 0 } = workflowFilter
    dispatch(setWorkflowFilter({ ...workflowFilter, page: page + 1 }))
    dispatch(getProjectWrokflows(undefined, false))
  }

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}>
            {totalCount > 0 && (<>Displaying &nbsp; {workflows.length}/ {totalCount}</>)}
          </span>
        </div>

        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () => await OpenWrokflowModal({})}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp; Add WrokFlow
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={workflows}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={workflows.length < totalCount}
                onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
}

export default WorkflowList;

