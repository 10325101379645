import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";



export const Footer = () => {
    const { liveMetersData } = useContext(CardContext) as CardContextType;
    const [status, setStatus] = useState({ prevDay: "", isEqual: "" });



    function getStatus() {
        if (liveMetersData !== undefined) {
            const { lastTwoDays } = liveMetersData;
            const yesterday = lastTwoDays.length - 2
            const today = lastTwoDays.length - 1

            if (lastTwoDays[yesterday].count > lastTwoDays[today].count) {
                setStatus({ ...status, prevDay: "high", isEqual: "" });
            } else if (lastTwoDays[yesterday].count < lastTwoDays[today].count) {
                setStatus({ ...status, prevDay: "low", isEqual: "" });
            } else {
                setStatus({ ...status, isEqual: "yes", prevDay: "" });
            }

        }
    }
    useEffect(() => {
        getStatus()
    }, [liveMetersData])


    if (liveMetersData === undefined) {
        return <></>
    }



    const prevMonthStyle = { color: "gray", fontSize: "0.85rem" }
    const { lastTwoDays } = liveMetersData
    const yesterday = lastTwoDays.length - 2
    const today = lastTwoDays.length - 1

    const prevDay = lastTwoDays[yesterday].day
    const currDay = lastTwoDays[today].day
    const prevDayCount = lastTwoDays[yesterday].count
    const currDayCount = lastTwoDays[today].count

    const prevCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevDay === "high" ? "green" : "red"))
    const currCountStyle = ((status.isEqual === "yes") ? "#FFCB2F" : (status?.prevDay === "high" ? "red" : "green"))

    return <>
        <div className="flex-row-center" style={{ fontSize: "1rem", alignItems: "end", paddingTop: "1rem" }}>

            <div className="flex-column-center" >
                <div className="flex-row-center">
                    <div style={{ color: prevCountStyle }}>
                        {prevDayCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevDay === "high"
                            ? <UpTriangleIcon />
                            : <DownTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{prevDay}</div>
            </div>
            <div className="vs-style">
                vs
            </div>

            <div className="flex-column-center">
                <div className="flex-row-center" >
                    <div style={{ color: currCountStyle }}>
                        {currDayCount}
                    </div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon />
                        : status?.prevDay === "high"
                            ? <DownTriangleIcon />
                            : <UpTriangleIcon />}
                </div>
                <div style={prevMonthStyle}>{currDay}</div>
            </div>
        </div>
    </>
}