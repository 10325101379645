/* eslint-disable react/jsx-no-undef */
import { useMemo, useState } from 'react';
import { CheckboxVisibility, DetailsList, IColumn, SelectionMode, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider, useDispatch } from 'react-redux';
import { PrimaryButton, DefaultButton, ActionButton } from '@fluentui/react/lib/Button';
import ReactDOM from 'react-dom';
import store from '../../../store';
import ModalDialog from '../common/ModalDialog';
import DynamicForm from '../../dynamic-form/DynamicForm';
import { StringMap } from '../common/types';
import { getWorkflowTasksColumns, getWrokflowFields, initWorkflow, validateWorkflow } from './helper';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import _ from 'lodash';
import { createUpdateWorkflow } from '../../../store/admin/workflow/action';
import { IWorkflow } from "@solarforschools/sfs-core/dist/workflow/types";
import { OpenWrokflowTaskModal } from './WorkflowTaskDialog';

export const OpenWrokflowModal = (props: any) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add Edit Workflow"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-md"
                >
                    <WorkflowDialog {...props} onSave={() => callback(undefined)} onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export function WorkflowDialog({ projectWorkflow, onCancel, onSave }: Props) {

    const dispatch = useDispatch()
    const [errors, setErrors] = useState<any>({});
    const [workflow, setWorkflow] = useState<IWorkflow>({ ...projectWorkflow || initWorkflow } as IWorkflow);

    const workflowFields = useMemo(() => getWrokflowFields({ workflow }), [workflow]);

    const handleOnChangeInput = (key: string, item: any) => {
        switch (key) {
            case 'regions':
                let regions = [...workflow.regions]

                if (item?.selected) {
                    if (!regions.find(p => p === item?.key)) {
                        regions.push(item?.key)
                    }
                } else {
                    regions = regions.filter(p => p !== item?.key)
                }
                setWorkflow({ ...workflow, regions });
                break;
            default:
                setWorkflow({ ...workflow, [key]: item as string });
                break;
        }
    };

    const handleOnDelete = async (task: { id: any; }) => {
        const confirm = await ConfirmDialog({
            dialogContentProps: {
                title: "Delete wrokflow Task",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to delete ${task.id}`,
            },
        });
        if (confirm) {
            const tasks = [...workflow.tasks]
            // set predecessor to null where current task was a predecessor
            const _tempIndex = tasks.findIndex((t => t.relationship.predecessor === task.id))
            if (_tempIndex > -1) {
                tasks[_tempIndex].relationship.predecessor = null
            }

            setWorkflow({ ...workflow, tasks: tasks.filter((t: { id: any; }) => t.id !== task.id) })
        }
    };

    const headerRow = getWorkflowTasksColumns({
        actions: [
            {
                icon: "Trash",
                onClick: handleOnDelete
            }
        ]
    })
    const [columns] = useState<IColumn[]>(headerRow)

    const handleOnSave = async () => {
        const data: IWorkflow = { ...workflow, regions: _.filter(workflow.regions) } // remove null regions 
        const errors: StringMap = await validateWorkflow(data);
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        await dispatch(createUpdateWorkflow(workflow));
        onSave()
    };

    const handleOnAddTask = async () => {
        const data: IWorkflow = await OpenWrokflowTaskModal({ workflow }) as IWorkflow
        if (data) setWorkflow({ ...data })
    }

    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <section className='margin-top-md'>
                    <div className="ms-Grid-row">
                        <DynamicForm
                            fields={workflowFields}
                            data={workflow}
                            errors={errors}
                            onChange={handleOnChangeInput} />
                    </div>
                    <div className="ms-Grid-row margin-top-mlg">
                        <span className='ms-Grid-col ms-lg10'></span>
                        <ActionButton className='ms-Grid-col ms-lg2' iconProps={{ iconName: 'Add' }} onClick={handleOnAddTask}>
                            Add Task
                        </ActionButton>
                    </div>
                </section>
            </div>
            <DetailsList
                items={workflow.tasks}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
            />
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item className="margin-top-lg">
                        <PrimaryButton text="Save" onClick={handleOnSave} className='btn-primary' />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
}

interface OwnProps {
    projectWorkflow?: IWorkflow;
    onCancel?: () => void;
    onSave: () => void
}

type Props = OwnProps;
