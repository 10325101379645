import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { IMeterReading, ISystem } from "@solarforschools/sfs-core/dist/solardb/types";
import { IMeterReadingFilter } from "../../../components/admin/meterReading/types";
import { exportFitReadingsApi, getFitReadingsApi, getMeterReadingsApi } from "../../client/meterReading";
import { getSystems } from "../../client/system";
import { IFitReportingFilter } from "../../../components/admin/fitReporting/types";
import { downloadFileFromStream } from "../../../components/admin/common/utils";

// Action Creators

export const setFitReportingFilter = (fitReportingFilter: IFitReportingFilter) => {
  return { type: "SET_FIT_REPORTING_FILTER", fitReportingFilter };
};

export const setSystemList = (systems: any[]) => {
  return { type: "FITREPORTING/SET_SYSTEMS_LIST", systems };
};


export const setFitRportingList = (
  fitReadings: IMeterReading[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_FIT_REPOERTING_LIST", fitReadings, totalCount, reset };
};


export const getFitSystemList =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { systems } = await getSystems({ schoolSlug: 'all', fit: true })
        let data = []
        if (systems.length) data = systems.map((s: { id: any; name: any; provider: any; }) => ({
          key: s.id.toString(),
          text: `${s.name} ${s.provider}`
        }))
        dispatch(setSystemList(data));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const getFitReportingData =
  (params?: IFitReportingFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IMeterReadingFilter =
            getState().web.fitReport.fitReportingFilter;
          reqBody = { ...filters };
        }
        const { fitReadings } = await getFitReadingsApi(reqBody);
        dispatch(setFitRportingList(fitReadings, fitReadings.length, true));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const exportFitReadings = (params?: IFitReportingFilter, reset: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const filters: IMeterReadingFilter =
          getState().web.fitReport.fitReportingFilter;
        reqBody = { ...filters };
      }
      const data: string = await exportFitReadingsApi(reqBody);
      downloadFileFromStream({ data, filename: "fit-reading-report", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };


