import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { changeContentTemplate } from "../../../../store/content/action";
import {
  ContentItems,
  IContentItems,
  IContentTemplate,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { IContent } from "../../../../store/content/reducer";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import UploadWrapper from "../formElements/UploadWrapper";
import "./CTemplate.css";
import { getName, getImageURL } from "../util";

const C8 = ({ content, changeContentTemplate, handleSubmit }: Props) => {
  const maxImages = 25;
  const sectionOneStart = 4;
  const sectionTwoStart = 31;
  const [selectedSequence, setSelectedSequence] = useState<number>(-1);
  const [imageUrl, setImageUrl] = useState("");
  const selectedImage = useMemo(() => {
    return content.contentTemplate?.items?.find(
      (item) => item.sequence === selectedSequence
    );
  }, [selectedSequence]);

  const {
    firstSectionImages,
    secondSectionImages,
    nextFirstSeq,
    nextSecondSeq,
  } = useMemo(() => {
    const firstSectionImages: IContentItems[] = [];
    const secondSectionImages: IContentItems[] = [];
    let nextFirstSeq = sectionOneStart - 1;
    let nextSecondSeq = sectionTwoStart - 1;
    if (!content.contentTemplate?.items) {
      return { firstSectionImages, secondSectionImages };
    }
    for (const item of content.contentTemplate?.items) {
      if (!item.sequence) continue;
      if (
        item.sequence >= sectionOneStart &&
        item.sequence <= sectionOneStart + maxImages
      ) {
        firstSectionImages.push(item);
        nextFirstSeq = Math.max(item.sequence, nextFirstSeq);
      } else if (
        item.sequence >= sectionTwoStart &&
        item.sequence <= sectionTwoStart + maxImages
      ) {
        secondSectionImages.push(item);
        nextSecondSeq = Math.max(item.sequence, nextSecondSeq);
      }
    }
    nextFirstSeq += 1;
    nextSecondSeq += 1;
    return {
      firstSectionImages,
      secondSectionImages,
      nextFirstSeq,
      nextSecondSeq,
    };
  }, [content.contentTemplate?.items]);

  let moduleID: string, unitID: string;
  if (content.screen?.moduleID && content.screen?.unitID) {
    moduleID = content.screen.moduleID;
    unitID = content.screen.unitID;
  }

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const handleAddImages = (files: FileList, startSeq: number) => {
    const items = [];
    let seq = startSeq;
    const maxSeq =
      startSeq < sectionTwoStart
        ? sectionOneStart + maxImages
        : sectionTwoStart + maxImages;
    for (let i = 0; i < files.length; i++) {
      const contentItem: IContentItems = {
        type: ContentItems.IMAGE,
        sequence: seq++,
        text: "",
        file: files[i],
        fileName: files[i]?.name,
        imageProperties: [
          {
            type: ContentItems.PARAGRAPH,
            sequence: 1,
            text: "",
          },
          {
            type: ContentItems.SUBSUBHEADING,
            sequence: 2,
            text: "",
          },
          {
            type: ContentItems.PARAGRAPH,
            sequence: 3,
            text: "",
          },
        ],
      };
      items.push(contentItem);
    }
    const temp = {
      ...content.contentTemplate,
      isEditing: !!content.contentTemplate?.isEditing,
      contentId: content.contentTemplate?.contentId,
      items: [...(content.contentTemplate?.items || []), ...items],
    };
    changeContentTemplate(temp);
  };

  const handleChangeImageProperty = (
    sequence: number,
    subSequence: number,
    value: string
  ) => {
    const temp = content.contentTemplate?.items?.find(
      (item) => item.sequence === sequence
    );
    if (!temp) return;
    temp.imageProperties = temp.imageProperties?.map((item) => {
      return {
        ...item,
        text: subSequence === item.sequence ? value : item.text,
      };
    });
    dispatchContent(temp, sequence);
  };

  const handleTextbox1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    addContent(ContentItems.TITLE, e.target.value, 1);
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    addContent(ContentItems.PARAGRAPH, e.target.value, 2);
  };

  const handleTextbox2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    addContent(ContentItems.SUBHEADING, e.target.value, 3);
  };

  const handleTextbox3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    addContent(ContentItems.SUBHEADING, e.target.value, sectionTwoStart - 1);
  };

  const addContent = (
    contentType: ContentItems,
    value: string,
    sequence: number
  ) => {
    let contentItem: IContentItems = {
      type: contentType,
      sequence,
      text: value,
    };

    dispatchContent(contentItem, sequence);
  };

  const dispatchContent = (contentItem: IContentItems, sequence: number) => {
    let contentTemplate: IContentTemplate = {
      isEditing: !!content.contentTemplate?.isEditing,
      contentId: content.contentTemplate?.contentId,
      module: moduleID,
      unit: unitID,
      template: TemplateItems.C8,
      order: 0,
      items: content.contentTemplate?.items || [],
    };

    let newContent = contentTemplate.items?.filter(
      (temp) => temp.sequence !== sequence
    );

    if (newContent) {
      newContent.push(contentItem);
      contentTemplate.items = newContent;
    }

    changeContentTemplate(contentTemplate);
  };

  const handleRemoveImage = (sequence: number) => {
    const items = [...(content.contentTemplate?.items || [])];
    const index = items.findIndex((item) => item.sequence === sequence);
    items.splice(index, 1);
    changeContentTemplate({
      ...content.contentTemplate,
      isEditing: !!content.contentTemplate?.isEditing,
      contentId: content.contentTemplate?.contentId,
      items,
    });
  };

  const { submitButtonText } = getName(content, -1);

  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
          style={{ marginTop: 5 }}
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, height: "50%", paddingTop: 0 }}
        >
          <Textbox
            placeholder="Enter the topic here"
            maxChar={30}
            onChange={handleTextbox1Change}
            width="100%"
            value={
              content.contentTemplate?.items?.find(
                (item) => item.sequence === 1
              )?.text
            }
          />
          <Textarea
            placeholder="Enter your content here"
            value={
              content.contentTemplate?.items?.find(
                (item) => item.sequence === 2
              )?.text
            }
            onChange={handleTextareaChange}
            maxChar={500}
            width="100%"
            height="100px"
          />
        </div>
        <Textbox
          placeholder="Enter Sub heading"
          maxChar={30}
          onChange={handleTextbox2Change}
          width="100%"
          margin="1rem 0 0 0"
          value={
            content.contentTemplate?.items?.find((item) => item.sequence === 3)
              ?.text
          }
        />
        <UploadButton
          onSelectFiles={(files) => handleAddImages(files, nextFirstSeq!)}
        />
        <div id="fileUpload" style={{ height: 200 }}>
          {firstSectionImages.map((image, index) => {
            const active = selectedSequence === image.sequence;
            const imageURL = getImageURL(
              content,
              image.sequence ? image.sequence : -1
            );
            return (
              <ImageThumbnail
                key={index}
                imageFile={imageURL!}
                onRemove={() => handleRemoveImage(image.sequence!)}
                active={active}
                onClick={() =>
                  setSelectedSequence(active ? -1 : image.sequence!)
                }
              />
            );
          })}
        </div>
        <Textbox
          placeholder="Enter Sub heading"
          maxChar={30}
          onChange={handleTextbox3Change}
          width="100%"
          margin="1rem 0 0 0"
          value={
            content.contentTemplate?.items?.find(
              (item) => item.sequence === sectionTwoStart - 1
            )?.text
          }
        />
        <UploadButton
          onSelectFiles={(files) => handleAddImages(files, nextSecondSeq!)}
        />
        <div id="fileUpload" style={{ height: 200 }}>
          {secondSectionImages.map((image, index) => {
            const active = selectedSequence === image.sequence;
            const imageURL = getImageURL(
              content,
              image.sequence ? image.sequence : -1
            );
            return (
              <ImageThumbnail
                key={index}
                imageFile={imageURL!}
                onRemove={() => handleRemoveImage(image.sequence!)}
                active={active}
                onClick={() =>
                  setSelectedSequence(active ? -1 : image.sequence!)
                }
              />
            );
          })}
        </div>
      </aside>
      {selectedImage ? (
        <div className="card-preview">
          <div style={{ fontSize: 30, fontWeight: "bold" }}>
            {
              content.contentTemplate?.items?.find(
                (item) => item.sequence === 3
              )?.text
            }
          </div>
          <Textbox
            placeholder="Enter paragraph"
            maxChar={30}
            onChange={(event) =>
              handleChangeImageProperty(selectedSequence, 1, event.target.value)
            }
            width="100%"
            margin="0.5rem 0"
            value={
              selectedImage.imageProperties?.find((item) => item.sequence === 1)
                ?.text
            }
          />
          <Textbox
            placeholder="Enter Sub Sub heading"
            maxChar={30}
            onChange={(event) =>
              handleChangeImageProperty(selectedSequence, 2, event.target.value)
            }
            width="100%"
            margin="0.5rem 0"
            value={
              selectedImage.imageProperties?.find((item) => item.sequence == 2)
                ?.text
            }
          />
          <img
            style={{ width: 300, height: 300 }}
            src={
              selectedImage.url
                ? selectedImage.url
                : URL.createObjectURL(selectedImage.file)
            }
            alt=""
          />
          <Textarea
            placeholder="Enter Paragraph"
            value={
              selectedImage.imageProperties?.find((item) => item.sequence == 3)
                ?.text
            }
            onChange={(event) =>
              handleChangeImageProperty(selectedSequence, 3, event.target.value)
            }
            maxChar={100}
            width="100%"
            height="25%"
          />
        </div>
      ) : null}
      <aside>
        <p>
          Use the textboxes on the screen to add your content. Drag and drop
          relevant images (or .json animations) into the area encased in dotted
          lines, or click "add visual" where appropriate.
        </p>
        <Button
          width=""
          height="8%"
          name={submitButtonText}
          fontSize="1.5rem"
          onClick={(e: React.MouseEvent<HTMLButtonElement> | null) =>
            handleSubmit(e, content.contentTemplate?.items?.length!)
          }
        />
      </aside>
    </section>
  );
};

const UploadButton = (props: { onSelectFiles: (files: FileList) => void }) => {
  return (
    <UploadWrapper
      accept="image/*"
      onSelect={(event) => {
        event.target.files && props.onSelectFiles(event.target.files);
      }}
    >
      <Button
        width="150px"
        height="35px"
        name="Add Images"
        fontSize="16px"
        margin="4px 0px"
      />
    </UploadWrapper>
  );
};

const ImageThumbnail = (props: {
  imageFile: string;
  onRemove: () => void;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className="image-thumbnail"
      onClick={props.onClick}
      style={{ background: `url('${props.imageFile}')` }}
    >
      <div className="x-btn" onClick={props.onRemove}>
        X
      </div>
    </div>
  );
};

interface DispatchProps {
  changeContentTemplate: (contentTemplate: IContentTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    noOfItems: number
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeContentTemplate: async (contentTemplate: IContentTemplate) => {
      await dispatch(changeContentTemplate(contentTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(C8);
