import { IUserSkill } from "@solarforschools/sfs-core/dist/user/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

export const getUserSkillsApi = (query: any): Promise<any> => {
  return axios
    .get(
      `${host}/user/skill?${generateQueryString(query as ParsedUrlQueryInput)}`
    )
    .then((res) => res.data);
};

export const createUserSkillAPi = (skill: IUserSkill): Promise<any> => {
  return axios.post(`${host}/user/skill`, skill).then((res) => res.data);
};

export const updateUserSkillApi = (userSkill: IUserSkill): Promise<any> => {
  return axios
    .put(`${host}/user/skill/${userSkill._id}`, userSkill)
    .then((res) => res.data);
};

export const deleteUserSkillApi = (userSkill: IUserSkill): Promise<any> => {
  return axios
    .delete(`${host}/user/skill/${userSkill._id}`)
    .then((res) => res.data);
};

export const validateUserSkillApi = (userSkill: IUserSkill): Promise<any> => {
  return axios
    .put(`${host}/user/skill/validate/skill`, userSkill)
    .then((res) => res.data);
};
