import axios from "axios";
import { generateQueryString } from "../../../utils/util";
import { host } from "../../config";
import { IAddLead } from "../../../components/admin/PipedriveLead/type";

export const getPipedriveLeadSourcesApi = (): Promise<any> => {
    return axios.get(`${host}/pipedrive/lead/sources`, {}).then((res) => res.data);
};

export const getPipedriveLeadLabelsApi = (): Promise<any> => {
    return axios.get(`${host}/pipedrive/lead/labels`).then((res) => res.data);
};

export const createLeadApi = (data: IAddLead): Promise<any> => {
    return axios
        .post(`${host}/pipedrive/lead`, data)
        .then((res) => res.data);
};
