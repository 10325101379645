import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardFooter from '../layouts/CardFooter'
import CardHeader from '../layouts/CardHeader'
import { FrontFooter as PipeDriveFrontFooter, BackFooter as PipeDriveBackFooter } from './footer/PipeDriveFlipFooter'
import { FrontContent as PipeDriveFrontContent, BackContent as PipeDriveBackContent } from './contents/PipeDriveFlipContent'

const PipeDrive = (props: Partial<IAdminDashboard>) => {

    if(props?.pipeDrive === undefined) return <></>

    return (
        <div className="dashboard-layout--pipeDrive">
            <Card pipeDrive={props?.pipeDrive} >
                <CardHeader
                    title="Pipedrive"
                    iconName="PipeDrive"
                />
                <CardContent variant='Flipper' frontContent={PipeDriveFrontContent} backContent={PipeDriveBackContent} />
                <CardFooter variant='Flipper' frontFooter={PipeDriveFrontFooter} backFooter={PipeDriveBackFooter} />
            </Card>
        </div>
    )
}

export default PipeDrive