import _ from "lodash";
import { Action } from "./actionTypes";
import { DropdownParams } from "../../../components/admin/common/types";
import { ISchoolsFilter } from "../../../components/admin/schools/types";
import { IEstablishment as IEstablishmentDocument } from "@solarforschools/sfs-core/dist/school/types";

// States' definition
export interface ISchool {
  isLoading: boolean;
  schools: IEstablishmentDocument[];
  schoolsFilter: ISchoolsFilter;
  projects: DropdownParams[];
  users: DropdownParams[];
  countries: DropdownParams[];
  services: string[]
  status: string[]
  // contractFilter: DropdownParams[];
  totalCount: number;
}
export interface State {
  schools: ISchool;
}

const schools = (
  state: ISchool = {
    isLoading: false,
    schoolsFilter: {
      page: 1,
      pageSize: 50,
      sortField: "_id",
      sortOrder: "desc",
      user: "all",
      project: "all",
      filterBy: [],
      stageStatus: 'all',
      status: "all",
    },
    projects: [],
    users: [],
    // contractFilter: [],
    totalCount: 0,
    schools: [],
    countries: [],
    services: [],
    status: []
  },
  action: Action
): ISchool => {
  switch (action.type) {
    case "SET_SCHOOLS_COUNTRIES": {
      return { ...state, countries: action.countries };
    }
    case "SET_SCHOOLS_SERVICES": {
      return { ...state, services: action.services };
    }
    case "SET_SCHOOLS_STATUS": {
      return { ...state, status: action.status };
    }
    case "SET_SCHOOLS_FILTER": {
      return { ...state, schoolsFilter: action.data };
    }
    case "SET_USERS_FILTER": {
      return { ...state, users: action.users };
    }
    case "SET_PROJECTS_FILTER": {
      return { ...state, projects: action.projects };
    }
    case "SET_SCHOOLS": {
      return { ...state, schools: action.schools };
    }
    case "SET_SCHOOLS_TOTAL_COUNT": {
      return { ...state, totalCount: action.totalCount };
    }
    case "DELETE_SCHOOL": {
      const schools = state.schools.filter(
        (s) => s?.slug && s.slug !== action.school.slug
      );
      const filter = { ...state.schoolsFilter };
      const { page, pageSize } = filter;
      const count = state.totalCount - 1
      if (count !== schools.length) schools.push(null as unknown as IEstablishmentDocument)
      return {
        ...state,
        schools,
        totalCount: state.totalCount - 1,
        schoolsFilter: {
          ...filter,
          page: Math.ceil((schools.length / page!) * pageSize!),
        },
      };
    }
    case "SET_SCHOOL_LIST":
      // // let schools = action.replace ? action.schools : [...state.schools, ...action.schools];
      // schools = schools.filter(Boolean)
      // if (schools.length !== action.totalCount) schools.push(null as unknown as IEstablishmentDocument)
      return { ...state, schools: action.schools, totalCount: action.totalCount };

    case "UPDATE_SCHOOL": {
      const schools = [...state.schools];
      const index = schools.findIndex((s) => s?.slug && s.slug === action.school.slug);
      if (index > -1) {
        schools[index] = action.school;
      } else {
        schools.unshift(action.school);
      }
      const count = index > -1 ? state.totalCount : state.totalCount + 1
      if (count !== schools.length) schools.push(null as unknown as IEstablishmentDocument)
      return {
        ...state,
        schools,
        totalCount: count,
      };
    }
    default:
      return state;
  }
};

export default schools;
