import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useState } from "react";
import { getSystems } from "../../../store/client/system";
import { ISystem } from "@solarforschools/sfs-core";
import CustomTagPicker from "./CustomTagPicker";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IBilling } from "../../../store/admin/billing/reducer";
import _ from "lodash";

interface ContractPickerProps {
    contracts?: any[]
    selectedItems?: ITag[],
    onChange: (key: string, items?: ITag[]) => void
}

const BillingContractPicker: FunctionComponent<ContractPickerProps> = ({
    contracts = [],
    selectedItems,
    onChange
}) => {
    const [selectedContract, setSelectedContract] = useState<ITag[]>(selectedItems || [])
    const handleGetSystems = async (
        search: string,
        selectedItems?: ITag[],
    ): Promise<ITag[]> => {
        try {
            const data = _.filter(contracts, (item) => {
                const name = item.text.toLowerCase();
                return name.includes(search.toLowerCase());
            }).map(c => ({ name: c.text, key: c.key }));
            return data
        } catch (error) {
            return [] as ITag[]
        }
    }
    const handleOnChangeInput = async (key: string, value: any) => {
        (value.length) ? setSelectedContract(value) : setSelectedContract([])
        onChange(key, value)
    }

    return (
        <CustomTagPicker
            itemLimit={1}
            label="Contract"
            placeholder="Choose Contract"
            selectionAriaLabel="Choose COntract"
            selectedItems={selectedContract}
            onResolveSuggestions={handleGetSystems}
            onChange={(items?: ITag[]) => handleOnChangeInput('selectedContract', items)} />
    );
}

export default BillingContractPicker;
