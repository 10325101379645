

import { useEffect, useState } from "react";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import {
    ComboBox,
    IComboBoxOption,
    Stack,
    Sticky,
    StickyPositionType,
    TextField,
} from "@fluentui/react";
import "office-ui-fabric-react/dist/css/fabric.css";
import _ from "lodash";
import { IOpportunity, ISubProduct } from "@solarforschools/sfs-core/dist/opportunity/type";
import { IProduct } from "@solarforschools/sfs-core/dist/product/type";
import { comboBoxStyles } from "../common/common";
import { revenueCalc, validateSubProduct } from "./helper";
import { StringMap } from "../common/types";


export const AddSubProduct = ({ opportunity, selectedProductIndex, onCancel, onSave, products }: Props) => {
    const [product, setProduct] = useState(selectedProductIndex ? { ...opportunity.products[selectedProductIndex] } : { id: null, title: null, revenue: 0 });
    const [selectedOpportunity, setOpportunity] = useState(_.cloneDeep(opportunity));
    const [errors, setErrors] = useState<any>({});
    const [originalProduct, setOriginalProduct] = useState<IProduct>() // product from products collection

    const handleOnChangeInput = (key: string, value: any) => {
        let oppProducts = selectedOpportunity.products || []
        switch (key) {
            case 'product':
                const p: any = products.find(p => p.productId === value.key)
                if (p) {
                    const tempProduct = { id: p.productId, title: p.title!, revenue: revenueCalc({ ...p, id: p.productId }, selectedOpportunity, products) }
                    // const index = oppProducts.findIndex((o: { id: any; }) => o.id === p.productId)
                    if (selectedProductIndex) {
                        oppProducts[selectedProductIndex] = tempProduct
                    } else {
                        oppProducts.push(tempProduct)
                    }
                    setProduct(tempProduct)
                    setOriginalProduct(p)
                }
                break;

            default:
                const index = selectedProductIndex || oppProducts.length -1
                if (index !== -1) {
                    oppProducts[index].revenue = value
                    setProduct(oppProducts[index])
                }
                break;
        }

        setOpportunity({ ...selectedOpportunity, products: oppProducts });
    };

    useEffect(() => {
        const p: any = products.find(p => p.productId === product.id)
        if (p) {
            setOriginalProduct(p)
        }
    }, [])

    const handleOnSave = () => {
        const errors: StringMap = validateSubProduct(product as ISubProduct);
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        onSave({ ...selectedOpportunity });
    };
    return (
        <>
            <div className="edit-record">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <ComboBox
                            label="Product"
                            placeholder="Select Product"
                            selectedKey={product?.id || null}
                            options={products.map(p => { return { key: p.productId, text: p.title } }) as IComboBoxOption[]}
                            onChange={(e, item) => handleOnChangeInput('product', item)}
                            styles={comboBoxStyles}
                            errorMessage={errors?.product}
                            className="ms-Grid-col ms-lg4"
                        />
                        {product?.id && (
                            <TextField
                                label={`Revenue [${products[0]?.currency}]`}
                                placeholder=" Enter revenue"
                                type="number"
                                step="1"
                                onChange={(e, value) => handleOnChangeInput('revenue', value && parseFloat(value))}
                                value={product?.revenue?.toString()}
                                errorMessage={errors?.revenue}
                                className="ms-Grid-col ms-lg2"
                            />
                        )}
                        <TextField
                            label="Revenue Fix"
                            value={originalProduct?.revenueFix?.toString()!}
                            disabled
                            className="ms-Grid-col ms-lg2"
                        />

                        <TextField
                            label="Revenue Var"
                            disabled
                            value={originalProduct?.revenueVar?.toString()!}
                            className="ms-Grid-col ms-lg2"
                        />
                        <TextField
                            label="Scaling Type"
                            disabled
                            value={originalProduct?.scalingType!}
                            className="ms-Grid-col ms-lg2"
                        />
                    </div>
                </div>
                <Sticky stickyPosition={StickyPositionType.Footer}>
                    <Stack horizontal horizontalAlign="center">
                        <Stack.Item>
                            <PrimaryButton
                                text="Save"
                                onClick={handleOnSave}
                                className="btn-primary"
                            />
                            <DefaultButton onClick={onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </div>
        </>
    );
};
interface StateProps {
    opportunity: IOpportunity,
    selectedProductIndex?: number;
    products: IProduct[];
}

interface OwnProps {
    onCancel: () => void;
    onSave: (opportunity: IOpportunity) => void;
}

type Props = StateProps & OwnProps;
