import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ComboBox, Dropdown, IComboBoxOption, IDropdownOption, Pivot, PivotItem, Stack, Sticky, StickyPositionType, TextField } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../../store';
import ModalDialog from '../../common/ModalDialog';
import { onRenderPivoteItemLink } from '../../common/PivotItemRenderLink';
import DynamicForm from '../../../dynamic-form/DynamicForm';
import { bondTabs, getDetailFields, getGeneralFields, getOrderFields, getpaymentFields, getTabErrors, validateBond } from './BondHelper';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import moment from 'moment';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import BondTransactions from './BondTransactions';
import { createBondTransactions, createUpdateBond, downloadBondCertificate, reGenrateCertificate } from '../../../../store/admin/funder/bond/action';
import { OpenRepaymentModal } from './BondRepaymentDialog';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { OpenTransferBondModal } from './TransferBondDialog';
import { toast } from 'react-toastify';
import { getBondOfferByIdApi } from '../../../../store/client/funder/bondOffer';
import { IBondOffer } from '@solarforschools/sfs-core/dist/funder/bond-offer/types';


export const OpenAddBondModal = (props: OwnProps) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: any) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Add/Edit Bond"}
          onDismiss={() => callback(undefined)}
        // containerClassName="modal-size-md"
        >
          <AddBondDialog
            {...props}
            onSave={bond => callback(bond)}
            onCancel={() => callback(undefined)} />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

export const AddBondDialog = ({
  bond,
  onSave,
  onCancel,
}: Props) => {
  const dispatch = useDispatch()
  const { bondOffers, bondHolders }: IBondState = useSelector<RootState, IBondState>((state) => state.web.bonds);
  const [selectedBond, setSelectedBond] = useState<IBond>(_.cloneDeep(bond));

  const [errors, setErrors] = useState<any>({});
  const [tabErrors, setTabErrors] = useState({ ...bondTabs });
  const [showActions, setShowActions] = useState<boolean>((bond.status === 'Open' && bond._id) ? true : false)

  // Get tab fields
  const generalFields = useMemo(() => getGeneralFields({ bond, bondOffers, bondHolders }), [selectedBond, bondOffers, bondHolders]);
  const orderFields = useMemo(() => getOrderFields({ bond }), [selectedBond]);
  const detailFields = useMemo(() => getDetailFields({ bond }), [selectedBond]);
  const paymentFields = useMemo(() => getpaymentFields({ bond }), [selectedBond]);

  const onPivotLinkClick = async (props: any) => {
    if ([".$certificate", ".$transactions", ".$log"].includes(props.key)) {
      setShowActions(false)
    } else if (bond.status === 'Open') {
      setShowActions(true)
    }
  };

  const getBondOfferById = async (id: string) => {
    try {
      const { bondOffer } = await getBondOfferByIdApi(id)
      return bondOffer
    } catch (error) {
      toast.error("Something happend wrong")
      return null
    }
  }

  const handleOnChangeInput = async (key: string, value: any) => {
    let bond: IBond = _.cloneDeep(selectedBond);
    if (errors[key]) {
      const err = { ...errors };
      delete err[key];
      const tabErrors = getTabErrors(err, bond);
      setErrors(err);
      setTabErrors(tabErrors);
    }
    switch (key) {
      case "order.unitPrice":
        bond = _.set({ ...bond }, key, value);
        bond.order.value = (bond.order.unitPrice * bond.order.units) || 0
        break;
      case "order.units":
        bond = _.set({ ...bond }, key, value);
        bond.order.value = (bond.order.unitPrice * bond.order.units) || 0
        bond.bondDetails.bondAmount = value
        break;
      case "bondOfferId":
        bond = _.set({ ...bond }, key, value);
        const bondOffer: IBondOffer = await getBondOfferById(value)
        if (bondOffer) {
          bond.bondOfferTitle = bondOffer.title
          bond.bondOfferName = bondOffer.name
          bond.bondDetails.interestRate = bondOffer.batch.interestRate
          bond.bondDetails.issueDate = moment(bondOffer.issueDate).format("YYYY-MM-DD") as unknown as Date
          bond.bondDetails.interestStartDate = moment(bondOffer.batch.interestStartDate).format("YYYY-MM-DD") as unknown as Date
          bond.bondDetails.annualPaymentDate = moment(bondOffer.batch.annualPaymentDate).format("YYYY-MM-DD") as unknown as Date
          bond.bondDetails.repaymentDate = moment(bondOffer.batch.repaymentDate).format("YYYY-MM-DD") as unknown as Date
        }
        // bond.order.value = (bond.order.unitPrice * bond.order.units) || 0
        // bond.bondDetails.bondAmount = value
        break;
      case "bondDetails.issueDate":
      case "bondDetails.interestStartDate":
      case "bondDetails.annualPaymentDate":
      case "bondDetails.repaymentDate":
        const v = moment(value).format("YYYY-MM-DD")
        bond = _.set({ ...bond }, key, v);
        break

      default:
        bond = _.set({ ...bond }, key, value);
        break;
    }
    setSelectedBond(bond);
  };

  const handleSave = async () => {
    const errors = await validateBond(selectedBond);
    if (Object.keys(errors).length) {
      const tabErrors = getTabErrors(errors, selectedBond);
      setErrors(errors);
      setTabErrors(tabErrors);
      return;
    }
    dispatch(createUpdateBond(selectedBond))
    // onSave(selectedBond);
  };

  const handleDownloadCert = (e?: any) => {
    e.preventDefault();
    dispatch(downloadBondCertificate(selectedBond))
  }

  const genBondTransactions = async (bond: IBond) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Create Transaction",
        subText: `Do you want to create Transaction for this bond?`,
      },
      confirmBtnText: 'Generate',
    });
    if (confirm) dispatch(createBondTransactions(bond))

  };

  const handleRepayment = async () => {
    const data: any = OpenRepaymentModal({ bond: selectedBond })
    if (data.success) {
      setSelectedBond({ ...selectedBond, status: "Closed" })
    }
  };

  const handleTransferBond = async () => {
    const data: any = await OpenTransferBondModal({ userName: bond.userName, bondId: bond._id! as unknown as string })
    if (data?.success) {
      setSelectedBond({ ...selectedBond, userName: data.userName })
    }
  };

  const handleRegenCert = async () => {
    const bond: any = await dispatch(reGenrateCertificate(selectedBond))
    setSelectedBond({ ...selectedBond, certificate: bond.certificate })
    dispatch(downloadBondCertificate(selectedBond))
  };

  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <Pivot linkSize="large" onLinkClick={(item) => onPivotLinkClick(item)}>
          <PivotItem
            key="general"
            headerText="General"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.general)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={generalFields}
                data={selectedBond}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
            <div className='ms-Grid-row'>
              <TextField
                label="Notes"
                placeholder=""
                onChange={(e: any, value: any) => handleOnChangeInput('notes', value)}
                multiline={true}
                rows={3}
                className='ms-Grid-col ms-lg12'
                value={selectedBond.notes} />
            </div>
          </PivotItem>
          <PivotItem
            key="order"
            headerText="Order"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.order)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={orderFields}
                data={selectedBond}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
          </PivotItem>
          <PivotItem
            key="detail"
            headerText="Details"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.detail)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={detailFields}
                data={selectedBond}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>

          </PivotItem>
          <PivotItem
            key="paymentPreference"
            headerText="Payment Preference"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.paymentPreference)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={paymentFields}
                data={selectedBond}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
          </PivotItem>
          {selectedBond.certificate?.id &&
            <PivotItem
              key="certificate"
              headerText="Certificate"
              onRenderItemLink={onRenderPivoteItemLink(tabErrors.transactions)}
            >
              <table style={{ width: "100%" }}>
                <thead style={{ backgroundColor: "#2e2e2e", color: 'white' }}>
                  <tr>
                    <th style={{ padding: "5px" }} >Cert Number</th>
                    <th style={{ padding: "5px" }} >Name</th>
                    <th style={{ padding: "5px" }}> Action </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: "5px" }} ><a className="text-primary" onClick={handleDownloadCert} href={`#`} >{selectedBond.certNumber}</a></td>
                    <td >{selectedBond.certificate.name}</td>
                    <td className="text-center text-primary">
                      <a className="text-primary" onClick={handleDownloadCert} href={`#`} >{selectedBond.certificate.path}</a>
                    </td>
                  </tr>
                </tbody>
              </table>

            </PivotItem>
          }
          {selectedBond._id &&
            <PivotItem
              key="transactions"
              headerText="Transactions"
              onRenderItemLink={onRenderPivoteItemLink(tabErrors.transactions)}
            >
              <BondTransactions bond={selectedBond} />
            </PivotItem>
          }
          <PivotItem key="log" headerText="Audit Log">
            <table style={{ width: "100%" }}>
              <thead style={{ backgroundColor: "#2e2e2e", color: 'white' }}>
                <tr>
                  <th style={{ padding: "5px" }}> Action </th>
                  <th style={{ padding: "5px" }} >Date</th>
                </tr>
              </thead>
              <tbody>
                {selectedBond.auditLog.map((a, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ padding: "5px" }}> {a.action}</td>
                      <td style={{ padding: "5px" }}>{moment(a.time).format("MMM DD YYYY")}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </PivotItem>
        </Pivot>
        {showActions &&
          <div className="margin-top-md">
            <Stack horizontal tokens={{ childrenGap: 30 }}>
              <PrimaryButton className="btn-primary" iconProps={{ iconName: "RepeatAll" }} text="Re Generate Certificate" onClick={handleRegenCert} allowDisabledFocus />
              <PrimaryButton className="btn-primary" iconProps={{ iconName: "Switch" }} text="Change BondHolder" onClick={handleTransferBond} allowDisabledFocus />
              <PrimaryButton className="btn-primary" iconProps={{ iconName: "Money" }} text="Re Payment" onClick={handleRepayment} allowDisabledFocus />
              <PrimaryButton className="btn-primary" iconProps={{ iconName: "M365InvoicingLogo" }} text="Create Transactions" onClick={() => genBondTransactions(selectedBond)} allowDisabledFocus />
            </Stack>
          </div>
        }
      </div>
      <div className="ms-Grid-roe">
        <p className="ms-Grid-col ms-lg6 amrgin-top-md">* If selected bond offer is test bondoffer. Bond will also mark as test bond.</p>
      </div>
      {showActions && (
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg6">
            <h5>Warning</h5>
            <h6>On click save</h6>
            <ul>
              <li>If Bond Status "Open", future transactions will be regenerated using "GracePeriod"</li>
              <li>If bond marked as "Deleted" all "Open" transactions will also "Deleted"</li>
              <li>If bond marked as "Closed" all "Open" transactions will also "Cancelled"</li>
            </ul>
          </div>

        </div>
      )}
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleSave}
              className="btn-primary"
            />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface OwnProps {
  onCancel: () => void
  bond: IBond,
  onSave: (bond: IBond) => void
}

type Props = OwnProps;
