
import { DefaultButton, Dropdown, PrimaryButton, ProgressIndicator, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { useContext, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import store, { RootState } from "../../../../store"
import { IBondTransationState } from "../../../../store/admin/funder/transaction/reducer"
import { getCreateTaxStatementApi } from "../../../../store/client/funder/transaction"
import { dropdownStyles } from "../../common/common"
import ModalDialog from "../../common/ModalDialog"
import { SocketContext } from "../../../../context/socket";
import { toast } from "react-toastify"
import { pyamentPreferencesTypes } from "../helper"
import BondOfferPicker from "../../common/BondOfferPicker"
import moment from "moment"


export const OpenCreateTaxStatementModal = (props: { params: any }) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: any) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Create Tax Stament"}
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-md"}
        >
          <Modal {...props} onSave={(data: any) => callback(data)} onCancel={() => callback(undefined)} />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}


export const Modal = ({
  params,
  onCancel,
  onSave,
}: OwnProps) => {
  const { coreSocket } = useContext(SocketContext);
  const { periods, transactionFilter }: IBondTransationState = useSelector<RootState, IBondTransationState>((state) => state.web.transaction)

  const [bondHolders, setBondHolders] = useState<any>([])
  const [years, setYears] = useState<any>([])
  const [progressData, setProgressData] = useState<any>({ counter: 0, total: 0 })
  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  const [payload, setPayload] = useState<ICreateStatementPayload>({
    period: 'all',
    year: parseInt(moment().format("YYYY")),
    paymentBy: 'all',
    bondHolder: "all",
    transactionId: params.transactionId,
    generateAll: false,
    isTestTransaction: transactionFilter.isTestTransaction
  })


  const loadData = async () => {
    const { bondHolders, years } = await getCreateTaxStatementApi({ ...params });
    setBondHolders(bondHolders)
    setYears(years)

  }


  useEffect(() => {
    loadData();
    coreSocket.on("create-tax-statements", (data) => {
      setProgressData(data)
    });
    coreSocket.on("create-tax-statements-done", (data) => {
      setIsGenerating(false)
      // toast.success(data.message)
    });
    return () => {
      coreSocket.off("create-tax-statements");
      coreSocket.off("create-tax-statements-done");
    };
  }, []);

  const handleProcess = async ({ generateAll = false }) => {
    if (isGenerating) {
      toast.info("Tax certificates already in process.")
      return
    } else {
      setIsGenerating(true)
      payload.generateAll = generateAll
      payload.isTestTransaction = transactionFilter.isTestTransaction
      coreSocket.emit("create-tax-statements", payload);
    }
  };

  const handleCancel = () => {
    onCancel()
  }

  const handleOnChange = async (key: string, value: any) => {
    let p: ICreateStatementPayload = { ...payload }
    switch (key) {
      case 'selectedBondOffer':
        p = { ...p, bondOfferId: value[0]?.key }
        break;
      default:
        p = { ...p, [key]: value };
        break;
    }
    setPayload(p)
  }
  return (
    <div className="edit-record">
      <div className='ms-Grid-row'>
        <div className='margin-top-lg'>
          <p className='ms-Grid-col ms-lg12' > {progressData.totalTransactions > 0 && <ProgressIndicator description={`Processed ${progressData.counter}/${progressData.totalTransactions}`} percentComplete={progressData.counter / progressData.totalTransactions} />}</p>
        </div>
      </div>

      {!params.transactionId && (
        <div className="ms-Grid" dir="ltr"
          style={{ minWidth: "700px", maxWidth: "1300px" }}>
          <div className='ms-Grid-row'>
            <Dropdown
              dropdownWidth='auto'
              label="Payment Period"
              onChange={(e, item) => handleOnChange("period", item?.key?.toString())}
              selectedKey={payload.period || 'all'}
              options={periods}
              styles={dropdownStyles}
              className="inlineflex ms-Grid-col ms-lg3"
            />
            {payload.period && payload.period === "all" && (
              <Dropdown
                label="Choose Year"
                selectedKey={payload.year || moment().format('YYYY')}
                onChange={(e: any, item: any) => handleOnChange('year', item.key)}
                placeholder="Choose Year"
                options={years}
                styles={dropdownStyles}
                className="ms-Grid-col ms-lg3"
              />
            )}

            <Dropdown
              label="Bond Holder"
              selectedKey={payload.bondHolder || 'all'}
              onChange={(e: any, item: any) => handleOnChange('bondHolder', item.key)}
              placeholder="BondHolder"
              options={[{ key: "all", text: "All" }, ...bondHolders?.filter((b: { year: any }) => {
                return payload.year !== 'all' && b.year === payload.year
              }
              ).map((b: { key: any; text: any }) => ({ key: b.key, text: b.text }))]}
              styles={dropdownStyles}
              className="ms-Grid-col ms-lg6"
            />
          </div>
          <div className='ms-Grid-row'>
            <Dropdown
              dropdownWidth='auto'
              label="Payment By"
              onChange={(e, item) => handleOnChange("paymentBy", item?.key?.toString())}
              selectedKey={payload.paymentBy || 'all'}
              options={pyamentPreferencesTypes}
              styles={dropdownStyles}
              className="ms-Grid-col ms-lg6"
            />
            <div className={`ms-Grid-col ms-lg6 margin-top-xsm`}>
              <BondOfferPicker filters={{}} onChange={handleOnChange} />
            </div>
          </div>
        </div>

      )}

      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item style={{ marginTop: "15px" }}>
            {params.transactionId && (
              <PrimaryButton
                text={'Generate'}
                onClick={() => handleProcess({ generateAll: false })}
                className="btn-primary"
              />
            )}
            {!params.transactionId && (
              <>
                <PrimaryButton
                  text={'Generate All'}
                  onClick={() => handleProcess({ generateAll: true })}
                  className="btn-primary"
                />
                <PrimaryButton
                  text={'Generate Missing'}
                  onClick={() => handleProcess({ generateAll: false })}
                  className="btn-primary"
                />
              </>

            )}
            <DefaultButton onClick={handleCancel} text="Close" />
          </Stack.Item>
        </Stack>
      </Sticky>
      {!params.transactionId &&
        <div className='ms-Grid-row'>
          <div className="ms-Grid-col ms-lg12">
            <ul>
              <li>There will one certificate per transaction</li>
              <li>Once click on generate process tax statments generation will run on background</li>
              <li>Even dialog is closed process will continue</li>
              <li>Progress status will display top right corner</li>
              <li>Once all certificates generated a toast message will display</li>
            </ul>
          </div>
        </div>
      }
    </div>
  );
};

interface ICreateStatementPayload {
  period?: string;
  year?: any,
  bondHolder: any,
  paymentBy: string,
  transactionId?: string
  bondOfferId?: string
  generateAll: boolean
  isTestTransaction?: boolean;
}
interface OwnProps {
  params: {
    transactionId?: string,
  };
  onCancel: () => void;
  onSave: (params: any) => void;
}