import { Action, ISchoolFilter, ISystemFilterParams, ISystemFilter } from "./actionTypes";
import { IPvamSite } from '../../../components/admin/system/types';
import { ISystem } from "@solarforschools/sfs-core";
import systems from "@solarforschools/sfs-core/dist/PVAM/systems";
import _ from "lodash";


// States' definition
export interface ISystemState {
  isLoading: boolean;
  schoolFilter: ISchoolFilter[];
  systemFilter: ISystemFilter[];
  systemFilterParams: ISystemFilterParams;
  systems: ISystem[];
  newSites?: IPvamSite[];
  totalCount: number;
  selectedSystem?: ISystem | undefined;
  epm?: number[] | undefined;
  seSystems?: any[];
  ftpFolders: any[];
  countriesFilter: any[];
  services?: string[];
}
export interface State {
  system: ISystemState;
}

const system = (
  state: ISystemState = {
    isLoading: false,
    schoolFilter: [],
    systemFilter: [],
    countriesFilter: [],
    systemFilterParams: {
      schoolSlug: 'all',
      system: 'all',
      provider: 'all',
      ownedBy: 'all',
      billedBy: 'all',
      country: 'all',
      page: 1,
      sortField: 'name',
      sortOrder: 'asc',
      pageSize: 50
    },
    systems: [],
    newSites: [],
    totalCount: 0,
    ftpFolders: [],
    services: []
  },
  action: Action
): ISystemState => {
  switch (action.type) {
    case 'SET_SCHOOLS_FILTER':
      return { ...state, schoolFilter: action.schools }
    case 'SET_SYSTEM_FILTER':
      return { ...state, systemFilter: action.systems }
    case 'SET_SYSTEM_FILTER_PARAMS':
      return { ...state, systemFilterParams: action.params }
    case 'SET_SYSTEMS_LIST':
      let systems = action.reset ? action.systemList : [...state.systems, ...action.systemList]
      systems = _.uniqBy(systems, 'id');
      return { ...state, systems }
    case 'GET_FTP_FOLDERS':
      return { ...state, ftpFolders: action.folders as string[] }
    case 'GET_NEW_SITES_FROM_PVAM': {
      return { ...state, newSites: action.pvamSites }
    }
    case "GET_SE_SYSTEM_FROM_PVAM": {
      return { ...state, seSystems: action.pavamSESystems }
    }
    case 'SET_SYSTEMS_TOTALS_COUNT': {
      return { ...state, totalCount: action.totalCount }
    }
    case 'DELETE_SYSTEM': {
      const stateObj = { ...state }
      const systemFilter: ISystemFilter[] = stateObj.systemFilter.filter(s => s.key?.toString() !== action.id.toString())
      const systems: ISystem[] = stateObj.systems.filter(s => s.id?.toString() !== action.id.toString())
      const systemFilterParams = { ...stateObj.systemFilterParams }
      const { page, pageSize } = systemFilterParams
      return { ...state, systemFilter, systems, totalCount: stateObj.totalCount - 1, systemFilterParams: { ...systemFilterParams, page: Math.ceil(systems.length / page! * pageSize!) } }
    }
    case 'SYSTEM/UPDATE_SYSTEM': {
      let systems = [...state.systems]
      const index = systems.findIndex(s => s.id?.toString() === action.system.id.toString())
      if (index > -1) {
        systems[index] = action.system
      } else {
        systems.unshift(action.system)
      }
      systems = _.uniqBy(systems, 'id');
      return { ...state, systems, totalCount: index > -1 ? state.totalCount : state.totalCount + 1 }
    }
    case 'SET_SELECTED_SYSTEM': {
      return { ...state, selectedSystem: action.selectedSystem }
    }
    case 'SET_ENERGY_PER_MONTH': {
      return { ...state, epm: action.epm }
    }
    case "SET_SYSTEM_SERVICES": {
      return { ...state, services: action.services };
    }
    case 'SYSTEM/SET_COUNTRIES_FILTER': {
      return { ...state, countriesFilter: action.countries }
    }
    default:
      return state;
  }
};

export default system;
