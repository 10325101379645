import { IBillingPeriod } from "@solarforschools/sfs-core/dist/billing/types";
import moment from "moment";
import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";

export const getPeriodFields = (options: StringMap): IField[] => [
  {
    label: "Cycle",
    key: "Cycle",
    type: "string",
    extras: { required: true },
    placeholder: "Cycle",
    className: "ms-Grid-col ms-lg4",
  },
  {
    label: "Period",
    key: "Period",
    type: "string",
    extras: { required: true },
    placeholder: "Period",
    className: "ms-Grid-col ms-lg4",
  },
  {
    label: "Previous",
    key: "Previous",
    type: "string",
    extras: { required: true },
    className: "ms-Grid-col ms-lg4",
  },
  {
    label: "Start",
    key: "Start",
    type: "date",
    extras: { required: true },
    placeholder: "Start",
    className: "ms-Grid-col ms-lg4",
  },
  {
    label: "End",
    key: "End",
    type: "date",
    extras: { required: true },
    className: "ms-Grid-col ms-lg4",
  },
];
export const validatePeriod = async (periodDocument: IBillingPeriod) => {
  let errors: StringMap = {};
  const { Period, Start, End, Previous, Cycle } = periodDocument || {};
  if (!Period) errors["Period"] = requiredMessage;
  if (!Start) errors["Start"] = requiredMessage;
  if (!End) errors["End"] = requiredMessage;
  if (!Previous) errors["Previous"] = requiredMessage;
  if (!Cycle) errors["Cycle"] = requiredMessage;
  // if (!Object.keys(errors).length) {
  //     errors = await validateProductId(product) as unknown as StringMap
  // }
  return errors;
};

export const initPeriod = {
  Period: `${moment().year()}Q${moment().quarter()}`,
  Start: new Date(moment().startOf("quarter").format("YYYY-MM-DD")),
  End: new Date(moment().endOf("quarter").format("YYYY-MM-DD")),
  Previous: null,
  Cycle: `${moment().year()}Q${moment().quarter()}`,
};
