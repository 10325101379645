import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import MeterFilters from './meterFilters';
import MeterList from './meterList';
import { exportMeterReadings, getMeterReadings } from '../../../store/admin/meterReading/action';
import { ActionButton, Stack, Text } from '@fluentui/react';
import { RootState } from '../../../store';
import { IMeterReadingState } from '../../../store/admin/meterReading/reducer';
import { initMeterReading } from './helper';
import openMeterReadingModal from './meterDialog';
import { openFillingMeterReadingModal } from './meterFillingDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { ImportMeterReadingDialog, OpenImportReadingModal } from './ImportMeterReadingModal';

const MeterScreen = () => {
  const dispatch = useDispatch()
  const { meterReadings, totalCount }: IMeterReadingState = useSelector<RootState, IMeterReadingState>((state: RootState) => state.web.meterReading)
  useEffect(() => {
    dispatch(getMeterReadings())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Meter Readings</h1>
        <MeterFilters />
      </header>
      <MeterList actions={['edit']} />
    </div>
  );
}
export default MeterScreen;

