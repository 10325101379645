import { IProject } from "@solarforschools/sfs-core/dist/project/types"
import axios from "axios"
import { StringMap } from "../../components/admin/common/types"
import { generateQueryString } from "../../utils/util"
import { host } from "../config"

let GetProductAPiAbortController: AbortController

export const getProductsApi = (params: any
): Promise<{ projects: IProject[], count: number }> => {

    if (GetProductAPiAbortController) {
        GetProductAPiAbortController.abort(); // Tell the browser to abort request
    }
    GetProductAPiAbortController = new AbortController();
    return axios.get(`${host}/product?${generateQueryString(params)}`, { signal: GetProductAPiAbortController.signal }).then(res => res.data)
}

export const deleteProjectApi = (slug: any): Promise<any> => {
    return axios.delete(`${host}/projects/${slug}`).then(res => res.data)
}



