import { Action } from "./actionTypes";
import moment from "moment";
import { IPortfolio } from "@solarforschools/sfs-core/dist/types";
import { IPortfolioFilter } from "../../../../components/admin/funder/portfolio/types";

// States' definition
export interface IPortfolioState {
  isLoading: boolean;
  portfolioes: IPortfolio[];
  portfolioFilter: IPortfolioFilter;
  totalCount: number;
}
export interface State {
  portfolioes: IPortfolioState;
}

const portfolio = (
  state: IPortfolioState = {
    isLoading: false,
    portfolioes: [],
    portfolioFilter: {
      page: 1,
      pageSize: 50,
      sortField: "date",
      sortOrder: "desc",
      status: 'all',
    },
    totalCount: 0,
  },
  action: Action
): IPortfolioState => {
  switch (action.type) {
    case "FUNDER/SET_PORTFOLIO_FILTER":
      return { ...state, portfolioFilter: action.portfolioFilter };
    case "FUNDER/SET_PORTFOLIO_LIST":
      const portfolioes = action.reset
        ? action.portfolioes
        : [...state.portfolioes, ...action.portfolioes];
      return { ...state, portfolioes, totalCount: action.totalCount };


    default:
      return state;
  }
};

export default portfolio;
