import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchTeamMembers, resetAll } from '../../../store/admin/team/action'
import { CSSProperties } from 'styled-components'
import Loader from '../common/Loader'
import Lists from './Lists'

const Team = () => {

    const dispatch = useDispatch()

    const layoutStyles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
    }

    useEffect(() => {

        dispatch(fetchTeamMembers())

        // cleanup
        return () => {
            dispatch(resetAll())
        }
    }, [])


    return (
        <div style={layoutStyles} className='team' >

            <Loader />

            <div>
                <header className="text-center">
                    <h1>Team</h1>
                </header>
            </div>

            <Lists />
        </div>
    )
}

export default Team