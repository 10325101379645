import axios from "axios";
import { ICommentsfilter } from "../../components/admin/comments/type";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

let GetCommentAPiAbortController: AbortController

export const getCommentsAPi = (
  filters: ICommentsfilter
): Promise<any> => {
  const queryStr = generateQueryString({ ...filters});
  if (GetCommentAPiAbortController) GetCommentAPiAbortController.abort()
  GetCommentAPiAbortController = new AbortController()
  return axios.get(`${host}/users/comments?${queryStr}`, { signal: GetCommentAPiAbortController.signal }).then((res) => res.data);
};