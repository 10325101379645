import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IFaq } from "@solarforschools/sfs-core/dist/faq/types";
import { IField } from "../../dynamic-form/DynamicForm";
import { Regions, requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { Languages } from '../common/common';
import _ from "lodash";

export const getGeneralFields = (props: { faq: IFaq; }): IField[] => {
    return [
        {
            label: "Question",
            key: "question",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Question",
            className: 'ms-Grid-col ms-lg12'
        },
        {
            label: "Region",
            key: "country",
            type: "dropdown",
            extras: { required: true, multiSelect: true },
            options: Regions.filter(c => c.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg4'
        },
        // {
        //     label: "Language",
        //     key: "lang",
        //     type: "dropdown",
        //     extras: { required: true },
        //     options: Languages.filter(c => c.key !== 'all') as IDropdownOption[],
        //     className: 'ms-Grid-col ms-lg4'
        // },
        {
            key: 'live',
            label: 'Live',
            type: 'checkbox',
            className: 'margin-top-lg ms-Grid-col ms-lg1',
        },
        {
            key: 'order',
            label: 'Order',
            type: 'number',
            className: 'ms-Grid-col ms-lg2',
        },
        {
            label: "Keywords",
            key: "keywords",
            type: "string",
            placeholder: "Enter keywords",
            className: 'ms-Grid-col ms-lg12'
        },
    ];
}
export const validateFaq = async (faq: IFaq) => {
    let errors: StringMap = {};
    const { question, lang,
        country,
        keywords,
        site } = faq
    if (!question) errors['question'] = requiredMessage
    if (!keywords) errors['keywords'] = requiredMessage
    if (!country) errors['country'] = requiredMessage
    // if (!lang) errors['lang'] = requiredMessage
    if (!site || !site.length) errors['site'] = requiredMessage
    return errors;
}

export const getFaqColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'question',
            name: 'Question',
            fieldName: 'question',
            minWidth: 150,
            maxWidth: 2500,
            ...isSortable,
            onRender: (item: IFaq) => <TooltipHost content={item.question} closeDelay={500}> {item.question}</TooltipHost>
        },
        {
            key: 'site',
            name: 'Site',
            fieldName: 'site',
            minWidth: 150,
            ...isSortable,
            onRender: (item: IFaq) => item.site?.join(' ,')
        },
        // {
        //     key: 'lang',
        //     name: 'Language',
        //     fieldName: 'lang',
        //     minWidth: 150,
        //     ...isSortable,
        //     onRender: (item: IFaq) => item.lang
        // },
        {
            key: 'order',
            name: 'Order',
            fieldName: 'order',
            minWidth: 50,
            ...isSortable,
        },
        {
            key: 'country',
            name: 'Country',
            fieldName: 'country',
            minWidth: 80,
            ...isSortable,
            onRender: (item: IFaq) => {
                return (item.country && _.isArray(item.country)) ? item.country.join(' ,') : item.country
            }
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (data) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(data)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export interface UserTabErrors {
    general: boolean
    schools: boolean
}


export const initFaq: IFaq = {
    question: "",
    lang: "",
    country: [],
    keywords: "",
    ans: "",
    live: false,
    isDeleted: false,
    sections: [],
    pages: [],
    order: 0,
    site: [],
    subCategory: ""
}
