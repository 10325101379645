
import { SetBondAuditLogs, SetBondBondHolders, SetBondBondOffers, SetBondFilter, SetBondList, UpdateBond } from './actionType';
import { IBondFilter } from './../../../../components/admin/funder/bond/types.d';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../..';
import { isLoading } from '../../action';
import { createBondAPi, createBondTransactionsApi, deleteBondApi, downloadBondCertificateApi, exportBondsApi, getBondFilterApi, getBondsApi, processBondRepaymentApi, reGenCertificateApi, transferBondApi, updateBondApi } from '../../../client/funder/bond';
import { downloadFileFromStream } from '../../../../components/admin/common/utils';
import { toast } from 'react-toastify';
import { getBondOffersApi } from '../../../client/funder/bondOffer';
import { ObjectId } from 'mongodb';
import { initbondHolder } from '../../../../components/admin/funder/bondholder/BondHolderHelper';
import { getBondHoldersApi } from '../../../client/funder/bondHolder';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';
import { IBondOffer } from '@solarforschools/sfs-core/dist/funder/bond-offer/types';
import { IBondOfferFilter } from '../../../../components/admin/funder/bondOffer/types';
import { IBondHolderFilter } from '../../../../components/admin/funder/bondholder/types';

// Action Creators

export const setBondsFilter = (bondFilter: IBondFilter): SetBondFilter => {
  return { type: "FUNDER/SET_BONDS_FILTER", bondFilter };
};

export const setBondsList = (
  bonds: IBond[],
  totalCount: number,
  replace: boolean = true
): SetBondList => {
  return { type: "FUNDER/SET_BONDS_LIST", bonds, totalCount, replace };
};

export const setBondBondoffers = (bondOffers: any[]): SetBondBondOffers => {
  return { type: "FUNDER/BOND_SET_BONDOFFER_FILTER", bondOffers };
};

export const setBondBondHolders = (bondHolders: any[]): SetBondBondHolders => {
  return { type: "FUNDER/BOND_SET_BONDHOLDER_FILTER", bondHolders };
};

export const updateBond = (bond: IBond): UpdateBond => {
  return { type: "FUNDER/UPDATE_BOND", bond };
};

export const setBondAuditLogs = (auditLogs: any[], periods: any[]): SetBondAuditLogs => {
  return { type: "FUNDER/BOND_SET_BONDOAUDITLOG_FILTER", auditLogs, periods };
};


export const getBonds =
  (params?: IBondFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IBondFilter =
            getState().web.bonds.bondFilter;
          reqBody = { ...filters };
        }
        const { bonds, totalCount } = await getBondsApi(reqBody);
        dispatch(setBondsList(bonds, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const downloadBondCertificate = (bond: IBond) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const res = await downloadBondCertificateApi(bond._id)
      await downloadFileFromStream({ data: res.data, filename: bond.certNumber.toString(), type: 'pdf' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something went wrong')
    }
  };

export const createBondTransactions = (bond: IBond) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const res = await createBondTransactionsApi(bond._id)
      if (res.status === 200) {
        toast.success(res.data.msg);
      }
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something went wrong')
    }
  };


export const getBondBondoffers =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const filters: IBondFilter = getState().web.bonds.bondFilter;
        const { bondOffers } = await getBondOffersApi({ all: "true", bondOffer: filters.bondOffer });
        dispatch(setBondBondoffers([{ key: 'all', text: 'All' }, ...bondOffers.map(b => ({ key: b._id, text: b.name }))]));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error('Something went wrong')
      }
    };

export const getBondFilter =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { auditLogs, periods } = await getBondFilterApi();
        dispatch(setBondAuditLogs([{ key: 'all', text: 'All' }, ...auditLogs.map((b: any) => ({ key: b, text: b }))], [{ key: 'all', text: 'All' }, ...periods.map((b: any) => ({ key: b, text: b }))]));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error('Something went wrong')
      }
    };

export const getBondBondHolders =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const filters: IBondFilter = getState().web.bonds.bondFilter;
        const { bondHolders } = await getBondHoldersApi({ all: "true", email: filters.userName });
        dispatch(setBondBondHolders([{ key: 'all', text: 'All' }, ...bondHolders.map((b: IBondHolder) => ({ key: b.userName, text: b.userName }))]));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error('Something went wrong')
      }
    };

export const exportBonds =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const filters: IBondFilter =
          getState().web.bonds.bondFilter;
        const data: any = await exportBondsApi(filters)
        downloadFileFromStream({ data, filename: "export-bonds", type: 'xlsx' })
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error('Something went wrong')
      }
    };

export const deleteBond =
  (bondId: string) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { bond, msg } = await deleteBondApi(bondId);
        updateBond(bond)
        if (msg) toast.success(msg)
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateBond =
  (data: IBond) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { bond, msg } = data._id
          ? await updateBondApi(data)
          : await createBondAPi(data);
        const { bonds, totalCount } = getState().web.bonds
        if (!data._id) {
          dispatch(setBondsList([bond, ...bonds], totalCount + 1, true));
        } else {
          const index = bonds.findIndex(x => x._id?.toString() === bond._id?.toString());
          const updatedBonds = [...bonds];
          updatedBonds[index] = bond;
          dispatch(setBondsList([...updatedBonds], totalCount, true));
        }
        toast.success(msg);
        dispatch(isLoading(false));
        return
      } catch (err: any) {
        console.log(err);
        if (err?.response?.data?.msg) toast.error(err.response.data.msg)
        dispatch(isLoading(false));
      }
    };


export const processBondRepaymet = (data: any) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      const { bond, msg } = await processBondRepaymentApi(data)

      const { bonds, totalCount } = getState().web.bonds
      if (!data._id) {
        dispatch(setBondsList([bond, ...bonds], totalCount + 1, true));
      } else {
        const index = bonds.findIndex(x => x._id?.toString() === bond._id?.toString());
        const updatedBonds = [...bonds];
        updatedBonds[index] = bond;
        dispatch(setBondsList([...updatedBonds], totalCount, true));
      }
      toast.success(msg);
      dispatch(isLoading(false));
      return
    } catch (err: any) {
      console.log(err);
      if (err?.response?.data?.msg) toast.error(err.response.data.msg)
      dispatch(isLoading(false));
    }
  };


export const transferBond = (data: any) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      const { bond, msg } = await transferBondApi(data)
      const { bonds, totalCount } = getState().web.bonds
      const index = bonds.findIndex(x => x.certNumber?.toString() === bond.certNumber?.toString());
      const updatedBonds = [...bonds];
      if (index > -1) {
        updatedBonds[index] = bond;
      }
      dispatch(setBondsList([...updatedBonds], totalCount, true));

      toast.success(msg);
      dispatch(isLoading(false));
      return
    } catch (err: any) {
      console.log(err);
      if (err?.response?.data?.msg) toast.error(err.response.data.msg)
      dispatch(isLoading(false));
    }
  };

export const reGenrateCertificate = (data: IBond) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { bond, msg } = await reGenCertificateApi(data.certNumber)
      toast.success(msg);
      const { bonds, totalCount } = getState().web.bonds
      const index = bonds.findIndex(x => x._id?.toString() === bond._id?.toString());
      const updatedBonds = [...bonds];
      updatedBonds[index] = bond;
      dispatch(setBondsList([...updatedBonds], totalCount, true));
      dispatch(isLoading(false));
      return bond
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error('Something went wrong')
    }
  };