import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";
import { IRTYield } from "@solarforschools/sfs-core/dist/solardb/types";

export const getRTYieldsApi = (query: any): Promise<any> => {
  return axios.get(`${host}/rtyields?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}

export const exportRTYieldsApi = (query: any): Promise<any> => {
  return axios.get(`${host}/rtyields/download?${generateQueryString(query as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const deleteMultipeRTYieldsApi = (data: any): Promise<any> => {
  return axios
    .put(`${host}/rtyields/delete/bulk`, data)
    .then((res) => res.data);
};

export const deleteMultipeRTReadingApi = (data: any): Promise<any> => {
  return axios
    .put(`${host}/rtreadings/delete/bulk`, data)
    .then((res) => res.data);
};
