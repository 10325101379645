import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import "./question.css";
import {
  IAnswer,
  IQuestionTemplate,
  QuestionItems,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { changeQuestionTemplate } from "../../../../store/content/action";

const Q8 = ({
  content,
  handleSubmit,
  handleTextBoxChange,
  changeQuestionTemplate,
}: Props) => {
  const thisTemplate = TemplateItems.Q8;

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const handleAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      text: e.target.value,
    };
    dispatchEvent(answer);
  };

  const handleTips = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      tips: e.target.value,
    };
    dispatchEvent(answer);
  };

  const dispatchEvent = (answer: IAnswer) => {
    let questionTemplate: IQuestionTemplate = {
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer,
    };
    changeQuestionTemplate(questionTemplate);
  };
  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, paddingTop: 0, height: "80%" }}
        >
          <Textbox
            placeholder="Scrambled word here"
            maxChar={80}
            style={{ fontSize: "1rem", fontWeight: "normal" }}
            containerStyle={{ margin: "1rem 1rem", height: "10%" }}
            onChange={(e) =>
              handleTextBoxChange(e, 1, thisTemplate, QuestionItems.QUESTION)
            }
            value={
              content.questionTemplate?.items?.find(
                (temp) => temp.sequence === 1
              )?.text
            }
          />
        </div>
      </aside>
      <aside>
        <p>
          Unscrable words. Enter the scrambled word in the question box and the
          answer in the textbox below.
        </p>
        <div className="correct-answer">
          <div className="choose-correct-answer">
            <Label name="Enter the answer here." fontSize="large" />
            <div className="answer-options">
              <Textbox
                placeholder="Unscrambled Word"
                maxChar={45}
                style={{ fontSize: "1rem", fontWeight: "normal" }}
                containerStyle={{ margin: "0.5rem 0.5rem", height: "70%" }}
                onChange={handleAnswer}
                value={content.questionTemplate?.answer?.text}
              />
            </div>
          </div>
          <div className="answer-comment-box">
            <Label
              name="Correct!"
              fontSize="xx-large"
              style={{ marginTop: 0 }}
            />
            <Textarea
              placeholder="Enter any additional text to appear on the correct / not quite message (optional)"
              maxChar={50}
              width="100%"
              containerStyle={{ height: "55%" }}
              onChange={handleTips}
              value={content.questionTemplate?.answer?.tips}
            />
          </div>
        </div>
        <Button
          width="20%"
          height="8%"
          name="Submit"
          fontSize="1.5rem"
          onClick={handleSubmit}
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeQuestionTemplate: (template: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: () => void;
  handleTextBoxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(questionTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Q8);
