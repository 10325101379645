import { useMemo, useState } from "react";
import { PrimaryButton, DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import {
  IStackTokens,
  ITextFieldProps,
  Stack,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import "office-ui-fabric-react/dist/css/fabric.css";
import _ from "lodash";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { getMeterFields, validateMeter } from "./SystemHelper";
import { StringMap } from "../common/types";
import { useDispatch } from "react-redux";
import { fetchFtpFile } from "../../../store/admin/system/action";
import ModalDialog from "../common/ModalDialog";
import { toast } from "react-toastify";
import { IMeter } from "@solarforschools/sfs-core/dist/solardb/types";
import { ISystem } from "@solarforschools/sfs-core";


export const EditMeter = ({ system, meter, onCancel, onSave, ftpFolders }: Props) => {
  const dispatch = useDispatch();
  const [selectedMeter, setSelectedMeter] = useState({ ...meter });
  const [errors, setErrors] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const [fileName, setFileName] = useState(null);

  const onIconClick = async (props: ITextFieldProps & { meter: IMeter; iconName: string; title: string; ariaLabel: string; }) => {
    if (props.id === 'channelId') {
      const { data, fileName: fname }: any = await dispatch(fetchFtpFile(props.meter))
      if (data) {
        setIsModalOpen(true)
        setFileContent(data);
        setFileName(fname);
      } else {
        toast.error('No File content found')
      }
    }
  }

  const handleOnDismissModal = () => {
    setIsModalOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewFtpFile = (props: ITextFieldProps) => <CustomLabel id="channelId" ariaLabel="info" iconName="Info" title="info" meter={selectedMeter} {...props} />;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewFolderName = (props: ITextFieldProps) => <CustomLabel id="folderName" ariaLabel="" iconName="" title="" meter={selectedMeter} {...props} />;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewDataFormat = (props: ITextFieldProps) => <CustomLabel id="dataFromat" ariaLabel="" iconName="" title="" meter={selectedMeter} {...props} />;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const meterFields = useMemo(() => getMeterFields({ ftpFolders, viewFtpFile, viewFolderName, viewDataFormat, provider: system.provider }), [ftpFolders, viewFtpFile, viewFolderName, viewDataFormat, system.provider]);

  const handleOnChangeInput = (key: string, value: any) => {
    let meterObj = { ...selectedMeter };
    switch (key) {
      case 'folderName':
        meterObj = _.set({ ...selectedMeter }, key, value === 'select' ? '' : value);
        break;

      default:
        meterObj = _.set({ ...selectedMeter }, key, value);
        break;
    }

    setSelectedMeter(meterObj);
  };

  const handleOnSave = () => {
    const errors: StringMap = validateMeter(selectedMeter);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    const meter = {...selectedMeter}
    meter.MSN = meter.MSN?.trim()
    meter.channel = meter.channel?.trim()
    meter.channelNumber = meter.channelNumber?.trim()
    onSave({ ...meter, folderName: ["RASPI"].includes(selectedMeter.dataFormat!) && selectedMeter.folderName?.indexOf('/sync/data') === -1 ? `${selectedMeter.folderName}/sync/data` : selectedMeter.folderName || '' });
  };

  // format
  const clonedMeter = useMemo(() => {
    const m: IMeter = _.cloneDeep(selectedMeter);
    m.folderName = m.dataFormat && ["RASPI"].includes(m.dataFormat) ? m.folderName?.split('/')[0] : m.folderName || ''
    return m
  }, [selectedMeter])

  const stackTokens: IStackTokens = {
    childrenGap: 4,
    maxWidth: 300,
  };
  const CustomLabel = (props: ITextFieldProps & { meter: IMeter, iconName: string, title: string, ariaLabel: string }): JSX.Element => {
    return (
      <>
        <Stack horizontal verticalAlign="center" tokens={stackTokens}>
          <span style={{
            fontSize: "14px",
            fontWeight: 600
          }} id={props.id}>{props.label}</span>
          <IconButton
            id={props.id}
            iconProps={{ iconName: props.iconName }}
            title={props.title}
            onClick={() => onIconClick(props)}
            ariaLabel={props.ariaLabel}
          />
        </Stack>
      </>
    );
  };
  return (
    <>
      <ModalDialog
        isModalOpen={isModalOpen}
        title={fileName || "S3 file Content"}
        containerClassName={"modal-size-sm"}
        onDismiss={handleOnDismissModal}
      >
        <div dangerouslySetInnerHTML={{ __html: fileContent.replaceAll("\n", "</br>") }}></div>
      </ModalDialog>
      <div className="edit-record">
        <div className="form-grid">
          <DynamicForm
            fields={meterFields}
            data={clonedMeter}
            errors={errors}
            onChange={handleOnChangeInput}
          />
        </div>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Stack horizontal horizontalAlign="center">
            <Stack.Item>
              <PrimaryButton
                text="Save"
                onClick={handleOnSave}
                className="btn-primary"
              />
              <DefaultButton onClick={onCancel} text="Cancel" />
            </Stack.Item>
          </Stack>
        </Sticky>
      </div>
    </>
  );
};



interface StateProps {
  system: ISystem,
  meter: IMeter;
  ftpFolders: any[];
}

interface OwnProps {
  onCancel: () => void;
  onSave: (meter: IMeter) => void;
}

type Props = StateProps & OwnProps;
