import React, { useContext } from "react"
import { MdFlipCameraAndroid } from "react-icons/md";
import { CardContext, CardContextType } from "../";

interface IFlipFooterProps {
    frontFooter?: () => JSX.Element
    backFooter?: () => JSX.Element
}

const FlipFooter = (props: IFlipFooterProps) => {
    const { setIsFlip, isflip } = useContext(CardContext) as CardContextType;
    const FrontFooter = props?.frontFooter
    const BackFooter = props?.backFooter

    const handleFlip = () => {
        setIsFlip(!isflip)
    }
    if (FrontFooter && BackFooter)
        return (<>
            <div className={`card-flip ${isflip ? "flipped" : ""}`}>
                <div className="card-flip-inner">
                    <div className="card-flip-front flex-row-center">
                        <FrontFooter />
                        <div style={{ fontSize: "1.15rem", position: "absolute", bottom: 0, right: 0, paddingRight: "0.25rem", color: "#243f61", cursor: "pointer" }}>
                            <MdFlipCameraAndroid onClick={handleFlip} title="Flip Tile" />
                        </div>
                    </div>
                    <div className="card-flip-back"> <BackFooter />
                        <div style={{ fontSize: "1.15rem", position: "absolute", bottom: 0, right: 0, paddingRight: "0.25rem", color: "#243f61", cursor: "pointer" }}>
                            <MdFlipCameraAndroid onClick={handleFlip} title="Flip Tile" />
                        </div>
                    </div>
                </div>
            </div>
        </>)
    else return null;
}

export default FlipFooter