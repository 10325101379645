import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../../common/utils";
import moment from "moment";
import { numberToCurrencyFormat } from "../../../../utils/util";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";

export const getCertificateColumns: IColumn[] = [
    {
        key: 'userName',
        name: 'User Name',
        fieldName: 'userName',
        minWidth: 150,
        maxWidth: 200,
        ...isSortable,
        onRender: (item: IBond) => <TooltipHost content={item.userName?.toString() || ''} closeDelay={500}> {item.userName}</TooltipHost>
    },
    {
        key: 'certNumber',
        name: 'CertNumber',
        fieldName: 'certNumber',
        minWidth: 100,
        maxWidth: 100,
        ...isSortable,
        onRender: (item: IBond) => <TooltipHost content={item.certNumber?.toString() || ''} closeDelay={500}> {item.certNumber}</TooltipHost>
    },
    
    {
        key: 'certificate.name',
        name: 'Cert Name',
        fieldName: 'certificate.name',
        minWidth: 100,
        maxWidth: 200,
        onRender: (item: IBond) => <TooltipHost content={item.certificate?.name?.toString() || ''} closeDelay={500}> {item.certificate?.name}</TooltipHost>
    },
    {
        key: 'paymentPreference',
        name: 'PaymentPrefrence',
        fieldName: 'paymentPreference',
        minWidth: 90,
        maxWidth: 100,
        onRender: (item: IBond) => <TooltipHost content={item.paymentPreference?.toString() || ''} closeDelay={500}> {item.paymentPreference}</TooltipHost>
    },
    {
        key: 'bondOfferTitle',
        name: 'Bond Offer',
        fieldName: 'bondOfferTitle',
        minWidth: 90,
        maxWidth: 300,
        onRender: (item: IBond) => <TooltipHost content={item.bondOfferTitle || ''} closeDelay={500}> {item.bondOfferTitle}</TooltipHost>
    },
    {
        key: 'type',
        name: 'Type',
        fieldName: 'type',
        minWidth: 90,
        maxWidth: 100,
        onRender: (item: IBond) => <TooltipHost content={item.type || ''} closeDelay={500}> {item.type}</TooltipHost>
    }
];

export const defaultCertificateFilter = {
    page: 1,
    pageSize: 50,
    sortField: 'date',
    sortOrder: 'desc',
}
