import { IColumn } from "@fluentui/react";
import { ISystem } from "@solarforschools/sfs-core";
import { IInvoiceDocument } from "../invoice/types";
import { saveAs } from "file-saver";

// Detail list header sort direction icon
export const SetDetailListHeaderSortDirection = (column?: IColumn, columns: IColumn[] = []) => {
  if (column?.key === 'action') return columns
  const index = columns.findIndex(c => c.key === column?.key)
  const newColumns = [...columns]

  if (!column?.isSorted) {
    newColumns[index].isSorted = true
    newColumns[index].isSortedDescending = true
  } else {
    newColumns[index].isSortedDescending = !columns[index].isSortedDescending
  }
  newColumns.forEach((c, i) => {
    if (index !== i) {
      newColumns[i].isSorted = false
      newColumns[i].isSortedDescending = false
    }
  });
  return newColumns

};


// Detail List header colum configuration
export const isSortable = {
  isSorted: false,
  isSortedDescending: false,
  sortAscendingAriaLabel: 'Sorted A to Z',
  sortDescendingAriaLabel: 'Sorted Z to A',
  // isPadded: true,
  isRowHeader: true,
  isResizable: true
}

export const downloadFileFromStream = ({ data, filename, type }: { data: string, filename: string, type: string }) => {
  let blobType = ''
  switch (type) {
    case 'pdf':
      blobType = 'application/pdf'
      window.open(window.URL.createObjectURL(new Blob([data], { type: blobType })), '_blank');
      return
      break;
    case 'xlsx':
      blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      break
    case 'csv':
      blobType = 'text/csv;charset=utf-8;'
      const blob = new Blob([data], { type: blobType });
      saveAs(blob, `${filename}.csv`);
      return
      break;
  }

  const blob = new Blob([data], { type: blobType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.${type}`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();  //afterwards we remove the element again 
  return
}

export const generateInvoiceFilename = (invoice: IInvoiceDocument) => {
  const customerName = invoice?.contract?.customerName?.replace(/\s+/g, '-').toLowerCase()
  const fileName = `${invoice.number}-${invoice.contract?.number}-${customerName}`
  return fileName
}

export const generateMid = (system: ISystem) => {
  const meters = system.meters || []
  const mids = meters.map((m: { mid?: any; }) => m.mid)
  let mid = 0
  if (system.provider !== 'orsis')
    mid = parseInt(system.id.toString()) * 1000 + meters.length
  while (mids.includes(mid)) {
    mid++
  }
  return mid
}
