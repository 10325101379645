
import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


export interface IDoughnutChartData {
    label?: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
}

interface IDoughnutChart {
    chartLabels: string[];
    chartData: IDoughnutChartData[]
}

const DoughnutChart = ({ chartLabels, chartData }: IDoughnutChart) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        if(!chartRef.current) return

        const ctx = chartRef.current.getContext('2d') as CanvasRenderingContext2D;
        const data = {
            labels: chartLabels,
            datasets: chartData
        };
        const myDoughnutChart = new Chart(ctx, {
            type: 'doughnut',
            data,
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        color: 'white',

                        padding: 6,
                        formatter: Math.round
                    },
                    tooltip: {
                        callbacks: {
                            label: function (content: any) {
                                const label = content.label;
                                let value = content.formattedValue;
                                if (label === "Size") {
                                    value = content.formattedValue + "MWp";
                                    return `Size ${value}`
                                }
                                return `${label} ${value}`;
                            }
                        },
                    },
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 10,
                            font: {
                                size: 11
                            }
                        },
                        position: "bottom" as const,
                        align: "end" as const
                    },
                },

            },
        });

        return () => {
            myDoughnutChart.destroy(); // Cleanup
        };
    }, [chartData]);


    return (
        <div>
            <div className="flex-column-center">
                <div style={{ height: "160px", width: "150px", paddingTop: "0.5rem" }}>
                    <canvas ref={chartRef} />
                </div>
            </div>
        </div>
    )
};

export default DoughnutChart;