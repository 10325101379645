

import { CheckboxVisibility, DefaultButton, DetailsList, IColumn, IComboBoxOption, IDropdownOption, PrimaryButton, ScrollbarVisibility, SelectionMode, Separator, Stack, Sticky, StickyPositionType, Text } from '@fluentui/react';
import { IRegionConfig } from '@solarforschools/sfs-core'
import { CSSProperties, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import store from '../../../store';
import DynamicForm from '../../dynamic-form/DynamicForm';
import ModalDialog from '../common/ModalDialog';
import cloneDeep from "lodash/cloneDeep"
import { ActionButton } from 'office-ui-fabric-react';
import { openCreateModal } from './CreateModal';
import { openCreateFundingModal } from './CreateFundingModal';
import { openCreatePanelPowerModal } from "./CreatePanelPowerModal"
import { ConfirmDialog } from '../common/ConfirmDialog';
import { getData, getGeneralAddressesFields, getGeneralFirstFields, getGeneralFundingDetailsFields, getGeneralLinksMailsFields, getGeneralPVGISOthersFields, getGeneralSecondFields, validateRegionConfigDetails } from './SettingsHelper';
import { fetchRegionCostsResults, updateRegionConfig } from '../../../store/admin/settings/action';
import { FundingItem } from '@solarforschools/sfs-core/dist/calculator/types';
import openAddCapexModal from './AddCapexData';
import { IRegionCapex } from '@solarforschools/sfs-core/dist/settings/types';


export const openSettingsDialog = (props: IRegionConfig) => {

  return new Promise<IRegionConfig | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: IRegionConfig) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    const regionConfigData = { ...props }

    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Edit Region Config"}
          onDismiss={() => callback(undefined)}

        >
          <SettingsDialog
            regionConfigData={regionConfigData}
            onCancel={() => callback(undefined)}
            onSave={(regionConfig: IRegionConfig) => callback(regionConfig)}
          />
        </ModalDialog>

      </Provider>
      ,
      mountTarget
    )


  })
}

const SettingsDialog = (props: SettingsDialogProps) => {
  const dispatch = useDispatch()

  const [errors, setErrors] = useState<any>({});
  const [regionConfig, setRegionConfig] = useState<IRegionConfig>(cloneDeep(props.regionConfigData));
  const [columns] = useState<IColumn[]>(getData())
  const [arrOfObj, setArrOfObj] = useState<any[]>([])
  const [waitToBuild, setWaitToBuild] = useState<boolean>(true)
  const [capexData, setCapexData] = useState<IRegionCapex[]>([])
  const tableOuterLayout: CSSProperties = { position: "relative", minHeight: "fit-content", display: "flex", flexDirection: "row-reverse" }

  useEffect(() => {
    buildCapexData(regionConfig?.data?.capex?.data)
    dispatch(fetchRegionCostsResults())
  }, [])

  function buildCapexData(capexData: any) {
    let result: any = []

    if (capexData) {
      if (Array.isArray(capexData) && capexData.length > 0) {
        capexData.forEach((elm: any) => {
          const { headName, headValue, items, selectedCostResult } = elm
          result.push({ name: headName, perKWP: headValue, selectedCostResult })

          if (Array.isArray(items) && items.length > 0) {
            items.forEach((item: any) => {
              const { subHeadName, subHeadValue, selectedCostResult } = item
              result.push({ name: subHeadName, perKWP: subHeadValue, selectedCostResult })
            })
          }
        })
      }
      setCapexData(capexData)
      setArrOfObj(result)
    }
    setWaitToBuild(false)
  }

  const handleSave = async () => {

    const errors = await validateRegionConfigDetails(regionConfig)
    setErrors(errors)
    if (Object.keys(errors).length) {
      return;
    }
    const isConfirmed = await ConfirmDialog({
      dialogContentProps: {
        title: " Save configuration",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to save configuration for ${regionConfig.country}`,
      }, confirmBtnText: "Save",
    });
    if (isConfirmed) {
      regionConfig.data["capex"] = {
        data: capexData
      }
      const saved = await dispatch(updateRegionConfig(regionConfig))
      props.onSave(saved as unknown as IRegionConfig)
    };

  }



  const handleChangeInput = (key: string, value: any) => {
    let modifiedKey: string | string[];
    modifiedKey = key.match(/\./g) ? key.split(".") : key

    if (modifiedKey.length === 3) {
      modifiedKey = modifiedKey[2]
    } else if (modifiedKey.length === 2) {
      modifiedKey = modifiedKey[1]
    }



    switch (modifiedKey) {
      case "order":
        setRegionConfig({ ...regionConfig, [modifiedKey]: value }); break;
      case "lat":
      case "lng":
      case "zoom":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, MAP: { ...regionConfig.data.MAP, [modifiedKey]: value } } }); break;

      case "TWITTER":
      case "FACEBOOK":
      case "LINKEDIN":
      case "INSTAGRAM":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, SOCIALMEDIALINKS: { ...regionConfig.data.SOCIALMEDIALINKS, [modifiedKey]: value } } }); break;


      case "team":
      case "info":
      case "registration":
      case "bonds":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, EMAILS: { ...regionConfig.data.EMAILS, [modifiedKey]: value } } }); break;

      case "azimuth":
      case "angle":
      case "loss":
      case "db":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, PVGIS: { ...regionConfig.data.PVGIS, [modifiedKey]: value } } }); break;


      case "site":
      case "drive":
      case "path":
        setRegionConfig({
          ...regionConfig, data: {
            ...regionConfig.data, CustomerFolder: {
              ...regionConfig.data.CustomerFolder, [modifiedKey]: value
            }
          }
        }); break;

      case "contact":
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, PHONE_NUMBERS: { ...regionConfig.data.PHONE_NUMBERS, [modifiedKey]: value } } }); break;

      default:
        setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, [modifiedKey as any]: value } }); break

    }


  }



  //create model for FundingModal
  const handleCreateFundingModal = async () => {
    const fundingLists = regionConfig.data.Funding;
    const openModal: FundingItem[] | undefined = await openCreateFundingModal(fundingLists)
    if (openModal)
      setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, Funding: openModal } })
  }

  //create model for FundingModal
  const handleCreatePanelPowerOptionModal = async () => {
    const data = regionConfig.data.panelPowerOptions || [];
    const openModal: any | undefined = await openCreatePanelPowerModal(data)
    if (openModal)
      setRegionConfig({ ...regionConfig, data: { ...regionConfig.data, panelPowerOptions: openModal } })
  }

  const handleEditCapexData = async () => {
    const openModal = await openAddCapexModal(capexData)
    if (openModal !== undefined) {
      buildCapexData(openModal)
    }
  }

  //create modal for LANGUAGES, HOSTS, ADDRESS, FundingModel
  const handleCreateModal = async (name: string) => {
    let fieldValues: any;

    if (name === "LANGUAGES") {
      fieldValues = regionConfig.data.LANGUAGES
    } else if (name === "HOSTS") {
      fieldValues = regionConfig.data.HOSTS
    } else if (name === "ADDRESS") {
      fieldValues = regionConfig.data.ADDRESS
    } else if (name === "FundingModel") {
      fieldValues = regionConfig.data.FundingModel
    }

    const openModal = await openCreateModal(
      { data: fieldValues })
    const resFromModal = openModal?.data.filter(e => e !== "")
    if (resFromModal !== undefined) {
      setRegionConfig(
        {
          ...regionConfig,
          data: {
            ...regionConfig.data,
            [name]: resFromModal
          }
        })
    }


  }



  if (waitToBuild) return <></>


  return (
    <div>


      <div className='ms-Grid' dir="ltr">
        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralFirstFields()}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />
          <div style={{ cursor: "pointer", marginTop: '1.75rem' }}>
            {
              regionConfig._id && <ActionButton
                title={'Edit Hosts'}
                iconProps={{ iconName: "Edit" }}
                onClick={() => handleCreateModal("HOSTS")}
              />
            }
          </div>

        </div>

        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralSecondFields()}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />
          <div>
            {
              regionConfig._id && <ActionButton
                title={'Edit Language'}
                style={{ marginTop: "1rem" }}
                iconProps={{ iconName: "Edit" }}
                onClick={() => handleCreateModal("LANGUAGES")}
              />
            }
          </div>
        </div>

        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>CAPEX Data</Separator>
          </div>
        </div>

        <>
          <div className='flex-row-center' >
            {
              arrOfObj.length > 0 ? <div style={tableOuterLayout} >
                <ActionButton
                  title={'Edit Capex Data'}
                  iconProps={{ iconName: "Edit" }}
                  onClick={() => handleEditCapexData()}
                />
                <DetailsList
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  compact={true}
                  items={arrOfObj}
                  checkboxVisibility={CheckboxVisibility.hidden}
                />
              </div> : <div className='flex-row-center'>

                <Text variant="medium">Add Capex Data</Text>
                <ActionButton
                  title={'Edit Capex Data'}
                  iconProps={{ iconName: "Edit" }}
                  onClick={() => handleEditCapexData()}
                />
              </div>
            }
          </div>
        </>

        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Funding Lists</Separator>
          </div>
        </div>

        <div className='ms-Grid-row'>
          <div style={{ display: "flex" }} >

            <DynamicForm
              fields={getGeneralFundingDetailsFields}
              data={regionConfig}
              onChange={handleChangeInput}
              errors={errors}
            />
            {regionConfig._id && <ActionButton
              title={'Edit Funding'}
              iconProps={{ iconName: "Edit" }}
              onClick={() => handleCreateModal("FundingModel")}
            />}

            <div className='center-table' style={{ marginLeft: "5rem", marginTop: "1rem", flexBasis: "500px" }}>
              <table>
                <thead >
                  <th> Funded By</th>
                  <th> Funding Model</th>
                  <th> Active </th>
                </thead>
                <tbody>
                  {
                    regionConfig.data.Funding.map((el: any) => {
                      return <tr >
                        <td> {el.FundedBy}</td>
                        <td> {el.FundingModel}</td>
                        <td >

                          {String(el.default) === "true" ? "True" : "False"}

                        </td>
                      </tr>
                    })
                  }

                </tbody>
              </table>
              <div className='center-table-edit'>

                {
                  regionConfig._id && <ActionButton
                    title={'Edit Funding'}
                    iconProps={{ iconName: "Edit" }}
                    onClick={handleCreateFundingModal}
                  />
                }
              </div>

            </div>

          </div>

        </div>

        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Panel Power Options</Separator>
          </div>
        </div>
        <div className='ms-Grid-row'>
          <div style={{ display: "flex" }} >


            <div className='center-table' style={{ marginLeft: "5rem", marginTop: "1rem", flexBasis: "500px" }}>
              <table>
                <thead >
                  <th> Label</th>
                  <th> Value</th>
                  <th>Unit Height(m)</th>
                  <th>Unit Width(m)</th>
                </thead>
                <tbody>
                  {
                    regionConfig.data.panelPowerOptions?.map((el: any) => {
                      return <tr >
                        <td> {el.label}</td>
                        <td> {el.value}</td>
                        <td> {el.portrait?.unitHeight || ''}</td>
                        <td> {el.portrait?.unitWidth || ''}</td>
                      </tr>
                    })
                  }

                </tbody>
              </table>
              <div className='center-table-edit'>

                {
                  regionConfig._id && <ActionButton
                    title={'Edit Power Options'}
                    iconProps={{ iconName: "Edit" }}
                    onClick={handleCreatePanelPowerOptionModal}
                  />
                }
              </div>

            </div>

          </div>

        </div>

        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Address</Separator>
          </div>
        </div>


        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralAddressesFields}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />
          {
            regionConfig._id && <ActionButton
              title={'Edit Address'}
              iconProps={{ iconName: "Edit" }}
              onClick={() => handleCreateModal("ADDRESS")}
            />
          }

        </div>


        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>Links & Mails</Separator>
          </div>
        </div>

        <div className='ms-Grid-row'>
          <DynamicForm
            fields={getGeneralLinksMailsFields}
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />

        </div>


        <div className="ms-Grid-row" style={{ marginTop: "1rem" }}>
          <div className="ms-Grid-col ms-lg12">
            <Separator>PVGIS</Separator>
          </div>
        </div>

        <div className="ms-Grid-row margin-top-md">
          <DynamicForm
            fields={getGeneralPVGISOthersFields
            }
            data={regionConfig}
            onChange={handleChangeInput}
            errors={errors}
          />
        </div>







      </div>


      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center" verticalAlign='center'>
          <PrimaryButton
            text='Save'
            onClick={handleSave}
            className="btn-primary"
          />
          <DefaultButton onClick={() => props.onCancel()} text="Cancel" />
        </Stack>
      </Sticky>
    </div>
  )
}

export default SettingsDialog



interface SettingsDialogProps {
  regionConfigData: IRegionConfig
  onSave: (regionConfig: IRegionConfig) => void
  onCancel: () => void
}




