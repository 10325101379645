import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { IWorkflowFilter } from "../../../components/admin/workflow/types";
import { IWorkflow } from "@solarforschools/sfs-core/dist/workflow/types";
import {
  createWorkflowAPi,
  deleteWorkflowAPi,
  getProjectFlowsApi,
  updateWorkflowAPi,
} from "../../client/workflow";
import { toast } from "react-toastify";

// Action Creators

export const setWorkflowFilter = (
  workflowFilter: IWorkflowFilter
) => {
  return { type: "PROJECT/SET_WORKFLOW_FILTER", workflowFilter };
};

export const setWorkflowList = (
  workflows: IWorkflow[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "PROJECT/SET_WORKFLOW_LIST", workflows, totalCount, reset };
};

export const updateWorkflowList = (workflow: IWorkflow) => {
  return { type: "PROJECT/UPDATE_WORKFLOW", workflow };
};

export const delWorkflow = (wrokflowId: string) => {
  return { type: "PROJECT/DELETE_WORKFLOW", wrokflowId };
};

export const getProjectWrokflows =
  (params?: IWorkflowFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IWorkflowFilter =
            getState().web.projectWorkflow.workflowFilter;
          reqBody = { ...filters };
        }
        const { workflows, totalCount } = await getProjectFlowsApi(reqBody);
        dispatch(setWorkflowList(workflows, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateWorkflow =
  (params: IWorkflow) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { workflow, msg } = params._id
          ? await updateWorkflowAPi(params)
          : await createWorkflowAPi(params);
        dispatch(updateWorkflowList(workflow));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteProjectWrokflow =
  (workflow: IWorkflow) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        await deleteWorkflowAPi(workflow);
        deleteProjectWrokflow(workflow);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
