import { ThunkDispatch } from "redux-thunk";

import { RootState } from "../../../index";
import { isLoading } from "../../action";
import { downloadFileFromStream } from "../../../../components/admin/common/utils";
import { IGSSReportFilter } from "../../../../components/admin/reporting/gssReporting/types";
import { getGSSReportDataApi, getGSSReportExportApi } from "../../../client/reporting/gssReport";
import { IInvoiceAccountReportFilter } from "../../../../components/admin/reporting/invoiceAccountReporting/types";
import { getInvoices } from "../../../client/invoice";
import { getInvoiceAccountReportDataApi, getInvoiceAccountReportExportApi } from "../../../client/reporting/invoiceAccount";

// Action Creators
export const setInvoiceAccountReportFilter = (invoiceAccountReportFilter: IInvoiceAccountReportFilter) => {
  return { type: "REPORT/SET_INVOICE_ACCOUNT_REPORT_FILTER", invoiceAccountReportFilter };
};

export const setInvoiceAccountReportData = (
  invoiceAccountReportData: any[], reset:boolean=true
) => {
  return { type: "REPORT/SET_INVOICE_ACCOUNT_REPORT_LIST", invoiceAccountReportData,  reset};
};

export const setInvoiceAccountReportTotalCount = (totalCount: number) => {
  return { type: 'REPORT/SET_INVOICE_ACCOUNT_REPORT_TOTALS_COUNT', totalCount }
}


export const fetchInvoiceAcountReportData = (params?: IInvoiceAccountReportFilter,
  reset: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const state = getState()
        const filter: IInvoiceAccountReportFilter = state.web.invoiceAccountReport.invoiceAccountReportFilter
        reqBody = { ...filter }
      }
      const { data, totalCount } = await getInvoiceAccountReportDataApi(reqBody)

      await dispatch(setInvoiceAccountReportData(data, reset));
      await dispatch(setInvoiceAccountReportTotalCount(totalCount))
      dispatch(isLoading(false));
      return data
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const exportInvoiceAccountReport = () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { invoiceAccountReportFilter } = getState().web.invoiceAccountReport;
      const params = { ...invoiceAccountReportFilter };
      const data = await getInvoiceAccountReportExportApi(params);
      downloadFileFromStream({ data, filename: "invoice-account-report", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };