import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsList,
  IDetailsListProps,
  IDetailsRowProps,
  Shimmer
} from "@fluentui/react";
import React, { useRef, useState } from "react";
import { InView } from 'react-intersection-observer';
import { DetailListStickyHeader } from "../admin/common/DetailListStickyHeader";
import { SetDetailListHeaderSortDirection } from "../admin/common/utils";

const DetailsListWapper = (props: DetailsListWapperProps) => {
  const items = [...props.items, null];
  const [columns, setColumns] = useState<IColumn[]>(props.columns || [])

  const listRef = useRef<IDetailsList | null>(null);

  const isLoadingProps: Partial<IDetailsListProps> = props.isLoading ? {
    items: Array(15).fill(1),
    onRenderRow: (props?: IDetailsRowProps) => <Shimmer width='100%' />
  } : {}

  const onRenderMissingItem = (index?: number) => {
    if (props.canLoadMore) {
      return (
        <InView as="div" onChange={(inView) => inView && props.onRequestLoadMore && props.onRequestLoadMore()}>
          <Shimmer width='100%' />
        </InView>
      )
    }
    return <></>
  }

  const handleOnHeaderClick = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined, column?: IColumn): void => {
    if (column?.key === "action") return;
    const index = columns.findIndex((c) => c.key === column?.key);

    const newColumns = SetDetailListHeaderSortDirection(column, columns);
    setColumns(newColumns);
    listRef.current?.focusIndex(0)
    props.onSort && props.onSort(
      newColumns[index].key,
      newColumns[index].isSortedDescending ? "desc" : "asc"
    );
  };

  return (
    <>
      <DetailsList
        {...props}
        items={items}
        {...isLoadingProps}
        onRenderDetailsHeader={DetailListStickyHeader}
        componentRef={listRef}
        onColumnHeaderClick={handleOnHeaderClick}
        constrainMode={ConstrainMode.unconstrained}
        layoutMode={DetailsListLayoutMode.justified}
        onRenderMissingItem={onRenderMissingItem}
      />
    </>
  );
}

interface DetailsListWapperProps extends IDetailsListProps {
  isLoading?: boolean;
  canLoadMore?: boolean;
  onRequestLoadMore?: () => void;
  onSort?: (field: string, order: 'asc' | 'desc') => void;
}

export default DetailsListWapper;
