const Shimmer = () => {
    
  const classNames = [
    "skimmer-schools",
    "skimmer-schools",
    "skimmer-users",
    "skimmer-electricityGen",
    "skimmer-feedInTarrif",
    "skimmer-projects",
    "skimmer-designs",
    "skimmer-carbonSavings",
    "skimmer-devSchools",
    "skimmer-pipeDrive",
    "skimmer-liveMeters",
    "skimmer-ethex",
    "skimmer-pipedrive-deal-in-legal",
    "skimmer-pipedrive-deal-offer-sent",
    "skimmer-pipedrive-web-leads",
    "skimmer-pipedrive-web-deals",
    "skimmer-pipedrive-uk-active-deal",
    "skimmer-pipedrive-deal-offer-avg-days",
    "skimmer-teacher-portal-activity"
  ];

  const elements = classNames.map((elm, index) => {
    return (
      <div key={index}>
        <span className={`${elm} skimmer-loader`}></span>
      </div>
    );
  });

  return (
    <div className="flex-row-center">
      <div className="skimmer-layout">{elements}</div>
    </div>
  );
};
export default Shimmer;
