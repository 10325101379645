import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { ActionButton, Checkbox, CheckboxVisibility, IColumn, IconButton, ScrollablePane, ScrollbarVisibility, SelectionMode, Text, Toggle } from '@fluentui/react';
import { startCase, toLower, uniqueId } from 'lodash';
import { CONT_GRANT, EDU_SET, FUND_FEE, PARTNER_FEE, SFS_DEV_FEE, SYS_COST, TOTAL_INV, TOT_CAPEX, checkIsAllValuesEmpty, defaultOpexActuals, getActual, getData, getOpexActuals, getSofsValues, isAllActualsEmpty, isAllActualsEmptyAfterUpdate } from "./Helper"
import { ICostSummary } from '../../../store/admin/costSummary/reducers';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import DetailsListWapper from '../../common/DetailsListWapper';
import { IProps, openAddActualsDialog } from "./ActualsDialog"
import { exportCostSummary, resetState, updateActuals } from '../../../store/admin/costSummary/actions';
import { IDetailsProps, IResArrOfObj, IBuildedOpexArrOfObj, IDefaultOpexArrOfObj, ISofsExpenses, IUrlColumnData, IResult, ICostSummaryTableProps } from './types';
import { ObjectId } from 'mongodb';
import { ICapexRes } from '@solarforschools/sfs-core/dist/analysis/types';
import { ICapex } from '@solarforschools/sfs-core/dist/analysis/types';
import { IOpexRes } from '@solarforschools/sfs-core/dist/analysis/types';
import { IOpex } from '@solarforschools/sfs-core/dist/analysis/types';
import { openGetInvoiceDataDialog } from './GetInvoiceDataDialog';
import ChartView from './ChartView';
import { websiteHost } from '../../../store/config';

const CostSummaryTable = (props: ICostSummaryTableProps) => {

    const { waitToRender, setWaitToRender, selectedSlug, waitToBuild, setWaitToBuild } = props


    const dispatch = useDispatch()

    const { designsResult, regionCapexData, regionOpexData } = useSelector<RootState, ICostSummary>((state: RootState) => state.web.costSummary)

    const desingResActuals = designsResult[0]?.actuals
    // const desingResOpexActuals = designsResult[0]?.opexActuals

    const aboveCapexOpexLayout: CSSProperties = { marginLeft: "2rem", marginTop: "0.25rem", marginBottom: "0.25rem" }
    const capexTableLayout: CSSProperties = { position: "relative", minHeight: "80vh" }
    const opexTableLayout: CSSProperties = { position: "relative", minHeight: "70vh" }
    const isDesignsHaveActuals = designsResult[0]?.actuals && designsResult[0]?.actuals !== "Nil" && (designsResult[0]?.actuals as ICapex)?.data?.length > 0

    const [details, setDetails] = useState<IDetailsProps | undefined>()
    const [columns, setColumns] = useState<IColumn[]>()
    const [opexColumns, setOpexColumns] = useState<IColumn[]>()
    const [isActualDesign, setIsActualDesign] = React.useState(false);
    const [urlColumnData, setUrlColumnData] = useState<IUrlColumnData[]>([])
    const [sofsExpenses, setSofsExpenses] = useState<ISofsExpenses[] | []>([])
    const [resArrOfObj, setResArrOfObj] = useState<IResArrOfObj[] | []>([])
    const [opexArrOfObj, setOpexArrOfObj] = useState<IDefaultOpexArrOfObj[] | IBuildedOpexArrOfObj[] | []>([])
    const [isSofsActualsThere, setIsSofsActualsThere] = useState(false)

    const [isStopToggle, setIsStopToggle] = useState(false)
    const [isToggleChart, setIsToggleChart] = useState<boolean | undefined>(false)




    // const handleCheckBoxChange = useCallback(
    //     (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    //         setIsActualDesign(!!checked);
    //     },
    //     [],
    // );


    const handleCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        setIsActualDesign(!!checked);
    }



    useEffect(() => {
        if (isStopToggle && resArrOfObj.length === 0) return

        if (isDesignsHaveActuals) checkIsAllValuesEmpty(resArrOfObj, setIsSofsActualsThere, setSofsExpenses)
        if (!isStopToggle) {

            if (isAllActualsEmpty(resArrOfObj) === false) {
                setIsToggleChart(false)
            }
            else {
                setIsToggleChart(true)
            }

        }
        setWaitToRender(false)

    }, [resArrOfObj])


    // Reset the states in redux when the components are unmounted
    useEffect(() => {

        return () => {
            dispatch(resetState())
        }
    }, [])

    useEffect(() => {

        if (designsResult) {
            const results = designsResult[0]?.results
            const schoolName = designsResult[0]?.schoolName
            const res = {
                school: schoolName,
                version: results?.version,
                fundedBy: results?.FundedBy,
                systemSize: results?.SystemSize,
                currency: results?.Currency
            }
            setDetails(res)
            buildCapexOpexData()
            if (!isStopToggle) {
                if (desingResActuals !== "Nil") {
                    if (isAllActualsEmpty(resArrOfObj)) {
                        setIsToggleChart(false)
                    }
                    else {
                        setIsToggleChart(true)
                    }
                } else {
                    setIsToggleChart(false)
                }
            }

            if (designsResult[0]?.invoice) {
                const { invoice } = designsResult[0]
                let result: IUrlColumnData[] = []
                if (Array.isArray(invoice)) {
                    invoice.forEach((elm) => {
                        const { invoice, url } = elm
                        if (url !== undefined) {
                            result.push({ url, invoiceNumber: invoice.invoiceNumber, invoiceDate: invoice.invoiceDate })
                        }
                    })
                }
                setUrlColumnData(result)
            } else {
                setUrlColumnData([])
            }

            return () => {
                setIsActualDesign(false)
            }
        }
    }, [designsResult, regionOpexData])
    //   }, [desingResActuals, desingResOpexActuals, designsResult, regionOpexData,]) // -> Will work (dependencies)

    // if (!isQuery) {
    //     if (selectedRegion !== "" && (selectedSlug === "" || selectedDesign === "")) {
    //         return (
    //             <></>
    //         )
    //     }
    // }

    //! Commented for a purpose
    // if (selectedDesign === "" && waitToRender)
    //     return (
    //         <>
    //             <div className='flex-row-center' style={{ marginTop: "2rem" }}>
    //                 <Text variant='medium'> Select a design</Text>
    //             </div>
    //         </>
    //     )

    if (waitToRender) return <></>

    if (!details || details["school"] === undefined || details["version"] === undefined || resArrOfObj.length === 0 && waitToRender)
        return (<></>)


    // Build Capex Data
    function buildCapexOpexData() {

        let buildedArrOfObj = [...regionCapexData] // perkWP(total/designs' systemSize), total(totalBudget from design params) - calculated from backend
        let renderThisValues = []

        // If actuals Present in Analysis then build new arr of objects to render the capex table
        // If not take buildedArrOfObjects to render the table
        if (isDesignsHaveActuals) {
            renderThisValues = buildedArrOfObj?.map((el) => {
                const { name, perKWP, total, isActuals, paymentBy, uniqueId, underHead } = el
                const paymentIdDate = name + "-" + uniqueId + "-p"
                let findElm
                if (name === "System Costs" || name === "Total Capex" || name === "Total Investment")
                    findElm = ((designsResult[0]?.actuals as ICapex)?.data)?.find((el: ICapexRes) => el.name === name)
                else
                    findElm = ((designsResult[0]?.actuals as ICapex)?.data)?.find((el: ICapexRes) => el.name === name && el.uniqueId === uniqueId)
                let returnThis: any
                if (findElm !== undefined) {
                    let calulateTotal = 0
                    if (typeof findElm.perKWP === "number" && findElm.perKWP !== 0) {
                        calulateTotal = (typeof total === "number" ? total : 0) - findElm.perKWP
                    }
                    //    let calulateTotal = (typeof total === "number" ? total : 0) - (typeof findElm.perKWP === "number" ? findElm.perKWP : 0)

                    returnThis = { uniqueId, name, perKWP, total, actualPerKWP: findElm.perKWP, totalBudget: calulateTotal === 0 ? "-" : calulateTotal }

                    if (isActuals) returnThis['isActuals'] = isActuals
                    if (paymentBy) returnThis['paymentById'] = paymentBy
                    if (underHead) returnThis['underHead'] = underHead
                    if (findElm[paymentIdDate]) returnThis[paymentIdDate] = findElm[paymentIdDate]
                    if (findElm.paymentBy) returnThis['paymentBy'] = findElm.paymentBy
                    return returnThis
                } else {
                    returnThis = { uniqueId, name, perKWP, total, paymentBy: "-", paymentIdDate: new Date(), actualPerKWP: "-", totalBudget: "-" } //Fallback Case
                    if (isActuals) returnThis['isActuals'] = isActuals
                    if (paymentBy) returnThis['paymentById'] = paymentBy
                    if (underHead) returnThis['underHead'] = underHead
                    return returnThis
                }
            })
            let useThisToCalcSysCost: any = {}, sysSum = 0, capexSum = 0, invstSum, findElm;
            let removeThisFromCalc = [PARTNER_FEE, EDU_SET, SFS_DEV_FEE, FUND_FEE, CONT_GRANT, TOTAL_INV, SYS_COST, TOT_CAPEX]

            // Variance Calc - System Cost -> Note: Variance - key is totalBudget
            for (const value of renderThisValues) {
                const { paymentById, name, uniqueId, underHead } = value
                if (underHead !== undefined && !removeThisFromCalc.includes(name)) {
                    if (underHead.indexOf(uniqueId) !== -1) {
                        if (useThisToCalcSysCost[paymentById] === undefined)
                            useThisToCalcSysCost[paymentById] = value
                    }

                }
            }

            for (const key in useThisToCalcSysCost) {
                const totalBudget = useThisToCalcSysCost[key]?.totalBudget
                if (totalBudget !== undefined) {
                    sysSum = sysSum + (typeof totalBudget === "number" ? totalBudget : 0)
                }
            }
            findElm = renderThisValues.find(obj => obj.name === SYS_COST)
            if (findElm !== undefined) {
                findElm.totalBudget = sysSum
            }
            // Variance Calc - System Cost

            // Variance Calc - Total Capex
            capexSum = sysSum

            for (const value of renderThisValues) {
                const { name, totalBudget } = value
                if (name === PARTNER_FEE || name === EDU_SET || name === SFS_DEV_FEE) {
                    capexSum = capexSum + (typeof totalBudget === "number" ? totalBudget : 0)
                }
            }
            // Variance Calc - Total Capex

            // Variance Calc - Total Investment
            invstSum = capexSum

            for (const value of renderThisValues) {
                const { name, totalBudget } = value
                if (name === FUND_FEE) {
                    invstSum = invstSum + (typeof totalBudget === "number" ? totalBudget : 0)
                } else if (name === CONT_GRANT) {
                    invstSum = invstSum - (typeof totalBudget === "number" ? totalBudget : 0)
                }
            }
            // Variance Calc - Total Investment

            findElm = renderThisValues.find(obj => obj.name === TOT_CAPEX)
            if (findElm !== undefined) {
                findElm.totalBudget = capexSum
            }
            findElm = renderThisValues.find(obj => obj.name === TOTAL_INV)
            if (findElm !== undefined) {
                findElm.totalBudget = invstSum
            }

            // findElm = renderThisValues.find(obj => obj.name === FUND_FEE)
            // if (findElm !== undefined) {
            //     findElm.paymentBy = "SOFS"
            // }

            const isActualsDesignInDb = (desingResActuals as ICapex)?.isActualDesign || false
            setIsActualDesign(isActualsDesignInDb)
            const updateTheColumn = [...getData("CAPEX"), ...getActual()]
            setColumns(updateTheColumn)
        } else {
            setColumns(getData("CAPEX"))
            renderThisValues = buildedArrOfObj
        }
        setResArrOfObj(renderThisValues)
        buildOpexData()
    }

    // Build Opex Data (no rendered in UI)
    function buildOpexData() {

        // System Size
        const systemSize = designsResult[0]?.results?.SystemSize

        // Build Arr Of Objects which has perKWP as total/systemSize
        const buildArrayOfObjects = regionOpexData.map((elm) => {
            const { name, total } = elm
            if (typeof elm.total === "number") {
                return { name, perKWP: elm.total / systemSize, total }
            } else {
                return { name, perKWP: "-", total }
            }
        })


        let renderTheseValues: IDefaultOpexArrOfObj[] | IBuildedOpexArrOfObj[] = []

        // If OpexActuals Present in Analysis then build new resArrOfObjects to render the opexData table
        // If not take buildedArrOfObjects to render the table
        if (designsResult[0]?.opexActuals && designsResult[0]?.opexActuals !== "Nil" && (designsResult[0]?.opexActuals as IOpex)?.data?.length > 0) {

            renderTheseValues = buildArrayOfObjects.map((el: any) => {
                const { name, perKWP, total } = el
                const findActualElm = ((designsResult[0]?.opexActuals as IOpex)?.data as IOpexRes[])?.find((el) => el.name.toLowerCase() === name.toLowerCase())
                let calulateTotal = (typeof total === "number" ? total : 0) - (typeof findActualElm?.total === "number" ? findActualElm.total : 0)
                let returnThis = { name, perKWP, total, actualTotal: findActualElm?.total, overallTotal: calulateTotal === 0 ? "-" : calulateTotal }
                return returnThis

            })
            const updateTheColumn = [...getData(`OPEX(year 1)`), ...getOpexActuals()]
            setOpexColumns(updateTheColumn)
        } else {
            setOpexColumns(getData("OPEX"))
            renderTheseValues = buildArrayOfObjects;
        }
        setOpexArrOfObj(renderTheseValues)
        setWaitToBuild(true)
    }


    const handleAddActuals = async () => {

        const opexActuals = designsResult[0].opexActuals

        let opexActualsData: any = []

        // If Opex Actuals is Nil in designResult then take default OpexActuals
        // If not create New Opex Actuals based on designResult
        if (opexActuals !== 'Nil' && opexActuals !== undefined) {
            //  opexActualsData = [...opexActuals]
            opexActualsData = (opexActuals as IOpex).data.map((el: { name: string, total: string | number }) => {
                const { name, total } = el
                return { [name as string]: total }
            })
        } else {
            opexActualsData = [...defaultOpexActuals]
        }

        const renderAtDialogBox: IProps = { opex: opexActualsData, capexRender: resArrOfObj }
        const openModalResp: IResult | undefined = await openAddActualsDialog(renderAtDialogBox)

        if (openModalResp === undefined) return

        const isEmpty = isAllActualsEmptyAfterUpdate(resArrOfObj, openModalResp.capex)
        if (!isEmpty) setIsStopToggle(true)
        else setIsStopToggle(false)

        setIsToggleChart(false)

        const designId = designsResult[0].designId as unknown as ObjectId
        const schoolSlug = designsResult[0].slug
        // dispatch to update 
        dispatch(updateActuals(schoolSlug, designId, openModalResp, isActualDesign))
    }

    const handleGenerateInvoice = async () => {
        await openGetInvoiceDataDialog(sofsExpenses)
        setIsStopToggle(true)
    }
    const handleExport = () => {
        dispatch(exportCostSummary(resArrOfObj))
    }

    const renderInvoiceTable = () => {

        const headerStyle = { marginLeft: "2rem", marginTop: "0.5rem" }
        const outerLayout = { display: "flex", fontSize: "14px", minHeight: "20vh", maxWidth: "40vw", overflow: "scroll" }
        const tableHeaderStyle: CSSProperties = { position: "sticky", top: 0, zIndex: 100 }
        const innerLayout = { margin: "0.5rem 0 0.5rem 2rem", flexBasis: "500px" }

        const openFile = (fileUrl: string) => {
            const newTab = window.open(fileUrl, '_blank');
            if (newTab) {
                newTab.focus();
            }
        }

        return (
            <>
                <h3 style={headerStyle}>Invoice List</h3>
                <div style={outerLayout} >
                    <div className='center-table' style={innerLayout}>
                        <table>
                            <thead style={tableHeaderStyle}>
                                <th>Download Invoice</th>
                                <th>Invoice Date</th>
                            </thead>
                            <tbody>
                                {
                                    urlColumnData.map((elm: IUrlColumnData, id: number) => {
                                        const { url, invoiceDate, invoiceNumber } = elm
                                        return <tr key={id}>
                                            <td>

                                                <div className='flex-row-center'>
                                                    <div>
                                                        <a href={url} target="_blank" rel="noopener noreferrer" >{invoiceNumber}</a>
                                                    </div>
                                                    <div>
                                                        <IconButton
                                                            iconProps={{ iconName: 'FileSymLink' }}
                                                            styles={{
                                                                icon: { color: '#243f61', fontSize: 5 },
                                                            }}
                                                            aria-label="Open File"
                                                            onClick={() => openFile(url)}
                                                            title="Open File"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{invoiceDate}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    // Render on top to CAPEX, OPEX Tables
    const renderAboveCapexOpexTable = (handleChartToggle: (e: React.MouseEvent<HTMLElement>, checked?: boolean) => void, isToggle: boolean | undefined) => {
        const url = `${websiteHost}/schools/${selectedSlug}/analysis`

        return (
            <>
                <div style={aboveCapexOpexLayout}>
                    {
                        Object.keys(details).map((el: string, i: number) => {
                            if (el === "school") {
                                return <div key={i}>
                                    <Text variant='mediumPlus'>
                                        {startCase(toLower(el))} -  <a href={url} target="_blank" rel="noopener noreferrer" title='Click to view analysis'> {details[el]}</a>
                                    </Text>
                                </div>
                            } else {
                                return <span key={i}>
                                    <Text variant='mediumPlus' >
                                        {startCase(toLower(el))} -  <strong> {details[el as keyof IDetailsProps]}</strong>
                                    </Text> &nbsp;
                                </span>
                            }

                        })
                    }
                </div>



                <div>
                    <div className={'flex-row-center'}>
                        <>
                            <div>
                                <ActionButton
                                    iconProps={{ iconName: 'Add', style: { color: '#243f61' } }}
                                    onClick={handleExport}>
                                    Export
                                </ActionButton>
                            </div>
                            <div>
                                <Checkbox label="Actual Design" checked={isActualDesign} onChange={handleCheckBoxChange} />
                            </div>

                            {
                                isSofsActualsThere && (columns && columns?.length > 3 ? <ActionButton
                                    iconProps={{ iconName: "DownloadDocument", style: { color: "#243f61;" } }}
                                    onClick={handleGenerateInvoice}>
                                    Generate Invoice
                                </ActionButton> : <></>)
                            }

                            <ActionButton
                                iconProps={columns?.length === 3 ? { iconName: 'Add', style: { color: '#243f61' } } : { iconName: "Edit", style: { color: "#243f61;" } }}
                                onClick={handleAddActuals}>
                                {columns?.length === 3 ? " Add Actuals" : "Update Actuals"}
                            </ActionButton></>

                        {
                            isStopToggle ? (<>
                                {isDesignsHaveActuals && (
                                    <div className='flex-row-center visual-view' style={{ justifyContent: "right", marginTop: "0.25rem" }}>
                                        <Toggle
                                            label={<div>Visual View</div>}
                                            inlineLabel
                                            checked={isToggle}
                                            onChange={handleChartToggle}
                                        />
                                    </div>)}

                            </>) : (
                                <>
                                    {isDesignsHaveActuals && isToggleChart && (
                                        <div className='flex-row-center visual-view' style={{ justifyContent: "right", marginTop: "0.25rem" }}>
                                            <Toggle
                                                label={<div>Visual View</div>}
                                                inlineLabel
                                                checked={isToggle}
                                                onChange={handleChartToggle}
                                            />
                                        </div>)}
                                </>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }


    const renderCapexOpexTable = (
        <>
            <div style={capexTableLayout}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsListWapper
                        columns={columns}
                        onRenderDetailsHeader={DetailListStickyHeader}
                        selectionMode={SelectionMode.none}
                        compact={true}
                        items={resArrOfObj}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </ScrollablePane>
            </div>
            {/* -> Opex Table Render
            <div style={opexTableLayout}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsListWapper
                        columns={opexColumns}
                        onRenderDetailsHeader={DetailListStickyHeader}
                        selectionMode={SelectionMode.none}
                        compact={true}
                        items={opexArrOfObj}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </ScrollablePane>
            </div> */}

        </>
    )


    if (!regionCapexData || !waitToBuild) return <></>

    const renderTable = (handleChartToggle: (e: React.MouseEvent<HTMLElement>, checked?: boolean) => void, isToggle: boolean | undefined) => {
        return (
            <>
                {urlColumnData.length > 0 && renderInvoiceTable()}
                <div
                    className={'flex-row-center'}
                    style={{ justifyContent: "space-between" }}>
                    {renderAboveCapexOpexTable(handleChartToggle, isToggle)}
                </div>
                {renderCapexOpexTable}
            </>
        )
    }

    return (<>
        <RenderChartOrTable
            isToggleChart={isToggleChart}
            renderTable={renderTable}
            data={resArrOfObj}
            isStopToggle={isStopToggle}
            setIsStopToggle={setIsStopToggle}
            setIsToggleChart={setIsToggleChart}
        />
    </>)

}

export default CostSummaryTable


interface IRenderChartOrTableProps {
    isToggleChart: boolean | undefined
    isStopToggle: boolean
    setIsStopToggle: React.Dispatch<React.SetStateAction<boolean>>
    setIsToggleChart: React.Dispatch<React.SetStateAction<boolean | undefined>>
    renderTable: (handdleChartToggle: (e: React.MouseEvent<HTMLElement>, checked?: boolean) => void, isToggle: boolean | undefined) => JSX.Element
    data: IResArrOfObj[] | []
}


const RenderChartOrTable = (props: IRenderChartOrTableProps) => {
    const [isToggle, setIsToggle] = useState<boolean | undefined>(false)

    const { isToggleChart, renderTable, data, isStopToggle, setIsStopToggle, setIsToggleChart } = props

    const handleChartToggle = (e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (isStopToggle) {
            setIsToggleChart(true)
            setIsStopToggle(false)
        }
        setIsToggle(checked)
    }

    useEffect(() => {
        if (!isToggleChart) setIsToggle(false)
        else setIsToggle(true)
    }, [isToggleChart])


    if (!isToggleChart)
        return renderTable(handleChartToggle, isToggle)

    return (
        <>
            {
                isToggle ? <ChartView
                    data={data}
                    isToggle={isToggle}
                    handleChartToggle={handleChartToggle} /> : renderTable(handleChartToggle, isToggle)
            }
        </>
    )
}

