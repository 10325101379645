import React, { useEffect, useRef } from "react"


/**
 * Custom hook to handle infinite scrolling
 * @param elmRef - last element reference
 * @param dispatchFn - have api call
 * @param data - render lists
 * @param stopInitialRender - flag to stop initial rendering
 */
const useFetch = (elmRef: React.RefObject<HTMLDivElement>, dispatchFn: ()=> void, hasLoadMore: boolean, data: any, stopInitialRender = true  ) => {

    const ref = useRef(0)
    const limit = stopInitialRender ? 2 : 0
    

    function onIntersection(entries: any) {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasLoadMore) {
            if(ref.current >= limit) {
                dispatchFn()
            } 
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection);

        if (observer && elmRef.current) {
            observer.observe(elmRef.current);
        }

        ref.current = ref.current + 1;
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [data]);


    return
}

export default useFetch