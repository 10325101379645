import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { IInvoiceReportFilter } from "../../../components/admin/reporting/invoice/types"
import { generateQueryString } from "../../../utils/util"
import { host } from "../../config"

let GetInvoiceAPiAbortController: AbortController

export const getInvoiceReportDataApi = (query: IInvoiceReportFilter): Promise<any> => {
  if (GetInvoiceAPiAbortController) GetInvoiceAPiAbortController.abort()
  GetInvoiceAPiAbortController = new AbortController()
  return axios.get(`${host}/reports/invoice/data?${generateQueryString(query as unknown as ParsedUrlQueryInput)}`, { signal: GetInvoiceAPiAbortController.signal }).then(res => res.data)
}

export const getInvoiceReportExportApi = (query: IInvoiceReportFilter): Promise<any> => {
  return axios.get(`${host}/reports/invoice/excel?${generateQueryString(query as unknown as ParsedUrlQueryInput)}`,  { responseType: 'arraybuffer' }).then(res => res.data)
}
