import { ActionButton, Dropdown, ICommandBarItemProps, IDropdownOption, ScrollablePane, ScrollbarVisibility, Stack, Text } from '@fluentui/react';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Loader from "../../common/Loader";
import { exportBondHolders, getBondHolders, setBondHolderFilter } from '../../../../store/admin/funder/bondHolder/action';
import { IBondHolderState } from '../../../../store/admin/funder/bondHolder/reducer';
import BondHolderFilters from "./BondHolderFilters";
import BondHolderList from './BondHolderList';
import HamburgerMenu from '../../common/HamburgerMenu';
import { getPaymentPrefEmailExtraFields, initbondHolder } from './BondHolderHelper';
import { openBondHolderModal } from './BondHolderDialog';
import { isLoading } from '../../../../store/admin/action';
import { toast } from 'react-toastify';
import { getBondHolderEmailPreview, getBondHoldersApi, getBondHoldersWitOutDateProfileApi, sendBondholderPaymentPreferenceEmailApi } from '../../../../store/client/funder/bondHolder';
import { OpenEmailPreviewModal } from '../../common/EmailPreviewDialog';
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';
import { Checkbox } from 'office-ui-fabric-react';
import { useParams } from 'react-router-dom';
import { StringMap } from '../../common/types';
import { PageSize } from '../../common/common';


const BondHolderScreen = () => {
  const dispatch = useDispatch()
  const { bondHolders, totalCount, bondHolderFilter }: IBondHolderState = useSelector<RootState, IBondHolderState>((state: RootState) => state.web.bondHolder)
  const params: StringMap = useParams() || {};
  useEffect(() => {
    if (Object.keys(params).length) {
      dispatch(setBondHolderFilter({ ...bondHolderFilter, email: params.email }))
    }
    dispatch(getBondHolders())
  }, []);

  const handleCreateBondholder = async (data: any) => {
    try {
      await openBondHolderModal({
        bondHolder: data
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleExport = async () => {
    dispatch(exportBondHolders())
  }

  const handleRePullBonHolderdata = async (filters: any) => {
    try {
      dispatch(isLoading(true))
      const bondHolders = await getBondHoldersWitOutDateProfileApi({ ...filters });
      if (bondHolders?.length) {
        return bondHolders
      } else {
        toast.error('No bondholder found!')
        return []
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data || 'Something went wrong')
      return []
    } finally {
      dispatch(isLoading(false))
    }
  }

  const handlePreviewSurveyEmail = async () => {
    try {
      dispatch(isLoading(true))
      const bondHolders = await getBondHoldersWitOutDateProfileApi({});
      if (bondHolders?.length) {
        const { html } = await getBondHolderEmailPreview({ userName: bondHolders[0].userName })
        dispatch(isLoading(false))
        await OpenEmailPreviewModal({
          params: {
            data: [...bondHolders],
            title: 'Payment Preference',
            btnTitle: 'Send',
            html,
            externalForm: getPaymentPrefEmailExtraFields,
            reloadData: handleRePullBonHolderdata,
            sendAction: sendBondholderPaymentPreferenceEmailApi
          }
        })
      } else {
        toast.error('No bondholder found!')
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data || 'Something went wrong')
    } finally {
      dispatch(isLoading(false))
    }
  }
  const actions = [{
    key: 'top-bh-action',
    text: '',
    cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
    iconProps: { iconName: 'GlobalNavButton' },
    subMenuProps: [
      {
        key: 'survey-email-bh',
        text: 'Send Payment Preference Reminder',
        iconProps: { iconName: 'SkypeCircleClock' },
        action: handlePreviewSurveyEmail,
        data: 'all'
      }, {
        key: 'export-bh',
        text: 'Export Bond Holders',
        iconProps: { iconName: 'ExcelDocument' },
        action: handleExport,
      },
      {
        key: 'add-bh',
        text: 'Add Bond Holder',
        iconProps: { iconName: 'Add' },
        action: handleCreateBondholder,
        data: initbondHolder
      }]
  }]

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...bondHolderFilter, page: 1, pageSize: size }
    dispatch(setBondHolderFilter(filters))
    dispatch(getBondHolders())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Bond Holders</h1>
        <BondHolderFilters />
      </header>
      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }} className="margin-top-md">
        <div style={{ flex: "20%" }}>
        <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={bondHolderFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        <ActionButton iconProps={{ iconName: 'SkypeCircleClock' }} onClick={() => handlePreviewSurveyEmail()}>
          Send Payment Preference Reminder
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'ExcelDocument' }} onClick={() => handleExport()} >
          Export
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={() => handleCreateBondholder(initbondHolder)}>
          Add Bond Holder
        </ActionButton>

      </div>
      {/* <div className="data-list-container">
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> */}
      <BondHolderList actions={[]} />
      {/* </ScrollablePane>
      </div> */}

    </div>
  );
}
export default BondHolderScreen;
