import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts";


export const FrontFooter = () => {
    const { ethexData } = useContext(CardContext) as CardContextType;
    const [data, setData] = useState<any>({})
    const layoutStyle = { fontSize: "1rem", alignItems: "end", paddingTop: "1rem", color: "#243f61" }
    const footerContentStyle = { color: "gray", fontSize: "0.85rem" }
    function getFrontFooterContent() {
        if (ethexData) {
            const { raisedSoFar, noOfInvestors } = ethexData
            setData({ raisedSoFar, noOfInvestors })
        }
    }

    useEffect(() => {
        getFrontFooterContent()
    }, [ethexData])

    if (ethexData === undefined) return <></>


    return <>
        <div className="flex-row-center" style={layoutStyle}>
            <div className="flex-column-center" >
                <div className="flex-row-center">
                    <div> {data["raisedSoFar"] ?? 0} </div>
                </div>
                <div style={footerContentStyle}> Raised amount </div>
            </div>
            <div className="vs-style">

            </div>

            <div className="flex-column-center">
                <div className="flex-row-center" >
                    <div>{data["noOfInvestors"] ?? 0}  </div>
                </div>
                <div style={footerContentStyle}>No of investors</div>
            </div>
        </div>
    </>
}

export const BackFooter = () => {

    const { ethexData } = useContext(CardContext) as CardContextType;
    const [offerDate, setOfferDate] = useState<any>({})
    const layoutStyle = { fontSize: "0.9rem", alignItems: "end", paddingTop: "1.5rem", color: "#243f61" }
    const footerContentStyle = { color: "gray", fontSize: "0.85rem" }

    function getFrontFooterContent() {
        if (ethexData) {
            const { openDate, closeDate } = ethexData["offer"]
            setOfferDate({ openDate, closeDate })
        }
    }

    useEffect(() => {
        getFrontFooterContent()
    }, [ethexData])

    if (ethexData === undefined) return <></>


    return <>
        <div className="flex-row-center" style={layoutStyle}>
            <div className="flex-column-center" >
                <div className="flex-row-center">
                    <div> {offerDate["openDate"] ?? 0} </div>
                </div>
                <div style={footerContentStyle}> Offer opened </div>
            </div>
            <div className="vs-style">

            </div>

            <div className="flex-column-center">
                <div className="flex-row-center" >
                    <div>{offerDate["closeDate"] ?? 0}  </div>
                </div>
                <div style={footerContentStyle}> Closing date </div>
            </div>
        </div>
    </>

}