import { CSSProperties, DragEvent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrashAlt,
  faGripHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { ThunkDispatch } from "redux-thunk";
import { connect, useDispatch } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import {
  changeScreen,
  showPopup,
  getUnits,
  deleteContents,
  reorderModule,
  reorderUnit,
  isEditPopup,
  editContent,
  copyModule,
} from "../../../store/content/action";
import {
  DeleteType,
  IDeleteParams,
  IModule,
  IUnit,
  IScreen,
  Screens,
} from "../../../store/content/actionTypes";
import { TITLE } from "./config";
import { ConfirmDialog } from "../../admin/common/ConfirmDialog";
import { FaEdit } from "react-icons/fa";
import { MdOutlineContentCopy } from "react-icons/md";


const Block = (props: Props) => {
  const {
    id,
    name,
    url,
    isNew = false,
    showPopup,
    changeScreen,
    content,
    isQuiz = false,
    deleteContents,
  } = props;
  let className = isNew ? "new" : "";
  const isQuestionOrModule =
    !isNew && content.screen?.currentScreen !== Screens.TEMPLATE_SELECTION;
  const [showDropTarget, setShowDropTarget] = useState(false);
  const dispatch = useDispatch()

  const handleBlockClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isNew) {
      showPopup(true);
      dispatch(isEditPopup(false))
    } else {
      switch (content.screen?.currentScreen) {
        case Screens.MODULE:
          const screen: IScreen = {
            currentScreen: Screens.UNIT,
            title: TITLE.UNIT,
            moduleID: id,
          };
          changeScreen(screen);
          break;
        case Screens.UNIT:
          changeScreen({
            ...content.screen,
            currentScreen: Screens.ADD_OR_EDIT_TEMPLATE,
            title: TITLE.ADD_EDIT_TEMPLATE,
            unitID: id,
            isQuiz,
          });
          break;
        case Screens.TEMPLATE_SELECTION:
          changeScreen({
            ...content.screen,
            currentScreen:
              id === "Question"
                ? Screens.QUESTION_TEMPLATE_SELECTION
                : Screens.CONTENT_TEMPLATE_SELECTION,
            title: `Which ${id} Template ?`,
            template: id,
          });
          break;
      }
    }
  };

  const handleReorder = (type: Screens, fromId: string, toId: string) => {
    const list = (type == Screens.MODULE ? content.modules : content.units)!;
    switch (type) {
      case Screens.MODULE:
        props.reorderModule(fromId, toId);
        break;
      case Screens.UNIT:
        const fromIndex = list.findIndex(
          (item: IModule | IUnit) => item.id == fromId
        );
        const toIndex = list.findIndex(
          (item: IModule | IUnit) => item.id == toId
        );
        const moduleId = content.screen?.moduleID!;
        props.reorderUnit(moduleId, fromIndex, toIndex);
        break;
    }
  };

  const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.dataTransfer.setData("id", id);
    e.dataTransfer.setData("type", content.screen!.currentScreen);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.getData("id") == id) return;
    if (showDropTarget) {
      setShowDropTarget(false);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.getData("id") == id) return;
    if (!showDropTarget) {
      setShowDropTarget(true);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDropTarget(false);
    if (e.dataTransfer.getData("id") == id) return;
    const sourceId: string = e.dataTransfer.getData("id");
    const type: Screens = e.dataTransfer.getData("type") as Screens;
    handleReorder(type, sourceId, id);
    return false;
  };

  const handleDelete = async (id: string) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Confirm Delete",
        closeButtonAriaLabel: "Close",
        subText: `Are you sure you want to delete ?`,
      },
      confirmBtnText: "OK",
    });

    const params: IDeleteParams = {
      type:
        content.screen?.currentScreen === "UNIT"
          ? DeleteType.UNIT
          : DeleteType.MODULE,
      moduleId:
        content.screen?.currentScreen === "MODULE"
          ? id
          : content.screen?.moduleID,
      unitId: content.screen?.currentScreen === "UNIT" ? id : "",
    };
    if (confirm) {
      deleteContents(params);
    }
  };

  const handleCopy = (e: React.MouseEvent<SVGElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    if (id) dispatch(copyModule(id))
  }
  const handleClick = (e: any) => {
    e.stopPropagation();
    dispatch(isEditPopup(true))
    dispatch(editContent({ id: props.id }))
  }

  const blockIndicator: CSSProperties = {
    height: "15px",
    width: "15px",
    position: "absolute",
    top: "50%",
    left: "12%",
    transform: "translateY(-50%)",
    borderRadius: "50%"
  }

  const basicIndicator: CSSProperties = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  }

  return (
    <div
      className="module-unit-block clickable"
      onClick={(e) => {
        e.stopPropagation();
        handleDelete(id);
      }}
    >
      {isQuestionOrModule ? (
        <FontAwesomeIcon icon={faTrashAlt} color="red" size="1x" />
      ) : null}
      <section
        className="module clickable"
        onClick={handleBlockClick}
        style={
          showDropTarget ? { border: "1px solid", borderRadius: 10 } : undefined
        }
        {...(isNew
          ? {}
          : {
            draggable: !isNew,
            onDragOver: handleDragOver,
            onDragLeave: handleDragLeave,
            onDragStart: handleDragStart,
            onDrop: handleDrop,
          })}
      >
        <article className={className}   >
          <figure>
            {isNew ? (
              <FontAwesomeIcon icon={faPlus} size="1x" />
            ) : (
              <img src={url} alt="" />
            )}
          </figure>
        </article>

        <article style={{ position: "relative" }}>
          <h3>
            {name}
          </h3>

          {!isNew ? (
            <div className="drag-handle">
              <FontAwesomeIcon icon={faGripHorizontal} />
            </div>
          ) : null}
          {
            props?.isModule && !isNew && <div style={props.isActive ? { ...blockIndicator, backgroundColor: "green" } : { ...blockIndicator, backgroundColor: "orange" }}> </div>
          }
          {
            (props?.isModule || props?.isUnit) && !isNew && <div style={props.isModule ? { ...basicIndicator, right: "15%" } : { ...basicIndicator, right: "10%" }}>
              <FaEdit style={{ cursor: "pointer" }} onClick={(e) => handleClick(e)} />
            </div>
          }

          {
            props.isModule && !isNew && <div style={{ ...basicIndicator, right: "5%" }}>
              <MdOutlineContentCopy style={{ cursor: "pointer" }} onClick={(e) => handleCopy(e, props.id)} />
            </div>
          }

        </article >
      </section>
    </div>
  );
};





interface DispatchProps {
  showPopup: (isShow: boolean) => void;
  changeScreen: (screen: IScreen) => void;
  getUnits: (moduleID: string) => void;
  deleteContents: (params: IDeleteParams) => void;
  reorderUnit: (moduleId: string, fromIndex: number, toIndex: number) => void;
  reorderModule: (fromId: string, toId: string) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  id: string;
  name: string;
  url: string;
  isNew?: boolean;
  isQuiz?: boolean;
  isActive?: boolean;
  description?: string;
  isModule?: boolean;
  isUnit?: boolean;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    showPopup: (isShow: boolean) => dispatch(showPopup(isShow)),
    changeScreen: (screen: IScreen) => dispatch(changeScreen(screen)),
    getUnits: (moduleID: string) => dispatch(getUnits(moduleID)),
    deleteContents: (params: IDeleteParams) => dispatch(deleteContents(params)),
    reorderUnit: (moduleId: string, from: number, to: number) =>
      dispatch(reorderUnit(moduleId, from, to)),
    reorderModule: (fromId: string, toId: string) =>
      dispatch(reorderModule(fromId, toId)),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Block);
