import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  IModule,
  IBlock,
  SetAction,
  SetBlock,
  SetShowPopup,
  UpdateModule,
  IScreen,
  SetScreen,
  Screens,
  IUnit,
  UpdateUnit,
  SetUnit,
  IContentTemplate,
  SetContentTemplate,
  SubmitContentTemplate,
  ContentItems,
  IQuestionTemplate,
  SetQuestionTemplate,
  SubmitQuestionTemplate,
  QuestionItems,
  SetAllContent,
  IDBModule,
  IDeleteParams,
  DeleteType,
  SetGlossary,
  IGlossaryItem,
  SetEditPopup,
  SetEditContent,
  IEditContent,
  updateUnitAfterEdit,
} from "./actionTypes";
import axios from "axios";
import { contentHost as host } from "../config";
import { toast } from "react-toastify";
import { isLoading } from "../admin/action";
import { RootState } from "..";

// Action Creators
export const setModule = (modules: IModule[]): SetAction => {
  return { type: "SET_MODULES", modules };
};
export const isShowPopup = (isShowPopup: boolean): SetShowPopup => {
  return { type: "SET_SHOWPOPUP", isShowPopup };
};
export const editContent = (data: IEditContent): SetEditContent => {
  return { type: "SET_EDIT_CONTENT", editContent: data };
};

export const isEditPopup = (isEditPopup: boolean): SetEditPopup => {
  return { type: "SET_EDITPOPUP", isEditPopup };
};

export const saveBlock = (block: IBlock | null): SetBlock => {
  return { type: "SET_BLOCK", block };
};
export const updateModule = (module: IModule): UpdateModule => {
  return { type: "UPDATE_MODULE", module };
};
export const setScreen = (screen: IScreen): SetScreen => {
  return { type: "SET_SCREEN", screen };
};
export const setUnit = (units: IUnit[]): SetUnit => {
  return { type: "SET_UNIT", units };
};
export const updateUnit = (unit: IUnit): UpdateUnit => {
  return { type: "UPDATE_UNIT", unit };
};

export const UpdateUnitAfterEdit = (unit: IUnit): updateUnitAfterEdit => {
  return { type: "UPDATE_UNIT_AFTER_EDIT", unit };
};

export const setContentTemplate = (
  contentTemplate: IContentTemplate | null
): SetContentTemplate => {
  return { type: "SET_CONTENT_TEMPLATE", contentTemplate };
};
export const submitContentTemplate = (
  contentTemplate: IContentTemplate
): SubmitContentTemplate => {
  return { type: "SUBMIT_CONTENT_TEMPLATE", contentTemplate };
};
export const setQuestionTemplate = (
  questionTemplate: IQuestionTemplate | null
): SetQuestionTemplate => {
  return { type: "SET_QUESTION_TEMPLATE", questionTemplate };
};
export const submitQuestionTemplate = (
  questionTemplate: IQuestionTemplate
): SubmitQuestionTemplate => {
  return { type: "SUBMIT_QUESTION_TEMPLATE", questionTemplate };
};
export const setAllContent = (params: IDBModule[]): SetAllContent => {
  return { type: "SET_ALL_CONTENT", params };
};
export const setGlossary = (glossaryItems: IGlossaryItem[]): SetGlossary => {
  return { type: "SET_GLOSSARY", glossaryItems };
};

export const editBlockAPI = (
  name: string,
  data: Partial<IBlock>,
  moduleId?: string
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function editBlockThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      const formData = new FormData();

      if (data.file) formData.append("file", data.file);
      if (data.animationFile)
        formData.append("animationFile", data.animationFile);

      formData.append("block", JSON.stringify(data));

      if (name.toLowerCase() === "unit")
        formData.append("moduleID", JSON.stringify(moduleId));

      let URL =
        name.toLowerCase() === "module"
          ? `${host}/v1/content/module`
          : `${host}/v1/content/unit`;

      const res = await axios.put(URL, formData, { headers });
      dispatch(isLoading(false));

      if (res.status === 200) {
        if (name.toLowerCase() === "module") {
          toast.success("Module updated successfully");
          dispatch(getModules());
          return;
        }
        dispatch(UpdateUnitAfterEdit(res.data));
      } else {
        toast.error("Something went wrong");
        console.log("Err ", res);
      }
    } catch (err) {
      console.log("Error is ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const getModules = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      const token = localStorage.getItem("token");
      const headers = {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      dispatch(isLoading(true));
      axios
        .get(`${host}/v1/content/module`, { headers })
        .then((res) => {
          dispatch(setModule(res.data));
          dispatch(isLoading(false));
        })
        .catch((err) => {
          dispatch(isLoading(false));
        });
    });
  };
};

export const showPopup = (
  isShow: boolean
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isShowPopup(isShow));
    });
  };
};

export const setBlock = (
  block: IBlock | null
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(saveBlock(block));
    });
  };
};

export const addBlock = (
  block: IBlock,
  screen: IScreen
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      const formData = new FormData();
      if (block.name && block.file) {
        formData.append("file", block.file);
      }
      if (block.animationFile) {
        formData.append("animationFile", block.animationFile);
      }
      formData.append("block", JSON.stringify(block));

      let screenType = "";

      switch (screen.currentScreen) {
        case Screens.MODULE:
          screenType = "module";
          break;
        case Screens.UNIT:
          screenType = "unit";
          if (screen.moduleID) {
            formData.append("moduleID", screen.moduleID);
          }
          break;
      }

      axios
        .post(`${host}/v1/content/${screenType}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(isShowPopup(false));
          dispatch(isLoading(false));
          switch (screen.currentScreen) {
            case Screens.MODULE:
              toast.success("Module added successfully");
              dispatch(updateModule(res.data));
              break;
            case Screens.UNIT:
              toast.success("Unit added successfully");
              dispatch(updateUnit(res.data));
              break;
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const copyModule = (
  moduleId: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async function copyModuleThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      const res = await axios.post(
        `${host}/v1/content/clone-module`,
        {
          moduleId,
        },
        { headers }
      );
      dispatch(isLoading(false));
      if (res.status === 200) {
        toast.success("Module cloned successfully");
        dispatch(getModules());
      } else {
        toast.error("Error in cloning module");
      }
    } catch (err) {
      console.log("Error is ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const getUnits = (
  moduleID: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      axios
        .get(`${host}/v1/content/${moduleID}/unit`)
        .then((res) => {
          dispatch(isLoading(false));
          dispatch(setUnit(res.data));
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const changeScreen = (
  screen: IScreen
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(setScreen(screen));
      if (screen.currentScreen === Screens.UNIT && screen.moduleID) {
        dispatch(getUnits(screen.moduleID));
      }
    });
  };
};

export const changeContentTemplate = (
  contentTemplate: IContentTemplate | null
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(setContentTemplate(contentTemplate));
    });
  };
};

export const addContentTemplate = (
  contentTemplate: IContentTemplate
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      const formData = new FormData();

      const fileItems = contentTemplate.items?.filter(
        (temp) => temp.type === ContentItems.IMAGE
      );

      fileItems?.forEach((item) => {
        if (item.file) {
          let name = JSON.stringify({
            sequence: item.sequence,
            name: item.file.name,
          });
          formData.append("file[]", item.file, name);
        }
      });

      formData.append("template", JSON.stringify(contentTemplate));

      axios
        .post(`${host}/v1/content/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              changeScreen({
                currentScreen: Screens.WHATS_NEXT,
                title: `${contentTemplate.template} Template Selected`,
                moduleID: contentTemplate.module,
                unitID: contentTemplate.unit,
              })
            );
            dispatch(isLoading(false));
            dispatch(setContentTemplate(null));
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const changeQuestionTemplate = (
  questionTemplate: IQuestionTemplate | null
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(setQuestionTemplate(questionTemplate));
    });
  };
};

export const addQuestionTemplate = (
  questionTemplate: IQuestionTemplate
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      const formData = new FormData();

      const fileItems = questionTemplate.items?.filter(
        (temp) => temp.type === QuestionItems.IMAGE
      );

      fileItems?.forEach((item) => {
        if (item.file) {
          let name = JSON.stringify({
            sequence: item.sequence,
            name: item.file.name,
          });
          formData.append("file[]", item.file, name);
        }
      });

      formData.append("template", JSON.stringify(questionTemplate));

      axios
        .post(`${host}/v1/content/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              changeScreen({
                currentScreen: Screens.WHATS_NEXT,
                title: `${questionTemplate.template} Template Selected`,
                moduleID: questionTemplate.module,
                unitID: questionTemplate.unit,
              })
            );
            dispatch(isLoading(false));
            dispatch(setQuestionTemplate(null));
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const fetchContents = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));

      axios
        .get<IDBModule[]>(`${host}/v1/content/cms`)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAllContent(res.data));
            dispatch(isLoading(false));
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const changeOrder = (
  unitID: string,
  from: number,
  to: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  const payload = {
    unitID,
    from,
    to,
  };
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      axios
        .post(`${host}/v1/content/order`, payload)
        .then((res) => {
          if (res.status === 204) {
            dispatch(fetchContents());
            dispatch(isLoading(false));
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const deleteContents = (
  params: IDeleteParams
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  const { type, moduleId = "", unitId = "", contentId = "" } = params;
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      axios
        .delete(`${host}/v1/content/`, {
          data: { type, moduleId, unitId, contentId },
        })
        .then((res) => {
          if (res.status === 204) {
            if (type === DeleteType.MODULE) dispatch(getModules());
            else if (type === DeleteType.UNIT) dispatch(getUnits(moduleId));
            else if (type === DeleteType.CONTENT) dispatch(fetchContents());
            dispatch(isLoading(false));
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const reorderUnit = (
  moduleId: string,
  fromIndex: number,
  toIndex: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      axios
        .post(`${host}/v1/content/unitOrder`, { moduleId, fromIndex, toIndex })
        .then((res) => {
          if (res.status === 200) {
            dispatch(getUnits(moduleId));
            dispatch(isLoading(false));
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const reorderModule = (
  fromId: string,
  toId: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(isLoading(true));
      axios
        .post(`${host}/v1/content/moduleOrder`, { fromId, toId })
        .then((res) => {
          if (res.status == 200) {
            dispatch(getModules());
            dispatch(isLoading(false));
          }
        })
        .catch((err: Error) => {
          toast.error(`Something went wrong - ${err.message}`);
          dispatch(isLoading(false));
        });
    });
  };
};

export const getGlossary = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(isLoading(true));
    const url = `${host}/v1/content/glossary?mode=list`;
    axios
      .get(url)
      .then((res) => {
        dispatch(isLoading(false));
        dispatch(setGlossary(res.data));
      })
      .catch((err: Error) => {
        dispatch(isLoading(false));
      });
  };
};

export const addToGlossary = (
  glossary: IGlossaryItem
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(isLoading(true));
    const url = `${host}/v1/content/glossary/`;
    return axios
      .post(url, glossary)
      .then((res) => dispatch(getGlossary()))
      .catch((err: Error) => {
        dispatch(isLoading(false));
      });
  };
};

export const deleteGlossary = (
  id: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(isLoading(true));
    const url = `${host}/v1/content/glossary/${id}`;
    return axios
      .delete(url)
      .then((res) => dispatch(getGlossary()))
      .catch((err: Error) => {
        dispatch(isLoading(false));
      });
  };
};

export const editGlossary = (
  id: string,
  glossary: IGlossaryItem
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(isLoading(true));
    const url = `${host}/v1/content/glossary/${id}`;
    return axios
      .put(url, glossary)
      .then((res) => dispatch(getGlossary()))
      .catch((err: Error) => {
        dispatch(isLoading(false));
      });
  };
};
