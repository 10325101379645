import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryButton, DefaultButton, ActionButton, IconButton } from '@fluentui/react/lib/Button';
import { Checkbox, Text, DetailsList, Dropdown, IColumn, IDropdownOption, IDropdownProps, Label, SelectionMode, Separator, Stack, Sticky, StickyPositionType, TagPicker, TextField, ITag, Icon, FontIcon } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { StringMap } from '../common/types';
import { getOpportunityFields, getOpportunityFields1, getOpportunityFields2, getOpportunityPipeLineFields, revenueCalc, validateOpportunity, } from './helper';
import DynamicForm from '../../dynamic-form/DynamicForm';
import { IOpportunity, ISubProduct } from '@solarforschools/sfs-core/dist/opportunity/type';
import { fetchProducts } from '../../../store/admin/product/action';
import { Provider, useDispatch } from 'react-redux';
import { addIcon, comboBoxStyles } from '../common/common';
import { IProduct } from '@solarforschools/sfs-core/dist/product/type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import ModalDialog from '../common/ModalDialog';
import { AddSubProduct } from './AddSubProduct';
import { stages } from '../schools/common';
import { IStage } from '@solarforschools/sfs-core/dist/school/types';
import { StageListing } from '../common/StageListing';
import ReactDOM from 'react-dom';
import store from '../../../store';
import { createUpdateOpportunity } from '../../../store/admin/opportunity/action';
import moment from 'moment';
import { numberToCurrencyFormat } from '../../../utils/util';
import { getPipeLineDealByIdApi, getPipelinesApi, getPipelinesStagesApi } from '../../../store/client/common';
import { toast } from 'react-toastify';
import { getSchoolsMetaApi } from '../../../store/client/school';
import { getPipedriveOrgApi, getPipedriveOrgByIdApi } from '../../../store/client/pipedrive/organization';
import { projectNameChange } from '../project/helper';


export const OpenOpportunityModal = (props: { params: any }) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: { opportunity: IOpportunity, stage: IStage }) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={`${props.params.type.toLowerCase() === "project" ? projectNameChange.Project : props.params.type} (${props.params.school?.country?.toUpperCase() || props.params.project?.region?.toUpperCase()}): ${props.params.school?.name || props.params.project?.name}` || "Add Edit Opportunity"}
          onDismiss={() => callback(undefined)}
          containerClassName="modal-size-sm"
        >
          <AddOpportunity {...props} onSave={(opportunity, stage) => callback({ opportunity, stage })} onCancel={() => callback(undefined)} />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

export const AddOpportunity = ({
  params,
  onCancel,
  onSave,
}: Props) => {
  const dispatch = useDispatch();
  const [opportunity, setOpportunity] = useState<IOpportunity>({ ...params } as IOpportunity);
  const [errors, setErrors] = useState<any>({});
  const [products, setProducts] = useState<IProduct[]>([] as IProduct[]);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number | undefined>()
  const [pipelines, setPipelines] = useState<any>([])
  const [pstages, setpStages] = useState<any>([])
  const opportunityFields = useMemo(() => getOpportunityFields({ opportunity, products, pipelines, pstages }), [opportunity, products, pipelines, pstages]);
  const opportunityFields1 = useMemo(() => getOpportunityFields1({ opportunity, products, pipelines, pstages }), [opportunity, products, pipelines, pstages]);
  const opportunityFields2 = useMemo(() => getOpportunityFields2({ opportunity, products, pipelines, pstages }), [opportunity, products, pipelines, pstages]);
  const opportunityPipelineFields = useMemo(() => getOpportunityPipeLineFields({ opportunity, products, pipelines, pstages }), [opportunity, products, pipelines, pstages]);
  const [isModalOpen, setIsModalOpen] = useState(false); // to open add product modal
  const [activeStage, setActiveStage] = useState<IStage>(opportunity?.school?.activeStage || opportunity?.project?.activeStage || {});
  const [infoModalOpen, setInfoModal] = useState(false);
  const currency = products[0]?.currency || '-'
  const headerRow: IColumn[] = [
    {
      key: "title",
      name: "Product",
      fieldName: "title",
      minWidth: 150
    },
    {
      key: "revenue",
      name: `Revenue[${currency}]`,
      fieldName: "revenue",
      minWidth: 100,
      onRender: (product: ISubProduct, index?: number) => numberToCurrencyFormat(Math.round(product.revenue || 0))
    }, {
      key: "action",
      name: "Action",
      fieldName: "-",
      minWidth: 70,
      onRender: (product: ISubProduct, index?: number) => {
        return (
          <span>
            <span className="action-icons">
              <FontAwesomeIcon
                title="Edit Product"
                onClick={() => {
                  handleOnActionClick(product, index as number);
                }}
                icon={faPencilAlt}
              />
            </span>
            <span className="action-icons">
              <FontAwesomeIcon
                onClick={async () => {
                  const confirm = await ConfirmDialog({
                    dialogContentProps: {
                      title: "Delete Product",
                      closeButtonAriaLabel: "Close",
                      subText: `Are you want to delete?`,
                    },
                  });
                  if (confirm) handleOnDeleteProduct(product, index as number);
                }}
                title="Delete Meter"
                icon={faTrash}
              />
            </span>
          </span>
        );
      },
    },
  ];

  const loadPipeStagesData = async () => {
    try {
      const stages = await getPipelinesStagesApi({ pipelineID: opportunity.pipeline || 16 })
      setpStages(stages.map(p => ({ key: p.id, text: p.name })))
    } catch (error) {

    }
  }

  const loadData = async () => {
    try {
      const promises: any[] = []
      if (opportunity.pipeDriveOrganization) {
        const { organization } = await getPipedriveOrgByIdApi(opportunity.pipeDriveOrganization)
        if (organization?.name && organization?.id) {
          setOpportunity({ ...opportunity, pipeDriveOrganization: { key: organization.id, name: organization.name } })
        }
      }
      promises.push(dispatch(fetchProducts({ region: params?.country || 'GB', all: true })))
      promises.push(getPipelinesApi())
      promises.push(getPipelinesStagesApi({ pipelineID: opportunity.pipeline || 16 }))
      const [data, pipelines = [], stages = []] = await Promise.all(promises)

      if (data?.products) {
        setProducts(data.products)
      }
      setPipelines(pipelines.map((p: { id: any; name: any; }) => ({ key: p.id, text: p.name })))
      setpStages(stages.map((p: { id: any; name: any; }) => ({ key: p.id, text: p.name })))

      if (opportunity.pipedriveDealId) {
        const { deal } = await getPipeLineDealByIdApi(opportunity.pipedriveDealId)
        if(deal) {
          setOpportunity({...opportunity, pipeline: deal.pipeline_id, pipelineStage: deal.stage_id})
        }
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    loadData();
  }, [])

  useEffect(() => {
    loadPipeStagesData();
  }, [opportunity.pipeline])

  const handleOnActionClick = (product: ISubProduct, index: number) => {
    setSelectedProductIndex(index);
    setIsModalOpen(true);
  }

  const handleOnDeleteProduct = (product: ISubProduct, index: number) => {
    setOpportunity((opportunity: any) => {
      let opportunitObj: IOpportunity = _.cloneDeep(opportunity);
      const products = opportunitObj?.products;
      products.splice(index, 1)
      opportunitObj = { ...opportunity, products: [...products] };
      return opportunitObj;
    });
  };

  const totalRevenue = () => {
    const total = opportunity.products.reduce((a: string | number, b: { revenue: string | number; }) => +a + +b.revenue || 0, 0) || 0;
    return Math.round(total || 0);
  }

  const handleOnChangeInput = async (key: string, value: any) => {
    switch (key) {
      case 'stage':
        if (value) setActiveStage({ ...activeStage, 'name': value, date: new Date() });
        break
      case 'activeStage':
        setActiveStage({ ...activeStage, active: value, date: new Date() });
        break
      case 'created.date':
        setOpportunity({
          ...opportunity, created: { user: opportunity.created?.user || null, date: new Date(moment(value).format('YYYY-MM-DD')) }
        });
        break
      case 'pipeline':
        setOpportunity({
          ...opportunity, [key]: value, pipelineStage: 0
        }
        );
        break
      default:
        setOpportunity({ ...opportunity, [key]: value })
        break;
    }
    if (['size', 'investment', 'students'].includes(key)) {
      const tempProducts: ISubProduct[] = []
      for (const product of opportunity.products || []) {
        tempProducts.push({ ...product, revenue: revenueCalc(product, { ...opportunity, [key]: value }, products) })
      }
      setOpportunity({ ...opportunity, [key]: value, products: tempProducts })
    }
  }
  const handleOnSave = async () => {
    const errors: StringMap = await validateOpportunity(opportunity);
    if (Object.keys(errors).length) {
      setErrors(errors);
      if (errors?.products) toast.error(`Products ${errors.products}`)
      return;
    }
    await dispatch(createUpdateOpportunity({ ...opportunity }, { ...activeStage }));
    return onSave({ ...opportunity }, { ...activeStage })
  };

  const handleOnDismissModal = () => {
    setIsModalOpen(false);
    setInfoModal(false)
  };

  const handleOnSaveProduct = (opportunity: IOpportunity) => {
    setOpportunity(opportunity)
    setIsModalOpen(false)
  }

  const handleOnAddProduct = () => {
    setSelectedProductIndex(undefined)
    setIsModalOpen(true)
  };

  const onRenderLabel = (props: IDropdownProps): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <Label>{props.label}</Label>
        <IconButton
          iconProps={{ iconName: 'Info' }}
          title="History"
          ariaLabel="History"
          onClick={() => {
            setInfoModal(true)
          }}
          styles={{ root: { marginBottom: -3 } }}
        />
      </Stack>
    );
  };

  const handleGetPipedriceOrgs = async (
    filter: string,
    selectedItems?: ITag[],
  ): Promise<ITag[]> => {
    try {
      const { organizations } = await getPipedriveOrgApi({ name: filter })
      let data = organizations?.length ? organizations.map((d: any) => ({ key: d.item.id, name: d.item.name })) : []
      return data
    } catch (error) {
      return [] as ITag[]
    }
  }

  const handleSelectPipedriveOrg = async (items?: ITag[]) => {
    handleOnChangeInput(`pipeDriveOrganization`, items?.length && items[0] ? items[0] : null)
  }
  const stage = opportunity.school?.stage || opportunity.project?.stage || []

  return (
    <>
      <div className="edit-record">
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <Dropdown
              label="Stage"
              selectedKey={activeStage.name! || null}
              placeholder="Select Stage"
              options={
                stages.filter((s: { key: string; }) => s.key !== "all") as IDropdownOption[]
              }
              onChange={(e, item) => handleOnChangeInput('stage', item?.key)}
              styles={comboBoxStyles}
              className="ms-Grid-col ms-lg3"
              // @ts-ignore
              onRenderLabel={onRenderLabel}
            />
            {activeStage.name && (
              <div className="ms-Grid-col ms-lg1">
                <Label>Active</Label>
                <Checkbox
                  label=""
                  checked={activeStage.active}
                  onChange={(e, value) => handleOnChangeInput('activeStage', value)}
                />
              </div>
            )}
            <DynamicForm
              fields={opportunityFields}
              data={opportunity}
              errors={errors}
              onChange={handleOnChangeInput}
            />
          </div>
          <div className="ms-Grid-row">
            <DynamicForm
              fields={opportunityFields1}
              data={opportunity}
              errors={errors}
              onChange={handleOnChangeInput}
            />
          </div>
          <div className="ms-Grid-row">
            <DynamicForm
              fields={opportunityFields2}
              data={opportunity}
              errors={errors}
              onChange={handleOnChangeInput}
            />
          </div>
          <div className="ms-Grid-row margin-top-md">
            <div className="ms-Grid-col ms-lg12">
              <Separator>Pipe Drive infomarion</Separator>
            </div>
          </div>
          <div className="ms-Grid-row">
            <DynamicForm
              fields={opportunityPipelineFields}
              data={opportunity}
              errors={errors}
              onChange={handleOnChangeInput}
            />
            <div className={`ms-Grid-col ms-lg3 margin-top-xsm margin-bottom-lg`}>
              <Text className='' style={{ fontSize: "14px", fontWeight: 600, color: "black" }}>Organization <FontIcon title='If any organization is selected it will create new deal for that organization in pipedrive' iconName="info" style={{ color: 'blue', fontSize: 15, cursor: "pointer" }} /></Text>
              <TagPicker
                styles={{ root: { marginTop: 5 } }}
                inputProps={{ placeholder: "Select Organization" }}
                onResolveSuggestions={handleGetPipedriceOrgs}
                selectedItems={opportunity.pipeDriveOrganization ? [opportunity.pipeDriveOrganization] : []}
                itemLimit={1}
                selectionAriaLabel={`Selected Organization`}
                onChange={handleSelectPipedriveOrg}
              />
            </div>
          </div>
          <div className='ms-Grid-row'>
            <TextField
              label="Notes"
              placeholder=""
              onChange={(e: any, value: any) => handleOnChangeInput('comment', value)}
              multiline={true}
              rows={2}
              className='ms-Grid-col ms-lg12'
              value={opportunity?.comment!} />
          </div>
          <div className="ms-Grid-row">
            <span className='margin-top-sm' style={{ display: 'inline-flex', fontWeight: 'bold' }}>Total Revenue: {`${numberToCurrencyFormat(totalRevenue() || 0)} ${currency || '-'}`}</span>
            <ActionButton
              iconProps={addIcon}
              style={{ float: 'right' }}
              allowDisabledFocus
              onClick={() => { handleOnAddProduct() }}
            > Add Product
            </ActionButton>
          </div>
          <div className="ms-Grid-row">
            <DetailsList
              items={opportunity.products || []}
              columns={headerRow}
              selectionMode={SelectionMode.none}
              compact={true}
            />
          </div>

        </div>

        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Stack horizontal horizontalAlign="center">
            <Stack.Item>
              <PrimaryButton
                text="Save"
                onClick={handleOnSave}
                className="btn-primary"
              />
              <DefaultButton onClick={onCancel} text="Cancel" />
            </Stack.Item>
          </Stack>
        </Sticky>
      </div>
      <ModalDialog
        isModalOpen={isModalOpen}
        title={"Add Edit Product"}
        containerClassName={"modal-size-sm"}
        onDismiss={handleOnDismissModal}
      >
        <AddSubProduct
          opportunity={opportunity}
          selectedProductIndex={selectedProductIndex}
          products={products}
          onCancel={handleOnDismissModal}
          onSave={handleOnSaveProduct}
        />
      </ModalDialog>
      {infoModalOpen && (
        <ModalDialog
          isModalOpen={infoModalOpen}
          title={"Stage History"}
          containerClassName={"modal-size-sm"}
          onDismiss={handleOnDismissModal}
        >
          <StageListing stage={stage} />
        </ModalDialog>
      )}
    </>
  );
};

interface OwnProps {
  params?: IOpportunity;
  onCancel: () => void;
  onSave: (opportunity: IOpportunity, stage: IStage) => void;
}

type Props = OwnProps;
