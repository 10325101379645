import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { IInvoiceFilter } from "../../components/admin/invoice/types";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";
import { IInvoiceAccountReportFilter } from "../../components/admin/reporting/invoiceAccountReporting/types";

let GetInvoicePriceAPiAbortController: AbortController

export const getInvoicePricesAPI = (query: any): Promise<any> => {
  if (GetInvoicePriceAPiAbortController) GetInvoicePriceAPiAbortController.abort()
  GetInvoicePriceAPiAbortController = new AbortController()
  return axios.get(`${host}/billing/invoice-price?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetInvoicePriceAPiAbortController.signal }).then(res => res.data)
}

export const getOneInvocePriceAPI = (id: any): Promise<any> => {
  return axios.get(`${host}/billing/invoice-price/get-one/${id}`).then(res => res.data)
}

export const getInvoicePriceEmailPreview = (query: ParsedUrlQueryInput): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/billing/invoice-price/email-preview?${queryStr}`).then(res => res.data)
}

export const saveInvoicePriceEmailAsDraft = (query: ParsedUrlQueryInput): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.post(`${host}/billing/invoice-price/draftMail?${queryStr}`).then(res => res.data)
}

export const exportBillingInvoicePriceApi = (query: IInvoiceFilter): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/billing/invoice-price-export/download?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}
