
import { ITransaction, ITransactionCreateUpdate } from '@solarforschools/sfs-core/dist/funder/transaction/types';
import { generateQueryString } from '../../../utils/util';
import axios from 'axios';
import { host } from '../../config';
import { IBondTransactionFilter } from './../../../components/admin/funder/transaction/types.d';
import { ParsedUrlQueryInput } from 'querystring';

let GetBondTransAPiAbortController: AbortController

export const getBondTransactionsApi = (params: IBondTransactionFilter): Promise<{ transactions: ITransaction[]; totalCount: number }> => {
  const queryStr = generateQueryString({ ...params } as any);
  if (GetBondTransAPiAbortController) GetBondTransAPiAbortController.abort()
  GetBondTransAPiAbortController = new AbortController()
  return axios.get(`${host}/funder/bond-transactions?${queryStr}`, { signal: GetBondTransAPiAbortController.signal }).then((res) => res.data);
};

export const getBondTransactionFilterApi = (): Promise<{ periods: []; auditLogs: [], bonds: [] }> => {
  return axios.get(`${host}/funder/bond-transactions/filter?`).then((res) => res.data);
};

export const exportBondTransactionApi = (query: any): Promise<any> => {
  return axios.get(`${host}/funder/bond-transactions/download?${generateQueryString(query as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const processPaymentApi = (query: any): Promise<any> => {
  return axios.get(`${host}/funder/bond-transactions/process-payment?${generateQueryString(query as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const deleteTransactionApi = (id: any): Promise<any> => {
  return axios.delete(`${host}/funder/bond-transactions/${id}`).then(res => res.data)
}

export const reinvestTransactionApi = (data: any): Promise<any> => {
  const url = data.transactionId ? data.transactionId : ''
  return axios.put(`${host}/funder/bond-transactions/reinvest/${url}`, data).then(res => res.data)
}

export const createTransactionAPi = (data: any): Promise<any> => {
  return axios
    .post(`${host}/funder/bond-transactions`, data)
    .then((res) => res.data);
};

export const updateTransactionApi = (data: ITransactionCreateUpdate): Promise<any> => {
  return axios
    .put(`${host}/funder/bond-transactions/${data.transactionId}`, data)
    .then((res) => res.data);
};

export const getCreateTaxStatementApi = (query: ParsedUrlQueryInput): Promise<{ years: any[]; bondHolders: any[] }> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/funder/bond-transactions/bondholders?${queryStr}`).then((res) => res.data);
};

export const getBondTransactionEmailPreview = (transactionId: string, query: ParsedUrlQueryInput): Promise<any> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/funder/bond-transactions/payment-email/${transactionId}/${query.action}?${queryStr}`).then(res => res.data)
}

export const sendBondTransactionEmailApi = (transactionId: string, query: ParsedUrlQueryInput): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.post(`${host}/funder/bond-transactions/payment-email/${transactionId}?${queryStr}`).then(res => res.data)
}

export const processPaymentByTidApi = (transactionId: any, payload: any): Promise<any> => {
  const queryStr = generateQueryString(payload as ParsedUrlQueryInput)
  return axios.get(`${host}/funder/bond-transactions/process-payment/${transactionId}?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}

export const downloadBondCertificateApi = (tid: any): Promise<any> => {
  return axios.get(`${host}/funder/bond-transactions/downloadTaxStatement/${tid}`, { responseType: 'arraybuffer' }).then(res => res)
}

