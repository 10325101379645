import { TextField } from '@fluentui/react';
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import React, { FormEvent, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import store from '../../../../store';
import { addToGlossary, deleteGlossary, editGlossary } from '../../../../store/content/action';
import { IGlossaryItem } from '../../../../store/content/actionTypes';


export const showGlossaryForm = (props: ShowGlossaryFormProps) => {
  const div = document.createElement('div')
  document.body.appendChild(div)
  const handleClose = () => {
    div.parentElement?.removeChild(div)
  }
  ReactDOM.render(
    <Provider store={store} >
      <div className="glossary-form-modal" onClick={handleClose}>
        <GlossaryItemForm
          {...props}
          onClose={handleClose}
        />
      </div>
    </Provider>,
    div
  )
}

const GlossaryItemForm = (props: GlossaryItemFormProps) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<IGlossaryItem>({ word: '', description: '' })

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrors({ word: '', description: '' })
    const target = e.target as any
    const data: IGlossaryItem = {
      word: target.word.value,
      description: target.description.value
    }
    if (!data.word || !data.description) {
      const errors = { word: '', description: '' }
      if (!data.word) {
        errors.word = 'Enter a word'
      }
      if (!data.description) {
        errors.description = 'Please enter the definition'
      }
      return setErrors(errors)
    }
    if (props.id) {
      await dispatch(editGlossary(props.id, data))
    } else {
      await dispatch(addToGlossary(data))
    }
    props.onClose()
  }

  const handleClickDelete = async () => {
    await dispatch(deleteGlossary(props.id!))
    props.onClose()
  }

  return (
    <form
      className="glossary-item-form"
      onSubmit={handleSave}
      onClick={e => e.stopPropagation()}
    >
      <TextField
        label="Word"
        name='word'
        defaultValue={props.initialData?.word}
        errorMessage={errors.word}
      />
      <TextField
        name="description"
        defaultValue={props.initialData?.description}
        label="Description"
        multiline
        autoAdjustHeight
        errorMessage={errors.description}
      />
      <footer>
        {props.id ? (
          <IconButton
            iconProps={{ iconName: 'Delete' }}
            onClick={handleClickDelete}
          />
        ) : null}
        <div style={{display: 'flex', flex: 1}} />
        <DefaultButton
          text="Cancel"
          onClick={props.onClose}
          style={{ marginRight: 10 }}
        />
        <PrimaryButton
          type="submit"
          text="Save"
        />
      </footer>
    </form>
  );
}

interface ShowGlossaryFormProps {
  id?: string
  initialData?: IGlossaryItem
}

interface GlossaryItemFormProps extends ShowGlossaryFormProps {
  onClose: () => void
}

export default GlossaryItemForm