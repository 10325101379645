import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { CardContext, CardContextType } from "../../layouts/index";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";
import { getElecGenValues, conversionInY, tooltipConversion } from "../../utils/tileHelper";

export const FrontContent = () => {
    const { electricityGenData } = useContext(CardContext) as CardContextType;
    //const [status, setStatus] = useState({ prevMonth: "", isEqual: "" });
    const [electricityGenerated, setElectricityGenerated] = useState(electricityGenData?.G)
    const getStatus = () => {
        if (electricityGenData !== undefined) {
            setElectricityGenerated(electricityGenData?.G)
        }
        // if (electricityGenData !== undefined) {
        //     const g = electricityGenData.lastSixMonths.map(e => e.g)
        //     const elecGen = getElecGenValues(g)
        //     if (elecGen[4] > elecGen[5]) {
        //         setStatus({ ...status, prevMonth: "high", isEqual: "" });
        //     } else if (elecGen[4] < elecGen[5]) {
        //         setStatus({ ...status, prevMonth: "low", isEqual: "" });
        //     } else {
        //         setStatus({ ...status, isEqual: "yes", prevMonth: "" });
        //     }
        // }
    }

    useEffect(() => {
        getStatus();
    }, [electricityGenData])

    //const countColor = (status?.isEqual === "yes") ? { color: "#FFCB2F", fontSize: "3rem" } : ((status?.prevMonth === "high") ? { color: "red", fontSize: "3rem" } : { color: "green", fontSize: "3rem" })

    return <>

        <div className="card-content">
            <div className="card-content--count" style={{ color: "#243f61", fontSize: "3rem" }} >
                {electricityGenerated}
            </div>
            {/* <div>
                {status?.isEqual === "yes"
                    ? <RightTriangleIcon fontSize="2rem" />
                    : status?.prevMonth === "high"
                        ? <DownTriangleIcon fontSize="2rem" />
                        : <UpTriangleIcon fontSize="2rem" />}
            </div> */}
        </div>

    </>
}

export const BackContent = () => {
    const { electricityGenData } = useContext(CardContext) as CardContextType;
    const labels = electricityGenData?.regions.map(e => e.country)
    const elecGen = electricityGenData?.regions.map(e => e.G)
    let elecGenValues
    if (elecGen !== undefined)
        elecGenValues = getElecGenValues(elecGen)
    const data = {
        labels,
        datasets: [
            {
                label: "G",
                data: elecGenValues,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                borderColor: "#909090",
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return tooltipConversion(context)
                    },
                },
            }

        },
        scales: {
            y: {
                ticks: {
                    callback: (value: any) => {
                        return conversionInY(value)
                    }
                }
            },
            x: {
                ticks: {
                    display: false
                }
            }
        }
    };

    return <>
        <div className="flex-column-center">
            <div style={{ height: "90px", width: "250px" }}>
                <Line data={data} options={options} />
            </div>
        </div>

    </>
}