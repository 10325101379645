import { DatePicker, DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getGeneralFields } from "./helper"
import { IRTReading, IRTYield } from "@solarforschools/sfs-core/dist/solardb/types"
import SystemPicker from "../common/SystemPicker"
import moment from "moment"
import { dropdownStyles } from "../common/common"
import { toast } from "react-toastify"
import { deleteMultipleRTReading, deleteMultipleRTYields } from "../../../store/admin/rtYields/action"


export const delRTYieldsModal = (props?: any) => {
  return new Promise<IRTYield | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: IRTYield | IRTReading) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Delete RtYields"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <DelRTYieldDialog
            {...props}
            onSave={(data: IRTYield | undefined) => callback(data)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const DelRTYieldDialog = (props: YieldsProps) => {
  const dispatch = useDispatch();

  const [payload, setPayload] = useState<any>({ systemId: null, start: moment().subtract(5, "day").format("YYYY-MM-DD"), end: moment().subtract(1, 'day').format("YYYY-MM-DD") })
  const handleOnSave = async () => {
    if (!payload.systemId || payload.systemId === "all") {
      toast.error("Please seletect a system");
      return
    }
    if (props.type === "rtReading") {
      dispatch(deleteMultipleRTReading(payload))
    } else {
      dispatch(deleteMultipleRTYields(payload))
    }
  }


  const handleOnChangeInput = (key: string, value: any) => {

    let params: any = _.cloneDeep(payload);
    switch (key) {
      case 'selectedSystems':
        params = { ...params, systemId: value[0]?.key }
        setPayload(params)
        break
      default:
        setPayload(_.set({ ...params }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg12 margin-top-xsm">
            <SystemPicker selectedItems={[{ key: 'all', name: 'All' }]} onChange={handleOnChangeInput} />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg12 margin-top-xsm">
            <DatePicker
              label="Start Date"
              placeholder="Select a date..."
              ariaLabel="Select a date"
              value={new Date(payload.start!)}
              onSelectDate={(date) => handleOnChangeInput('start', moment(date).startOf('day').format('YYYY-MM-DD'))}
              styles={dropdownStyles}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg12 margin-top-xsm">
            <DatePicker
              label="Start Date"
              placeholder="Select a date..."
              ariaLabel="Select a date"
              value={new Date(payload.end!)}
              onSelectDate={(date) => handleOnChangeInput('end', moment(date).startOf('day').format('YYYY-MM-DD'))}
              styles={dropdownStyles}
            />
          </div>
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Confirm"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface YieldsProps {
  onCancel?: () => void;
  onSave: (data?: IRTYield | IRTReading) => void;
  data?: IRTYield | IRTReading;
  type?: "rtReading" | "rtYield"
}

export default delRTYieldsModal;
