import { Action } from "./actionTypes";
import { IYield } from "@solarforschools/sfs-core/dist/solardb/types";
import { IYieldsFilter } from "../../../components/admin/yields/types";
import moment from "moment";

// States' definition
export interface IYieldsState {
  isLoading: boolean;
  yields: IYield[];
  yieldsFilter: IYieldsFilter;
  totalCount: number;
}
export interface State {
  yields: IYieldsState;
}

const yields = (
  state: IYieldsState = {
    isLoading: false,
    yields: [],
    yieldsFilter: {
      page: 1,
      pageSize: 50,
      sortField: "date",
      sortOrder: "desc",
      startEnd: 'no',
      start: moment().subtract(5, 'day').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      ownedBy: "all"
    },
    totalCount: 0,
  },
  action: Action
): IYieldsState => {
  switch (action.type) {
    case "SET_YIELDS_FILTER":
      return { ...state, yieldsFilter: action.yieldsFilter };
    case "SET_YIELDS_LIST":
      const yields = action.reset
        ? action.yields
        : [...state.yields, ...action.yields];
      return { ...state, yields, totalCount: action.totalCount };
    case "DELETE_YIELDS": {
      const stateObj = { ...state };
      const yields: IYield[] = stateObj.yields.filter(
        (s) => s._id !== action.yields._id
      );
      const yieldsFilter = { ...stateObj.yieldsFilter };
      const { page, pageSize } = yieldsFilter;
      return {
        ...state,
        yields,
        totalCount: stateObj.totalCount - 1,
        yieldsFilter: {
          ...yieldsFilter,
          page: Math.ceil((yields.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_YIELDS": {
      const yields = [...state.yields];
      const index = yields.findIndex(
        (s) => s._id === action.yields?._id
      );
      if (index > -1) {
        yields[index] = action.yields;
      } else {
        yields.unshift(action.yields);
      }
      return {
        ...state,
        yields,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default yields;
