import { useContext, useEffect, useState } from 'react'
import { CardContext, CardContextType } from '../../layouts';
import { Chart, registerables } from "chart.js";
import { Doughnut } from 'react-chartjs-2';

const EthexCardContent = () => {
    const { ethexData } = useContext(CardContext) as CardContextType;
    const [chartData, setChartData] = useState<any>({})

    useEffect(() => {
        if (ethexData !== undefined) {
            const { raisedSoFar, targetAmt } = ethexData
            const raisedAmt = +raisedSoFar.replaceAll("£", "").replaceAll(",", "")
            const _targetAmt = +targetAmt.replaceAll("£", "").replaceAll(",", "")
            let receivedAmtPercent, balanceAmtPercent

            receivedAmtPercent = +((raisedAmt * 100) / _targetAmt).toFixed(2);
            if (receivedAmtPercent > 100) {
                balanceAmtPercent = 0
            } else {
                balanceAmtPercent = 100 - receivedAmtPercent;
            }
           
            setChartData({ receivedAmtPercent, balanceAmtPercent })

        }

    }, [ethexData])


    if (ethexData === undefined || Object.keys(chartData).length === 0) return <></>

    Chart.register(...registerables);


    const data = {
        labels: ["Remaining", "Raised",],
        datasets: [
            {
                data: [chartData.balanceAmtPercent || 0, chartData.receivedAmtPercent || 0],
                backgroundColor: [
                    "#243f61",
                    "#FFCB30",

                ],
                borderColor: [
                    "#243f61",
                    "#FFCB30",

                ],
                borderWidth: 0
            },
        ],
    };

    const options = {
        cutout: 35,
        responsive: true,
        maintainAspectRatio: false,
        zindex: -100,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },

            tooltip: {
                callbacks: {
                    label: function (content: any) {
                        const label = content.label;
                        const value = content.formattedValue + " %";
                        return `${label} ${value}`;
                    }
                },
            }
        },
        datalabels: {
            display: false,
        },
        centerLabel: {
            enabled: true,
            fontColor: '#000000',
            fontSize: 20,
            content: 'Center Label',
        },

    };

    const plugins = [{
        id: "text",
        beforeDraw: function (chart: any, a: any, b: any) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;

            ctx.restore();
            ctx.font = "1" + "rem sans-serif";
            ctx.fillStyle = "#243f61"
            ctx.textBaseline = "middle";
            var text = chartData.receivedAmtPercent + " %" ?? "0 %",
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.save();
        },
    }]

    return (
        <>
            <div className="flex-row-center" style={{ height: "6rem", gap: "1.5rem", }}>
                <div style={{ height: "100px", width: "250px" }}>
                    <Doughnut data={data} options={options as any} plugins={plugins} />
                </div>
            </div>

        </>
    )
}

export default EthexCardContent