import { ComboBox, IComboBoxOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getPortfolioes, setPortfolioFilter } from '../../../../store/admin/funder/portfolio/action';
import { IPortfolioState } from '../../../../store/admin/funder/portfolio/reducer';
import { comboBoxStyles, FundingOptions } from '../../common/common';

const PortfolioFilters = (props: YieldFilterProps) => {
    const dispatch = useDispatch()
    const { portfolioFilter }: IPortfolioState = useSelector<RootState, IPortfolioState>((state) => state.web.portfolio)

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        let params = { ...portfolioFilter, page: 1, [key]: value };

        dispatch(setPortfolioFilter(params));
        dispatch(getPortfolioes(params));
    }


    const { status, name, owner } = portfolioFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="School"
                    placeholder="Enter School"
                    onChange={(e, value) => handleOnChange('name', value || '')}
                    className="ms-Grid-col ms-lg5"
                    value={name!} />
                <ComboBox
                    label="Owner"
                    placeholder=""
                    selectedKey={owner || "all"}
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={FundingOptions as IComboBoxOption[]}
                    onChange={(e, item?: IComboBoxOption) =>
                        handleOnChange("owner", item?.key || 'all')
                    }
                    className="ms-Grid-col ms-lg3"
                    styles={comboBoxStyles}
                />
                <ComboBox
                    label="Status"
                    placeholder=""
                    selectedKey={status || "all"}
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={[{ key: 'all', text: 'All' }, { key: "TBD", text: "Upcomming" }] as IComboBoxOption[]}
                    onChange={(e, item?: IComboBoxOption) =>
                        handleOnChange("status", item?.key || 'all')
                    }
                    className="ms-Grid-col ms-lg3"
                    styles={comboBoxStyles}
                />
            </div>
        </div>
    );
}

interface YieldFilterProps {

}

export default PortfolioFilters;

