import { ActionButton, Text } from '@fluentui/react';
import React from 'react';
import { openDialog1 } from './Add';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ITeam } from '../../../store/admin/team/reducer';
import { ITeam as ITeamDB } from "@solarforschools/sfs-core/dist/team/types";
import { awsUrlHost } from '../../../store/config';
import { openDialog2 } from './Edit';

const Lists = () => {
    const { data, isLoading }: ITeam = useSelector<RootState, ITeam>((state: RootState) => state.web.team);

    const handleAddMember = async () => {
        await openDialog1()
    }

    const editTeamMember = async (data: ITeamDB) => {
        await openDialog2(data)
    }



    return (
        <>
            <div style={{ textAlign: "right" }} >
                <ActionButton
                    iconProps={{ iconName: "Add" }}
                    style={{
                        color: "var(--solar-blue)"
                    }}
                    onClick={handleAddMember}
                >
                    Add Member
                </ActionButton>
            </div>
            {!isLoading && <div style={{ margin: "0 0 10px 5px" }}>
                {
                    data?.length > 0 && <Text variant='medium'>
                        Displaying: <span style={{ fontWeight: 600 }}>{data.length}</span> </Text>
                }
            </div>}

            {
                !isLoading && data.length === 0 ? (<Text variant='mediumPlus' className='text-center'>No data found</Text>) : (
                    <div className='team-container'>
                        {
                            data.map((elm, index) => {
                                const { name, description, designation, pic, isActive } = elm;
                                let imageURL = `${awsUrlHost}/${pic}`
                                return (
                                    <div key={index} className='flex-item' style={!isActive ? { opacity: "0.5" } : {}}>
                                        <section className='avatar'>
                                            <img
                                                src={imageURL}
                                                style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                                alt={`${name}-pic`}
                                            />
                                        </section>

                                        <div className='edit'>
                                            <ActionButton
                                                iconProps={{ iconName: "Edit" }}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { editTeamMember(elm) }}
                                            />
                                        </div>

                                        <section>
                                            <div className='text-center'>
                                                <Text variant='large'>{name} </Text>
                                            </div>

                                            <div className='text-center' >
                                                <Text variant='medium'>{designation} </Text>
                                            </div>
                                            <div className='description' style={{ display: "flex", justifyContent: "center" }}>
                                                <Text variant='medium'>{description}</Text>
                                            </div>
                                        </section>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }

        </>
    )
}

export default Lists