import { useEffect, useState } from "react";
import "./content.css";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import {
  changeScreen,
  getModules,
  changeContentTemplate,
  changeQuestionTemplate,
} from "../../../store/content/action";
import { logoutUser, registerAuthEvent } from "../../../store/admin/action";
import AddPopup from "./Popup";
import {
  IContentTemplate,
  IQuestionTemplate,
  IScreen,
  Screens,
} from "../../../store/content/actionTypes";
import Modules from "./Modules";
import Units from "./Units";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan, faBook } from "@fortawesome/free-solid-svg-icons";
import Template from "./Template";
import QCTemplate from "./QCTemplate";
import ContentTemplate from "./content/ContentTemplate";
import QuestionTemplate from "./question/QuestionTemplate";
import WhatsNext from "./WhatsNext";
import AddorEditTemplate from "./AddorEditTemplate";
import { TITLE } from "./config";
import { IAdmin } from "../../../store/admin/reducer";
import { Link } from "react-router-dom";
import { IAuthEvent } from "../../../store/admin/actionTypes";
import RearrangeScreens from "./RearrangeScreens";
import GlossaryPanel from "./GlossaryPanel";
import Loader from "../../admin/common/Loader";

const ContentHome = ({
  getModules,
  changeScreen,
  content,
  web,
  registerAuthEvent,
  logoutUser,
  changeContentTemplate,
  changeQuestionTemplate,
}: Props) => {
  const [isGlossaryOpen, setGlossaryVisible] = useState(false);

  useEffect(() => {
    getModules();
  }, [getModules]);

  let screenName = "";
  let headerTitle = "";
  const renderComponent = (content: IContent) => {
    switch (content.screen?.currentScreen) {
      case Screens.MODULE:
        return <Modules content={content} />;
      case Screens.UNIT:
        return <Units content={content} />;
      case Screens.ADD_OR_EDIT_TEMPLATE:
        return <AddorEditTemplate />;
      case Screens.TEMPLATE_SELECTION:
        return <Template isQuiz={content.screen?.isQuiz || false} />;
      case Screens.CONTENT_TEMPLATE_SELECTION:
        return <QCTemplate type="content" />;
      case Screens.QUESTION_TEMPLATE_SELECTION:
        return <QCTemplate type="question" />;
      case Screens.ADDING_CONTENT:
        return <ContentTemplate name={content.screen.cTemplate} />;
      case Screens.ADDING_QUESTION:
        return <QuestionTemplate name={content.screen.qTemplate} />;
      case Screens.WHATS_NEXT:
        return <WhatsNext />;
      case Screens.REARRANGE_SCREENS:
        return <RearrangeScreens />;
    }
  };

  switch (content.screen?.currentScreen) {
    case Screens.MODULE:
      screenName = "Module";
      headerTitle = `Welcome to the content adder, ${web.user?.name}`;
      break;
    case Screens.UNIT:
      let currentModule = content.modules?.filter(
        (module) => module.id === content.screen?.moduleID
      )[0];
      headerTitle = `Module Selected : ${currentModule?.name}`;
      screenName = "Unit";
      break;
    case Screens.TEMPLATE_SELECTION:
    case Screens.CONTENT_TEMPLATE_SELECTION:
    case Screens.QUESTION_TEMPLATE_SELECTION:
    case Screens.ADDING_CONTENT:
    case Screens.ADDING_QUESTION:
    case Screens.WHATS_NEXT:
    case Screens.ADD_OR_EDIT_TEMPLATE:
    case Screens.REARRANGE_SCREENS:
      let currentUnit = content.units?.filter(
        (unit) => unit.id === content.screen?.unitID
      )[0];
      let cModule = content.modules?.filter(
        (module) => module.id === currentUnit?.moduleID
      )[0];
      headerTitle = `${cModule?.name} Module, ${currentUnit?.name} Unit`;
      break;
  }

  const handleBackButton = (e: React.MouseEvent) => {
    e.stopPropagation();
    switch (content.screen?.currentScreen) {
      case Screens.UNIT:
        changeScreen({
          currentScreen: Screens.MODULE,
          title: TITLE.MODULE,
          moduleID: "",
          unitID: "",
        });
        break;
      case Screens.ADD_OR_EDIT_TEMPLATE:
        changeScreen({
          ...content.screen,
          currentScreen: Screens.UNIT,
          title: TITLE.UNIT,
        });
        break;
      case Screens.TEMPLATE_SELECTION:
        changeScreen({
          ...content.screen,
          currentScreen: Screens.ADD_OR_EDIT_TEMPLATE,
          title: TITLE.ADD_EDIT_TEMPLATE,
        });
        break;

      case Screens.QUESTION_TEMPLATE_SELECTION:
      case Screens.CONTENT_TEMPLATE_SELECTION:
        changeScreen({
          ...content.screen,
          currentScreen: Screens.TEMPLATE_SELECTION,
          title: TITLE.TEMPLATE_SELECTION,
          template: "",
        });
        break;
      case Screens.ADDING_CONTENT:
        changeScreen({
          ...content.screen,
          currentScreen: Screens.CONTENT_TEMPLATE_SELECTION,
          title: TITLE.CONTENT_TEMPLATE_SELECTION,
          cTemplate: "",
        });
        changeContentTemplate(null);
        break;
      case Screens.ADDING_QUESTION:
        changeScreen({
          ...content.screen,
          currentScreen: Screens.QUESTION_TEMPLATE_SELECTION,
          title: TITLE.QUESTION_TEMPLATE_SELECTION,
          qTemplate: "",
        });
        changeQuestionTemplate(null);
        break;
      case Screens.REARRANGE_SCREENS:
        changeScreen({
          ...content.screen,
          currentScreen: Screens.ADD_OR_EDIT_TEMPLATE,
          title: TITLE.ADD_EDIT_TEMPLATE,
        });
    }
  };

  const renderGlossaryButton = () => {
    if (
      ![Screens.ADDING_CONTENT, Screens.ADDING_QUESTION].includes(
        content.screen?.currentScreen!
      )
    ) {
      return null;
    }
    return (
      <div className="glossary-btn" onClick={() => setGlossaryVisible(true)}>
        <FontAwesomeIcon
          title="Glossary"
          style={{ fontSize: 25 }}
          icon={faBook}
        />
      </div>
    );
  };
  
  return (
    <main className="container content-root">
      <Loader />
      <section className="container-heading">
        <div className="nav-btn">
          <Link to="/content/glossary">
            <FontAwesomeIcon
              title="Glossary"
              style={{ fontSize: 25, color: "white" }}
              icon={faBook}
            />
          </Link>
        </div>
        <h1>{headerTitle}</h1>
      </section>
      <figure>
        <img className="sfs-logo-footer" src="/images/sfs-logo.png" alt="" />
      </figure>
      <div className="container-body-wrapper">
        <section className="container-body">
          <div className="container-header">
            <h2>{content.screen?.title}</h2>
            {renderGlossaryButton()}
          </div>
          <div className="modules-wrapper">{renderComponent(content)}</div>
        </section>
        {content.screen?.currentScreen !== Screens.MODULE &&
          content.screen?.currentScreen !== Screens.WHATS_NEXT ? (
          <span className="back-button" onClick={handleBackButton}>
            <FontAwesomeIcon icon={faLessThan} />
          </span>
        ) : null}
        {(content.isShowPopup || content.isEditPopup) && <AddPopup type={screenName} />}
      </div>
      <GlossaryPanel
        isOpen={isGlossaryOpen}
        dismissPanel={() => setGlossaryVisible(false)}
      />
    </main>
  );
};

interface DispatchProps {
  getModules: () => void;
  changeScreen: (screen: IScreen) => void;
  logoutUser: () => void;
  registerAuthEvent: (registerAuthEvent: IAuthEvent) => void;
  changeContentTemplate: (template: IContentTemplate | null) => void;
  changeQuestionTemplate: (template: IQuestionTemplate | null) => void;
}

interface StateProps {
  content: IContent;
  web: IAdmin;
}
interface IRootState {
  learning: any;
  web: any;
}

interface OwnProps { }

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
  web: state.web.admin,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    getModules: async () => {
      await dispatch(getModules());
    },
    changeScreen: async (screen: IScreen) => {
      await dispatch(changeScreen(screen));
    },
    logoutUser: async () => {
      await dispatch(logoutUser());
    },
    registerAuthEvent: async (event: IAuthEvent) => {
      await dispatch(registerAuthEvent(event));
    },
    changeContentTemplate: async (template: IContentTemplate | null) => {
      await dispatch(changeContentTemplate(template));
    },
    changeQuestionTemplate: async (template: IQuestionTemplate | null) => {
      await dispatch(changeQuestionTemplate(template));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(ContentHome);
