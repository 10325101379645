import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn, Selection } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { getYieldsColumns } from './Yieldhelper';
import DetailsListWapper from '../../common/DetailsListWapper';
import { IYield } from '@solarforschools/sfs-core/dist/solardb/types';
import { IYieldsState } from '../../../store/admin/yields/reducer';
import { deleteYields, getYields, setYieldsFilter } from '../../../store/admin/yields/action';
import openYieldsModal from './YieldsDialog';
import Pagination from '../common/Pagination';

const YieldsList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { yields, totalCount, yieldsFilter }: IYieldsState = useSelector<RootState, IYieldsState>((state: RootState) => state.web.yields)
  const [selectedList, setSelectedList] = useState<any>([])
  const handleOnDelete = async (data: IYield) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Yield",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete`,
      },
    });
    if (confirm) dispatch(deleteYields(data));
  };

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedList(selection.getSelection())
    }
  })

  const handleOnEdit = async (data: IYield) => {
    await openYieldsModal({ data })
  };

  const headerRow = getYieldsColumns({
    actions: [
      {
        icon: 'SingleColumnEdit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        onClick: handleOnDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...yieldsFilter, sortField: field, sortOrder: order };
    dispatch(setYieldsFilter(params));
    dispatch(getYields(params))
  }
  const loadNextPage = (p: any) => {
    dispatch(setYieldsFilter({ ...yieldsFilter, ...p }))
    dispatch(getYields())
  }

  return (
    <>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={yields}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={yields.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={yieldsFilter?.page!}
            pageSize={yieldsFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default YieldsList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

