import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useState } from "react";
import { getSystems } from "../../../store/client/system";
import { IEstablishment, ISystem } from "@solarforschools/sfs-core";
import CustomTagPicker from "./CustomTagPicker";
import { getSchoolsApi } from "../../../store/client/school";
import { getBondOffersApi } from "../../../store/client/funder/bondOffer";
import { IBondOffer } from "@solarforschools/sfs-core/dist/funder/bond-offer/types";

interface BondOfferPickerProps {
    filters?: { [key: string]: any; }
    onChange: (key: string, items?: ITag[]) => void
}

const BondOfferPicker: FunctionComponent<BondOfferPickerProps> = ({
    filters = {},
    onChange
}) => {
    const [selectedBondOffer, setSelectedBondOffer] = useState<ITag[]>([])

    const handleGetBondOffer = async (
        str: string,
        selectedItems?: ITag[],
    ): Promise<ITag[]> => {
        try {
            const { bondOffers } = await getBondOffersApi({ ...filters, name: str })
            let data = bondOffers?.length ? bondOffers.map((s: IBondOffer) => ({ key: s._id?.toString()|| "", name: `${s.name}` })) : []
            return data
        } catch (error) {
            return [] as ITag[]
        }
    }
    const handleOnChangeInput = async (key: string, value: any) => {
        (value.length) ? setSelectedBondOffer(value) : setSelectedBondOffer([])
        onChange(key, value)
    }

    return (
        <CustomTagPicker
            itemLimit={1}
            label="Bond Offers"
            placeholder="Choose Bond Offer"
            selectionAriaLabel="Choose Bond Offer"
            selectedItems={selectedBondOffer}
            onResolveSuggestions={handleGetBondOffer}
            onChange={(items?: ITag[]) => handleOnChangeInput('selectedBondOffer', items)} />
    );
}

export default BondOfferPicker;
