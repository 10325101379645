import { toast } from "react-toastify";
import axios, { AxiosRequestConfig } from "axios";
import { isLoading } from "../action";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../..";
import { host } from "../../config";
import { IEnvConfig } from "@solarforschools/sfs-core";

export const setEnvConfigsList = (envConfigList: IEnvConfig[]) => {
  return { type: "SET_ENV_CONFIG", envConfigList };
};

export const updateEnvConfigList = (envConfigList: IEnvConfig) => {
  return { type: "UPDATE_ENV_CONFIG", envConfigList };
};

export const updateEnvConfig = (
  envConfig: IEnvConfig
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => {
    try {
      dispatch(isLoading(true));
      const res: AxiosResponse<any> | undefined =
        envConfig._id &&
        (await axios.put(`${host}/configs/env/update`, envConfig));

      res?.data.msg &&
        toast.success("Environment Configuration Updated Successfully");
      if (res?.data.hasOwnProperty("error")) {
        dispatch(isLoading(false));
        return;
      }

      dispatch(updateEnvConfigList(res?.data.msg));
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};
export const fetchEnvConfig = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchConfigThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${host}/configs/env`);
      dispatch(setEnvConfigsList(res.data));
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error ", err);
      dispatch(isLoading(false));
    }
  };
};

interface AxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
}
