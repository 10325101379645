import {
  CheckboxVisibility,
  DetailsList,
  IColumn
} from "@fluentui/react";
import { SelectionMode } from "@fluentui/react/lib/index";
import { IEstablishment } from '@solarforschools/sfs-core/dist/school/types';
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createUpdateSchool } from "../../../store/admin/school/action";
import { fetchSystems } from "../../../store/admin/system/action";
import { getSchools, getSchoolsPostApi } from "../../../store/client";
import { getOpportunityApi } from "../../../store/client/opportunity";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { SetDetailListHeaderSortDirection } from "../common/utils";
import { OpenOpportunityModal } from "../opportunity/AddOpportunity";
import { initOpportunity } from "../opportunity/helper";
import { openEditSchoolModal } from "./EditSchoolDialog";
import { getSchoolColumns } from "./helper";

const SchoolList = (props: SchoolListProps) => {
  const dispatch = useDispatch();
  
  const [schools, setSchools] = useState(props.schools)
  const [columns, setColumns] = useState<IColumn[]>(getSchoolColumns({
    actions: [
      {
        icon: "Money",
        onClick: handleEditOpportunity
      }, {
        icon: "Edit",
        onClick: handleEditSchool
      },
      ...props.extraActions,
    ]
  }))

  useEffect(() => {
    setSchools(props.schools)
  }, [props.schools])

  async function handleEditSchool(school: IEstablishment) {
    const result = await openEditSchoolModal({
      school,
      onFetchSystem: () => dispatch(fetchSystems("all", false)),
      onCancel: () => null,
      onSave: () => null,
    });
    if (result) {
      await dispatch(createUpdateSchool(result))
      const updated = [...props.schools];
      const index = props.schools.findIndex(x => x.slug === school.slug);
      updated[index] = result;
      props.onUpdated(updated);
    }
  }

  async function handleEditOpportunity(school: IEstablishment) {
    let { opportunity, totals }: any = await getOpportunityApi(school.slug, 'School')
    if (!opportunity) opportunity = initOpportunity(school, 'School', totals)
    await OpenOpportunityModal({ params: opportunity })
  }

  const handleOnHeaderClick = async (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined, column?: IColumn) => {
    try {
      if (column?.key === "action") return;
      const index = columns.findIndex((c) => c.key === column?.key);
      const newColumns = SetDetailListHeaderSortDirection(column, columns);
      setColumns(newColumns);
      const data = await getSchoolsPostApi({
        slug: props.schools.map(s => s.slug), all: true, sortField:
          newColumns[index].key,
        sortOrder: newColumns[index].isSortedDescending ? "desc" : "asc"
      });
      setSchools(data)
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <DetailsList
        items={schools}
        columns={columns}
        selectionMode={SelectionMode.none}
        onRenderDetailsHeader={DetailListStickyHeader}
        compact={true}
        checkboxVisibility={CheckboxVisibility.always}
        onColumnHeaderClick={handleOnHeaderClick}
      />
    </>
  );
};

interface SchoolListProps {
  actions: string[];
  extraActions: { icon: string, onClick: (school: IEstablishment) => any }[];
  schools: IEstablishment[];
  onUpdated: (schools: IEstablishment[]) => void
}

export default SchoolList;
