import { useEffect, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Dropdown, IDropdownOption, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import { dropdownStyles, FundingOptions, } from '../common/common'
import { ICreateInvoicePrice } from './types';
import { DropdownParams } from '../common/types';
import { fetchNewInvoiceData } from '../../../store/admin/invoice/action';
import { Provider, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import store from '../../../store';
import ModalDialog from '../common/ModalDialog';
import moment from 'moment';
import BillingContractPicker from '../common/BillingContractPicker';
import { createInvoicePrice } from '../../../store/admin/invoicePrice/action';
import { DatePicker } from '@fluentui/react/lib/DatePicker';

export const OpenGeneratePriceModal = () => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Generate Price Invoice"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-sm"
                >
                    <AddInvoicePriceDialog onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export const AddInvoicePriceDialog = ({
    onCancel,
}: Props) => {
    const dispatch = useDispatch()
    const [invoicePriceParams, setInvoicePriceParams] = useState<ICreateInvoicePrice>({
        contractId: 'all',
        funder: "CBS",
        year: parseInt(moment().format("YYYY")),
    })

    const [contracts, setContracts] = useState<DropdownParams[]>([])

    const loadData = async () => {
        const { contracts }: any = await dispatch(fetchNewInvoiceData())
        setContracts(contracts as DropdownParams[])
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleOnChangeInput = (key: string, item: any) => {
        switch (key) {
            case 'selectedContract':
                setInvoicePriceParams({ ...invoicePriceParams, contractId: item[0]?.key })
                break
            case 'year':
                setInvoicePriceParams({ ...invoicePriceParams, year: item.getFullYear() })
                break
            default:
                setInvoicePriceParams({ ...invoicePriceParams, [key]: item as string })
                break;
        }
    }

    const handleOnSave = () => {
        dispatch(createInvoicePrice(invoicePriceParams as ICreateInvoicePrice))
    }
    const onDateSelect = (date: any) => {
        if (date) {
            setInvoicePriceParams({ ...invoicePriceParams, year: date.getFullYear() })
        }
    };

    const formatDate = (date: any) => {
        return date ? date.getFullYear().toString() : '';
    };
    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <section className='margin-top-md'>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg6">
                            <DatePicker
                                label="Select Year"
                                placeholder="Select a year"
                                onSelectDate={onDateSelect}
                                formatDate={formatDate}
                                value={new Date(invoicePriceParams!.year!, 0, 1)}
                                // Set showMonthPickerAsOverlay to false to hide the month and day picker
                                showMonthPickerAsOverlay={false}
                                // Set isDatePickerVisible to false to hide the date picker icon
                                isMonthPickerVisible={false}
                            />
                        </div>
                        <div className="ms-Grid-col ms-lg6">
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg6">
                            <BillingContractPicker selectedItems={[{ key: 'all', name: 'All' }]} onChange={handleOnChangeInput} contracts={contracts} />
                        </div>
                        {invoicePriceParams.contractId === 'all' && (
                            <div className="ms-Grid-col ms-lg6">
                                <Dropdown
                                    label="Funder"
                                    placeholder="Select contract (optional)"
                                    selectedKey={invoicePriceParams?.funder || 'CBS'}
                                    onChange={(e, item) => handleOnChangeInput("funder", item?.key)}
                                    options={FundingOptions as IDropdownOption[]}
                                    styles={dropdownStyles}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item className="margin-top-lg">
                        {invoicePriceParams && <PrimaryButton text="Select" onClick={handleOnSave} className='btn-primary' />}
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    onCancel: () => void
}

type Props = OwnProps;
