import { IAdminDashboard } from "@solarforschools/sfs-core";
import { AnyAction } from "redux";
import { RootState } from "./../../index";
import { ThunkAction } from "redux-thunk";
import axios, { AxiosResponse } from "axios";
import { host } from "../../config";
import { ILiveMetersConditions } from "@solarforschools/sfs-core/dist/adminDashboard/types";
import { toast } from "react-toastify";
import { setAuthToken } from "../action";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const UPDATE_LIVE_METER_CONDITIONS = "UPDATE_LIVE_METER_CONDITIONS";

export const fetchAdminDashboardData = () => {
  return {
    type: FETCH_DATA_REQUEST,
  };
};

export const fetchAdminDashboardDataSuccess = (
  adminDashboardData: IAdminDashboard
) => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: adminDashboardData,
  };
};

export const fetchAdminDashboardDataFailure = (error: any) => {
  return { type: FETCH_DATA_FAILURE, payload: error };
};

export const updateAdminDashboardLiveMeter = (
  data: ILiveMetersConditions[]
) => {
  return { type: UPDATE_LIVE_METER_CONDITIONS, payload: data };
};

export const fetchDashboardData = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async (dispatch) => {
    dispatch(fetchAdminDashboardData());
    try {
      let isThereToken = axios.defaults.headers.common["authorization"];
      if (isThereToken === undefined) {
        let token = localStorage.getItem("token") as string;
        setAuthToken(token);
      }
      await new Promise((resolve) => setTimeout(resolve, 300));

      //Response
      const res: AxiosResponse<any> | undefined = await axios.get(
        `${host}/admindashboard`
      );

      dispatch(fetchAdminDashboardDataSuccess(res?.data));
    } catch (err) {
      console.log(err);
      dispatch(fetchAdminDashboardDataFailure("Something went wrong"));
    }
  };
};

export const updateLiveMeter = (
  condition: Partial<ILiveMetersConditions>
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      //  await new Promise((resolve) => setTimeout(resolve, 300));

      const res: any = await axios.put(
        `${host}/admindashboard/update/livemeters`,
        {
          condition,
        }
      );
      if (res.status === 200) {
        dispatch(updateAdminDashboardLiveMeter(res.data.msg));
        toast.success(`Live Meter Condition Updated Successfully`);
      }
      //dispatch(fetchDashboardData());
    } catch (err) {
      console.log(err);
      dispatch(fetchAdminDashboardDataFailure("Something went wrong"));
    }
  };
};
// export const setAdminDashboardData = (adminDashboardData: IAdminDashboard) => {
//   return { type: "SET_DASHBOARD_DATA", adminDashboardData };
// };

// export const fetchDashboardData = (): ThunkAction<
//   Promise<void>,
//   RootState,
//   {},
//   AnyAction
// > => {
//   return async function fetchDashboardDataThunk(dispatch) {
//     try {
//       dispatch(isLoading(true));
//       const res: AxiosResponse<any> | undefined = await axios.get(
//         `${host}/admindashboard`
//       );
//       dispatch(setAdminDashboardData(res?.data));
//       dispatch(isLoading(false));
//     } catch (err) {
//       console.log(err);
//       dispatch(isLoading(false));
//     }
//   };
// };
