import { ComboBox, DefaultButton, IComboBoxOption, Pivot, PivotItem, PrimaryButton, Separator, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import { IUser } from "@solarforschools/sfs-core"
import store, { RootState } from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getGeneralFields, getUserTabErrors, UserTabErrors, validateUser } from "./helper"
import { createUpdateuser } from "../../../store/admin/users/action"
import { isLoading } from "../../../store/admin/action"
import { getuserFunctionsApi } from "../../../store/client/userFunction"
import { toast } from "react-toastify"
import { comboBoxStyles } from "../common/common"
import { IUserState } from "../../../store/admin/users/reducer"
import { onRenderPivoteItemLink } from "../common/PivotItemRenderLink"
import UserSchools from "./UserSchools"
import moment from "moment"
import { createActivity } from "../../../store/client/user"


export const openUserModal = (props?: any) => {
  return new Promise<IUser | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: IUser) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add/Edit User"
          onDismiss={() => callback(undefined)}
        // containerClassName={"modal-size-sm"}
        >
          <EditUserDialog
            {...props}
            onSave={(data: IUser | undefined) => callback(data)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditUserDialog = (props: UserProps) => {
  const dispatch = useDispatch();
  const tempUser = { ...props.user }
  const { skills, permissions }: IUserState = useSelector<RootState, IUserState>((state) => state.web.user)
  const [user, setUser] = useState<IUser>(_.cloneDeep(props.user as IUser))
  const [errors, setErrors] = useState<any>({});
  const [roles, setRoles] = useState<any>([])
  const [tabErrors, setTabErrors] = useState<UserTabErrors>({ general: false, schools: false });

  const generalFields = useMemo(() => getGeneralFields({ user, roles }), [user, roles]);
  const loadData = async () => {
    try {
      dispatch(isLoading(true))
      // as per discussion user role will be only filter for GB
      const data: any = await getuserFunctionsApi({ all: true, active: true, country: "GB" })
      setRoles(data.userFunctions.map((s: any) => {
        return {
          key: s.slug,
          text: s.name
        }
      }))

      dispatch(isLoading(false))
    } catch (error: any) {
      toast.error(error?.msg || 'Something happen wrong')
      dispatch(isLoading(false))
    }
  }

  useEffect(() => {
    loadData();

  }, []);

  const handleOnSave = async () => {
    const errors = await validateUser(user);
    const tabErrors = await getUserTabErrors(errors);
    if (Object.keys(errors).length) {
      setErrors(errors);
      setTabErrors(tabErrors);
      return;
    }
    await dispatch(createUpdateuser(user))
    createActivity({ oldData: tempUser, newData: user, isUserChange: true })
    props.onSave(user)
  }


  const handleOnChange = (key: string, value: any) => {
    let data: IUser = _.cloneDeep(user);
    switch (key) {
      case "skills":
        let skills = [...data?.skills || []]
        if (value?.selected) {
          if (!skills?.find((s) => s === value?.key)) {
            skills.push(value.key);
          }
        } else {
          skills = skills?.filter((s) => s !== value?.key);
        }
        setUser(_.set({ ...data }, key, skills))
        break;
      case "permission":
        let permissions = [...data?.permission || []]
        if (value?.selected) {
          if (!permissions?.find((s) => s === value?.key)) {
            permissions.push(value.key);
          }
        } else {
          permissions = permissions?.filter((s) => s !== value?.key);
        }
        setUser(_.set({ ...data }, key, permissions))
        break;
      default:
        setUser(_.set({ ...data }, key, value))
        break;
    }
  }

  const updateUser = (key: string, value: any) => {
    const updated = _.cloneDeep(user)
    _.set(updated, key, value);
    setUser(updated);
  }

  return (
    <div className="edit-record">
      <Pivot linkSize="large">
        <PivotItem
          key="general"
          headerText="General"
          onRenderItemLink={onRenderPivoteItemLink(tabErrors.general)}
        >
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <DynamicForm
                fields={generalFields}
                data={user}
                onChange={handleOnChange}
                errors={errors}
              />
            </div>
            <div className="ms-Grid-row">
              <ComboBox
                label="Permissions"
                selectedKey={user?.permission || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                  permissions as IComboBoxOption[]
                }
                onChange={(e, item) => handleOnChange("permission", item)}
                styles={comboBoxStyles}
                className="ms-Grid-col ms-lg6"
                errorMessage={errors?.permission}
              />
              <ComboBox
                label="Skills"
                selectedKey={user?.skills! as unknown as string[] || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                  skills as IComboBoxOption[]
                }
                onChange={(e, item) => handleOnChange("skills", item)}
                styles={comboBoxStyles}
                className="ms-Grid-col ms-lg6"
                errorMessage={errors?.skills}
              />
            </div>
            <Separator className="margin-top-md">Login Information</Separator>
            <div className="ms-Grid-row">
              <TextField
                label="Last Attempt"
                placeholder="Last Attempt"
                className="ms-Grid-col ms-lg3"
                readOnly
                value={user?.login?.lastAttempt && moment(user.login.lastAttempt).format('YYYY-MM-DD hh:mm:ss')} />
              <TextField
                label="Last Success"
                placeholder="Last Success"
                className="ms-Grid-col ms-lg3"
                readOnly
                value={user?.login?.lastSuccess && moment(user.login.lastSuccess).format('YYYY-MM-DD hh:mm:ss')} />
              <TextField
                label="Failed Attempts"
                placeholder="Failed Attempts"
                className="ms-Grid-col ms-lg1"
                readOnly
                value={user?.login?.failedAttempts?.toString()} />
              <TextField
                label="Reset password Attempt"
                placeholder="Reset password Attempt"
                className="ms-Grid-col ms-lg3"
                readOnly
                value={user?.login?.lastResetPasswordAttempt && moment(user.login.lastResetPasswordAttempt).format('YYYY-MM-DD hh:mm:ss')} />
            </div>
          </div>
        </PivotItem>
        <PivotItem
          key="schools"
          headerText="Schools"
          onRenderItemLink={onRenderPivoteItemLink(tabErrors.schools)}
        >
          <UserSchools user={user} onUpdate={updateUser} />
        </PivotItem>
      </Pivot>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface UserProps {
  onCancel?: () => void;
  onSave: (data?: IUser) => void;
  user?: IUser;
}

export default openUserModal;
