import { IAdminDashboard } from '@solarforschools/sfs-core';
import Card from '../layouts'
import CardContent from '../layouts/CardContent';
import CardHeader from '../layouts/CardHeader';
import MapContent from './contents/MapContent';


const MapSchools = (props: Partial<IAdminDashboard>) => {

    if(props?.activeSchools === undefined) return <></>
    
    return (
        <div>
            <Card>
                <CardHeader title='Global Presence' iconName='Map' />
                <CardContent variant='Plain' children={<MapContent activeSchools={props.activeSchools} />} />
            </Card>
        </div>
    )
}

export default MapSchools