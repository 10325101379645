import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { DatePicker, Dropdown, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider, useDispatch } from 'react-redux';
import ModalDialog from '../../common/ModalDialog';
import ReactDOM from 'react-dom';
import store from '../../../../store';
import { dropdownStyles } from '../../common/common';
import { paymentPreferences } from '../bondholder/BondHolderHelper';
import moment from 'moment';
import { toast } from 'react-toastify';
import { rePayBondOfferBonds } from '../../../../store/admin/funder/bondOffer/action';
import { IBondOffer } from '@solarforschools/sfs-core/dist/funder/bond-offer/types';



export const OpenBondOfferRepaymentModal = (props: { params: any }) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={`Bond Offer Repayment`}
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-xs"}
                >
                    <RepaymentModal {...props} onSave={(data) => callback(data)} onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}


export const RepaymentModal = ({
    params,
    onCancel,
    onSave,
}: Props) => {

    const dispatch = useDispatch()
    const [payload, setPayload] = useState({ paymentPreference: "cheque", dueDate: moment().format('YYYY-MM-DD') })

    const handleOnRepay = async () => {
        await dispatch(rePayBondOfferBonds(payload, params.bondOffer._id))
    }
    // onSave({ success: true });
    // };

    const handleOnChange = async (key: string, value?: any) => {
        setPayload({ ...payload, [key]: value })
    }
    return (
        <div className="edit-record">
            <div className='ms-Grid-row'>
                <h3 className='ms-Grid-col ms-lg12 margin-top-md'>Warning!</h3>
            </div>
            <div className='ms-Grid-row '>
                <div className='margin-top-xs'>
                    <p className='ms-Grid-col ms-lg12'>On Repayment all bonds and transactions (having selected payment preference) will mark closed.</p>
                    <DatePicker
                        label="Start Date"
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={new Date(payload?.dueDate!)}
                        onSelectDate={(date: any) => handleOnChange('dueDate', date)}
                        styles={dropdownStyles}
                        className="ms-Grid-col ms-lg12"
                    />
                    <Dropdown
                        label="Payment Preference"
                        selectedKey={payload.paymentPreference}
                        onChange={(e, item) => handleOnChange("paymentPreference", item?.key)}
                        placeholder="Choose Region"
                        options={paymentPreferences}
                        styles={dropdownStyles}
                        className="ms-Grid-col ms-lg12"
                    />
                </div>
            </div>

            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>

                        <PrimaryButton
                            text={'Re Pay'}
                            onClick={handleOnRepay}
                            className="btn-primary"
                        />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    params: {
        bondOffer: IBondOffer,
    };
    onCancel: () => void;
    onSave: (params: any) => void;
}

type Props = OwnProps;
