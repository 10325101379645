import { useEffect, useMemo, useState } from "react";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { Stack, Sticky, StickyPositionType } from "@fluentui/react";
import "office-ui-fabric-react/dist/css/fabric.css";
import _ from "lodash";
import { Provider, useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import store from "../../../store";
import ModalDialog from "../common/ModalDialog";
import { IBillingPeriod } from "@solarforschools/sfs-core/dist/billing/types";
import { fetchBillingPeriods } from "../../../store/admin/invoice/action";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { DropdownParams, StringMap } from "../common/types";
import { initPeriod, getPeriodFields, validatePeriod } from "./helper";
import moment from "moment";
import { createBillingPeriod } from "../../../store/admin/period/action";

export const OpenAddPeriodModal = (props: any) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement("div");
    document.body.appendChild(mountTarget);
    const callback = (result?: any) => {
      resolve(result);
      ReactDOM.unmountComponentAtNode(mountTarget);
      mountTarget.remove();
    };
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Add/Edit Period"}
          onDismiss={() => callback(undefined)}
          containerClassName="modal-size-sm"
        >
          <AddPeriodDialog
            {...props}
            onSave={() => callback(undefined)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    );
  });
};

export function AddPeriodDialog({ period, onCancel, onSave }: Props) {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<any>({});
  const [billingPeriod, setBillingPeriod] = useState<IBillingPeriod>({
    ...(period || initPeriod),
  } as IBillingPeriod);
  const [periods, setPeriods] = useState<DropdownParams[]>([]); // previous periods list

  const loadData = async () => {
    const { periods }: any = await fetchBillingPeriods();
    setPeriods(periods as DropdownParams[]);
  };

  useEffect(() => {

    loadData();
  }, []);

  const periodFileds = useMemo(
    () => getPeriodFields({ perviousCycles: periods }),
    [periods]
  );

  const handleOnChangeInput = (key: string, item: any) => {
    switch (key) {
      case "Start":
      case "End":
        setBillingPeriod({
          ...billingPeriod,
          [key]: moment(item).format("YYYY-MM-DD"),
        });
        break;
      default:
        setBillingPeriod({
          ...billingPeriod,
          [key]: item.toUpperCase() as string,
        });
        break;
    }
  };

  const handleOnSave = async () => {
    const errors: StringMap = await validatePeriod(billingPeriod);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    await dispatch(createBillingPeriod(billingPeriod));
    onSave();
  };
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <section className="margin-top-md">
          <div className="ms-Grid-row">
            <DynamicForm
              fields={periodFileds}
              data={billingPeriod}
              errors={errors}
              onChange={handleOnChangeInput}
            />
          </div>
        </section>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item className="margin-top-lg">
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
}

interface OwnProps {
  period?: IBillingPeriod;
  onCancel?: () => void;
  onSave: () => void;
}

type Props = OwnProps;
