import { useState } from 'react';
import { ActionButton, CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { getRTYieldsColumns } from './helper';
import DetailsListWapper from '../../common/DetailsListWapper';
import { getRTYields, setRTYieldsFilter, exportRtYields } from '../../../store/admin/rtYields/action';
import { IRTYieldState } from '../../../store/admin/rtYields/reducer';
import { faFileExcel, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import openRTYieldsModal from './delRTYieldsDialog';

const RTYieldsList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { rtYieldsFilter, totalCount, rtYields }: IRTYieldState = useSelector<RootState, IRTYieldState>((state) => state.web.rtYields)


  const handleOnDelete = async (type:"rtReading"|"rtYeild"="rtReading") => {
    await openRTYieldsModal({type})
  };

  const headerRow = getRTYieldsColumns({
    actions: [
      // {
      //   icon: 'Edit',
      //   onClick: handleOnEdit,
      // },
      // {
      //   icon: "Trash",
      //   onClick: handleOnDelete
      // }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...rtYieldsFilter, page: 1, sortField: field, sortOrder: order };
    dispatch(setRTYieldsFilter(params));
    dispatch(getRTYields(params))
  }
  const loadNextPage = () => {
    const { page = 0 } = rtYieldsFilter
    dispatch(setRTYieldsFilter({ ...rtYieldsFilter, page: page + 1 }))
    dispatch(getRTYields(undefined, false))
  }

  const handleOnExport = () => {
    dispatch(exportRtYields({ ...rtYieldsFilter, all: true }))
  }


  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}>
            {totalCount > 0 && (<>Displaying &nbsp; {rtYields?.length}/ {totalCount}</>)}
          </span>
        </div>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => handleOnDelete("rtReading")}
        ><FontAwesomeIcon icon={faTrash} size='1x' color="#106ebe" />  &nbsp;Delete RtReading
        </ActionButton>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => handleOnDelete()}
        ><FontAwesomeIcon icon={faTrash} size='1x' color="#106ebe" />  &nbsp;Delete RtYields
        </ActionButton>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => handleOnExport()}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={rtYields}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={rtYields.length < totalCount}
                onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default RTYieldsList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

