import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ModalDialog from '../common/ModalDialog';
import store from '../../../store';
import { importMeterReadingApi } from '../../../store/client/meterReading';


export const OpenImportReadingModal = (props?: OwnProps) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: any) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Import Reading"}
          onDismiss={() => callback(undefined)}
          containerClassName="modal-size-sm"
        >
          <ImportMeterReadingDialog
            {...props}
            onSave={(data) => callback(data)}
            onCancel={() => callback(undefined)} />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

export const ImportMeterReadingDialog = ({
  onSave,
  onCancel,
}: Props) => {
  const dispatch = useDispatch()
  const [payload, setPayload] = useState<any>({ bondTyps: 'all' })
  const [file, setFile] = useState<any>()

  const [errors, setErrors] = useState<any>({});


  const handleOnChange = (key: string, value: any) => {
    let data: any = _.cloneDeep(payload);
    if (errors[key]) {
      const err = { ...errors };
      delete err[key];
      setErrors(err);
    }
    switch (key) {
      // case "bondType":

      // break;
      default:
        data = _.set({ ...data }, key, value);
        break;
    }
    setPayload(data)
  };
  const handleUpload = (e: any) => {
    e.preventDefault();

    const files = e.target.files
    const f = files[0];
    setFile(f)
   
  }

  const handleSave = async () => {

    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    try {
      const data: any = await importMeterReadingApi(formData)
      toast.success(data.msg)
    } catch (error) {
      toast.error(`Something went wrong`)
    }
  };

  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className='ms-Grid-row' style={{ margin: "10px" }}>
          <ul className='padding-left-md'>
            <li>Sheet name should be "readings"</li>
            <li>First line should be header</li>
            <li>Columns name systemid	Date=2023/07/23	type	MSN	reading(wh)	mid	readingType</li>
          </ul>
        </div>

        <div className='ms-Grid-row margin-top-md' style={{ margin: "10px" }}>
          <label style={{ fontSize: "14px", fontWeight: 'bolder' }}>Select Excel File</label>
        </div>
        <div className='ms-Grid-row margin-top-md'>
          <input className="form-input" name="inputFile" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleUpload} />
        </div>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleSave}
              className="btn-primary"
            />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface OwnProps {
  onCancel?: () => void
  onSave?: (data: any) => void
}

type Props = OwnProps;
