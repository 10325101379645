import { ISpinButtonStyles, PrimaryButton, SpinButton, TextField } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getFunderConfigAPi, updateFunderConfigAPi } from "../../../../store/client/funder/configuration";
import Loader from "../../common/Loader";

const ConfigScreen = () => {
  const [config, setConfig] = useState<any>({ transactionGracePeriod: 0 })

  useEffect(() => {
    const getConfiguration = async () => {
      const data = await getFunderConfigAPi()
      setConfig(data)
    }
    getConfiguration()
  }, []);
  
  const min = 0;
  const max = 1000;
  // By default the field grows to fit available width. Constrain the width instead.
  const styles: Partial<ISpinButtonStyles> = { spinButtonWrapper: {} };

  /** Remove the suffix or any other text after the numbers, or return undefined if not a number */
  const getNumericPart = (value: string): number | undefined => {
    const valueRegex = /^(\d+(\.\d+)?).*/;
    if (valueRegex.test(value)) {
      const numericValue = Number(value.replace(valueRegex, '$1'));
      return isNaN(numericValue) ? undefined : numericValue;
    }
    return undefined;
  };

  const onIncrement = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
    const numericValue = getNumericPart(value);
    if (numericValue !== undefined) {
      setConfig({ ...config, transactionGracePeriod: Math.min(numericValue + 1, max) })
    }
  };

  const onDecrement = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
    const numericValue = getNumericPart(value);
    if (numericValue !== undefined) {
      setConfig({ ...config, transactionGracePeriod: Math.max(numericValue - 1, min) })

    }
  };


  const onValidate = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
    let numericValue = getNumericPart(value);
    if (numericValue !== undefined) {
      numericValue = Math.min(numericValue, max);
      numericValue = Math.max(numericValue, min);
      return String(numericValue)
    }
  };

  /** This will be called after each change */
  const onChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
    setConfig({ ...config, transactionGracePeriod: value })
  };

  const updateConfig = async () => {
    const res = await updateFunderConfigAPi(config)
    toast.success(res.msg)
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Configuration</h1>
      </header>
      <div className="data-list-container ">
        <div className="ms-Grid" dir="ltr">
          {config.transactionGracePeriod > -1 &&
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                <SpinButton
                  label="Grace Period(Days)"
                  defaultValue={'0'}
                  value={config.transactionGracePeriod}
                  min={min}
                  max={max}
                  onValidate={onValidate}
                  onIncrement={onIncrement}
                  onDecrement={onDecrement}
                  onChange={onChange}
                  incrementButtonAriaLabel="Increase value by 1"
                  decrementButtonAriaLabel="Decrease value by 1"
                  styles={styles}
                />
              </div>
              <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg2">
                <PrimaryButton text="Update" onClick={() => updateConfig()} />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
export default ConfigScreen;

