import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IYield } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { methods } from "./common";

export const getGeneralFields = (options: StringMap): IField[] => {
    return [
        {
            label: "Id",
            key: "id",
            type: "number",
            extras: { required: true },
            placeholder: "system Id",
            className: 'ms-Grid-col ms-lg3'
        }, {
            label: "Name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "Date",
            key: "date",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "G",
            key: "data.G",
            type: "number",
            placeholder: "Generation",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "I",
            key: "data.I",
            type: "number",
            placeholder: "Import",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "E",
            key: "data.E",
            type: "number",
            placeholder: "Export",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "C",
            key: "data.C",
            type: "number",
            placeholder: "Consumption",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "SC",
            key: "data.SC",
            type: "number",
            placeholder: "Self Consumption",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "EV",
            key: "data.EV",
            type: "number",
            placeholder: "EV",
            className: 'ms-Grid-col ms-lg2'
        },
    ];
}
export const getGenYieldFields = (options: StringMap): IField[] => {
    return [
        {
            label: "Method/Mode",
            key: "mode",
            type: "dropdown",
            extras: { required: true },
            options: methods as IDropdownOption[],
            className: 'ms-Grid-col ms-lg6',
        },
        {
            label: "Start Date",
            key: "start",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "End Date",
            key: "end",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg6'
        }
    ];
}
export const getAddYieldFields = (options: StringMap): IField[] => {
    return [

        {
            label: "Date",
            key: "date",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "G",
            key: "data.G",
            type: "number",
            extras: { required: true, min: 0 },
            placeholder: "Enter G",
            step: "0.1",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "E",
            key: "data.E",
            type: "number",
            extras: { required: true, min: 0 },
            placeholder: "Enter E",
            step: "0.1",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "I",
            key: "data.I",
            type: "number",
            extras: { required: true, min: 0 },
            placeholder: "Enter I",
            step: "0.1",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "C",
            key: "data.C",
            type: "number",
            extras: { required: true, min: 0 },
            placeholder: "Enter C",
            step: "0.1",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "SC",
            key: "data.SC",
            type: "number",
            extras: { required: true, min: 0 },
            placeholder: "Enter SC",
            step: "0.1",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "EV",
            key: "data.EV",
            type: "number",
            extras: { min: 0 },
            placeholder: "Enter EV",
            step: "0.1",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Target",
            key: "data.T",
            type: "number",
            extras: { required: true, min: 0 },
            placeholder: "Enter Target",
            step: "0.1",
            className: 'ms-Grid-col ms-lg2'
        }
    ];
}
export const validateYield = async (params: IYield) => {
    let errors: StringMap = {};
    const { name, id, date } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (!id) errors['id'] = requiredMessage;
    if (!date) errors['date'] = requiredMessage;
    return errors;
}

export const validateGenYield = async (params: StringMap) => {
    let errors: StringMap = {};
    const { mode, id } = params || {}
    if (!id) errors['id'] = requiredMessage;
    if (!mode) errors['mode'] = requiredMessage;
    return errors;
}

export const validateAddYield = async (params: StringMap) => {
    let errors: StringMap = {};
    const { date, id } = params || {}
    if (!id) errors['id'] = requiredMessage;
    if (!date || moment(date).isSameOrAfter(moment())) errors['date'] = "invalid date";
    return errors;
}

export const getYieldsColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 300,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            key: 'id',
            name: 'Id',
            fieldName: 'id',
            minWidth: 80,
            ...isSortable,
            onRender: (item: IYield) => <TooltipHost content={item.id.toString()} closeDelay={500}> {item.id}</TooltipHost>
        },
        {
            name: 'Date',
            key: 'date',
            fieldName: 'date',
            minWidth: 80,
            ...isSortable,
            onRender: (item: IYield) => <TooltipHost content={moment(item.date).format('YYYY-MM-DD')} closeDelay={500}> {moment(item.date).format('YYYY-MM-DD')}</TooltipHost>
        },
        {
            key: 'g',
            name: 'G',
            fieldName: 'g',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IYield) => item.data.G?.toFixed(0)
        },
        {
            key: 'i',
            name: 'I',
            fieldName: 'i',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IYield) => item.data.I?.toFixed(0)
        },
        {
            key: 'e',
            name: 'E',
            fieldName: 'e',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IYield) => item.data.E?.toFixed(0)
        },
        {
            key: 'c',
            name: 'C',
            fieldName: 'data.C',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IYield) => item.data.C?.toFixed(0)
        },
        {
            key: 'sc',
            name: 'SC',
            fieldName: 'data.SC',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IYield) => item.data.SC?.toFixed(0)
        },
        {
            key: 'ev',
            name: 'EV',
            fieldName: 'data.EV',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IYield) => item.data?.EV?.toFixed(0)
        },
        {
            key: 't',
            name: 'Target',
            fieldName: 'data.T',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: IYield) => item.data.T?.toFixed(0)
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (data) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(data)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

// export const initUserFunction: IUserFunction = {
//     name: '',
//     slug: '',
//     country: 'all',
//     active: false,
// } as unknown as IUserFunction
