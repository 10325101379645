import { useEffect, useState } from "react";
import {
  ActionButton,
  CheckboxVisibility,
  DetailsList,
  DetailsRowFields,
  Text,
  IDetailsRowFieldsProps,
  ScrollablePane,
  ScrollbarVisibility,
  IDetailsRowProps,
  DetailsRow,
} from "@fluentui/react";
import { SelectionMode, IColumn } from "@fluentui/react/lib/index";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { DetailListStickyHeader } from "../../common/DetailListStickyHeader";

import { IReports } from "../../../../store/admin/reporting/reports/reducer";
import { fetchExcelBuffer } from "../../../../store/admin/reporting/reports/action";
import { headerInvoiceRowCompare, headerInvoiceRowNormal, headerRowCompare, headerRowNormal } from "./helper";

const ReportList = () => {
  const dispatch = useDispatch();
  const report: IReports = useSelector<RootState, IReports>(
    (state) => state.web.reports
  );
  const { isCompare, period } = report.schoolReportForm;

  const headerRow: IColumn[] = isCompare ? headerRowCompare : headerRowNormal(report);
  const [columns, setColumns] = useState<IColumn[]>(headerRow);

  const setHeaderRow = () => {
    if (period === "invoice") {
      setColumns(isCompare ? [...headerInvoiceRowCompare] : [...headerInvoiceRowNormal])
    } else {
      setColumns(isCompare ? [...headerRowCompare] : [...headerRowNormal(report)])
    }
  }

  useEffect(() => {
    setHeaderRow()
  }, [isCompare, period]);

  const renderRowFields = (props: IDetailsRowFieldsProps) => {
    return (
      <span data-selection-disabled={true}>
        <DetailsRowFields {...props} />
      </span>
    );
  };
  const renderRow = (props: IDetailsRowProps) => {
    return <DetailsRow rowFieldsAs={renderRowFields} {...props} />;
  };

  const handleOnExport = () => {
    if (report.schoolReportForm.customHeaders.length > 0) {
      dispatch(
        fetchExcelBuffer(
          report.schoolReportData,
          report.schoolReportForm.customHeaders,
          report.schoolReportForm.isCompare
        )
      );
    }
  };

  return (
    <>
      {report?.schoolReportData?.length > 0 && (
        <div
          style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
          className="margin-top-md"
        >
          <div style={{ flex: "20%" }}>
            <span
              style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
            >
              <Text>
                Displaying: {report.schoolReportData?.length} {period !== "invoice" ? ", Unit: kWh" : ""}
              </Text>
            </span>
          </div>
          <>
            <ActionButton
              iconProps={{ iconName: "ExcelLogo" }}
              onClick={handleOnExport}
            >
              Export excel
            </ActionButton>
          </>
        </div>
      )}

      {report.schoolReportData.length > 0 ? (
        <div className="data-list-container">
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsList
              items={report.schoolReportData}
              columns={columns}
              selectionMode={SelectionMode.none}
              //onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
              onRenderDetailsHeader={DetailListStickyHeader}
              compact={true}
              //selection={selection}
              checkboxVisibility={CheckboxVisibility.always}
              onRenderRow={(props) => renderRow(props!)}
            //onRenderMissingItem={onRenderMissingItem}
            />
          </ScrollablePane>
        </div>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  );
};

export default ReportList;
