

import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from '../../index';
import { isLoading } from "../action";
import { ITask } from "@solarforschools/sfs-core/dist/task/types";
import { ITaskFilter } from "../../../components/admin/task/types";
import { getTasksApi } from "../../client/task";

// Action Creators
export const setTaskFilter = (taskFilter: ITaskFilter) => {
  return { type: "SET_TASK_FILTER", taskFilter };
};

export const setTaskList = (tasks: ITask[], totalCount: number, reset: boolean = true) => {
  return { type: "SET_TASK_LIST", tasks, totalCount, reset };
};

export const updateTaskList = (task: ITask) => {
  return { type: "UPDATE_TASK_LIST", task };
};

export const fetchTasks = (
  params?: ITaskFilter,
  reset: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  let filter = params;
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        dispatch(isLoading(true));
        if (!filter) {
          const taskFilter: ITaskFilter = getSate().web.task.taskFilter
          filter = { ...taskFilter }
        }
        const { tasks, totalCount } = await getTasksApi(filter)
        dispatch(setTaskList(tasks, totalCount, reset));
        dispatch(isLoading(false));
        resolve()
      } catch (e) {
        console.log(e);
        dispatch(isLoading(false));
      }
    })
  };
}

// export const createBillingPeriod = (period: IBillingPeriod): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
//   // Invoke API
//   return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
//     dispatch(isLoading(true))
//     return new Promise<void>(async (resolve) => {
//       try {
//         const data: any = period._id ? await updatePeriod(period) : await createPeriod(period)
//         dispatch(updateBillingPeriodList(data.period))
//         toast.success(data.msg);
//         dispatch(isLoading(false));
//       } catch (e) {
//         console.log(e)
//         dispatch(isLoading(false));
//       }
//     });
//   };
// };


// export const deleteBillingPeriod = (
//   period: IBillingPeriod,
// ): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
//   // Invoke API
//   return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
//     return new Promise<void>(async (resolve) => {
//       try {
//         dispatch(isLoading(true));
//         const data: any = await deletePeriod(period)
//         dispatch(delBillingPeriod(period._id!.toString()))
//         toast.success(data.msg);
//         dispatch(isLoading(false));
//       } catch (e) {
//         console.log(e);
//         dispatch(isLoading(false));
//       }
//     });
//   };
// }
