import { ComboBox, DatePicker, IComboBoxOption } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getYields, setYieldsFilter } from '../../../store/admin/yields/action';
import { IYieldsState } from '../../../store/admin/yields/reducer';
import { FundingOptions, comboBoxStyles, dropdownStyles } from '../common/common';
import { Providers } from '../system/common';
import SchoolPicker from '../common/SchoolPicker';
import SystemPicker from '../common/SystemPicker';

const YieldsFilters = (props: YieldFilterProps) => {
    const dispatch = useDispatch()
    const { yieldsFilter }: IYieldsState = useSelector<RootState, IYieldsState>((state) => state.web.yields)

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        let params = { ...yieldsFilter, page: 1, [key]: value };
        switch (key) {
            case "selectedSchool":

                params = { ...params, schoolSlug: value[0]?.key }
                break;
            case 'selectedSystems':

                params = { ...params, system: value[0]?.key }
                break
        }
        dispatch(setYieldsFilter(params));
        dispatch(getYields(params));
    }


    const { schoolSlug, start, end, provider, ownedBy } = yieldsFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <div className={`ms-Grid-col ms-lg4 margin-top-xsm`}>
                    <SchoolPicker filters={{ live: true }} onChange={handleOnChange} />
                </div>
                <div className={`ms-Grid-col ms-lg4 margin-top-xsm`}>
                    <SystemPicker filters={{ schoolSlug }} onChange={handleOnChange} />
                </div>
                <ComboBox
                    label="Provider"
                    placeholder="Choose"
                    selectedKey={provider || "all"}
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={Providers as IComboBoxOption[]}
                    onChange={(e, item?: IComboBoxOption) =>
                        handleOnChange("provider", item?.key?.toString() || 'all')
                    }
                    className="ms-Grid-col ms-lg2"
                    styles={comboBoxStyles}
                />
                <ComboBox
                    label="Owned By"
                    placeholder="Choose"
                    selectedKey={ownedBy || "all"}
                    allowFreeform={true}
                    autoComplete={"on"}
                    options={FundingOptions as IComboBoxOption[]}
                    onChange={(e, item?: IComboBoxOption) =>
                        handleOnChange("ownedBy", item?.key?.toString() || 'all')
                    }
                    className="ms-Grid-col ms-lg2"
                    styles={comboBoxStyles}
                />
            </div>
            <div className="ms-Grid-row">
                <DatePicker
                    label="Start Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(moment(start).format('YYYY-MM-DD'))!}
                    onSelectDate={(date: any) => handleOnChange('start', moment(date).format('YYYY-MM-DD'))}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg4"
                />
                <DatePicker
                    label="End Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(moment(end).format('YYYY-MM-DD'))!}
                    onSelectDate={(date: any) => handleOnChange('end', moment(date).format('YYYY-MM-DD'))}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg4"
                />
            </div>
        </div>
    );
}

interface YieldFilterProps {

}

export default YieldsFilters;

