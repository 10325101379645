import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../../common/utils";
import moment from "moment";
import { IInvoice } from "../../../../store/admin/invoice/reducer";

export const headerInvoiceAccountReportingRow: IColumn[] = [
  {
    key: "contract.customerName",
    name: "*ContactName",
    fieldName: "contract.customerName",
    minWidth: 80,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.contract?.customerName || ''} closeDelay={500}>{item.contract?.customerName}</TooltipHost>

  },
  {
    key: "EmailAddress",
    name: "EmailAddress",
    ariaLabel: "EmailAddress",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "POAddressLine1",
    name: "POAddressLine1",
    ariaLabel: "POAddressLine1",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "POAddressLine2",
    name: "POAddressLine2",
    ariaLabel: "POAddressLine2",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "POAddressLine3",
    name: "POAddressLine3",
    ariaLabel: "POAddressLine3",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "POAddressLine4",
    name: "POAddressLine4",
    ariaLabel: "POAddressLine4",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "POCity",
    name: "POCity",
    ariaLabel: "POCity",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "PORegion",
    name: "PORegion",
    ariaLabel: "PORegion",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "POPostalCode",
    name: "POPostalCode",
    ariaLabel: "POPostalCode",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "POCountry",
    name: "POCountry",
    ariaLabel: "POCountry",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "InvoiceNumber",
    name: "*InvoiceNumber",
    fieldName: "number",
    ariaLabel: "InvoiceNumber",
    minWidth: 80,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.number || ''} closeDelay={500}>{item.number}</TooltipHost>
  },
  {
    key: "Reference",
    name: "Reference",
    fieldName: "Reference",
    ariaLabel: "Reference",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "*InvoiceDate",
    name: "*InvoiceDate",
    ariaLabel: "InvoiceDate",
    fieldName: "date",
    minWidth: 80,
    data: "string",
    ...isSortable,
    onRender: (item: any) => {
      const date = item.date ? moment(item.date).format("DD/MM/YYYY") : ''
      return <TooltipHost content={date} closeDelay={500}>{date}</TooltipHost>
    }
  }, {
    key: "DueDate",
    name: "*DueDate",
    fieldName: "DueDate",
    ariaLabel: 'DueDate',
    minWidth: 80,
    data: "string",
    ...isSortable,
    onRender: (item: any) => {
      const date = item.date ? moment(item.dueDate).format("DD/MM/YYYY") : ''
      return <TooltipHost content={date} closeDelay={500}>{date}</TooltipHost>
    }
  },
  {
    key: "Total",
    name: "Total",
    fieldName: "Total",
    ariaLabel: 'Total',
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "InventoryItemCode",
    name: "InventoryItemCode",
    fieldName: "InventoryItemCode",
    ariaLabel: "InventoryItemCode",
    minWidth: 50,
    data: "string",
    onRender: (item: any) => ''
  },
  {
    key: "Description",
    name: "*Description",
    ariaLabel: "Description",
    fieldName: "number",
    minWidth: 80,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.number || ''} closeDelay={500}>{item.number}</TooltipHost>
  },
  {
    key: "Quantity",
    name: "Quantity",
    fieldName: "Quantity",
    minWidth: 30,
    data: "string",
    ariaLabel: 'Quantity',
    onRender: (item: any) => '1'
  },
  {
    key: "UnitAmount",
    name: "*UnitAmount",
    ariaLabel: "UnitAmount",
    fieldName: "UnitAmount",
    minWidth: 80,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item?.amounts?.total || ''} closeDelay={500}>{item?.amounts?.total}</TooltipHost>
  },
  {
    key: "Discount",
    name: "Discount",
    fieldName: "Discount",
    minWidth: 50,
    data: "string",
    ariaLabel: 'Discount',
    onRender: (item: any) => ''
  },
  {
    key: "AccountCode",
    name: "*AccountCode",
    fieldName: "AccountCode",
    minWidth: 50,
    data: "string",
    ariaLabel: 'AccountCode',
    onRender: (item: any) => '212'
  },
  {
    key: "TaxType",
    name: "*TaxType",
    ariaLabel: "TaxType",
    fieldName: "TaxType",
    minWidth: 80,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={(item?.contract?.vat || '') + '% (VAT on Income)'} closeDelay={500}>{(item?.contract?.vat || '') + '% (VAT on Income)'}</TooltipHost>
  },
  {
    key: "TaxAmount",
    name: "TaxAmount",
    fieldName: "TaxAmount",
    minWidth: 50,
    data: "string",
    ariaLabel: 'TaxAmount',
    onRender: (item: any) => ''
  },
  {
    key: "TrackingName1",
    name: "TrackingName1",
    fieldName: "TrackingName1",
    minWidth: 50,
    data: "string",
    ariaLabel: 'TrackingName1',
    onRender: (item: any) => 'Schools'
  },
  {
    key: "TaxType",
    name: "*TaxType",
    ariaLabel: "TaxType",
    fieldName: "TaxType",
    minWidth: 90,
    data: "string",
    ...isSortable,
    onRender: (item: any) => {
      let t = item?.contract?.number || ''
      t += '-' + item.contract.title || ''
      return <TooltipHost content={t} closeDelay={500}>{t}</TooltipHost>
    }
  },
  {
    key: "TrackingName2",
    name: "TrackingName2",
    fieldName: "TrackingName2",
    minWidth: 50,
    data: "string",
    ariaLabel: 'TrackingName2',
    onRender: (item: any) => ''
  },
  {
    key: "TrackingOption2",
    name: "TrackingOption2",
    fieldName: "TrackingOption2",
    minWidth: 50,
    data: "string",
    ariaLabel: 'TrackingOption2',
    onRender: (item: any) => ''
  },
  {
    key: "Currency",
    name: "Currency",
    fieldName: "Currency",
    minWidth: 50,
    data: "string",
    ariaLabel: 'Currency',
    onRender: (item: any) => ''
  },
  {
    key: "BrandingTheme",
    name: "BrandingTheme",
    fieldName: "BrandingTheme",
    minWidth: 50,
    data: "string",
    ariaLabel: 'BrandingTheme',
    onRender: (item: any) => ''
  },
];
