
import { Action } from "./actionType";
import { IBond } from "@solarforschools/sfs-core/dist/funder/bond/types";
import { ICertificateFilter } from "../../../../components/admin/funder/certificate/types";
import { defaultCertificateFilter } from "../../../../components/admin/funder/certificate/CertificateHelper";

// States' definition
export interface ICertificateState {
  isLoading: boolean;
  bonds: IBond[];
  totalCount: number;
  bondOffers: [];
  certificateFilter: ICertificateFilter
}
export interface State {
  certificate: ICertificateState;
}

const certificates = (
  state: ICertificateState = {
    isLoading: false,
    bonds: [],
    bondOffers: [],
    certificateFilter: defaultCertificateFilter,
    totalCount: 0,
  },
  action: Action
): ICertificateState => {
  switch (action.type) {
    case 'FUNDER/SET_CERTIFICATES_FILTER':
      return { ...state, certificateFilter: action.certificateFilter }
    case 'FUNDER/SET_CERTIFICATES_LIST':
      const bonds = action.replace ? action.bonds : [...state.bonds, ...action.bonds]
      return { ...state, bonds, totalCount: action.totalCount }
      case "FUNDER/CERTIFICATES_SET_BONDOFFER_FILTER":
        return { ...state, bondOffers: action.bondOffers as [] }
    default:
      return state;
  }
};

export default certificates;
