import React, { CSSProperties, useEffect, useState } from 'react'
import Loader from '../common/Loader'
import TranslationsFilter from './Filter'
import TranslationsLists from './Lists'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPendingApprovalCount, fetchTranslations, resetAll } from '../../../store/admin/translations/actions'
import { useLocation } from 'react-router-dom'
import PendingLists from './PendingLists'
import { HistoryPanel } from './History'
import { IAdmin } from '../../../store/admin/reducer'
import { RootState } from '../../../store'
import { ITranslations } from './utils/types'
import { buildFilterLang } from './utils/helper'


const Translations = () => {

  const admin: IAdmin = useSelector<RootState, IAdmin>((state: RootState) => state.web.admin);
  const { filter: { lang, value } } = useSelector<RootState, ITranslations>((state) => state.web.translations);


  const dispatch = useDispatch()
  const location = useLocation()
  const [renderPendingLists, setRenderPendingLists] = useState(false)
  const hasTranslApprovePermissions = admin?.user?.permission?.includes("translationApprover")

  const layoutStyles: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    position: "relative",
    scrollBehavior: "smooth",
  }

  useEffect(() => {

    const [_, baseUrl, secondaryPath] = location.pathname.split("/")

    let fetchLang = buildFilterLang(lang)

    if (secondaryPath === "pending") {
      if (hasTranslApprovePermissions) setRenderPendingLists(true)
      else {
        setRenderPendingLists(false)
        dispatch(fetchTranslations({ page: 1, ...fetchLang, hasToReset: true }))
        window.history.replaceState(null, "", `/translations`);
      }
    } else {
      if (baseUrl === "translations") {
        // initial fetch
        dispatch(fetchTranslations({ page: 1, ...fetchLang, hasToReset: true, value }))
        if (hasTranslApprovePermissions) {
          dispatch(fetchPendingApprovalCount())
        }
      }
    }

    return () => {
      const currentPath = window.location.pathname
      // clean up all unnecessary data (if not these paths)
      if (currentPath !== "/translations" && currentPath !== "/translations/pending") {
        dispatch(resetAll())
      }
    }

  }, [])


  return (
    <div style={layoutStyles} className='translations'>

      <Loader />

      <div>
        <header className="text-center">
          <h1>Translations</h1>
        </header>
      </div>

      <HistoryPanel />

      {
        renderPendingLists ? <PendingLists /> : (
          <>
            <TranslationsFilter />
            <TranslationsLists />
          </>
        )
      }

    </div>
  )
}

export default Translations