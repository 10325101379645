import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import "./question.css";
import {
  IAnswer,
  IQuestionTemplate,
  QuestionItems,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { changeQuestionTemplate } from "../../../../store/content/action";
import { getName } from "../util";

const Q2 = ({
  content,
  handleSubmit,
  handleTextBoxChange,
  changeQuestionTemplate,
}: Props) => {
  let moduleID: string, unitID: string;
  if (content.screen?.moduleID && content.screen?.unitID) {
    moduleID = content.screen.moduleID;
    unitID = content.screen.unitID;
  }

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const thisTemplate = TemplateItems.Q2;

  const handleAnswer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const span = e.target as HTMLSpanElement;
    const chosenOption = Number(span.textContent);
    const answerOptions = content.questionTemplate?.answer?.options;
    let options: number[] =
      answerOptions && answerOptions?.length > 0 ? answerOptions : [];

    if (span.textContent && typeof Number(span.textContent) === "number") {
      if (
        content.questionTemplate?.answer?.options?.find(
          (val) => val === chosenOption
        )
      ) {
        options = content.questionTemplate?.answer?.options.filter(
          (val) => val !== chosenOption
        );
      } else {
        options.push(chosenOption);
      }

      let answer: IAnswer = {
        ...content.questionTemplate?.answer,
        options,
      };
      dispatchEvent(answer);
    }
  };

  const handleTips = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      tips: e.target.value,
    };
    dispatchEvent(answer);
  };

  const dispatchEvent = (answer: IAnswer) => {
    let questionTemplate: IQuestionTemplate = {
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer,
    };
    changeQuestionTemplate(questionTemplate);
  };

  const { submitButtonText } = getName(content, -1);

  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, paddingTop: 0, height: "65%" }}
        >
          <Textbox
            placeholder="Enter the Question here"
            maxChar={80}
            style={{ fontSize: "1rem", fontWeight: "normal" }}
            containerStyle={{ margin: "1rem 1rem" }}
            onChange={(e) =>
              handleTextBoxChange(e, 1, thisTemplate, QuestionItems.QUESTION)
            }
            value={
              content.questionTemplate?.items?.find(
                (temp) => temp.sequence === 1
              )?.text
            }
          />

          <div className="q-options-container">
            <Textbox
              placeholder="Enter Option 1"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(e, 2, thisTemplate, QuestionItems.OPTIONS)
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 2
                )?.text
              }
            />
            <Textbox
              placeholder="Enter Option 2"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(e, 3, thisTemplate, QuestionItems.OPTIONS)
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 3
                )?.text
              }
            />
            <Textbox
              placeholder="Enter Option 3"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(e, 4, thisTemplate, QuestionItems.OPTIONS)
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 4
                )?.text
              }
            />
            <Textbox
              placeholder="Enter Option 4"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(e, 5, thisTemplate, QuestionItems.OPTIONS)
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 5
                )?.text
              }
            />
          </div>
        </div>
      </aside>
      <aside>
        <p>
          Use the textboxes left hand side to add the questions and appropriate
          options. This template allows you to chose more than one correct
          answer below.
        </p>
        <div className="correct-answer">
          <div className="choose-correct-answer">
            <Label name="Which answer is correct ?" fontSize="large" />
            <div className="answer-options" onClick={handleAnswer}>
              <span
                className={
                  content.questionTemplate?.answer !== undefined &&
                  content.questionTemplate?.answer?.options?.indexOf(1) !== -1
                    ? "answer-selected"
                    : ""
                }
              >
                1
              </span>
              <span
                className={
                  content.questionTemplate?.answer !== undefined &&
                  content.questionTemplate?.answer?.options?.indexOf(2) !== -1
                    ? "answer-selected"
                    : ""
                }
              >
                2
              </span>
              <span
                className={
                  content.questionTemplate?.answer !== undefined &&
                  content.questionTemplate?.answer?.options?.indexOf(3) !== -1
                    ? "answer-selected"
                    : ""
                }
              >
                3
              </span>
              <span
                className={
                  content.questionTemplate?.answer !== undefined &&
                  content.questionTemplate?.answer?.options?.indexOf(4) !== -1
                    ? "answer-selected"
                    : ""
                }
              >
                4
              </span>
            </div>
          </div>
          <div className="answer-comment-box">
            <Label
              name="Correct!"
              fontSize="xx-large"
              style={{ marginTop: 0 }}
            />
            <Textarea
              placeholder="Enter any additional text to appear on the correct / not quite message (optional)"
              maxChar={50}
              width="100%"
              containerStyle={{ height: "55%" }}
              onChange={handleTips}
              value={content.questionTemplate?.answer?.tips}
            />
          </div>
        </div>
        <Button
          width="20%"
          height="8%"
          name={submitButtonText}
          fontSize="1.5rem"
          onClick={handleSubmit}
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeQuestionTemplate: (template: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: () => void;
  handleTextBoxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(questionTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Q2);
