import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Separator, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import { stageFields } from './helper';
import DynamicForm from '../../dynamic-form/DynamicForm';
import { IEstablishment as IEstablishmentDocument } from "@solarforschools/sfs-core/dist/school/types";
import { StageListing } from './StageListing';


export const EditStage = ({
    school,
    onCancel,
    onSave
}: Props) => {
    const [selectedSchool, setSelectedSchool] = useState(_.cloneDeep(school));
    const [errors, setErrors] = useState<any>({});

    const handleOnChangeInput = (key: string, value: any) => {
        let school: any = _.cloneDeep(selectedSchool);
        if (key === "activeStage.name" && value === "all") return;
        const name =
            key === "activeStage.name"
                ? value
                : selectedSchool.activeStage?.name || "Prospect";
        const date = new Date();
        const active =
            key === "activeStage.active"
                ? value
                : selectedSchool.activeStage?.active || false;
        school.activeStage = { name, date, active };

        school = _.set({ ...school }, key, value);
        setSelectedSchool(school);
    }

    const handleOnSave = async () => {
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        onSave(selectedSchool as IEstablishmentDocument, 'stage');
    }

    return (
        <div className="edit-record">
            <div className="form-grid">
                <DynamicForm
                    fields={stageFields}
                    data={selectedSchool}
                    onChange={handleOnChangeInput}
                    errors={errors}
                />
            </div>
            <div className="ms-Grid-row margin-top-md">
                <div className="ms-Grid-col ms-lg12">
                    <Separator><b>Stage History</b></Separator>

                </div>
            </div>
            <div>
                <StageListing stage={selectedSchool.stage || []} />
                <Sticky stickyPosition={StickyPositionType.Footer}>
                    <Stack horizontal horizontalAlign="center">
                        <Stack.Item className="margin-top-lg">
                            <PrimaryButton text="Save" onClick={() => handleOnSave()} className='btn-primary' />
                            <DefaultButton onClick={onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </div>
        </div>);
};
interface OwnProps {
    school: IEstablishmentDocument;
    onCancel: () => void
    onSave: (school: IEstablishmentDocument, action: string) => void
}

type Props = OwnProps;

