import { CommandBar, Dropdown, ICommandBarItemProps, IDropdownOption, MessageBar, ProgressIndicator, ScrollablePane, ScrollbarVisibility, Stack, Text } from '@fluentui/react';
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Loader from "../../common/Loader";
import { IBondTransationState } from './../../../../store/admin/funder/transaction/reducer';
import TransactionFilters from './TransactionFilters';
import TransactionList from './TransactionList';
import { exportTransactions, getBondTransactions, getTransactionsBondoffers, getTransactionsFilter, setBondTransactionFilter } from './../../../../store/admin/funder/transaction/action';
import { getBondTransactionEmailPreview, getBondTransactionsApi, sendBondTransactionEmailApi } from '../../../../store/client/funder/transaction';
import { ITransactionEmailParams, TransactionPaymentAction } from './types';
import { OpenPaymentProcessModal } from './ProcessPaymentDialog';
import { ToastContainer, toast } from 'react-toastify';
import { OpenAddTransactionModal } from './AddTransactionDialog';
import { initTrasnaction } from './TransactionHelper';
import { OpenReinvestModal } from './ReinvestDialog';
import { OpenCreateTaxStatementModal } from './CreateTaxStatementDialog';
import { isLoading } from '../../../../store/admin/action';
import { OpenEmailPreviewModal } from '../../common/EmailPreviewDialog';
import { ITransaction } from '@solarforschools/sfs-core/dist/funder/transaction/types';
import { SocketContext } from '../../../../context/socket';
import { htmlElementProperties } from 'office-ui-fabric-react';
import { PageSize } from '../../common/common';


const BondTransactionScreen = () => {
  const dispatch = useDispatch()
  const { coreSocket } = useContext(SocketContext);
  const { transactions, totalCount, transactionFilter }: IBondTransationState = useSelector<RootState, IBondTransationState>((state: RootState) => state.web.transaction)

  const [progressData, setProgressData] = useState<any>({ counter: 0, total: 0, totalTrasnactions: 0 })

  const ShowProgress = () => (
    <div role='status'
      style={{
        position: 'fixed',
        top: 10,
        right: 10,
        zIndex: 1000, // Adjust as needed to ensure the message is on top
        width: 300
      }}
    >
      <MessageBar>
        <Stack style={{ width: 250 }}>
          <ProgressIndicator description={`Generating Tax Certificates ${progressData.counter}/${progressData.totalTransactions}`} percentComplete={progressData.counter / progressData.totalTransactions} />
        </Stack>
      </MessageBar>
    </div>
  );

  useEffect(() => {
    dispatch(getTransactionsBondoffers())
    dispatch(getTransactionsFilter())
    dispatch(getBondTransactions())

    coreSocket.on("create-tax-statements", (data) => {
      setProgressData(data)
    }
    );
    coreSocket.on("create-tax-statements-done", (data) => {
      setProgressData({ counter: 0, total: 0, totalTrasnactions: 0 })
      toast.success(data.message)
    });
    return () => {
      coreSocket.off("create-tax-statements");
      coreSocket.off("create-tax-statements-done");
    };

  }, []);

  async function processPayment(type: "interest" | "repayment", paymentBy: TransactionPaymentAction) {
    if (!transactionFilter.period || transactionFilter.period === 'all') {
      toast.error("Select a payment period!")
      return
    }
    if (!transactionFilter.transactionType || transactionFilter.transactionType === 'all') {
      toast.error("Select a transaction type!")
      return
    }

    await OpenPaymentProcessModal({
      params: {
        action: paymentBy,
        type,
        period: transactionFilter.period,
        bondOfferId: transactionFilter.bondOfferId,
        ...transactionFilter
      }
    })
  }

  async function reInvestTransaction(type: "interest" | "repayment", transactionId: any = null) {
    if (!transactionFilter.period || transactionFilter.period === 'all') {
      toast.error("Select a payment period!")
      return
    }
    const params: any = {
      type,
      period: transactionFilter.period,
      transactionId,
      filterBondOffer: transactionFilter.bondOfferId,
      isTestTransaction: transactionFilter.isTestTransaction
    }
    if (transactionFilter.bondOfferId !== "all") params.bondOfferId = transactionFilter.bondOfferId
    await OpenReinvestModal({
      params: params
    })
  }


  async function addTransaction() {

    await OpenAddTransactionModal({ transaction: initTrasnaction })
  }

  async function handlePaymentStatementEmail(params: ITransactionEmailParams) {
    try {
      const { transactionId, transactionType, actionType = "payment-statement" } = params
      if ((!transactionFilter.period || transactionFilter.period === 'all') && !transactionId) {
        toast.error("Select a payment period!")
        return
      }
      if ((!transactionFilter.transactionType || transactionFilter.transactionType === 'all') && !transactionId) {
        toast.error("Select a transaction type!")
        return
      }
      dispatch(isLoading(true))
      const filter: any = transactionId ? { transactionId } : { period: transactionFilter.period, bondOfferId: transactionFilter.bondOfferId, all: true, status: "Open", actionType, transactionType, payment: transactionFilter.payment, isTestTransaction: transactionFilter.isTestTransaction }
      const { transactions } = await getBondTransactionsApi(filter)
      if (!transactions || !transactions.length) {
        toast.error("No transaction found!")

        return
      } else {
        const { period, action, bondOfferId, type: transactionType } = transactions[0]
        const { html } = await getBondTransactionEmailPreview(transactions[0]._id!.toString(), { period, bondOfferId: bondOfferId.toString(), transactionType, paymentBy: action, action, type: transactionType, actionType })
        dispatch(isLoading(false))
        await OpenEmailPreviewModal({
          params: {
            data: [...transactions],
            title: 'Payment Statement',
            btnTitle: 'Send',
            html,
            sendAction: (p: ITransaction) => sendBondTransactionEmailApi(p._id!.toString(), { period, bondOfferId: bondOfferId.toString(), transactionType, paymentBy: action, action, type: transactionType, actionType })
          }
        })
      }
      return
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong!")
    } finally {
      dispatch(isLoading(false))
    }
  }

  const interestSubMenu = () => {
    const items: any[] = [{
      key: 'export-transactions',
      text: 'Export Transactions',
      ariaLabel: "Export Transactions",
      iconProps: { iconName: 'Download' },
      onClick: (e: any) => {
        e?.preventDefault();
        dispatch(exportTransactions())
      },
    }, {
      key: 'payment-statement',
      text: '1. Send Payment Statement',
      ariaLabel: "1. Send payment statement",
      iconProps: { iconName: 'Mail' },
      onClick: (e: any) => {
        e?.preventDefault();
        handlePaymentStatementEmail({
          actionType: 'payment-statement',
          transactionType: 'interest'
        })
      },
    },
    // {
    //   key: 'payment-cheque',
    //   text: '2.a Process Cheque Payment',
    //   ariaLabel: "2.a Process Cheque Payment",
    //   iconProps: { iconName: 'ProcessMetaTask' },
    //   onClick: (e: any) => {
    //     e?.preventDefault();
    //     processPayment('interest', 'cheque')
    //   },
    // }, 
    {
      key: 'payment-account',
      text: '2.a Process Bank Transfer Payment',
      ariaLabel: "2.a Process Bank Transfer Payment",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        processPayment('interest', 'account')
      },
    },
    {
      key: 'payment-ethex',
      text: '2.b Process Ethex Payment',
      ariaLabel: "2.b Process Ethex Payment",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        processPayment('interest', 'ethex')
      },
    },
    {
      key: 'payment-donate',
      text: '2.c Process Donate Payment',
      ariaLabel: "2.c Process Donate Payment",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        processPayment('interest', 'donate')
      },
    },
    {
      key: 'payment-reinvest',
      text: '2.d Process Re-Invest',
      ariaLabel: "2.d Process Re-Investt",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        reInvestTransaction('interest')
      },
    },
    {
      key: 'tax-statement',
      text: '3. Create Tax Statements',
      ariaLabel: "3. Create Tax Statements",
      iconProps: { iconName: 'EditCreate' },
      onClick: (e: any) => {
        e?.preventDefault();
        OpenCreateTaxStatementModal({ params: {} })
      },
    },
    {
      key: 'payment-confirmation',
      text: '4. Send Payment Confirmation',
      ariaLabel: "4. Send Payment Confirmation",
      iconProps: { iconName: 'Mail' },
      onClick: (e: any) => {
        e?.preventDefault();
        handlePaymentStatementEmail({
          actionType: 'payment-processed',
          transactionType: 'interest'
        })
      },
    }, {
      key: 'add-transaction',
      text: 'Add Transaction',
      ariaLabel: "Add Transaction",
      iconProps: { iconName: 'Add' },
      onClick: (e: any) => {
        e?.preventDefault();
        addTransaction()
      },
    }]

    return items
  }

  const getinterestActions = () => {
    let listActions: ICommandBarItemProps[] = []
    listActions.push(
      {
        key: 'newItem',
        text: '',
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'CollapseMenu' },
        subMenuProps: {
          items: [...interestSubMenu()],
        },
      })

    return listActions
  }


  const repaymentSubMenu = () => {
    const items: any[] = [{
      key: 'payment-statement',
      text: '1. Send Re Payment Statements',
      ariaLabel: "1. Send Re Payment Statements",
      iconProps: { iconName: 'Mail' },
      onClick: (e: any) => {
        e?.preventDefault();
        handlePaymentStatementEmail({
          actionType: 'payment-statement',
          transactionType: 'repayment'
        })
      },
    },
    // {
    //   key: 'payment-cheque',
    //   text: '2.a Process Cheque Re Payment',
    //   ariaLabel: "2.a Process Cheque Re Payment",
    //   iconProps: { iconName: 'ProcessMetaTask' },
    //   onClick: (e: any) => {
    //     e?.preventDefault();
    //     processPayment('repayment', 'cheque')
    //   },
    // }, 
    {
      key: 'payment-account',
      text: '2.a Process Bank Transfer Re Payment',
      ariaLabel: "2.a Process Bank Transfer Re Payment",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        processPayment('repayment', 'account')
      },
    },
    {
      key: 'payment-ethex',
      text: '2.b Process Ethex Re Payment',
      ariaLabel: "2.b Process Ethex Re Payment",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        processPayment('repayment', 'ethex')
      },
    },
    {
      key: 'payment-donate',
      text: '2.c Process Re Payment Donate',
      ariaLabel: "2.c Process Re Payment Donate",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        processPayment('repayment', 'donate')
      },
    },
    {
      key: 'payment-reinvest',
      text: '2.d Process Re Payment Re-Invest',
      ariaLabel: "2.d Process Re Payment Re-Invest",
      iconProps: { iconName: 'ProcessMetaTask' },
      onClick: (e: any) => {
        e?.preventDefault();
        reInvestTransaction("repayment")
      },
    },
    {
      key: 'payment-confirmation',
      text: '4. Send Re Payment Confirmation',
      ariaLabel: "4. Send Re Payment Confirmation",
      iconProps: { iconName: 'Mail' },
      onClick: (e: any) => {
        e?.preventDefault();
        handlePaymentStatementEmail({
          actionType: 'payment-processed',
          transactionType: 'repayment'
        })
      },
    }]

    return items
  }

  const getRepaymentActions = () => {
    let listActions: ICommandBarItemProps[] = []
    listActions.push(
      {
        key: 'newItem',
        text: '',
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'CollapseMenu' },
        subMenuProps: {
          items: [...repaymentSubMenu()],
        },
      })

    return listActions
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...transactionFilter, page: 1, pageSize: size }
    dispatch(setBondTransactionFilter(filters))
    dispatch(getBondTransactions())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      {progressData.totalTransactions > 0 && <ShowProgress />}
      <header className="table-header">
        <h1>Bond Transactions</h1>
        <TransactionFilters />
      </header>
      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }} className="margin-top-md">
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}>
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={transactionFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        <CommandBar
          items={[...getRepaymentActions()]}
          ariaLabel="Actions"
          primaryGroupAriaLabel="Actions"
        />
        <CommandBar
          items={[...getinterestActions()]}
          ariaLabel="Actions"
          primaryGroupAriaLabel="Actions"
        />
      </div>
      <TransactionList actions={["edit", "delete"]} />
    </div>
  );
}
export default BondTransactionScreen;
