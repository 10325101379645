import { ComboBox, DefaultButton, IComboBoxOption, IPersonaProps, Label, NormalPeoplePicker, Pivot, PivotItem, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { ITaskType } from "@solarforschools/sfs-core/dist/taskType/types"
import _ from "lodash"
import { useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import store, { RootState } from "../../../store"
import ModalDialog from "../common/ModalDialog"
import { IEstablishment } from "@solarforschools/sfs-core"
import { requiredMessage } from "../common/common"
import { assignSchool } from "../../../store/admin/school/action"
import { getProjectsApi } from "../../../store/client/project"
import { getUsers } from "../../../store/client"
import { projectNameChange } from "../project/helper"


export const openAssignSchoolModal = (props?: any) => {
  return new Promise<ITaskType | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: any) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={`Assign/ Un Assign schools`}
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <AssignSchoolDialog
            {...props}
            onSave={() => callback(undefined)}
            onCancel={() => callback(undefined)}
            type={props?.type || 'Project'}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const AssignSchoolDialog = (props: IAssignSchoolProps) => {
  const dispatch = useDispatch();
  const [payload, setData] = useState<any>({
    selected: [],
    type: 'project',
    mode: props.mode,
    schools: props.schools?.map(s => {
      return {
        _id: s._id,
        name: s.name,
        slug: s.slug
      }
    })
  })
  const [errors, setErrors] = useState<any>({})


  const handleOnSave = async () => {
    const errors = payload.selected?.length === 0 ? { error: requiredMessage } : {};
    if (Object.keys(errors).length) {

      setErrors(errors);
      return;
    }
    await dispatch(assignSchool(payload))
    props.onSave()
  }



  const handleOnChangeInput = (key: string, item: any) => {
    setData({ ...payload, _id: item.key })
  }
  const onPivotLinkClick = async (props: any) => {
    if (props.key === ".$project") {
      setData({ ...payload, type: 'project', selected: [] })
    } else {
      setData({ ...payload, type: 'user', selected: [] })
    }
    setErrors({})
  };

  const handleGetData = (query: any) => async (
    search: string,
    selectedItems: IPersonaProps[] = [],
  ): Promise<IPersonaProps[]> => {
    if (search.length < 4) return []
    try {
      const data: any = payload.type === 'user' ? await getUsers({ userName: search, ...query }) : await (await getProjectsApi({ name: search, all: true })).projects;
      const selectedMap: { [key: string]: boolean } = {};
      selectedItems?.forEach(i => selectedMap[i.key!] = true);
      const isUser = payload.type === 'user';
      return data
        .filter((u: { userEmail: any; slug: any }) => !selectedMap[isUser ? u.userEmail : u.slug])
        .map((option: { userEmail: any; userName: any, slug: string, name: string }) => {
          return {
            key: isUser ? option.userEmail : option.slug,
            text: isUser ? option.userName : option.name,
            secondaryText: isUser ? option.userEmail : option.slug,
          }
        })
    } catch (error) {
      return []
    }
  }
  const handleChangeData = (property: string) => (selected?: IPersonaProps[]) => {
    if (!selected) return [];
    const users = selected?.map(user => {
      const pm: Partial<any> = {
        key: user.secondaryText!,
        text: user.text!,
        secondaryText: user.secondaryText!,
      }
      return pm as any;
    });
    setData({ ...payload, selected: users[0] ? [users[0]] : [] })
  }
  const pmToPersona = (data: any): IPersonaProps => {
    return {
      key: data.key,
      text: data.text,
      secondaryText: data.secondaryText
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <Pivot linkSize="large" onLinkClick={(item) => onPivotLinkClick(item)}>
          <PivotItem key="project" headerText={`${projectNameChange['Project']}`}>
            <Label
              style={{ color: errors.error ? 'rgb(164, 38, 44)' : undefined }}
              className='ms-Grid-col ms-lg12'
            >
              <NormalPeoplePicker
                styles={{ root: { marginTop: 5 } }}
                selectedItems={payload.selected?.map(pmToPersona)}
                onResolveSuggestions={handleGetData({ permissions: 'projectManager' })}
                getTextFromItem={persona => persona.text as string}
                selectionAriaLabel={'Select Project'}
                itemLimit={1}
                onChange={handleChangeData('projectManagers')}
              />
              {errors.error}
            </Label>
          </PivotItem>
          <PivotItem key="user" headerText="User">
            <Label
              style={{ color: errors.error ? 'rgb(164, 38, 44)' : undefined }}
              className='ms-Grid-col ms-lg12'
            >
              <NormalPeoplePicker
                styles={{ root: { marginTop: 5 } }}
                selectedItems={payload.selected?.map(pmToPersona)}
                onResolveSuggestions={handleGetData({})}
                getTextFromItem={persona => persona.text as string}
                itemLimit={1}
                selectionAriaLabel={'Select User'}
                onChange={handleChangeData('projectManagers')}
              />
              {errors.error}
            </Label>
          </PivotItem>
        </Pivot>
        <div className="ms-Grid-row">
          {/* {props.type === 'Project' &&
            <Label
              style={{ color: errors.projectManagers ? 'rgb(164, 38, 44)' : undefined }}
              className='ms-Grid-col ms-lg12'
            >
              {errors.projectManagers || 'Project Managers'}
              <NormalPeoplePicker
                styles={{ root: { marginTop: 5 } }}
                selectedItems={payload.selected?.map(pmToPersona)}
                onResolveSuggestions={handleGetData({ permissions: 'projectManager' })}
                getTextFromItem={persona => persona.text as string}
                selectionAriaLabel={'Selected managers'}
                onChange={handleChangeProjectManagers('projectManagers')}
              />
            </Label>
          } */}
          {/* {props.type === 'User' &&
            <ComboBox
              label="Select User"
              placeholder="Select User"
              selectedKey={data?._id || null}
              options={users as IComboBoxOption[]}
              onChange={(e, item) => handleOnChangeInput('user', item)}
              errorMessage={errors?._id}
              className="ms-Grid-col ms-lg6"
            />
          } */}
        </div>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface IAssignSchoolProps {
  onCancel?: () => void;
  onSave: () => void;
  schools?: IEstablishment[];
  mode?: 'assign' | 'unassign'
}

export default openAssignSchoolModal;
