import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { isSortable } from "../common/utils";
import { IInvoicePriceDocument } from "@solarforschools/sfs-core";


// {
//     "_id": "659fa68c9d0701b1634b244e",
//     "contract": {
//         "id": "1",
//         "number": "SS0003",
//         "title": "Kingfisher Hall",
//         "school": "Kingfisher Hall Primary Academy",
//         "contact": "Sue Ellingham",
//         "customerName": "Kingfisher Hall Primary Academy",
//         "customerNr": "SS0003",
//         "address": "Kingfisher Hall Primary Academy",
//         "address1": "40 The Ride",
//         "address2": null,
//         "postcode": "EN3 7GB",
//         "city": "Enfield, London",
//         "county": null,
//         "formatted": "40 The Ride, Enfield, London, EN3 7GB",
//         "MPAN": "10 3008 3649 169",
//         "vat": 5,
//         "standingCharge": null,
//         "start": "2016-09-01T22:00:00Z",
//         "active": true,
//         "to": [
//             "SEllingham@northstartrust.org.uk"
//         ],
//         "cc": [
//             "SEllingham@northstartrust.org.uk",
//             "cbsaccounts@solarforschools.co.uk"
//         ],
//         "MailName": "Susan",
//         "funder": "CBS",
//         "peakPower": 29.92
//     },
//     "year": "2023",
//     "currentPrice": {
//         "id": "915",
//         "contractId": "SS0003",
//         "customer": "Kingfisher Hall",
//         "start": "2023-03-31T00:00:00.000Z",
//         "end": "2024-03-31T00:00:00.000Z",
//         "budget": 11.1,
//         "ppa": 12.59,
//         "vat": 5,
//         "pricePaid": 28
//     },
//     "previousPrice": {
//         "id": "591",
//         "contractId": "SS0003",
//         "customer": "Kingfisher Hall",
//         "start": "2022-04-01T00:00:00.000Z",
//         "end": "2023-03-31T00:00:00.000Z",
//         "budget": 10.33,
//         "ppa": 11.1,
//         "vat": 5,
//         "pricePaid": 21
//     },
//     "link": "/Beta/CBS/InvoicesPrice/SS0003-kingfisher-hall-primary-academy-2023-1704967262.docx"
// }
export const getInvoicePriceColumns = (params?: {
    actions: {
        name?: string;
        icon: any,
        title: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'contract.number',
            name: 'Contract Number',
            fieldName: 'contract.number',
            ariaLabel: "Contract Number",
            minWidth: 80,
            maxWidth: 200,
            data: 'string',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => <TooltipHost content={item.contract.number} closeDelay={500}> {item.contract.number}</TooltipHost>
        },
        {
            key: 'contract.customerName',
            name: 'Customer Name',
            ariaLabel: "Customer Name",
            fieldName: 'contract.customerName',
            minWidth: 120,
            data: 'string',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => <TooltipHost content={item.contract.customerName} closeDelay={500}> {item.contract.customerName}</TooltipHost>
        },

        {
            key: 'previousPrice.start',
            name: 'Old Start Date',
            fieldName: 'previousPrice.start',
            minWidth: 80,
            data: 'string',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => {
                return item.previousPrice.start ? moment(item.previousPrice.start).format('YYYY-MM-DD') : item.previousPrice.start
            }
        },
        {
            key: 'previousPrice.start',
            name: 'Old End Date',
            fieldName: 'previousPrice.start',
            minWidth: 80,
            data: 'string',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => {
                return item.previousPrice.end ? moment(item.previousPrice.end).format('YYYY-MM-DD') : item.previousPrice.end
            }
        },

        {
            key: 'previousPrice.ppa',
            name: 'OldPPA',
            fieldName: 'previousPrice.ppa',
            minWidth: 80,
            data: 'number',
            // className: 'text-right',
            // headerClassName: 'text-right',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => {
                return item.previousPrice.ppa
            }
        },
        {
            key: 'currentPrice.start',
            name: 'Start Date',
            fieldName: 'currentPrice.start',
            minWidth: 80,
            data: 'string',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => {
                return item.currentPrice.start ? moment(item.currentPrice.start).format('YYYY-MM-DD') : item.currentPrice.start
            }
        },
        {
            key: 'currentPrice.start',
            name: 'End Date',
            fieldName: 'currentPrice.start',
            minWidth: 80,
            data: 'string',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => {
                return item.currentPrice.end ? moment(item.currentPrice.end).format('YYYY-MM-DD') : item.currentPrice.end
            }
        },
        {
            key: 'currentPrice.ppa',
            name: 'Price',
            fieldName: 'currentPrice.ppa',
            minWidth: 80,
            data: 'number',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => {
                return item.currentPrice.ppa
            }
        },{
            key: 'year',
            name: 'Year',
            fieldName: 'year',
            minWidth: 80,
            data: 'number',
            ...isSortable,
            onRender: (item: IInvoicePriceDocument) => {
                return item.year
            }
        }, {
            key: "action",
            name: "Action",
            fieldName: "-",
            minWidth: 90,
            onRender: (item: IInvoicePriceDocument) => {
                return (
                    <div className="action-icons-container">
                        {params?.actions.map((action: any) => {
                            if (!action.name || (action.name && item.link))
                                return <span className="action-icons" >
                                    <FontAwesomeIcon
                                        icon={action.icon}
                                        onClick={() => action.onClick(item)}
                                        title={action.title || ''} />
                                </span>
                        })
                        }
                    </div >
                );
            },
        },
    ];
}