import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTasks, setTaskFilter } from "../../../store/admin/task/action";
import Loader from "../common/Loader";
import { DefaultTaskFilters } from "./common";
import TaskFilters from "./TaskFilters";
import TasksList from "./TasksList";


const TaskScreen = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTaskFilter(DefaultTaskFilters))
    dispatch(fetchTasks())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Tasks</h1>
        <TaskFilters taskFilter={DefaultTaskFilters}  hidden={[]}/>
      </header>
      <TasksList />
    </div>
  )
}
export default TaskScreen;

