import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import FaqFilters from './FaqsFilters';
import { RootState } from '../../../store';
import { ActionButton, Dropdown, IDropdownOption, Stack, Text } from '@fluentui/react';
import { IFaqState } from './../../../store/admin/faqs/reducer';
import { getFaqs, setFaqCatsAndSites, setFaqFilter } from './../../../store/admin/faqs/action';
import FaqsList from './FaqsList';
import OpenFaqModal from './AddEditFaqDialog';
import { initFaq } from './FaqHelper';
import { PageSize } from '../common/common';

const FaqScreen = () => {
  const dispatch = useDispatch()
  const { faqs, totalCount, faqFilter }: IFaqState = useSelector<RootState, IFaqState>((state: RootState) => state.web.faqs)

  useEffect(() => {
    dispatch(getFaqs())
    dispatch(setFaqCatsAndSites())
  }, []);

  const handleCreateNewFaq = async () => {
    try {
      await OpenFaqModal({ faq: initFaq })
    } catch (error) {
      console.log(error)
    }
  }
  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 10
    const filters = { ...faqFilter, page: 1, pageSize: size }
    dispatch(setFaqFilter(filters))
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>FAQ's</h1>
        <FaqFilters />
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{ width: '100%' }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={faqFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
          <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleCreateNewFaq}>
            Add FAQ
          </ActionButton>
        </Stack>
      </header>
      <FaqsList actions={[]} />
    </div>
  );
}
export default FaqScreen;

