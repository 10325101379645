import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import UserList from './userList';
import UserFilters from './userFilters';
import { getUserPermissionsSkills, getUsers, setUserFilter } from '../../../store/admin/users/action';
import { RootState } from '../../../store';
import { ActionButton, Dropdown, IDropdownOption, Stack, Text } from '@fluentui/react';
import { initUser } from './helper';
import openUserModal from './userDialog';
import { IUserState } from '../../../store/admin/users/reducer';
import { PageSize } from '../common/common';

const UserScreen = () => {
  const dispatch = useDispatch()
  const { users, totalCount, userFilter }: IUserState = useSelector<RootState, IUserState>((state: RootState) => state.web.user)

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getUserPermissionsSkills({ active: true, all: true }))
  }, []);

  const handleCreateNewUser = async () => {
    try {
      await openUserModal({ user: initUser })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...userFilter, page: 1, pageSize: size }
    dispatch(setUserFilter(filters))
    dispatch(getUsers(filters))
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Users</h1>
        <UserFilters />
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{ width: '100%' }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={userFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
          <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleCreateNewUser}>
            Add User
          </ActionButton>
        </Stack>
      </header>
      <UserList actions={['edit', 'add', 'delete']} />
    </div>
  );
}
export default UserScreen;

