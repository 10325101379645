import { IRegionConfig } from "@solarforschools/sfs-core";
import { Action } from "./actionTypes";

export interface IRegionConfigState {
  isLoading: boolean;
  regionConfigList: IRegionConfig[];
  regionCostResults: Record<string, number> | undefined;
  error: string;
}
const initialState = {
  isLoading: false,
  regionConfigList: [],
  regionCostResults: undefined,
  error: "",
};
const regionConfig = (
  state: IRegionConfigState = initialState,
  action: Action
): IRegionConfigState => {
  switch (action.type) {
    case "SET_REGION_CONFIG":
      return { ...state, regionConfigList: action.regionConfigList };

    case "UPDATE_REGION_CONFIG":
      const regionConfigList: IRegionConfig[] = [...state.regionConfigList];
      const regionConfigCheck = regionConfigList.findIndex(
        (regionConfig) => regionConfig._id === action.regionConfigList._id
      );
      if (regionConfigCheck + 1) {
        regionConfigList[regionConfigCheck] = action.regionConfigList;
      }

      return { ...state, regionConfigList };

    case "SET_COST_RESULTS":
      const costResultsPayload = action.payload;
      return {
        ...state,
        regionCostResults: costResultsPayload,
      };

    case "SET_NEW_REGION_CONFIG":
      const newRegionsPayload = action.payload;

      return {
        ...state,
        regionConfigList: [...state.regionConfigList, newRegionsPayload]
      };

    default:
      return state;
  }
};

export default regionConfig;
