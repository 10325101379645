import moment from "moment";
import {
  Action,
  IComboxBoxOptions,
  ISchoolReportData,
  ISchoolReportForm,
} from "./actionTypes";

// States' definition
export interface IReports {
  isLoading: boolean;
  liveSchools: any[];
  liveSchoolRegions: any[];
  liveSchoolTrusts: any[];
  invoiceCycles: IComboxBoxOptions[];
  schoolReportForm: ISchoolReportForm;
  siteNames: IComboxBoxOptions[];
  schoolReportData: ISchoolReportData[];
  pvamPrices?: any[]
  dailyYields?: any[]
}
export interface State {
  reports: IReports;
}

const reports = (
  state: IReports = {
    isLoading: false,
    liveSchools: [],
    liveSchoolTrusts: [],
    liveSchoolRegions: [],
    invoiceCycles: [],
    schoolReportForm: {
      trustNames: [],
      regionNames: [],
      schoolNames: [],
      siteNames: [],
      customHeaders: [],
      ownedBy: "all",
      period: "",
      invoiceCycle: `${moment().year()}Q${moment().quarter()}`,
      from: moment().subtract(1, "month").format("YYYY-MM-DD"),
      to: moment(new Date()).format("YYYY-MM-DD"),
      isCompare: false,
      compareInvoiceCycle: `${moment().year()}Q${moment().quarter()}`,
      compareFrom: moment().subtract(4, "month").format("YYYY-MM-DD"),
      compareTo: moment().subtract(2, "month").format("YYYY-MM-DD"),
    },
    siteNames: [],
    schoolReportData: [],
    pvamPrices: [],
    dailyYields: []
  },
  action: Action
): IReports => {
  switch (action.type) {
    case "SET_DAILY_YIELDS_DATA": {
      return { ...state, dailyYields: action.dailyYields }
    }
    case "SET_SCHOOL_PVAM_PRICE_DATA": {
      return { ...state, pvamPrices: action.pvamPrices };
    }
    case "SET_LIVE_SCHOOL_REGIONS": {
      return { ...state, liveSchoolRegions: action.options };
    }
    case "SET_LIVE_SCHOOL_TRUSTS": {
      return { ...state, liveSchoolTrusts: action.options };
    }
    case "SET_LIVE_SCHOOL": {
      return { ...state, liveSchools: action.options };
    }
    case "SET_INVOICE_CYCLE": {
      return { ...state, invoiceCycles: action.options };
    }
    case "SET_SCHOOL_FORM": {
      return { ...state, schoolReportForm: action.payload };
    }
    case "SET_SITE_NAME": {
      return { ...state, siteNames: action.options };
    }
    case "SET_REPORT_DATA": {
      return { ...state, schoolReportData: action.data };
    }
    default:
      return state;
  }
};

export default reports;
