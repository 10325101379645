import { useEffect, useState } from "react";
import {
  ActionButton,
  ScrollablePane,
  Stack,
  CheckboxVisibility,
  ICommandBarItemProps,
  CommandBar,
  IDropdownOption,
  Dropdown
} from "@fluentui/react";
import { SelectionMode, IColumn } from "@fluentui/react/lib/index";

import { ISystemState } from "../../../store/admin/system/reducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  deleteASystem,
  exportSystems,
} from "../../../store/admin/system/action";
import { OpenEditSystemModal } from "./EditSystemDialog";
import { RootState } from "../../../store";
import { OpenAddSystemDialog } from "./AddSystemDialog";
import {
  setDetailListFilter,
  fetchSystemsData,
} from "../../../store/admin/system/action";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { ISystem } from "@solarforschools/sfs-core";
import { OpenDataProcessModal } from "./DataProcessDialog";
import DetailsListWapper from "../../common/DetailsListWapper";
import { getSystemColumns } from "./SystemColumns";
import { StringMap } from "../common/types";
import { getSystemApi } from "../../../store/client/system";
import { useParams } from "react-router-dom";
import { getSystemProps } from "./common";
import { PageSize } from "../common/common";
import Pagination from "../common/Pagination";

const SystemList = ({ actions, extraActions }: Props = { actions: [], extraActions: [] }) => {
  const dispatch = useDispatch();
  const params: StringMap = {}// useParams();
  const { systems = [], systemFilterParams = {}, totalCount }: ISystemState = useSelector<RootState, ISystemState>(
    (state: RootState) => state.web.system
  );
  const handleOnDeleteSystem = async (system: ISystem) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete System",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${system.name}`,
      },
    });
    if (confirm) dispatch(deleteASystem(system.id));
  }
  const handleDataprocessing = async (system: ISystem) => {
    await OpenDataProcessModal({ system })
  }
  const handleOnEditSystem = async (system: ISystem) => {
    window.history.replaceState(null, "", `/systems/${system.id}`)
    await OpenEditSystemModal({ system, })
    window.history.replaceState(null, "", `/systems`)
  }

  const loadData = async () => {
    if (params?.id) {
      const { system }: any = await getSystemApi(params?.id)
      if (system) {
        handleOnEditSystem(system)
      }
    }
  }
  useEffect(() => {
    loadData();
  }, [])

  const getActions = (system: ISystem, actions: any, extraActions?: ICommandBarItemProps[]) => {
    let listActions: ICommandBarItemProps[] = []
    for (const action of actions) {
      if (action === 'dp') listActions.push(
        {
          key: 'newItem',
          text: '',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
          iconProps: { iconName: 'DatabaseSync' },
          subMenuProps: {
            items: [
              {
                key: 'dp',
                text: 'Generate Data',
                iconProps: { iconName: 'chart' },
                onClick: (e) => {
                  e?.preventDefault();
                  handleDataprocessing(system);
                },
              }
            ],
          },
        })
      if (action === 'edit') listActions.push({
        key: 'edit',
        text: '',
        iconProps: { iconName: 'Edit' },
        onClick: (e) => {
          e?.preventDefault();
          handleOnEditSystem(system);
        },
      })
      if (action === 'delete') listActions.push({
        key: 'delete',
        text: '',
        iconProps: { iconName: 'Trash' },
        onClick: (e) => {
          e?.preventDefault();
          handleOnDeleteSystem(system);
        },
      })
    }
    if (extraActions) {
      for (const action of extraActions) {
        listActions.push({
          key: action.key,
          text: '',
          iconProps: { iconName: action.icon },
          onClick: (e) => {
            e?.preventDefault();
            action?.action(system);
          },
        })
      }
    }
    return listActions
  }

  const headerRow: IColumn[] = [...getSystemColumns, {
    key: "action",
    name: "Action",
    fieldName: "-",
    minWidth: 200,
    onRender: (system: ISystem) => {
      return (
        <CommandBar
          items={[...getActions(system, actions, extraActions)]}
          ariaLabel="Actions"
          primaryGroupAriaLabel="Actions"
        />
      )
    },
  }]

  const [columns] = useState<IColumn[]>(headerRow);
  const handleOnExport = () => dispatch(exportSystems())
  const handleOnSort = (field: string, order: string) => {
    const params = { ...systemFilterParams, sortField: field, sortOrder: order };
    dispatch(setDetailListFilter(params));
    dispatch(fetchSystemsData(params));
  }

  const loadNextPage = (p: any) => {
    dispatch(setDetailListFilter({ ...systemFilterParams, ...p }))
    dispatch(fetchSystemsData())
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...systemFilterParams, page: 1, pageSize: size }
    dispatch(setDetailListFilter(filters))
    dispatch(fetchSystemsData())
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Stack tokens={{ padding: '0 15px' }} horizontal verticalAlign='center'>
        <span
          style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
        >
          {totalCount > 0 &&
            <>
              Displaying&nbsp;
              <Dropdown
                selectedKey={systemFilterParams.pageSize?.toString() || "50"}
                options={PageSize}
                styles={{ dropdown: { width: "80px" } }}
                onChange={handleOnChangePageSize}
              />
              &nbsp;/ {totalCount}
            </>}
        </span>
        <div style={{ display: 'flex', flex: 1 }} />
        {actions.includes('add') && (
          <ActionButton
            allowDisabledFocus
            style={{ textAlign: 'right' }}
            onClick={async () => {
              const system = getSystemProps({
                provider: ""
              }, {})
              if (system) await OpenEditSystemModal({ system })
            }}
          ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp;Add System(Manually)
          </ActionButton>
        )}
        {actions.includes('export') && (
          <ActionButton
            allowDisabledFocus
            style={{ textAlign: 'right' }}
            onClick={() => handleOnExport()}
          ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
          </ActionButton>)}
        {actions.includes('add') && (
          <ActionButton
            allowDisabledFocus
            style={{ textAlign: 'right' }}
            onClick={async () => {
              const system = await OpenAddSystemDialog({})
              if (system) await OpenEditSystemModal({ system })
            }}
          ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp;Add System
          </ActionButton>
        )}
      </Stack>
      {totalCount ? (
        <>
          <ScrollablePane
            style={{
              display: "flex",
              flex: 1,
              position: "relative",
              minHeight: "300px"
            }}
          >
            <DetailsListWapper
              items={systems}
              columns={columns}
              selectionMode={SelectionMode.none}
              onRenderDetailsHeader={DetailListStickyHeader}
              compact={true}
              checkboxVisibility={CheckboxVisibility.hidden}
              canLoadMore={systems.length < totalCount}
              // onRequestLoadMore={loadNextPage}
              onSort={(field, order) => handleOnSort(field, order)}
            // styles={{ contentWrapper: { height: "60vh" } }}
            />
          </ScrollablePane>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={systemFilterParams?.page!}
            pageSize={systemFilterParams.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </div>
  );
};

export default SystemList;

interface OwnProps {
  actions: string[];
  extraActions?: any[]
}

type Props = OwnProps;
