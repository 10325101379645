import {
  ISupporters,
  ISchools,
} from "@solarforschools/sfs-core/dist/supporters/types";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../..";
import { AnyAction } from "redux";
import { isLoading } from "../action";
import axios from "axios";
import { host } from "../../config";
import { toast } from "react-toastify";
import { IDeleteSupporter, ISetLiveSchools, ISetSupporters, IUpdateSupporter } from "./actionTypes";

export const setSupporters = (data: ISupporters[]): ISetSupporters => {
  return {
    type: "SET_SUPPORTERS",
    payload: data,
  };
};

export const setLiveSchools = (data: ISchools[]): ISetLiveSchools => {
  return {
    type: "SET_LIVE_SCHOOLS",
    payload: data,
  };
};

export const removeSupporter = (data: string):IDeleteSupporter => {
  return {
    type: "DELETE_SUPPORTER",
    payload: data,
  }
}

export const updateSupporter = (data: ISupporters): IUpdateSupporter => {
  return {
    type: "UPDATE_SUPPORTER",
    payload: data
  }
}

export const fetchSupporters = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchSupportersThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${host}/supporters`);
      dispatch(isLoading(false));
      if (res.status === 200) {
        dispatch(setSupporters(res?.data));
      } else {
        toast.error("Error in getting supporters list");
      }
    } catch (err) {
      console.log("Error is ", err);
      toast.error("Error in getting supporters list");
      dispatch(isLoading(false));
    }
  };
};

export const fetchLiveSchools = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchLiveSchoolsThunk(dispatch) {
    try {
      const res = await axios.get(`${host}/supporters/liveschools`);

      if (res.status === 200) {
        dispatch(setLiveSchools(res?.data));
      } else {
        toast.error("Error in getting live schools");
      }
    } catch (err) {
      console.log("Error is ", err);
      toast.error("Error in getting live schools");
    }
  };
};

export const insertASupporter = (
  data: Partial<ISupporters>
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function createSupportersThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.post(`${host}/supporters`, { data });
      dispatch(isLoading(false));
      if (res.status === 201) {
        toast.success("New supporter added");
        dispatch(fetchSupporters());
      } else {
        toast.success("Failed to create new supporter");
      }
    } catch (err: any) {
      toast.error("Failed to create new Supporter");

      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};

export const deleteASupporter = (
  supporterId: string,
  icon: string
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function deleteSupportersThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.delete(
        `${host}/supporters/${supporterId}?imagePath=${icon}`
      );
      dispatch(isLoading(false));
      if (res.status === 200) {
        toast.success("Supporter deleted successfully");
        dispatch(removeSupporter(supporterId));
      } else {
        toast.success("Failed to delete supporter");
      }
    } catch (err: any) {
      toast.error("Failed to delete Supporter");

      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};

export const updateASupporter = (
  data: ISupporters
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function updateSupportersThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.put(`${host}/supporters`, { data });
      dispatch(isLoading(false));
      if (res.status === 200) {
        toast.success("Supporter updated successfully");
        dispatch(updateSupporter(data));
      } else {
        toast.success("Failed to update supporter");
      }
    } catch (err: any) {
      toast.error("Failed to update Supporter");

      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};
