import { useEffect, useRef, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ProgressIndicator, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider, useDispatch } from 'react-redux';
import ModalDialog from '../../common/ModalDialog';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import store from '../../../../store';
import { Checkbox } from 'office-ui-fabric-react';
import { getBondsApi } from '../../../../store/client/funder/bond';
import { isLoading } from '../../../../store/admin/action';
import { TransactionPaymentAction } from './types';
import { TransactionType } from '@solarforschools/sfs-core/dist/funder/transaction/types';
import { processPaymentApi } from '../../../../store/client/funder/transaction';
import { downloadFileFromStream } from '../../common/utils';



export const OpenPaymentProcessModal = (props: { params: any }) => {
	return new Promise((resolve, reject) => {
		const mountTarget = document.createElement('div')
		document.body.appendChild(mountTarget)
		const callback = (result?: any) => {
			resolve(result)
			ReactDOM.unmountComponentAtNode(mountTarget)
			mountTarget.remove()
		}
		ReactDOM.render(
			<Provider store={store}>
				<ModalDialog
					isModalOpen={true}
					title={"Confirm Payment Process"}
					onDismiss={() => callback(undefined)}
					containerClassName={"modal-size-xs"}
				>
					<PaymentProcessModal {...props} onSave={(data) => callback(data)} onCancel={() => callback(undefined)} />
				</ModalDialog>
			</Provider>,
			mountTarget
		)
	})
}


export const PaymentProcessModal = ({
	params,
	onCancel,
	onSave,
}: Props) => {
	const dispatch = useDispatch()

	const [statusClosed, setStatusClosed] = useState<boolean>(false)



	const handleProcess = async () => {
		dispatch(isLoading(true))
		try {
			const { data } = await processPaymentApi({ period: params.period, status: statusClosed, transactionType: params.transactionType, paymentBy: params.action, type: params.type, bondOfferId: params.bondOfferId, isTestTransaction: params.isTestTransaction });
			downloadFileFromStream({ data: data, filename: `${params.period} payment-${params.action}`, type: "csv" })
		} catch (error) {
			console.log(error)
		} finally {
			dispatch(isLoading(false))
		}

	};
	const handleCancel = () => {
		onCancel()
	}

	const handleOnChange = async (value?: boolean) => {
		setStatusClosed(value!)
	}
	return (
		<div className="edit-record">
			<div className='ms-Grid-row'>
				<div className='margin-top-lg'>
					<p className='ms-Grid-col ms-lg12' >Mark to close transactions in final run.</p>
				</div>
			</div>
			<div className='ms-Grid-row'>
				<Checkbox
					label="Close"
					checked={statusClosed}
					onChange={(e, value) => handleOnChange(value)}
					className="ms-Grid-col ms-lg12 margin-top-md"
				/>
			</div>
			<Sticky stickyPosition={StickyPositionType.Footer}>
				<Stack horizontal horizontalAlign="center">
					<Stack.Item>
						<PrimaryButton
							text={'Process'}
							onClick={handleProcess}
							className="btn-primary"
						/>
						<DefaultButton onClick={handleCancel} text="Cancel" />
					</Stack.Item>
				</Stack>
			</Sticky>
		</div>
	);
};

interface OwnProps {
	params: {
		action: TransactionPaymentAction,
		type: TransactionType,
		period: string,
		bondOfferId: string
		isTestTransaction?: any
		transactionType?: any
	};
	onCancel: () => void;
	onSave: (params: any) => void;
}

type Props = OwnProps;
