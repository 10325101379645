import { useEffect, useState } from 'react';
import { ActionButton, CheckboxVisibility, DetailsList, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility, Shimmer, Stack, Text } from '@fluentui/react';
import { SelectionMode, IColumn, Selection } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";



import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { getMeterReadingColumns, initMeterReading } from './helper';
import DetailsListWapper from '../../common/DetailsListWapper';
import { IMeterReading } from '@solarforschools/sfs-core/dist/solardb/types';
import { IMeterReadingState } from '../../../store/admin/meterReading/reducer';
import { deleteMeterReading, deleteMultipleMeterReading, exportMeterReadings, getMeterReadings, setMeterReadingFilter } from '../../../store/admin/meterReading/action';
import openMeterReadingModal from './meterDialog';
import { SetDetailListHeaderSortDirection } from '../common/utils';
import { RenderListRow } from '../common/RenderListRow';
import InView from "react-intersection-observer";
import { faFileExcel, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OpenImportReadingModal } from './ImportMeterReadingModal';
import openFillingMeterReadingModal from './meterFillingDialog';
import Pagination from '../common/Pagination';
import { PageSize } from '../common/common';

const MeterList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { meterReadings, totalCount, meterReadingFilter }: IMeterReadingState = useSelector<RootState, IMeterReadingState>((state: RootState) => state.web.meterReading)

  const handleOnDelete = async (data: IMeterReading | any[], isMultiDelete: boolean = false) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Meter Reading(s)",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete`,
      },
    });
    if (confirm) {
      if (isMultiDelete) {
        dispatch(deleteMultipleMeterReading(data as IMeterReading[]))
        setSelectedItems([])
        selection.setAllSelected(false)
      } else {
        dispatch(deleteMeterReading(data as IMeterReading));
      }
    }
  };

  const handleOnEdit = async (data: IMeterReading) => {
    await openMeterReadingModal({ data })
  };

  const headerRow = getMeterReadingColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        onClick: handleOnDelete
      }
    ]
  })
  const [columns, setColumns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...meterReadingFilter, sortField: field, sortOrder: order };
    dispatch(setMeterReadingFilter(params));
    dispatch(getMeterReadings(params))
  }
  const loadNextPage = (p: any) => {
    const { page = 0 } = meterReadingFilter
    dispatch(setMeterReadingFilter({ ...meterReadingFilter, ...p }))
    dispatch(getMeterReadings())
  }

  const handleOnHeaderClick = (column?: IColumn): void => {
    if (column?.key === 'action') return
    const index = columns.findIndex(c => c.key === column?.key)
    const newColumns = SetDetailListHeaderSortDirection(column, columns)
    setColumns(newColumns)
    handleOnSort(newColumns[index].key, newColumns[index].isSortedDescending ? 'desc' : 'asc')
  };
  const [selectedItems, setSelectedItems] = useState<Array<any> | undefined>(undefined)
  const [selection] = useState(new Selection({
    onSelectionChanged: () => {
      const data = selection.getSelection()
      setSelectedItems(data.filter(Boolean));
    }
  }));

  useEffect(() => {
    const preSelected: any = (selectedItems);
    if (preSelected?.length > 0) {
      selection.setChangeEvents(false);
      preSelected.forEach((item: any) => {
        const index = meterReadings.findIndex((s: any) => s?._id.toString() === item?._id.toString())
        if (index >= 0) {
          selection.setIndexSelected(index, true, true)
        }
      });
      selection.setChangeEvents(true);
    }
  }, [meterReadings, selectedItems]);

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...meterReadingFilter, page: 1, pageSize: size }
    dispatch(setMeterReadingFilter(filters));
    dispatch(getMeterReadings(filters))
  }
  return (
    <>
      {totalCount > 0 ? (
        <>
          <Stack tokens={{ padding: '0 15px' }} horizontal verticalAlign='center'>
            <span
              style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
            >
              {totalCount > 0 &&
                <>
                  Displaying&nbsp;
                  <Dropdown
                    selectedKey={meterReadingFilter.pageSize?.toString() || "50"}
                    options={PageSize}
                    styles={{ dropdown: { width: "80px" } }}
                    onChange={handleOnChangePageSize}
                  />
                  &nbsp;/ {totalCount}
                </>}
            </span>
            <div style={{ display: 'flex', flex: 1 }} />
            {selectedItems?.length && (
              <ActionButton
                allowDisabledFocus
                style={{ textAlign: 'right' }}
                onClick={async () => await handleOnDelete(selectedItems, true)}
              >
                <FontAwesomeIcon icon={faTrash} size='1x' color="#106ebe" />  &nbsp;Delete Selected
              </ActionButton>
            )}

            <ActionButton
              style={{ textAlign: 'right' }}
              iconProps={{ iconName: 'Import' }} onClick={async () => {
                await OpenImportReadingModal()
              }}>
              Import Reading
            </ActionButton>
            <ActionButton
              style={{ textAlign: 'right' }}
              iconProps={{ iconName: 'Add' }} onClick={async () => {
                await openFillingMeterReadingModal()
                dispatch(getMeterReadings())
              }}>
              Fill Reading
            </ActionButton>
            <ActionButton iconProps={{ iconName: 'Add' }} onClick={async () => await openMeterReadingModal({ data: initMeterReading })}>
              Add Reading
            </ActionButton>
            <ActionButton
              allowDisabledFocus
              // style={{ textAlign: 'right' }}
              onClick={() => dispatch(exportMeterReadings())}
            ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
            </ActionButton>
          </Stack>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                items={meterReadings}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                selection={selection}
                checkboxVisibility={CheckboxVisibility.always}
                onRenderRow={(props) => RenderListRow(props!)}
              // onRenderMissingItem={() => {
              //   if (meterReadings.length < totalCount) {
              //     return (
              //       <InView as="div" onChange={(inView) => inView && loadNextPage && loadNextPage()}>
              //         <Shimmer width='100%' />
              //       </InView>
              //     )
              //   }
              //   return <></>
              // }}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={meterReadingFilter?.page!}
            pageSize={meterReadingFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default MeterList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

