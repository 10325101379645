import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardFooter from '../layouts/CardFooter'
import CardHeader from '../layouts/CardHeader'
import { BackContent as FitBackContent, FrontContent as FitFrontContent } from './contents/FitIncomeFlipContent'
import { BackFooter as FitBackFooter, FrontFooter as FitFrontFooter } from './footer/FitIncomeFlipFooter'


const FitIncome = (props: Partial<IAdminDashboard>) => {

    if(props?.feedInTarrif === undefined) return <></>

    return (
        <div className="dashboard-layout--feedInTarrif">
            <Card feedInTarrif={props?.feedInTarrif} >
                <CardHeader
                    title="Feed-in Tariffs (FIT)"
                    iconName="Tarrif"
                />
                <CardContent variant='Flipper' title="Overall FIT Income" frontContent={FitFrontContent} backContent={FitBackContent} />
                <CardFooter variant='Flipper' frontFooter={FitFrontFooter} backFooter={FitBackFooter} />
            </Card>
        </div>
    )
}

export default FitIncome
