import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getGeneralFields, initUserSkill, validateUserSkill } from "./helper"
import { IUserSkill } from "@solarforschools/sfs-core/dist/user/types"
import { createUpdateUserSkill } from "../../../store/admin/userSkill/action"


export const openUserSkillModal = (props?: any) => {
  return new Promise<IUserSkill | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (userSkill?: IUserSkill) => {
      resolve(userSkill);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add Edit User Skill"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditUserSkillDialog
            {...props}
            onSave={(userSkill: IUserSkill | undefined) => callback(userSkill)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditUserSkillDialog = (props: UserSkillProps) => {
  const dispatch = useDispatch();

  const [userSkill, setUserSkill] = useState<IUserSkill>(_.cloneDeep(props.userSkill || initUserSkill as IUserSkill))
  const [errors, setErrors] = useState<any>({});


  const generalFields = useMemo(() => getGeneralFields({ userSkill }), [userSkill]);

  const handleOnSave = async () => {
    const errors = await validateUserSkill(userSkill);
    if (Object.keys(errors).length) {
      // const tabErrors = getTabErrors(errors);
      setErrors(errors);
      // setTabErrors(tabErrors);
      return;
    }
    await dispatch(createUpdateUserSkill(userSkill))
    props.onSave(userSkill)
  }

  const onPivotLinkClick = async (props: any) => { };


  const handleOnChangeInput = (key: string, value: any) => {

    let data: IUserSkill = _.cloneDeep(userSkill);
    switch (key) {
      default:
        setUserSkill(_.set({ ...data }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <DynamicForm
            fields={generalFields}
            data={userSkill}
            onChange={handleOnChangeInput}
            errors={errors}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface UserSkillProps {
  onCancel?: () => void;
  onSave: (userSkill: IUserSkill) => void;
  userSkill?: IUserSkill;
}

export default openUserSkillModal;
