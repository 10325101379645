import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../common/utils";

export const getArrOfObjColumns = (res: any) => {
  let columns: IColumn[] = [];
  if (res && res.length > 0) {

    const lengthOfEachObj = res.map((el: Record<string, any>) => Object.keys(el).length)
    const maxIndex = lengthOfEachObj.indexOf(Math.max(...lengthOfEachObj))

    const objKeys = Object.keys(res[maxIndex]);
    for (let i = 0; i < objKeys.length; i++) {
      let actualKey = objKeys[i];
      let lowercaseKey = objKeys[i].toLowerCase();
      let renderKey = lowercaseKey[0].toUpperCase() + lowercaseKey.substring(1);
      let temp = {
        key: actualKey,
        name: renderKey,
        fieldName: actualKey,
        minWidth: 150,
        maxWidth: 250,
        ...isSortable,
        onRender: (item: any) => {
          const value = item[actualKey];
          console.log(value, actualKey);
          return (
            <TooltipHost content={actualKey === "markerUrl" ? <img src={value} alt="marker" style={{ height: "32px", width: "32px" }} /> : value} closeDelay={500}>
              {actualKey === "markerUrl" ? (
                <img src={value} alt="marker" style={{ height: "32px", width: "32px" }} />
              ) : (
                value
              )}
            </TooltipHost>
          );
        },
      };
      columns.push(temp);
    }
  }

  return columns;
};

export const getObjectColumns = (): IColumn[] => {
  return [
    {
      key: "key",
      name: "Key",
      fieldName: "key",
      minWidth: 75,
      maxWidth: 250,
      ...isSortable,
      onRender: (item) => {
        const value = item["key"];
        return (
          <TooltipHost content={value} closeDelay={500}>
            {value}
          </TooltipHost>
        );
      },
    },
    {
      key: "value",
      name: "Value",
      fieldName: "value",
      minWidth: 75,
      maxWidth: 250,
      ...isSortable,
      onRender: (item) => {
        const value = item["value"];
        return (
          <TooltipHost content={value} closeDelay={500}>
            {value}
          </TooltipHost>
        );
      },
    },
  ];
};

export const getArrayColumns = (): IColumn[] => {
  return [
    {
      key: "value1",
      name: "Value",
      fieldName: "value1",
      minWidth: 75,
      maxWidth: 250,
      ...isSortable,
      onRender: (item) => {
        const value = item["value1"];
        return (
          <TooltipHost content={value} closeDelay={500}>
            {value}
          </TooltipHost>
        );
      },
    },
  ];
};
