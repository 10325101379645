import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../../store';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { getPotfolioColumns } from './Portfoliohelper';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { IPortfolioState } from '../../../../store/admin/funder/portfolio/reducer';
import { getPortfolioes, setPortfolioFilter } from '../../../../store/admin/funder/portfolio/action';
import Pagination from '../../common/Pagination';
import { IPortfolioFilter } from './types';

const PortfolioList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { portfolioes, totalCount, portfolioFilter }: IPortfolioState = useSelector<RootState, IPortfolioState>((state: RootState) => state.web.portfolio)

  const headerRow = getPotfolioColumns({
    actions: [
     
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...portfolioFilter, sortField: field, sortOrder: order };
    dispatch(setPortfolioFilter(params));
    dispatch(getPortfolioes(params))
  }
  const loadNextPage = (p: any) => {
    dispatch(setPortfolioFilter({ ...portfolioFilter, ...p }))
    dispatch(getPortfolioes())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
        <div className="data-list-container">
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsListWapper
              items={portfolioes}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              onRenderDetailsHeader={DetailListStickyHeader}
              compact={true}
              checkboxVisibility={CheckboxVisibility.hidden}
              canLoadMore={portfolioes.length < totalCount}
              // onRequestLoadMore={loadNextPage}
              onSort={(field, order) => handleOnSort(field, order)}
            />
          </ScrollablePane>
        </div>
        <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={portfolioFilter?.page!}
            pageSize={portfolioFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default PortfolioList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

