import { toast } from "react-toastify"
import { IField } from "../../dynamic-form/DynamicForm"
import { requiredMessage } from "../common/common"
import { StringMap } from "../common/types"
import { IAddLead } from "./type"

export const initLead = (data: any, type = 'School') => {

}
export const isNewContact = [{
    key: 'isNewContact',
    label: 'Create New Contact',
    type: 'checkbox',
    className: 'ms-Grid-col ms-lg4 margin-top-sm padding-top-lg',
}]

export const getContactFields = (options: StringMap): IField[] => {
    return [
        {
            key: 'name',
            label: 'Name',
            placeholder: "Enter Full Name",
            type: 'string',
            extras: { required: options.payload.isNewContact ? true : false },
            className: 'ms-Grid-col ms-lg4',
        },
        {
            key: 'email',
            label: 'Email',
            type: 'string',
            placeholder: "Enter Email",
            extras: { required: options.payload.isNewContact ? true : false },
            className: 'ms-Grid-col ms-lg6',
        },
        {
            key: 'jobTitle',
            label: 'Job Title',
            type: 'string',
            placeholder: "Enter Job Title",
            className: 'ms-Grid-col ms-lg4',
        },
        {
            key: 'contactType',
            label: 'Contact Type',
            type: 'dropdown',
            placeholder: "Enter Job Title",
            extras: { required: options.payload.isNewContact ? true : false },
            options: options.contactTypes,
            className: 'ms-Grid-col ms-lg3',
        },
        // {
        //     key: 'contactLabel',
        //     label: 'Contact Label',
        //     type: 'dropdown',
        //     placeholder: "Enter Job Title",
        //     extras: { required: options.payload.isNewContact ? true : false },
        //     options: options.contactLabels,
        //     className: 'ms-Grid-col ms-lg3',
        // },
    ]
}

export const getLeadFields = (options: StringMap): IField[] => {
    return [
        {
            key: 'title',
            label: 'Title',
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Title",
            className: 'ms-Grid-col ms-lg4',
        },
        // {
        //     key: 'value',
        //     label: 'Value(GBP)',
        //     type: 'number',
        //     placeholder: "Enter Value",
        //     step: "0.01",
        //     extras: { required: true },
        //     className: 'ms-Grid-col ms-lg2',
        // },

        {
            key: 'labelIds',
            label: 'Labels',
            type: 'dropdown',
            options: options.labels,
            extras: { required: true },
            className: 'ms-Grid-col ms-lg3',
        },
        // {
        //     key: 'source',
        //     label: 'Source',
        //     type: 'dropdown',
        //     extras: { required: true },
        //     options: options.sourses,
        //     className: 'ms-Grid-col ms-lg3',
        // },
    ]
}
export const validateAddLead = (data: IAddLead) => {
    let errors: StringMap = {};
    const { source, labelIds, title, contactType, jobTitle, email, isNewContact, name, contact } = data || {}
    if (isNewContact) {
        const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !mailformat.test(email)) errors['email' as string] = 'Invalid Email'
        if (!jobTitle) errors['jobTitle'] = requiredMessage
        if (!contactType) errors['contactType'] = requiredMessage
        if (!name) errors['name'] = requiredMessage
    } else {
        if (!contact?.key) {
            errors['contact'] = requiredMessage
            toast.error('Invalid contact')
        }
    }
    if (!title) errors['title'] = requiredMessage;
    if (!labelIds) errors['labelIds'] = requiredMessage;
    // if (!source) errors['source'] = requiredMessage;
   return errors
}