import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { ConfirmDialog } from '../common/ConfirmDialog';
import DetailsListWapper from '../../common/DetailsListWapper';
import { getUserColumns } from './helper';
import openUserModal from './userDialog';
import { IUser } from '@solarforschools/sfs-core';
import { deleteUser, getUsers, setUserFilter } from '../../../store/admin/users/action';
import { IUserState } from '../../../store/admin/users/reducer';
import Pagination from '../common/Pagination';
import { getUserJWTToken } from '../../../store/client/user';
import { isLoading } from '../../../store/admin/action';
import { tpHost } from '../../../store/config';

const YieldsList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { users, totalCount, userFilter }: IUserState = useSelector<RootState, IUserState>((state: RootState) => state.web.user)

  const handleOnDelete = async (data: IUser) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete User",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete`,
      },
    });
    if (confirm) dispatch(deleteUser(data));
  };

  const handleOnEdit = async (data: IUser) => {
    await openUserModal({ user: data })
  };

  const handleOpenTpLink = async ({ _id }: IUser) => {
    if (_id) {
      dispatch(isLoading(true))
      const { token } = await getUserJWTToken(_id.toString());
      dispatch(isLoading(false))
      if(token) {
        const url = `${tpHost}/?token=${token}`
        const newTab = window.open(url, '_blank');
            if (newTab) {
                newTab.focus();
            }
      }
    }

  }

  const headerRow = getUserColumns({
    actions: [
      {
        icon: 'FileSymLink',
        onClick: handleOpenTpLink,
      },
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        onClick: handleOnDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...userFilter, sortField: field, sortOrder: order };
    dispatch(setUserFilter(params));
    dispatch(getUsers(params))
  }
  const loadNextPage = (p: any) => {
    dispatch(setUserFilter({ ...userFilter, ...p }))
    dispatch(getUsers())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={users}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={users.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={userFilter?.page!}
            pageSize={userFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default YieldsList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

