import { useEffect, useState } from 'react';
import Moment from 'moment'
import { ActionButton, CheckboxVisibility, DetailsList, IconButton, ScrollablePane, ScrollbarVisibility, TooltipHost } from '@fluentui/react';
import { SelectionMode, IColumn, Selection } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { isSortable, SetDetailListHeaderSortDirection } from '../common/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPlus, faVoicemail, faTrash, faYenSign, faEye, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { IInvoice } from '../../../store/admin/invoice/reducer';
import { deleteInovice, downloadInvoice, exportBillingInvoices, fetchInvoices, generateInvoice, setInvoiceFilter } from '../../../store/admin/invoice/action';

import { ConfirmDialog } from '../common/ConfirmDialog';
import DetailsListWapper from '../../common/DetailsListWapper';
import { OpenAddInvoiceModal } from './AddInvoiceDialog';
import { IInvoiceDocument, IInvoiceFilter } from './types';
import { isLoading } from '../../../store/admin/action';
import { getInvoices, getInvoiceEmailPreview, getOneInvoce, saveInvoiceEmailAsDraft } from '../../../store/client/invoice';
import { toast } from 'react-toastify';
import { getInvoiceColumns } from './helper';
import { OpenEmailPreviewModal } from '../common/EmailPreviewDialog';
import Pagination from '../common/Pagination';
import { RenderListRow } from '../common/RenderListRow';

const InvoiceList = (params:any) => {
  const dispatch = useDispatch()
  const { invoices, totalCount, invoiceFilter }: IInvoice = useSelector<RootState, IInvoice>((state: RootState) => state.web.invoice)
  const [selectedItems, setSelectedItems] = useState<Array<any> | []>([])
  const [selection] = useState(new Selection({
    onSelectionChanged: () => {
      const data = selection.getSelection()
      setSelectedItems(data.filter(Boolean));
    }
  }));

  useEffect(() => {
    const preSelected: any = (selectedItems);
    if (preSelected?.length > 0) {
      selection.setChangeEvents(false);
      preSelected.forEach((item: any) => {
        const index = invoices.findIndex((s: any) => s?._id.toString() === item?._id.toString())
        if (index >= 0) {
          selection.setIndexSelected(index, true, true)
        }
      });
      selection.setChangeEvents(true);
    }
    params.setSelectedInvoices(selectedItems)
  }, [invoices, selectedItems]);

  const openEmailPreview = async (param: any) => {
    try {
      dispatch(isLoading(true))
      const { data = [] } = { data: [await getOneInvoce(param._id)] }
      if (!data.length) {
        dispatch(isLoading(false))
        return
      }
      const { html }: any = await getInvoiceEmailPreview({ invoiceId: data[0]._id })
      dispatch(isLoading(false))
      await OpenEmailPreviewModal({
        params: {
          data: [...data],
          title: 'Invoice Email Preview',
          btnTitle: 'Save Draft',
          html,
          sendAction: saveInvoiceEmailAsDraft
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }

  const handleInvoiceDownload = async (item: IInvoiceDocument) => {
    dispatch(downloadInvoice(item!))
  }

  const handleGenerateInvoice = async (item: IInvoiceDocument) => {
    dispatch(generateInvoice(item!))
  }

  const handleDelete = async (item: IInvoiceDocument) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete inovice",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${item.number}`,
      },
    });
    if (confirm) handleOnDelete(item._id!);
  }

  const headerRow: IColumn[] = getInvoiceColumns({
    actions: [
      {
        title: 'Email',
        icon: faEnvelope,
        name: 'email',
        onClick: openEmailPreview,
      },
      {
        title: 'View Invoice',
        icon: faEye,
        name: 'view-invoice',
        onClick: handleInvoiceDownload,
      },
      {
        title: 'Generate Invoice',
        icon: faPlus,
        onClick: handleGenerateInvoice,
      },
      {
        icon: faTrash,
        title: 'Delete Invoice',
        onClick: handleDelete
      }
    ]
  })
  const [columns, setColumns] = useState<IColumn[]>(headerRow)

  const handleOnDelete = (invoiceId: string) => {
    dispatch(deleteInovice(invoiceId));
  };

  const loadNextPage = (p: IInvoiceFilter) => {
    dispatch(setInvoiceFilter({ ...invoiceFilter, ...p }))
    dispatch(fetchInvoices())
  }

  const handleOnHeaderClick = (column?: IColumn): void => {
    if (column?.key === 'action') return
    const index = columns.findIndex(c => c.key === column?.key)
    const newColumns = SetDetailListHeaderSortDirection(column, columns)
    setColumns(newColumns)
    const params = { ...invoiceFilter, sortField: newColumns[index].key, sortOrder: newColumns[index].isSortedDescending ? 'desc' : 'asc' };
    dispatch(setInvoiceFilter(params));
    dispatch(fetchInvoices(params))
    // handleOnSort({ key: newColumns[index].key, text: newColumns[index].isSortedDescending ? 'desc' : 'asc' })
  };
  if (selectedItems?.length > 0) { }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                items={invoices}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                selection={selection}
                checkboxVisibility={CheckboxVisibility.always}
                onRenderRow={(props) => RenderListRow(props!)}
              // onRenderMissingItem={onRenderMissingItem}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={invoiceFilter?.page!}
            pageSize={invoiceFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
}

export default InvoiceList;

