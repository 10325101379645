import { ThunkDispatch } from "redux-thunk";

import { RootState } from "../../../index";
import { isLoading } from "../../action";
import { downloadFileFromStream } from "../../../../components/admin/common/utils";
import { IGSSReportFilter } from "../../../../components/admin/reporting/gssReporting/types";
import { getGSSReportDataApi, getGSSReportExportApi } from "../../../client/reporting/gssReport";
import { AnyAction } from "redux";
import { host } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { getDatafeedDataApi } from "../../../client/reporting/datafeed";

// Action Creators
export const setDatafeedLiveSchools = (schools: any) => {
  return { type: "REPORT/SET_DATAFEED_LIVESCHOOLS", schools };
};

export const setDatafeedFilter = (
  datafeedFilter: any
) => {
  return { type: "REPORT/SET_DATFEED_FILTER", datafeedFilter };
};

export const setDatafeedData = (
  data: any[]
) => {
  return { type: "REPORT/SET_DATA_IN_DATAFEED", data };
};


export const fetchDatefeedLiveSchools = (isShowLoader: boolean = true) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.get(`${host}/reports/school/live`);
        if (res.status === 200) {
          const liveSchools = [{ key: "all", text: "Select All Schools", country: 'all', trust: "all" }];
          for (const s of res.data.schools) {
            liveSchools.push({ key: s.slug, text: s.name, country: s.country, trust: s.meta.trust });
          }
          
          dispatch(setDatafeedLiveSchools(liveSchools));
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};
export const fetchDataFeedData = (isShowLoader: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(setDatafeedData([]))
      if (isShowLoader) dispatch(isLoading(true));
      const { datafeedFilter } = getState().web.datafeed;
      const params = { ...datafeedFilter };
      const { data } = await getDatafeedDataApi(params);
      await dispatch(setDatafeedData(data));
      if (isShowLoader) dispatch(isLoading(false));
      return data
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

// export const getDatafeedData = () =>
//   async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
//     try {
//       dispatch(isLoading(true));
//       const { gssReportFilter } = getState().web.datafeed;
//       const params = { ...gssReportFilter };
//       const data = await getGSSReportExportApi(params);
//       downloadFileFromStream({ data, filename: "GSS-report", type: 'xlsx' })
//       dispatch(isLoading(false));
//     } catch (e: any) {
//       console.log(e);
//       dispatch(isLoading(false));
//     }
//   };