import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchEnvConfig } from "../../../store/admin/envConfig/actions";
import Loader from "../common/Loader";
import EnvConfigLists from "./EnvConfigLists";

const EnvConfig = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEnvConfig());
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Environment Config Settings</h1>
      </header>

      <EnvConfigLists />
    </div>
  );
};

export default EnvConfig;
