import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { IInvoiceFilter } from "../../components/admin/invoice/types";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";
import { IInvoiceAccountReportFilter } from "../../components/admin/reporting/invoiceAccountReporting/types";

let GetInvoiceAPiAbortController: AbortController

export const getInvoices = (query: IInvoiceFilter|IInvoiceAccountReportFilter): Promise<any> => {
  if (GetInvoiceAPiAbortController) GetInvoiceAPiAbortController.abort()
  GetInvoiceAPiAbortController = new AbortController()
  return axios.get(`${host}/billing/invoice?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetInvoiceAPiAbortController.signal }).then(res => res.data)
}
export const getOneInvoce = (id: any): Promise<any> => {
  return axios.get(`${host}/billing/invoice/get-one/${id}`).then(res => res.data)
}

export const getInvoiceEmailPreview = (query: ParsedUrlQueryInput): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/billing/email-preview?${queryStr}`).then(res => res.data)
}

export const saveInvoiceEmailAsDraft = (query: ParsedUrlQueryInput): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.post(`${host}/billing/invoice/draftMail?${queryStr}`).then(res => res.data)
}

export const exportBillingInvoiceApi = (query: IInvoiceFilter): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/billing/invoice-export/download?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}
export const getInvoicesCyclesApi = (query: any): Promise<any> => {
  return axios.get(`${host}/billing/cycles?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}
