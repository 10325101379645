import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import _ from "lodash"
import { useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import ModalDialog from "./ModalDialog"
import { isLoading } from '../../../store/admin/action';
import { createProjectFolderApi, getCustomerFolderAPi, getListItemAPi } from "../../../store/client/projectFolder"
import { toast } from "react-toastify"
import { IProjectFolder, IProjectFolderLink } from "@solarforschools/sfs-core/dist/types"
import { SP_CLIENT_ID } from "../../../utils/util"
import { projectNameChange } from "../project/helper"


export const OpenCreateProjectFolderModal = (props?: any) => {
    return new Promise<IProjectFolderLink | undefined>((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (data?: IProjectFolderLink) => {
            resolve(data);
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={`Create ${projectNameChange['Project']} Folder`}
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-xs"}
                >
                    <CreateProjectFolder
                        {...props}
                        onSave={(data: any | undefined) => callback(data)}
                        onCancel={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

const CreateProjectFolder = (props: IProjectFolderProps) => {
    const dispatch = useDispatch();
    const [pf, setPf] = useState<IProjectFolder | any>({ ...props.folder } as IProjectFolder)

    const handleOnSave = async () => {
        try {
            dispatch(isLoading(true))
            const folderLik: IProjectFolderLink = await createProjectFolderApi(pf)
            props.onSave(folderLik)
            dispatch(isLoading(false))
        } catch (error) {
            console.log(error)
            dispatch(isLoading(false))
        }
    }
    // const fetchMyAPI = useCallback(async () => {
    //     try {
    //         dispatch(isLoading(true))
    //         const customerFolder: any = await getCustomerFolderAPi({ region: props.folder?.region || 'GB' })
    //         if (customerFolder) {
    //             setPf({ ...pf })
    //         }
    //         dispatch(isLoading(false))

    //     } catch (error) {
    //         dispatch(isLoading(false))
    //         console.log(error)
    //         toast.error('Something went wrong')
    //         props.onCancel && props.onCancel()
    //     }
    // }, [props.folder]) // if userId changes, useEffect will run again
    const fetchMyAPI = async () => {
        try {
            dispatch(isLoading(true))
            // const customerFolder: any = await getCustomerFolderAPi({ region: props.folder?.region || 'GB' })
            dispatch(isLoading(false))
            const odOptions = {
                clientId: SP_CLIENT_ID,
                action: 'query',
                viewType: 'folders',
                multiSelect: false,
                advanced: {
                    endpointHint: `https://solarforschools.sharepoint.com/sites/ukteam/Shared%20Documents/Forms/AllItems.aspx`,
                },
                success: async function (files: { value: string | any[]; }) {
                    if (files.value && files.value.length > 0) {
                        try {
                            dispatch(isLoading(true))
                            const data: any = {
                                driveItemId: files.value[0].id,
                                driveId: files.value[0].parentReference?.driveId
                            }
                            const listItem: any = await getListItemAPi({ driveItemId: data.driveItemId, driveId: data.driveId })
                            if (listItem && listItem.fields?.id) {
                                setPf({ ...pf, webUrl: new URL(listItem.webUrl).pathname })
                            }
                            dispatch(isLoading(false))
                        } catch (error) {
                            console.log(error)
                            toast.error(JSON.stringify(error))
                            dispatch(isLoading(false))
                        }
                    }
                },
                cancel: function () {
                    props.onCancel && props.onCancel()
                },
                error: function (error: any) {
                    console.log(error)
                    toast.error('Something went wrong')
                    props.onCancel && props.onCancel()
                }
            }
            window.OneDrive.open(odOptions);
        } catch (error) {
            dispatch(isLoading(false))
            console.log(error)
            toast.error('Something went wrong')
            props.onCancel && props.onCancel()
        }
    }
    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <PrimaryButton className="margin-top-md" style={{ marginLeft: 0 }} text="Select Folder Path" onClick={fetchMyAPI} allowDisabledFocus disabled={false} checked={false} />
                </div>

                <div className="ms-Grid-row">
                    <TextField
                        label="Folder Path"
                        placeholder="Folder Path"
                        value={pf?.webUrl!}
                        disabled={true}
                        ariaLabel={pf.webUrl}
                        className="ms-Grid-col ms-lg12" />
                </div>

                <div className="ms-Grid-row">
                    <TextField
                        label="Folder Name"
                        placeholder="Folder Name"
                        value={pf?.name!}
                        onChange={(e, value) => setPf({ ...pf, name: value || '' })}
                        className="ms-Grid-col ms-lg12" />
                </div>

            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center" className="margin-top-md">
                    <Stack.Item>
                        {pf.name.trim().length > 0 &&
                            <PrimaryButton
                                text="Save"
                                onClick={handleOnSave}
                                className="btn-primary"
                            />}
                        <DefaultButton onClick={props.onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface IProjectFolderProps {
    onCancel?: () => void;
    onSave: (data?: any) => void;
    folder?: IProjectFolder;
}

export default OpenCreateProjectFolderModal;
