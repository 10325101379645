import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import GSSReportFilter from "./GSSReportFilter";
import GSSReportList from "./GSSReportList";
import { fetchGSSReportData } from "../../../../store/admin/reporting/gssReport/action";


const GssReportScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(fetchGSSReportData());
    })();
  }, []);



  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'relative' }}>
      <Loader />
      <header className="table-header">
        <h1>CBS & GSS Report</h1>
        <GSSReportFilter />
      </header>
      <GSSReportList/>
    </div>
  );
};

export default GssReportScreen;
