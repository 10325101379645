import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../common/utils";
import { IField } from "../../dynamic-form/DynamicForm";
import moment from "moment";
import { StringMap } from "../common/types";
import { get } from "lodash";
import { CSSProperties } from "react";
import { IModifiedRegionCapex } from "@solarforschools/sfs-core/dist/settings/types";
import { IResArrOfObj, ISofsExpenses } from "./types";


export const invoiceDialogLayout: CSSProperties = {
    display: "flex",
    marginTop: "1rem",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
}

const defaultBoldContent = ["System Costs", "Partner Fee", "Education Setup", "SfS Development Fee", "Total Capex", "Fundraising Fee", "Contribution / Grant", "Total Investment", "Total OPEX"]


//! Custom Tabel Header
// export const RenderHeader = () => {
//     const fontStyles = { root: { fontWeight: "600" } }

//     return (
//         <div>
//             <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", backgroundColor: "white", position: "sticky", top: "0" }}>
//                 <div style={{ display: "flex" }}>
//                     <div style={{ width: "33%" }}></div>
//                     <div className="table-header2" style={{ width: "66%", textAlign: "center", paddingTop: "0.5rem" }}>
//                         <Text variant="medium" styles={fontStyles} >Budget</Text>
//                     </div>
//                 </div>
//                 <div style={{ display: "flex", marginBottom: "0.5rem" }}>
//                     <div style={{ width: "33%", textAlign: "center", cursor: 'pointer' }}>
//                         <Text variant="medium" styles={fontStyles} >CAPEX</Text>
//                     </div>
//                     <div style={{ width: "33%", textAlign: "center", cursor: 'pointer' }}>
//                         <Text variant="medium" styles={fontStyles} >Per KWP</Text>
//                     </div>
//                     <div style={{ width: "33%", textAlign: "center", cursor: 'pointer' }}>
//                         <Text variant="medium" styles={fontStyles} >Total</Text>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     )
// }

/**
 * @param item (a row in opex table)
 * @returns boolean
 */
const checkIsBold = (item: any) => {
    // if headId contains uniqueId then bold it
    // uniqueId - unique for each row
    // headId - only head and head with empty item 
    // note: defaultBoldContent have headName to be bolded

    const value = item?.name
    const underHead = item["underHead"]
    let isBold = false
    if (underHead) {
        const uniqueId = item["uniqueId"]
        const underHead = item["underHead"]
        if (underHead?.includes(uniqueId)) isBold = true
        else isBold = false
    } else {
        if (defaultBoldContent.includes(value)) isBold = true
    }

    return isBold
}

export const getData = (startColumnName: string): IColumn[] => {

    return [
        {
            key: "name",
            name: startColumnName,
            fieldName: "name",
            minWidth: 200,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                const value = item?.name || ""
                const isBold = checkIsBold(item)
                return (
                    <span style={isBold ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        },
        {
            key: "perKWP",
            name: "per kWp",
            fieldName: "perKWP",
            minWidth: 200,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                let value;
                if (typeof item?.perKWP === "number")
                    value = item["perKWP"].toFixed(2);
                else value = item["perKWP"]

                const isBold = checkIsBold(item)

                return (
                    <span style={isBold ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        },

        {
            key: "total",
            name: "Budget Total",
            fieldName: "total",
            minWidth: 100,
            maxWidth: 100,
            ...isSortable,
            onRender: (item) => {
                let value;
                if (typeof item?.total === "number") {
                    value = item["total"].toFixed(2);
                } else value = item["total"]

                const isBold = checkIsBold(item)

                return (
                    <span style={isBold ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        }

    ]
}

export const getActual = (): IColumn[] => {
    return [
        {
            key: "actualPerKWP",
            name: "Actual Total",
            fieldName: "Actual Total",
            minWidth: 100,
            maxWidth: 100,
            ...isSortable,
            className: "actual-column",
            onRender: (item) => {
                let value;
                if (typeof item?.actualPerKWP === "number") {
                    value = item["actualPerKWP"].toFixed(2);
                } else value = item["actualPerKWP"]

                const isBold = checkIsBold(item)

                return (
                    <span style={isBold ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        },
        {
            key: "totalBudget",
            name: "Variance",
            fieldName: "total",
            minWidth: 150,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => {
                let value, isNegative = false, contentStyle: CSSProperties = {};
                if (typeof item?.totalBudget === "number") {
                    if (item?.totalBudget < 0) {
                        isNegative = true;
                    } else isNegative = false
                    value = item["totalBudget"].toFixed(2);
                }
                else value = item["totalBudget"]

                const isBold = checkIsBold(item)
                if (isBold) {
                    contentStyle["fontWeight"] = 600
                    if (isNegative) {
                        contentStyle["color"] = "red"
                    }
                } else {
                    if (isNegative) {
                        contentStyle["color"] = "red"
                    }
                }


                return (
                    <span style={contentStyle}>
                        {value}
                    </span>
                );
            },
        },

        {
            key: "paymentBy",
            name: "Payment by",
            fieldName: "Payment by",
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            onRender: (item) => {
                let value
                if (item.name === FUND_FEE) {
                    value = "SOFS"
                } else {
                    value = item?.paymentBy
                }
                const isBold = checkIsBold(item)

                return (
                    <span style={isBold ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        },
        {
            key: "paymentDate",
            name: "Payment Date",
            fieldName: "Payment Date",
            minWidth: 150,
            maxWidth: 150,
            ...isSortable,
            onRender: (item: any) => {
                let value;
                let name = item.name
                let id = item.uniqueId
                const paymentId = `${name}-${id}-p`
                if (item[paymentId] === undefined) value = "-"
                else value = moment(item[paymentId]).format("DD/MM/YYYY")
                const isBold = checkIsBold(item)

                return (
                    <span style={isBold ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        }

    ]
}

export const getOpexActuals = (boldThisContent: string[] = []): IColumn[] => {
    return [
        {
            key: "actualTotal",
            name: "Actual Total",
            fieldName: "Actual Total",
            minWidth: 100,
            maxWidth: 100,
            ...isSortable,
            className: "actual-column",

            onRender: (item) => {
                let value;
                if (typeof item?.actualTotal === "number") {
                    value = item["actualTotal"].toFixed(2);
                } else value = item["actualTotal"]


                return (
                    <span style={boldThisContent.includes(item.name) ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        },
        {
            key: "overallTotal",
            name: "Variance",
            fieldName: "Total",
            minWidth: 150,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => {
                let value;
                if (typeof item?.overallTotal === "number") {
                    value = item["overallTotal"].toFixed(2);
                } else value = item["overallTotal"]


                return (
                    <span style={boldThisContent.includes(item.name) ? { fontWeight: 600 } : {}}>
                        {value}
                    </span>
                );
            },
        }
    ]
}


export const defaultOpexActuals = [
    { insurance: "-" },
    { cleaningFee: "-" },
    { exportMetering: "-" },
    { businessRates: "-" },
    { inverterReserves: "-" },
    { dismantlingReserves: "-" },
    { assetManagementCosts: "-" },
    { fundManagementCosts: "-" },
    { education: "-" },
    { internationalCommunity: "-" },
]

export const getOpexGeneralFields: IField[] = [
    {
        label: "Insurance",
        key: "insurance",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Insurance",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Cleaning Fee",
        key: "cleaningFee",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Cleaning Fee",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Export Metering",
        key: "exportMetering",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Export Metering",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Business Rates",
        key: "businessRates",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Business Rates",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Inverter Reserves",
        key: "inverterReserves",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Inverter Reserves",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Dismantling Reserves",
        key: "dismantlingReserves",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Dismantling Reserves",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Asset Management Costs",
        key: "assetManagementCosts",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Asset Management Costs",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Fund Management Costs",
        key: "fundManagementCosts",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Fund Management Costs",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Education",
        key: "education",
        type: "number",
        extras: { required: true },
        placeholder: "Enter Education",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "International Community",
        key: "internationalCommunity",
        type: "number",
        extras: { required: true },
        placeholder: "Enter International Community",
        className: 'ms-Grid-col  ms-lg3'
    },
]


export const extractNameAndId = (str: any) => {
    let nameWithoutId: string, uniqueId: string
    const slashIndex = str.lastIndexOf("//")
    nameWithoutId = str.substr(0, slashIndex)
    uniqueId = str.substr(slashIndex + 2)

    return [nameWithoutId, uniqueId]
}

export const PARTNER_FEE = "Partner Fee"
export const EDU_SET = "Education Setup"
export const SFS_DEV_FEE = "SfS Development Fee"
export const FUND_FEE = "Fundraising Fee"
export const CONT_GRANT = "Contribution / Grant"
export const TOTAL_INV = "Total Investment"
export const SYS_COST = "System Costs"
export const TOT_CAPEX = "Total Capex"

export const REQUIRED_MSG = "Cannot be empty";
export const INVOICE_DATA = {
    "Invoice Date": new Date(),
    "Invoice Number": "",
    Reference: "",
    "Vat Number": "232066930",
    "Due Date": new Date(),
}
export const VAT = 20 / 100;

export const getInvoiceGeneralFields: IField[] = [
    {
        label: "Invoice Date",
        key: "Invoice Date",
        type: "date",
        extras: { required: true },
        placeholder: "Pick invoice date",
        className: 'ms-Grid-col  ms-lg2'
    },
    {
        label: "Invoice Number",
        key: "Invoice Number",
        type: "string",
        extras: { required: true },
        placeholder: "Enter invoice number",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Reference",
        key: "Reference",
        type: "string",
        extras: { required: true },
        placeholder: "Enter a reference",
        className: 'ms-Grid-col  ms-lg2'
    },
    {
        label: "VAT Number",
        key: "Vat Number",
        type: "string",
        extras: { required: true },
        placeholder: "Enter a vat number",
        className: 'ms-Grid-col  ms-lg3'
    },
    {
        label: "Due Date",
        key: "Due Date",
        type: "date",
        extras: { required: true },
        placeholder: "Pick Due Date",
        className: 'ms-Grid-col  ms-lg2'
    },
]


export const validateInvoiceData = (invoiceData: any) => {

    const errors: StringMap = {};
    const generalFields = [...getInvoiceGeneralFields]

    generalFields.forEach((field) => {
        let value = get(invoiceData, field.key)
        const label = field.label
        if (field.type === "string") value = value.trim()

        if (!value || value.length === 0) {
            errors[field.key] = label + " " + REQUIRED_MSG
        }
    })

    return errors
}


export const getSofsExpenseColumn = (): IColumn[] => {
    return [
        {
            key: "name",
            name: "Description",
            fieldName: "name",
            minWidth: 75,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                const value = item["name"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "actualTotal",
            name: "Amount GBP",
            fieldName: "Amount GBP",
            minWidth: 45,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => {
                const value = item["actualTotal"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
    ];
};


export const getUserInvoiceColummns = (): IColumn[] => {
    return [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 75,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                const value = item["name"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "value",
            name: "Value",
            fieldName: "Value",
            minWidth: 45,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => {

                const name = item["name"]
                let value
                if (name === "Invoice Date" || name === "Due Date") {
                    let tempValue = item["value"];
                    value = moment(tempValue).format("DD MMM YYYY")
                } else {
                    value = item["value"]
                }

                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
    ];
};


export const getSofsExpensesColumn = (): IColumn[] => {
    return [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                const value = item["name"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "quantity",
            name: "Quantity",
            fieldName: "Quantity",
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                let value = item["quantity"]
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "actualTotal",
            name: "Unit Price",
            fieldName: "Unit Price",
            minWidth: 100,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => {

                let value = item["actualTotal"];
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },

        {
            key: "vat",
            name: "VAT",
            fieldName: "vat",
            minWidth: 100,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => {

                let value = item["vat"]

                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        },
        {
            key: "amount",
            name: "Amount GBP",
            fieldName: "amount",
            minWidth: 100,
            maxWidth: 250,
            ...isSortable,
            onRender: (item) => {

                let value = item["amount"]
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {value}
                    </TooltipHost>
                );
            },
        }
    ];
};


export const getCBSColumns = (): IColumn[] => {
    return [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                const value = item["name"];
                const nameStyle: CSSProperties = { fontWeight: "600" as any }
                return (
                    <TooltipHost content={value} closeDelay={500}>
                        <span style={nameStyle}>
                            {value}
                        </span>
                    </TooltipHost>
                );
            },
        },
        {
            key: "actualPerKWP",
            name: "Actual Per kWp",
            fieldName: "actualPerKWP",
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => {
                let value = item["actualPerKWP"]

                return (
                    <TooltipHost content={value} closeDelay={500}>
                        {
                            typeof value === "number" ? addZeroes(value) : addZeroes(0)
                        }
                    </TooltipHost>
                );
            },
        },
    ];
};



export function addZeroes(num: number) {

    return new Intl.NumberFormat('en-gb', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
        .format(num);

}

export function calculateVAT(vat: number) {
    return Math.floor(vat * 100) + "%"
}




// Re Structure CAPEX Actuals to render in Capex Table
export function restructureThis(values: Record<string, string | boolean | number>, capexRender: IResArrOfObj[], processThis: Record<string, string[]>) {

    //! Variables
    // processThis -> Have capex head, items. Based on this used to build addition
    // values -> have actuals data

    const finalResult: Omit<IModifiedRegionCapex, "isAcutals">[] | [{}] = [{}] //-> Have Final Result to store

    // Delete Last Key Value pair from processThis -> why? sfsDev dont need calculation
    const processThisObj = { ...processThis }
    const processThisObjkeys = Object.keys(processThisObj)
    const deletedThisLastKey = processThisObjkeys[processThisObjkeys.length - 1]
    delete processThisObj[deletedThisLastKey]

    // loop through object
    for (const key in processThisObj) {
        const value = processThisObj[key]
        let summation: number | string = 0;
        let countEmpty = 0, uniqueId: string, nameWithoutId: string, modifiedName;

        // values -> if array len > 1 then loop through it and get summation else take that value
        if (Array.isArray(value) && value.length > 1) {

            for (const val of value) {
                [nameWithoutId, uniqueId] = extractNameAndId(val)
                modifiedName = nameWithoutId + "?%%?" + uniqueId

                if (values[modifiedName] === "" || values[modifiedName] === "-") countEmpty++;
                if (values[modifiedName]) summation = summation + Number((typeof values[modifiedName] === "number") ? values[modifiedName] : 0)
            }

            [nameWithoutId, uniqueId] = extractNameAndId(value[0])

            finalResult.push({ uniqueId: uniqueId, name: nameWithoutId, perKWP: ((countEmpty === (value.length - 1)) ? "-" : summation), paymentBy: (values[`id-${uniqueId}`] === undefined ? "-" : (values[`id-${uniqueId}`] ? "CBS" : "SOFS")) })

            // Items under head so have paymentDate
            for (let val = 1; val < value.length; val++) {
                [nameWithoutId, uniqueId] = extractNameAndId(value[val])
                modifiedName = nameWithoutId + "?%%?" + uniqueId

                finalResult.push({ uniqueId: uniqueId, name: nameWithoutId, perKWP: values[modifiedName] === "" ? "-" : values[modifiedName], paymentBy: (values[`id-${uniqueId}`] === undefined ? "-" : (values[`id-${uniqueId}`] ? "CBS" : "SOFS")), [`${nameWithoutId}-${uniqueId}-p`]: new Date(values[`${nameWithoutId}-${uniqueId}-p`] as string) }) // perKWP have the values
            }
        } else { //-> have single actuals so have paymentDate
            [nameWithoutId, uniqueId] = extractNameAndId(value[0])
            modifiedName = nameWithoutId + "?%%?" + uniqueId

            summation = values[modifiedName] as string | number
            finalResult.push({ uniqueId: uniqueId, name: nameWithoutId, perKWP: (summation === "" ? "-" : summation), paymentBy: (values[`id-${uniqueId}`] === undefined ? "-" : (values[`id-${uniqueId}`] ? "CBS" : "SOFS")), [`${nameWithoutId}-${uniqueId}-p`]: new Date(values[`${nameWithoutId}-${uniqueId}-p`] as string) })
        }
        summation = 0; // Reset the Summation
        countEmpty = 0; // Reset the countEmpty
    }

    // Why hardcoded ? -> theses values are made compulsory while getting capex in regionConfig
    // Note: System Cost, Total Capex, Total Investments don't have paymentDate other than below have paymentDate
    //! Actuals System Cost
    let capexToCalSysCost = [], resultSum = 0, countProcessEmpty = 0, isSysCostAreHyphen = false, isTotalCapexAreHyphen = false, isTotalInvstmtAreHyphen = false;
    for (let key in processThisObj) {
        const firstVal = processThisObj[key][0]
        const [nameWithoutId, uniqueId] = extractNameAndId(firstVal)

        capexToCalSysCost.push({ name: nameWithoutId, id: uniqueId })
    }

    if (capexToCalSysCost.length > 0) {
        capexToCalSysCost.forEach((el) => {

            const findElm = (finalResult as any[]).find((elm) => elm.name === el.name && elm.uniqueId === el.id)["perKWP"]
            if (findElm === "-") countProcessEmpty++;
            resultSum = resultSum + (typeof findElm === 'number' ? findElm : 0)
        })
    }
    if (countProcessEmpty === capexToCalSysCost.length) isSysCostAreHyphen = true
    finalResult.push({ name: SYS_COST, perKWP: (isSysCostAreHyphen ? "-" : resultSum) })
    countProcessEmpty = 0 // Reset

    //! Actual Partner Fee
    const pfIndex = (capexRender as any).findLastIndex((el: IResArrOfObj) => el.name === PARTNER_FEE)
    const getPfId = capexRender[pfIndex].uniqueId
    const modifiedPfName = PARTNER_FEE + "?%%?" + getPfId

    finalResult.push({ uniqueId: getPfId, name: PARTNER_FEE, perKWP: values[modifiedPfName] === "" ? "-" : values[modifiedPfName], [`Partner Fee-${getPfId}-p`]: new Date((values[`Partner Fee-${getPfId}-p`] as string)) })

    //! Actual Education Setup
    const esIndex = (capexRender as any).findLastIndex((el: IResArrOfObj) => el.name === EDU_SET)
    const getEsId = capexRender[esIndex].uniqueId
    const modifiedEsName = EDU_SET + "?%%?" + getEsId

    finalResult.push({ uniqueId: getEsId, name: EDU_SET, perKWP: values[modifiedEsName] === "" ? "-" : values[modifiedEsName], [`Education Setup-${getEsId}-p`]: new Date((values[`Education Setup-${getEsId}-p`] as string)) })

    //! Actual SFS Development Fee
    const sfsIndex = (capexRender as any).findLastIndex((el: IResArrOfObj) => el.name === SFS_DEV_FEE)
    const getSfsId = capexRender[sfsIndex].uniqueId
    const modifiedSfsName = SFS_DEV_FEE + "?%%?" + getSfsId

    finalResult.push({ uniqueId: getSfsId, name: SFS_DEV_FEE, perKWP: values[modifiedSfsName] === "" ? "-" : values[modifiedSfsName], paymentBy: values[deletedThisLastKey] ? "CBS" : "SOFS", [`SfS Development Fee-${getSfsId}-p`]: new Date((values[`SfS Development Fee-${getSfsId}-p`]) as string) })

    //! Actuals Total Capex
    if ((values[modifiedPfName] === "-" || values[modifiedPfName] === "") && (values[modifiedEsName] === "-" || values[modifiedEsName] === "") && (values[modifiedSfsName] === "-" || values[modifiedSfsName] === "")) {
        isTotalCapexAreHyphen = true
    }
    let totalCapexSum = resultSum + Number(typeof (values[modifiedPfName]) === "number" ? (values[modifiedPfName] as string) : 0) +
        Number(typeof values[modifiedEsName] === "number" ? values[modifiedEsName] : 0) +
        Number(typeof values[modifiedSfsName] === "number" ? values[modifiedSfsName] : 0)

    finalResult.push({ name: TOT_CAPEX, perKWP: (isTotalCapexAreHyphen && isSysCostAreHyphen) ? "-" : totalCapexSum })

    //! Actuals Fundraising Fee
    const ffIndex = (capexRender as any).findLastIndex((el: IResArrOfObj) => el.name === FUND_FEE)
    const getFfId = capexRender[ffIndex].uniqueId
    const modifiedFfName = FUND_FEE + "?%%?" + getFfId

    finalResult.push({ uniqueId: getFfId, name: FUND_FEE, perKWP: values[modifiedFfName] === "" ? "-" : values[modifiedFfName], [`Fundraising Fee-${getFfId}-p`]: new Date((values[`Fundraising Fee-${getFfId}-p`]) as string) })

    //! Actuals Contribution/Grant
    const cgfIndex = (capexRender as any).findLastIndex((el: IResArrOfObj) => el.name === CONT_GRANT)
    const getCgId = capexRender[cgfIndex].uniqueId
    const modifiedCgName = CONT_GRANT + "?%%?" + getCgId

    finalResult.push({ uniqueId: getCgId, name: CONT_GRANT, perKWP: values[modifiedCgName] === "" ? "-" : values[modifiedCgName], [`Contribution / Grant-${getCgId}-p`]: new Date((values[`Contribution / Grant-${getCgId}-p`]) as string) })

    //! Actuals Total Investments
    if ((values[modifiedFfName] === "-" || values[modifiedFfName] === "") && (values[modifiedCgName] === "-" || values[modifiedCgName] === "")) {
        isTotalInvstmtAreHyphen = true
    }
    const totalInvstmSum = totalCapexSum + Number(typeof values[modifiedFfName] === "number" ? values[modifiedFfName] : 0) - (typeof values[modifiedCgName] === "number" ? (values[modifiedCgName] as number) : 0 as number)
    finalResult.push({ name: "Total Investment", perKWP: (isTotalInvstmtAreHyphen && isSysCostAreHyphen) ? "-" : totalInvstmSum })

    const filterOutFinalResult = finalResult.filter(value => Object.keys(value).length !== 0);
    return filterOutFinalResult
}

export const getParams = (isRegion = false, isSlug = false, isDesignId = false) => {
    const overAllParams = new URLSearchParams(window.location.search);
    const regionValue: string = overAllParams.get("region") || ""
    const slugValue = overAllParams.get("slug") || ""
    const designIdValue = overAllParams.get("designId") || ""

    const result: IResult = {}
    if (isRegion) result['region'] = regionValue.trim()
    if (isDesignId) result['designId'] = designIdValue.trim()
    if (isSlug) result['slug'] = slugValue.trim()
    return result
}

export const ReplaceURL = (history: any, location: any, searchParams: any) => {
    const params = new URLSearchParams(searchParams);
    history.replace({ pathname: location.pathname, search: params.toString() });
}

export const clearParams = () => {
    const { region, slug, designId } = getParams(true, true, true)
    if (region || slug || designId) {
        let url = window.location.href;
        let clearURL = url.split("?")[0]
        window.location.href = clearURL
    }
}


export const isAllActualsEmpty = (resData: IResArrOfObj[]) => {
    if (resData.length === 0) return

    // let actualsCount = resData.filter(res => res.isActuals === true)?.length
    //  let actualsWithValue: number = 0
    let isEmpty: boolean = false

    for (const object of resData) {
        const { isActuals, actualPerKWP } = object
        if (isActuals) {
            if (typeof actualPerKWP === "number" && actualPerKWP !== 0) {
                isEmpty = true;
                break
            }
        }
    }

    if (isEmpty) return true
    return false
}

/* Check Is all values are empty or not */
export const getSofsValues = (resData: IResArrOfObj[]) => {

    const sofsPaymentId: Record<string, number> = {}

    let finalResult = []
    resData.forEach((elm) => {
        const { isActuals, paymentBy, paymentById } = elm
        if (isActuals) {
            if (paymentBy === "SOFS") {
                if (paymentById) sofsPaymentId[paymentById] = 1
            }
        }
    })

    const sofsPaymentIdsKeys = Object.keys(sofsPaymentId)
    if (sofsPaymentIdsKeys.length > 0) {
        for (let key in sofsPaymentId) {
            const findElm = resData.find((elm) => elm.paymentById === key)
            if (findElm !== undefined) {
                const { name, actualPerKWP } = findElm
                if (actualPerKWP === "" || actualPerKWP === "-" || actualPerKWP === 0) continue;
                finalResult.push({ name, actualTotal: actualPerKWP })
            }
        }
    } else {
        finalResult = []
    }

    return finalResult
}

// If all values are empty, then we cannot generate invoices(For SOFS Expenses)
export function checkIsAllValuesEmpty(res: IResArrOfObj[], setIsSofsActualsThere: React.Dispatch<React.SetStateAction<boolean>>, setSofsExpenses: React.Dispatch<React.SetStateAction<[] | ISofsExpenses[]>>) {


    const finalResult = getSofsValues(res)
    setSofsExpenses(finalResult)


    if (finalResult.length === 0) {
        setIsSofsActualsThere(false)
        return
    }
    let countEmptyValues = 0
    finalResult.forEach((elm) => {
        const { actualTotal } = elm
        if (actualTotal === "-" || actualTotal === "" || actualTotal === 0) {
            countEmptyValues++;
        }
    })
    if (countEmptyValues === finalResult.length) {
        setIsSofsActualsThere(false)
        return
    }
    setIsSofsActualsThere(true)
}



export const isAllActualsEmptyAfterUpdate = (capexRender: IResArrOfObj[], updatedData: Omit<IModifiedRegionCapex, "isAcutals">[]) => {

    const acutalsIds = [];
    let isNotEmpty = false

    for (const object of capexRender) {
        const { uniqueId, isActuals } = object
        if (isActuals) acutalsIds.push(uniqueId)
    }

    for (const uniqueId of acutalsIds) {

        const findObj = updatedData.find(obj => (obj.uniqueId as unknown as string | number) === uniqueId)

        if (findObj) {
            const { perKWP } = findObj
            if (typeof perKWP === "number") {
                isNotEmpty = true;
                break;
            }
        }

    }

    if (isNotEmpty) return false
    return true

}







interface IResult {
    region?: string;
    designId?: string;
    slug?: string;
}