import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IAdmin } from "../../../store/admin/reducer";
import { IContent } from "../../../store/content/reducer";
import Block from "./Block";

const Modules = ({ content }: ModulesProp) => {
  const admin: IAdmin = useSelector<RootState, IAdmin>((state) => state.web.admin)
  return (
    <div className="modules">
   
      {!admin.isLoading &&
        content.modules &&
        content.modules.map((module: any) => {
          const { id, name, url, isActive, description } = module;
          return <Block key={id} name={name} url={url} id={id} isActive={isActive} description={description} isModule={true}/>;
        })}
      
      <Block
        name="Add New"
        url=""
        isNew={true}
        id={new Date().getTime().toString()}
      />
    </div>
  );
};

export interface ModulesProp {
  content: IContent;
}

export default Modules;
