import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { IRegionConfig } from "@solarforschools/sfs-core";
import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import get from "lodash/get"
import { FundingConfiguration } from "@solarforschools/sfs-core/dist/types";
import { ILanguageConfiguration } from "@solarforschools/sfs-core";


//added extras
export const getGeneralFirstFields = (isAltered = false) => {

  let fields: IField[] = [
    {
      label: "Order",
      key: "order",
      type: "string",
      extras: { required: true },
      placeholder: "Enter order",
      className: 'ms-Grid-col  ms-lg2'
    },
    {
      label: "Country",
      key: "country",
      type: "string",
      extras: { required: true, disabled: true },
      placeholder: "Enter country",
      className: 'ms-Grid-col ms-lg2'
    },
    {
      label: "Id",
      key: "id",
      type: "string",
      placeholder: "Enter id",
      extras: { required: true, disabled: true },
      className: 'ms-Grid-col ms-lg2'
    },
    {
      label: "Hosts",
      key: "data.HOSTS",
      type: "multiLineText",
      extras: { required: true, disabled: true },
      className: 'ms-Grid-col ms-lg4',
      placeholder: "Add hosts",
    },

  ]
  if (!isAltered) return fields

  else return fields.map(field => {
    if (field.key === "data.HOSTS") return field
    return { ...field, extras: { required: true } }
  })

}



export const getGeneralSecondFields = (isAltered = false) => {

  const fields: IField[] = [
    {
      label: "Domain",
      key: "data.DOMAIN",
      type: "string",
      extras: { required: true },
      placeholder: "Enter domain",
      className: 'ms-Grid-col ms-lg4'
    },
    {
      label: "Currency",
      key: "data.CURRENCY",
      type: "string",
      extras: { required: true, disabled: true },
      placeholder: "Enter currency",
      className: 'ms-Grid-col ms-lg2'
    },

    {
      label: "Currency Code",
      key: "data.CURRENCY_CODE",
      type: "string",
      extras: { required: true, disabled: true },
      placeholder: "Enter country code",
      className: 'ms-Grid-col ms-lg2'
    },

    {
      label: "Title",
      key: "data.TITLE",
      type: "string",
      extras: { required: true },
      placeholder: "Enter title",
      className: 'ms-Grid-col ms-lg4'
    },

    {
      label: "Code",
      key: "data.CODE",
      type: "string",
      extras: { required: true, disabled: true },
      placeholder: "Enter code",
      className: 'ms-Grid-col ms-lg3'
    },


    {
      label: "Name",
      key: "data.NAME",
      type: "string",
      extras: { required: true },
      placeholder: "Enter name",
      className: 'ms-Grid-col ms-lg3'
    },
    {
      label: "Default Language",
      key: "data.DEFAULTLANGUAGE",
      type: "string",
      extras: { required: true },
      placeholder: "Enter default language",
      className: 'ms-Grid-col ms-lg3'
    },
    {
      label: "Value",
      key: "data.VALUE",
      type: "string",
      extras: { required: true },
      placeholder: "Enter value",
      className: 'ms-Grid-col ms-lg3'
    },

    {
      label: "Default Project",
      key: "data.DEFAULTPROJECT",
      type: "string",
      extras: { required: true },
      placeholder: "Enter default project",
      className: 'ms-Grid-col ms-lg3'
    },

    {
      label: "Intro Video",
      key: "data.INTRO_VIDEO",
      type: "string",
      extras: { required: true },
      placeholder: "Enter intro video",
      className: 'ms-Grid-col ms-lg3'
    },
    {
      label: "Intro Video Path",
      key: "data.INTRO_VIDEO_PATH",
      type: "string",
      extras: { required: true },
      placeholder: "Enter intro video path",
      className: 'ms-Grid-col ms-lg3'
    },

    {
      label: "Subscription Url",
      key: "data.SUBSCRIPTION_URL",
      type: "string",
      extras: { required: true },
      placeholder: "Enter subscription url",
      className: 'ms-Grid-col ms-lg3'
    },

    {
      label: "Team Web Hook",
      key: "data.TEAMS_WEBHOOK",
      type: "string",
      extras: { required: true },
      placeholder: "Enter team web hhook",
      className: 'ms-Grid-col ms-lg12'
    },
    {
      label: "Customer Folder - Site",
      key: "data.CustomerFolder.site",
      type: "string",
      extras: { required: true },
      placeholder: "Enter customer folder - site",
      className: 'ms-Grid-col ms-lg4'
    },
    {
      label: "Customer Folder - Drive",
      key: "data.CustomerFolder.drive",
      type: "string",
      extras: { required: true },
      placeholder: "Enter customer folder - site",
      className: 'ms-Grid-col ms-lg4'
    },
    {
      label: "Customer Folder - Path",
      key: "data.CustomerFolder.path",
      type: "string",
      extras: { required: true },
      placeholder: "Enter customer folder - path",
      className: 'ms-Grid-col ms-lg4'
    },

    {
      label: "Language",
      key: "data.LANGUAGES",
      type: "string",
      rows: 0,
      placeholder: "Add language",
      extras: { required: true, disabled: true },
      className: 'ms-Grid-col ms-lg4'
    },

  ]

  if (!isAltered) return fields

  else return fields.map(field => {
    if (field.key === "data.LANGUAGES") return field
    return { ...field, extras: { required: true } }
  })
}



export const getGeneralFundingDetailsFields: IField[] = [
  {
    label: "Funding",
    key: "data.FundingModel",
    type: "multiLineText",
    rows: 0,
    placeholder: "Add funding model",
    extras: { required: true, disabled: true },
    className: 'ms-Grid-col ms-lg4'
  },
]

export const getGeneralLangList: IField[] = [
  {
    label: "NAME",
    key: "NAME",
    type: "string",
    extras: { required: true },
    placeholder: "Enter name",
    className: 'ms-Grid-col ms-lg2'
  },
  {
    label: "REGION",
    key: "REGION",
    type: "string",
    extras: { required: true },
    placeholder: "Enter region",
    className: 'ms-Grid-col ms-lg2'
  },
  {
    label: "LANG",
    key: "LANG",
    type: "string",
    extras: { required: true },
    placeholder: "Enter language",
    className: 'ms-Grid-col ms-lg2'
  },
  {
    label: "TITLE",
    key: "title",
    type: "string",
    extras: { required: true },
    placeholder: "Enter title",
    className: 'ms-Grid-col ms-lg2'
  }

]

export const getGeneralAddressesFields: IField[] = [
  {
    label: "Lat",
    key: "data.MAP.lat",
    type: "string",
    extras: { required: true },
    placeholder: "Enter latitude",
    className: 'ms-Grid-col ms-lg2'
  },
  {
    label: "Lng",
    key: "data.MAP.lng",
    type: "string",
    extras: { required: true },
    placeholder: "Enter longitude",
    className: 'ms-Grid-col ms-lg2'
  },
  {
    label: "Zoom",
    key: "data.MAP.zoom",
    type: "string",
    extras: { required: true },
    placeholder: "Enter map zoom",
    className: 'ms-Grid-col ms-lg2'
  }
  ,
  {
    label: "Address",
    key: "data.ADDRESS",
    type: "string",
    extras: { required: true, disabled: true },
    placeholder: "Add address",
    className: 'ms-Grid-col ms-lg5'
  }

]

export const getGeneralLinksMailsFields: IField[] = [
  {
    label: "Twitter Link",
    key: "data.SOCIALMEDIALINKS.TWITTER",
    type: "string",
    extras: { required: true },
    placeholder: "Enter twitter link",
    className: 'ms-Grid-col ms-lg3'
  },
  {
    label: "Facebook Link",
    key: "data.SOCIALMEDIALINKS.FACEBOOK",
    type: "string",
    extras: { required: true },
    placeholder: "Enter facebook link",
    className: 'ms-Grid-col ms-lg3'
  },
  {
    label: "Linkedin Link",
    key: "data.SOCIALMEDIALINKS.LINKEDIN",
    type: "string",
    extras: { required: true },
    placeholder: "Enter linkedin link",
    className: 'ms-Grid-col ms-lg3'
  },
  {
    label: "Instagram Link",
    key: "data.SOCIALMEDIALINKS.INSTAGRAM",
    type: "string",
    extras: { required: true },
    placeholder: "Enter instagram link",
    className: 'ms-Grid-col ms-lg3'
  },
  {
    label: "Team Email",
    key: "data.EMAILS.team",
    type: "string",
    extras: { required: true },
    placeholder: "Enter team email",
    className: 'ms-Grid-col ms-lg3'
  },

  {
    label: "Info Email",
    key: "data.EMAILS.info",
    type: "string",
    extras: { required: true },
    placeholder: "Enter info email",
    className: 'ms-Grid-col ms-lg3'

  },

  {
    label: "Registration Email",
    key: "data.EMAILS.registration",
    type: "string",
    extras: { required: true },
    placeholder: "Enter Registration email",
    className: 'ms-Grid-col ms-lg3'

  },

  {
    label: "Bonds Email",
    key: "data.EMAILS.bonds",
    type: "string",
    extras: { required: true },
    placeholder: "Enter bonds email",
    className: 'ms-Grid-col ms-lg3'

  },

]

export const getGeneralPVGISOthersFields: IField[] = [
  {
    label: "Azimuth",
    key: "data.PVGIS.azimuth",
    type: "string",
    extras: { required: true },
    placeholder: "Enter PVIGS - azimuth",
    className: 'ms-Grid-col ms-lg2'
  },

  {
    label: "Angle",
    key: "data.PVGIS.angle",
    type: "string",
    extras: { required: true },
    placeholder: "Enter PVGIS - ANGLE",
    className: 'ms-Grid-col ms-lg2'
  },


  {
    label: "Loss",
    key: "data.PVGIS.loss",
    type: "string",
    extras: { required: true },
    placeholder: "Enter PVGIS-LOSS",
    className: 'ms-Grid-col ms-lg2'
  },
  {
    label: " DB",
    key: "data.PVGIS.db",
    type: "string",
    extras: { required: true },
    placeholder: "Enter PVGIS - DB",
    className: 'ms-Grid-col ms-lg2'
  },
  {
    label: "Logo Url",
    key: "data.LOGO_URL",
    type: "string",
    extras: { required: true },
    placeholder: "Enter logo url",
    className: 'ms-Grid-col ms-lg6'
  },
  {
    label: "Phone Number",
    key: "data.PHONE_NUMBERS.contact",
    type: "string",
    extras: { required: true },
    placeholder: "Enter phone number",
    className: 'ms-Grid-col ms-lg4'
  },
  {
    label: "Active",
    key: "data.ACTIVE",
    extras: { required: true },
    type: "switch",
    className: 'ms-Grid-col ms-lg2'
  },
]



export const validateRegionConfigDetails = async (regionConfig: IRegionConfig) => {
  const errors: StringMap = {};
  const generalFields = [...getGeneralFirstFields(), ...getGeneralSecondFields(), ...getGeneralFundingDetailsFields, ...getGeneralAddressesFields, ...getGeneralLinksMailsFields, ...getGeneralPVGISOthersFields];
  generalFields.forEach((field) => {
    if (field.readOnly) return
    if (field.label === "Bonds Email") return;
    const value = get(regionConfig, field.key)
    if ((field.label === "Azimuth" && value === 0) || (field.label === "Angle" && value === 0) || (field.label === "Loss" && value === 0) || (field.label === "DB" && value === 0) || (field.label === "Active")) return;

    if (field.label === "Team Email" || field.label === "Info Email" || field.label === "Registration Email" || field.label === "Bonds Email") {
      const res = validateEmail(value);

      if (!res) {
        errors[field.key] = `${field.label} is invalid`;
      }
    }
    if (!value || value.length === 0) {
      errors[field.key] = requiredMessage;
    }

  })
  return errors;
}


export const getRegionConfigColumns = (params: {
  actions: {
    icon: string,
    ariaLabel?: string,
    onClick: (task: any) => any
  }[]
}): IColumn[] => {
  return [
    {
      key: 'order',
      name: 'Order',
      fieldName: 'order',
      minWidth: 45,
      maxWidth: 60,
      ...isSortable,
      onRender: (item: IRegionConfig) => {
        let value: string = "-"
        if (item['order']) {
          value = item['order']
        }

        return (
          <TooltipHost content={value} closeDelay={500}>
            {value}
          </TooltipHost>
        );
      },
    },
    {
      name: 'Country',
      key: 'country',
      fieldName: 'country',
      minWidth: 50,
      maxWidth: 60,
      ...isSortable,
      onRender: (item: IRegionConfig) => {
        let value = item['country']
        return (
          <TooltipHost content={value} closeDelay={500} >
            {value}
          </TooltipHost >
        );
      },
    },
    {
      name: 'Domain',
      key: 'domain',
      fieldName: 'domain',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item: IRegionConfig) => {
        let value = item.data.DOMAIN
        return (
          <TooltipHost content={value} closeDelay={500} >
            {value}
          </TooltipHost >
        );
      },
    },
    {
      name: 'Currency',
      key: 'currency',
      fieldName: 'currency',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item: IRegionConfig) => {
        let value = item.data.CURRENCY
        return (
          <TooltipHost content={value} closeDelay={500} >
            {value}
          </TooltipHost >
        );
      },
    },
    {
      name: 'Currency Code',
      key: 'currency code',
      fieldName: 'currency code',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item: IRegionConfig) => {
        let value = item.data.CURRENCY_CODE
        return (
          <TooltipHost content={value} closeDelay={500} >
            {value}
          </TooltipHost >
        );
      },
    },

    {
      name: 'Active',
      key: 'active',
      fieldName: 'active',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => {
        let value = item.data.ACTIVE;

        const styleValue = {
          redDot: {
            height: "15px",
            width: "15px",
            borderRadius: "50%",
            backgroundColor: "red",
            display: "inline-block"
          },
          greenDot: {
            height: "15px",
            width: "15px",
            borderRadius: "50%",
            backgroundColor: "green",
            display: "inline-block"
          },
        };
        return (
          <TooltipHost
            closeDelay={500} >

            {
              value ? <span style={styleValue.greenDot}>
              </span> :
                <span style={styleValue.redDot}></span>

            }
          </TooltipHost >
        );
      },
    },

    {
      key: "action",
      name: "Action",
      fieldName: "-",
      ariaLabel: "Action",
      minWidth: 100,
      maxWidth: 200,
      onRender: (taskType) => {

        return (
          <div className="action-icons-container">
            {params.actions.map((action: { icon: string | undefined; ariaLabel?: string, onClick: (arg0: any) => void; }) => {

              return <FontIcon
                iconName={action.icon}
                aria-label={action.ariaLabel || ''}
                onClick={() => action.onClick(taskType)} />;
            })}
          </div>
        );
      },
    }

  ];
}

export const getData = (): IColumn[] => {
  return [
    {
      key: "name",
      name: "CAPEX",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => {
        const value = item?.name || ""
        return (
          <span>
            {value}
          </span>
        );
      },
    },
    //! Budget Total field (headValue)
    // {
    //   key: "perKWP", // TODO: need to change this key 
    //   name: "Budget Total",
    //   fieldName: "perKWP",
    //   minWidth: 200,
    //   maxWidth: 200,
    //   ...isSortable,
    //   onRender: (item) => {
    //     let value;
    //     if (typeof item?.perKWP === "number")
    //       value = item["perKWP"].toFixed(2);
    //     else value = item["perKWP"]

    //     return (
    //       <span>
    //         {value}
    //       </span>
    //     );
    //   },
    // },
    {
      key: "selectedCostResult",
      name: "Calculator mapping",
      fieldName: "selectedCostResult",
      minWidth: 200,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => {
        let value = item?.selectedCostResult || "-"


        return (
          <span>
            {value}
          </span>
        );
      },
    },

  ]
}

export const defaultRegionConfig: IRegionConfig = {
  country: "",
  id: "",
  type: "REGION",
  data: {
    DOMAIN: "",
    HOSTS: [],
    TITLE: "",
    LANGUAGES: [],
    CODE: "",
    NAME: "",
    DEFAULTLANGUAGE: "",
    ACTIVE: true,
    CURRENCY: "",
    CURRENCY_CODE: "",
    VALUE: 0,
    DEFAULTPROJECT: "",
    MAP: {
      lat: 0,
      lng: 0,
      zoom: 0,
    },
    ADDRESS: [],
    INTRO_VIDEO: "",
    INTRO_VIDEO_PATH: "",
    SUBSCRIPTION_URL: "",
    SOCIALMEDIALINKS: {
      TWITTER: "",
      FACEBOOK: "",
      LINKEDIN: "",
      INSTAGRAM: "",
    },
    TEAMS_WEBHOOK: "",
    CustomerFolder: {
      site: "",
      drive: "",
      path: "",
    },
    FundingModel: [] as string[],
    Funding: [] as FundingConfiguration,
    EMAILS: {
      team: "",
      info: "",
      registration: "",
      bonds: "",
    },
    PVGIS: {
      azimuth: 0,
      angle: 0,
      loss: 0,
      db: "",
    },
    PHONE_NUMBERS: {
      contact: "",
    },
    LOGO_URL: "",
    panelPowerOptions: []
  },
  order: "",
  
};

export const defaultLangList = {
  NAME: "",
  REGION: "",
  LANG: "",
  title: ""
}


const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};


export const validateNewRegionConfigDetails = (regionConfig: IRegionConfig) => {
  const errors: StringMap = {};
  const generalFields = [...getGeneralFirstFields(), ...getGeneralSecondFields(), ...getGeneralFundingDetailsFields, ...getGeneralAddressesFields, ...getGeneralLinksMailsFields, ...getGeneralPVGISOthersFields];
  generalFields.forEach((field) => {
    if (field.readOnly) return

    const value = get(regionConfig, field.key)

    if ((field.label === "Azimuth" && value === 0) || (field.label === "Angle" && value === 0) || (field.label === "Loss" && value === 0) || (field.label === "DB" && value === 0) || (field.label === "Active")) return;

    if (field.label === "Team Email" || field.label === "Info Email" || field.label === "Registration Email" || field.label === "Bonds Email") {
      const res = validateEmail(value);

      if (!res) {
        errors[field.key] = `${field.label} is invalid`;
      }
    }
    if (!value || value.length === 0) {
      errors[field.key] = requiredMessage;
    }

  })
  return errors;
}


export const validateLangList =  (langList: ILanguageConfiguration) => {
  const errors: StringMap = {};
  getGeneralLangList.forEach((field) => {
    const value = get(langList, field.key)

    if (!value || value.length === 0) {
      errors[field.key] = requiredMessage;
    }

  })
  return errors;
}