
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchPendingApprovalDrafts, handleDraftApproval, handleDraftRejection, resetPendingApprovalDrafts, setIsLoading } from '../../../store/admin/translations/actions'
import { RootState } from '../../../store'
import { IARParams, IPendingListLeftPane, IPendingListRightPane, ITranslations } from './utils/types'
import { BsFiletypeKey } from 'react-icons/bs'
import { languageOptions } from './utils/helper'
import { Icon, Text } from '@fluentui/react'
import { ConfirmDialog } from '../common/ConfirmDialog'
import { FcApproval } from 'react-icons/fc'
import Card from './utils/Card'

const PendingLists = () => {

    const { pendingDrafts: data, pendingDraftsCount: count, isLoading, filter: { lang } } = useSelector<RootState, ITranslations>((state) => state.web.translations);

    const dispatch = useDispatch()
    const history = useHistory()


    useEffect(() => {
        if (count === 0 && !isLoading) {
            history.push("/translations")
        }
    }, [count])

    useEffect(() => {
        dispatch(setIsLoading())
        dispatch(fetchPendingApprovalDrafts())

        return () => {
            // reset the pending draft array (clean up)
            dispatch(resetPendingApprovalDrafts())
        }
    }, [])

    const handleApprove = async (params: IARParams) => {
        if (!params.docId) return

        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Approve translation",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to approve this translation?`,
            },
            confirmBtnText: "Approve",
        });

        if (isConfirmed) {
            if(params.isDeleted) dispatch(handleDraftApproval(params))
            else {
                delete params["isDeleted"]
                dispatch(handleDraftApproval(params))
            }
        } 
    }

    const handleReject = async (params: IARParams) => {
        if (!params.docId) return

        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Reject translation",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to reject this translation?`,
            },
            confirmBtnText: "Reject",
        });

        if (isConfirmed) dispatch(handleDraftRejection(params))
    }

    const LeftPane = ({ langKey }: IPendingListLeftPane) => {
        return (
            <>
                <div style={{ display: "flex", gap: "5px" }}>
                    <div>
                        <BsFiletypeKey size={18} />
                    </div>
                    <div>
                        {langKey}
                    </div>

                </div>
            </>
        )
    }

    const RightPane = ({ en, findLang, value, langKey, language, docId, isDeleted }: IPendingListRightPane) => {
        return (
            <>
                <div className='card-right-outer' style={{justifyContent: ""}} >
                    <div className='card-right-language' style={{ minWidth: "25%" }}>
                        {"English (en)"}
                    </div>
                    <div className='card-right-content'>
                        {en || ""}
                    </div>

                </div>
                <hr />
                <div className='card-right-outer' >
                    <div className='card-right-language' style={{ minWidth: "25%" }}>
                        {findLang || ""}
                    </div>
                    <div className='card-right-content'>
                        {value || ""}
                    </div>

                    <div className='flex-row-center card-right-btn'>
                        <div className='icon' onClick={() => handleApprove({ key: langKey, isApproved: true, value, language, docId, isDeleted })}>
                            {
                                isDeleted ? <Icon iconName='Delete' style={{ fontSize: "1.05rem", color:"red" }} /> : <FcApproval size={23} />
                            }
                        </div>

                        <div className='icon' onClick={() => handleReject({ key: langKey, isRejected: true, value, language, docId, isDeleted })}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px">
                                <path fill='#FF0000' d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z" /></svg>
                        </div>

                    </div>

                </div>
            </>
        )
    }

    return (
        <>
            <div style={{ margin: "2rem 0 2rem 2rem" }}>
                <div>
                    <button
                        onClick={() => {
                            history.push("/translations")
                        }}
                        className='back-btn'
                    >
                        {"< Back"}
                    </button>
                </div>

                <div style={{ textAlign: "center" }}>
                    <Text variant='large'>
                        Pending for approval
                    </Text>
                </div>

                <div>
                    <Text style={{ color: "var(--solar-blue)" }}>
                        Displaying: <span style={{ fontWeight: 600 }}> {data.length || 0} </span> / <span> {data.length || 0} </span>
                    </Text>
                </div>
            </div>

            <div className='card-layout'>

                {
                    data?.map((elm, index: number) => {
                        const { key: langKey, value, _id: docId, language, en, isDeleted } = elm
                        const findLang = languageOptions.find(elm => elm.key === language)?.text || "English"

                        return (
                            <React.Fragment key={index}>

                                <Card isPendingList={false}>
                                    <LeftPane langKey={langKey} />
                                    <RightPane en={en as string} findLang={findLang} isDeleted={isDeleted} value={value} langKey={langKey} language={language} docId={docId as string} />
                                </Card>

                            </React.Fragment>
                        )
                    })
                }
            </div>
        </>
    )
}

export default PendingLists
