import { useState } from 'react';
import { ActionButton, CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { getTaskTypeColumns } from './taskTypeHelper';
import { ITaskTypeState } from '../../../store/admin/taskType/reducer';
import { ITaskType } from '@solarforschools/sfs-core/dist/taskType/types';
import { deleteTaskType, getTaskTypes, setTaskTypeFilter } from '../../../store/admin/taskType/action';
import openTaskTypeModal from './TaskTypeDialog';
import DetailsListWapper from '../../common/DetailsListWapper';

const TaskTypeList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { taskTypes, totalCount, taskTypeFilter }: ITaskTypeState = useSelector<RootState, ITaskTypeState>((state: RootState) => state.web.taskType)

  const handleOnDelete = async (taskType: ITaskType) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete TaskType",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${taskType.name}`,
      },
    });
    if (confirm) dispatch(deleteTaskType(taskType));
  };

  const handleOnEdit = async (taskType: ITaskType) => {
    await openTaskTypeModal({ taskType })

  };

  const headerRow = getTaskTypeColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        onClick: handleOnDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...taskTypeFilter, page: 1, sortField: field, sortOrder: order };
    dispatch(setTaskTypeFilter(params));
    dispatch(getTaskTypes(params))
  }

  const loadNextPage = () => {
    const { page = 0 } = taskTypeFilter
    dispatch(setTaskTypeFilter({ ...taskTypeFilter, page: page + 1 }))
    dispatch(getTaskTypes(undefined, false))
  }

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}>
            {totalCount > 0 && (<>Displaying &nbsp; {taskTypes.length}/ {totalCount}</>)}
          </span>
        </div>

        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () => await openTaskTypeModal({})}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp; Add TaskType
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={taskTypes}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={taskTypes.length < totalCount}
                onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default TaskTypeList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

