import { toast } from "react-toastify";
import { Action } from "./actionTypes";
import { ITeam as ITeamDb } from "@solarforschools/sfs-core/dist/team/types";

export interface ITeam {
  data: ITeamDb[];
  isLoading: boolean;
}
const initialState: ITeam = {
  data: [],
  isLoading: true,
};

const team = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_TEAM":
      return {
        data: action.payload,
        isLoading: false,
      };

    case "CREATE_NEW_TEAM_MEMBER":
      const data = action.payload;
      return {
        data: [...state.data, data],
        isLoading: false,
      };
    case "RESET_ALL":
      return {
        ...initialState,
      };

    case "SET_TEAM_MEMBER":
      const updateThisData = action.payload;
      let tempData = [...state.data];
      const findDataIndex = tempData.findIndex(
        (data) => data._id === updateThisData._id
      );
      if (findDataIndex !== -1) {
        tempData[findDataIndex] = updateThisData;
       
        tempData?.sort((a, b) => a.order - b.order);

        toast.success("Team member updated");
      } else {
        toast.error("Error is updating team");
      }

      return {
        data: tempData,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default team;
