

import { toast } from "react-toastify";
import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { host } from "../../config";
import { RootState } from '../../index';
import { isLoading } from "../action";
import { IBillingContract } from "../../../components/admin/invoice/types";
import { DropdownParams } from "../../../components/admin/common/types";
import { SetInvoicePriceFilter } from "./actionTypes";
import { downloadFileFromStream } from "../../../components/admin/common/utils";
import { ICreateInvoicePrice, IInvoicePriceFilter } from "../../../components/admin/invoicePrices/types";
import { exportBillingInvoicePriceApi, getInvoicePricesAPI } from "../../client/invoicePrice";
import { IInvoicePriceDocument } from "@solarforschools/sfs-core";


// Action Creators

export const setInvoicePriceFilter = (invoicePriceFilter: IInvoicePriceFilter): SetInvoicePriceFilter => {
  return { type: "SET_INVOICE_PRICE_FILTER", invoicePriceFilter };
};


export const setInvoicePriceList = (invoicePrices: IInvoicePriceDocument[]) => {
  return { type: "SET_INVOICE_PRICE_LIST", invoicePrices };
};
export const updateInvoicePriceList = (invoice: IInvoicePriceDocument) => {
  return { type: "BILLING/UPDATE_INVOICE_PRICE", invoice };
};

export const setInvoicePriceTotalCount = (totalCount: number) => {
  return { type: 'SET_INVOICE_PRICE_TOTALS_COUNT', totalCount }
}
export const deleteBillingInvoicePrice = (id: string) => {
  return { type: 'BILLING/DELETE_INVOICE_PRICE', id }
}

export const fetchBillingContracts = async () => {
  try {
    const { data } = await axios.get(`${host}/billing/contract`)
    const contracts: DropdownParams[] = [{ key: 'all', text: 'All' }]
    const contractRecords = data?.sort((a: IBillingContract, b: IBillingContract) => a?.customerName?.toLowerCase().localeCompare(b?.customerName?.toLowerCase() || ''))
    for (const c of contractRecords) {
      contracts.push({ key: c.number, text: `${c.customerName} (${c.number})` })
    }
    return contracts
  } catch (error) {
    return []
  }
}

export const fetchInvoicePrices = (
  params?: IInvoicePriceFilter,
) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      if (!params) {
        const state = getState()
        const filter: IInvoicePriceFilter = state.web.invoicePrice.invoicePriceFilter
        params = { ...filter }
      }
      const { data, totalCount } = await getInvoicePricesAPI(params)
      dispatch(setInvoicePriceList(data));
      dispatch(setInvoicePriceTotalCount(totalCount))
      dispatch(isLoading(false));
    } catch (e) {
      console.log(e);
    }
  };

export const createInvoicePrice = (params: ICreateInvoicePrice) =>
  // Invoke API
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const reqBody = params;
    dispatch(isLoading(true))
    try {
      const res = await axios.post(`${host}/billing/invoice-price`, reqBody)
      // dispatch(fetchCycles())
      dispatch<any>(fetchInvoicePrices({ ...getState().web.invoicePrice.invoicePriceFilter, page: 1 }))
      toast.success(res.data.msg, { autoClose: false, closeOnClick: true });
      dispatch(isLoading(false));
    } catch (e) {
      console.log(e)
      dispatch(isLoading(false));
    }
  };

export const exportBillingInvoicePrices =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const filters: IInvoicePriceFilter =
          getState().web.invoicePrice.invoicePriceFilter;
        const data: any = await exportBillingInvoicePriceApi(filters)
        downloadFileFromStream({ data, filename: "inovices-prices", type: 'xlsx' })

        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const downloadInvoicePrice = (params: IInvoicePriceDocument): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API

  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.get(`${host}/billing/invoice-price-url/${params._id}`)
        dispatch(isLoading(false));
        if (res.status === 200) {
          if (res.data.url) {
            window.open(res.data.url, '_blank');
          } else {
            downloadFileFromStream({ data: res.data, filename: params.contract.number, type: 'pdf' })
          }
        }
      } catch (e) {
        console.log(e)
        dispatch(isLoading(false));
      }
    });
  };
};

export const deleteInovicePrice = (
  id: string,
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(isLoading(true));
        axios
          .delete(`${host}/billing/invoice-price/${id}`)
          .then((res) => {
            dispatch(isLoading(false));
            if (res.status === 200) {
              toast.success(res.data.msg);
              dispatch(deleteBillingInvoicePrice(id))
            }
          })
          .catch((err) => {
            if (err?.response?.status === 404) {
              toast.error(err.response.data.msg);
            }
            dispatch(isLoading(false));
          });
      } catch (e) {
        console.log(e);
      }
    });
  };
}
