import {
    DefaultButton,
    PrimaryButton,
    ScrollablePane,
    ScrollbarVisibility,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
} from "@fluentui/react";
import { CheckboxVisibility, SelectionMode } from "office-ui-fabric-react";
import React, { CSSProperties, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import { toast } from "react-toastify";
import DetailsListWapper from "../../common/DetailsListWapper";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { VAT, addZeroes, calculateVAT, getSofsExpensesColumn, getUserInvoiceColummns, invoiceDialogLayout } from "./Helper"
import { saveInvoiceData } from "../../../store/admin/costSummary/actions";
import { ObjectId } from "mongodb";
import { ICostSummary } from "../../../store/admin/costSummary/reducers";
import { startCase, toLower } from "lodash";
import moment from "moment";
import { IInvoice } from "@solarforschools/sfs-core/dist/analysis/types";
import { IFinalResult, ITotalInfo, IsendThisToGenerateInvoice, ISofsExpenses as IMSofsExpenses } from "./types"
import { ISofsExpenses } from "@solarforschools/sfs-core/dist/analysis/types";


export const openGenerateInvoice = (props: IFinalResult) => {
    return new Promise<"close" | "cancel" | undefined>((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget);
        const callback = (result?: any) => {
            resolve(result);
            ReactDOM.unmountComponentAtNode(mountTarget);
            mountTarget.remove();
        };

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Tax Invoice"}
                    onDismiss={() => callback(undefined)}
                >
                    <GenerateInvoice
                        onBack={() => callback("cancel")}
                        onClose={() => callback("close")}
                        invoiceData={props}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        );
    });
};

const GenerateInvoice = (props: IGenerateInvoice) => {

    const { invoiceData } = props
    const { designsResult } = useSelector<RootState, ICostSummary>((state: RootState) => state.web.costSummary)

    const invoiceUserDataLayout: CSSProperties = { position: "relative", minHeight: "28vh", maxWidth: "700px", margin: "0 auto" }
    const gridContainer: CSSProperties = { display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridTemplateRows: "repeat(2, 1fr)", maxWidth: "700px", gridGap: "0.25rem 3rem" }
    const invoiceSofsDataStyle: CSSProperties = { display: "flex", flexDirection: "column", maxWidth: "700px", margin: "0 auto", gap: "10px" }
    const invoiceSofsDataLayout: CSSProperties = { position: "relative", minHeight: "30vh" }
    const headerStyle: CSSProperties = { textAlign: "center", margin: "0.25rem" }
    const gridItem: CSSProperties = { padding: 0 }
    const totalInfoStyle: CSSProperties = { alignSelf: "flex-end" }

    const [userInvoiceColummns] = useState(getUserInvoiceColummns())
    const [userInvoiceData, setUserInvoiceDate] = useState<Record<string, string | Date>[]>([])
    const [sofsColumns] = useState(getSofsExpensesColumn())
    const [sofsInvoiceData, setSofsInvoiceData] = useState<ISofsExpenses[]>([])
    const [totalInfo, setTotalInfo] = useState<ITotalInfo[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        const { invoice, sofsExpenses } = invoiceData
        setUserInvoiceDate(invoice)
        buildSofsData(sofsExpenses)
    }, [])

    function buildSofsData(sofsExpenses: IMSofsExpenses[]) {
        if (!Array.isArray(sofsExpenses) || sofsExpenses.length === 0 || Array.isArray(sofsExpenses[0])) return []
        const buildResult: any = []

        sofsExpenses.forEach((sofsExpense) => {
            const { name, actualTotal } = sofsExpense
            const quantity = 1.00
            const amount = (typeof actualTotal === 'number' ? actualTotal : 0) * quantity
            const vat = VAT
            buildResult.push({ name, actualTotal, quantity, amount: (actualTotal === "-" || "") ? "-" : amount, vat })
        })

        const subTotal = buildResult.reduce((acc: number, curr: Record<string, number | string>) => {
            const { actualTotal } = curr
            acc = (typeof actualTotal === 'number' ? actualTotal : 0) + acc
            return acc
        }, 0)

        const vatTotal = subTotal * VAT
        const overAllTotal = (typeof subTotal === "number" ? subTotal : 0) + (typeof vatTotal === 'number' ? vatTotal : 0)
        setTotalInfo([{ subTotal: addZeroes(subTotal), totalVAT: addZeroes(vatTotal), vat: calculateVAT(VAT), overAllTotal: addZeroes(overAllTotal) }])

        const modifiedBuildResult = buildResult.map((result: Record<string, string | number>) => {
            const { name, actualTotal, quantity, amount, vat } = result
            return { name, actualTotal: typeof actualTotal === "number" ? addZeroes(actualTotal) : "-", quantity: addZeroes(quantity as number), amount: (actualTotal === "-" || "") ? "-" : addZeroes(amount as number), vat: calculateVAT(vat as number) }
        })
        setSofsInvoiceData(modifiedBuildResult)
    }

    const handleGenerateInvoice = async () => {

        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Generate Invoice",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to generate invoice ?`,
            },
            confirmBtnText: "Generate Invoice",
        });

        if (isConfirmed) {
            const designId = designsResult[0]?.designId as unknown as ObjectId
            const slug: string | undefined = designsResult[0]?.slug
            const region: string | undefined = designsResult[0]?.schoolRegion
            let modifiedUserInvData: Record<string, string> = {}

            userInvoiceData.forEach((elm) => {
                let { name, value } = elm
                const splittedName = (name as string).split(" ")
                const [first, last] = splittedName
                const modifiedName = first.toLowerCase() + startCase(toLower(last));
                if (name === "Invoice Date" || name === "Due Date") {
                    let tempVal = moment(value).format("DD MMM YYYY")
                    modifiedUserInvData[modifiedName] = tempVal
                } else {
                    modifiedUserInvData[modifiedName] = value as string
                }

            })

            const modifiedInvoices: Partial<IInvoice> = {
                ...modifiedUserInvData,
                sofsExpenses: sofsInvoiceData,
                total: totalInfo[0]
            }

            const sendThisToGenerateInvoice: IsendThisToGenerateInvoice = { designId, slug, invoiceData: modifiedInvoices, region }
            toast.success("Invoice is generating ... Don't refresh the page")
            dispatch(saveInvoiceData(sendThisToGenerateInvoice));
            props.onClose();
        }
    }

    const renderInvoiceUserData = (

        <>
            <div style={invoiceUserDataLayout}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsListWapper
                        columns={userInvoiceColummns}
                        onRenderDetailsHeader={DetailListStickyHeader}
                        selectionMode={SelectionMode.none}
                        compact={true}
                        items={userInvoiceData}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </ScrollablePane>
            </div>

        </>
    )


    const renderInvoiceSofsData = (
        <>
            <div style={invoiceSofsDataStyle}>
                <div>
                    <div style={invoiceSofsDataLayout}>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <DetailsListWapper
                                columns={sofsColumns}
                                onRenderDetailsHeader={DetailListStickyHeader}
                                selectionMode={SelectionMode.none}
                                compact={true}
                                items={sofsInvoiceData}
                                checkboxVisibility={CheckboxVisibility.hidden}
                            />
                        </ScrollablePane>
                    </div>
                </div>

                <div style={totalInfoStyle}>
                    {
                        totalInfo?.map((elm) => {
                            const { subTotal, vat, totalVAT, overAllTotal } = elm
                            return (
                                <div style={gridContainer}>
                                    <div style={gridItem}>
                                        <Text variant="medium">Sub Total</Text>
                                    </div>
                                    <div style={gridItem}>
                                        <Text variant="medium">{subTotal}</Text>
                                    </div>
                                    <div style={gridItem}>
                                        <Text variant="medium">Total VAT {vat}</Text>
                                    </div>
                                    <div style={gridItem}>
                                        <Text variant="medium">{totalVAT}</Text>
                                    </div>
                                    <div style={gridItem}>
                                        <Text variant="medium">TOTAL GBP</Text>
                                    </div>
                                    <div style={gridItem}>
                                        <Text variant="medium">{overAllTotal}</Text>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </>
    )


    const headerRender = (
        <h2 style={headerStyle}>
            Tax Invoice
        </h2>
    )

    return (
        <div>

            {headerRender}

            {renderInvoiceUserData}

            {renderInvoiceSofsData}

            <Sticky stickyPosition={StickyPositionType.Footer}>
                <div style={invoiceDialogLayout}>
                    <Stack horizontal horizontalAlign="center" className="margin-top-xs">
                        <Stack.Item>

                            <DefaultButton onClick={props.onBack} text="Back" />
                            {
                                sofsInvoiceData.length > 0 && <PrimaryButton
                                    text="Generate Invoice"
                                    onClick={handleGenerateInvoice}
                                    className="btn-primary"
                                />
                            }
                            <DefaultButton onClick={props.onClose} text="Close" />

                        </Stack.Item>
                    </Stack>
                </div>
            </Sticky>
        </div>
    );
};

export default GenerateInvoice;

interface IGenerateInvoice {
    onBack: () => void;
    onClose: () => void;
    invoiceData: IFinalResult
}