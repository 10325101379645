import { useEffect, useState } from "react"
import Loader from "../common/Loader"
import CommentsForm from "./CommentsPage"
import { fetchComments } from "../../../store/admin/Comments/actions"
import { useDispatch, useSelector } from "react-redux"
import { fetchCommentsType } from "../../../store/admin/Comments/actions"
import { IComments } from "../../../store/admin/Comments/reducer"
import { RootState } from "../../../store"
import { ICommentsType } from "@solarforschools/sfs-core/dist/comment/types"
import { IDropdownOption } from "@fluentui/react"
import { sortObj } from "./Helpers"

const Comments = () => {

    const dispatch = useDispatch()
    const { commentsType, commentFilter }: IComments = useSelector<RootState, IComments>((state: RootState) => state.web.comments)
    const [options, setOptions] = useState<IDropdownOption[]>([])

    // Initial fetch comments details
    useEffect(() => {
        dispatch(fetchComments(commentFilter))
        dispatch(fetchCommentsType())
    }, [])

    useEffect(() => {
        let res = buildOptions(commentsType)
        setOptions(res)
    }, [commentsType])

    // Build Dropdown Options
    function buildOptions(options: ICommentsType) {
        if (!options.types || options.types.length === 0) return []
        const res = options.types.filter((type: any) => {
            if (type === null || type === "undefined") {
                return false
            } return true
        })
        let finalOptions: { key: string, text: string }[] = [];
        for (let i = 0; i < res.length; i++) {
            const obj = {
                key: res[i],
                text: res[i].toLowerCase()
            }
            finalOptions.push(obj)
        }
        finalOptions.sort(sortObj)
        finalOptions = [{ key: "all", text: "all" }, ...finalOptions]
        return finalOptions
    }


    return (<>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                position: "relative",
                scrollBehavior: "smooth",
            }}
        >
            <Loader />
            <div>
                <header className="text-center">
                    <h1>Comments</h1>
                </header>
            </div>
            {
                options?.length !== 0 ? (
                    <CommentsForm
                        options={options}
                    />) : <></>
            }
        </div>
    </>)
}

export default Comments