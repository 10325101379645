import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";

interface IProps {
    title: string;
}

const DesignsCardContent = (props: IProps) => {
    //designsData
    const { designsData } = useContext(CardContext) as CardContextType
    const { title } = props
    const [designsCount, setDesignsCount] = useState(designsData?.count)
    // const [status, setStatus] = useState({ prevMonth: "", isEqual: "" });

    const getStatus = () => {
        if (designsData !== undefined) {
            setDesignsCount(designsData.count)
        }

        // if (designsData !== undefined) {
        //     if (designsData.lastSixMonths[4].count > designsData.lastSixMonths[5].count) {
        //         setStatus({ ...status, prevMonth: "high", isEqual: "" });
        //     } else if (designsData.lastSixMonths[4].count < designsData.lastSixMonths[5].count) {
        //         setStatus({ ...status, prevMonth: "low", isEqual: "" });
        //     } else {
        //         setStatus({ ...status, isEqual: "yes", prevMonth: "" });
        //     }
        // }
    }

    useEffect(() => {
        getStatus()
    }, [designsData])



    //const countColor = (status?.isEqual === "yes") ? { color: "#FFCB2F" } : ((status?.prevMonth === "high") ? { color: "red" } : { color: "green" })

    return (<>
        <div className="card-content">
            <div className="card-content--count" style={{ color: "#243f61" }}>
                {designsCount !== undefined && new Intl.NumberFormat('en-GB').format(designsCount)}
            </div>
            {/* <div>
                {status?.isEqual === "yes"
                    ? <RightTriangleIcon fontSize="2rem" />
                    : status?.prevMonth === "high"
                        ? <DownTriangleIcon fontSize="2rem" />
                        : <UpTriangleIcon fontSize="2rem" />}
            </div> */}
        </div>
        <div className="card-content-title">
            {title}
        </div>
    </>)
}

export default DesignsCardContent