import { Checkbox, ChoiceGroup, Dropdown, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import { getBonds, setBondsFilter } from '../../../../store/admin/funder/bond/action';
import { RootState } from '../../../../store';
import { StringMap } from '../../common/types';
import { dropdownStyles } from '../../common/common';
import { bondHolderStatus, bondTypes } from './BondHelper';
import { pyamentPreferencesTypes } from '../helper';
import BondOfferPicker from '../../common/BondOfferPicker';


const BondFilters = (props: IBondFilterProps) => {
  const dispatch = useDispatch()
  const { bondFilter, bondOffers, auditLogs, periods }: IBondState = useSelector<RootState, IBondState>((state) => state.web.bonds)
  const setFilters = (filters: StringMap) => dispatch(setBondsFilter(filters));

  const handleOnChange = async (
    key: string,
    value?: any
  ) => {
    let params = { ...bondFilter, page: 1, [key]: value };
    switch (key) {
      case 'selectedBondOffer':
        params = { ...params, bondOffer: value[0]?.key }
        break;
      default:
        params = { ...bondFilter, page: 1, [key]: value };
        break;
    }

    setFilters(params);
    handleUpdateFilters(params)
  }

  const handleUpdateFilters = useCallback(
    (filters: any) => {
      dispatch(getBonds(filters));
    },
    []
  );



  const { certNumber, email, type, status, paymentPreference, bondOffer, isTestBond, period } = bondFilter
  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{ minWidth: "900px", maxWidth: "1300px" }}
    >
      <div className="ms-Grid-row">
        <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
          <BondOfferPicker filters={{}} onChange={handleOnChange} />
        </div>
        <TextField
          label="Certificate Number"
          placeholder="Enter Certificate Number"
          onChange={(e, value) => handleOnChange('certNumber', value || '')}
          className="ms-Grid-col ms-lg3"
          value={certNumber! as unknown as string} />
        <TextField
          label="Bond Holder"
          placeholder="Enter UserName"
          onChange={(e, value) => handleOnChange('email', value || '')}
          className="ms-Grid-col ms-lg3"
          value={email!} />
        <Dropdown
          dropdownWidth='auto'
          label="Payment Preferences"
          onChange={(e, item) => handleOnChange("paymentPreference", item?.key?.toString())}
          selectedKey={paymentPreference || 'all'}
          options={pyamentPreferencesTypes}
          styles={dropdownStyles}
          className="inlineflex ms-Grid-col ms-lg3"
        />
      </div>
      <div className="ms-Grid-row">
        {/* <Dropdown
          dropdownWidth='auto'
          label="AudiLogs"
          onChange={(e, item) => handleOnChange("auditLog", item?.key?.toString())}
          selectedKey={auditLog || 'all'}
          options={auditLogs}
          styles={dropdownStyles}
          className="inlineflex ms-Grid-col ms-lg3"
        /> */}
        <Dropdown
          dropdownWidth='auto'
          label="Periods"
          onChange={(e, item) => handleOnChange("period", item?.key?.toString())}
          selectedKey={period || 'all'}
          options={periods}
          styles={dropdownStyles}
          className="inlineflex ms-Grid-col ms-lg2"
        />
        <ChoiceGroup
          className="inlineflex ms-Grid-col ms-lg3"
          label="Type"
          selectedKey={type || "all"}
          onChange={(e, option) => handleOnChange("type", option?.key)}
          options={bondTypes}
        />
        <ChoiceGroup
          className="inlineflex ms-Grid-col ms-lg4"
          label="Status"
          selectedKey={status || "all"}
          onChange={(e, option) => handleOnChange("status", option?.key)}
          options={bondHolderStatus}
        />
        <Checkbox
          label="Test Bonds"
          checked={isTestBond === true}
          onChange={(e, value) => handleOnChange('isTestBond', value)}
          className="ms-Grid-col ms-lg2 margin-top-lg"
        />
      </div>
    </div>
  );
}

interface IBondFilterProps {

}

export default BondFilters;

