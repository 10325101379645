import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";

import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { Languages } from './../common/common';
import { validateBlogApi } from './../../../store/client/blog';
import { IBlog } from "@solarforschools/sfs-core/dist/blog/types";

export const getGeneralFields = (options: StringMap): IField[] => {
  return [
    {
      label: "Title",
      key: "title",
      type: "string",
      extras: { required: true },
      placeholder: "Enter Title",
      className: 'ms-Grid-col ms-lg4'
    },
    {
      label: "Slug",
      key: "slug",
      type: "string",
      extras: { required: true, ...options?.blog?._id ? { disabled: true } : {} },
      placeholder: "Enter Slug",
      className: 'ms-Grid-col ms-lg4'
    },
    {
      label: "Date",
      key: "date",
      type: "date",
      extras: { required: true },
      placeholder: "Date",
      className: 'ms-Grid-col ms-lg2'
    }, {
      label: "Language",
      key: "lang",
      type: "dropdown",
      extras: { required: true },
      options: Languages,
      className: 'ms-Grid-col ms-lg1'
    },
    {
      label: "Live",
      key: "live",
      type: "checkbox",
      className: 'ms-Grid-col ms-lg1 margin-top-lg'
    },
    {
      label: "Keywords",
      key: "keywords",
      type: "string",
      extras: { required: true },
      placeholder: "Enter Key words",
      className: 'ms-Grid-col ms-lg3'
    },
  ];
}

export const getBlogColumn = (params: {
  actions: {
    icon: string;
    ariaLabel?: string;
    onClick: (task: any) => any;
  }[];
}): IColumn[] => {
  return [
    {
      key: "title",
      name: "Title",
      fieldName: "title",
      minWidth: 20,
      maxWidth: 500,
      ...isSortable,
      onRender: (item: IBlog) => {
        return (
          <TooltipHost content={item.title} closeDelay={500}>
            {item.title}
          </TooltipHost>
        );
      },
    },
    {
      name: "Date Published",
      key: "date",
      fieldName: "date",
      minWidth: 20,
      maxWidth: 180,
      ...isSortable,
      onRender: (item: IBlog) => {
        return (
          <TooltipHost
            content={new Date(item.date).toDateString()}
            closeDelay={500}
          >
            {new Date(item.date).toDateString()}
          </TooltipHost>
        );
      },
    },
    {
      name: "Live",
      key: "live",
      fieldName: "live",
      minWidth: 50,
      maxWidth: 100,
      ...isSortable,
      onRender: (item: IBlog) => {
        return (
          <TooltipHost content={item.live ? "True" : "False"} closeDelay={500}>
            {item.live ? "True" : "False"}
          </TooltipHost>
        );
      },
    },
    {
      name: "Region",
      key: "country",
      fieldName: "country",
      minWidth: 60,
      maxWidth: 100,
      ...isSortable,
      onRender: (item: IBlog) => {
        return (
          <TooltipHost content={item.country.toString()} closeDelay={500}>
            {item.country.toString()}
          </TooltipHost>
        );
      },
    },
    {
      name: "Language",
      key: "lang",
      fieldName: "lang",
      minWidth: 60,
      maxWidth: 70,
      ...isSortable,
      ariaLabel:"Language",
      onRender: (item: IBlog) => {
        return (
          <TooltipHost content={item.lang} closeDelay={500}>
            {item.lang}
          </TooltipHost>
        );
      },
    },
    {
      name: "Category",
      key: "categorySlugs",
      fieldName: "categorySlugs",
      minWidth: 100,
      maxWidth: 250,
      ...isSortable,
      onRender: (item: IBlog) => {
        return (
          <TooltipHost content={item.categorySlugs.toString()} closeDelay={500}>
            {item.categorySlugs.toString()}
          </TooltipHost>
        );
      },
    },
    {
      key: "action",
      name: "Action",
      fieldName: "-",
      ariaLabel: "Action",
      minWidth: 150,
      maxWidth: 250,
      onRender: (item: IBlog) => {
        return (
          <div className="action-icons-container">
            {params.actions.map(
              (action: {
                icon: string | undefined;
                ariaLabel?: string;
                onClick: (arg0: any) => void;
              }) => {
                return (
                  <FontIcon
                    iconName={action.icon}
                    aria-label={action.ariaLabel || ""}
                    onClick={() => action.onClick(item)}
                  />
                );
              }
            )}
          </div>
        );
      },
    },
  ];
};

export const validateBlog = async (blog: IBlog) => {
  let errors: StringMap = {};
  const { categorySlugs, country, shortSummary, description } = { ...blog } || {}
  const fields = ['title', 'slug', 'lang', 'country', 'keywords', 'shortSummary', 'description', 'date'];
  for (const field of fields) {
    if (!blog[field as keyof IBlog]) errors[`${field}`] = requiredMessage
  }
  if (categorySlugs?.length === 0) errors['categorySlugs'] = requiredMessage;
  if (country?.length === 0) errors['country'] = requiredMessage;
  if (shortSummary?.length < 20) errors['shortSummary'] = 'Min value 20 character';
  if (description?.length < 20) errors['description'] = 'Min value 250 character';
  if (!Object.keys(errors).length) {
    errors = await validateBlogApi(blog) as unknown as StringMap
  }
  return errors;
}

export const shortSummaryOptions = { minHeight: '100px', maxHeight: '100px', hideIcons: ['side-by-side', 'separator-2', 'separator-1', 'fullscreen', 'guide', 'image', 'link'], autofocus: true, uniqueId: "demo", }
export const descriptionOptions = { hideIcons: ['side-by-side', 'separator-2', 'separator-1', 'fullscreen', 'guide'], autofocus: true, uniqueId: "demo1", }

export const initBlog: IBlog = {
  title: "",
  slug: "",
  lang: "",
  country: [],
  keywords: "",
  shortSummary: "",
  description: "",
  date: new Date(),
  live: false,
  isDeleted: false,
  categorySlugs: [],
  images: [],
  textColor: "",
  site: []
}