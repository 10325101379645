import { ComboBox, DefaultButton, IComboBoxOption, IPersonaProps, Pivot, PivotItem, PrimaryButton, Stack, TextField, ActionButton, Label } from '@fluentui/react';
import { IProject, IProjectManager } from '@solarforschools/sfs-core/dist/project/types';
import _ from 'lodash';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import "office-ui-fabric-react/dist/css/fabric.css";
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../store';
import { saveProject, unlinkProjectFolder } from '../../../store/admin/projects/action';
import { IProjectState } from '../../../store/admin/projects/reducer';
import { getUsers } from '../../../store/client';
import DynamicForm from '../../dynamic-form/DynamicForm';
import ModalDialog from '../common/ModalDialog';
import { onRenderPivoteItemLink } from '../common/PivotItemRenderLink';
import { StringMap } from '../common/types';
import { projectFields, projectStageFields, getProjectTabErrors, ProjectTabErrors, validateProject } from './ProjectHelper';
import ProjectSchools from './ProjectSchools';
import ProjectAnalysis from './ProjectAnalysis';
import { IProjectFolder, IProjectFolderLink } from '@solarforschools/sfs-core/dist/projectFolder/types';
import openProjectFolderModal from '../common/SelectProjectfolderDialog';
import { isLoading } from '../../../store/admin/action';
import { toast } from 'react-toastify';

import { registerIcons } from '@fluentui/react/lib/Styling';
import { getListItemAPi } from '../../../store/client/projectFolder';
import OpenCreateProjectFolderModal from '../common/CreateProjectfolderDialog';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { projectNameChange } from './helper';
registerIcons({
  icons: {
    'folder-link': <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.25 14.5a3.75 3.75 0 0 1 .202 7.495l-.199.005-1 .005a.75.75 0 0 1-.108-1.493l.102-.007 1.003-.005a2.25 2.25 0 0 0 .154-4.495L18.25 16h-1a.75.75 0 0 1-.102-1.493l.102-.007h1Zm-4.5 0a.75.75 0 0 1 .102 1.493L13.75 16h-1a2.25 2.25 0 0 0-.154 4.495l.154.005h1a.75.75 0 0 1 .102 1.493L13.75 22h-1a3.75 3.75 0 0 1-.2-7.495l.2-.005h1Zm6-8a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v6.585a4.742 4.742 0 0 0-3.75-1.835h-5.5a4.75 4.75 0 0 0-4.417 6.501L4.25 20a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5h5.929Zm-1.5 11a.75.75 0 0 1 .102 1.493L18.25 19h-5.5a.75.75 0 0 1-.102-1.493l.102-.007h5.5ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z" fill="rgb(36, 63, 97)" /></svg>,
    'folder-add': <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 11a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm0 2-.09.007a.5.5 0 0 0-.402.402L17 13.5V16L14.498 16l-.09.008a.5.5 0 0 0-.402.402l-.008.09.008.09a.5.5 0 0 0 .402.402l.09.008H17v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008.09-.008a.5.5 0 0 0 .402-.402l.008-.09V17l2.504.001.09-.008a.5.5 0 0 0 .402-.402l.008-.09-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H18v-2.5l-.008-.09a.5.5 0 0 0-.402-.403L17.5 13Zm2.25-6.5a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v3.06A6.5 6.5 0 0 0 12.023 20H4.25a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5h5.929ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z" fill="rgb(36, 63, 97)" /></svg>
  },
});

export const openProjectDialog = (props: OpenProjectDialogProps) => {
  return new Promise<IProject | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: IProject) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={props.title || projectNameChange.Project}
          onDismiss={() => callback(undefined)}
        >
          <ProjectDialog
            {...props}
            onCancel={() => callback(undefined)}
            onSave={project => callback(project)}
          />
        </ModalDialog>
      </Provider>
      ,
      mountTarget
    )
  })
}

const ProjectDialog = (props: ProjectDialogProps) => {
  const dispatch = useDispatch();
  const [project, setProject] = useState<IProject>(_.cloneDeep(props.project) as IProject)
  const [errors, setErrors] = useState<StringMap>({});
  const [tabErrors, setTabErrors] = useState<ProjectTabErrors>({ general: false, schools: false, analysis: false });
  const { projectManagers, projectMembers } = useSelector<RootState, IProjectState>(state => state.web.project);

  const handleOnChange = (key: string, item: any) => {
    let updated = _.cloneDeep(project);
    switch (key) {
      case "projectMembers":
        let data: IProjectManager[] = project.projectMembers || [];
        if (item?.selected) {
          if (!data?.find((s) => s === item?.key)) {
            const obj = projectMembers.find(m => m.email === item.key)
            data.push(obj)
          }
        } else {
          data = data?.filter((s) => s.email !== item?.key) as IProjectManager[];
        }
        updated = _.set(updated, key, data);
        break
      case "projectManagers":
        let pms: IProjectManager[] = project.projectManagers || [];
        if (item?.selected) {
          if (!pms?.find((s) => s === item?.key)) {
            const obj = projectManagers.find(m => m.email === item.key)
            pms.push(obj)
          }
        } else {
          pms = pms?.filter((s) => s.email !== item?.key) as IProjectManager[];
        }
        updated = _.set(updated, key, pms);
        break;

      default:
        updated = _.set(updated, key, item);
        break;
    }
    if (key.includes('activeStage')) {
      _.set(updated, 'activeStage.date', new Date());
    }
    setProject(updated);
  }

  const handleGetUsers = (query: any) => async (
    filter: string,
    selectedItems?: IPersonaProps[],
  ): Promise<IPersonaProps[]> => {
    const users = await getUsers({ userName: filter, ...query });
    const selectedMap: { [key: string]: boolean } = {};
    selectedItems?.forEach(i => selectedMap[i.key!] = true);
    return users
      .filter(u => !selectedMap[u.userEmail])
      .map(user => {
        return {
          key: user.userEmail,
          text: user.userName,
          secondaryText: user.userEmail,
        }
      })
  }

  const updateProject = (key: string, value: any) => {
    const updated = _.cloneDeep(project)
    const data = _.set(updated, key, value);
    setProject(data);
  }

  const handleChangeProjectManagers = (property: string) => (selected?: IPersonaProps[]) => {
    if (!selected) return;
    const users = selected?.map(user => {
      const pm: Partial<IProjectManager> = {
        name: user.text!,
        email: user.secondaryText!,
      }
      return pm as IProjectManager;
    });
    updateProject(property, users);
  }

  const handleSave = async () => {
    const errors = await validateProject(project);
    const tabErrors = await getProjectTabErrors(errors);
    setErrors(errors);
    setTabErrors(tabErrors);
    if (Object.keys(errors).length) {
      return;
    }
    let _p = _.cloneDeep(project)
    _p.schools = _p.schools.map(s => ({ _id: s._id, name: s.name, slug: s.slug, tasks: [] }))
    const saved = await dispatch(saveProject(_p as IProject));
    props.onSave(saved as unknown as IProject);
  }

  const pmToPersona = (pm: IProjectManager): IPersonaProps => ({
    key: pm.email,
    text: pm.name,
    secondaryText: pm.email
  })

  const handlePFSelectClick = async () => {
    const folder: IProjectFolder = {
      folderType: "Project",
      region: project.region?.toUpperCase() || "GB",
      slug: project.slug,
      sfsId: 1,
      name: project.name
    };
    if (project.activeStage?.name && project.activeStage.active) folder.stage = project.activeStage?.name;
    const pf = await openProjectFolderModal({ folder })
    if (pf) {
      let p: IProject = _.cloneDeep(project);
      p = _.set({ ...p }, "projectFolder", pf);
      setProject(p)
    }
  }

  const handleUnlikFolder = async () => {

    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Unlink Project Folder",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to Unlink?`,
      },
    });
    if (confirm) dispatch(unlinkProjectFolder(project))
  }

  const openProjectFolder = async (data: IProjectFolderLink) => {
    try {
      dispatch(isLoading(true))
      const listItem: any = await getListItemAPi({ driveItemId: data.driveItemId, driveId: data.driveId })
      if (listItem?.webUrl) {
        const win = window.open(listItem?.webUrl, '_blank');
        if (win != null) {
          win.focus();
        }
      }
    } catch (error) {
      console.log(error)
      toast.error('Something happend wrong')
    }
    finally {
      dispatch(isLoading(false))
    }
  }

  const handleCreateProjectFolder = async () => {
    const folder: IProjectFolder = {
      name: project.name,
      region: project.region?.toUpperCase() || 'GB',
      folderType: "Project",
      slug: project.slug,
      sfsId: 1,
    }
    if (project.activeStage?.name) folder.stage = project.activeStage?.name
    const pf = await OpenCreateProjectFolderModal({ folder })
    if (pf) {
      let p: IProject = _.cloneDeep(project);
      p = _.set({ ...p }, "projectFolder", pf);
      setProject(p)
    }
  }

  return (
    <div>
      <Pivot linkSize="large">
        <PivotItem
          key="general"
          headerText="General"
          onRenderItemLink={onRenderPivoteItemLink(tabErrors.general)}
        >
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <DynamicForm
                fields={projectFields}
                errors={errors}
                data={project}
                onChange={handleOnChange}
              />
              <ComboBox
                label={projectNameChange['Project Managers']}
                selectedKey={project?.projectManagers.map((m: { email: any; }) => m.email) || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                  projectManagers.map(s => { return { key: s.email, text: `${s.name}(${s.email})` } }) as IComboBoxOption[]
                }
                onChange={(e, item) => handleOnChange("projectManagers", item)}
                className="ms-Grid-col ms-lg12"
                errorMessage={errors?.projectManagers}
              />
              <ComboBox
                label={projectNameChange["Project Members"]}
                selectedKey={project?.projectMembers.map((m: { email: any; }) => m.email) || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                  projectMembers.map(s => { return { key: s.email, text: `${s.name}(${s.email})` } }) as IComboBoxOption[]
                }
                onChange={(e, item) => handleOnChange("projectMembers", item)}
                className="ms-Grid-col ms-lg12"
                errorMessage={errors?.projectMembers}
              />
              {/*<Label
                style={{ color: errors.projectManagers ? 'rgb(164, 38, 44)' : undefined }}
                className='ms-Grid-col ms-lg12'
              >
                {errors.projectManagers || 'Project Managers'}
                 <NormalPeoplePicker
                  styles={{ root: { marginTop: 5 } }}
                  selectedItems={project.projectManagers?.map(pmToPersona)}
                  onResolveSuggestions={handleGetUsers({ permissions: 'projectManager' })}
                  getTextFromItem={persona => persona.text as string}
                  selectionAriaLabel={'Selected managers'}
                  onChange={handleChangeProjectManagers('projectManagers')}
                /> 
              </Label>
              {/* <Label className='ms-Grid-col ms-lg12'>
                Project Members
                <NormalPeoplePicker
                  styles={{ root: { marginTop: 5 } }}
                  selectedItems={project.projectMembers?.map(pmToPersona)}
                  onResolveSuggestions={handleGetUsers({})}
                  getTextFromItem={persona => persona.text as string}
                  selectionAriaLabel={'Selected members'}
                // onChange={handleChangeProjectManagers('projectMembers')}
                />
              </Label> */}
              <DynamicForm
                fields={projectStageFields}
                errors={errors}
                data={project}
                onChange={handleOnChange}
              />

              <div className="ms-Grid-col ms-lg2" style={{ cursor: "pointer" }}>
                <Label style={{ paddingBottom: '5px' }}>
                  {projectNameChange["Project Folder"]}
                </Label>
                {project._id && project.projectFolder?.listItemId && (
                  <span style={{ border: '1px solid', padding: '5px 10px' }}>
                    <ActionButton
                      title={'Click to open folder in browser'}
                      allowDisabledFocus
                      style={{ color: "blue" }}
                      onClick={() => openProjectFolder(project.projectFolder!)}
                    >
                      {project.projectFolder?.name?.substring(0, 30) || 'Open Project Folder'}
                    </ActionButton>
                  </span>
                ) || <span style={{ border: '1px solid', padding: '5px 10px', height: "32px", display: 'block' }}></span>}
              </div>
              <div className="ms-Grid-col ms-lg2" style={{ cursor: "pointer", marginTop: '30px' }}>
                <ActionButton
                  title={`Select ${projectNameChange['Project']} Folder`}
                  iconProps={{ iconName: "folder-link" }}
                  onClick={handlePFSelectClick}
                  style={{ marginTop: '-10px' }}
                >
                </ActionButton>
                {project._id && project.projectFolder?.driveId && (
                  <>
                    <ActionButton
                      title='Unlink folder'
                      iconProps={{ iconName: "FabricUnsyncFolder", style: { color: 'gray', fontSize: 20 } }}
                      onClick={handleUnlikFolder}
                      style={{ marginTop: '-12px', color: 'black' }}>

                    </ActionButton>
                  </>
                )}
                {project._id && !project.projectFolder?.listItemId && (
                  <ActionButton
                    title={`Create ${projectNameChange['Project']} Folder`}
                    iconProps={{ iconName: "folder-add" }}
                    onClick={handleCreateProjectFolder}
                    style={{ marginTop: '-10px' }}
                  />
                )}
              </div>
            </div>
            <div className='ms-Grid-row'>
              <TextField
                label="Description"
                placeholder={projectNameChange["Project description"]}
                onChange={(e: any, value: any) => handleOnChange('description', value)}
                multiline={true}
                rows={2}
                className='ms-Grid-col ms-lg12'
                value={project?.description!} />
            </div>
          </div>
        </PivotItem>
        <PivotItem
          key="schools"
          headerText="Schools"
          onRenderItemLink={onRenderPivoteItemLink(tabErrors.schools)}
        >
          <ProjectSchools project={project} onUpdateProject={updateProject} />
        </PivotItem>
        <PivotItem
          key="analysis"
          headerText="Analysis"
          onRenderItemLink={onRenderPivoteItemLink(tabErrors.analysis)}
        >
          <ProjectAnalysis project={project} onChangeProject={setProject} />
        </PivotItem>
        {/* <PivotItem key="tasks" headerText="Tasks">
          <ProjectTasks project={project} />
        </PivotItem> */}
      </Pivot>
      <Stack horizontal horizontalAlign="center" verticalAlign='center'>
        <PrimaryButton
          text={"Save"}
          onClick={handleSave}
          className="btn-primary"
        />
        <DefaultButton onClick={() => props.onCancel()} text="Cancel" />
      </Stack>
    </div>
  )
}

interface ProjectDialogProps {
  project: Partial<IProject>
  onSave: (project: IProject) => void
  onCancel: () => void;
}


interface OpenProjectDialogProps {
  project: Partial<IProject>
  title?: string
}

export default ProjectDialog;
