import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Checkbox, ChoiceGroup, Dropdown, Label, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider, useDispatch, useSelector } from 'react-redux';
import ModalDialog from '../../common/ModalDialog';
import ReactDOM from 'react-dom';
import store, { RootState } from '../../../../store';
import { TransactionType } from '@solarforschools/sfs-core/dist/funder/transaction/types';
import { IBondTransationState } from '../../../../store/admin/funder/transaction/reducer';
import { dropdownStyles } from '../../common/common';
import { reInvestTransaction } from '../../../../store/admin/funder/transaction/action';
import { toast } from 'react-toastify';
import { bondTypes } from '../bond/BondHelper';



export const OpenReinvestModal = (props: { params: any }) => {
	return new Promise((resolve, reject) => {
		const mountTarget = document.createElement('div')
		document.body.appendChild(mountTarget)
		const callback = (result?: any) => {
			resolve(result)
			ReactDOM.unmountComponentAtNode(mountTarget)
			mountTarget.remove()
		}
		ReactDOM.render(
			<Provider store={store}>
				<ModalDialog
					isModalOpen={true}
					title={"Reinvest"}
					onDismiss={() => callback(undefined)}
					containerClassName={"modal-size-sm"}
				>
					<ReinvestModal {...props} onSave={(data) => callback(data)} onCancel={() => callback(undefined)} />
				</ModalDialog>
			</Provider>,
			mountTarget
		)
	})
}


export const ReinvestModal = ({
	params,
	onCancel,
	onSave,
}: Props) => {
	const dispatch = useDispatch()

	const [payload, setPayload] = useState<any>({
		...params,
		bondType: "non-ISA",
	})
	const { bondOffers, transactionFilter }: IBondTransationState = useSelector<RootState, IBondTransationState>((state: RootState) => state.web.transaction)



	const handleProcess = async () => {
		if (payload.bondOffer && (payload.interest || payload.repayment)) {
			dispatch(reInvestTransaction(payload))
		}
		// onSave({ success: true });
	};
	const handleCancel = () => {
		onCancel()
	}


	const dataOffers = [];
	for (const o of bondOffers) {
		if (transactionFilter.isTestTransaction && o.isTestOffer) {
			dataOffers.push(o)
		} else if (!transactionFilter.isTestTransaction && !o.isTestOffer) {
			dataOffers.push(o)
		}
	}


	const handleOnChange = async (value: string | boolean, type?: string) => {
		switch (type) {
			case "interest":
				setPayload({ ...payload, [type]: value })
				break;
			case "repayment":
				setPayload({ ...payload, [type]: value })
				break;
			case "bondType":
				setPayload({ ...payload, [type]: value })
				break;
			default:
				setPayload({ ...payload, bondOffer: value })
				break;
		}

	}
	return (
		<div className="edit-record">
			<div className='ms-Grid-row'>
				<div className='margin-top-md'>
					<p className='ms-Grid-col ms-lg12' >Are you sure to reinvest?</p>
					<ul className='ms-Grid-col ms-lg12' >
						<li>All transactions belong to specific bondholder will combine into one new bond for selected period{transactionFilter.bondOfferId && transactionFilter.bondOfferId !== "all" && " and selected bondoffer in filter section"}.</li>
						<li>If want to comobine both interest and repayment transactions to one bond select below options accordingly</li>
						<li>New bonds belongs to which offer, select bondoffer accordingly</li>
						<li>Bondoffer batch amount will automatically update</li>
					</ul>
				</div>
			</div>
			<div className='ms-Grid-row'>
				<div className="ms-Grid-col ms-lg12">
					<Label>Interest Transactions</Label>
					<Checkbox
						label=""
						checked={payload.interest}
						onChange={(e, value) => handleOnChange(value!, 'interest')}
					/>
				</div>
			</div>
			<div className='ms-Grid-row'>
				<div className="ms-Grid-col ms-lg12">
					<Label>Repayment Transactions</Label>
					<Checkbox
						label=""
						checked={payload.repayment}
						onChange={(e, value) => handleOnChange(value!, 'repayment')}
					/>
				</div>
			</div>
			<div className='ms-Grid-row'>
				<div className="ms-Grid-col ms-lg12">

				</div>
				<ChoiceGroup
					className="inlineflex ms-Grid-col ms-lg12"
					label="Type"
					selectedKey={payload.bondType || "non-ISA"}
					onChange={(e, option) => handleOnChange(option?.key!, "bondType",)}
					options={bondTypes.filter(b => b.key !== 'all')}
				/>
			</div>
			<div className='ms-Grid-row'>
				<Dropdown
					dropdownWidth='auto'
					label="Bond Offer"
					onChange={(e, item) => handleOnChange(item?.key?.toString()!)}
					selectedKey={payload.bondOffer || payload.bondOfferId}
					options={dataOffers.filter(b => b.key !== 'all')}
					styles={dropdownStyles}
					className="inlineflex ms-Grid-col ms-lg6"
				/>
			</div>
			<Sticky stickyPosition={StickyPositionType.Footer}>
				<Stack horizontal horizontalAlign="center">
					<Stack.Item style={{ marginTop: "15px" }}>
						{payload.bondOffer && (payload.interest || payload.repayment) &&
							<PrimaryButton
								text={'Process'}
								onClick={handleProcess}
								className="btn-primary"
							/>
						}
						<DefaultButton onClick={handleCancel} text="Cancel" />
					</Stack.Item>
				</Stack>
			</Sticky>
		</div>
	);
};

interface OwnProps {
	params: {
		transactionType: TransactionType,
		period: string,
		transactionId?: string,
		bondOfferId?: string
	};
	onCancel: () => void;
	onSave: (params: any) => void;
}

type Props = OwnProps;
