import { Dropdown, TextField } from '@fluentui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { changeFilters, getProjects, setProjectsFilter } from '../../../store/admin/projects/action';
import { IProjectState } from '../../../store/admin/projects/reducer';
import { Regions } from '../common/common';
import { StringMap } from '../common/types';
import { stages } from '../schools/common';
import { stageStatuses } from '../task/common';
import { projectNameChange } from './helper';
import { IProjectsFilter } from './types';

const ProjectFilters = (props: ProjectFilterProps) => {
  const dispatch = useDispatch()
  const { projectManagers, projectFilter } = useSelector<RootState, IProjectState>(state => state.web.project)
  const setFilters = (filters: StringMap) => dispatch(changeFilters(filters))
  const [selectedStages, setSelectedStages] = useState<string[]>([])
  const stackTokens = { childrenGap: 30 };

  const handleChange = (key: string, value: string | undefined) => {
    let newFilters: IProjectsFilter = { ...projectFilter, page: 1 }
    switch (key) {
      // case "filterBy":
      //   let filterBy = [...filters.filterBy!];
      //   if (!filterBy?.find((s) => s === value)) {
      //     filterBy.push(value);
      //   } else {
      //     filterBy = filterBy?.filter((s) => s !== value) as string[];
      //   }
      //   newFilters = { ...filters, filterBy };
      //   break;

      default:
        newFilters = { ...projectFilter, [key]: value }
        break;
    }
    dispatch(setProjectsFilter(newFilters))
    dispatch(getProjects())
  }

  const { name, region, stage, stageStatus, status } = projectFilter

  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{ minWidth: "700px", maxWidth: "1300px" }}
    >
      <div className="ms-Grid-row">
        <TextField
          label="Name"
          placeholder={projectNameChange['Project Name']}
          type='text'
          onChange={(_, value) => handleChange('name', value!)}
          value={name}
          className="ms-Grid-col ms-lg5"
        />
        <Dropdown
          label="Region"
          style={{ minWidth: 100 }}
          selectedKey={region || 'all'}
          options={Regions}
          onChange={(_, option) => handleChange('region', option?.key?.toString() || 'all')}
          className="ms-Grid-col ms-lg2"
        />
        <Dropdown
          label="Stage"
          placeholder="Select"
          style={{ minWidth: 120 }}
          multiSelect={true}
          options={stages.filter((s) => s.key !== "all")}
          onChange={(e, option) => {
            const selectedKeys = option?.selected
              ? [...selectedStages, option.key.toString()]
              : selectedStages.filter(x => x !== option?.key);
            setSelectedStages(selectedKeys)
            handleChange('stage', selectedKeys.join(',') || 'all')
          }}
          className="ms-Grid-col ms-lg3"
        />
        <Dropdown
          label="Stage Active"
          selectedKey={stageStatus ? stageStatus : "all"}
          options={stageStatuses}
          onChange={(e, item) => handleChange("stageStatus", item?.key?.toString())}
          className="ms-Grid-col ms-lg2"
        />
        <Dropdown
          label={projectNameChange['Project Managers']}
          style={{ minWidth: 200 }}
          selectedKey={projectFilter.projectManagers || 'all'}
          options={[
            { key: 'all', text: 'All' },
            ...projectManagers.map(x => ({ key: x.email, text: `${x.name} (${x.email})` }))
          ]}
          onChange={(_, option) => handleChange('projectManagers', option?.key?.toString() || 'all')}
          className="ms-Grid-col ms-lg6"
        />
        <Dropdown
          label="Status"
          selectedKey={status || 'all'}
          options={[
            { key: 'all', text: 'All' },
            { key: 'true', text: 'Open' },
            { key: 'false', text: 'Closed' },
          ]}
          onChange={(_, option) => handleChange('status', option?.key?.toString() || 'all')}
          className="ms-Grid-col ms-lg2"
        />
      </div>
    </div>
  );
}

interface ProjectFilterProps {

}

export default ProjectFilters;

