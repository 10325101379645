import { useContext, useEffect, useMemo, useState } from "react";
import { CardContext, CardContextType } from "../../layouts";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from "../../utils/Icons";

interface IProps {
    title: string
}

const RegistrationsCardContent = (props: IProps) => {
    const { registrationData } = useContext(CardContext) as CardContextType
    const { title } = props
    const [registrationCount, setRegistrationCount] = useState(registrationData?.count)
    //const [status, setStatus] = useState({ prevMonth: "", isEqual: "" });

    const getStatus = () => {
        if (registrationData !== undefined) {
            setRegistrationCount(registrationData.count)
        }

        // if (registrationData !== undefined) {
        //     if (registrationData.lastSixMonths[4].count > registrationData.lastSixMonths[5].count) {
        //         setStatus({ ...status, prevMonth: "high", isEqual: "" });
        //     } else if (registrationData.lastSixMonths[4].count < registrationData.lastSixMonths[5].count) {
        //         setStatus({ ...status, prevMonth: "low", isEqual: "" });
        //     } else {
        //         setStatus({ ...status, isEqual: "yes", prevMonth: "" });
        //     }

        // }
    }

    useEffect(() => {
        getStatus()
    }, [registrationData])

    //const countColor = (status?.isEqual === "yes") ? { color: "#FFCB2F" } : ((status?.prevMonth === "high") ? { color: "red" } : { color: "green" })

    return (<>
        <div className="card-content">
            <div className="card-content--count" style={{ color: "#243f61" }}>
                {registrationCount !== undefined && new Intl.NumberFormat('en-GB').format(registrationCount)}

            </div>
            {/* <div>
                {status?.isEqual === "yes"
                    ? <RightTriangleIcon fontSize="2rem" />
                    : status?.prevMonth === "high"
                        ? <DownTriangleIcon fontSize="2rem" />
                        : <UpTriangleIcon fontSize="2rem" />}
            </div> */}
        </div>
        <div className="card-content-title">
            {title}
        </div>
    </>)
}

export default RegistrationsCardContent