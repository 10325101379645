import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IOpportunity, ISubProduct } from "@solarforschools/sfs-core/dist/opportunity/type";
import { IProduct } from "@solarforschools/sfs-core/dist/product/type";
import { IProject } from "@solarforschools/sfs-core/dist/project/types";
import moment from "moment";
import { numberToCurrencyFormat } from "../../../utils/util";
import { IField } from "../../dynamic-form/DynamicForm";
import { OpportunityStatus, requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { ITotals } from "@solarforschools/sfs-core";

export const getOpportunityFields = (options: StringMap): IField[] => [
  {
    label: "Probability",
    key: "probability",
    type: "number",
    step: "1",
    extras: { required: true },
    placeholder: "Propbability",
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: "Size[kwp]",
    key: "size",
    type: "number",
    step: "1",
    extras: { required: true },
    placeholder: "Size in kwp",
    className: 'ms-Grid-col ms-lg3',
  }, {
    label: `Investment [${options.products[0]?.currency || '-'}]`,
    key: "investment",
    type: "number",
    step: "1",
    extras: { required: true },
    placeholder: "",
    className: 'ms-Grid-col ms-lg3',
  }
];

export const getOpportunityFields1 = (options: StringMap): IField[] => [
  {
    label: `Students`,
    key: "students",
    type: "number",
    step: "1",
    extras: { required: true },
    placeholder: "",
    className: 'ms-Grid-col ms-lg3',
  },
  {
    key: 'created.date',
    label: 'Created',
    type: 'date',
    placeholder: 'Pick Created date',
    className: 'ms-Grid-col ms-lg3',
  }, {
    label: "Status",
    key: "status",
    type: "dropdown",
    extras: { required: true },
    options: OpportunityStatus.filter(c => c.key !== 'all') as IDropdownOption[],
    className: 'ms-Grid-col ms-lg3'
  }
];

export const getOpportunityFields2 = (options: StringMap): IField[] => [

  {
    label: `Capital Investment`,
    key: "capitalInvestment",
    type: "number",
    step: "1",
    placeholder: "Capital Investment",
    className: 'ms-Grid-col ms-lg3',
  }
  , {
    label: `PPA`,
    key: "ppa",
    type: "number",
    step: "1",
    placeholder: "PPA",
    className: 'ms-Grid-col ms-lg3',
  }
  , {
    label: `Yr1Saving`,
    key: "yr1Saving",
    type: "number",
    step: "1",
    placeholder: "yr1Savings",
    className: 'ms-Grid-col ms-lg3',
  }
  , {
    label: `Contribution Needed`,
    key: "contributionNeeded",
    type: "number",
    step: "1",
    placeholder: "Contribution Needed",
    className: 'ms-Grid-col ms-lg3',
  }, {
    label: `Mains Rate`,
    key: "mainsRate",
    type: "number",
    step: "1",
    placeholder: "Mains Rate",
    className: 'ms-Grid-col ms-lg2',
  }
];

export const getOpportunityPipeLineFields = (options: StringMap): IField[] => [
  {
    label: "Pipeline",
    key: "pipeline",
    type: "dropdown",
    extras: { required: true },
    options: options.pipelines as IDropdownOption[],
    className: 'ms-Grid-col ms-lg3'
  },
  {
    label: "Pipeline Stage",
    key: "pipelineStage",
    type: "dropdown",
    extras: { required: true },
    options: options.pstages as IDropdownOption[],
    className: 'ms-Grid-col ms-lg3'
  }, {
    ariaLabel: "If you want to link to existing deal then provide that deal id",
    label: `PipeDrive Deal Id`,
    key: "pipedriveDealId",
    type: "number",
    step: "1",
    placeholder: "",
    className: 'ms-Grid-col ms-lg3',
  },
];


export const validateOpportunity = async (opportunity: IOpportunity) => {
  let errors: StringMap = {};
  const { probability, linkedTo, size, investment, status, pipeline, pipelineStage } = opportunity || {}
  if (!size) errors['size'] = requiredMessage;
  if (!status) errors['status'] = requiredMessage;
  if (!probability) errors['probability'] = requiredMessage;
  if (!linkedTo) errors['linkedTo'] = requiredMessage;
  if (!investment) errors['investment'] = requiredMessage;
  if (pipeline && !pipelineStage) errors['pipelineStage'] = requiredMessage;
  if (!opportunity.products || opportunity.products.length === 0) errors['products'] = requiredMessage;
  return errors;
}

export const validateSubProduct = async (product: ISubProduct) => {
  let errors: StringMap = {};
  const { id, revenue, } = product || {}
  if (!id) errors['product'] = requiredMessage;
  if (!revenue) errors['revenue'] = requiredMessage;
  return errors;
}

export const initOpportunity = (data: any, type = 'School', totals: ITotals) => {
  const { slug: linkedTo, students } = data
  const { Students, Investment, SystemSize } = totals || {}
  const opportunity: IOpportunity = {
    country: type === 'Project' ? data.region?.toUpperCase() : data.country,
    linkedTo,
    type,
    probability: 10,
    comment: '',
    products: [],
    investment: Investment,
    created: { date: moment().format('YYYY-MM-DD') },
    updated: { date: moment().format('YYYY-MM-DD') },
    students: students || Students,
    size: SystemSize,
    status: 'Active',
    capitalInvestment: totals.Investment,
    ppa: totals.PPA,
    yr1Saving: totals.BenefitsFirstYear,
    contributionNeeded: totals.Contribution,
    mainsRate: totals.PricePaid
  } as unknown as IOpportunity
  if (type === 'School') opportunity.school = data
  if (type === 'Project') { opportunity.project = data }
  return opportunity
}

export const revenueCalc = (product: ISubProduct, opportunity: IOpportunity, products: IProduct[] = []) => {
  const p = products.find(p => p.productId === product.id) as IProduct
  const { revenueFix = 0, revenueVar = 0 } = p
  let revenue = revenueFix || 0
  let revenueTemp = 0;
  switch (p.scalingType) {
    case 'Investment':
      // revenueVar is percentage for Investment
      revenueTemp = (revenueVar / 100) * (opportunity.investment || 0)
      break;
    case 'Students':
      revenueTemp = revenueVar * (opportunity.students || 0)
      break;
    case 'SystemSize':
      revenueTemp = revenueVar * (opportunity.size || 0)
      break;
    default:
      revenueTemp = revenueVar * (opportunity.size || 0)
      break;
  }
  revenue += revenueTemp || 0
  return Math.round(revenue);
}


export const getOpportunitiesColumns = (params: {
  actions: {
    icon: string,
    title: string,
    onClick: (opportunity: any) => any
  }[]
}): IColumn[] => {
  return [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 200,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.school?.name || item.project?.name} closeDelay={500}>
            {item.school?.name || item.project?.name}
          </TooltipHost>
        );
      },
    },
    {
      key: "type",
      name: "Type",
      fieldName: "type",
      minWidth: 50,
      maxWidth: 100,
      ...isSortable,
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 70,
      maxWidth: 70,
      ...isSortable,
    },
    {
      key: "size",
      name: "Size",
      fieldName: "size",
      minWidth: 70,
      ...isSortable,
    },
    {
      key: "created.date",
      name: "Created",
      fieldName: "created.date",
      minWidth: 80,
      maxWidth: 100,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.created?.date ? moment(item.created.date).format('YYYY-MM-DD') : ''} closeDelay={500}>
            {item.created?.date ? moment(item.created.date).format('YYYY-MM-DD') : ''}
          </TooltipHost>
        );
      },
    },
    {
      key: "updated.date",
      name: "Updated",
      fieldName: "updated.date",
      minWidth: 80,
      maxWidth: 100,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.updated?.date ? moment(item.updated.date).format('YYYY-MM-DD') : ''} closeDelay={500}>
            {item.updated?.date ? moment(item.updated.date).format('YYYY-MM-DD') : ''}
          </TooltipHost>
        );
      },
    },
    {
      key: "revenue",
      name: "Revenue",
      fieldName: "revenue",
      minWidth: 80,
      maxWidth: 100,
      className: "text-right",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={numberToCurrencyFormat(Math.round(item.revenue || 0)).toString()} closeDelay={500}>
            {numberToCurrencyFormat(Math.round(item.revenue || 0))}
          </TooltipHost>
        );
      },
    },
    {
      key: "probability",
      name: "Probability",
      fieldName: "probability",
      minWidth: 80,
      maxWidth: 100,
      data: "number",
      className: "text-right",
      ...isSortable,
    },
    {
      key: "products.id",
      name: "Products",
      fieldName: "products.id",
      minWidth: 90,
      maxWidth: 250,
      onRender: (item) => {
        return (
          <TooltipHost content={item.products.map((p: { id: any; }) => p.id).join()} closeDelay={500}>
            {item.products.map((p: { id: any; }) => p.id).join()}
          </TooltipHost>
        );

      },
    },
    {
      key: "comment",
      name: "Notes",
      fieldName: "comment",
      minWidth: 150,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.comment} closeDelay={500}>
            {item.comment}
          </TooltipHost>
        );
      },
    },
    {
      key: "action",
      name: "Action",
      fieldName: "-",
      minWidth: 70,
      onRender: (taskType) => {
        return (
          <div className="action-icons-container">
            {params.actions.map((action: any) => {
              return <FontIcon
                iconName={action.icon}
                title={action.title || ''}
                onClick={() => action.onClick(taskType)} />;
            })}
          </div>
        );
      },
    },
  ];
}
