import React, { CSSProperties, useState } from "react";
import { toast } from "react-toastify";
import {
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
} from "@fluentui/react/lib/Dropdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { androidstudio } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import axios from "axios";
import { host } from "../../../store/config";

initializeIcons();

const datasets = [
  { key: "project", text: "Project Dataset" },
  { key: "school", text: "School Dataset" },
  { key: "geo", text: "Geo Dataset" },
  { key: "costSummary", text: "Cost Summary Dataset" },
  { key: "calc", text: "Calculator Dataset" },
];

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const Dataset = () => {
  const [results, setResults] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const layoutStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  };

  const getVariables = async (dataset: string) => {
    const reqBody = { dataset };
    const url = `${host}/report/variable`;

    try {
      const response = await axios.post(url, reqBody);
      if (response.status === 200) {
        setResults(response.data.variable);
        return;
      }
      toast.error("Something went wrong");
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const onChange = (
    item?: IDropdownOption<any> | undefined,
    type?: string
  ): void => {
    if (item) {
      setSelectedData(item.key.toString());
      getVariables(item.key.toString());
    }
  };

  const topOfPage = () => {
    return (
      <div className="flex-column-center">
        <div className="pdf-report-title">
          <h1>Dataset</h1>
        </div>
        <div className="form-container">
          <Dropdown
            label="Choose dataset"
            selectedKey={selectedData}
            onChange={(e, item) => onChange(item, "Dataset")}
            placeholder="Choose dataset"
            options={datasets}
            styles={dropdownStyles}
          />
        </div>
      </div>
    );
  };

  const contentOfPage = () => {
    const contentStyle: CSSProperties = {
      height: `calc(100vh - 19vh)`,
      width: "80vw",
      overflow: "scroll",
      margin: "0 auto",
    };

    if (results.length === 0) return <></>;

    return (
      <>
        <div style={contentStyle}>
          <SyntaxHighlighter
            showLineNumbers={true}
            language="typescript"
            style={androidstudio}
          >
            {results}
          </SyntaxHighlighter>
        </div>
      </>
    );
  };

  return (
    <div style={layoutStyle}>
      {topOfPage()}
      {contentOfPage()}
    </div>
  );
};

export default Dataset;
