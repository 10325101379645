import moment from "moment";
import {
  Action,
  IInvoiceReportData,
} from "./actionTypes";
import { IInvoiceReportFilter } from "../../../../components/admin/reporting/invoice/types";

// States' definition
export interface IInvoiceReport {
  invoiceReportFilter: IInvoiceReportFilter;
  invoiceReportData: IInvoiceReportData[];
}
export interface State {
  invoiceReport: IInvoiceReport;
}
let fmt = 'Y[Q]Q';
const invoiceReport = (
  state: IInvoiceReport = {
    invoiceReportFilter: {
      ownedBy: "CBS",
      invoiceCycle: moment().subtract(1, 'Q').format(fmt),
      compareInvoiceCycle: moment().subtract(2, 'Q').format(fmt),
      schoolSlug: "all",
      system: "all"
    },
    invoiceReportData: [],
  },
  action: Action
): IInvoiceReport => {
  switch (action.type) {
    case "REPORT/SET_INVOICE_REPORT_LIST": {
      return { ...state, invoiceReportData: action.invoiceReportDate };
    }
    case "REPORT/SET_INVOICE_REPORT_FILTER": {
      return { ...state, invoiceReportFilter: action.invoiceReportFilter };
    }
    default:
      return state;
  }
};

export default invoiceReport;
