import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../common/utils";
import moment from "moment";




export const ServerNameDropDown = [
  { key: "Choose Server", text: "Choose Server" },
  { key: "WEBSITE", text: "WEBSITE" },
  { key: "CORE_SERVICE", text: "CORE_SERVICE" },
];

export const headerRow: IColumn[] = [

  {
    key: "name",
    name: "Activity",
    fieldName: "name",
    maxWidth: 700,
    minWidth: 100,
    data: "string",
    ...isSortable,
    onRender: (item) => {
      return (
        <TooltipHost content={item.name} closeDelay={500}>
          {item.name}
        </TooltipHost>
      );
    },
  },
  {
    key: "createdBy",
    name: "Created By",
    fieldName: "createdBy",
    minWidth: 100,
    maxWidth: 300,
    data: "string",
    ...isSortable,
    onRender: (item) => {
      return (
        <TooltipHost content={item.createdBy} closeDelay={500}>
          {item.createdBy || ""}
        </TooltipHost>
      );
    },
  },
  {
    key: "createdAt",
    name: "Created At",
    fieldName: "createdAt",
    minWidth: 100,
    maxWidth: 300,
    data: "string",
    ...isSortable,
    onRender: (item) => {
      const date = moment(item.createdAt).local().format('DD MMM YYYY, HH:mm:ss') || ""

      return (
        <TooltipHost content={date} closeDelay={500}>
          {date}
        </TooltipHost>
      );
    },
  },
];


