import { IColumn, TooltipHost } from "@fluentui/react"
import { isSortable } from "../admin/common/utils"

export const generateColumns = (list: (Partial<IColumn> & { key: string })[]): IColumn[] => {
  return list.map(column => {
    return {
      name: column.name!,
      fieldName: column.fieldName || column.key,
      data: "string",
      ariaLabel: column.name,
      minWidth: column.minWidth || 90,
      ...isSortable,
      onRender: (item) => {
        const value = item[column.key!]
        const displayValue = Array.isArray(value) ? value.map(x => x.name).join(',') : value
        return (
          <TooltipHost content={displayValue} closeDelay={500} >
            {displayValue}
          </TooltipHost >
        );
      },
      ...column,
      key: column.key!,
    }
  })
}