import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import TSReadingsFilters from './TSReadingFilters';
import TSReadingsList from './TSReadingsList';
import { Stack, ActionButton, IDropdownOption, Dropdown } from '@fluentui/react';
import { RootState } from '../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { PageSize } from '../common/common';
import { exportTSReading, getTSReadings, setTSReadingFilter } from '../../../store/admin/tsReading/action';
import { ITSReadingState } from '../../../store/admin/tsReading/reducer';
import { OpenSwapIAndEDialog } from './SwapIAndEDialog';

const TSReadingScreen = () => {
  const dispatch = useDispatch()
  const { tsReadings, totalCount, tsReadingFilter }: ITSReadingState = useSelector<RootState, ITSReadingState>((state: RootState) => state.web.tsReadings)
  useEffect(() => {
    // dispatch(getTSReadings())
  }, []);

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...tsReadingFilter, page: 1, pageSize: size }
    dispatch(setTSReadingFilter(filters))
    dispatch(getTSReadings())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Live Meter Data (Time Series)</h1>
        <TSReadingsFilters />
      </header>
      <Stack tokens={{ padding: '0 15px' }} horizontal verticalAlign='center'>
        <span
          style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
        >
          {totalCount > 0 &&
            <>
              Displaying&nbsp;
              <Dropdown
                selectedKey={tsReadingFilter.pageSize?.toString() || "50"}
                options={PageSize}
                styles={{ dropdown: { width: "80px" } }}
                onChange={handleOnChangePageSize}
              />
              &nbsp;/ {totalCount}
            </>}
        </span>
        <div style={{ display: 'flex', flex: 1 }} />
        {/* <ActionButton
          allowDisabledFocus
          // style={{ textAlign: 'right' }}
          onClick={() => dispatch(exportTSReading())}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton> */}
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={async () => {
          // await openGenYieldModal({})
          OpenSwapIAndEDialog({ type: { $in: ["I", "E"] } })
        }}>
          Swap Import and Export
        </ActionButton>

      </Stack>
      <TSReadingsList actions={['edit']} />
    </div>
  );
}
export default TSReadingScreen;

