
import { ActionButton, DefaultButton, PrimaryButton, Stack } from "@fluentui/react"
import { useState } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import store from "../../../store"
import ModalDialog from "../common/ModalDialog"
import cloneDeep from "lodash/cloneDeep"
import { Checkbox, TextField } from "office-ui-fabric-react"
import { FundingItem } from "@solarforschools/sfs-core/dist/calculator/types"



export const openCreateFundingModal = (props: FundingItem[]) => {

    return new Promise<FundingItem[] | undefined>((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (data?: FundingItem[]) => {

            resolve(data);
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        const data = props;
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title="Edit Funding Lists"
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-sm"}
                >

                    <CreateFundingModal
                        data={data}
                        onSave={(data: FundingItem[]) => callback(data)}
                        onCancel={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export const CreateFundingModal = (props: ICreateFundingModal) => {

    const resData = props.data;

    const [tempRes, setTempRes] = useState<FundingItem[]>(cloneDeep(resData))


// added types
    const handleInput = (e: React.FormEvent<HTMLElement | HTMLInputElement | HTMLTextAreaElement> | undefined  , i: number) => {
        let checked;
        const lists: Record<string, any> = [...tempRes];
        const { name } = e?.target as  HTMLInputElement ;


        if (name === "default") {
            checked = (e?.target as HTMLInputElement).checked;
            lists[i][name] = checked
        }
        else {
            let { value } = e?.target as HTMLInputElement;
            lists[i][name] = value
        }


        setTempRes(lists as FundingItem[])
    }
    const handleRemoveClick = (i: number) => {
        const list = [...tempRes]
        list.splice(i, 1);
        setTempRes(list)
    }

    const handleAddClick = () => {
        setTempRes([...tempRes, {  FundedBy: "", FundingModel: "-", default: false }])
    }

    const handleOnSave = () => {
        props.onSave(tempRes)

    }

    return <div>

        <h2 style={{ textAlign: "center", margin: "1rem" }}>List Of Funding Values</h2>
<div className="fundingModal">
        <table className="fundingModal-table">
            <thead>
                <tr>
                    <th>Funded By</th>
                    <th>Funding Model</th>
                    <th>Default</th>
                    <th>Delete</th>

                </tr>
            </thead>
            <tbody>
                {
                    tempRes && tempRes.map((el:FundingItem, i: number) => {
                        const checkedVal = el.default;
                        const { FundedBy, FundingModel } = el;
                        const isChecked = String(checkedVal) === "true" ? true : false
                        return <tr key={i}>
                            <td>
                                <TextField
                                    value={FundedBy}
                                    name="FundedBy"
                                    placeholder="Enter Funding By"
                                    className="fundingModalTextBox"
                                    onChange={e => handleInput(e, i)}
                                />

                            </td>
                            <td>
                                <TextField
                                    value={FundingModel}
                                    name="FundingModel"
                                    placeholder="Enter Funding Model"
                                    className="fundingModalTextBox"
                                    onChange={e => handleInput(e, i)}
                                />

                            </td>
                            <td>
                                <Checkbox
                                    name="default"
                                    checked={isChecked}
                                    onChange={e => handleInput(e, i)}
                                    className="fundingModalCheck"
                                />

                            </td>
                            <td>
                                <ActionButton
                                    iconProps={{ iconName: 'Delete' }}
                                    onClick={() => handleRemoveClick(i)}
                                    className="deleteBtn"

                                />
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
       
        <DefaultButton text="Add Column" onClick={handleAddClick}
            style={{margin:"1rem 0 0 0"}}
        />
 </div>
        <Stack horizontal horizontalAlign="center" className="margin-top-md">
            <Stack.Item>
                {resData &&
                    <PrimaryButton
                        text="Save"
                        onClick={handleOnSave}
                        className="btn-primary"
                    />}
                <DefaultButton onClick={props.onCancel} text="Cancel" />
            </Stack.Item>
        </Stack>


    </div>
}

interface ICreateFundingModal {
    data: FundingItem[]
    onSave: (data: FundingItem[]) => void
    onCancel: () => void
}
