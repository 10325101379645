import React from 'react'
import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { CardContext, CardContextType } from "../../layouts";
import { DownTriangleIcon, RightTriangleIcon, UpTriangleIcon } from '../../utils/Icons';
import { renderFeedInTarrif } from '../../utils/tileHelper';

export const FrontContent = () => {
    const { feedInTarrif } = useContext(CardContext) as CardContextType;
    //const [status, setStatus] = useState({ prevQuarter: "", isEqual: "" });
    // let amountColor;

    const [overallFeedInTarrif, setOverAllFeedInTarrif] = useState(feedInTarrif?.amount)

    const getStatus = () => {
        if (feedInTarrif !== undefined) {
            setOverAllFeedInTarrif(feedInTarrif.amount)
        }


        // if (feedInTarrif !== undefined) {
        //     const quarters = Object.keys(feedInTarrif.lastFourQuarters); // Always have 4 sorted array of quarter
        //     const prevQuarter = quarters[2]; //Last Before elmenet is Previous Quarter
        //     const currQuarter = quarters[3]; //Last element is Current Quarter


        //     if (feedInTarrif.lastFourQuarters[prevQuarter].P > feedInTarrif.lastFourQuarters[currQuarter].P) {
        //         setStatus({ ...status, prevQuarter: "high", isEqual: "" });

        //     } else if (feedInTarrif.lastFourQuarters[prevQuarter].P < feedInTarrif.lastFourQuarters[currQuarter].P) {
        //         setStatus({ ...status, prevQuarter: "low", isEqual: "" });
        //     } else {
        //         setStatus({ ...status, isEqual: "yes", prevQuarter: "" });
        //     }

        // }

    }
    // amountColor = (status?.isEqual === "yes") ? { color: "#FFCB2F", fontSize: "3rem" } : ((status?.prevQuarter === "high") ? { color: "red", fontSize: "3rem" } : { color: "green", fontSize: "3rem" })




    useEffect(() => {
        getStatus()
    }, [feedInTarrif])
    return (
        <>
            <div className="card-content">
                <div className="card-content--count" style={{ color: "#243f61", fontSize: "3rem" }} >
                    {overallFeedInTarrif !== undefined && renderFeedInTarrif(overallFeedInTarrif)}
                </div>
                {/* <div>
                    {status?.isEqual === "yes"
                        ? <RightTriangleIcon fontSize="2rem" />
                        : status?.prevQuarter === "high"
                            ? <DownTriangleIcon fontSize="2rem" />
                            : <UpTriangleIcon fontSize="2rem" />}
                </div> */}
            </div>

        </>
    )
}

export const BackContent = () => {
    const { feedInTarrif } = useContext(CardContext) as CardContextType;
    let exportsData: number[] = [],
        generationsData: number[] = [],
        vatAmountData: number[] = [],
        totalPaymentData: number[] = [],
        labels: string[] = []


    if (feedInTarrif?.lastFourQuarters !== undefined) {
        labels = Object.keys(feedInTarrif?.lastFourQuarters)
        for (let i = 0; i < labels.length; i++) {
            exportsData.push(feedInTarrif?.lastFourQuarters[labels[i]].E);
            generationsData.push(feedInTarrif?.lastFourQuarters[labels[i]].G);
            vatAmountData.push(feedInTarrif?.lastFourQuarters[labels[i]].V);
            totalPaymentData.push(feedInTarrif?.lastFourQuarters[labels[i]].P);
        }
    }

    const chartData = {
        labels,
        datasets: [
            {
                label: "Export",
                data: exportsData,
                backgroundColor: "#FF6384",
                borderColor: "#FF6384",
                hoverBackgroundColor: "#FF6384",
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            },
            {
                label: "Generation",
                data: generationsData,
                backgroundColor: "#36A2EB",
                borderColor: "#378AFF",
                hoverBackgroundColor: "#36A2EB",
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            },
            {
                label: "VAT",
                data: vatAmountData,
                backgroundColor: "#BE61CA",
                borderColor: "#BE61CA",
                hoverBackgroundColor: "#BE61CA",
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            },
            {
                label: "Payment",
                data: totalPaymentData,
                backgroundColor: "#F13C59",
                borderColor: "#F13C59",
                hoverBackgroundColor: "#F13C59",
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
            tooltip: {
                intersect: false,

                callbacks: {
                    label: function (context: any) {
                        const datasetIndex = context.datasetIndex;
                        const dataset = context.chart.data.datasets[datasetIndex];
                        const xValue = context.chart.data.labels[context.dataIndex];
                        const yValue = renderFeedInTarrif(context.parsed.y);
                        return `${dataset.label}: ${yValue} `;
                    }
                },

            }
        },
        scales: {
            x: {
                ticks: {
                    display: false
                }
            },
            y: {
                ticks: {
                    callback: (value: any) => {
                        return renderFeedInTarrif(value);
                    }
                }
            }
        }
    };


    return <>
        <div className="flex-column-center">
            <div style={{ height: "90px", width: "250px" }}>
                <Line data={chartData} options={options as any} />
            </div>
        </div>

    </>


}