import { TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { IEstablishment } from '@solarforschools/sfs-core/dist/school/types';
import { markerColorBasedOnStages } from './helper';

export const ControlPosition = {
    BOTTOM: 11,
    BOTTOM_CENTER: 11,
    BOTTOM_LEFT: 10,
    BOTTOM_RIGHT: 12,
    CENTER: 13,
    LEFT: 5,
    LEFT_BOTTOM: 6,
    LEFT_CENTER: 4,
    LEFT_TOP: 5,
    RIGHT: 7,
    RIGHT_BOTTOM: 9,
    RIGHT_CENTER: 8,
    RIGHT_TOP: 7,
    TOP: 2,
    TOP_CENTER: 2,
    TOP_LEFT: 1,
    TOP_RIGHT: 3,
};
function MapSearch({ school, onPlaceChanged, onMarkerDragEnd }: MapSerachProps) {
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)
    const [map, setMap] = useState<google.maps.Map>()
    const [markerColor, setMarkerColor] = useState("")

    useEffect(()=>{
        const schStage = school.activeStage?.name
        let color = "orange"
        if(schStage) {
            if(school.activeStage?.active) {
                color = markerColorBasedOnStages[schStage] || 'orange'
            } 
        }
        setMarkerColor(color)
    },[])

    const onAutocompleteLoad = (auto: google.maps.places.Autocomplete) => {
        setAutocomplete(auto)
    }
    const onSelectPlace = () => {
        const place: google.maps.places.PlaceResult = autocomplete?.getPlace() as google.maps.places.PlaceResult
        onPlaceChanged(place)
    }

    function handleMarkerDragEnd(e: google.maps.MapMouseEvent) {
        onMarkerDragEnd(e)
    }

    const onLoadMap = (map: google.maps.Map) => {
        setMap(map)
    }
    return (
        <>
            <GoogleMap
                mapContainerStyle={{ height: "300px", width: "100%" }}
                center={new google.maps.LatLng(school?.address?.location?.coordinates[1] || 37.4419, school?.address?.location?.coordinates[0] || -122.1419)}
                tilt={0}
                zoom={17}
                onLoad={onLoadMap}
                clickableIcons={false}
                options={{
                    mapTypeId: 'hybrid',
                    mapTypeControl: false,
                    rotateControl: false,
                    zoomControl: true,
                    streetViewControl: false,
                }}
            >
                <Autocomplete
                    onLoad={onAutocompleteLoad}
                    onPlaceChanged={onSelectPlace}
                >
                    <input
                        type="text"
                        placeholder="Enter location"
                        style={{
                            top: '10px',
                            boxSizing: `border-box`,
                            border: `1px solid black`,
                            width: `400px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute"
                        }}
                    />
                </Autocomplete>
                <Marker
                    key={school.slug}
                    icon={{
                        url:`https://maps.google.com/mapfiles/ms/icons/${markerColor}.png` ,
                        scaledSize: new window.google.maps.Size(22, 22)
                    }}
                    position={new google.maps.LatLng(school?.address?.location?.coordinates[1] || 37.4419, school?.address?.location?.coordinates[0] || -122.1419)}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />
            </GoogleMap>

        </>
    );
}

export default React.memo(MapSearch)

interface MapSerachProps {
    school: IEstablishment,
    onPlaceChanged: (place: google.maps.places.PlaceResult) => void
    onMarkerDragEnd: (e: google.maps.MapMouseEvent) => void
}
