import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { deleteATranslation, fetchTranslationHistory, fetchTranslations, handleDraftCreation, setPanelOpen } from '../../../store/admin/translations/actions';
import useFetch from '../../../hooks/useFetch';
import { Icon, Shimmer, Text, TooltipHost } from '@fluentui/react';
import { FcApproval } from "react-icons/fc"
import { VscAdd } from "react-icons/vsc";
import { BsFiletypeKey } from "react-icons/bs";
import { MdDelete } from "react-icons/md"
import { buildFilterLang, languageMap } from './utils/helper';
import { ConfirmDialog } from '../common/ConfirmDialog';
import moment from 'moment';
import { openEditTranslationDialog } from './AddEditTranslation';
import { IListLeftPane, IListRightPane, ITData, ITranslations } from './utils/types';
import { IAdmin } from '../../../store/admin/reducer';
import Card from './utils/Card';

const Lists = () => {
    const { hasLoadMore, data, pageNumber, totalCount, isLoading, filter: { lang, value } } = useSelector<RootState, ITranslations>((state) => state.web.translations);
    const admin: IAdmin = useSelector<RootState, IAdmin>((state: RootState) => state.web.admin);
    const hasApprovalPermission = admin.user?.permission?.includes("translationApprover") ? true : false


    const dispatch = useDispatch()
    const elmRef = useRef<HTMLDivElement>(null)
    const hasViewPermission = admin.user?.permission?.includes("translation")
    const hasDeletePermission = admin.user?.permission?.includes("delete")

    // actual fetch logic is here
    const dispatchFunction = () => {
        let fetchLang = buildFilterLang(lang)

        dispatch(fetchTranslations({ page: pageNumber, ...fetchLang, value }))
    }

    // custom hook for infinite scrolling
    useFetch(elmRef, dispatchFunction, hasLoadMore, data)


    if (!isLoading && data?.length === 0) {
        return <div className='text-center m-10' style={{ color: "var(--solar-blue)" }}>
            <Text variant='mediumPlus'>No translations found</Text>
        </div>
    }

    const handleDelete = async (docId: string) => {
        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Delete Translation",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to delete this translation?`,
            },
            confirmBtnText: "Delete",
        });

        if (isConfirmed) {
            dispatch(deleteATranslation(docId))
        }
    }

    const RenderApprovalContent = ({ approvedAt, approvedBy }: { approvedAt: string, approvedBy: string }) => {

        const date = moment(approvedAt).local().format('DD MMM YYYY, HH:mm:ss') || ""
        const name = approvedBy || ""
        const content = `Approved by ${name} at ${date}`
        return <>
            <TooltipHost
                content={content}
                closeDelay={500}>
                <FcApproval size={23} />
            </TooltipHost>

        </>
    }

    const handleOpenPanel = (docId: string, lang: string) => {
        dispatch(setPanelOpen(true))
        dispatch(fetchTranslationHistory(docId, lang))
    }


    const handleEditTranslation = async (id: string, value: string, language: string, langKey: string, enValue: string) => {
        await openEditTranslationDialog({ id, value, language, key: langKey, enValue })
    }

    const handleAddTranslation = async (id: string, langKey: string, enValue: string) => {
        await openEditTranslationDialog({ id, key: langKey, enValue })
    }


    const handleDeleteTranslation = async (docId: string, translationValue: string, language: string, key: string) => {

        const confirm = await ConfirmDialog({
            dialogContentProps: {
                title: "Delete Translation",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to delete ${languageMap[language]}'s translation ?`,
            },
        });
        if (confirm) {
            const draftArgs = {
                key,
                language,
                value: translationValue,
                docId
            }
            dispatch(handleDraftCreation(draftArgs, true, hasApprovalPermission))
        }

    }

    const LeftPane = ({ langKey, docId, enValue }: IListLeftPane) => {
        return (
            <>
                <div style={{ display: "flex", gap: "5px" }}>
                    <div>
                        <BsFiletypeKey size={18} />
                    </div>
                    <div>
                        {langKey}
                    </div>

                </div>
                <div className='add-icon' >
                    <div className='flex-row-center' onClick={() => handleAddTranslation(docId, langKey, enValue)}>
                        <VscAdd size={12} />
                        <div>Add Translation </div>
                    </div>

                    {
                        hasViewPermission && hasDeletePermission && <div onClick={() => handleDelete(docId)} className='flex-row-center' style={{ gap: "5px", color: "red" }}>
                            <MdDelete size={12} />
                            <div>Delete</div>
                        </div>
                    }
                </div>
            </>

        )
    }

    const RightPane = ({ langKey, docId, translations, enValue }: IListRightPane) => {

        const length = translations.length

        return (
            <>
                {
                    translations.map((translation: ITData, index: number) => {
                        return <div key={index}>
                            <div className='card-right-outer' >
                                <div className='card-right-language'>
                                    {languageMap[translation.language] || ""}
                                </div>
                                <div className='card-right-content'>
                                    {translation.value || ""}
                                </div>
                                <div className='flex-row-center card-right-btn'>

                                    {translation.language.toLowerCase() !== "en" && <div className='icon delete-icon'>
                                        <Icon iconName='Delete' style={{ color: "red" }} onClick={() => handleDeleteTranslation(docId, translation.value || "", translation.language || "", langKey)} />
                                    </div>}

                                    <div className='icon'>
                                        <Icon iconName='Edit' onClick={() => handleEditTranslation(docId, translation.value, languageMap[translation.language] || "", langKey, enValue)} />
                                    </div>


                                    <div className='icon'>
                                        <Icon iconName='FullHistory' onClick={() => handleOpenPanel(docId, translation.language)} />
                                    </div>

                                    <div className='icon'>
                                        <RenderApprovalContent approvedAt={translation.approvedAt as string} approvedBy={translation.approvedBy} />
                                    </div>

                                </div>
                            </div>
                            {index < (length - 1) && <hr />}
                        </div>
                    })
                }

            </>
        )
    }


    return (
        <div>
            <Text variant='medium' style={{ marginLeft: "2rem", color: "var(--solar-blue)" }}>
                Displaying - <span style={{ fontWeight: 600 }}>{data?.length || 0}</span>  / {totalCount}
            </Text>

            <div className='card-layout'>
                {
                    data?.map((elm, index: number) => {
                        const { key: langKey, translations, id: docId } = elm
                        const findEnValue = translations.find(elm => elm.language.toLowerCase() === "en")
                        let enValue = ""
                        if (findEnValue) enValue = findEnValue.value

                        return (
                            <React.Fragment key={index}>
                                <Card isPendingList={false}>
                                    <LeftPane langKey={langKey} docId={docId} enValue={enValue} />
                                    <RightPane langKey={langKey} docId={docId} translations={translations} enValue={enValue} />
                                </Card>
                            </React.Fragment>
                        )
                    })
                }
            </div>
            {
                hasLoadMore
                && <div ref={elmRef}>
                    {
                        data.length > 0 && <Shimmer width="100%" />
                    }
                </div>
            }
        </div>
    )
}

export default Lists