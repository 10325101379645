import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import store, { RootState } from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getFillingFields, getMetersOptions, initFilling, validateFillingReading } from "./helper"
import { IMeterReading } from "@solarforschools/sfs-core/dist/solardb/types"
import { toast } from "react-toastify"
import { isLoading } from "../../../store/admin/action"
import moment from "moment"
import { fillMeterReading } from "../../../store/admin/meterReading/action"
import { IMeterReadingState } from "../../../store/admin/meterReading/reducer"
import { filterSystemsMeterOptions } from "./common"
import { getSystemOptions } from "../common/common"
import SystemPicker from "../common/SystemPicker"
import { getSystems } from "../../../store/client/system"


export const openFillingMeterReadingModal = (props?: any) => {
  return new Promise<IMeterReading | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: any) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Fill Meter Reading"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <FillingDialog
            {...props}
            onSave={(data: any | undefined) => callback(data)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const FillingDialog = (props: MeterReadingProps) => {
  const dispatch = useDispatch();
  const [fillingOptions, setMeterReading] = useState<any>(initFilling)
  const [errors, setErrors] = useState<any>({});
  const [meters, setMeters] = useState<any>([])

  const generalFields = useMemo(() => getFillingFields({ fillingOptions, meters }), [fillingOptions, meters]);

  const handleOnSave = async () => {
    const errors = await validateFillingReading(fillingOptions);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    const result: any = await dispatch(fillMeterReading(fillingOptions))
    if (result) return props.onSave(fillingOptions)
    return false
  }


  const handleOnChangeInput = async (key: string, value: any) => {

    let data: IMeterReading = _.cloneDeep(fillingOptions);
    switch (key) {
      case 'start':
      case 'end':
        const date = moment(value).format('YYYY-MM-DD')
        data = _.set({ ...data }, key, date)
        break
      case 'selectedSystems':
        data = { ...data, name: value[0]?.name, id: value[0]?.key }
        break
      default:
        data = _.set({ ...data }, key, value)
        break;
    }
    if (key === 'selectedSystems') {
      if (data.id) {
        const { systems } = await getSystems({ system: data.id })
        const { meters, msns } = filterSystemsMeterOptions(systems, { system: fillingOptions.id || 'all' })
        setMeters(meters)

      } else {
        setMeters([])

      }

    }
    setMeterReading(data)
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className={`ms-Grid-col ms-lg12 margin-top-xsm`}>
            <SystemPicker onChange={handleOnChangeInput} />
          </div>
        </div>
        <div className="ms-Grid-row">
          <DynamicForm
            fields={generalFields}
            data={fillingOptions}
            onChange={handleOnChangeInput}
            errors={errors}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface MeterReadingProps {
  onCancel?: () => void;
  onSave: (data?: IMeterReading) => void;
  data?: IMeterReading;
}

export default openFillingMeterReadingModal;

