import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchBillingPeriods } from "../../../store/admin/period/action";
import Loader from "../common/Loader";
import BillingPeriodFilter from "./BillingPeriodFilter";
import BillingPeriodList from "./BillingPeriodList";


const BillingPeriodScreen = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBillingPeriods())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Billing Periods</h1>
        <BillingPeriodFilter />
      </header>
      <BillingPeriodList />
    </div>
  )
}
export default BillingPeriodScreen;

