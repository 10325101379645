import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility, TooltipHost } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { isSortable } from '../common/utils';

import DetailsListWapper from '../../common/DetailsListWapper';
import moment from 'moment';
import { fetchBillingPeriods, setBillingPeriodFilter } from '../../../store/admin/period/action';
import { fetchTasks, setTaskFilter } from '../../../store/admin/task/action';
import { ITaskState } from '../../../store/admin/task/reducer';

const TaskList = ({ scrollShow = true }: Props) => {
  const dispatch = useDispatch()
  const { tasks, totalCount, taskFilter }: ITaskState = useSelector<RootState, ITaskState>((state: RootState) => state.web.task)

  const headerRow: IColumn[] = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 150,
      ...isSortable,
      onRender: (task) => <TooltipHost content={task.name} closeDelay={500}> {task.name}</TooltipHost>
    },
    {
      key: 'taskType',
      name: 'taskType',
      fieldName: 'taskType',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.taskType} closeDelay={500}> {item.taskType}</TooltipHost>
    },
    {
      key: 'workflow',
      name: 'workflow',
      fieldName: 'workflow',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.workflow} closeDelay={500}> {item.workflow}</TooltipHost>
    },
    {
      key: 'startDate',
      name: 'Start',
      fieldName: 'startDate',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : ''
    },
    {
      key: 'owner',
      name: 'Owner',
      fieldName: 'owner',
      minWidth: 200,
      maxWidth: 250,
      ...isSortable,
      onRender: (task) => <TooltipHost content={task.owner} closeDelay={500}> {task.owner}</TooltipHost>
    },
    {
      key: 'status',
      name: 'status',
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 100,
      ...isSortable,
      onRender: (item) => item.status
    }
  ];

  const [columns] = useState<IColumn[]>(headerRow)


  // const handleOnDelete = async (period: IBillingPeriod) => {
  //   const confirm = await ConfirmDialog({
  //     dialogContentProps: {
  //       title: "Delete Period",
  //       closeButtonAriaLabel: "Close",
  //       subText: `Are you want to delete ${period.Cycle}`,
  //     },
  //   });
  //   if (confirm) dispatch(deleteBillingPeriod(period));
  // };

  const handleOnSort = (field: string, order: string) => {
    const params = { ...taskFilter, page: 1, sortField: field, sortOrder: order };
    dispatch(setTaskFilter(params));
    dispatch(fetchTasks(params))
  }

  const loadNextPage = () => {
    const { page = 0 } = taskFilter
    dispatch(setTaskFilter({ ...taskFilter, page: page + 1 }))
    dispatch(fetchTasks(undefined, false))
  }

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}>
            {totalCount > 0 && (<>Displaying &nbsp; {tasks.length}/ {totalCount}</>)}
          </span>
        </div>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            {scrollShow && <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={tasks}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={tasks.length < totalCount}
                onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
            }
            {!scrollShow &&
              <DetailsListWapper
                items={tasks}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={tasks.length < totalCount}
                onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            }
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
}

interface Props {
  scrollShow?: boolean;
}

export default TaskList;

