import { ComboBox, DatePicker, IComboBoxOption, TextField } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IOpportunity } from "../../../store/admin/opportunity/reducer";
import { useCallback, useEffect, useState } from "react";
import { fetchOpportunityFilter, getOpportunities, setOpportunityFilter } from "../../../store/admin/opportunity/action";
import { opportunityTypes } from "./common";
import { OpportunityStatus, dropdownStyles, months, quarters } from "../common/common";
import moment from "moment";



const OpportunityFilter = () => {
	const dispatch = useDispatch();
	const opportunity: IOpportunity = useSelector<RootState, IOpportunity>((state) => state.web.opportunity)


	const [regions, setRegions] = useState<IComboBoxOption[]>([] as IComboBoxOption[])
	const [users, setUsers] = useState<IComboBoxOption[]>([] as IComboBoxOption[])

	const loadData = async () => {
		const res: any = await fetchOpportunityFilter();
		setRegions(res?.regions || [] as IComboBoxOption[]);
		setUsers(res?.users || [] as IComboBoxOption[]);
	}

	useEffect(() => {
		loadData();
	}, []);

	const handleOnChangeInput = (key?: string,
		value?: any,
	) => {
		const params = { ...opportunity.opportunityFilter, page: 1, [key!]: value };
		dispatch(setOpportunityFilter(params));
		handleUpdateFilters(params);
	}

	const handleUpdateFilters = useCallback((filters: any) => { dispatch(getOpportunities(filters)) }, [])

	const { type, country, created, updated, name, status, month, periodType, year, from, to, quarter } = opportunity.opportunityFilter

	return (
		<div
			className="ms-Grid"
			dir="ltr"
			style={{ width: "-webkit-fill-available", minWidth: "700px", maxWidth: "1300px" }}
		>
			<div className="ms-Grid-row">
				<TextField
					label="Name"
					placeholder="Name"
					onChange={(e, value) => handleOnChangeInput('name', value || '')}
					className="ms-Grid-col ms-lg4"
					value={name!} />
				<ComboBox
					label="Type"
					selectedKey={type ? type : "all"}
					options={opportunityTypes}
					autoComplete={"on"}
					onChange={(e, item) => handleOnChangeInput('type', item?.key)}
					className="ms-Grid-col ms-lg1"
				/>
				<ComboBox
					label="Region"
					selectedKey={country ? country : "all"}
					options={regions}
					autoComplete={"on"}
					onChange={(e, item) => handleOnChangeInput('country', item?.key)}
					className="ms-Grid-col ms-lg1"
				/>
				<ComboBox
					label="Created By"
					selectedKey={created ? created : "all"}
					autoComplete={"on"}
					options={users as IComboBoxOption[]}
					onChange={(e, item) => handleOnChangeInput("created", item?.key)}
					className="ms-Grid-col ms-lg3"
				/>
				<ComboBox
					label="Updated By"
					selectedKey={updated ? updated : "all"}
					autoComplete={"on"}
					options={users as IComboBoxOption[]}
					onChange={(e, item) => handleOnChangeInput("updated", item?.key)}
					className="ms-Grid-col ms-lg3"
				/>
				<ComboBox
					label="Status"
					selectedKey={status ? status : "all"}
					options={OpportunityStatus}
					autoComplete={"on"}
					onChange={(e, item) => handleOnChangeInput('status', item?.key)}
					className="ms-Grid-col ms-lg1"
				/>
				<ComboBox
					label="Period"
					placeholder="Select period"
					allowFreeform={true}
					autoComplete={"on"}
					selectedKey={periodType || 'all'}
					options={[
						{ key: "all", text: "All" },
						{ key: "month", text: "Monthly" },
						{ key: "quarter", text: "Quartely" },
						{ key: "year", text: "Yearly" },
						{ key: "custom", text: "Custom" },
					]}
					className="ms-Grid-col ms-lg2"
					onChange={(e, item) => handleOnChangeInput("periodType", item?.key)}
				/>
				{periodType === "month" && (
					<>
						<ComboBox
							label="Month"
							placeholder="Select"
							allowFreeform={true}
							autoComplete={"on"}
							selectedKey={month || moment().format("MMM")}
							options={months}
							className="ms-Grid-col ms-lg1"
							onChange={(e, item) => handleOnChangeInput("month", item?.key)}
						/>
					</>
				)}
				{periodType === "quarter" && (
					<>
						<ComboBox
							label="Quarter"
							placeholder="Select"
							allowFreeform={true}
							autoComplete={"on"}
							selectedKey={quarter || "1"}
							options={quarters}
							className="ms-Grid-col ms-lg1"
							onChange={(e, item) => handleOnChangeInput("quarter", item?.key)}
						/>
					</>
				)}
				{(periodType == "month" || periodType === "year" || periodType === "quarter") && (
					<TextField label="Year"
						placeholder="year"
						type="number"
						step="1"
						min={2010}
						className="ms-Grid-col ms-lg1"
						onChange={(e, value) => handleOnChangeInput('year', value && parseFloat(value))}
						value={year?.toString() || moment().format("YYYY")}
					/>
				)}
				{periodType === "custom" && (
					<>
						<DatePicker
							label="From"
							placeholder="Start date"
							ariaLabel="Select a date"
							value={
								(from &&
									new Date(from)) ||
								new Date(moment().subtract(1, "month").format("YYYY-MM-DD"))
							}
							onSelectDate={(date) => handleOnChangeInput("from", moment(date).format("YYYY-MM-DD"))}
							styles={dropdownStyles}
							className="ms-Grid-col ms-lg2"
						/>
						<DatePicker
							label="To"
							placeholder="End date"
							ariaLabel="Select a date"
							value={
								(to &&
									new Date(to)) ||
								new Date()
							}
							onSelectDate={(date) => handleOnChangeInput("to", moment(date).format("YYYY-MM-DD"))}
							styles={dropdownStyles}
							className="ms-Grid-col ms-lg2"
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default OpportunityFilter;
