import { useEffect, useMemo, useState } from "react";
import {
  PrimaryButton,
  DefaultButton,
  ActionButton,
} from "@fluentui/react/lib/Button";
import {
  ComboBox,
  DetailsList,
  IColumn,
  IComboBoxOption,
  IStackTokens,
  SelectionMode,
  Separator,
  Stack,
  Sticky,
  StickyPositionType,
  TextField,
  TooltipHost,
} from "@fluentui/react";

import "office-ui-fabric-react/dist/css/fabric.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { EditMeter } from "./EditMeter";
import { ConfirmDialog } from "../common/ConfirmDialog";
import _ from "lodash";
import ModalDialog from "../common/ModalDialog";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import {
  generateMid,
  isSortable,
  SetDetailListHeaderSortDirection,
} from "../common/utils";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import { ISystemState } from "../../../store/admin/system/reducer";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { displaySettingField, getSchoolFields, getSystemGeneralFields, monthlyTargetFields, ownershipFields, targetFields, validateSystem } from "./SystemHelper";
import { StringMap } from "../common/types";
import { DefaultMeter, getCountryTz, setTargetMonthlyYield } from "./common";
import { getSchoolBySlug } from "../../../store/admin/school/action";
import { IEstablishment, ISystem } from "@solarforschools/sfs-core";
import {
  fetchEnergyDetails,
  fetchSystems,
  updateSystem,
} from "../../../store/admin/system/action";
import { addIcon, comboBoxStyles } from "../common/common";
import ReactDOM from "react-dom";
import { fetchEnergyDetailsfromPVGIS } from "../../../store/client/system";
import { IMeter } from "@solarforschools/sfs-core/dist/solardb/types";

export const OpenEditSystemModal = (props: any) => {
  return new Promise<ISystem | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: ISystem) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Edit System"}
          onDismiss={() => callback(undefined)}
        >
          <EditSystemDialog
            {...props}
            onCancel={() => callback(undefined)}
            onSave={(system: ISystem) => callback(system)}
          />
        </ModalDialog>
      </Provider>
      ,
      mountTarget
    )
  })
}

export const EditSystemDialog = ({ system, onCancel, onSave }: Props) => {
  const dispatch = useDispatch();
  const stackTokens: IStackTokens = { childrenGap: 30 };
  const systemState: ISystemState = useSelector<RootState, ISystemState>(
    (state: RootState) => state.web.system
  );
  const [schools] = useState([...systemState.schoolFilter]);
  const [ftpFolders] = useState([...systemState.ftpFolders!] as any[]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSystem, setSystem] = useState(_.cloneDeep(system));
  const [selectedMeterIndex, setSelectedMeterIndex] = useState<number>(-1);
  const [defaultMeter, seDefaultMeter] = useState<IMeter>({
    ...DefaultMeter,
    mid: generateMid(selectedSystem),
  } as unknown as IMeter);
  const [errors, setErrors] = useState<StringMap>({});

  const schoolFields = useMemo(() => getSchoolFields({ schools, selectedSystem }), [schools, selectedSystem]);
  const generalFields = useMemo(() => getSystemGeneralFields({ selectedSystem }), [selectedSystem]);
  useEffect(() => {
    setSystem(handlePercentage(_.cloneDeep(system)));
  }, []);

  const handlePercentage = (system: ISystem, factor?: number) => {
    const systemData = { ...system };
    if (system.pricePaid)
      systemData.pricePaid = factor
        ? system.pricePaid! * factor
        : ((system.pricePaid! * 100).toFixed(2) as unknown as number);
    if (system.priceSolar)
      systemData.priceSolar = factor
        ? system.priceSolar! * factor
        : ((system.priceSolar! * 100).toFixed(2) as unknown as number);
    if (system.priceExport)
      systemData.priceExport = factor
        ? system.priceExport! * factor
        : ((system.priceExport! * 100).toFixed(2) as unknown as number);
    systemData.target.SC = factor
      ? system.target?.SC! * factor
      : ((system.target?.SC! * 100).toFixed(2) as unknown as number);
    const targetPerMonth = systemData.target?.yieldPerMonth;
    let k: keyof typeof targetPerMonth;
    for (k in targetPerMonth) {
      if (targetPerMonth[k])
        systemData.target.yieldPerMonth[k] = factor
          ? targetPerMonth[k]! * factor
          : ((targetPerMonth[k]! * 100).toFixed(2) as unknown as number);
    }
    return systemData;
  };
  const handleOnActionClick = (index: number) => {
    seDefaultMeter({ ...defaultMeter, mid: generateMid(selectedSystem!) });
    setSelectedMeterIndex(index);
    setIsModalOpen(true);
  };

  const handleOnChangeInput = async (key: string, value: any) => {
    let systemObj = { ...selectedSystem! };
    switch (key) {
      case "schools":
        let schools = systemObj.schools || [];
        if (value !== 'select') {
          const school: IEstablishment = await getSchoolBySlug(value);
          if (school) {
            schools[0] = { schoolId: school._id, slug: school.slug, name: school.name }
            if (school?.address?.country) {
              const energytarget: any = await dispatch(fetchEnergyDetails(school.address.country, true, false))
              if (energytarget) {
                systemObj.target.yieldPerMonth = setTargetMonthlyYield(energytarget);
              }
            }
            systemObj.country = school?.address?.country as string
            systemObj.TZ = getCountryTz(systemObj.country) as string;
          }
        } else {
          schools = []
        }
        systemObj = _.set({ ...systemObj }, "schools", schools);
        break;
      case "services":
        let services = systemObj.services || [];
        if (value?.selected) {
          if (!services?.find((s) => s === value?.key)) {
            services.push(value.key);
          }
        } else {
          services = services?.filter((s) => s !== value?.key) as string[];
        }
        systemObj.services = services
        break;
      case 'country':
        const tz = getCountryTz(value)
        if (tz) systemObj.TZ = tz
        systemObj = _.set({ ...systemObj }, key, value);
        break
      default:
        systemObj = _.set({ ...systemObj }, key, value);
        break;
    }
    setSystem(systemObj);
  };

  const handleOnDeleteMeter = (index?: number) => {
    setSystem((selectedSystem: any) => {
      let systemObj: ISystem = _.cloneDeep(selectedSystem);
      const meters = systemObj?.meters?.filter(
        (m: any, i: number | undefined) => i != index
      );
      systemObj = { ...systemObj, meters: [...meters] };
      return systemObj;
    });
  };

  const handleOnSaveMeter = (meter: IMeter) => {
    setSystem((selectedSystem: any) => {
      let systemObj = _.cloneDeep(selectedSystem);
      if (!meter.mid) meter.mid = generateMid(systemObj);
      // for engynious mid is number
      if (systemObj.provider !== 'solaredge') meter.mid = parseInt(meter.mid?.toString()!)
      if (selectedMeterIndex > -1) {
        systemObj.meters[selectedMeterIndex!] = meter;
      } else {
        systemObj.meters.push(meter);
      }
      setIsModalOpen(false);
      setSelectedMeterIndex(-1);
      return systemObj;
    });
  };
  const handleOnDismissModal = () => {
    setIsModalOpen(false);
  };
  const headerRow: IColumn[] = [
    {
      key: "mid",
      name: "Meter Id",
      fieldName: "mid",
      minWidth: 50,
      maxWidth: 90,
      ...isSortable,
    },
    {
      key: "deviceType",
      name: "Device type",
      fieldName: "deviceType",
      minWidth: 70,
      maxWidth: 70,
      ...isSortable,
      ariaLabel: "Device Type",
    },
    {
      key: "type",
      name: "Type",
      fieldName: "type",
      minWidth: 30,
      maxWidth: 30,
      ...isSortable,
    },
    {
      key: "MSN",
      name: "MSN",
      fieldName: "MSN",
      minWidth: 100,
      maxWidth: 100,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.MSN} closeDelay={500}>
            {" "}
            {item.MSN}
          </TooltipHost>
        );
      },
    },
    {
      key: "channel",
      name: "Channel",
      fieldName: "channel",
      minWidth: 70,
      maxWidth: 100,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.channel} closeDelay={500}>
            {" "}
            {item.channel}
          </TooltipHost>
        );
      },
    },
    {
      key: "folderName",
      name: "Folder",
      fieldName: "folderName",
      minWidth: 150,
      maxWidth: 200,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.folderName} closeDelay={500}>
            {" "}
            {item.folderName}
          </TooltipHost>
        );
      },
    },
    {
      key: "dataFormat",
      name: "dataFormat",
      fieldName: "dataFormat",
      minWidth: 90,
      maxWidth: 90,
      ...isSortable,
    },
    {
      key: "FiTClientId",
      name: "FiT Client_ID",
      fieldName: "FiTClientId",
      minWidth: 100,
      maxWidth: 100,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.FiTClientId} closeDelay={500}>
            {" "}
            {item.FiTClientId}
          </TooltipHost>
        );
      },
    },
    {
      key: "FiTGeneratorAccNR",
      name: "FiT Client_RGS_ID",
      fieldName: "FiTGeneratorAccNR",
      minWidth: 120,
      maxWidth: 40,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.FiTGeneratorAccNR} closeDelay={500}>
            {" "}
            {item.FiTGeneratorAccNR}
          </TooltipHost>
        );
      },
    },
    {
      key: "FiTAccreditation",
      name: "FiT Accreditation_ID",
      fieldName: "FiTAccreditation",
      minWidth: 100,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.FiTAccreditation} closeDelay={500}>
            {" "}
            {item.FiTAccreditation}
          </TooltipHost>
        );
      },
    },
    {
      key: "factor",
      name: "Factor",
      fieldName: "factor",
      minWidth: 50,
      ...isSortable,
    },
    {
      key: "peakPower",
      name: "PeakPower",
      fieldName: "peakPower",
      minWidth: 50,
      ...isSortable,
      ariaLabel: "Peak Power",
    },
    {
      key: "FiT",
      name: "FiT",
      fieldName: "FiT",
      minWidth: 50,
      data: "boolean",
      ...isSortable,
    },
    {
      key: "include",
      name: "Include",
      fieldName: "include",
      minWidth: 50,
      data: "boolean",
      ...isSortable,
    },
    {
      key: "active",
      name: "active",
      fieldName: "active",
      minWidth: 50,
      data: "boolean",
      ...isSortable,
    },
    {
      key: "action",
      name: "Action",
      fieldName: "-",
      minWidth: 70,
      onRender: (meter: IMeter, index?: number) => {
        return (
          <span>
            <span className="action-icons">
              <FontAwesomeIcon
                title="Edit Meter"
                onClick={() => {
                  handleOnActionClick(index!);
                }}
                icon={faPencilAlt}
              />
            </span>
            <span className="action-icons">
              <FontAwesomeIcon
                onClick={async () => {
                  const confirm = await ConfirmDialog({
                    dialogContentProps: {
                      title: "Delete Meter",
                      closeButtonAriaLabel: "Close",
                      subText: `Are you want to delete?`,
                    },
                  });
                  if (confirm) handleOnDeleteMeter(index);
                }}
                title="Delete Meter"
                icon={faTrash}
              />
            </span>
          </span>
        );
      },
    },
  ];
  const [columns, setColumns] = useState(headerRow);

  const handleOnHeaderClick = (column?: IColumn): void => {
    const newColumns = SetDetailListHeaderSortDirection(column, columns);
    setColumns(newColumns);
  };

  const handleSaveSystem = async () => {
    const errors: any = validateSystem(selectedSystem);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    await dispatch(updateSystem(handlePercentage(selectedSystem, 0.01)));
    await dispatch(fetchSystems(systemState.systemFilterParams.schoolSlug, true));
    onSave(selectedSystem);
  };

  // format system.schools which can be use as selectedKeys in combobox
  const clonedSystem = useMemo(() => {
    const s: any = _.cloneDeep(selectedSystem);
    s.schools = s.schools.map((s: { slug: any; }) => s.slug)
    return s
  }, [selectedSystem])


  const handlOnRepullYield = async () => {
    const { epm } = await fetchEnergyDetailsfromPVGIS(selectedSystem.country || 'GB', true)
    let yieldPerMonth = { ...selectedSystem.target.yieldPerMonth }
    if (epm) {
      yieldPerMonth = setTargetMonthlyYield(epm.map((e: number) => (e * 100).toFixed(2)))
      setSystem({ ...selectedSystem, target: { ...selectedSystem.target, yieldPerMonth } })
    }
  }

  const totalDistribution = Object.values(selectedSystem.target.yieldPerMonth).reduce((c: any, v) => c + ((v || 0) * 1), 0)

  return (
    <>
      <ModalDialog
        isModalOpen={isModalOpen}
        title={"Add Edit Meter"}
        containerClassName={"modal-size-sm"}
        onDismiss={handleOnDismissModal}
      >
        <EditMeter
          system={selectedSystem}
          meter={
            selectedMeterIndex === -1
              ? defaultMeter
              : (selectedSystem?.meters[selectedMeterIndex!] as IMeter)
          }
          ftpFolders={ftpFolders}
          onCancel={handleOnDismissModal}
          onSave={handleOnSaveMeter}
        />
      </ModalDialog>
      <div className="edit-record">
        <div className="ms-Grid" dir="ltr">
          <section className="margin-top-md">
            <div className="ms-Grid-row">
              <DynamicForm
                fields={generalFields}
                data={selectedSystem}
                errors={errors}
                onChange={handleOnChangeInput}
              />
            </div>
          </section>
          <section className="margin-top-md">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg12">
                <Separator>Yield per month (%)</Separator>
              </div>
            </div>
            <div className="ms-Grid-row">
              <DynamicForm
                fields={targetFields}
                data={selectedSystem}
                errors={errors}
                onChange={handleOnChangeInput}
              />
              <TextField
                label="Total monthly Distibution"
                placeholder="Period"
                readOnly
                disabled
                className="ms-Grid-col ms-lg2"
                value={totalDistribution.toFixed(0)} />
              <ActionButton
                label="Pull yields per month from PVGIS"
                iconProps={{ iconName: "Info" }}
                onClick={handlOnRepullYield}
                className="ms-Grid-col ms-lg2 margin-top-md"
                allowDisabledFocus >
                Pull Yield from PVGIS
              </ActionButton>
            </div>

            <div className="ms-Grid-row">
              <DynamicForm
                fields={monthlyTargetFields}
                data={selectedSystem}
                errors={errors}
                onChange={handleOnChangeInput}
              />
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg12">
                <Separator></Separator>
              </div>
            </div>
          </section>
          <section className="margin-top-md">
            <div className="ms-Grid-row">
              <DynamicForm
                fields={ownershipFields}
                data={selectedSystem}
                errors={errors}
                onChange={handleOnChangeInput}
              />
              <ComboBox
                label="Services"
                selectedKey={selectedSystem.services! || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                  systemState.services!.map(s => ({ key: s, text: s })) as IComboBoxOption[]
                }
                onChange={(e: any, item: any) => handleOnChangeInput("services", item,)}
                styles={comboBoxStyles}
                className="ms-Grid-col ms-lg2"
              />
            </div>
          </section>
          <section className="margin-top-md">

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg12">
                <Separator>Show Meters</Separator>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg12">
                <DynamicForm
                  fields={displaySettingField}
                  data={selectedSystem}
                  errors={errors}
                  onChange={handleOnChangeInput}
                />
              </div>
            </div>
          </section>
          <section className="margin-top-md">

            <div className="ms-Grid-row">
              <DynamicForm
                fields={schoolFields}
                data={clonedSystem}
                errors={errors}
                onChange={handleOnChangeInput}
              />
            </div>
          </section>
          <section className="margin-top-md">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg12">
                <Separator>Meters</Separator>
              </div>
            </div>
            <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
              <Stack.Item>
                <ActionButton
                  iconProps={addIcon}
                  allowDisabledFocus
                  onClick={() => {
                    handleOnActionClick(-1);
                  }}
                >
                  Add Meter
                </ActionButton>
              </Stack.Item>
            </Stack>


            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg12">
                <DetailsList
                  items={selectedSystem?.meters || []}
                  columns={columns}
                  onRenderDetailsHeader={DetailListStickyHeader}
                  onColumnHeaderClick={(e, column) =>
                    handleOnHeaderClick(column)
                  }
                  compact={true}
                  selectionMode={SelectionMode.none}
                />
              </div>
            </div>

          </section>
        </div>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Stack horizontal horizontalAlign="center">
            <Stack.Item>
              <PrimaryButton
                text="Save"
                onClick={handleSaveSystem}
                className="btn-primary"
              />
              <DefaultButton onClick={onCancel} text="Cancel" />
            </Stack.Item>
          </Stack>
        </Sticky>
      </div>
    </>
  );
};

interface OwnProps {
  onCancel: () => void;
  onSave: (system: ISystem) => void;
  system: ISystem;
}

type Props = OwnProps;
