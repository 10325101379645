import { ActionButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

const ExpandableFilters = (props: ExpandableFilterProps) => {
  const [expanded, setExpanded] = useState(props.expanded || false);
  
  useEffect(() => {
    setExpanded(props.expanded || false)
  }, [props.expanded]);

  return (
    <>
      {expanded ? props.children : undefined}
      <ActionButton
        iconProps={{ iconName: expanded ? 'CollapseContent' : 'ExploreContent' }}
        text={expanded ? "Less" : "More"}
        onClick={() => setExpanded(!expanded)}
      />
    </>
  )
}

interface ExpandableFilterProps {
  children: React.ReactNode
  expanded?: boolean
}

export default ExpandableFilters;
