import Loader from "../common/Loader";
import CoreLogs from "../CoreLogs";
import ScriptsFilter from "./ScriptsFilter";

const ScriptsSettings = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
      }}
    >
      <Loader />
      <div>
        <header className="table-header">
          <h1>Run Scripts</h1>
        </header>
        <ScriptsFilter />
      </div>
      <div className="scripts-log">
        <CoreLogs />
      </div>
    </div>
  );
};

export default ScriptsSettings;
