import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { generateQueryString } from "../../utils/util"
import { host } from "../config"
import { IProjectFolder, IProjectFolderLink } from '@solarforschools/sfs-core/dist/projectFolder/types';

export const getCustomerFolderAPi = (query: any): Promise<any> => {
    return axios.get(`${host}/project-folder/customer-folder?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}
export const getListItemAPi = (query: any): Promise<any> => {
    return axios.get(`${host}/project-folder/list-item?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}
export const getDriveUrlAPi = (id: any): Promise<any> => {
    return axios.get(`${host}/project-folder/drive-url/${id}`).then(res => res.data)
}
export const selectProjectFolderApi = (data: { folder: IProjectFolder, folderLink: IProjectFolderLink }): Promise<any> => {
    return axios.post(`${host}/project-folder/select-folder`, data).then(res => res.data)
}
export const createProjectFolderApi = (folder: IProjectFolder): Promise<any> => {
    return axios.post(`${host}/project-folder`, folder).then(res => res.data)
}

export const unlinkPFApi = (listItemId: string, driveId:string): Promise<any> => {
    return axios.delete(`${host}/project-folder/unlink-pf/${listItemId}/${driveId}`).then(res => res.data)
}

