import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IMeterReading, ISystem } from "@solarforschools/sfs-core/dist/solardb/types";
import { IMeterReadingFilter } from "../../../components/admin/meterReading/types";
import { createMeterReadingAPi, deleteMeterReadingApi, deleteMultipeMeterReadingApi, exportReadingsApi, fillMeterReadingApi, getMeterReadingsApi, updateMeterReadingApi } from "../../client/meterReading";
import { getSystems } from "../../client/system";
import { downloadFileFromStream } from "../../../components/admin/common/utils";

// Action Creators

export const setMeterReadingFilter = (meterReadingFilter: IMeterReadingFilter) => {
  return { type: "SET_METER_READING_FILTER", meterReadingFilter };
};

export const setSystemList = (systems: ISystem[]) => {
  return { type: "METERS/SET_SYSTEMS_LIST", systems };
};


export const setMeterReadingList = (
  meterReadings: IMeterReading[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_METER_READING_LIST", meterReadings, totalCount, reset };
};

export const updateMeterReadingList = (meterReading: IMeterReading) => {
  return { type: "UPDATE_METER_READING", meterReading };
};

export const delMeterReading = (meterReading: IMeterReading) => {
  return { type: "DELETE_METER_READING", meterReading };
};

export const delMultipeMeterReading = (deletedMeterReading: IMeterReading[]) => {
  return { type: "DELETE_MULTIPLE_METER_READING", deletedMeterReading };
};

export const getSystemList =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { systems } = await getSystems({ schoolSlug: 'all' })
        dispatch(setSystemList(systems));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const exportMeterReadings = (params?: IMeterReadingFilter, reset: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const filters: IMeterReadingFilter =
          getState().web.meterReading.meterReadingFilter;
        reqBody = { ...filters };
      }
      const data: string = await exportReadingsApi(reqBody);
      downloadFileFromStream({ data, filename: "reading-report", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };
  
export const getMeterReadings =
  (params?: IMeterReadingFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IMeterReadingFilter =
            getState().web.meterReading.meterReadingFilter;
          reqBody = { ...filters };
        }
        const { meterReadings, totalCount } = await getMeterReadingsApi(reqBody);
        dispatch(setMeterReadingList(meterReadings, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateMeterReading =
  (params: IMeterReading) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { meterReading, msg } = await updateMeterReadingApi(params)
        dispatch(updateMeterReadingList(meterReading));
        toast.success(msg);
        dispatch(isLoading(false));
        return true
      } catch (e: any) {
        console.log(e.msg);
        toast.error(e?.response?.data?.msg || 'Something happend wrong')
        dispatch(isLoading(false));
        return false
      }
    };

export const deleteMeterReading =
  (data: IMeterReading) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteMeterReadingApi(data);
        dispatch(delMeterReading(data));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteMultipleMeterReading =
  (data: IMeterReading[]) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteMultipeMeterReadingApi(data);
        dispatch(delMultipeMeterReading(data));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const fillMeterReading =
  (params: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { msg } = await fillMeterReadingApi(params)
        const filter = getState().web.meterReading.meterReadingFilter
        dispatch(setMeterReadingFilter({ ...filter, page: 1 }))
        getMeterReadings({ ...filter, page: 1 }, true)
        toast.success(msg);
        dispatch(isLoading(false));
        return true
      } catch (e: any) {
        console.log(e);
        toast.error(e?.response?.data?.msg || 'Something happend wrong')
        dispatch(isLoading(false));
        return false
      }
    };