
import { IOpportunity } from "@solarforschools/sfs-core/dist/opportunity/type";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";


let GetOppAPiAbortController: any = null

export const getOpportunitiesApi = (query: any): Promise<any> => {
  if (GetOppAPiAbortController) GetOppAPiAbortController.abort()
  GetOppAPiAbortController = new AbortController()
  return axios.get(`${host}/opportunity?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetOppAPiAbortController.signal }).then(res => res.data)
}


export const createOpportunityAPi = ({ opportunity, stage }: any): Promise<any> => {
  return axios
    .post(`${host}/opportunity`, { opportunity, stage })
    .then((res) => res.data);
};

export const updateOpportunityApi = ({ opportunity, stage }: any): Promise<any> => {
  return axios
    .put(`${host}/opportunity/${opportunity._id}`, { opportunity, stage })
    .then((res) => res.data);
};

export const deleteOpportunityApi = (opportunity: IOpportunity): Promise<any> => {
  return axios
    .delete(`${host}/opportunity/${opportunity._id}`)
    .then((res) => res.data);
};

export const getOpportunityApi = (slug: string, type: string = 'School',): Promise<any> => {
  return axios.get(`${host}/opportunity/${slug}?type=${type}`).then(res => res.data)
}

export const exportOpportunityApi = (query: any): Promise<any> => {
  return axios.get(`${host}/opportunity/download?${generateQueryString(query as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const getOpportunityoDealOrgApi = (dealId: number): Promise<any> => {
  return axios.get(`${host}/opportunity/deal/organization/${dealId}`).then(res => res.data)
}

