import { IProject } from "@solarforschools/sfs-core/dist/project/types"
import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { StringMap } from "../../components/admin/common/types"
import { IProjectsFilter } from "../../components/admin/project/types"
import { generateQueryString } from "../../utils/util"
import { host } from "../config"

let GetProjectAPiAbortController: AbortController

export const getProjectsApi = (params: any): Promise<{ projects: IProject[], count: number }> => {
    const queryStr = generateQueryString(params)
    if (GetProjectAPiAbortController) {
        GetProjectAPiAbortController.abort(); // Tell the browser to abort request
    }
    GetProjectAPiAbortController = new AbortController();
    return axios.get(`${host}/projects?${queryStr}`, { signal: GetProjectAPiAbortController.signal }).then(res => {
        return res.data
    })
}

export const deleteProjectApi = (slug: any): Promise<any> => {
    return axios.delete(`${host}/projects/${slug}`).then(res => res.data)
}


export const getProjectFundingModelsApi = (region: string): Promise<any> => {
    return axios.get(`${host}/projects/funding-models/${region}`).then(res => res.data)
}
