import React, { useContext } from 'react'
import { CardContext, CardContextType } from '../../layouts';
import { convertToTonnes } from '../../utils/tileHelper';

export const FrontContent = () => {
    const { carbonSavings } = useContext(CardContext) as CardContextType;

    if (!carbonSavings?.overall) return <></>

    const renderThis = convertToTonnes(carbonSavings.overall)

    return (
        <>
            <div className="plain-content" >
                <div className="plain-content--children">
                    {new Intl.NumberFormat('en-GB').format(renderThis) + 't'}
                </div>
                <p>Life Time Savings</p>
            </div>
        </>

    )
}


export const BackContent = () => {
    const { carbonSavings } = useContext(CardContext) as CardContextType;

    if (!carbonSavings?.lifeTime) return <></>

    const renderThis = convertToTonnes(carbonSavings.lifeTime)

    return (
        <>
            <div className="plain-content" style={{marginTop:"2.3rem"}}>
                <div className="plain-content--children">
                    {new Intl.NumberFormat('en-GB').format(renderThis) + 't'}
                </div>
                <p>CO2 Savings Y1</p>
            </div>
        </>
    )
}
