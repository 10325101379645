import { Action } from "./actionTypes";
import { IMeterReading } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { RecordPerPage } from "../../../components/admin/common/common";
import { IFitReportingFilter } from "../../../components/admin/fitReporting/types";

// States' definition
export interface IFitReportingState {
  isLoading: boolean;
  fitReadings: IMeterReading[];
  fitReportingFilter: IFitReportingFilter;
  totalCount: number;
  systems: any[];
}
export interface State {
  fitReporting: IFitReportingState;
}

const fitReporting = (
  state: IFitReportingState = {
    isLoading: false,
    fitReadings: [],
    fitReportingFilter: {
      page: 1,
      pageSize: RecordPerPage,
      sortField: "date",
      sortOrder: "desc",
      system: "all",
      ownedBy: "all",
      date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
    totalCount: 0,
    systems: []
  },
  action: Action
): IFitReportingState => {
  switch (action.type) {
    case "SET_FIT_REPORTING_FILTER":
      return { ...state, fitReportingFilter: action.fitReportingFilter };
    case "SET_FIT_REPOERTING_LIST":
      const readings = action.reset
        ? action.fitReadings
        : [...state.fitReadings, ...action.fitReadings];
      return { ...state, fitReadings: readings, totalCount: action.totalCount };

    case "FITREPORTING/SET_SYSTEMS_LIST":
      return { ...state, systems: action.systems };
    default:
      return state;
  }
};

export default fitReporting;
