import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { IYieldsFilter } from "../../../components/admin/yields/types";
import { IRTYield } from "@solarforschools/sfs-core/dist/solardb/types";
import { getyieldsApi } from "../../client/yields";
import { IRTYieldsFilter } from "../../../components/admin/rtYields/types";
import { deleteMultipeRTReadingApi, deleteMultipeRTYieldsApi, exportRTYieldsApi, getRTYieldsApi } from "../../client/rtYields";
import { downloadFileFromStream } from "../../../components/admin/common/utils";
import { toast } from "react-toastify";

// Action Creators

export const setRTYieldsFilter = (rtYieldsFilter: IRTYieldsFilter) => {
  return { type: "SET_RTYIELDS_FILTER", rtYieldsFilter };
};

export const setRTYieldsList = (
  rtYields: IRTYield[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_RTYIELDS_LIST", rtYields, totalCount, reset };
};

export const deleteMultipleRTReading =
  (data: any[]) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteMultipeRTReadingApi(data);
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteMultipleRTYields =
  (data: any[]) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteMultipeRTYieldsApi(data);
        dispatch(getRTYields());
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const getRTYields =
  (params?: IRTYieldsFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IRTYieldsFilter =
            getState().web.rtYields.rtYieldsFilter;
          reqBody = { ...filters };
        }
        const { rtYields, totalCount } = await getRTYieldsApi(reqBody);
        dispatch(setRTYieldsList(rtYields, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const exportRtYields = (params?: IRTYieldsFilter, reset: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      let reqBody = params;
      if (!reqBody) {
        const filters: IRTYieldsFilter =
          getState().web.rtYields.rtYieldsFilter;
        reqBody = { ...filters };
      }
      const res = await exportRTYieldsApi(reqBody)
      downloadFileFromStream({ data: res.data, filename: "RTYields", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };
