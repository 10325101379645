import { IBillingPeriod } from "@solarforschools/sfs-core/dist/billing/types";
import { Action } from "./actionTypes";
import { IBillingPeriodFilter } from "../../../components/admin/period/types";

// States' definition
export interface IBillingPeriodState {
  isLoading: boolean;
  periods: IBillingPeriod[];
  periodFilter: IBillingPeriodFilter;
  totalCount: number;
}
export interface State {
  billingPeriod: IBillingPeriodState;
}

const billingPeriod = (
  state: IBillingPeriodState = {
    isLoading: false,
    periods: [],
    periodFilter: {
      cycle: "",
      period: "",
      page: 1,
      pageSize: 50,
      sortField: "Start",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): IBillingPeriodState => {
  switch (action.type) {
    case "BILLING/SET_PERIOD_FILTER":
      return { ...state, periodFilter: action.periodFilter };
    case "BILLING/SET_PERIOD_LIST":
      const periods = action.reset
        ? action.periods
        : [...state.periods, ...action.periods];
      return { ...state, periods, totalCount: action.totalCount };
    case "BILLING/DELETE_PERIOD": {
      const stateObj = { ...state };
      const periods: IBillingPeriod[] = stateObj.periods.filter(
        (s) => s._id?.toString() !== action.periodId.toString()
      );
      const periodFilter = { ...stateObj.periodFilter };
      const { page, pageSize } = periodFilter;
      return {
        ...state,
        periods,
        totalCount: stateObj.totalCount - 1,
        periodFilter: {
          ...periodFilter,
          page: Math.ceil((periods.length / page!) * pageSize!),
        },
      };
    }
    case "BILLING/UPDATE_PERIOD": {
      const periods = [...state.periods];
      const index = periods.findIndex(
        (s) => s._id?.toString() === action.period?._id?.toString()
      );
      if (index > -1) {
        periods[index] = action.period;
      } else {
        periods.unshift(action.period);
      }
      return {
        ...state,
        periods,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default billingPeriod;
