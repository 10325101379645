import { Action } from "./actionTypes";
import { IMeterReading, IYield } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { IMeterReadingFilter } from "../../../components/admin/meterReading/types";
import { RecordPerPage } from "../../../components/admin/common/common";

// States' definition
export interface IMeterReadingState {
  isLoading: boolean;
  meterReadings: IMeterReading[];
  meterReadingFilter: IMeterReadingFilter;
  totalCount: number;
  systems: any[];
}
export interface State {
  meterReading: IMeterReadingState;
}

const meterReading = (
  state: IMeterReadingState = {
    isLoading: false,
    meterReadings: [],
    meterReadingFilter: {
      page: 1,
      pageSize: RecordPerPage,
      sortField: "date",
      sortOrder: "desc",
      startEnd: false,
      start: moment().subtract(5, 'day').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      type: ['I', 'E', 'G'],
      rType: 'all'
    },
    totalCount: 0,
    systems: []
  },
  action: Action
): IMeterReadingState => {
  switch (action.type) {
    case "SET_METER_READING_FILTER":
      return { ...state, meterReadingFilter: action.meterReadingFilter };
    case "SET_METER_READING_LIST":
      const meterReadings = action.reset
        ? action.meterReadings
        : [...state.meterReadings, ...action.meterReadings];
      return { ...state, meterReadings, totalCount: action.totalCount };
    case "DELETE_METER_READING": {
      const stateObj = { ...state };
      const { id, date, type, mid, MSN } = action.meterReading || {}
      const meterReadings: IMeterReading[] = stateObj.meterReadings.filter(
        (s) => s.id !== id && (!moment(s.date).isSame(date)) && s.type !== type && s.mid !== mid && s.MSN !== MSN
      );
      const meterReadingFilter = { ...stateObj.meterReadingFilter };
      const { page, pageSize } = meterReadingFilter;
      return {
        ...state,
        meterReadings,
        totalCount: stateObj.totalCount - 1,
        meterReadingFilter: {
          ...meterReadingFilter,
          page: Math.ceil((meterReadings.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_METER_READING": {
      const meterReadings = [...state.meterReadings];
      const { id, date, type, mid, MSN } = action.meterReading || {}
      const index = meterReadings.findIndex(
        (s) => s.id === id && (moment(s.date).isSame(date)) && s.type === type && s.mid === mid && s.MSN === MSN
      );
      if (index > -1) {
        meterReadings[index] = action.meterReading;
      } else {
        meterReadings.unshift(action.meterReading);
      }
      return {
        ...state,
        meterReadings,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    case "METERS/SET_SYSTEMS_LIST":
      return { ...state, systems: action.systems };
    case "DELETE_MULTIPLE_METER_READING": {
      const ids = action.deletedMeterReading.map(d => d._id?.toString())
      const meterReadings = state.meterReadings.filter(d => !ids.includes(d._id?.toString()));
      return { ...state, meterReadings, totalCount: state.totalCount - action.deletedMeterReading.length };
    }
    default:
      return state;
  }
};

export default meterReading;
