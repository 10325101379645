import { Action, IPDFList, IUserParams } from "./actionTypes";
import { toast } from "react-toastify";

// States' definition
export interface IReport {
  isLoading: boolean;
  userParams: IUserParams;
  pdfList: IPDFList[];
}
export interface State {
  report: IReport;
}

const report = (
  state: IReport = {
    isLoading: false,
    userParams: {
      region: undefined,
      dataset: undefined,
      template: undefined,
      data: "",
      projectList: [],
      schoolList: [],
    },
    pdfList: [],
  },
  action: Action
): IReport => {
  switch (action.type) {
    case "SET_TEMPLATE_FILES":
      return {
        ...state,
        userParams: { ...state.userParams, fileList: action.template },
      };
    case "SET_USER_PARAMS":
      return { ...state, userParams: action.params };
    case "SET_PDF_DATASET":
      return {
        ...state,
        userParams: { ...state.userParams, data: action.data },
      };
    case "SET_PDF_LIST":
      return { ...state, pdfList: action.lists };
    case "SET_PDF_PROJECT":
      return {
        ...state,
        userParams: { ...state.userParams, projectList: action.projects },
      };
    case "SET_PDF_SCHOOL":
      return {
        ...state,
        userParams: { ...state.userParams, schoolList: action.schools },
      };
    case "SET_PDF_GEO":
      return {
        ...state,
        userParams: { ...state.userParams, geoList: action.geo },
      };
    default:
      return state;
  }
};

export default report;
