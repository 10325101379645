import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import "./question.css";
import {
  IAnswer,
  IQuestionItems,
  IQuestionTemplate,
  QuestionItems,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { changeQuestionTemplate } from "../../../../store/content/action";
import FileUpload from "../formElements/FileUpload";
import { getName } from "../util";

const Q4 = ({
  content,
  handleSubmit,
  handleTextBoxChange,
  changeQuestionTemplate,
}: Props) => {
  let moduleID: string, unitID: string;
  if (content.screen?.moduleID && content.screen?.unitID) {
    moduleID = content.screen.moduleID;
    unitID = content.screen.unitID;
  }

  const thisTemplate = TemplateItems.Q4;

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const handleFileChange = (file: File | null) => {
    let questionItem: IQuestionItems = {
      type: QuestionItems.IMAGE,
      sequence: 3,
      text: "",
      file: file,
      fileName: file?.name,
    };
    let questionContents = content.questionTemplate?.items;
    if (content.questionTemplate && questionContents) {
      questionContents = questionContents.filter((c) => c.sequence !== 3);
      questionContents.push(questionItem);
      content.questionTemplate.items = questionContents;
    } else {
      if (!content.questionTemplate)
        content.questionTemplate = {
          isEditing: false,
        };
      content.questionTemplate["items"] = [questionItem];
    }
    if (content && content.questionTemplate)
      changeQuestionTemplate(content.questionTemplate);
  };

  const handleTips = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      tips: e.target.value,
    };
    dispatchEvent(answer);
  };

  const handleAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      text: e.target.value,
    };
    dispatchEvent(answer);
  };

  const dispatchEvent = (answer: IAnswer) => {
    let questionTemplate: IQuestionTemplate = {
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer,
    };
    changeQuestionTemplate(questionTemplate);
  };
  const { submitButtonText, imageURL } = getName(content, 3);
  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, paddingTop: 0, height: "80%" }}
        >
          <Textbox
            placeholder="Enter the Question here"
            maxChar={80}
            style={{ fontSize: "1rem", fontWeight: "normal" }}
            containerStyle={{ margin: "1rem 1rem", height: "20%" }}
            onChange={(e) =>
              handleTextBoxChange(e, 1, thisTemplate, QuestionItems.QUESTION)
            }
            value={
              content.questionTemplate?.items?.find(
                (temp) => temp.sequence === 1
              )?.text
            }
          />

          <div className="q-options-container">
            <Textarea
              placeholder="Type your answer here"
              maxChar={500}
              width="100%"
              disabled
              style={{ pointerEvents: "none" }}
              containerStyle={{ opacity: 0.4 }}
            />
          </div>
          <FileUpload
            name="+ Add Visual"
            url={imageURL}
            width="100%"
            height="50%"
            onChangeCallback={handleFileChange}
          />
        </div>
      </aside>
      <aside>
        <p>
          Use the textboxes left hand side to add the question and a visual.
        </p>
        <div className="correct-answer">
          <div className="choose-correct-answer">
            <Label name="Enter the answer here." fontSize="large" />
            <div className="answer-options">
              <Textarea
                placeholder="Answer"
                maxChar={100}
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                }}
                containerStyle={{
                  height: "140%",
                  width: "85%",
                }}
                onChange={handleAnswer}
                value={content.questionTemplate?.answer?.text}
              />
            </div>
          </div>
          <div className="answer-comment-box">
            <Label
              name="Correct!"
              fontSize="xx-large"
              style={{ marginTop: 0 }}
            />
            <Textarea
              placeholder="Enter any additional text to appear on the correct / not quite message (optional)"
              maxChar={50}
              width="100%"
              containerStyle={{ height: "55%" }}
              onChange={handleTips}
              value={content.questionTemplate?.answer?.tips}
            />
          </div>
        </div>
        <Button
          width="20%"
          height="8%"
          name={submitButtonText}
          fontSize="1.5rem"
          onClick={handleSubmit}
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeQuestionTemplate: (template: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: () => void;
  handleTextBoxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(questionTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Q4);
