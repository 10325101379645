import { combineReducers } from "redux";
import { TITLE } from "../../components/learning/Content/config";
import {
  IBlock,
  IModule,
  Action,
  IUnit,
  IScreen,
  Screens,
  IContentTemplate,
  IQuestionTemplate,
  IDBModule,
  IGlossary,
  IEditContent,
} from "./actionTypes";
import { toast } from "react-toastify";

// States' definition
export interface IContent {
  isLoading: boolean;
  isShowPopup?: boolean;
  isEditPopup?: boolean;
  editContent?: IEditContent;
  screen?: IScreen;
  block?: IBlock | null;
  modules?: IModule[];
  units?: IUnit[];
  contentTemplate?: IContentTemplate | null;
  questionTemplate?: IQuestionTemplate | null;
  allModules?: IDBModule[];
  glossary: IGlossary;
}
export interface State {
  content: IContent;
}

const content = (
  state: IContent = {
    isLoading: false,
    isShowPopup: false,
    isEditPopup: false,
    editContent: {
      id: "",
    },
    modules: [],
    units: [],
    screen: {
      currentScreen: Screens.MODULE,
      title: TITLE.MODULE,
    },
    contentTemplate: {
      items: [],
      isEditing: false,
      glossary: [],
    },
    questionTemplate: {
      isEditing: false,
    },
    block: {
      isQuiz: false,
      isFinalQuiz: false,
      warningMessage: "",
      prerequisites: [],
      questionLength: 0,
      isPremium: false,
    },
    glossary: {
      glossaryItems: [],
    },
  },
  action: Action
): IContent => {
  switch (action.type) {
    case "SET_MODULES":
      return { ...state, isLoading: false, modules: action.modules };
    case "SET_SHOWPOPUP":
      return { ...state, isShowPopup: action.isShowPopup, block: null };
    case "SET_EDITPOPUP":
      return { ...state, isEditPopup: action.isEditPopup };
    case "SET_EDIT_CONTENT":
      return { ...state, editContent: action.editContent };
    case "SET_BLOCK":
      return { ...state, block: action.block };
    case "UPDATE_MODULE":
      state.modules?.push(action.module);
      return { ...state, isShowPopup: false };
    case "SET_SCREEN":
      return { ...state, screen: action.screen };
    case "UPDATE_UNIT":
      state.units?.push(action.unit);
      return { ...state, isShowPopup: false };
    case "SET_UNIT":
      return { ...state, isLoading: false, units: action.units };
    case "SET_CONTENT_TEMPLATE":
      return { ...state, contentTemplate: action.contentTemplate };
    case "SET_QUESTION_TEMPLATE":
      return { ...state, questionTemplate: action.questionTemplate };
    case "SET_ALL_CONTENT":
      return { ...state, allModules: action.params };
    case "SET_GLOSSARY":
      return { ...state, glossary: { glossaryItems: action.glossaryItems } };
    case "UPDATE_UNIT_AFTER_EDIT": 
        const unitId = action.unit?.id;
        const tempUnits = state.units as IUnit[]
        const findUnitIndex = tempUnits?.findIndex(elm => elm.id === unitId)

        if(findUnitIndex !== -1) {
            tempUnits[findUnitIndex] = action.unit
            toast.success("Unit updated successfully")
            return {
              ...state,
              units: tempUnits
            }
        }
        return state
    default:
      return state;
  }
};
export default combineReducers<State>({
  content,
});
