import { useEffect } from "react";
import {
  Checkbox,
  ComboBox,
  DatePicker,
} from "@fluentui/react/lib/index";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { isLoading } from "../../../../store/admin/action";
import { dropdownStyles } from "../../common/common";
import {
  fetchInvoicePriceData,
  fetchLiveSchools,
  fetchReportData,
  fetchSites,
  fetInvoiceCycle,
  setSchoolReportForm,
} from "../../../../store/admin/reporting/reports/action";

import Loader from "../../common/Loader";

import { IReports } from "../../../../store/admin/reporting/reports/reducer";
import ReportList from "./ReportList";
import _, { debounce } from "lodash";

function School() {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(isLoading(true));
      await dispatch(fetchLiveSchools());
      await dispatch(fetInvoiceCycle());
      await dispatch(isLoading(false));
    })();
  }, []);

  const report: IReports = useSelector<RootState, IReports>(
    (state) => state.web.reports
  );

  useEffect(() => {
    const { schoolNames, siteNames, customHeaders, period, invoiceCycle } =
      report.schoolReportForm;
    if (
      schoolNames.length > 0 &&
      siteNames.length > 0 &&
      customHeaders.length > 0 &&
      period !== ""
    ) {
      if (period === "invoice" && invoiceCycle === "") {
        return;
      }
      report.schoolReportForm.siteNames =
        report.schoolReportForm.siteNames.filter((s) => s !== "all");
      dispatch(fetchReportData(report.schoolReportForm));
      dispatch(fetchInvoicePriceData(report.schoolReportForm));
    }
  }, [report.schoolReportForm]);

  const handleOnChange = (item?: any, key?: string): void => {
    if (item) {
      let filters = { ...report.schoolReportForm }
      switch (key) {
        case "trustNames":
        case "regionNames":
        case "schoolNames":
        case "siteNames":
        case "trustNames":
        case "customHeaders": {
          let selected = item?.selected;
          if (key === "trustNames" && selected && item!.key === "all") {
            dispatch(
              setSchoolReportForm({
                ...filters,
                schoolNames: [],
                siteNames: [],
                [key]: report.liveSchoolTrusts.filter(f => report.schoolReportForm.regionNames.includes(f.country) || f.key === "all").map((obj) => obj.key),
              })
            );
            return;
          } else if (key === "trustNames" && !selected && item!.key === "all") {
            filters = {
              ...filters,
              schoolNames: [],
              siteNames: [],
              [key]: [],
            }
            dispatch(setSchoolReportForm({ ...filters, }));
            return;
          }
          if (key === "regionNames" && selected && item!.key === "all") {
            dispatch(
              setSchoolReportForm({
                ...filters,
                trustNames: [],
                schoolNames: [],
                siteNames: [],
                [key]: report.liveSchoolRegions.map((obj) => obj.key),
              })
            );
            return;
          } else if (key === "regionNames" && !selected && item!.key === "all") {
            filters = {
              ...filters,
              trustNames: [],
              schoolNames: [],
              siteNames: [],
              [key]: [],
            }
            dispatch(setSchoolReportForm({ ...filters, }));
            return;
          }

          if (key === "schoolNames" && selected && item!.key === "all") {
            filters = { ...filters, [key]: report.liveSchools.map((obj) => obj.key), }
            dispatch(setSchoolReportForm({ ...filters, }));
            const allSchools = report.liveSchools.filter(f => (report.schoolReportForm.regionNames.includes(f.country) && report.schoolReportForm.trustNames.includes(f.trust) || (report.schoolReportForm.regionNames.includes(f.country) && blankTrust && !f.trust)) || f.key === "all").map(s => s.key)
            allSchools.shift();
            dispatch(fetchSites(allSchools));
            return;
          } else if (
            key === "schoolNames" &&
            !selected &&
            item!.key === "all"
          ) {
            dispatch(
              setSchoolReportForm({
                ...filters,
                [key]: [],
              })
            );
            dispatch(fetchSites([]));
            return;
          }

          if (key === "siteNames" && selected && item!.key === "all") {
            dispatch(
              setSchoolReportForm({
                ...filters,
                [key]: report.siteNames.map((obj) => obj.key),
              })
            );
            return;
          } else if (key === "siteNames" && !selected && item!.key === "all") {
            filters = {
              ...filters,
              [key]: [],
            }
            dispatch(setSchoolReportForm({ ...filters, }));
            return;
          }
          const selectedItems = report.schoolReportForm[key];
          const items = selected
            ? [...selectedItems, item!.key]
            : selectedItems.filter((k) => k !== item!.key);

          filters = { ...filters, [key]: items, }
          dispatch(setSchoolReportForm({ ...filters, }));

          if (key === "schoolNames") {
            dispatch(fetchSites(items));
          }
          return
        }
        case "period":
        case "invoiceCycle":
        case "from":
        case "to":
        case "isCompare":
        case "compareInvoiceCycle":
        case "compareFrom":
        case "ownedBy":
        case "compareTo": {
          let value;
          if (
            key === "from" ||
            key === "to" ||
            key === "compareFrom" ||
            key === "compareTo"
          ) {
            let cTo = "to";
            let fromDate = report.schoolReportForm.from;
            if (key === "compareFrom" || key === "compareTo") {
              cTo = "compareTo";
              fromDate = report.schoolReportForm.compareFrom;
            }
            value = moment(item).format("YYYY-MM-DD");
            if (key === cTo) {
              const diff = moment(fromDate || new Date()).diff(
                moment(item),
                "days"
              );
              if (diff > 0) {
                value = moment(new Date()).format("YYYY-MM-DD");
              }
            }
          } else {
            value = item.key;
          }
          filters = {
            ...filters,
            [key]: value,
          };

          if (key === "period" && report.schoolReportForm.isCompare) {
            filters.isCompare = false;
          }
          break;
        }
      }
      dispatch(setSchoolReportForm(filters));
      const { period, from, to, schoolNames, siteNames, regionNames, trustNames } = filters
      if (regionNames.length && trustNames.length && siteNames?.length && schoolNames?.length && period && ((period === "custom" && to && from) || period === 'lifetime')) {
        dispatch(fetchReportData(filters));
        dispatch(fetchInvoicePriceData(filters));
      }
    }
  };

  const customHeaders = [
    { key: "generated", text: "Generation" },
    { key: "exported", text: "Export" },
    { key: "imported", text: "Import" },
    { key: "selfConsumption", text: "Self Consumption (SC)" },
    { key: "consumption", text: "Consumption" },
    { key: "target", text: "Expected Yield" },
    { key: "selfConsumptionTarget", text: "Expected SC" },
    { key: "perfRatio", text: "Peformance Ratio" },
    { key: "avoidedCO2", text: "Co2" },
    { key: "trees", text: "Trees" },
    { key: "savings", text: "Savings" },
  ];

  const blankTrust = report.schoolReportForm.trustNames.find(f => f === '-');
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Live Schools Report</h1>

        <div
          className="ms-Grid ms-Grid-row"
          dir="ltr"
          style={{
            width: "-webkit-fill-available",
            minWidth: "700px",
            maxWidth: "1300px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ComboBox
            label="Region"
            multiSelect
            selectedKey={report.schoolReportForm.regionNames}
            placeholder="Type Region Name"
            allowFreeform={true}
            autoComplete={"on"}
            options={_.uniqBy(report.liveSchoolRegions, 'key')}
            className="ms-Grid-col ms-lg2"
            onChange={(e, item) => handleOnChange(item, "regionNames")}
          />
          <ComboBox
            label="Trust"
            multiSelect
            selectedKey={report.schoolReportForm.trustNames}
            placeholder="Type Trust Name"
            allowFreeform={true}
            autoComplete={"on"}
            options={[...report.liveSchoolTrusts.filter(f => report.schoolReportForm.regionNames.includes(f.country) || f.key === "-" || f.key === "all")]}
            className="ms-Grid-col ms-lg2"
            onChange={(e, item) => handleOnChange(item, "trustNames")}
          />
          <ComboBox
            label="School Name"
            multiSelect
            selectedKey={report.schoolReportForm.schoolNames}
            placeholder="Type to filter school name"
            allowFreeform={true}
            autoComplete={"on"}
            options={report.liveSchools.filter(f => (report.schoolReportForm.regionNames.includes(f.country) && report.schoolReportForm.trustNames.includes(f.trust) || (report.schoolReportForm.regionNames.includes(f.country) && blankTrust && !f.trust)) || f.key === "all")}
            className="ms-Grid-col ms-lg2"
            onChange={(e, item) => handleOnChange(item, "schoolNames")}
          />
          {report.schoolReportForm.schoolNames.length > 0 && (
            <ComboBox
              label="Site name"
              placeholder="Type to filter site name"
              multiSelect
              selectedKey={report.schoolReportForm.siteNames}
              allowFreeform={true}
              autoComplete={"on"}
              options={report.siteNames}
              className="ms-Grid-col ms-lg2"
              onChange={(e, item) => handleOnChange(item, "siteNames")}
            />
          )}
          <ComboBox
            label="OwnedBy"
            placeholder="Select OwnedBy"
            allowFreeform={true}
            autoComplete={"on"}
            selectedKey={report.schoolReportForm.ownedBy || 'all'}
            options={[
              { key: "all", text: "All" },
              { key: "School", text: "School" },
              { key: "CBS", text: "CBS" },
              { key: "GSS1", text: "GSS1" },
              { key: "GSS2", text: "GSS2" },
            ]}
            className="ms-Grid-col ms-lg2"
            onChange={(e, item) => handleOnChange(item, "ownedBy")}
          />
          <ComboBox
            label="Period"
            placeholder="Select time period"
            allowFreeform={true}
            autoComplete={"on"}
            selectedKey={report.schoolReportForm.period}
            options={[
              { key: "lifetime", text: "Lifetime" },
              { key: "custom", text: "Custom" },
            ]}
            className="ms-Grid-col ms-lg2"
            onChange={(e, item) => handleOnChange(item, "period")}
          />
          {report.schoolReportForm.period !== "invoice" && (
            <ComboBox
              label="Custom Headers (Export)"
              placeholder="Select headers"
              multiSelect
              selectedKey={report.schoolReportForm.customHeaders}
              allowFreeform={true}
              autoComplete={"on"}
              options={customHeaders}
              className="ms-Grid-col ms-lg3"
              onChange={(e, item) => handleOnChange(item, "customHeaders")}
            />)}
          {report.schoolReportForm.period === "invoice" && (
            <ComboBox
              label="Invoice Cycle"
              placeholder="Select invoice cycle"
              selectedKey={report.schoolReportForm.invoiceCycle}
              allowFreeform={true}
              autoComplete={"on"}
              options={report.invoiceCycles}
              className="ms-Grid-col ms-lg2"
              onChange={(e, item) => handleOnChange(item, "invoiceCycle")}
            />
          )}
          {report.schoolReportForm.period === "custom" && (
            <>
              <DatePicker
                label="From"
                placeholder="Start date"
                ariaLabel="Select a date"
                value={
                  (report.schoolReportForm.from &&
                    new Date(report.schoolReportForm.from)) ||
                  new Date(moment().subtract(1, "month").format("YYYY-MM-DD"))
                }
                onSelectDate={(date) => handleOnChange(date, "from")}
                styles={dropdownStyles}
                className="ms-Grid-col ms-lg2"
              />
              <DatePicker
                label="To"
                placeholder="End date"
                ariaLabel="Select a date"
                value={
                  (report.schoolReportForm.to &&
                    new Date(report.schoolReportForm.to)) ||
                  new Date()
                }
                onSelectDate={(date) => handleOnChange(date, "to")}
                styles={dropdownStyles}
                className="ms-Grid-col ms-lg2"
              />
            </>
          )}
          <Checkbox
            label="Is Compare"
            className="compare-checkbox"
            checked={report.schoolReportForm.isCompare}
            onChange={(ev, isChecked?: boolean) =>
              handleOnChange({ key: isChecked }, "isCompare")
            }
            disabled={report.schoolReportForm.period === "lifetime"}
          />
        </div>
        {report.schoolReportForm.isCompare && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {report.schoolReportForm.period === "invoice" && (
              <ComboBox
                label="Invoice Cycle"
                placeholder="Select invoice cycle"
                selectedKey={report.schoolReportForm.compareInvoiceCycle}
                allowFreeform={true}
                autoComplete={"on"}
                options={report.invoiceCycles}
                className="ms-Grid-col ms-lg2"
                onChange={(e, item) =>
                  handleOnChange(item, "compareInvoiceCycle")
                }
              />
            )}
            {report.schoolReportForm.period === "custom" && (
              <>
                <DatePicker
                  label="From"
                  placeholder="Start date"
                  ariaLabel="Select a date"
                  value={new Date(report.schoolReportForm.compareFrom)}
                  onSelectDate={(date) => handleOnChange(date, "compareFrom")}
                  styles={dropdownStyles}
                  className="ms-Grid-col ms-lg2"
                />
                <DatePicker
                  label="To"
                  placeholder="End date"
                  ariaLabel="Select a date"
                  value={new Date(report.schoolReportForm.compareTo)}
                  onSelectDate={(date) => handleOnChange(date, "compareTo")}
                  styles={dropdownStyles}
                  className="ms-Grid-col ms-lg2"
                />
              </>
            )}
          </div>
        )}
      </header>
      <ReportList />
    </div>
  );
}

export default School;
