import { IUserSkill } from "@solarforschools/sfs-core/dist/user/types";
import { IUserSkillFilter } from "../../../components/admin/userSkill/types";
import { Action } from "./actionTypes";

// States' definition
export interface IUserSkillState {
  isLoading: boolean;
  userSkills: IUserSkill[];
  userSkillFilter: IUserSkillFilter;
  totalCount: number;
}
export interface State {
  userSkillState: IUserSkillState;
}

const userSkill = (
  state: IUserSkillState = {
    isLoading: false,
    userSkills: [],
    userSkillFilter: {
      page: 1,
      pageSize: 50,
      sortField: "Start",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): IUserSkillState => {
  switch (action.type) {
    case "SET_USER_SKILL_FILTER":
      return { ...state, userSkillFilter: action.userSkillFilter };
    case "SET_USER_SKILL_LIST":
      const userSkills = action.reset
        ? action.userSkills
        : [...state.userSkills, ...action.userSkills];
      return { ...state, userSkills, totalCount: action.totalCount };
    case "DELETE_USER_SKILL": {
      const stateObj = { ...state };
      const userSkills: IUserSkill[] = stateObj.userSkills.filter(
        (s) => s.id !== action.id
      );
      const userSkillFilter = { ...stateObj.userSkillFilter };
      const { page, pageSize } = userSkillFilter;
      return {
        ...state,
        userSkills,
        totalCount: stateObj.totalCount - 1,
        userSkillFilter: {
          ...userSkillFilter,
          page: Math.ceil((userSkills.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_USER_SKILL": {
      const userSkills = [...state.userSkills];
      const index = userSkills.findIndex(
        (s) => s.id === action.userSkill?.id
      );
      if (index > -1) {
        userSkills[index] = action.userSkill;
      } else {
        userSkills.unshift(action.userSkill);
      }
      return {
        ...state,
        userSkills,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default userSkill;
