import { ComboBox, Dropdown, Stack, TextField } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchProducts, setProductFilter } from '../../../store/admin/product/action';
import { IProduct } from '../../../store/admin/product/reducer';
import { Regions } from '../common/common';

const ProductFilters = (props: ProductFilterProps) => {
    const dispatch = useDispatch()
    const product: IProduct = useSelector<RootState, IProduct>((state) => state.web.product)
    const handleOnChangeInput = (
        value?: any,
        key?: string
    ) => {
        const params = { ...product.productFilter, page: 1, [key!]: value };
        dispatch(setProductFilter(params));
        switch (key) {
            case 'region':
                dispatch(fetchProducts(params));
                break;

            default:
                if (value?.length === 0 || (value && value.length > 3)) {
                    dispatch(fetchProducts(params));
                }
                break;
        }
    }

    const { title, region } = product.productFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Product Title"
                    placeholder="Enter product title"
                    type="text"
                    onChange={(e, value) => handleOnChangeInput(value, "title",)}
                    value={title!}
                    className="ms-Grid-col ms-lg4"
                />
                <ComboBox
                    label="Region"
                    selectedKey={region ? region : "all"}
                    options={Regions}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChangeInput(item?.key, 'region')}
                    className="ms-Grid-col ms-lg4"
                />
            </div>
        </div>
    );
}

interface ProductFilterProps {

}

export default ProductFilters;

