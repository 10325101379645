import { ChoiceGroup, ComboBox, Dropdown, IComboBoxOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getUsers, setUserFilter } from '../../../store/admin/users/action';
import { IUserState } from '../../../store/admin/users/reducer';
import { comboBoxStyles, dropdownStyles, Regions } from '../common/common';
import { StringMap } from '../common/types';
import { statusOptions } from './common';
import { IFaq } from '@solarforschools/sfs-core/dist/faq/types';
import { IFaqState } from '../../../store/admin/faqs/reducer';
import { setFaqFilter, getFaqs } from '../../../store/admin/faqs/action';
import { Languages } from '../common/common';

const FaqFilters = (props: FaqFilterProps) => {
    const dispatch = useDispatch()
    const { faqFilter, categories, sites }: IFaqState = useSelector<RootState, IFaqState>((state) => state.web.faqs)
    const setFilters = (filters: StringMap) => dispatch(setFaqFilter(filters));

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        const params = { ...faqFilter, page: 1, [key]: value };
        setFilters(params);
        handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback(
       (filters: any) => {
            dispatch(getFaqs(filters));
        },
        []
    );

    const { question,
        keywords,
        live,
        country,
        lang,
        section,
        site,
        // subCategory
    } = faqFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Question"
                    placeholder="Enter Question"
                    onChange={(e, value) => handleOnChange('question', value || '')}
                    className="ms-Grid-col ms-lg4"
                    value={question!} />
                <TextField
                    label="KeyWords"
                    placeholder="Enter Keywords"
                    onChange={(e, value) => handleOnChange('keywords', value || '')}
                    className="ms-Grid-col ms-lg4"
                    value={keywords!} />
                <Dropdown
                    dropdownWidth='auto'
                    label="Region"
                    onChange={(e, item) => handleOnChange("country", item?.key?.toString())}
                    selectedKey={country || 'all'}
                    options={Regions}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg2"
                />
                {/* <Dropdown
                    dropdownWidth='auto'
                    label="Language"
                    onChange={(e, item) => handleOnChange("lang", item?.key?.toString())}
                    selectedKey={lang || 'all'}
                    options={Languages}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg2"
                /> */}
                <Dropdown
                    dropdownWidth='auto'
                    label="Sections"
                    onChange={(e, item) => handleOnChange("section", item?.key?.toString())}
                    selectedKey={section || 'all'}
                    options={[{ key: 'all', text: 'All' }, ...categories.map(s => { return { key: s.value, text: s.name } })] as IComboBoxOption[]}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg2"
                />
                <Dropdown
                    dropdownWidth='auto'
                    label="Site"
                    onChange={(e, item) => handleOnChange("site", item?.key?.toString())}
                    selectedKey={site || 'all'}
                    options={[{ key: 'all', text: 'All' }, ...sites.map(s => { return { key: s.value, text: s.name } })] as IComboBoxOption[]}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg2"
                />
                <ChoiceGroup
                    className="inlineflex ms-Grid-col ms-lg6"
                    label="Live"
                    selectedKey={live || "all"}
                    onChange={(e, option) => handleOnChange("live", option?.key,)}
                    options={statusOptions}
                />

            </div>
        </div>
    );
}

interface FaqFilterProps {

}

export default FaqFilters;

