import react from "react";
import Loader from "../common/Loader";
import OptionsFilter from "./OptionsFilter";
const OneSkySync = () => {
    return <>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                position: "relative",
                scrollBehavior: "smooth",
            }}
        >

            <Loader />
            <div>
                <header className="text-center">
                    <h1>SFS Translations</h1>
                </header>
                <OptionsFilter />
            </div>

        </div>
    </>
}

export default OneSkySync