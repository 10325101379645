import React, { useState } from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import ReactDOM from 'react-dom';

const dialogStyles = { main: { maxWidth: 450 } };



export const ConfirmDialog = ({
  dialogContentProps,
  confirmBtnText,
  cancelBtnText
}: StateProps) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result: Boolean) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <ConfirmDialogModal
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        dialogContentProps={dialogContentProps}
        handleOnConfirm={() => callback(true)}
        handleOnDismiss={() => callback(false)} />,
      mountTarget
    )
  })
}

export const ConfirmDialogModal = ({
  dialogContentProps,
  handleOnConfirm,
  handleOnDismiss,
  confirmBtnText,
  cancelBtnText
}: Props) => {
  const dialogContentDefaultProps: DialogContentProps = {
    type: DialogType.close,
    title: 'Confirm',
    closeButtonAriaLabel: 'Close',
    subText: 'Please confirm'
  }
  return (
    <Dialog
      hidden={false}
      onDismiss={handleOnDismiss}
      dialogContentProps={{ ...dialogContentDefaultProps, ...dialogContentProps }}
      modalProps={{
        isBlocking: true,
        styles: dialogStyles
      }}
    >
      <DialogFooter>
        <DefaultButton onClick={() => handleOnDismiss()} text={cancelBtnText ? cancelBtnText : 'Cancel'} />
        <PrimaryButton className="btn-primary" onClick={() => handleOnConfirm()} text={confirmBtnText ? confirmBtnText : 'Delete'} />
      </DialogFooter>
    </Dialog>
  );
};
interface DialogContentProps {
  type?: DialogType;
  title?: string;
  closeButtonAriaLabel?: string;
  subText?: string;
};
interface StateProps {
  dialogContentProps: DialogContentProps
  confirmBtnText?: string,
  cancelBtnText?: string,
}

interface OwnProps {
  handleOnConfirm: () => void;
  handleOnDismiss: () => void;
}

type Props = StateProps & OwnProps;
