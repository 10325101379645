import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ComboBox, IComboBoxOption, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../../store';
import ModalDialog from '../../common/ModalDialog';
import { validateBondTransfer } from './BondHelper';
import { transferBond } from '../../../../store/admin/funder/bond/action';
import { comboBoxStyles } from '../../common/common';
import { ObjectId } from 'mongodb';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';


export const OpenTransferBondModal = (props: OwnProps) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Change Bond holder"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-xs"
                >
                    <TransferBondDialog
                        {...props}
                        onSave={bond => callback(bond)}
                        onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export const TransferBondDialog = ({
    userName,
    bondId,
    onSave,
    onCancel,
}: Props) => {
    const dispatch = useDispatch()
    const { bondHolders = [] }: IBondState = useSelector<RootState, IBondState>((state) => state.web.bonds);
    const [data, setData] = useState({ userName, bondId });

    const [errors, setErrors] = useState<any>({});

    const handleOnChangeInput = (key: string, value: any) => {
        const _data = _.set({ ...data }, key, value);
        setData(_data);
    };

    const handleSave = async () => {
        const errors = await validateBondTransfer(data);
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        dispatch(transferBond(data))
        onSave!({ success: true, userName: data.userName });
    };



    return (
        <div className="edit-record">

            <div className="ms-Grid-row">
                <ComboBox
                    label="Bond Holder"
                    placeholder="Select Bond holder"
                    selectedKey={data.userName}
                    autoComplete={"on"}
                    options={bondHolders as IComboBoxOption[]}
                    onChange={(e, item) => handleOnChangeInput("userName", item?.key)}
                    className="ms-Grid-col ms-lg12"
                    errorMessage={errors?.userName}
                    styles={comboBoxStyles}
                />
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>
                        <PrimaryButton
                            text="Transfer"
                            onClick={handleSave}
                            className="btn-primary"
                        />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    onCancel?: () => void
    userName: string,
    bondId: string | ObjectId
    onSave?: (data: any) => void
}

type Props = OwnProps;
