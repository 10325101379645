

import { toast } from "react-toastify";
import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { SetBillingReadingFilter, SetBillingReadingList } from "./actionTypes";
import { IBillingCycle, IBillingReadingDocument, IBillingReadingFilter, IReadingParams } from '../../../components/admin/billing/types';
import { host } from "../../config";
import { RootState } from './../../index';
import { DropdownParams } from "../../../components/admin/common/types";
import { isLoading } from "../action";
import { generateQueryString } from "../../../utils/util";
import { ParsedUrlQueryInput } from "querystring";
import { downloadFileFromStream } from "../../../components/admin/common/utils";
import { fetchBillingContracts } from "../invoice/action";
import { ISystem } from "@solarforschools/sfs-core";


// Action Creators

export const setBillingReadingFilter = (data: IBillingReadingFilter): SetBillingReadingFilter => {
  return { type: "SET_BILLING_READING_FILTER", data };
};

export const setPeriodsFilter = (periods: DropdownParams[]) => {
  return { type: "SET_PERIODS_FILTER", periods };
};

export const setContractsFilter = (contracts: DropdownParams[]) => {
  return { type: "SET_CONTRACTS_FILTER", contracts };
};

export const setBillingReadingList = (billingReadings: IBillingReadingDocument[], reset = true): SetBillingReadingList => {
  return { type: "SET_BILLING_READING_LIST", billingReadings, reset };
};
export const setSiteFilter = (sites: DropdownParams[]) => {
  return { type: "SET_SITE_FILTER", sites };
};

export const setReadingTotalCount = (totalCount: number) => {
  return { type: 'SET_READINGS_TOTALS_COUNT', totalCount }
}

export const deleteBillingReading = (readingId: string) => {
  return { type: 'BILLING/DELETE_READING', readingId }
}

export const updateBillingReading = (reading: IBillingReadingDocument) => {
  return { type: 'BILLING/UPDATE_READING', reading }
}


export const fetchPeriods = () => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>,): Promise<void> => {
    return new Promise<void>((resolve) => {
      try {
        dispatch(isLoading(true));
        axios
          .get(`${host}/billing/period?all=true`)
          .then((res) => {
            if (res.status === 200) {
              const periods = [{ key: 'all', text: 'All' }]
              for (const p of res.data.periods) {
                periods.push({ key: p.Period, text: p.Period })
              }
              dispatch(setPeriodsFilter(periods));
              dispatch(isLoading(false));
            }
          })
          .catch((err) => {
            if (err?.response?.status === 404) {
              toast.error(err.response.data.msg);
            }
            dispatch(isLoading(false));
          });
      } catch (e) {
        console.log(e);
      }
    });
  };
}

export const fetchBillingReadings = (
  params?: IBillingReadingFilter,
  reset: boolean = true
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  let reqBody = params;
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (!reqBody) {
          const state = getSate()
          const billingReadingFilter: IBillingReadingFilter = state.web.billing.billingReadingFilter
          reqBody = { ...billingReadingFilter }
        }
        const res = await axios.get(`${host}/billing/reading?${generateQueryString(reqBody as ParsedUrlQueryInput)}`)
        if (res.status === 200) {
          dispatch(setReadingTotalCount(res.data.totalCount))
          dispatch(setBillingReadingList(res.data.data, reset));
        }
      } catch (error) {
        console.log(error);
      }
    })
  };
}

export const exportBillingReadings = (): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {

  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        dispatch(isLoading(true));
        const state = getSate()
        const billingReadingFilter: IBillingReadingFilter = state.web.billing.billingReadingFilter
        const params = { ...billingReadingFilter }
        const res = await axios.get(`${host}/billing/reading/download?${generateQueryString(params)}`, { responseType: 'arraybuffer' })
        downloadFileFromStream({ data: res.data, filename: "reading", type: 'xlsx' })
        dispatch(isLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(isLoading(false));
      }
    })
  };
}

export const getMeterReading = (params: IReadingParams) => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>) => {
    dispatch(isLoading(true))
    try {
      const { date, id, mid, type, MSN } = params
      const res = await axios.get(`${host}/meter/reading?date=${date}&id=${id}&mid=${mid}&type=${type}&MSN=${MSN}`)
      dispatch(isLoading(false))
      return res.data
    } catch (err) {
      console.log(err)
      dispatch(isLoading(false))
    }
  }
};

export const updateReading = (reading: IBillingReadingDocument): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  const reqBody = reading;
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.put(`${host}/billing/reading`, reqBody)
        dispatch(updateBillingReading(res.data.data))
        toast.success(res.data.message);
        dispatch(isLoading(false));
      } catch (e) {
        dispatch(isLoading(false));
      }
    });
  };
};

export const refreshReading = (readingId: string): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API

  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.put(`${host}/billing/reading/refresh/${readingId}`, {})
        toast.success(res.data.message);
        dispatch(isLoading(false));
        dispatch(updateBillingReading(res.data.data))
      } catch (e) {
        dispatch(isLoading(false));
      }
    });
  };
};

export const createBulkReadings = (billingCycle: IBillingCycle): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  const reqBody = billingCycle;
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        await axios.post(`${host}/billing/bulkReading`, reqBody)
        const state = getSate()
        const billingReadingFilter: IBillingReadingFilter = state.web.billing.billingReadingFilter
        const params = { ...billingReadingFilter, page: 1 }
        dispatch<any>(fetchBillingReadings(params))
        dispatch<any>(fetchPeriods())
      } catch (e) {
        dispatch(isLoading(false));
      }
    });
  };
};

export const fetchSites =
  () =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const res = await axios.get(`${host}/billing/site`)
        const sites = [{ key: 'all', text: 'All' }, ...res.data.map((s: { id: { toString: () => any; }; name: any; }) => ({ key: s.id.toString(), text: `${s.name} (${s.id})` }))]
        dispatch(setSiteFilter(sites))
        dispatch(isLoading(false));
      } catch (e: any) {
        dispatch(isLoading(false));
      }
    };

export const getSystemFilter = async (systems: ISystem[] = []) => {
  const systemFilters: any = [{ key: "all", text: "All" }];
  systems.forEach((s: { name: any; id: { toString: () => any; }; provider: any; }) => {
    systemFilters.push({ text: `${s.name} - ${s.id} (${s.provider})`, key: s.id.toString() });
  });
  return systemFilters
}

export const deleteReading = (readingId: string): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>, getSate): Promise<void> => {
    dispatch(isLoading(true))
    return new Promise<void>(async (resolve) => {
      try {
        const res = await axios.delete(`${host}/billing/reading/${readingId}`)
        if (res.status === 200) {
          toast.success(res.data.msg)
        }
        dispatch(isLoading(false));
        dispatch(deleteBillingReading(readingId))
        dispatch<any>(fetchPeriods())
      } catch (e) {
        console.log(e)
        dispatch(isLoading(false));
      }
    });
  };
};

export const fetchContracts = () => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, RootState, AnyAction>,): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        dispatch(isLoading(true));
        const contracts = await fetchBillingContracts()
        dispatch(setContractsFilter(contracts));
        dispatch(isLoading(false));
      } catch (e) {
        console.log(e);
        dispatch(isLoading(false));
      }
    });
  };
}
