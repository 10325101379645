import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { IInvoiceReportFilter } from "../../../components/admin/reporting/invoice/types"
import { generateQueryString } from "../../../utils/util"
import { host } from "../../config"
import { ISchoolReportForm } from "../../admin/reporting/reports/actionTypes"

let GetSchoolAPiAbortController: AbortController

export const getSchoolReportDataApi = (params: ISchoolReportForm): Promise<any> => {
  if (GetSchoolAPiAbortController) GetSchoolAPiAbortController.abort()
  GetSchoolAPiAbortController = new AbortController()
  return axios.post(`${host}/reports/school/data`, params, { signal: GetSchoolAPiAbortController.signal }).then(res => res.data)
}

export const getSchoolReportExportApi = (query: IInvoiceReportFilter): Promise<any> => {
  return axios.get(`${host}/reports/school/excel?${generateQueryString(query as unknown as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res.data)
}
