import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IProduct } from "@solarforschools/sfs-core/dist/product/type";
import { ITaskType } from "@solarforschools/sfs-core/dist/taskType/types";
import { IUserSkill } from "@solarforschools/sfs-core/dist/user/types";
import { validateTaskTypeAPi } from "../../../store/client/taskType";
import { IField } from "../../dynamic-form/DynamicForm";
import { Regions, requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { TaskTypes } from "../task/common";

export const getGeneralFields = (options: StringMap): IField[] => {
    const selectedSkills = options?.taskType?.skillsRequired || ''
    return [
        {
            label: "name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg4'
        }, {
            label: "Slug",
            key: "slug",
            type: "string",
            extras: { required: true, ...options?.taskType?._id ? { disabled: true } : {} },
            placeholder: "Slug",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Region",
            key: "region",
            type: "dropdown",
            extras: { required: true },
            options: Regions as IDropdownOption[],
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Duration",
            key: "duration",
            type: "number",
            extras: { required: true },
            placeholder: "Duration",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Hours Required",
            key: "hoursRequired",
            type: "number",
            extras: {},
            placeholder: "hoursRequired",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Reminder",
            key: "reminder",
            type: "number",
            extras: {},
            placeholder: "Reminder",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Task Type",
            key: "taskType",
            type: "dropdown",
            extras: { required: true },
            options: TaskTypes.filter(c => c.key !== 'all') as IDropdownOption[],
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Skills Required",
            key: "skillsRequired",
            type: "dropdown",
            extras: { required: true, multiSelect: true },
            selectedKeys: selectedSkills,
            options: options?.skills?.map((s: IUserSkill) => { return { key: s.id, text: s.name } }) as IDropdownOption[],
            className: 'ms-Grid-col ms-lg5'
        },

        {
            label: "Description",
            key: "description",
            type: "string",
            extras: { multiline: true, rows: 3 },
            placeholder: "Description",
            className: 'ms-Grid-col ms-lg12'
        },
    ];
}
export const validateTaskType = async (params: ITaskType) => {
    let errors: StringMap = {};
    const { name, slug, region, taskType, skillsRequired } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (!slug) errors['slug'] = requiredMessage;
    if (!region) errors['region'] = requiredMessage;
    if (!taskType) errors['taskType'] = requiredMessage;
    if (skillsRequired && !skillsRequired.length) errors['skillsRequired'] = requiredMessage;
    if (!Object.keys(errors).length) {
        errors = await validateTaskTypeAPi(params) as unknown as StringMap
    }
    return errors;
}

export const initProduct = {
    productId: '',
    title: '',
    description: '',
    region: 'GB',
    currency: 'GBP',
    revenueFix: 0,
    revenueVar: 0,
    recurring: false,
    scalingType: 'SystemSize'
}
export const getTaskTypeColumns = (params: {
    actions: {
        icon: string,
        onClick: (task: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            name: 'Region',
            key: 'region',
            fieldName: 'region',
            minWidth: 100,
            ...isSortable,
            onRender: (item: ITaskType) => <TooltipHost content={item.region || ''} closeDelay={500}> {item.region}</TooltipHost>
        },
        {
            key: 'taskType',
            name: 'Type',
            fieldName: 'taskType',
            ariaLabel: "Task Type",
            minWidth: 100,
            maxWidth: 200,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.taskType} closeDelay={500}> {item.taskType}</TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (taskType) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(taskType)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export const TaskTypeTabs: StringMap = {
    general: false,
    inputs: false,
    tools: false,
    outputs: false,
}

export const initTaskType: ITaskType = {
    name: '',
    slug: '',
    region: '',
    duration: null,
    hoursRequired: null,
    description: '',
    taskType: 'School',
    reminder: null,
    skillsRequired: [],
    inputs: [],
    tools: [],
    outputs: [],
} as unknown as ITaskType
