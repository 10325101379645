import { ITaskType } from "@solarforschools/sfs-core/dist/taskType/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

export const getTaskTypesApi = (query: any): Promise<any> => {
    return axios.get(`${host}/taskType?${generateQueryString(query as ParsedUrlQueryInput)}`).then(res => res.data)
}
export const createTaskTypeAPi = (taskType: ITaskType): Promise<any> => {
    return axios
      .post(`${host}/taskType`, taskType)
      .then((res) => res.data);
  };
  
  export const updateTaskTypeAPi = (taskType: ITaskType): Promise<any> => {
    return axios
      .put(`${host}/taskType/${taskType._id}`, taskType)
      .then((res) => res.data);
  };
  
  export const deleteTaskTypeAPi = (taskType: ITaskType): Promise<any> => {
    return axios
      .delete(`${host}/taskType/${taskType._id}`)
      .then((res) => res.data);
  };
  
  export const validateTaskTypeAPi = (taskType: ITaskType): Promise<any> => {
    return axios
      .put(`${host}/taskType/validate/taskType`, taskType)
      .then((res) => res.data);
  };
