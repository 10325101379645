import {
    DefaultButton,
    Dropdown,
    PrimaryButton,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
} from "@fluentui/react";
import { TextField } from "office-ui-fabric-react";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import { languageOptions } from "./utils/helper";
import { toast } from "react-toastify";
import { handleDraftCreation } from "../../../store/admin/translations/actions";
import { IAdmin } from "../../../store/admin/reducer";

interface IProps {
    id: string;
    language?: string;
    value?: string;
    key: string;
    enValue: string;
}

// Open the modal dialog - return promise(Await to resolve data)
export const openEditTranslationDialog = (props: IProps) => {
    return new Promise<string | undefined>((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget);
        const callback = (result?: IProps[]) => {
            resolve("Resolved");
            ReactDOM.unmountComponentAtNode(mountTarget);
            mountTarget.remove();
        };

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={(props.language && props.value) ? "Edit Translation" : "Add Translation"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-lg"
                >
                    <AddEditTranslationDialog
                        data={props}
                        onCancel={() => callback(undefined)}
                        onSave={() => callback(undefined)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        );
    });
};
interface IHeaderProps {
    translation: {
        language: string;
        value: string;
    }
    isEdit: boolean;
    langKey: string;
    setTranslation: React.Dispatch<React.SetStateAction<{
        language: string;
        value: string;
    }>>
}


const Content = (props: IHeaderProps) => {

    const { translation, setTranslation, isEdit, langKey: key } = props

    let findLang;

    if (translation.language)
        findLang = languageOptions.find(elm => elm.key === translation.language)


    const handleDropDownChange = (e: React.FormEvent<HTMLDivElement>, item: any) => {
        setTranslation((prevState) => {
            return {
                ...prevState,
                language: item
            }
        })
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = (e.target as HTMLInputElement).value
        setTranslation((prevState) => {
            return {
                ...prevState,
                value: value
            }
        })
    }



    return (
        <>

            {
                <div style={{ marginBlock: "3rem 1rem" }}>

                    <div style={{ marginBottom: "0.5rem" }}>
                        <Text variant="medium">
                            <span style={{ fontWeight: 600 }}>Key  </span> - {key}
                        </Text>
                    </div>
                    {
                        !isEdit ? (<Dropdown
                            placeholder="Language"
                            label='Select language'
                            selectedKey={translation.language}
                            options={languageOptions}
                            onChange={(e, item) => handleDropDownChange(e, item?.key as string)}
                            style={{
                                width: "20rem"
                            }}
                        />) : (
                            <Text variant="medium">
                                <span style={{ fontWeight: 600 }}>Language  </span> - {translation?.language}
                            </Text>
                        )
                    }
                </div>

            }

            <TextField
                className="dr-text-area"
                style={{
                    minHeight: "200px"
                }}
                label="Value"
                placeholder={
                    `${!isEdit ? (translation?.language ? (languageOptions?.find(elm => elm.key === translation.language)?.text + "'s value") || "Enter Value" : "Enter value") : `Enter ${translation?.language}'s value`}`
                }
                multiline
                rows={5}
                value={translation.value}
                onChange={handleChange}
            />
        </>
    )
}


//Component for Modal dialog 
const AddEditTranslationDialog = (props: IAProps) => {


    const admin: IAdmin = useSelector<RootState, IAdmin>(
        (state: RootState) => state.web.admin
    );

    const language = props.data?.language
    const value = props.data?.value
    const enValue = props.data.enValue
    const isEdit = (language && value) ? true : false
    const hasApprovalPermission = admin.user?.permission?.includes("translationApprover") ? true : false

    const dispatch = useDispatch()

    const [translation, setTranslation] = useState({ language: language || "", value: value || "" })

    //Send data to resolve it
    const handleOnSave = async () => {

        if (translation?.language === "") {
            toast.error("Select a Language")
            return
        }
        if (translation?.value.trim() === "") {
            toast.error("Enter translation value")
            return
        }

        //TODO: Recheck validation
        if(translation?.value.trim().toLowerCase() === enValue.trim().toLowerCase()) {
            toast.error("Invalid. Entered translation value is same as en value")
            return
        }

        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: " Save translation",
                closeButtonAriaLabel: "Close",
                subText: `Are you want to save translation ?`,
            },
            confirmBtnText: "Save",
        });
        if (isConfirmed) {
            
            const draftArgs = {
                key: props.data.key,
                language: isEdit ? languageOptions.find(elm => elm.text === translation.language)?.key || 'gb' : translation.language,
                value: translation.value,
                docId: props.data.id
            }
            dispatch(handleDraftCreation(draftArgs, false, hasApprovalPermission))
            props.onSave(); // on save
        }
    };


    if (!props.data.id || !props.data.key) return <></>


    return (
        <div>


            <Content
                translation={translation}
                setTranslation={setTranslation}
                isEdit={isEdit}
                langKey={props.data.key} />



            <Sticky stickyPosition={StickyPositionType.Footer}>
                <div
                    style={{
                        display: "flex",
                        marginTop: "1rem",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Stack horizontal horizontalAlign="center" className="margin-top-xs">
                        <Stack.Item>

                            <PrimaryButton
                                text="Save"
                                onClick={handleOnSave}
                                className="btn-primary"
                            />

                            <DefaultButton onClick={props.onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </div>
            </Sticky>
        </div>
    );
};

export default AddEditTranslationDialog;

interface IAProps {
    data: IProps
    onCancel: () => void;
    onSave: () => void;
}
