import { IEnvConfig } from "@solarforschools/sfs-core";
import { Action } from "./actionTypes";

export interface IEnvConfigState {
  isLoading: boolean;
  envConfigList: IEnvConfig[];
}
const initialState = {
  isLoading: false,
  envConfigList: [],
};
const envConfig = (
  state: IEnvConfigState = initialState,
  action: Action
): IEnvConfigState | undefined => {
  switch (action.type) {
    case "SET_ENV_CONFIG":
      return { ...state, envConfigList: action.envConfigList };

    case "UPDATE_ENV_CONFIG":
      return {
        ...state,
        envConfigList: [
          ...state.envConfigList.filter(
            (el) => el._id !== action.envConfigList._id
          ),
          action.envConfigList,
        ],
      };
    default:
      return state;
  }
};

export default envConfig;
