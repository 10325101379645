import React, { useState } from "react";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import {
    DatePicker,
    Dropdown,
    Stack,
    Sticky,
    StickyPositionType,
    TextField,
} from "@fluentui/react";
import "office-ui-fabric-react/dist/css/fabric.css";
import { dropdownStyles } from "../common/common";
import moment from "moment";
import { IDPParams } from "./types";
import ReactDOM from "react-dom";
import store from "../../../store";
import { Provider, useDispatch } from "react-redux";
import ModalDialog from "../common/ModalDialog";
import { systemDP } from "../../../store/admin/system/action";
import { toast } from "react-toastify";
import { ISystem } from "@solarforschools/sfs-core";

export const OpenDataProcessModal = (props: any) => {
    return new Promise<ISystem | undefined>((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title="Process Data"
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-sm"}
                >
                    <DataProcessModal
                        {...props}
                        onCancel={() => callback(undefined)}
                        onSave={(system: ISystem) => callback(system)}
                    />
                </ModalDialog>
            </Provider>
            ,
            mountTarget
        )
    })
}

export const DataProcessModal = ({ system, onCancel, onSave }: Props) => {
    const dispatch = useDispatch();
    const [dpParams, setParams] = useState<IDPParams>({
        type: "last",
        systemId: system.id,
        start: new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')),
        end: new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')),
    });



    const handleOnChangeInput = (key: string, value: any) => {
        setParams({ ...dpParams, [key]: value });
    };

    const handleOnDp = async (params: IDPParams) => {
        const { data, msg }: any = await dispatch(systemDP(params))
        let message = `${msg} </br>`
        const result = data || {}
        if (result.rtYield) {
            const { startDate, endDate } = result.rtYield[0] || {}
            message = `${message} from : ${moment(startDate).format('YYYY-MM-DD')} </br> to: ${moment(endDate).format('YYYY-MM-DD')}</br>`
        }
        if (result.enReading) message = `${message}Reading: ${result.enReading && 'created'}</br>`
        if (result.enYield) message = `${message}enYield: ${result.enYield && 'created'}</br>`
        if (result.seReading) message = `${message}seReading: ${result.Reading && 'created'}</br>`
        if (result.seYield) message = `${message}seYield: ${result.Yield && 'created'}</br>`
        if (result.rtYield) message = `${message}rtYield: ${result.rtYield?.length && 'created'}</br>`
        if (result.rtReading) message = `${message}rtReading: ${result.rtReading && 'created'}</br>`

        toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />, {
            autoClose: false, closeOnClick: true, style: {
                minWidth: "300px",
                width: "100%",
                height: "fit-content"
            }
        });
        onSave(params)
    }

    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg6">
                        <TextField
                            label="System"
                            placeholder="System"
                            disabled
                            value={system.name} />
                    </div>
                    <div className="ms-Grid-col ms-lg3">
                        <TextField
                            label="System Id"
                            disabled
                            value={system.id.toString()} />
                    </div>
                    <div className="ms-Grid-col ms-lg3">
                        <TextField
                            label="Provider"
                            disabled
                            value={system.provider} />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg3">
                        <TextField
                            label="Siteid"
                            disabled
                            value={system.siteId?.toString()} />
                    </div>
                    <div className="ms-Grid-col ms-lg3">
                        <TextField
                            label="Contract"
                            disabled
                            value={system.contractId} />
                    </div>
                    <div className="ms-Grid-col ms-lg6">
                        <TextField
                            label="Source"
                            disabled
                            value={"Comming Soon"} />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg4">
                        <Dropdown
                            label="Type"
                            selectedKey={dpParams.type as string}
                            options={[{ key: 'last', text: "Last" }, { key: "date", text: "Date" }, { key: 'all', text: "All" }]}
                            onChange={(e, item) => handleOnChangeInput('type', item?.key.toString()!)}
                            styles={dropdownStyles}
                        />
                    </div>
                    <div className="ms-Grid-col ms-lg4">
                        {dpParams.type === 'date' &&
                            <DatePicker
                                label="Start Date"
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                value={new Date(dpParams.start!)}
                                onSelectDate={(date) => handleOnChangeInput('start', moment(date).startOf('day').format('YYYY-MM-DD'))}
                                styles={dropdownStyles}
                            />}
                    </div>
                    <div className="ms-Grid-col ms-lg4">
                        {dpParams.type === 'date' &&
                            <DatePicker
                                label="End end"
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                value={new Date(dpParams.end!)}
                                onSelectDate={(date) => handleOnChangeInput('end', moment(date).startOf('day').format('YYYY-MM-DD'))}
                                styles={dropdownStyles}
                            />}
                    </div>
                </div>
            </div>

            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>
                        <PrimaryButton
                            text="Start"
                            onClick={() => handleOnDp(dpParams)}
                            className="btn-primary"
                        />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};



interface StateProps {
    system: ISystem,
}
interface OwnProps {
    onCancel: () => void;
    onSave: (dpParams: IDPParams) => void;
}

type Props = StateProps & OwnProps;
