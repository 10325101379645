import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IUserSkillFilter } from "../../../components/admin/userSkill/types";
import { IUserSkill } from "@solarforschools/sfs-core/dist/user/types";
import { createUserSkillAPi, deleteUserSkillApi, getUserSkillsApi, updateUserSkillApi } from "../../client/userSkill";

// Action Creators

export const setUserSkillFilter = (userSkillFilter: IUserSkillFilter) => {
  return { type: "SET_USER_SKILL_FILTER", userSkillFilter };
};

export const setUserSkillList = (
  userSkills: IUserSkill[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_USER_SKILL_LIST", userSkills, totalCount, reset };
};

export const updateUserSkillList = (userSkill: IUserSkill) => {
  return { type: "UPDATE_USER_SKILL", userSkill };
};

export const delUserSkill = (slug: string) => {
  return { type: "DELETE_USER_SKILL", slug };
};

export const getUserSkills =
  (params?: IUserSkillFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IUserSkillFilter =
            getState().web.userSkill.userSkillFilter;
          reqBody = { ...filters };
        }
        const { userSkills, totalCount } = await getUserSkillsApi(reqBody);
        dispatch(setUserSkillList(userSkills, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateUserSkill =
  (params: IUserSkill) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { userSkill, msg } = params._id
          ? await updateUserSkillApi(params)
          : await createUserSkillAPi(params);
        dispatch(updateUserSkillList(userSkill));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteUserSkill =
  (userSkill: IUserSkill) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteUserSkillApi(userSkill);
        dispatch(delUserSkill(userSkill.id))
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
