import React, { useState } from "react";

const Button = ({
  name,
  onClick,
  width,
  height,
  fontSize,
  bgColor,
  color,
  margin,
  disabled = false,
}: buttonProps) => {
  const [buttonStyle, setButtonStyle] = useState<React.CSSProperties>({
    background: bgColor ? bgColor : "var(--solar-blue)",
    color: color ? color : "#fff",
    border: "0",
    padding: "0.5rem 1.7rem",
    borderRadius: "5px",
    outline: "0",
    cursor: "pointer",
    width: width ? width : "",
    height: height ? height : "",
    fontSize: fontSize ? fontSize : "",
    margin,
  });
  const [isFocus, setIsFocus] = useState(false);

  const setTransition = (isApply: boolean) => {
    let scaleNumber = isApply ? 1.04 : 1;

    setButtonStyle({
      ...buttonStyle,
      transform: `scale(${scaleNumber})`,
    });
  };

  const handleFocus = (e: React.FocusEvent<HTMLButtonElement>) => {
    setIsFocus(true);
    setTransition(true);
  };
  const handleFocusOut = (e: React.FocusEvent<HTMLButtonElement>) => {
    setIsFocus(false);
    setTransition(false);
  };
  const handleMouseOver = (e: React.MouseEvent) => {
    setTransition(true);
  };
  const handleMouseOut = (e: React.MouseEvent) => {
    if (!isFocus) {
      setTransition(false);
    }
  };

  return (
    <>
      <button
        style={buttonStyle}
        onMouseEnter={handleMouseOver}
        onMouseOut={handleMouseOut}
        onFocus={handleFocus}
        onBlur={handleFocusOut}
        onClick={onClick}
        disabled={disabled}
      >
        {name}
      </button>
    </>
  );
};

export default Button;

export interface buttonProps {
  name: string | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | null) => void;
  width?: string;
  height?: string;
  fontSize?: string;
  bgColor?: string;
  color?: string;
  disabled?: boolean;
  margin?: string | number
}
