import Card from '../layouts'
import CardHeader from '../layouts/CardHeader'
import { IQuartelyDealCount } from '@solarforschools/sfs-core/dist/adminDashboard/types'
import CardContent from '../layouts/CardContent'
import { BackContent, FrontContent } from './contents/DealWonFlipContent'

const DealsWon = ({ dealsWon }: { dealsWon: IQuartelyDealCount[] }) => {

    if (dealsWon === undefined || dealsWon?.length === 0) return <></>

    return (
        <div className="dashboard-layout--pipedrive-deal-in-legal">
            <Card dealByInLegals={dealsWon}>
                <CardHeader title='Deals won' iconName='PipeDrive' />
                <CardContent variant='Flipper' frontContent={FrontContent} backContent={BackContent} hasFooter={false} />
            </Card>
        </div>
    )
}

export default DealsWon