import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useState } from "react";
import { getSystems } from "../../../store/client/system";
import { ISystem } from "@solarforschools/sfs-core";
import CustomTagPicker from "./CustomTagPicker";
import { getTSMSNAPI } from "../../../store/client/tsReading";

interface MSNPickerProps {
    filters?: { [key: string]: any; }
    selectedItems?: ITag[]
    onChange: (key: string, items?: ITag[]) => void
}

const MSNPicker: FunctionComponent<MSNPickerProps> = ({
    filters = {},
    selectedItems,
    onChange
}) => {
    const [selectedMSN, setSelectedMSN] = useState<ITag[]>(selectedItems || [])
    
    const handleGetMSN = async (
        filter: string,
        selectedItems?: ITag[],
    ): Promise<ITag[]> => {
        
        console.log({ msn: filter, ...filters })
        try {
            const { msns } = await getTSMSNAPI({ msn: filter, ...filters })
            let data = msns?.length ? msns.map((s: string) => ({ key: s, name: `${s}` })) : []
            return data
        } catch (error) {
            return [] as ITag[]
        }
    }
    const handleOnChangeInput = async (key: string, value: any) => {
        (value.length) ? setSelectedMSN(value) : setSelectedMSN([])
        onChange(key, value)
    }

    return (
        <CustomTagPicker
            itemLimit={1}
            label="MSN"
            placeholder="Choose MSN"
            selectionAriaLabel="Choose MSN"
            selectedItems={selectedMSN}
            onResolveSuggestions={handleGetMSN}
            onChange={(items?: ITag[]) => handleOnChangeInput('selectedMSN', items)} />
    );
}

export default MSNPicker;
