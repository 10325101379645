import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

let GetUFAPiAbortController: AbortController

export const getuserFunctionsApi = (query: any): Promise<any> => {
  if (GetUFAPiAbortController) {
    GetUFAPiAbortController.abort(); // Tell the browser to abort request
  }
  GetUFAPiAbortController = new AbortController();
  return axios.get(`${host}/user-function?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetUFAPiAbortController.signal }).then(res => res.data)
}
export const createUserFunctionAPi = (userFunction: IUserFunction): Promise<any> => {
  return axios
    .post(`${host}/user-function`, userFunction)
    .then((res) => res.data);
};

export const updateUserFunctionApi = (userFunction: IUserFunction): Promise<any> => {
  return axios
    .put(`${host}/user-function/${userFunction._id}`, userFunction)
    .then((res) => res.data);
};

export const deleteUserFunctionApi = (userFunction: IUserFunction): Promise<any> => {
  return axios
    .delete(`${host}/user-function/${userFunction._id}`)
    .then((res) => res.data);
};

export const validateUserFunctionApi = (userFunction: IUserFunction): Promise<any> => {
  return axios
    .put(`${host}/user-function/validate/user-function`, userFunction)
    .then((res) => res.data);
};
