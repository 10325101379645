import React, { useEffect, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import "./question.css";
import {
  IAnswer,
  IQuestionItems,
  IQuestionTemplate,
  QuestionItems,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { changeQuestionTemplate } from "../../../../store/content/action";
import { getName } from "../util";

const Q9 = ({
  content,
  handleSubmit,
  handleTextBoxChange,
  changeQuestionTemplate,
}: Props) => {
  const [dragId, setDragId] = useState("");

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const thisTemplate = TemplateItems.Q9;

  const handleTips = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      tips: e.target.value,
    };
    dispatchEvent(answer);
  };

  const dispatchEvent = (answer: IAnswer) => {
    let questionTemplate: IQuestionTemplate = {
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer,
    };
    changeQuestionTemplate(questionTemplate);
  };

  const dispatchDraggable = (questionItems: IQuestionItems[]) => {
    const answer = questionItems
      .filter((items) => items.type === QuestionItems.OPTIONS)
      .sort((a, b) => a.order! - b.order!)
      .map((obj) => obj.text);
    changeQuestionTemplate({
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer: {
        texts: answer as string[],
      },
    });
  };
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const boxes = content.questionTemplate?.items?.filter(
      (c) => c.sequence && c.text?.length && c.sequence > 5
    );
    if (boxes) {
      const dragBox = boxes.find((box) => box.order === Number(dragId));
      const dropBox = boxes.find(
        (box) => box.order === Number(e.currentTarget.id)
      );
      if (dragBox && dropBox) {
        const dragBoxOrder = dragBox.order;
        const dropBoxOrder = dropBox.order;
        const newBoxState = boxes.map((box) => {
          if (box.order === Number(dragId)) {
            box.order = dropBoxOrder;
          } else if (box.order === Number(e.currentTarget.id)) {
            box.order = dragBoxOrder;
          }
          return box;
        });
        dispatchDraggable(newBoxState);
      }
    }
  };

  const findOrder = (sequence: number): number => {
    const currentTextbox = content.questionTemplate?.items?.find(
      (cont) => cont.sequence === sequence
    );
    return currentTextbox?.order !== undefined
      ? currentTextbox.order
      : sequence - 5;
  };

  const textBoxes = [
    [
      {
        name: "Fixed 1",
        sequence: 2,
      },
      {
        name: "Answer 1",
        sequence: 6,
      },
    ],
    [
      {
        name: "Fixed 2",
        sequence: 3,
      },
      {
        name: "Answer 2",
        sequence: 7,
      },
    ],
    [
      {
        name: "Fixed 3",
        sequence: 4,
      },
      {
        name: "Answer 3",
        sequence: 8,
      },
    ],
    [
      {
        name: "Fixed 4",
        sequence: 5,
      },
      {
        name: "Answer 4",
        sequence: 9,
      },
    ],
  ];
  const { submitButtonText } = getName(content, -1);

  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, paddingTop: 0, height: "65%" }}
        >
          <Textbox
            placeholder="Enter the Question here"
            maxChar={80}
            style={{ fontSize: "1rem", fontWeight: "normal" }}
            containerStyle={{ margin: "1rem 1rem" }}
            onChange={(e) =>
              handleTextBoxChange(e, 1, thisTemplate, QuestionItems.QUESTION)
            }
            value={
              content.questionTemplate?.items?.find(
                (temp) => temp.sequence === 1
              )?.text
            }
          />

          <div className="q-options-container">
            {textBoxes.map((pair) => {
              return (
                <div className="match-the-words">
                  {pair.map((tb) => {
                    const { name, sequence } = tb;
                    return (
                      <Textbox
                        placeholder={name}
                        maxChar={20}
                        width="50%"
                        style={{ fontSize: "1rem", fontWeight: "normal" }}
                        containerStyle={{ margin: "0rem 0.25rem" }}
                        onChange={(e) =>
                          handleTextBoxChange(
                            e,
                            sequence,
                            thisTemplate,
                            QuestionItems.OPTIONS,
                            findOrder(sequence)
                          )
                        }
                        value={
                          content.questionTemplate?.items?.find(
                            (temp) => temp.sequence === sequence
                          )?.text
                        }
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </aside>
      <aside>
        <p>
          Rearrange matching pairs. The left hand side box is fixed and the
          second box parallel to it is answer. Kindly drag and order the correct
          sequence which matches the question below.
        </p>
        <div className="correct-answer">
          <div className="choose-correct-answer">
            <Label name="Order the text below" fontSize="large" />
            <div className="draggable-answer-options">
              {content.questionTemplate?.items
                ?.filter((c) => c.sequence && c.text?.length && c.sequence > 5)
                .sort((a, b) =>
                  a.order !== undefined &&
                  b.order !== undefined &&
                  a.order > b.order
                    ? 1
                    : -1
                )
                .map((options) => {
                  return (
                    <div
                      draggable={true}
                      onDragOver={(ev) => ev.preventDefault()}
                      onDragStart={handleDrag}
                      onDrop={handleDrop}
                      id={options.order ? options.order.toString() : "0"}
                    >
                      <span className="grippy"></span>
                      {options.text}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="answer-comment-box">
            <Label
              name="Correct!"
              fontSize="xx-large"
              style={{ marginTop: 0 }}
            />
            <Textarea
              placeholder="Enter any additional text to appear on the correct / not quite message (optional)"
              maxChar={50}
              width="100%"
              containerStyle={{ height: "55%" }}
              onChange={handleTips}
              value={content.questionTemplate?.answer?.tips}
            />
          </div>
        </div>
        <Button
          width="20%"
          height="8%"
          name={submitButtonText}
          fontSize="1.5rem"
          onClick={handleSubmit}
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeQuestionTemplate: (template: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: () => void;
  handleTextBoxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems,
    order?: number
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(questionTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Q9);
