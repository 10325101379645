import { Action } from "./actionTypes";
import { ILiveTimeSeries } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { ITSReadingFilter } from "../../../components/admin/TSReading/types";

// States' definition
export interface ITSReadingState {
  isLoading: boolean;
  tsReadings: ILiveTimeSeries[];
  tsReadingFilter: ITSReadingFilter;
  totalCount: number;
}
export interface State {
  tsReading: ITSReadingState;
}

const tsReadings = (
  state: ITSReadingState = {
    isLoading: false,
    tsReadings: [],
    tsReadingFilter: {
      page: 1,
      pageSize: 50,
      sortField: "date",
      sortOrder: "desc",
      start: moment().subtract(2, 'day').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      date: moment().format('YYYY-MM-DD'),
    },
    totalCount: 0,
  },
  action: Action
): ITSReadingState => {
  switch (action.type) {
    case "SET_TSREADING_FILTER":
      return { ...state, tsReadingFilter: action.tsReadingFilter };
    case "SET_TSREADING_LIST":
      const tsReadings = action.reset
        ? action.tsReadings
        : [...state.tsReadings, ...action.tsReadings];
      return { ...state, tsReadings, totalCount: action.totalCount };
    
    case "UPDATE_TSREADING": {
      const tsReadings = [...state.tsReadings];
      const index = tsReadings.findIndex(
        (s) => s._id === action.tsReading?._id
      );
      if (index > -1) {
        tsReadings[index] = action.tsReading;
      } else {
        tsReadings.unshift(action.tsReading);
      }
      return {
        ...state,
        tsReadings,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default tsReadings;
