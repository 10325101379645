
import { ChoiceGroup, DefaultButton, Pivot, PivotItem, PrimaryButton, Stack, Sticky, StickyPositionType, Text, TextField } from '@fluentui/react';

import _ from 'lodash';
import { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../../store';
import DynamicForm from '../../../dynamic-form/DynamicForm';
import ModalDialog from '../../common/ModalDialog';
import { onRenderPivoteItemLink } from '../../common/PivotItemRenderLink';
import { bondholderTabs, getAccountFields, getAddressFields, getGeneralFields, getpreferencesFields, getTabErrors, validateBondholder } from './BondHolderHelper';
import moment from 'moment';
import AuditLog from '../util/AuditLog';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import { getBonds, setBondsFilter } from './../../../../store/admin/funder/bond/action';
import { defaultBondFilter } from '../bond/BondHelper';
import { saveBondHolder } from '../../../../store/admin/funder/bondHolder/action';
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import BondHolderBonds from './BondHolderBonds';

export const openBondHolderModal = (props: IEditBondHolderProps) => {
  return new Promise<IBondHolder | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (bondholder?: IBondHolder) => {
      resolve(bondholder);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add/Edit BondHolder"
          onDismiss={() => callback(undefined)}
        >
          <BondHolderDialog
            {...props}
            onSave={bondholder => callback(bondholder)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const BondHolderDialog = (props: IEditBondHolderProps) => {
  const dispatch = useDispatch()

  // const { bondFilter }: IBondState = useSelector<RootState, IBondState>((state: RootState) => state.web.bonds)

  const [bondHolder, setBondHolder] = useState<IBondHolder>(_.cloneDeep({ ...props.bondHolder }));
  const [errors, setErrors] = useState<any>({});
  const [tabErrors, setTabErrors] = useState({ ...bondholderTabs });
  const generalFields = useMemo(() => getGeneralFields({ bondHolder }), [bondHolder]);
  const accountFields = useMemo(() => getAccountFields({ bondHolder }), [bondHolder]);
  const addressFields = useMemo(() => getAddressFields({ bondHolder }), [bondHolder]);
  const preferencesFields = useMemo(() => getpreferencesFields({ bondHolder }), [bondHolder]);



  const handleOnChangeInput = (key: string, value: any) => {
    const numRegex = /^\d+$/
    let bh: IBondHolder = _.cloneDeep(bondHolder);
    if (errors[key]) {
      const err = { ...errors };
      delete err[key];
      const tabErrors = getTabErrors(err, bh);
      setErrors(err);
      setTabErrors(tabErrors);
    }
    switch (key) {
      case 'details.email': {
        if (!bh._id) bh.userName = value?.toLowerCase()
        bh = _.set({ ...bh }, key, value);
      }
        break;
      case 'account.number':
        if (value && !numRegex.test(value)) {
          errors[key] = "Invalid account number only digit allowed"
          setErrors(errors);
          return
        }
        bh = _.set({ ...bh }, key, value);
        break
      case 'account.sortCode':
        if (value && !numRegex.test(value)) {
          errors[key] = "Invalid, only digit allowed"
          setErrors(errors);
          return
        }
        bh = _.set({ ...bh }, key, value);
        break
      default:
        bh = _.set({ ...bh }, key, value);
        break;
    }
    setBondHolder(bh);
  };

  const onPivotLinkClick = async (props: any) => {
    if (props.key === ".$bonds") {
      dispatch(setBondsFilter({ ...defaultBondFilter, userName: bondHolder.userName }))
      dispatch(getBonds())
    }
  };

  const setValidationErrors = (errors: any) => {
    const tabErrors = getTabErrors(errors, bondHolder);
    setErrors(errors);
    setTabErrors(tabErrors);
  }

  const handleSave = async () => {
    let errors = await validateBondholder(bondHolder);
    if (Object.keys(errors).length) {
      setValidationErrors(errors)
      return;
    }
    const saved: any = await dispatch(saveBondHolder(bondHolder))
    if (saved && saved.errors && Object.keys(saved.errors).length) {
      setValidationErrors(saved.errors)
      return;
    }
    if (props.onSave) props.onSave(bondHolder)
  };

  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <Pivot linkSize="large" onLinkClick={(item) => onPivotLinkClick(item)}>
          <PivotItem
            key="general"
            headerText="General"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.general)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={generalFields}
                data={bondHolder}
                onChange={handleOnChangeInput}
                errors={errors}
              />
              <ChoiceGroup
                className="inlineflex ms-Grid-col ms-lg2"
                label="Status"
                selectedKey={bondHolder.status}
                onChange={(e, option: any) => handleOnChangeInput("status", option?.key)}
                options={[{ key: 'Open', text: 'Open' }, { key: 'Closed', text: 'Closed' }]}
              />
            </div>
            <div className='ms-Grid-row'>
              <TextField
                label="Notes"
                placeholder=""
                onChange={(e: any, value: any) => handleOnChangeInput('notes', value)}
                multiline={true}
                rows={3}
                className='ms-Grid-col ms-lg12'
                value={bondHolder?.notes!} />
            </div>
          </PivotItem>
          <PivotItem
            key="account"
            headerText="Account"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.account)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={accountFields}
                data={bondHolder}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
          </PivotItem>
          <PivotItem
            key="address"
            headerText="Address"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.address)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={addressFields}
                data={bondHolder}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
          </PivotItem>
          <PivotItem
            key="preferences"
            headerText="Preferences"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.preferences)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={preferencesFields}
                data={bondHolder}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
          </PivotItem>
          {bondHolder._id && (
            <PivotItem key="bonds" headerText="Bonds">
              <Stack>
                <BondHolderBonds bondHolder={bondHolder} />
              </Stack>
            </PivotItem>
          )}
          {bondHolder._id && (
            <PivotItem key="auditLog" headerText="Audit Log">
              <AuditLog auditLog={[...bondHolder.auditLog]} />
            </PivotItem>)}
        </Pivot>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface IEditBondHolderProps {
  onCancel?: () => void;
  onSave?: (bondholder: IBondHolder) => void;
  bondHolder: IBondHolder;
  onFetchBonds?: () => any
}

export default BondHolderDialog;
