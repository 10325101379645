import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getGeneralFields, validateYield } from "./Yieldhelper"
import { IYield } from "@solarforschools/sfs-core/dist/solardb/types"
import { createUpdateYields } from "../../../store/admin/yields/action"


export const openYieldsModal = (props?: any) => {
  return new Promise<IYield | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: IYield) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add Edit Yield"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditYieldDialog
            {...props}
            onSave={(data: IYield | undefined) => callback(data)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditYieldDialog = (props: YieldsProps) => {
  const dispatch = useDispatch();

  const [yieldData, setYieldData] = useState<IYield>(_.cloneDeep(props.data as IYield))
  const [errors, setErrors] = useState<any>({});

  const generalFields = useMemo(() => getGeneralFields({ yieldData }), [yieldData]);

  const handleOnSave = async () => {
    const errors = await validateYield(yieldData);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    await dispatch(createUpdateYields(yieldData))
    props.onSave(yieldData)
  }


  const handleOnChangeInput = (key: string, value: any) => {

    let data: IYield = _.cloneDeep(yieldData);
    switch (key) {
      case 'G':
      case 'I':
      case 'E':
      case 'C':
      case 'EV':
      case 'SC':
        setYieldData({ ...data, data: { ...data.data, [key]: value } })
        break;
      default:
        setYieldData(_.set({ ...data }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <DynamicForm
            fields={generalFields}
            data={yieldData}
            onChange={handleOnChangeInput}
            errors={errors}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface YieldsProps {
  onCancel?: () => void;
  onSave: (data?: IYield) => void;
  data?: IYield;
}

export default openYieldsModal;
