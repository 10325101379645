import { IBlog } from "@solarforschools/sfs-core/dist/blog/types";
import axios from "axios";
import { StringMap } from "../../components/admin/common/types";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";
import { ObjectId } from "mongodb";

let GetBlogAPiAbortController: AbortController

export const getBlogs = (
  filters: any = {},
  skip?: number,
  limit?: number,
  sort?: StringMap
): Promise<{ blogs: IBlog[]; count: number }> => {
  const queryStr = generateQueryString({ ...filters, ...sort, skip, limit });
  if (GetBlogAPiAbortController) GetBlogAPiAbortController.abort()
  GetBlogAPiAbortController = new AbortController()
  return axios.get(`${host}/admin/blogs?${queryStr}`, { signal: GetBlogAPiAbortController.signal }).then((res) => res.data);
};

export const deleteOneBlog = (
  blogId: ObjectId
): Promise<{ blogs: IBlog[]; count: number }> => {
  return axios.delete(`${host}/admin/blog/${blogId}`).then((res) => res.data);
};


export const createBlogAPi = (data: IBlog): Promise<any> => {
  return axios
    .post(`${host}/admin/blog`, data)
    .then((res) => res.data);
};

export const updateBlogApi = (data: IBlog): Promise<any> => {
  return axios
    .put(`${host}/admin/blog/${data._id}`, data)
    .then((res) => res.data);
};

export const validateBlogApi = (data: IBlog): Promise<any> => {
  return axios
    .put(`${host}/admin/blog/validate/blog`, data)
    .then((res) => res.data);
};

export const getBlogSitesAndCategoriesApi = (): Promise<any> => {
  return axios
    .get(`${host}/admin/blog/categories-sites`)
    .then((res) => res.data);
};

export const uploadBlogImageApi = async (fileContent: any) => {
  try {
    const formData = new FormData();
    formData.append("files", fileContent.file);
    const res = await axios.post(
      `${host}/admin/blog/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const deleteBlogImageApi = async (image: string) => {
  try {
    const res = await axios.delete(
      `${host}/admin/blog/image?image=${image}`
    );
    return res;
  } catch (e) {
    return null;
  }
};