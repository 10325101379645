import React from "react";
import FileUpload from "../formElements/FileUpload";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import { imagePlaceholder } from "../config";
import { IContent } from "../../../../store/content/reducer";
import {
  ContentItems,
  IContentItems,
} from "../../../../store/content/actionTypes";

const CFlip = ({ template, content, seq, dispatchContent }: props) => {
  const handleTextboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number
  ) => {
    addContent(ContentItems.TITLE, e.target.value, sequence);
  };

  const handleTextareaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    sequence: number
  ) => {
    addContent(ContentItems.PARAGRAPH, e.target.value, sequence);
  };

  const handleFileChange = (file: File | null, sequence: number) => {
    let contentItem: IContentItems = {
      type: ContentItems.IMAGE,
      sequence,
      text: "",
      file: file,
      fileName: file?.name,
    };
    dispatchContent(contentItem, sequence);
  };

  const addContent = (
    contentType: ContentItems,
    value: string,
    sequence: number
  ) => {
    let contentItem: IContentItems = {
      type: contentType,
      sequence,
      text: value,
    };

    dispatchContent(contentItem, sequence);
  };

  return (
    <>
      <Textbox
        placeholder="Enter Sub heading"
        maxChar={30}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleTextboxChange(e, seq[0])
        }
        width="100%"
        margin="1rem 0 1rem 0"
        value={
          content.contentTemplate?.items?.find(
            (item) => item.sequence === seq[0]
          )?.text
        }
      />
      {template === "C5" ? (
        <Textarea
          placeholder="Enter your content here"
          value={
            content.contentTemplate?.items?.find(
              (item) => item.sequence === seq[1]
            )?.text
          }
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleTextareaChange(e, seq[1])
          }
          maxChar={500}
          width="100%"
          height="35%"
        />
      ) : null}
      <FileUpload
        name="+ Add Visual"
        url={imagePlaceholder}
        width="100%"
        height="35%"
        onChangeCallback={(file: File | null) => handleFileChange(file, seq[2])}
      />
      <Textarea
        placeholder="Enter your content here"
        value={
          content.contentTemplate?.items?.find(
            (item) => item.sequence === seq[3]
          )?.text
        }
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          handleTextareaChange(e, seq[3])
        }
        maxChar={500}
        width="100%"
        height="35%"
        margin="0 0 2rem 0"
      />
    </>
  );
};

export interface props {
  template: string;
  content: IContent;
  seq: number[];
  dispatchContent: (contentItem: IContentItems, sequence: number) => void;
}

export default CFlip;
