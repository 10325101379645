import { useCallback, useEffect, useState } from 'react'
import { ComboBox, DatePicker, IComboBoxOption, TextField } from "@fluentui/react/lib/index";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from '../../../store';
import Loader from '../common/Loader';
import Opportunities from './OpportunitiesList';
import { opportunityTypes } from './common';
import { IOpportunity } from '../../../store/admin/opportunity/reducer';
import { getOpportunities, fetchOpportunityFilter, setOpportunityFilter } from '../../../store/admin/opportunity/action';
import _ from 'lodash';
import { OpportunityStatus, dropdownStyles, months } from '../common/common';
import moment from 'moment';
import OpportunityFilter from './OppFilter';


const Index = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOpportunities())
  }, []);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Opportunities</h1>
        <OpportunityFilter />
      </header>
      <Opportunities actions={['edit', 'delete']} />
    </div>
  );
}
export default Index;

