import React, { ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'
import { useGoogleMap } from '@react-google-maps/api'

const MapControl = ({ position, children, zIndex = 0 }: MapControlProps) => {
    const map = useGoogleMap()
    const [container] = useState(document.createElement('div'))
    React.useEffect(() => {
        const controlsContainer: any = map?.controls[position]
        controlsContainer?.push(container)
        return () => {
            const index = controlsContainer?.indexOf(container)
            if (index !== -1) {
                controlsContainer?.removeAt(index)
            }
        }
    }, [map])
    React.useEffect(() => {
        container.style.zIndex = zIndex + ''
    }, [zIndex])
    return createPortal(children, container)
}

interface MapControlProps {
    position: number,
    children: ReactNode,
    zIndex: number | string,
}

export default MapControl