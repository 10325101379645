import {
    Dropdown,
    IDropdownStyles
} from "@fluentui/react/lib/Dropdown";
import {
    ComboBox,
    IComboBoxOption,
    IComboBoxStyles
} from "@fluentui/react/lib/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
    fetchSystemsData, setDetailListFilter
} from "../../../store/admin/system/action";
import { ISystemState } from "../../../store/admin/system/reducer";
import { Providers } from "./common";
import { FundingOptions } from "../common/common";
import SystemPicker from "../common/SystemPicker";
import SchoolPicker from "../common/SchoolPicker";


const SystemFilter = () => {
    const dispatch = useDispatch();

    const { systemFilterParams, schoolFilter, services: allServices, countriesFilter }: ISystemState = useSelector<RootState, ISystemState>((state) => state.web.system);
    const dropdownStyles: Partial<IDropdownStyles> = {};
    const comboBoxStyles: Partial<IComboBoxStyles> = {
        container: { width: 300 },
    };
    const handleOnChange = (
        key: string,
        value?: any,
    ) => {
        let params = { ...systemFilterParams, page: 1 };
        switch (key) {
            case "selectedSchool":
                params = { ...params, schoolSlug: value[0]?.key }
                break;
            case 'selectedSystems':
                params = { ...params, system: value[0]?.key }
                break
            case "services":
                let services = params.services || [];
                if (value?.selected) {
                    if (!services?.find((s) => s === value?.key)) {
                        services.push(value.key);
                    }
                } else {
                    services = services?.filter((s) => s !== value?.key) as string[];
                }
                params.services = services
                break;
            default: {
                params = { ...params, [key]: value }
            }
        }
        dispatch(setDetailListFilter(params));
        dispatch(fetchSystemsData(params));
    };

    const params = { all: true, stage: ['Operate', 'Build', 'Developed', 'Funded', 'Managed'], live: true }

    const {
        ownedBy,
        billedBy,
        schoolSlug,
        country,
        provider,
        services
    } = systemFilterParams;
    return (
        <div
            className="ms-Grid ms-Grid-row"
            dir="ltr"
            style={{ width: "-webkit-fill-available", minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className={`ms-Grid-col ms-lg4 margin-top-xsm`}>
                <SchoolPicker filters={params} onChange={handleOnChange} />
            </div>
            <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
                <SystemPicker filters={{ schoolSlug }} onChange={handleOnChange} />
            </div>
            <ComboBox
                label="Country"
                placeholder="System Country"
                selectedKey={country || "all"}
                allowFreeform={true}
                autoComplete={"on"}
                onChange={(e, item) => handleOnChange("country", item?.key?.toString())}
                options={countriesFilter}
                styles={comboBoxStyles}
                className="ms-Grid-col ms-lg1"
            />
            <Dropdown
                dropdownWidth='auto'
                label="Billed By"
                selectedKey={billedBy || 'all'}
                onChange={(e, item) => handleOnChange("billedBy", item?.key?.toString())}
                options={FundingOptions}
                styles={dropdownStyles}
                className="ms-Grid-col ms-lg1"
            />
            <Dropdown
                dropdownWidth='auto'
                label="Owned By"
                selectedKey={ownedBy || 'all'}
                onChange={(e, item) => handleOnChange("ownedBy", item?.key?.toString())}
                options={FundingOptions}
                styles={dropdownStyles}
                className="ms-Grid-col ms-lg1"
            />
            <Dropdown
                dropdownWidth='auto'
                label="Provider"
                selectedKey={provider || 'all'}
                onChange={(e, item) => handleOnChange("provider", item?.key?.toString())}
                options={Providers}
                styles={dropdownStyles}
                className="ms-Grid-col ms-lg1"
            />
            <ComboBox
                label="Services"
                selectedKey={services! || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                    allServices!.map(s => ({ key: s, text: s })) as IComboBoxOption[]
                }
                onChange={(e: any, item: any) => handleOnChange("services", item,)}
                styles={comboBoxStyles}
                className="ms-Grid-col ms-lg1"
            />
        </div>
    );
};

export default SystemFilter;
