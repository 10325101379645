
import { generateQueryString } from '../../../utils/util';
import axios from 'axios';
import { host } from '../../config';
import { IBondOfferFilter } from '../../../components/admin/funder/bondOffer/types';
import { IBondOffer } from '@solarforschools/sfs-core/dist/funder/bond-offer/types';
import { ParsedUrlQueryInput } from 'querystring';

let GetBondOfferAPiAbortController: AbortController

export const getBondOffersApi = (params: IBondOfferFilter): Promise<{ bondOffers: IBondOffer[]; totalCount: number }> => {
  const queryStr = generateQueryString({ ...params });
  // if (GetBondOfferAPiAbortController) GetBondOfferAPiAbortController.abort()
  GetBondOfferAPiAbortController = new AbortController()
  return axios.get(`${host}/funder/bond-offers?${queryStr}`, { signal: GetBondOfferAPiAbortController.signal }).then((res) => res.data);
};


export const exportBondOffersApi = (query: IBondOfferFilter): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/funder/bond-offers/export-offers?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}
export const getBondOfferByIdApi = (id: string): Promise<any> => {
  return axios.get(`${host}/funder/bond-offers/${id}`).then((res) => res.data);
};

export const deleteBondOfferApi = (offerId: string): Promise<{ bondOffer: IBondOffer, msg: string }> => {
  return axios.delete(`${host}/funder/bond-offers/${offerId}`).then((res) => res.data);
};

export const createBondOfferAPi = (data: IBondOffer): Promise<any> => {
  return axios
    .post(`${host}/funder/bond-offers`, data)
    .then((res) => res.data);
};

export const updateBondOfferApi = (data: IBondOffer, overrideBond: boolean = false): Promise<any> => {
  return axios
    .put(`${host}/funder/bond-offers/${data._id}?overridebond=${overrideBond}`, data)
    .then((res) => res.data);
};

export const genBondOfferTransactiobsApi = (offerId: string): Promise<{ bondOffer: IBondOffer, msg: string }> => {
  return axios.put(`${host}/funder/bond-offers/create-transaction/${offerId}`).then((res) => res.data);
};

export const bondOfferRepaymetAPi = (data: any): Promise<{ bondOffer: IBondOffer, msg: string }> => {
  return axios.put(`${host}/funder/bond-offers/repayment/${data._id}`, data).then((res) => res.data);
};
