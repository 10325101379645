import { Panel } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { changeContentTemplate, getGlossary } from '../../../store/content/action';
import { IContentTemplate, IGlossary } from '../../../store/content/actionTypes';
import { useEffect, useMemo } from 'react';

const GlossaryPanel = (props: GlossaryPanelProps) => {
  const dispatch = useDispatch()
  const contentTemplate = useSelector<RootState, IContentTemplate>(state => state.learning.content.contentTemplate!)
  const glossary = contentTemplate?.glossary || []

  const handleChangeWord = (index: number, value: string) => {
    const newContentTemplate = {
      ...contentTemplate,
      glossary: [...glossary]
    }
    newContentTemplate.glossary[index] = value
    dispatch(changeContentTemplate(newContentTemplate))
  }

  const handleAddWord = () => {
    const newContentTemplate = {
      ...contentTemplate,
      glossary: [...glossary, '']
    }
    dispatch(changeContentTemplate(newContentTemplate))
  }

  const handleRemoveWord = (index: number) => {
    const newGlossary = [...glossary]
    newGlossary.splice(index, 1)
    const newContentTemplate = {
      ...contentTemplate,
      glossary: newGlossary
    }
    dispatch(changeContentTemplate(newContentTemplate))
  }

  return (
    <Panel
      headerText="Words in glossary"
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      closeButtonAriaLabel="Close"
    >
      <div className="glossary-panel">
        {glossary.map((word, index) => {
          return (
            <div className="word-item">
              <TextField
                key={index}
                value={word}
                styles={{ root: { width: '100%' } }}
                onChange={(e, value) => handleChangeWord(index, value!)}
              />
              <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                onClick={() => handleRemoveWord(index)}
              />
            </div>
          )
        })}
      </div>
      <DefaultButton
        text="New word"
        iconProps={{ iconName: 'Add' }}
        allowDisabledFocus
        onClick={handleAddWord}
      />
    </Panel>
  );
}

interface GlossaryPanelProps {
  isOpen: boolean
  dismissPanel: () => void
}

export default GlossaryPanel
