import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
  Stack,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import { TextField } from "office-ui-fabric-react";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "../../../store";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import { IProps } from "./EnvConfigLists";

// Open the modal dialog - return promise(Await to resolve data)
export const openEnvConfigDialog = (props: IProps[]) => {
  return new Promise<IProps[] | undefined>((resolve, reject) => {
    const mountTarget = document.createElement("div");
    document.body.appendChild(mountTarget);
    const callback = (result?: IProps[]) => {
      resolve(result);
      ReactDOM.unmountComponentAtNode(mountTarget);
      mountTarget.remove();
    };

    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Edit Environment Configurations"}
          onDismiss={() => callback(undefined)}
        >
          <EnvConfigDialog
            envConfigData={props}
            onCancel={() => callback(undefined)}
            onSave={(regionConfig: any) => callback(regionConfig)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    );
  });
};

//Component for Modal dialog - to edit the environment configuration(Add Column, Edit, Delete)
const EnvConfigDialog = (props: EnvConfigDialogProps) => {
  const [tempRes, setTempRes] = useState<IProps[]>(props.envConfigData);

  //refer the end div to scroll to that position
  const endRef = useRef<null | HTMLDivElement>(null);

  const handleChangeInput = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e?.target as HTMLInputElement;
    const list: Record<string, any> = [...tempRes];
    list[index][name] = value;
    setTempRes(list as IProps[]);
  };

  // To Create a new column
  const handleAddClick = () => {
    setTimeout(() => {
      endRef.current?.scrollIntoView();
    }, 100);

    setTempRes([...tempRes, { EnvName: "", EnvData: "" }]);
  };

  //Send data to openEnvConfigModal to resolve it
  const handleOnSave = async () => {
    //filter the empty envName, where envName is id in originalObject
    const res = tempRes.filter((el) => el.EnvName !== "");

    const isConfirmed = await ConfirmDialog({
      dialogContentProps: {
        title: " Save configuration",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to save configuration ?`,
      },
      confirmBtnText: "Save",
    });
    if (isConfirmed) props.onSave(res);
  };

  //Delete the column
  const handleRemoveClick = (index: number) => {
    const list = [...tempRes];
    list.splice(index, 1);
    setTempRes(list);
  };
  return (
    <div>
      <h2 style={{ textAlign: "center", margin: "1rem" }}>
        List Of Environment Variables
      </h2>

      <div className="centerTable">
        <table className="envConfigTable">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {tempRes &&
              tempRes.map((el: IProps, i: number) => {
                const { EnvName, EnvData } = el;
                return (
                  <tr key={i}>
                    <td>
                      <TextField
                        name="EnvName"
                        value={EnvName}
                        placeholder="Enter Environment Key"
                        className="envConfigModalTextBox"
                        onChange={(e) => handleChangeInput(e, i)}
                      />
                    </td>
                    <td>
                      <TextField
                        name="EnvData"
                        value={EnvData}
                        placeholder="Enter Environment Value"
                        className="envConfigModalTextBox"
                        onChange={(e) => handleChangeInput(e, i)}
                      />
                    </td>
                    <td>
                      <ActionButton
                        iconProps={{ iconName: "Delete" }}
                        onClick={() => handleRemoveClick(i)}
                        style={{margin:0}}

                      />
                    </td>
                  </tr>
                );
              })}
            <div ref={endRef} />
          </tbody>
        </table>
      </div>

      <Sticky stickyPosition={StickyPositionType.Footer}>
        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DefaultButton text="Add Environment" onClick={handleAddClick} />

          <Stack horizontal horizontalAlign="center" className="margin-top-xs">
            <Stack.Item>
              {tempRes && (
                <PrimaryButton
                  text="Save"
                  onClick={handleOnSave}
                  className="btn-primary"
                />
              )}
              <DefaultButton onClick={props.onCancel} text="Cancel" />
            </Stack.Item>
          </Stack>
        </div>
      </Sticky>
    </div>
  );
};

export default EnvConfigDialog;

interface EnvConfigDialogProps {
  envConfigData: IProps[];
  onSave: (regionConfig: IProps[]) => void;
  onCancel: () => void;
}
