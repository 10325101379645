import React, { useState } from 'react';
import Moment from 'moment'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { DatePicker, Dropdown, Stack, Sticky, StickyPositionType, TextField } from '@fluentui/react';
// import { useConst } from '@fluentui/react-hooks';

import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import { IBillingReadingDocument, IReadingParams } from './types';
import { dropdownStyles, ReadingTypes } from '../common/common';
import { useDispatch } from 'react-redux';
import { getMeterReading } from '../../../store/admin/billing/action';



export const EditReading = ({
    reading,
    onCancel,
    onSave
}: Props) => {
    const dispatch = useDispatch();
    const [selectedReading, setSelectedReading] = useState(_.cloneDeep(reading))
    const handleOnChangeInput = async (key: string, value: any) => {
        let reading = { ...selectedReading }
        switch (key) {
            case 'endDate':
            case 'startDate':
                reading = _.set({ ...reading }, key, value)
                let params = {
                    id: reading.siteId,
                    mid: reading.mid,
                    type: reading.type,
                    date: value,
                    MSN: reading.MSN
                }
                const data: any = await dispatch(getMeterReading(params));
                if (key === 'endDate') {
                    reading = { ...reading, endReading: data?.r ? Math.round(data.r / 1000) : 0, endType: data?.rType } // to convert to kwh
                } else {
                    reading = { ...reading, startReading: data?.r ? Math.round(data.r / 1000) : 0, startType: data?.rType }
                }
                reading = { ...reading, "yield": +Math.round((reading.endReading - reading.startReading) * (reading.factor)) }
                break
            case 'startReading':
                reading = _.set({ ...reading }, key, value)
                if (reading.startReading && reading.endReading) {
                    reading = { ...reading, "yield": +Math.round((reading.endReading - reading.startReading) * (reading.factor)) }
                }
                break
            case 'endReading':
                reading = _.set({ ...reading }, key, value)
                if (reading.startReading && reading.endReading) {
                    reading = { ...reading, "yield": +Math.round((reading.endReading - reading.startReading) * (reading.factor)) }
                }
                break
            case 'factor':
                reading = _.set({ ...reading }, key, value)
                if (reading.startReading && reading.endReading) {
                    reading = { ...reading, "yield": +Math.round((reading.endReading - reading.startReading) * (reading.factor)) }
                }
                break
            default:
                reading = _.set({ ...reading }, key, value)
                break;
        }
        setSelectedReading(reading)
    }

    return (
        <>
            <div className="edit-record">
                <div className="ms-Grid" dir="ltr">
                    <section className='margin-top-md'>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-lg2">
                                <TextField label="Site Id" required readOnly placeholder="site id"
                                    value={selectedReading?.siteId?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg8">
                                <TextField label="Site Name" required readOnly placeholder="site same"
                                    value={selectedReading?.siteName?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg2">
                                <TextField label="Period" required readOnly placeholder="period"
                                    value={selectedReading?.period?.toString()!}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-lg2">
                                <TextField label="Mid" required readOnly placeholder="mid"
                                    value={selectedReading?.mid?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg3">
                                <TextField label="MSN" required readOnly placeholder="MSN"
                                    value={selectedReading?.MSN?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg2">
                                <TextField label="Type" required readOnly placeholder="type"
                                    value={selectedReading?.type?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg2">
                                <TextField label="Factor"
                                    required
                                    placeholder="factor"
                                    type="number"
                                    step="0.1"
                                    onChange={(e, value) => handleOnChangeInput('factor', value && parseFloat(value))}
                                    value={selectedReading?.factor?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg2">
                                <TextField label="Yield" placeholder="Yield"
                                    type="number"
                                    step="0.1"
                                    onChange={(e, value) => handleOnChangeInput('yield', value && parseFloat(value))}
                                    value={selectedReading?.yield?.toString()!}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-lg3">
                                <DatePicker
                                    label="Start Date"
                                    placeholder="Select a date..."
                                    ariaLabel="Select a date"
                                    value={new Date(selectedReading?.startDate!)}
                                    onSelectDate={(date) => handleOnChangeInput('startDate', Moment(date).startOf('day').format('YYYY-MM-DD'))}
                                    styles={dropdownStyles}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg2">
                                <TextField
                                    label="Start Reading"
                                    placeholder="start reading"
                                    type="number"
                                    step="1"
                                    onChange={(e, value) => handleOnChangeInput('startReading', value && parseFloat(value))}
                                    value={selectedReading?.startReading?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg7">
                                <Dropdown
                                    label="Start Reading Type"
                                    selectedKey={selectedReading.startType?.toString() || '5'}
                                    options={ReadingTypes.filter(r => r.key !== 'all')}
                                    onChange={(e, item) => handleOnChangeInput('startType', item?.key)}
                                    styles={dropdownStyles}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-lg3">
                                <DatePicker
                                    label="End Date"
                                    placeholder="Select a date..."
                                    ariaLabel="Select a date"
                                    value={new Date(selectedReading?.endDate!)}
                                    onSelectDate={(date) => handleOnChangeInput('endDate', Moment(date).startOf('day').format('YYYY-MM-DD'))}
                                    styles={dropdownStyles}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg2">
                                <TextField
                                    label="End Reading"
                                    placeholder="end reading"
                                    type="number"
                                    step="1"
                                    onChange={(e, value) => handleOnChangeInput('endReading', value && parseFloat(value))}
                                    value={selectedReading?.endReading?.toString()!}
                                />
                            </div>
                            <div className="ms-Grid-col ms-lg7">
                                <Dropdown
                                    label="End Reading Type"
                                    selectedKey={selectedReading.endType?.toString() || '5'}
                                    options={ReadingTypes.filter(r => r.key !== 'all')}
                                    onChange={(e, item) => handleOnChangeInput('endType', item?.key)}
                                    styles={dropdownStyles}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-lg12">
                                <TextField
                                    label="Comment"
                                    multiline
                                    autoAdjustHeight
                                    onChange={(e, value) => handleOnChangeInput('comment', value)}
                                    value={selectedReading?.comment}
                                />
                            </div>
                        </div>
                    </section>
                </div>
                <Sticky stickyPosition={StickyPositionType.Footer}>
                    <Stack horizontal horizontalAlign="center">
                        <Stack.Item>
                            <PrimaryButton text="Save" onClick={() => onSave(selectedReading)} className='btn-primary' />
                            <DefaultButton onClick={onCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </div>
        </>
    );
};
interface OwnProps {
    onCancel: () => void
    onSave: (system: IBillingReadingDocument) => void
    reading: IBillingReadingDocument
}

type Props = OwnProps;
