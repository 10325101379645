import { ActionButton, CheckboxVisibility, IColumn, Label, ScrollablePane, ScrollbarVisibility, SelectionMode, Text } from '@fluentui/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '../../../store/admin/supporters/reducer'
import { RootState } from '../../../store'
import DetailsListWapper from '../../common/DetailsListWapper';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader'
import { getSupporters } from './helper'
import { openAddSupporterDialog } from './Add'
import { ISupporters } from '@solarforschools/sfs-core/dist/supporters/types'
import { ConfirmDialog } from '../common/ConfirmDialog'
import { deleteASupporter } from '../../../store/admin/supporters/action'
import { openEditSupporterDialog } from './Edit'

const SupportersLists = () => {

    const { data, isLoading } = useSelector<RootState, IInitialState>((state) => state.web.supporters)
    const dispatch = useDispatch()

    const handleAddtion = async () =>  await openAddSupporterDialog()
    

    const handleEdit = async (data: ISupporters) => {
        if (data?._id === undefined) return
        await openEditSupporterDialog(data)
    }

    const handleDelete = async (data: ISupporters) => {
        if (data?.supporterId === undefined) return
        const confirm = await ConfirmDialog({
            dialogContentProps: {
                title: 'Confirm delete',
                closeButtonAriaLabel: 'Close',
                subText: `Are you sure to delete this supporter?`,
            },
            confirmBtnText: 'OK'
        })
        if (confirm) {
            dispatch(deleteASupporter(data?.supporterId.toString(), data?.icon))
        }
    }


    // Render Columns
    const [columns] = useState<IColumn[]>(getSupporters({
        actions: [
            {
                icon: 'Edit',
                ariaLabel: 'Edit',
                onClick: handleEdit,
            },
            {
                icon: 'Delete',
                ariaLabel: 'Delete',
                onClick: handleDelete,
            }
        ]
    }))

    if (isLoading) return <></>

    return (
        <>
            <div style={{ textAlign: "right" }} >
                <ActionButton
                    iconProps={{ iconName: "Add" }}
                    style={{
                        color: "var(--solar-blue)"
                    }}
                    onClick={handleAddtion}
                >
                    Add Supporter
                </ActionButton>
            </div>
            {
                data.length > 0 && <div>
                    <Text variant='medium'>Displaying: <strong>{data.length}</strong></Text>
                </div>
            }
            {
                data.length === 0 ? <Text variant='medium' style={{ textAlign: "center" }}> No Supporters found </Text> : <div className="data-list-container">
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <DetailsListWapper
                            items={data}
                            onRenderDetailsHeader={DetailListStickyHeader}
                            columns={columns}
                            checkboxVisibility={CheckboxVisibility.always}
                            selectionMode={SelectionMode.none}
                            compact={true}
                            isLoading={isLoading}
                        />
                    </ScrollablePane>
                </div>
            }
        </>
    )
}

export default SupportersLists