import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import ModalDialog from "../common/ModalDialog";
import { CSSProperties, useEffect, useState } from "react";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import DynamicForm, { IField } from "../../dynamic-form/DynamicForm";
import { FUND_FEE, getOpexGeneralFields, restructureThis } from "./Helper";
import { cloneDeep } from "lodash";
import { ICapexRes, IOpexRes } from "@solarforschools/sfs-core/dist/analysis/types";
import { IResArrOfObj, IResult } from "./types"
import { ICostSummary } from "../../../store/admin/costSummary/reducers";
import { ICapex } from "@solarforschools/sfs-core/dist/analysis/types";

export interface IProps {
    opex: IOpexRes[],
    capexRender: IResArrOfObj[]
}

export const openAddActualsDialog = (props: IProps) => {
    return new Promise<IResult | undefined>((resolve, reject) => {
        const mountTarget = document.createElement("div");
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result);
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }

        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add Actuals"}
                    onDismiss={() => callback(undefined)}
                >
                    <AddActualsDialog
                        opexData={props.opex}
                        capexRender={props.capexRender}
                        onCancel={() => callback(undefined)}
                        onSave={(data: IResult) => callback(data)}
                    />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

// Add or Modify the Capex, Opex actuals
const AddActualsDialog = (props: IAddActualsProps) => {

    const { opexData, capexRender } = props // capexRender -> data which used to render capex 
    const { designsResult } = useSelector<RootState, ICostSummary>((state: RootState) => state.web.costSummary)
    const designsActuals: ICapex | undefined | string = designsResult[0]?.actuals
    let modifiedObj: Record<string, string | number | symbol> = {}, testValues: Record<string, string | boolean | number> = {}
    const textAlignment = { textAlign: "center", margin: "1rem" }
    const layoutStyle = { height: "80vh", overflow: "scroll" }

    const [waitToBuild, setWaitToBuild] = useState(true)
    const [generalFields, setGeneralFields] = useState<IField[] | []>([])
    const [processThis, setProcessThis] = useState<Record<string, string[]>>({})

    // Build Forms Based on capexRender(which used to render capex table)
    function buildGeneralFields(resArrOfObj: IResArrOfObj[]) {
        if (resArrOfObj.length === 0) return
        let results: IField[] = []

        // If designs don't have actuals
        if (designsActuals === "Nil") {

            resArrOfObj.forEach((res) => {
                const { name, isActuals, paymentBy, uniqueId } = res
                if (isActuals) {
                    const textField = {
                        label: name,
                        key: `${name}?%%?${uniqueId}`,
                        type: "number",
                        extras: { required: true },
                        placeholder: `Enter ${name}`,
                        className: 'ms-Grid-col  ms-lg3',
                        handleWheel: (event: React.MouseEvent<HTMLElement>) => event.currentTarget.blur(),
                    }
                    results.push(textField as IField)

                    if (paymentBy) {
                        const paymentBySwitch = {
                            key: paymentBy,
                            label: 'Payment By',
                            type: 'switch',
                            className: 'ms-Grid-col ms-lg1',
                            extras: {
                                onText: 'CBS',
                                offText: 'SOFS',
                            },
                        }
                        results.push(paymentBySwitch as IField)
                    }

                    const paymentByDate = {
                        key: `${name}-${uniqueId}-p`,
                        label: `${name} paid on`,
                        type: 'date',
                        placeholder: 'Pick Payment date',
                        className: 'ms-Grid-col ms-lg2',
                    }
                    results.push(paymentByDate as IField)

                }
            })
        } else {

            resArrOfObj.forEach((res) => {
                const { name, isActuals, paymentBy, paymentById, uniqueId } = res
                if (isActuals) {
                    const textField = {
                        label: name,
                        key: `${name}?%%?${uniqueId}`,
                        type: "number",
                        extras: { required: true },
                        placeholder: `Enter ${name}`,
                        className: 'ms-Grid-col  ms-lg3',
                        handleWheel: (event: React.MouseEvent<HTMLElement>) => event.currentTarget.blur(),
                    }
                    results.push(textField as IField)
                    if (paymentBy) {
                        const paymentBySwitch = {
                            key: paymentById,
                            label: 'Payment By',
                            type: 'switch',
                            className: 'ms-Grid-col ms-lg1',
                            extras: {
                                onText: 'CBS',
                                offText: 'SOFS',
                            },
                        }
                        results.push(paymentBySwitch as IField)
                    }
                    const paymentByDate = {
                        key: `${name}-${uniqueId}-p`,
                        label: `${name} paid on`,
                        type: 'date',
                        placeholder: 'Pick Payment date',
                        className: 'ms-Grid-col ms-lg2',
                    }
                    results.push(paymentByDate as IField)
                }
            })
        }
        setGeneralFields(results) // render the form
        buildObjValues(capexRender) // To build Object to render
    }


    function buildObjValues(resArrOfObj: IResArrOfObj[]) {

        let resObj: Record<string, string[]> = {} // Build Object which contains capex head and items

        // Build Object's for actuals
        // If Designs === "Nil" -> create actuals with "" and paymentBy with false 
        // Else -> take key's value from designs db

        if (designsActuals === "Nil") {

            // To Build TempValues
            resArrOfObj.forEach((res) => {
                const { isActuals, name, paymentBy, uniqueId } = res
                if (isActuals) {
                    testValues[`${name}?%%?${uniqueId}`] = ""
                    testValues[`${name}-${uniqueId}-p`] = res[`${name}-${uniqueId}-p`]
                    if (paymentBy) {
                        testValues[paymentBy] = false
                    }
                }
            })
            setTempValues(testValues)

            // To Build ProcessThis 
            for (const el in resArrOfObj) {
                const { paymentBy, name, uniqueId, underHead } = resArrOfObj[el]
                const nameWithId = name + "//" + uniqueId // -> Have name/uniqueId
                if (underHead) {
                    resObj[underHead] = resObj[underHead] === undefined ? [nameWithId] : [...resObj[underHead], nameWithId]
                }
            }
        } else {
            // Populate tempValues value stored in collection (designs)
            const actualsData: ICapexRes[] = (designsActuals as ICapex).data
            let prevPaymentId = ""

            resArrOfObj.forEach((res) => {
                const { isActuals, name, paymentBy, paymentById, uniqueId } = res
                const findElm = actualsData?.find((elm: { name: string, uniqueId: any }) => elm.name === name && elm.uniqueId === uniqueId)
                if (isActuals) {
                    testValues[`${name}?%%?${uniqueId}`] = findElm?.perKWP || ""
                    testValues[`${name}-${uniqueId}-p`] = res[`${name}-${uniqueId}-p`] || new Date()
                    if (prevPaymentId !== paymentById) {
                        if (paymentBy) {
                            if (paymentById)
                                testValues[paymentById] = (findElm?.paymentBy !== undefined) ? (findElm?.paymentBy === "CBS" ? true : false) : false
                        }
                    }
                    if (paymentById)
                        prevPaymentId = paymentById
                }

            })
            setTempValues(testValues)
            for (const el in resArrOfObj) {
                const { paymentById, name, uniqueId, underHead } = resArrOfObj[el]
                const nameWithId = name + "//" + uniqueId // -> Have name/uniqueId
                if (underHead) {
                    resObj[underHead] = resObj[underHead] === undefined ? [nameWithId] : [...resObj[underHead], nameWithId]
                }
            }
        }
        setProcessThis(resObj) //! Ex: { "capex1" : ["A", "B", "C"] } where "A", "B", "C" are under one Capex "capex1"
        setWaitToBuild(false)
    }

    useEffect(() => {
        if (capexRender) {
            buildGeneralFields(capexRender)
        } else {
            setWaitToBuild(true)
        }

        return () => {
            testValues = {}
            setTempValues({})
        }

    }, [])

    opexData.map((el) => {
        const [key] = Object.keys(el)
        modifiedObj[key] = (el as any)[key]
    })

    // For Capex Values
    const [tempValues, setTempValues] = useState(cloneDeep(testValues)) //! New Capex Values
    const [errors] = useState({});

    // For Opex Values
    const [values2, setValues2] = useState(cloneDeep(modifiedObj))
    const [errors2] = useState({});


    const handleOnChange2 = (key: string, value2: any) => {
        setValues2({ ...values2, [key]: value2 === "" || 0 ? "-" : value2 })
    }

    const handleChange = (key: string, value: any) => {
        setTempValues({ ...tempValues, [key]: value === "" || 0 ? "-" : value })
    }


    const handleOnSave = async () => {

        const isConfirmed = await ConfirmDialog({
            dialogContentProps: {
                title: "Save Actuals",
                closeButtonAriaLabel: "Close",
                subText: `Do you want to save this actual ?`
            },
            confirmBtnText: "Save"
        });
        //! Capex Data
        const finalResponse = restructureThis(tempValues, capexRender, processThis)
        //! Capex Data
        //! Opex Data
        const tempObj2 = cloneDeep(values2)
        const finalResponse2: Record<string, string | number>[] = []

        const finalRespWithKeys = Object.keys(tempObj2)
        finalRespWithKeys.forEach((el: string) => {
            let obj = { [el]: tempObj2[el] }
            finalResponse2.push(obj as Record<string, string | number>)
        })
        //! Opex Data

        const result: IResult = { capex: finalResponse, opex: finalResponse2 } // Final result after editing

        if (isConfirmed) props.onSave(result)
    }

    const renderCapexOpexForm = () => {
        return <>
            <h2 style={textAlignment as CSSProperties}>CAPEX Actuals </h2>

            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row costCal'>
                    <DynamicForm
                        fields={generalFields}
                        data={tempValues}
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
            </div>

            {/* <h2 style={textAlignment as CSSProperties}>OPEX Actuals </h2>

            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row costCal'>
                    <DynamicForm
                        fields={getOpexGeneralFields}
                        data={values2}
                        onChange={handleOnChange2}
                        errors={errors2}
                    />
                </div>
            </div> */}
        </>
    }

    if (waitToBuild) return <></>

    return (
        <div>
            <div style={layoutStyle}>
                <div>
                    {renderCapexOpexForm()}
                </div>
            </div>

            <Stack horizontal horizontalAlign="center">
                <Stack.Item >
                    {tempValues && (
                        <PrimaryButton
                            text="Save"
                            onClick={handleOnSave}
                            className="btn-primary"
                        />
                    )}
                    <DefaultButton onClick={props.onCancel} text="Cancel" />
                </Stack.Item>
            </Stack>

        </div>
    )
}

export default AddActualsDialog

interface IAddActualsProps {
    opexData: IOpexRes[]
    capexRender: IResArrOfObj[]
    onSave: (result: IResult) => void;
    onCancel: () => void;
}