import React, { useEffect, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ComboBox, DatePicker, Dropdown, IComboBoxOption, IDropdownOption, Stack, Sticky, StickyPositionType, TextField } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import Moment from 'moment';
import { comboBoxStyles, dropdownStyles, FundingModelOptions, FundingOptions, } from '../common/common'
import { ICreateInvoice } from './types';
import { DropdownParams } from '../common/types';
import { createInvoice, fetchNewInvoiceData } from '../../../store/admin/invoice/action';
import { Provider, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import store from '../../../store';
import ModalDialog from '../common/ModalDialog';
import moment from 'moment';
import BillingContractPicker from '../common/BillingContractPicker';

export const OpenAddInvoiceModal = () => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add Invoice"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-sm"
                >
                    <AddInvoiceDialog onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export const AddInvoiceDialog = ({
    onCancel,
}: Props) => {
    const dispatch = useDispatch()
    const [invoiceParams, setInvoiceParams] = useState<ICreateInvoice>({
        cycle: `${Moment().format('YYYY')}Q${Moment().quarter()}`,
        periods: [],
        contractId: 'all',
        funder: "CBS",
        invoiceDate: new Date(moment().format("YYYY-MM-DD")),
        dueDate: new Date(moment().add(10, 'days').format("YYYY-MM-DD")),
    })
    const [periods, setPeriods] = useState<DropdownParams[]>([])
    const [contracts, setContracts] = useState<DropdownParams[]>([])

    const loadData = async () => {
        const { periods, contracts }: any = await dispatch(fetchNewInvoiceData())
        setPeriods(periods as DropdownParams[])
        setContracts(contracts as DropdownParams[])
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleOnChangeInput = (key: string, item: any) => {
        switch (key) {
            case 'selectedContract':
                setInvoiceParams({ ...invoiceParams, contractId: item[0]?.key })
                break
            case 'cycle':
                setInvoiceParams({ ...invoiceParams, [key]: item as string })
                break;
            case 'period':
                const invoiceParamsObj = { ...invoiceParams }
                let cyclePeriods = invoiceParamsObj?.periods || []
                if (item?.selected) {
                    if (!cyclePeriods.find(p => p === item?.key)) {
                        cyclePeriods.push(item?.key)
                    }
                } else {
                    cyclePeriods = cyclePeriods.filter(p => p !== item?.key)
                }
                setInvoiceParams({ ...invoiceParamsObj, 'periods': cyclePeriods })
                break;
            default:
                setInvoiceParams({ ...invoiceParams, [key]: item as string })
                break;
        }
    }

    const handleOnSave = () => {
        dispatch(createInvoice(invoiceParams as ICreateInvoice))
    }
    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <section className='margin-top-md'>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg6">
                            <TextField
                                label="Cycle"
                                placeholder="Cycle"
                                onChange={(e, value) => handleOnChangeInput('cycle', value?.trim()?.toUpperCase())}
                                value={invoiceParams?.cycle!} />
                        </div>
                        <div className="ms-Grid-col ms-lg6">
                            <ComboBox
                                label="Choose Period"
                                placeholder="Choose Period"
                                multiSelect
                                autoComplete={"on"}
                                options={periods as IComboBoxOption[]}
                                onChange={(e, item) => handleOnChangeInput('period', item)}
                                styles={comboBoxStyles}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg6">
                            <DatePicker
                                label="Invoice Date"
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                value={invoiceParams?.invoiceDate!}
                                onSelectDate={(date: any) => handleOnChangeInput('invoiceDate', date)}
                                styles={dropdownStyles}
                            />
                        </div>
                        <div className="ms-Grid-col ms-lg6">
                            <DatePicker
                                label="Due Date"
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                                value={invoiceParams?.dueDate!}
                                onSelectDate={(date: any) => handleOnChangeInput('dueDate', date)}
                                styles={dropdownStyles}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg6">
                            <BillingContractPicker selectedItems={[{ key: 'all', name: 'All' }]} onChange={handleOnChangeInput} contracts={contracts} />
                        </div>
                        {invoiceParams.contractId === 'all' && (
                            <div className="ms-Grid-col ms-lg6">
                                <Dropdown
                                    label="Funder"
                                    placeholder="Select contract (optional)"
                                    selectedKey={invoiceParams?.funder || 'CBS'}
                                    onChange={(e, item) => handleOnChangeInput("funder", item?.key)}
                                    options={FundingOptions as IDropdownOption[]}
                                    styles={dropdownStyles}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item className="margin-top-lg">
                        {invoiceParams?.cycle?.length && invoiceParams.periods?.length! > 0 && <PrimaryButton text="Select" onClick={handleOnSave} className='btn-primary' />}
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    onCancel: () => void
}

type Props = OwnProps;
