

import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DetailsListWrapper from "../../common/DetailsListWapper"
import { RootState } from '../../../store'
import { SelectionMode } from "@fluentui/react/lib/index";
import { getRegionConfigColumns } from './SettingsHelper'
import { IRegionConfigState } from '../../../store/admin/settings/reducer'
import { DetailListStickyHeader } from '../common/DetailListStickyHeader'
import { IRegionConfig } from '@solarforschools/sfs-core'
import { useParams } from 'react-router-dom'
import { StringMap } from '../common/types'
import { getRegionConfig } from '../../../store/client'
import { openSettingsDialog } from './SettingsDialog'

const SettingsList = () => {

  const { isLoading, regionConfigList } = useSelector<RootState, IRegionConfigState>((state) => state.web.regionConfig)

  const params: StringMap = useParams();

  const loadData = async () => {
    if (params.slug) {
      const uniqueRegion = await getRegionConfig(params.slug)
      if (uniqueRegion) {
        handleEditRegionConfig(uniqueRegion)
      }

    }
  }
  useEffect(() => {
    loadData();
  }, [params.slug])


  const handleEditRegionConfig = async (regionConfig: IRegionConfig) => {
    window.history.replaceState(null, "", `/settings/config/${regionConfig.country}`)
    await openSettingsDialog(regionConfig)
    window.history.replaceState(null, "", `/settings/config`)
  }

  const [columns] = useState(getRegionConfigColumns({
    actions: [
      {
        icon: 'Edit',
        ariaLabel: 'Edit Region Config',
        onClick: handleEditRegionConfig,
      }
    ]
  }))

  if (regionConfigList.length === 0) return <></>


  return (
    <div className="data-list-container">
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsListWrapper
          items={regionConfigList}
          onRenderDetailsHeader={DetailListStickyHeader}
          columns={columns}
          checkboxVisibility={CheckboxVisibility.always}
          selectionMode={SelectionMode.none}
          compact={true}
          isLoading={isLoading}
        />

      </ScrollablePane>
    </div>
  )
}

export default SettingsList;


