import { FontIcon, IColumn, IconButton, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IBondHolder } from "@solarforschools/sfs-core/dist/funder/bond-holder/types";
import { IField } from "../../../dynamic-form/DynamicForm";
import { StringMap } from "../../common/types";
import { isSortable } from "../../common/utils";
import { requiredMessage } from "../../common/common";

export const getGeneralFields = (props: { bondHolder: IBondHolder; }): IField[] => {
    return [
        {
            label: "User Name",
            key: "userName",
            type: 'string',
            extras: { required: true, disabled: true },
            placeholder: "Enter User name",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Email Address",
            key: "details.email",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Email Address",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Alternate Email",
            key: "details.alternateEmail",
            type: 'string',
            placeholder: "Alternate Email",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "First Name",
            key: "name.first",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter First Name",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Middle Name",
            key: "name.middle",
            type: 'string',
            placeholder: "Enter Middle Name",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "Last Name",
            key: "name.last",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Last Name",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Full Name",
            key: "name.fullName",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Full Name",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Company",
            key: "company",
            type: 'string',
            placeholder: "Enter Company",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Holder (display on Certificate)",
            key: "officialHolder",
            type: 'string',
            placeholder: "Enter Official Holder",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Phone",
            key: "details.phone",
            type: 'string',
            placeholder: "Enter Phone Number",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Birthday",
            key: "details.birthdate",
            type: "date",
            extras: { required: true },
            placeholder: "Select Birth Date",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Gender",
            key: "details.gender",
            type: "dropdown",
            extras: { required: true },
            options: gender,
            className: 'ms-Grid-col ms-lg1'
        },
        {
            label: "Nationality",
            key: "details.Nationality",
            type: "string",
            placeholder: "Enter Nationality",
            className: 'ms-Grid-col ms-lg3'
        },
    ];
}

export const getAccountFields = (props: { bondHolder: IBondHolder; }): IField[] => {
    return [
        {
            label: "Bank Name",
            key: "account.bankName",
            type: 'string',
            placeholder: "Enter Bank Name",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Account Name",
            key: "account.name",
            type: 'string',
            placeholder: "Enter Account Name",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Sort Code",
            key: "account.sortCode",
            type: 'string',
            placeholder: "dddddd",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Account Number",
            key: "account.number",
            type: 'string',
            placeholder: "Enter Account Number",
            className: 'ms-Grid-col ms-lg3'
        }]
}
export const getAddressFields = (props: { bondHolder: IBondHolder; }): IField[] => {
    return [
        {
            label: "Recipient Name",
            key: "address.name",
            type: 'string',
            placeholder: "Enter Recipient Name",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Address Line1",
            key: "address.line1",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Address Line 1",
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "Address Line2",
            key: "address.line2",
            type: 'string',
            placeholder: "Enter Address Line 2",
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "Town",
            key: "address.town",
            type: 'string',
            placeholder: "Enter Town",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "County",
            key: "address.county",
            type: 'string',
            placeholder: "Enter County",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Zip Code",
            key: "address.zipCode",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Zip Code",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Country",
            key: "address.country",
            type: 'string',
            extras: { required: true },
            placeholder: "Enter Country",
            className: 'ms-Grid-col ms-lg3'
        }]
}

export const getpreferencesFields = (props: { bondHolder: IBondHolder; }): IField[] => {
    return [
        {
            label: "Payment Preferences",
            key: "paymentPreference",
            type: 'dropdown',
            options: paymentPreferences,
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Invest Further",
            key: "preferences.investFurther",
            type: 'string',
            placeholder: "Enter Invest Further",
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "Repayment Preferences",
            key: "address.name",
            type: 'dropdown',
            options: repaymentPreferences,
            className: 'ms-Grid-col ms-lg6'
        }]
}

export const getBondHolderColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
        title?: string,
        ariaLabel?: string
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name.fullName',
            name: 'Name',
            fieldName: 'name.fullName',
            minWidth: 150,
            maxWidth: 250,
            ...isSortable,
            onRender: (item: IBondHolder) => <TooltipHost content={item?.name?.fullName || ''} closeDelay={500}> {item?.name?.fullName}</TooltipHost>
        },
        {
            key: 'userName',
            name: 'User Name',
            fieldName: 'userName',
            minWidth: 250,
            maxWidth: 300,
            ...isSortable,
            onRender: (item: IBondHolder) => <TooltipHost content={item.userName || ''} closeDelay={500}> {item.userName}</TooltipHost>
        },
        {
            key: 'details.email',
            name: 'Email',
            fieldName: 'details.email',
            minWidth: 250,
            maxWidth: 300,
            ...isSortable,
            onRender: (item: IBondHolder) => <TooltipHost content={item.details.email || ''} closeDelay={500}> {item.details.email}</TooltipHost>
        },
        {
            key: 'address.county',
            name: 'County',
            fieldName: 'address.county',
            minWidth: 90,
            maxWidth: 100,
            ...isSortable,
            onRender: (item: IBondHolder) => <TooltipHost content={item.address.county || ''} closeDelay={500}> {item.address.county}</TooltipHost>
        },
        {
            key: 'address.country',
            name: 'Country',
            fieldName: 'address.country',
            minWidth: 150,
            maxWidth: 150,
            ...isSortable,
            onRender: (item: IBondHolder) => <TooltipHost content={item.address.country || ''} closeDelay={500}> {item.address.country}</TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (data) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action) => {
                            return <IconButton
                                iconProps={{ iconName: action.icon }}
                                onClick={() => action.onClick(data)}
                                title={action.title || ''}
                                ariaLabel={action.ariaLabel || ''} />
                        })}
                    </div>
                );
            },
        }
    ];
}


export const getPaymentPrefEmailExtraFields = (props: { data: any; }): IField[] => {
    return [
        {
            key: 'all',
            label: 'Send To All (By default send emails to those who did not recieve email in last 60 days. If send all is marked then it will resend emails to all BH who recieved email by today or did not recive in last 60 days)',
            type: 'checkbox',
            className: 'margin-top-lg',
            ariaLabel: "By default send emails to those who did not recieve email in last 60 days. If send all is marked then it will resend emails to all BH who recieved email by today or did not recive in last 60 days"
        }
    ]
}
export interface UserTabErrors {
    general: boolean
    schools: boolean
}

export const bondHolderStatus =
    [
        { key: "all", text: "All" },
        { key: "Open", text: "Open" },
        { key: "Closed", text: "Closed" },
        { key: "Deleted", text: "Deleted" },
    ]


export const initbondHolder: IBondHolder = {
    account: {
        bankName: "",
        name: "",
        number: "",
        sortCode: "",
    },
    address: {
        country: "",
        county: "",
        line1: "",
        line2: "",
        name: "",
        town: "",
        zipCode: ""
    },
    auditLog: [],
    details: {
        alternateEmail: "",
        birthdate: new Date(),
        email: "",
        gender: "male",
        nationality: "",
        phone: ""
    },
    login: {
        funderToken: "",
        funderTokenExpiryDate: "" as unknown as Date
    },
    name: {
        first: "",
        fullName: "",
        last: "",
        middle: ""
    },
    notes: "",
    paymentPreference: "cheque",
    preferences: {
        contactForInvestment: "",
        investFurther: "",
        repayment: "No hurry but happy to be re-paid early if it helps"
    },
    status: "Closed",
    userName: "",
    officialHolder: "",
    company: ""
}

export interface IBondHolderTabErrors {
    general: boolean;
    account: boolean;
    address: boolean;
    preferences: boolean;
    bonds: boolean;
    auditLog: false
}

export const validateBondholder = async (bondholder: IBondHolder): Promise<StringMap> => {
    let errors: StringMap = {};
    if (bondholder.paymentPreference == "account" || bondholder.account?.number || bondholder.account?.sortCode) {
        const regex = /^\d+$/
        const { sortCode, number: accountNumber, name, bankName } = bondholder.account || {}
        if (!sortCode || !regex.test(sortCode)) errors["account.sortCode"] = "Invalid sortcode, only digit allowed";
        if (!accountNumber || !regex.test(accountNumber)) errors["account.number"] = "Invalid account number, only digit allowed";
        if (!name || !name.trim()) errors["account.name"] = requiredMessage;
        if (!bankName || !bankName.trim()) errors["account.bankName"] = requiredMessage;
    }
    return errors;
}

export const getTabErrors = (errors: StringMap, bondHolder: IBondHolder): IBondHolderTabErrors => {
    const tabErrors = { ...bondholderTabs }
    Object.keys(errors).forEach(property => {
        if (getGeneralFields({ bondHolder }).find(field => field.key === property)) {
            tabErrors.general = true;
        }
        if (getAccountFields({ bondHolder }).find(field => field.key === property)) {
            tabErrors.account = true;
        }
        if (getAddressFields({ bondHolder }).find(field => field.key === property)) {
            tabErrors.address = true;
        }
    })
    return tabErrors;
}

export const bondholderTabs: IBondHolderTabErrors = {
    general: false,
    account: false,
    address: false,
    preferences: false,
    bonds: false,
    auditLog: false
}

export const repaymentPreferences = [
    { key: 'No hurry but happy to be re-paid early if it helps', text: 'No hurry but happy to be re-paid early if it helps' },
    { key: "ASAP", text: "ASAP" },
    { key: 'Within 12 month', text: 'Within 12 month' },
    { key: 'Would really prefer NOT to be re-paid early', text: "Would really prefer NOT to be re-paid early" }
]

export const gender = [
    { key: 'male', text: 'Male' },
    { key: 'female', text: 'Female' },
]

export const paymentPreferences = [
    { key: 'cheque', text: 'Cheque' },
    { key: 'account', text: 'Account' },
    { key: 'ethex', text: 'Ethex' }
]
