import React, { useEffect, useState } from "react";
import {
    IBuildPieChart,
    IBuildResult,
    IBuildResultObject,
    IChartViewProps,
    IResArrOfObj,
} from "./types";
import {
    CheckboxVisibility,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Text,
    Toggle,
} from "@fluentui/react";
import { FUND_FEE, TOTAL_INV, getCBSColumns } from "./Helper";
import CBSPieChart from "./CBSPieChart";
import DetailsListWapper from "../../common/DetailsListWapper";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import CBSLineChart from "./CBSLineChart";

const ChartView = (props: IChartViewProps) => {
    const { data, isToggle, handleChartToggle } = props;
    const [buildResult, setBuildResult] = useState<IBuildResult>({});
    const [buildPieChartData, setBuildPieChartData] = useState<IBuildPieChart[]>(
        []
    );

    function buildSOFSCBSData(data: IResArrOfObj[]) {

        if (data.length === 0 || !data) return;
        let tempBuildResult: IBuildResult = {};
        let tempBuildPieChartData: IBuildPieChart[] = [];

        for (const object of data) {
            const {
                paymentBy,
                actualPerKWP,
                isActuals,
                name,
                uniqueId,
                paymentById,
            } = object;

            if (paymentBy) {
                let res = {
                    name,
                    paymentBy,
                    actualPerKWP: typeof actualPerKWP === "number" ? actualPerKWP : 0,
                    isActuals: isActuals === undefined ? false : isActuals,
                    uniqueId: uniqueId as string,
                    paymentById,
                };
                if (tempBuildResult[paymentBy] === undefined) {
                    tempBuildResult[paymentBy] = [res];
                } else {
                    tempBuildResult[paymentBy] = [...tempBuildResult[paymentBy], res];
                }
            }
        }

        //! As Fundraising Fee was SOFS by default, we dont need below code
        // const getFundraisingFees = data.find((object) => object.name === FUND_FEE);
        // if (getFundraisingFees) {
        //     const { actualPerKWP, name } = getFundraisingFees;
        //     let res: IBuildResultObject = {
        //         name,
        //         paymentBy: "CBS",
        //         isActuals: false,
        //         actualPerKWP: actualPerKWP,
        //     };

        // why isActuals is included -> to unbold the content
        //     if (tempBuildResult["CBS"] === undefined) {
        //         tempBuildResult["CBS"] = [res];
        //     } else {
        //         tempBuildResult["CBS"] = [...tempBuildResult["CBS"], res];
        //     }
        // }


        const getTotalInvestment = data.find((object) => object.name === TOTAL_INV);

        if (getTotalInvestment) {
            tempBuildPieChartData.push({
                name: getTotalInvestment.name,
                actualTotal: getTotalInvestment.actualPerKWP,
                budgetTotal: getTotalInvestment.total,
                difference:
                    (typeof getTotalInvestment.total === "number"
                        ? getTotalInvestment.total
                        : 0) -
                    (typeof getTotalInvestment.actualPerKWP === "number"
                        ? getTotalInvestment.actualPerKWP
                        : 0),
            });
        }

        // SOFS needs only headValue
        let headerIds: any = {},
            modifiedSOFS = [];

        for (const object of tempBuildResult["SOFS"]) {
            const { paymentById } = object;
            if (paymentById) {
                if (headerIds[paymentById] === undefined) {
                    headerIds[paymentById] = 1;
                }
            }
        }

        for (const keys in headerIds) {
            const findElm = tempBuildResult["SOFS"].find(
                (elm) => elm.paymentById === keys
            );
            if (findElm !== undefined) {
                modifiedSOFS.push(findElm);
            }
        }

        tempBuildResult["SOFS"] = modifiedSOFS;

        const differenceObj = {
            name: "Difference from budget",
            isActuals: true,
            actualPerKWP: tempBuildPieChartData[0].difference,
            paymentBy: "SOFS",
        }; // Note: acutalPerKWP have difference value
        tempBuildResult["SOFS"].push(differenceObj);

        const findElm = data.find((obj) => obj.name === FUND_FEE);

        let customObj1: any = {};
        if (findElm !== undefined) {
            customObj1 = {
                name: findElm.name,
                isActuals: true,
                actualPerKWP: findElm.actualPerKWP,
                uniqueId: findElm.uniqueId,
            };
            tempBuildResult["SOFS"].push(customObj1);
        }

        let totalInvAmt = tempBuildResult["SOFS"].reduce((acc, curr) => {
            const { actualPerKWP } = curr;
            acc = acc + Number(typeof actualPerKWP === "number" ? actualPerKWP : 0);
            return acc;
        }, 0);

        const customObj2 = {
            name: "Total invoice amount",
            isActuals: true,
            actualPerKWP: totalInvAmt,
            paymentBy: "SOFS",
        };
        tempBuildResult["SOFS"].push(customObj2);

        setBuildResult(tempBuildResult);
        setBuildPieChartData(tempBuildPieChartData);
    }

    useEffect(() => {
        buildSOFSCBSData(data);
    }, [data]);

    if (Object.keys(buildResult).length === 0 || buildPieChartData.length === 0) {
        return <></>;
    }

    return (
        <>
            <div className="flex-row-center" style={{ justifyContent: "flex-end" }}>
                <Toggle
                    label={<div>Visual View</div>}
                    inlineLabel
                    checked={isToggle}
                    onChange={handleChartToggle}
                />
            </div>

            <div className="flex-column-center">
                <div
                    style={{
                        height: "fit-content",
                        marginTop: "0.25rem",
                        background: "white",
                        padding: "1rem",
                    }}
                >
                    <div className="flex-row-center" style={{ gap: "2rem" }}>
                        <CBSLineChart data={buildResult["CBS"]} />
                        <CBSPieChart chartData={buildPieChartData} />
                    </div>
                    <div style={{ textAlign: "center", marginTop: "1rem" }}>
                        <Text
                            variant="mediumPlus"
                            style={{ fontWeight: "bold", color: "#243f61" }}
                        >
                            CBS Procurement
                        </Text>
                    </div>
                </div>
            </div>

            <div style={{ textAlign: "left" }}>
                <Text
                    variant="mediumPlus"
                    style={{
                        fontWeight: "bold",
                        color: "#243f61",
                        marginLeft: "0.75rem",
                    }}
                >
                    SOFS Invoicing to CBS
                </Text>
            </div>

            <SOFSTable data={buildResult["SOFS"]} />
        </>
    );
};

export default ChartView;

interface ICBSTableProps {
    data: IBuildResultObject[];
}
const SOFSTable = (props: ICBSTableProps) => {
    const { data } = props;
    const [columns] = useState(getCBSColumns());

    if (!data || data.length === 0) return <></>;

    return (
        <>
            <div className="data-list-container" style={{ marginTop: "0.25rem" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsListWapper
                        columns={columns}
                        onRenderDetailsHeader={DetailListStickyHeader}
                        selectionMode={SelectionMode.none}
                        compact={true}
                        items={data}
                        checkboxVisibility={CheckboxVisibility.hidden}
                    />
                </ScrollablePane>
            </div>
        </>
    );
};
