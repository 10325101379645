import { ComboBox, DatePicker, Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import SystemPicker from "../../common/SystemPicker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IGSSReport } from "../../../../store/admin/reporting/gssReport/reducer";
import { fetchGSSReportData, setGSSReportFilter } from "../../../../store/admin/reporting/gssReport/action";
import { FundingOptions, comboBoxStyles, dropdownStyles, months } from "../../common/common";
import moment from "moment";
import { IInvoiceAccountReportFilter } from "./types";
import { IInvoiceAccountReport } from "../../../../store/admin/reporting/invoiceAccountReport/reducer";
import { fetchInvoiceAcountReportData, setInvoiceAccountReportFilter } from "../../../../store/admin/reporting/invoiceAccountReport/action";
import InvoiceCyclePicker from "../../common/InvoiceCyclePicker";



const InvoiceAccountReportFilter = () => {
  const dispatch = useDispatch();

  const { invoiceAccountReportFilter }: IInvoiceAccountReport = useSelector<RootState, IInvoiceAccountReport>((state) => state.web.invoiceAccountReport);
  const handleOnChange = (
    key: string,
    value?: any,
  ) => {
    let params = { ...invoiceAccountReportFilter };

    switch (key) {
      case 'selectedCycle':
        params = { ...params, cycle: value[0]?.key }
        break
      default:
        params = { ...params, [key]: value }
        break;
    }
    dispatch(setInvoiceAccountReportFilter(params));
    dispatch(fetchInvoiceAcountReportData());
  };
  const {
    contractNumber,
    funder
  } = invoiceAccountReportFilter;
  return (
    <div
      className="ms-Grid ms-Grid-row"
      dir="ltr"
      style={{ width: "-webkit-fill-available", minWidth: "700px", maxWidth: "1300px" }}
    >

<TextField
          label="Contract Num"
          placeholder='Contract Number'
          type='text'
          onChange={(e, value) => handleOnChange('contractNumber', value)}
          value={contractNumber!}
          className="ms-Grid-col ms-lg2"
        />
        <div className="ms-Grid-col ms-lg4 margin-top-xsm">
          <InvoiceCyclePicker selectedItems={[{ key: 'all', name: 'All' }]} onChange={handleOnChange} />
        </div>
        <Dropdown
          label="Funder"
          placeholder="Choose Funder"
          selectedKey={funder || 'all'}
          onChange={(e, item) => handleOnChange('funder', item?.key?.toString() || 'all')}
          options={FundingOptions as IDropdownOption[]}
          styles={comboBoxStyles}
          className="ms-Grid-col ms-lg3"
        />
    </div>
  );
};

export default InvoiceAccountReportFilter;
