// Action Definition

//Module Starts
export interface IModule {
  id: string;
  name: string;
  url: string;
  isTakeAction: boolean;
  sequence: number;
  isPremium: boolean;
  isActive?: boolean;
  description?: string;
}

export interface SetAction {
  type: "SET_MODULES";
  modules: IModule[];
}

export interface SetGlossary {
  type: "SET_GLOSSARY";
  glossaryItems: IGlossaryItem[];
}

export interface UpdateModule {
  type: "UPDATE_MODULE";
  module: IModule;
}

export interface updateUnitAfterEdit {
  type: "UPDATE_UNIT_AFTER_EDIT";
  unit: IUnit;
}
//Module Ends

//Block Starts
export interface IBlock {
  blockType?: string;
  id?: string;
  name?: string;
  file?: File;
  animationFile?: File;
  isQuiz?: boolean;
  isFinalQuiz?: boolean;
  warningMessage?: string;
  prerequisites?: string[];
  questionLength?: number;
  primaryColor?: string;
  secondaryColor?: string;
  accentColor?: string;
  accentColorTwo?: string;
  isTakeAction?: boolean;
  sequence?: number;
  isPremium?: boolean;
  description ?: string;
  isActive ?: boolean;
  url?:string
  animationURL?:string
  language?:string;
}
export interface SetShowPopup {
  type: "SET_SHOWPOPUP";
  isShowPopup: boolean;
}

export interface SetEditPopup {
  type: "SET_EDITPOPUP";
  isEditPopup: boolean;
}
export interface IEditContent {
  id: string;
}
export interface SetEditContent {
  type: "SET_EDIT_CONTENT",
  editContent: IEditContent;
}

export interface SetBlock {
  type: "SET_BLOCK";
  block: IBlock | null;
}
//Block Ends

//Unit Starts
export interface IUnit {
  id: string;
  name: string;
  url: string;
  moduleID: string;
  isFinalQuiz: boolean;
  warningMessage: string;
  prerequisites: string[];
  isPremium: boolean;
}
export interface UpdateUnit {
  type: "UPDATE_UNIT";
  unit: IUnit;
}
export interface SetUnit {
  type: "SET_UNIT";
  units: IUnit[];
}
//Unit Ends

//Screen Starts
export enum Screens {
  MODULE = "MODULE",
  UNIT = "UNIT",
  ADD_OR_EDIT_TEMPLATE = "ADD_OR_EDIT_TEMPLATE",
  TEMPLATE_SELECTION = "TEMPLATE_SELECTION",
  CONTENT_TEMPLATE_SELECTION = "CONTENT_TEMPLATE_SELECTION",
  ADDING_CONTENT = "ADDING_CONTENT",
  QUESTION_TEMPLATE_SELECTION = "QUESTION_TEMPLATE_SELECTION",
  ADDING_QUESTION = "ADDING_QUESTION",
  WHATS_NEXT = "WHATS_NEXT",
  REARRANGE_SCREENS = "REARRANGE_SCREENS",
}
export interface IScreen {
  currentScreen: Screens;
  title: string;
  moduleID?: string;
  unitID?: string;
  isQuiz?: boolean;
  template?: string;
  cTemplate?: string;
  qTemplate?: string;
}
export interface SetScreen {
  type: "SET_SCREEN";
  screen: IScreen;
}
//Screen Ends

//Content Starts
export enum ContentItems {
  IMAGE = "IMAGE",
  TITLE = "TITLE",
  PARAGRAPH = "PARAGRAPH",
  SUBHEADING = "SUBHEADING",
  SUBSUBHEADING = "SUBSUBHEADING",
  VIDEO_SOURCE = "VIDEO_SOURCE",
  VIDEO = "VIDEO",
}

export interface IContentNestedItems {
  type: ContentItems;
  sequence?: number;
  text?: string;
}

export interface IContentItems {
  type: ContentItems;
  url?: string;
  sequence?: number;
  text?: string;
  file?: File | null;
  fileName?: string | null;
  imageProperties?: IContentNestedItems[];
}

export enum TemplateItems {
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  C6 = "C6",
  C7 = "C7",
  C8 = "C8",
  C9 = "C9",
  C10 = "C10",
  C11 = "C11",
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
  Q5 = "Q5",
  Q6 = "Q6",
  Q7 = "Q7",
  Q8 = "Q8",
  Q9 = "Q9",
}
export interface IContentTemplate {
  module?: string;
  unit?: string;
  template?: TemplateItems;
  items?: IContentItems[];
  order?: number;
  isEditing: boolean;
  contentId?: string;
  glossary?: string[];
}
export interface SetContentTemplate {
  type: "SET_CONTENT_TEMPLATE";
  contentTemplate: IContentTemplate | null;
}

export interface SubmitContentTemplate {
  type: "SUBMIT_CONTENT_TEMPLATE";
  contentTemplate: IContentTemplate;
}

export interface IItems {
  _id: string;
  sequence: number;
  type: ContentItems & QuestionItems;
  url: string;
  text: string;
  texts: string[];
}
export interface IDBContentItems {
  _id: string;
  template: TemplateItems;
  created: Date;
  items: IItems[];
  answer: IAnswer;
  glossary: string[];
}
export interface IDBUnit {
  created: string;
  _id: string;
  name: string;
  url: string;
  isQuiz: boolean;
  questionLength: number;
  contents: IDBContentItems[];
}
export interface IDBModule {
  created: string;
  _id: string;
  name: string;
  url: string;
  animationURL: string;
  units: IDBUnit[];
}

export interface SetAllContent {
  type: "SET_ALL_CONTENT";
  params: IDBModule[];
}
//Content Ends

//Question Start
export enum QuestionItems {
  QUESTION = "QUESTION",
  OPTIONS = "OPTIONS",
  IMAGE = "IMAGE",
  CATEGORY = "CATEGORY",
}

export interface IQuestionItems {
  type: QuestionItems;
  url?: string;
  sequence?: number;
  order?: number;
  text?: string;
  texts?: string[];
  file?: File | null;
  fileName?: string | null;
}

export interface IAnswer {
  option?: number;
  options?: number[];
  text?: string;
  texts?: string[];
  tips?: string;
}
export interface IQuestionTemplate {
  module?: string;
  unit?: string;
  template?: TemplateItems;
  items?: IQuestionItems[];
  answer?: IAnswer;
  order?: number;
  isEditing: boolean;
  contentId?: string;
  glossary?: string[];
}

export interface SetQuestionTemplate {
  type: "SET_QUESTION_TEMPLATE";
  questionTemplate: IQuestionTemplate | null;
}

export interface SubmitQuestionTemplate {
  type: "SUBMIT_QUESTION_TEMPLATE";
  questionTemplate: IQuestionTemplate;
}

//Question Ends

//Delete Starts

export enum DeleteType {
  UNIT = "UNIT",
  MODULE = "MODULE",
  CONTENT = "CONTENT",
}
export interface IDeleteParams {
  type: DeleteType;
  moduleId?: string;
  unitId?: string;
  contentId?: string;
}
//Delete Ends

// Union Action Types
export type Action =
  | SetAction
  | SetShowPopup
  | SetBlock
  | UpdateModule
  | SetScreen
  | UpdateUnit
  | SetUnit
  | SetContentTemplate
  | SubmitContentTemplate
  | SetQuestionTemplate
  | SubmitQuestionTemplate
  | SetAllContent
  | SetEditPopup
  | SetEditContent
  | updateUnitAfterEdit 
  | SetGlossary;

export interface IGlossary {
  glossaryItems: IGlossaryItem[];
}

export interface IGlossaryItem {
  _id?: string;
  word: string;
  description: string;
}
