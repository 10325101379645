import moment from 'moment'
import Moment from 'moment'
import { StringMap } from '../common/types'


export const PeriodReadingYears = () => {
    const years: StringMap[] = []
    let current = moment().year()
    while (current > 2018) {
        years.push({ key: current, text: current })
        current--
    }
    return years
}