import { Text } from '@fluentui/react';
import moment from 'moment';

function AuditLog(props: IProps) {

    return (
        <>
            <div className="ms-Grid-row" style={{ backgroundColor: 'gray' }}>
                <Text className='inlineflex ms-Grid-col ms-lg6 margin-bottom-sm margin-top-sm text-center' style={{ fontWeight: 'bold' }}>Action</Text>
                <Text className='inlineflex ms-Grid-col ms-lg6 margin-bottom-sm margin-top-sm text-center text-bold' style={{ fontWeight: 'bold' }}>Time</Text>
            </div>
            {props.auditLog.map((log, i) => {
                return (
                    <div key={i} className="ms-Grid-row" style={{ backgroundColor: i % 2 == 0 ? '#dadee4' : '' }}>
                        <Text className='inlineflex ms-Grid-col ms-lg6 margin-bottom-sm margin-top-sm text-center'>{log.action}</Text>
                        <Text className='inlineflex ms-Grid-col ms-lg6 margin-bottom-sm margin-top-sm text-center'>{moment(log.time).format('MMM DD, YYYY')}</Text>
                    </div>
                )
            })}
        </>
    )
}

interface IProps {
    auditLog: any[];
}

export default AuditLog