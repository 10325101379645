import {
  IProject,
  IWorkflow,
} from "@solarforschools/sfs-core/dist/project/types";
import { IProjectsFilter } from "../../../components/admin/project/types";
import { ProjectAction } from "./ActionTypes";

export interface IProjectState {
  isLoading: boolean;
  projects: IProject[];
  projectManagers: any[];
  projectMembers: any[];
  count: number;
  workflows: IWorkflow[];
  designAnalysis: any[];
  projectFilter: IProjectsFilter;
}

const initialState: IProjectState = {
  isLoading: false,
  projects: [],
  projectManagers: [],
  projectMembers: [],
  workflows: [],
  designAnalysis: [],
  count: 0,
  projectFilter: {
    page: 1,
    pageSize: 50,
    sortField: "_id",
    sortOrder: "desc",
    stageStatus: "all",
    status: "all",
  },
};

const project = (
  state: IProjectState = initialState,
  action: ProjectAction
): IProjectState => {
  switch (action.type) {
    case "PROJECTS/SET_PROJECTS_FILTER": {
      return { ...state, projectFilter: action.data };
    }
    case "PROJECTS/SET_PROJECTS_LIST":
      let projects = action.replace
        ? action.projects
        : [...state.projects, ...action.projects];
      projects = projects.filter(Boolean);
      return { ...state, projects, count: action.count };
    case "PROJECTS/SET_PROJECT_MANAGERS_LIST":
      return {
        ...state,
        projectManagers: action.projectManagers,
        projectMembers: action.projectMembers,
      };
    case "PROJECTS/DELETE_PROJECT": {
      const projects: IProject[] = state.projects.filter(
        (s) => s.slug !== action.projectSlug
      );
      return {
        ...state,
        projects,
        count: state.count - 1,
      };
    }
    case "PROJECTS/WORKFLOWS":
      return {
        ...state,
        workflows: action.workflows,
      };
    case "PROJECTS/ANALYSIS_DESIGNS_DATA":
      return {
        ...state,
        designAnalysis: action.data,
      };
    case "PROJECTS/UPDATE_PROJECT": {
      const projects = [...state.projects];
      const index = projects.findIndex(
        (s) => s?.slug && s.slug === action.project.slug
      );
      if (index > -1) {
        projects[index] = action.project;
      } else {
        projects.unshift(action.project);
      }
      const count = index > -1 ? state.count : state.count + 1;
      return {
        ...state,
        projects,
        count,
      };
    }
    default:
      return state;
  }
};

export default project;
