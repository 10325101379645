
import { generateQueryString } from '../../../utils/util';
import axios from 'axios';
import { host } from '../../config';
import { IBondHolderFilter } from '../../../components/admin/funder/bondholder/types';
import { ParsedUrlQueryInput } from 'querystring';
import { IBondHolder } from '@solarforschools/sfs-core/dist/funder/bond-holder/types';

let GetBondHolderAPiAbortController: AbortController 

export const getBondHoldersApi = (params: IBondHolderFilter): Promise<{ bondHolders: IBondHolder[]; totalCount: number }> => {
  const queryStr = generateQueryString({ ...params });
  if (GetBondHolderAPiAbortController) GetBondHolderAPiAbortController.abort()
  GetBondHolderAPiAbortController = new AbortController()
  return axios.get(`${host}/funder/bond-holders?${queryStr}`, { signal: GetBondHolderAPiAbortController.signal }).then((res) => res.data);
};

export const exportBondHoldersApi = (query: any): Promise<any> => {
  return axios.get(`${host}/funder/bond-holders/download?${generateQueryString(query as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const createBondHolderAPi = (data: IBondHolder): Promise<any> => {
  return axios
    .post(`${host}/funder/bond-holders`, data)
    .then((res) => res.data);
};

export const updateBondHolderApi = (data: IBondHolder): Promise<any> => {
  return axios
    .put(`${host}/funder/bond-holders/${data._id}`, data)
    .then((res) => res.data);
};

export const deleteBondHolderApi = (data: IBondHolder): Promise<any> => {
  return axios
    .delete(`${host}/funder/bond-holders/${data._id}`)
    .then((res) => res.data);
};

export const getBondHolderEmailPreview = (query: ParsedUrlQueryInput): Promise<any> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/funder/bond-holders/email-preview?${queryStr}`).then(res => res.data)
}

export const sendBondholderPaymentPreferenceEmailApi = (query: ParsedUrlQueryInput): Promise<any[]> => {
  const queryStr = generateQueryString({ userName: query.userName } as ParsedUrlQueryInput)
  return axios.post(`${host}/funder/bond-holders/send-mail?${queryStr}`).then(res => res.data)
}

// not update their payment pref in last 60 days or updated today. and also have their bond open
export const getBondHoldersWitOutDateProfileApi = (query: ParsedUrlQueryInput): Promise<IBondHolder[]> => {
  const queryStr = generateQueryString({ ...query });
  return axios.get(`${host}/funder/bond-holders/outdated?${queryStr}`).then((res) => res.data);
};