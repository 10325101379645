import { ThunkAction } from "redux-thunk";
import { RootState } from "../..";
import { AnyAction } from "redux";
import { isLoading } from "../action";
import axios from "axios";
import { host, websiteHost } from "../../config";
import { toast } from "react-toastify";
import { IParams } from "@solarforschools/sfs-core/dist/translations/types";
import {
  IARParams,
  IDrafts,
  IFilterOptions,
  ISetTrHistoryPayload,
  ITrData,
} from "../../../components/admin/translations/utils/types";

export const setTranslations = (data: ITrData) => {
  return { type: "SET_TRANSLATIONS", payload: data };
};

export const setFilterOptions = (data: IFilterOptions) => {
  return { type: "SET_FILTER", payload: data };
};

export const setTranslationDrafts = (data: IDrafts[]) => {
  return { type: "SET_TRANSLATION_DRAFTS", payload: data };
};
export const setTranslationHistory = (data: ISetTrHistoryPayload) => {
  return { type: "SET_TRANSLATION_HISTORY", payload: data };
};
export const setPendingApprovalDrafts = (data: IDrafts[]) => {
  return { type: "SET_PENDING_APPROVAL_DRAFTS", payload: data };
};

export const setPendingApprovalCount = (data: IDrafts[]) => {
  return { type: "SET_PENDING_APPROVAL_DRAFTS_COUNT", payload: data };
};

export const setIsLoading = () => {
  return { type: "IS_LOADING" };
};

export const resetPendingApprovalDrafts = () => {
  return { type: "RESET_PENDING_APPROVAL_DRAFTS" };
};

export const resetAll = () => {
  return { type: "RESET_ALL" };
};

export const deleteTranslation = (id: string) => {
  return { type: "DELETE_TRANSLATION", payload: id };
};

export const setPanelOpen = (isOpen: boolean) => {
  return { type: "SET_PANEL_OPEN", payload: isOpen };
};

export const fetchTranslations = ({
  page = 1,
  key = "",
  value = "",
  lang1 = "en", // default lang
  lang2 = "",
  lang3 = "",
  hasToReset = false,
}): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function fetchTranslationsThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/translations?page=${page}&key=${key}&value=${value}&lang1=${lang1}&lang2=${lang2}&lang3=${lang3}`;
      const res = await axios.get(url);
      dispatch(isLoading(false));

      if (res.status === 200) {
        const buildParams = { ...res.data, hasToReset };
        dispatch(setTranslations(buildParams));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const fetchTranslationHistory = (
  docId: string,
  lang: string,
  page: number = 1
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function fetchTranslationHistoryThunk(dispatch) {
    try {
      // dispatch(isLoading(true));
      const url = `${host}/translations/history/${docId}?lang=${lang}&page=${page}`;
      const res = await axios.get(url);
      // dispatch(isLoading(false));
      if (res.status === 200) {
        dispatch(setTranslationHistory(res.data));
      } else {
        console.log("Error ", res);
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Something went wrong");
      // dispatch(isLoading(false));
    }
  };
};

// create
export const handleDraftCreation = (
  params: Partial<IParams>,
  isDeleted: boolean = false,
  hasPermission: boolean = false
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function createDraftThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/translations/draft`;
      params["isDeleted"] = isDeleted;
      const res = await axios.post(url, params);
      dispatch(isLoading(false));
      if (res.status === 200) {
        if (hasPermission) dispatch(fetchPendingApprovalCount());
        toast.success("Translation sent for approval");
      } else {
        console.log("Error ", res);
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const fetchPendingApprovalCount = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchPendingApprovalCountThunk(dispatch) {
    try {
      const url = `${host}/translations/count`;
      const res = await axios.get(url);

      if (res.status === 200) {
        dispatch(setPendingApprovalCount(res.data.data));
      } else {
        console.log("Error ", res);
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Something went wrong");
    }
  };
};

export const fetchPendingApprovalDrafts = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchPendingApprovalDraftsThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/translations/pending/drafts`;
      const res = await axios.get(url);

      dispatch(isLoading(false));
      if (res.status === 200) {
        dispatch(setPendingApprovalDrafts(res.data.data));
      } else {
        console.log("Error ", res);
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      dispatch(isLoading(false));
      toast.error("Something went wrong");
    }
  };
};

// pending for approval
//1. used to create history - once draft approved
export const handleDraftApproval = (
  params: IARParams
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function createDraftThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/translations/approve`;

      const res = await axios.post(url, params);

      if (res.status === 200) {
        const syncRes: any = await handleSyncTranslation(
          params.language,
          "./translations",
          `./translations/${params.language}.json`
        );
        dispatch(fetchPendingApprovalCount());
        dispatch(setPendingApprovalDrafts(res.data.data));

        if (syncRes.status === 200) {
          dispatch(isLoading(false));
          if (syncRes.data.success === true) {
            toast.success(`Translation synced in the website`);
          } else {
            toast.error("Translation approved but failed to sync");
          }
        } else {
          dispatch(isLoading(false));
          toast.error("Translation approved but failed to sync");
        }
      } else {
        console.log("Error ", res);
        dispatch(isLoading(false));
        toast.error("Translation approval failed");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Translation approval failed");
      dispatch(isLoading(false));
    }
  };
};

//2. draft removal
export const handleDraftRejection = (
  params: IARParams,
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const url = `${host}/translations/reject`;
      const response = await axios.put(url, params);
      dispatch(isLoading(false));
      if (response.status === 200) {
        if (
          Array.isArray(response.data.data) &&
          response.data.data.length === 0
        ) {
          const searchParams = new URLSearchParams(window.location.search);
          const pendingDraftsQuery = searchParams.get("pending-drafts");
          if (pendingDraftsQuery) {
            window.history.replaceState(null, "", `/translations`);
            dispatch(fetchTranslations({ page: 1 }));

            dispatch(fetchPendingApprovalCount());
          }
        }
        dispatch(setPendingApprovalDrafts(response.data.data));

        toast.success("Translation rejected");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log("Error ", error);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const deleteATranslation = (
  docId: string
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const url = `${host}/translations/${docId}`;
      const response = await axios.delete(url);

      if (response.status === 200) {
        dispatch(deleteTranslation(docId));
        dispatch(fetchPendingApprovalCount());

        const res: any = await handleSyncAlTranslation();

        if (res.status === 200) {
          dispatch(isLoading(false));
          toast.success("Files synced successfully");
        } else {
          dispatch(isLoading(false));
          toast.error("Translations deleted but failed to sync");
        }
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log("Error ", error);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

//! sync individual language (after approval)
const handleSyncTranslation = async (
  fileName: string,
  directory: string,
  filePath: string
) => {
  try {
    const urlName = `${websiteHost}/api/sync/translation`;
    const res = await axios.post(urlName, { fileName, directory, filePath });
    return res;
  } catch (error) {
    console.log("Error ", error);
    toast.error("Translation approved but failed to sync");
  }
};

//! sync all languages files
const handleSyncAlTranslation = async () => {
  try {
    const urlName = `${websiteHost}/api/sync/all/translations`;
    const res = await axios.post(urlName);
    return res;
  } catch (error) {
    console.log("Error ", error);
    toast.error("Translation failed to sync");
  }
};
