import React, { useEffect, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import "./question.css";
import {
  IAnswer,
  IQuestionTemplate,
  QuestionItems,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { changeQuestionTemplate } from "../../../../store/content/action";
import { getName } from "../util";

const Q3 = ({
  content,
  handleSubmit,
  handleTextBoxChange,
  handleTextAreaChange,
  handleOptions,
  changeQuestionTemplate,
}: Props) => {
  let moduleID: string, unitID: string;
  if (content.screen?.moduleID && content.screen?.unitID) {
    moduleID = content.screen.moduleID;
    unitID = content.screen.unitID;
  }

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const thisTemplate = TemplateItems.Q3;

  const handleAnswers = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const span = e.target as HTMLSpanElement;
    const chosenOption = Number(span.textContent);
    const answerOptions = content.questionTemplate?.answer?.options;
    let options: number[] =
      answerOptions && answerOptions?.length > 0 ? answerOptions : [];

    if (span.textContent && typeof Number(span.textContent) === "number") {
      if (
        content.questionTemplate?.answer?.options?.find(
          (val) => val === chosenOption
        )
      ) {
        options = content.questionTemplate?.answer?.options.filter(
          (val) => val !== chosenOption
        );
      } else {
        options.push(chosenOption);
      }

      let answer: IAnswer = {
        ...content.questionTemplate?.answer,
        options,
      };
      dispatchEvent(answer);
    }
  };

  const handleTips = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      tips: e.target.value,
    };
    dispatchEvent(answer);
  };

  const handleAnswer = (index: number, text: string) => {
    let texts = content.questionTemplate?.answer?.texts;
    if (texts === undefined) {
      texts = [];
    }
    texts[index] = text;
    let answer: IAnswer = {
      texts,
    };
    console.log(answer);
    dispatchEvent(answer);
  };

  const dispatchEvent = (answer: IAnswer) => {
    let questionTemplate: IQuestionTemplate = {
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer,
    };
    changeQuestionTemplate(questionTemplate);
  };
  const [q3Option, setQ3Option] = useState<any>({
    option1: "",
    option2: "",
    option3: "",
  });

  const q3Options = ["option1", "option2", "option3"];

  const choices = content.questionTemplate?.items
    ?.filter((item) => {
      if (item && item.sequence) {
        return item.sequence > 1 && item.sequence < 5;
      }
      return false;
    })
    .sort((a, b) =>
      a.sequence && b.sequence && a.sequence > b.sequence ? 1 : -1
    );
  const { submitButtonText } = getName(content, -1);
  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, paddingTop: 0, height: "65%" }}
        >
          <Textarea
            placeholder="Question type - Fill in the blanks"
            maxChar={150}
            width="100%"
            containerStyle={{ height: "55%", margin: "1rem 0" }}
            value={
              content.questionTemplate?.items?.find(
                (temp) => temp.sequence === 1
              )?.text
            }
            onChange={(e) =>
              handleTextAreaChange(e, 1, thisTemplate, QuestionItems.QUESTION)
            }
          />
        </div>
      </aside>
      <aside style={{ justifyContent: "flex-start" }}>
        <p>
          Type the sentence in the text box to the left. <br />
          <br />
          Type the answer options for each dropdown clicking + to add. Once
          added click on the correct answer below it.
        </p>
        <div className="q3-answers">
          <div className="choose-options">
            {q3Options.map((value, index) => {
              return (
                <div className="choose-option">
                  <Label
                    name={`${index + 1}.`}
                    fontSize="1.5rem"
                    style={{ marginTop: 0 }}
                  />
                  <Textbox
                    placeholder={`Option ${index + 1}`}
                    width="8rem"
                    style={{ fontSize: "1rem" }}
                    containerStyle={{ margin: "0 0.5rem", padding: 0 }}
                    value={q3Option[value]}
                    onChange={(e) =>
                      setQ3Option({ ...q3Option, [value]: e.target.value })
                    }
                  />
                  <Button
                    name="+"
                    onClick={() => {
                      const sequence = index + 2;
                      let options = content.questionTemplate?.items?.find(
                        (temp) => temp.sequence === sequence
                      )?.texts;
                      if (options === undefined) {
                        options = [];
                      }
                      if (options) {
                        handleOptions(
                          sequence,
                          [...options, q3Option[value]],
                          thisTemplate
                        );
                      }
                      setQ3Option({ ...q3Option, [value]: "" });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="correct-answers">
            {choices?.map((choice, index) => {
              return (
                <div className="question-options">
                  {choice.texts?.map((text) => {
                    return (
                      <div
                        className={
                          content.questionTemplate?.answer?.texts !==
                            undefined &&
                          content.questionTemplate?.answer?.texts[index] ===
                            text
                            ? "answer-selected"
                            : ""
                        }
                        onClick={() => handleAnswer(index, text)}
                      >
                        {text}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div
            className="answer-comment-box"
            style={{ height: 300, margin: "0px 20px" }}
          >
            <Label name="Correct!" fontSize="x-large" />
            <Textarea
              placeholder="Enter any additional text to appear on the correct / not quite message (optional)"
              maxChar={50}
              containerStyle={{ height: "200px" }}
              onChange={handleTips}
              value={content.questionTemplate?.answer?.tips}
            />
          </div>
        </div>
        <Button
          width="20%"
          height="8%"
          name={submitButtonText}
          fontSize="1.5rem"
          onClick={handleSubmit}
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeQuestionTemplate: (template: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: () => void;
  handleTextBoxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems
  ) => void;
  handleTextAreaChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems,
    order?: number
  ) => void;
  handleOptions: (
    sequence: number,
    texts: string[],
    template: TemplateItems
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(questionTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Q3);
