import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ChoiceGroup, Dropdown, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../../store';
import ModalDialog from '../../common/ModalDialog';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import { dropdownStyles } from '../../common/common';
import * as XLSX from "xlsx";
import { validateImportBond } from './BondHelper';
import { toast } from 'react-toastify';
import { importBondsApi } from '../../../../store/client/funder/bond';
import { getBonds } from '../../../../store/admin/funder/bond/action';
import { isLoading } from '../../../../store/admin/action';


export const OpenImportBondModal = (props: OwnProps) => {
	return new Promise((resolve, reject) => {
		const mountTarget = document.createElement('div')
		document.body.appendChild(mountTarget)
		const callback = (result?: any) => {
			resolve(result)
			ReactDOM.unmountComponentAtNode(mountTarget)
			mountTarget.remove()
		}
		ReactDOM.render(
			<Provider store={store}>
				<ModalDialog
					isModalOpen={true}
					title={"Import Bonds"}
					onDismiss={() => callback(undefined)}
					containerClassName="modal-size-sm"
				>
					<ImportBondDialog
						{...props}
						onSave={(data) => callback(data)}
						onCancel={() => callback(undefined)} />
				</ModalDialog>
			</Provider>,
			mountTarget
		)
	})
}

export const ImportBondDialog = ({
	onSave,
	onCancel,
}: Props) => {
	const dispatch = useDispatch()
	const { bondOffers }: IBondState = useSelector<RootState, IBondState>((state) => state.web.bonds);
	const [payload, setPayload] = useState<any>({ bondType: 'all' })
	const [file, setFile] = useState<any>()
	const [bondTypes, setBondTypes] = useState<any>([])

	const [errors, setErrors] = useState<any>({});


	const handleOnChange = (key: string, value: any) => {
		let data: any = _.cloneDeep(payload);
		if (errors[key]) {
			const err = { ...errors };
			delete err[key];
			setErrors(err);
		}
		switch (key) {
			// case "bondType":

			// break;
			default:
				data = _.set({ ...data }, key, value);
				break;
		}
		setPayload(data)
	};
	const handleUpload = (e: any) => {
		e.preventDefault();

		const files = e.target.files
		const f = files[0];
		setFile(f)
		var reader = new FileReader();
		reader.onload = function (e) {
			const data = e.target?.result;
			let readedData = XLSX.read(data, { type: 'binary' });
			const wsname = readedData.SheetNames[0];
			const ws = readedData.Sheets[wsname];

			/* Convert array to json*/
			let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
			dataParse.splice(0, 1) // first 1 row are header section
			const isaBond = dataParse.filter((b: any) => b[10] === 'Yes').length || 0
			const nonIsaBond = dataParse.filter((b: any) => b[10] === 'No').length || 0
			setBondTypes([
				{ key: "all", text: `All (${isaBond + nonIsaBond})` },
				{ key: 'ISA', text: `ISA (${isaBond})` },
				{ key: 'non-ISA', text: `non-ISA (${nonIsaBond})` }
			])
			//setFileUploaded(dataParse);
		};
		reader.readAsBinaryString(f)
	}

	const handleSave = async () => {

		const errors = await validateImportBond(payload);
		if (Object.keys(errors).length) {
			setErrors(errors);
			return;
		}
		const formData: FormData = new FormData();
		formData.append('file', file);
		formData.append('fileName', file.name);
		formData.append("bondOffer", payload.bondOffer)
		formData.append("bondType", payload.bondType)
		try {
			dispatch(isLoading(true))
			const data: any = await importBondsApi(formData)
			dispatch(getBonds())
			toast.success(data.msg)
			onSave!(data)
		} catch (error) {
			toast.error(`Something went wrong`)
		} finally {
			dispatch(isLoading(false))
		}

	};

	return (
		<div className="edit-record">
			<div className="ms-Grid" dir="ltr">
				<div className='ms-Grid-row'>
					<ul className='padding-left-md'>
						<li>Sheet name should be "bonds"</li>
						<li>First line should be header</li>
						<li>11th colum should be "IFISA"</li>
					</ul>
				</div>
				<div className='ms-Grid-row'>
					<Dropdown
						dropdownWidth='auto'
						label="Bond Offers"
						onChange={(e, item) => handleOnChange("bondOffer", item?.key?.toString())}
						selectedKey={payload?.bondOffer}
						options={bondOffers.filter((o: any) => o.key !== "all")}
						styles={dropdownStyles}
						errorMessage={errors.bondOffer}
						className="inlineflex ms-Grid-col ms-lg12"
					/>
				</div>
				<div className='ms-Grid-row margin-top-md' style={{ margin: "5px" }}>
					<label style={{ fontSize: "14px", fontWeight: 'bolder' }}>Select Excel File</label>
				</div>
				<div className='ms-Grid-row margin-top-md'>
					<input className="form-input" name="inputFile" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleUpload} />
				</div>
				{bondTypes.length > 0 && (
					<div className='ms-Grid-row'>
						<ChoiceGroup
							className="inlineflex ms-Grid-col ms-lg12"
							label="Bond Count"
							selectedKey={payload?.bondType || "all"}
							onChange={(e, option) => handleOnChange("bondType", option?.key)}
							options={bondTypes}
						/>
					</div>
				)}
			</div>
			<Sticky stickyPosition={StickyPositionType.Footer}>
				<Stack horizontal horizontalAlign="center">
					<Stack.Item>
						<PrimaryButton
							text="Save"
							onClick={handleSave}
							className="btn-primary"
						/>
						<DefaultButton onClick={onCancel} text="Cancel" />
					</Stack.Item>
				</Stack>
			</Sticky>
		</div>
	);
};

interface OwnProps {
	onCancel?: () => void
	onSave?: (data: any) => void
}

type Props = OwnProps;
