import Card from '../layouts'
import CardHeader from '../layouts/CardHeader'
import { IQuartelyDealCount } from '@solarforschools/sfs-core/dist/adminDashboard/types'
import CardContent from '../layouts/CardContent'
import { BackContent, FrontContent } from './contents/OfferSentFlipContent'

const OffersSent = ({ dealByOfferSent }: { dealByOfferSent: IQuartelyDealCount[] }) => {

    if (dealByOfferSent === undefined || dealByOfferSent.length === 0) return <></>

    return (
        <div className="dashboard-layout--pipedrive-deal-offer-sent">
            <Card dealByOfferSent={dealByOfferSent}>
                <CardHeader title='Offers sent' iconName='PipeDrive' />
                <CardContent variant='Flipper' frontContent={FrontContent} backContent={BackContent} hasFooter={false} />
            </Card>
        </div>
    )
}

export default OffersSent