import { useEffect } from "react";

import { IEstablishment } from "@solarforschools/sfs-core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import TaskFilters from "../task/TaskFilters";
import TasksList from "../task/TasksList";
import { ITaskState } from "../../../store/admin/task/reducer";
import { fetchTasks, setTaskFilter } from "../../../store/admin/task/action";

const SchoolTasks = (props: { school: IEstablishment }) => {

    const dispatch = useDispatch()
    const { taskFilter }: ITaskState = useSelector<RootState, ITaskState>((state) => state.web.task);

    const loadData = async () => {
        const params = { ...taskFilter, page: 1, slug: props.school.slug, taskType: 'schools', owner: '' };
        dispatch(setTaskFilter(params));
        dispatch(fetchTasks(params))
    }
    useEffect(
        () => {

            loadData();
        }, []);

    // const handleOnRemoveSystem = async (system: ISystem) => {
    //     await deleteSchoolSystem(props.school.slug, system.id)
    //     dispatch(fetchSystemsData({ schoolSlug: props.school.slug }))
    // }
    // const extraActions = [{ icon: 'Delete', title: "Delete System", onClick: handleOnRemoveSystem }]

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                //   height: "100vh",
                //   position: "relative",
            }}
        >
            <header className="table-header">
                <TaskFilters hidden={['taskType']} />
            </header>
            <TasksList scrollShow={false} />
        </div>
    );
};

export default SchoolTasks;
