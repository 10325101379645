import axios from "axios";
import { host } from "../../config";


export const updateFunderConfigAPi = (data: any): Promise<any> => {
    return axios
        .put(`${host}/funder/config`, data)
        .then((res) => res.data);
};

export const getFunderConfigAPi = (): Promise<{ config: any }> => {
    return axios.get(`${host}/funder/config`).then((res) => res.data);
};