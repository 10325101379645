import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useState } from "react";
import { getSystems } from "../../../store/client/system";
import { ISystem } from "@solarforschools/sfs-core";
import CustomTagPicker from "./CustomTagPicker";
import { getSchoolMetaFilterDataApi } from "../../../store/client/school";

interface IPickerProps {
    label: string,
    filters?: { [key: string]: any; }
    selectedItems?: ITag[]
    onChange: (key: string, items?: ITag[]) => void
}

const SchoolFitlerDataPicker: FunctionComponent<IPickerProps> = ({
    label,
    filters = {},
    selectedItems,
    onChange
}) => {
    const [data, setSelectedData] = useState<ITag[]>(selectedItems || [])
    const handleGetData = async (
        filter: string,
        selectedItems?: ITag[],
    ): Promise<ITag[]> => {
        try {
            const { data: _data } = await getSchoolMetaFilterDataApi({ term: filter, key: label })
            let data = _data?.length ? _data.map((s: string) => ({ key: s, name: `${s}` })) : []
            return data
        } catch (error) {
            return [] as ITag[]
        }
    }
    const handleOnChangeInput = async (key: string, value: any) => {
        (value.length) ? setSelectedData(value) : setSelectedData([])
        onChange(key, value)
    }

    return (
        <CustomTagPicker
            itemLimit={1}
            label={`${label}`}
            placeholder={`Search ${label}`}
            selectionAriaLabel={`Selected ${label}`}
            selectedItems={data}
            onResolveSuggestions={handleGetData}
            onChange={(items?: ITag[]) => handleOnChangeInput(label, items)} />
    );
}

export default SchoolFitlerDataPicker;
