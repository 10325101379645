import { IFilterMeterDumps } from "@solarforschools/sfs-core";
import { Action } from "./actionTypes";
import { IUniqueMeterDumps } from "@solarforschools/sfs-core";
export interface ILiveMeterDumpsState {
  filteredMetersSchools: IFilterMeterDumps[];
  latestMeterDate: string;
  meterDetails: IUniqueMeterDumps;
  options: { key: string; text: string }[];
  selectedOptions: string;
}
export interface IInitialState {
  latestMeterDate: string;
  filteredMetersSchools: IFilterMeterDumps[];
  meterDetails: IUniqueMeterDumps;
  options: { key: string; text: string }[];
  selectedOptions: string;
}
// const DEFAULT_METERS_DETAILS = {
//   readingsCount: 0,
//   meterDetails: [],
//   meterId: "",
//   pagesCount: 0,
//   meterType: "",
// };
const initialState: IInitialState = {
  latestMeterDate: "",
  filteredMetersSchools: [],
  // meterDetails: DEFAULT_METERS_DETAILS,
  meterDetails: {
    readingsCount: 0,
    meterDetails: [],
    meterId: "",
    pagesCount: 0,
    meterType: "",
  },
  options: [{ key: "", text: "" }],
  selectedOptions: "",
};
const liveMeterDumps = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_LATEST_METER_DETAILS":
      return {
        ...state,
        latestMeterDate: action.payload.latestDate,
        filteredMetersSchools: action.payload.metersDetails,
      };
    case "SET_METERS_SCHOOLS":
      return {
        ...state,
        filteredMetersSchools: action.payload,
      };
    case "SET_METERS_DETAILS":
      const { readingsCount, meterDetails, meterId, meterType, pagesCount } =
        action.payload;

      const updateMeterDetails = {
        readingsCount,
        pagesCount,
        meterId,
        meterType,
        meterDetails: [...state.meterDetails.meterDetails, ...meterDetails],
      };
      return { ...state, meterDetails: updateMeterDetails };

    case "SET_METER_TYPE_OPTIONS":
      const possibleTextObj: any = {
        E: "Export",
        I: "Import",
        G: "Generation",
      };
      const payloadOptions = action.payload?.type.map((e: any) => {
        return {
          key: e,
          text: possibleTextObj[e.trim().toUpperCase()],
        };
      });
      const payloadSelectedOptions = payloadOptions[0]?.key;

      // const updatedMeterTypeOptions = {
      //   options: payloadOptions,
      //   selectedOptions: payloadSelectedOptions,
      // };
      return {
        ...state,
        options: payloadOptions,
        selectedOptions: payloadSelectedOptions,
      };

    case "UPDATE_SELECTED_OPTIONS":
      const updatedSlectedOptions = action.payload;
      return {
        ...state,
        selectedOptions: updatedSlectedOptions,
      };

    case "RESET_METER_DETAILS":
      return {
        ...state,
        meterDetails: initialState.meterDetails,
      };
    default:
      return state;
  }
};

export default liveMeterDumps;
