import { Action } from "./actionTypes";
import { IRTYield } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { IRTYieldsFilter } from "../../../components/admin/rtYields/types";

// States' definition
export interface IRTYieldState {
  isLoading: boolean;
  rtYields: IRTYield[];
  rtYieldsFilter: IRTYieldsFilter;
  totalCount: number;
}
export interface State {
  rtYields: IRTYieldState;
}

const rtYields = (
  state: IRTYieldState = {
    isLoading: false,
    rtYields: [],
    rtYieldsFilter: {
      page: 1,
      pageSize: 50,
      sortField: "date",
      sortOrder: "desc",
      startEnd: 'no',
      start: moment().subtract(5, 'day').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      interval: "15",
    },
    totalCount: 0,
  },
  action: Action
): IRTYieldState => {
  switch (action.type) {
    case "SET_RTYIELDS_FILTER":
      return { ...state, rtYieldsFilter: action.rtYieldsFilter };
    case "SET_RTYIELDS_LIST":
      const rtYields = action.reset
        ? action.rtYields
        : [...state.rtYields, ...action.rtYields];
      return { ...state, rtYields, totalCount: action.totalCount };
    default:
      return state;
  }
};

export default rtYields;
