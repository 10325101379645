import React from "react";
import SparkLineChart from "./contentVariants/SparkLineChart";
import Table from "./contentVariants/Table";
import PlainContent from "./contentVariants/PlainContent";
import FlipContent from "./contentVariants/FlipContent";
import LineChartContent from "./contentVariants/LineChartContent";
import { IStackedColumnWithLineChartData, StackedColumnWithLineChart } from "./contentVariants/StackedColumnWithLineChart";
import DoughnutChart, { IDoughnutChartData } from "./contentVariants/DoughnutChart";
import BarChart, { IBarChartData } from "./contentVariants/BarChart";


interface ICardContentProps {
    variant?: string;
    title?: string;
    chartData?: {
        count: number;
        activeProjectsStages: Record<string, number>;
    };
    stackedBarLineChartData?: IStackedColumnWithLineChartData[];
    frontContent?: () => JSX.Element
    backContent?: () => JSX.Element
    children?: React.ReactNode;
    contentHeight?: string
    labels?: string[]
    data?: number[]
    value?: number
    contents?: React.ComponentType<any>
    hasFooter?: boolean
    doughnutChartData?: IDoughnutChartData[]
    barChartData?: IBarChartData[]
}
const CardContent = (props: ICardContentProps) => {
    const { variant, title, contents, frontContent, backContent, children, contentHeight, stackedBarLineChartData, barChartData, doughnutChartData, labels, data, value, hasFooter = true } = props;
    let Component;
    switch (variant) {
        case "LineChart":
            Component = <LineChartContent chartLabels={labels} chartData={data} value={value} />;
            break;
        case "SparkLine":
            Component = <SparkLineChart />;
            break;
        case "StackedBarLineChart":
            Component = <StackedColumnWithLineChart chartLabels={labels} chartData={stackedBarLineChartData} />;
            break;
        case "Table":
            Component = <Table />;
            break;
        case "Flipper": Component = <FlipContent title={title} frontContent={frontContent} backContent={backContent} hasFooter={hasFooter} />;
            break;
        case "Plain":
            Component = <PlainContent title={title} children={children} contentHeight={contentHeight} />; break;
        case "DoughnutChart":
            Component = <DoughnutChart chartLabels={labels as string[]} chartData={doughnutChartData as IDoughnutChartData[]} />; break;
        case "BarChart":
            Component = <BarChart chartLabels={labels as string[]} chartData={barChartData as IBarChartData[]} />; break;
        default:
            Component = <DefaultComponent title={title} contents={contents} />; break;
    }

    return <div>{Component}</div>;
};
export default CardContent;



interface IProps {
    title?: string;
    contents?: React.ComponentType<any>
}

const DefaultComponent = (props: IProps) => {
    const { title } = props;
    const RenderContent = props?.contents
    if (RenderContent)
        return <RenderContent title={title} />
    else return <></>
}
