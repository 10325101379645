import React, { useState } from 'react';
import { ActionButton, CheckboxVisibility, DetailsList, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility, Stack, TooltipHost } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import Pagination from '../common/Pagination';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { isSortable, SetDetailListHeaderSortDirection } from '../common/utils';
import { DropdownParams } from '../common/types';
import { PageSize } from '../common/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { IProductFilter } from './types';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { IProduct } from '../../../store/admin/product/reducer';
import { IProduct as IProductDocument } from '@solarforschools/sfs-core/dist/product/type';
import { deleteProduct, fetchProducts, setProductFilter } from '../../../store/admin/product/action';
import { initProduct } from './productHelper';
import { OpenProductModal } from './ProductDialog';

const ProductList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const product: IProduct = useSelector<RootState, IProduct>(
    (state: RootState) => state.web.product
  );
  const products = product.products;
  const headerRow: IColumn[] = [
    {
      key: "productId",
      name: "Product Id",
      fieldName: "productId",
      minWidth: 120,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.productId} closeDelay={500}>
            {item.productId}
          </TooltipHost>
        );
      },
    },
    {
      key: "title",
      name: "Title",
      fieldName: "title",
      minWidth: 120,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.title} closeDelay={500}>
            {item.title}
          </TooltipHost>
        );
      },
    },
    {
      key: "scalingType",
      name: "ScalinType",
      fieldName: "region",
      minWidth: 100,
      ...isSortable,
      onRender: (item) => item.scalingType || ''
    },{
      key: "region",
      name: "Region",
      fieldName: "region",
      minWidth: 60,
      ...isSortable,
      onRender: (item) => item.region
    },
    {
      key: "currency",
      name: "Currency",
      fieldName: "currency",
      minWidth: 60,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.currency} closeDelay={500}>
            {item.currency}
          </TooltipHost>
        );
      },
    },
    {
      key: "revenueFix",
      name: "RevenueFix",
      fieldName: "revenueFix",
      minWidth: 90,
      data: "number",
      className: "text-right",
      ...isSortable,
    },
    {
      key: "revenueVar",
      name: "RevenueVar",
      fieldName: "revenueVar",
      minWidth: 90,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.revenueVar} closeDelay={500}>
            {item.revenueVar}
          </TooltipHost>
        );
      },
    },
    {
      key: "recurring",
      name: "Recurring",
      fieldName: "recurring",
      minWidth: 90,
      ...isSortable,
    },
    {
      key: "action",
      name: "Action",
      fieldName: "-",
      minWidth: 70,
      onRender: (product: IProductDocument, index?: number) => {
        return (
          <span>
            <span className="action-icons">
              <FontAwesomeIcon
                title="Edit Product"
                onClick={async () => { await OpenProductModal({ params: product }) }}
                icon={faPencilAlt}
              />
            </span>
            <span className="action-icons">
              <FontAwesomeIcon
                onClick={async () => {
                  const confirm = await ConfirmDialog({
                    dialogContentProps: {
                      title: "Delete Product",
                      closeButtonAriaLabel: "Close",
                      subText: `Are you want to delete`,
                    },
                  });
                  if (confirm && product._id) handleOnDelete(product._id.toString());
                }}
                title="Delete Product"
                icon={faTrash}
              />
            </span>
          </span>
        );
      },
    },
  ];
  const [columns, setColumns] = useState<IColumn[]>(headerRow);
  const handleOnSort = (item: DropdownParams) => {
  

    const params = { ...product.productFilter, ...{ sortField: item?.key, sortOrder: item?.text } };
    dispatch(setProductFilter(params as IProductFilter));
    dispatch(fetchProducts());
  }

  const handleOnHeaderClick = (column?: IColumn): void => {
    if (column?.key === 'action') return
    const index = columns.findIndex(c => c.key === column?.key)
    const newColumns = SetDetailListHeaderSortDirection(column, columns)
    setColumns(newColumns)
    handleOnSort({ key: newColumns[index].key, text: newColumns[index].isSortedDescending ? 'desc' : 'asc' })
  };

  const setFormElement = (filterParams: any) => {
    const params = { ...product.productFilter, page: 1, ...filterParams };
    dispatch(setProductFilter(params));
    dispatch(fetchProducts())
  };
  const handleOnPaginate = (page: number) => {
    setFormElement({ page })
  }

  function handleOnDelete(_id: string) {
    dispatch(deleteProduct(_id as string))
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 10
    setFormElement({ pageSize: size });
  };

  return (
    <>
      <Stack tokens={{ padding: '0 15px' }} horizontal verticalAlign='center'>
        Displaying&nbsp;
        <Dropdown
          selectedKey={product.productFilter.pageSize?.toString() || "10"}
          options={PageSize}
          styles={{ dropdown: { width: "80px" } }}
          onChange={handleOnChangePageSize}
        />
        &nbsp;
        / {product.totalCount}
        <div style={{ display: 'flex', flex: 1 }} />
        {/* Check action */}
        {actions.includes('add') && (
          <ActionButton
            allowDisabledFocus
            style={{ textAlign: 'right' }}
            onClick={async () => { await OpenProductModal({ params: initProduct }) }}
          ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp;Add Product
          </ActionButton>
        )}
      </Stack>

      {product.totalCount ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                items={products}
                columns={columns}
                selectionMode={SelectionMode.none}
                onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.always}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={product.totalCount}
            onPaginate={(page) => handleOnPaginate(page)}
            currentPage={product?.productFilter?.page!}
            pageSize={product?.productFilter?.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  );
};

export default ProductList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

