import { Bar } from 'react-chartjs-2';

export interface IBarChartData {
    label: string;
    data: number[];
    backgroundColor: string;
}

interface IBarChart {
    chartLabels: string[];
    chartData: IBarChartData[]
}

const BarChart = ({ chartLabels, chartData }: IBarChart) => {

    if (chartData?.length === 0 || chartLabels?.length === 0) return <></>

    const data = {
        labels: chartLabels,
        datasets: chartData
    };

    const options = {
        plugins: {
            responsive: true,
            maintainAspectRatio: true,
            title: {
                display: false,
            },
            legend: {
                display: false
            },
           
        },
        barThickness: 20
    };

    return (
        <div>
            <div className="flex-column-center">
                <div style={{ height: "160px", width: "280px", paddingTop: "0.5rem" }}>
                    <Bar data={data} options={options} />
                </div>
            </div>
        </div>
    )
}

export default BarChart