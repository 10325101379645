import { useState, useRef } from 'react';
import Moment from 'moment'
import { ActionButton, CheckboxVisibility, Dropdown, IDetailsList, IDropdownOption, ScrollablePane, ScrollbarVisibility, TooltipHost } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";
import { IBillingCycle, IBillingReadingDocument, IBillingReadingFilter } from './types';
import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { isSortable } from '../common/utils';
import { IBilling } from '../../../store/admin/billing/reducer';
import { MapReadingTyp } from './common';
import { createBulkReadings, fetchBillingReadings, setBillingReadingFilter, updateReading, exportBillingReadings, refreshReading, deleteReading } from '../../../store/admin/billing/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPencilAlt, faPlus, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalDialog from '../common/ModalDialog';
import { EditReading } from './EditReading';
import { AddBillingPeriod } from './AddBillingPeriod';
import { ConfirmDialog } from '../common/ConfirmDialog';
import DetailsListWapper from '../../common/DetailsListWapper';
import { PageSize, ReadingTypes } from '../common/common';
import Pagination from '../common/Pagination';

const ReadingList = () => {
  const dispatch = useDispatch()
  const { totalCount, billingReadingFilter, billingReadings }: IBilling = useSelector<RootState, IBilling>((state: RootState) => state.web.billing)
  const readings: IBillingReadingDocument[] = billingReadings || []
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedReading, setSelectedReading] = useState<IBillingReadingDocument>();
  const [modalType, setModalType] = useState<string>();
  const listRef = useRef<IDetailsList | null>(null);

  const headerRow: IColumn[] = [
    {
      key: 'siteId',
      name: 'Site Id',
      fieldName: 'siteId',
      minWidth: 50,
      maxWidth: 100,
      data: 'number',
      className: 'text-right',
      ...isSortable
    },
    {
      key: 'siteName',
      name: 'Site Name',
      fieldName: 'siteName',
      minWidth: 120,
      data: 'string',
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.siteName} closeDelay={500}> {item.siteName}</TooltipHost>
    },
    {
      key: 'MSN',
      name: 'MSN',
      fieldName: 'MSN',
      minWidth: 100,
      data: 'string',
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.MSN} closeDelay={500}> {item.MSN}</TooltipHost>

    },
    {
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      minWidth: 30,
      data: 'string',
      ...isSortable
    },
    {
      key: 'factor',
      name: 'Factor',
      fieldName: 'factor',
      minWidth: 30,
      data: 'number',
      ariaLabel: 'Factor',
      className: 'text-right',
      ...isSortable
    },
    {
      key: 'startDate',
      name: 'Start Date',
      fieldName: 'startDate',
      minWidth: 80,
      data: 'Date',
      ...isSortable,
      onRender: (item: IBillingReadingDocument) => item.startDate ? Moment(item.startDate).format('YYYY-MM-DD') : item.startDate
    },
    {
      key: 'startType',
      name: 'Start Type',
      fieldName: 'startType',
      ariaLabel: 'Start Reading Type',
      minWidth: 30,
      data: 'number',
      ...isSortable,
      onRender: (item) => <TooltipHost content={MapReadingTyp(item.startType)} closeDelay={500}> {item.startType}</TooltipHost>
    },
    {
      key: 'startReading',
      name: 'Start Reading',
      fieldName: 'startReading',
      minWidth: 85,
      data: 'number',
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.startReading?.toString()} closeDelay={500}> {item.startReading}</TooltipHost>
    },
    {
      key: 'endDate',
      name: 'End Date',
      fieldName: 'endDate',
      minWidth: 80,
      data: 'Date',
      ...isSortable,
      onRender: (item: IBillingReadingDocument) => item.endDate ? Moment(item.endDate).format('YYYY-MM-DD') : item.endDate
    },
    {
      key: 'endType',
      name: 'End Type',
      ariaLabel: 'End Reading Type',
      fieldName: 'endType',
      minWidth: 30,
      data: 'number',
      ...isSortable,
      onRender: (item) => <TooltipHost content={MapReadingTyp(item.endType)} closeDelay={500}> {item.endType}</TooltipHost>

    },
    {
      key: 'endReading',
      name: 'End Reading',
      ariaLabel: 'End Reading',
      fieldName: 'endReading',
      minWidth: 80,
      data: 'number',
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.endReading?.toString()} closeDelay={500}> {item.endReading}</TooltipHost>
    },
    {
      key: 'yield',
      name: 'Yield',
      ariaLabel: 'Yield',
      fieldName: 'yield',
      minWidth: 60,
      data: 'number',
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.yield?.toString()} closeDelay={500}> {item.yield}</TooltipHost>
    },
    {
      key: 'period',
      name: 'Period',
      fieldName: 'period',
      minWidth: 50,
      data: 'string',
      ...isSortable,
      onRender: (item) => <TooltipHost content={item.period?.toString()} closeDelay={500}> {item.period}</TooltipHost>
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: '-',
      minWidth: 100,
      onRender: (reading: IBillingReadingDocument, index?: number) => {
        return (
          <span>
            <span className="action-icons">
              <FontAwesomeIcon
                title="Refresh Reading"
                onClick={async () => {
                  const confirm = await ConfirmDialog({
                    dialogContentProps: {
                      title: "Refresh Reading",
                      closeButtonAriaLabel: "Close",
                      subText: `Are you want to refresh`
                    },
                    confirmBtnText: 'Yes'
                  });
                  if (confirm) handleOnClickRefresh(reading._id!);
                }}
                icon={faRedo}
              />
            </span>
            <span className="action-icons">
              <FontAwesomeIcon
                title="Edit Reading"
                onClick={() => {
                  handleOnActionClick(reading);
                }}
                icon={faPencilAlt}
              />
            </span>
            <span className="action-icons">
              <FontAwesomeIcon
                title="Delete Reading"
                onClick={async () => {
                  const confirm = await ConfirmDialog({
                    dialogContentProps: {
                      title: "Delete Reading",
                      closeButtonAriaLabel: "Close",
                      subText: `Are you want to delete?`
                    },
                    confirmBtnText: 'Delete'
                  });
                  if (confirm) dispatch(deleteReading(reading._id!));
                }}
                icon={faTrash}
              />
            </span>

          </span>
        )
      },
    }
  ];
  const [columns, setColumns] = useState<IColumn[]>(headerRow)

  const handleOnActionNewCycle = () => {
    setIsModalOpen(true);
    setModalType("ADD");
  };

  const handleOnExport = () => {
    dispatch(exportBillingReadings())
  }

  const handleOnActionClick = (reading: IBillingReadingDocument) => {
    setSelectedReading(reading);
    setIsModalOpen(true);
    setModalType("EDIT");
  };
  const handleOnDismissModal = () => {
    setIsModalOpen(false);
  };
  const handleOnUpdate = (reading: IBillingReadingDocument) => {
    dispatch(updateReading(reading));
    setIsModalOpen(false);
  };

  const handleOnSaveBillingCycle = async (billingCycle: IBillingCycle) => {
    dispatch(createBulkReadings(billingCycle))
    setIsModalOpen(false);
  }

  const handleOnClickRefresh = async (readingId: string) => {
    dispatch(refreshReading(readingId))
    setIsModalOpen(false);
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...billingReadingFilter, page: 1, pageSize: size }
    dispatch(setBillingReadingFilter(filters));
    dispatch(fetchBillingReadings())
  };

  const loadNextPage = (p: IBillingReadingFilter) => {
    dispatch(setBillingReadingFilter({ ...billingReadingFilter, ...p }))
    dispatch(fetchBillingReadings())
  }

  return (
    <>
      {modalType === "ADD" && (
        <ModalDialog
          isModalOpen={isModalOpen}
          containerClassName="modal-size-sm"
          title="Add/Edit Reading"
          onDismiss={handleOnDismissModal}
        >
          <AddBillingPeriod
            onCancel={handleOnDismissModal}
            onSave={handleOnSaveBillingCycle}
          />
        </ModalDialog>
      )}
      {modalType === "EDIT" && selectedReading && (
        <ModalDialog
          isModalOpen={isModalOpen}
          title={"Add Edit Reading"}
          containerClassName={'modal-size-sm'}
          onDismiss={handleOnDismissModal}
        >
          <EditReading
            reading={selectedReading}
            onCancel={handleOnDismissModal}
            onSave={handleOnUpdate}
          />
        </ModalDialog>
      )}
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={billingReadingFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => handleOnExport()}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => handleOnActionNewCycle()}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp;Add/Refresh Period
        </ActionButton>
      </div>

      {totalCount ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={readings}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={readings.length < totalCount}
                // onRequestLoadMore={() => dispatch(fetchBillingReadings(undefined, false))}
                onSort={(field, order) => {
                  const params = { ...billingReadingFilter, sortField: field, sortOrder: order };
                  dispatch(setBillingReadingFilter(params));
                  dispatch(fetchBillingReadings(params))
                }}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={billingReadingFilter?.page!}
            pageSize={billingReadingFilter.pageSize!}
          />
          <div className="margin-top-md margin-left-sm">
            {ReadingTypes.filter(t => t.key !== 'all').map((t) => {
              return (<span><small><b>{t.key}.</b> {t.text}. &nbsp;</small></span>)
            })}
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
}

export default ReadingList;

