import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ActionButton, CheckboxVisibility, IColumn, ScrollablePane, ScrollbarVisibility, SelectionMode } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { DetailListStickyHeader } from "../../common/DetailListStickyHeader";
import { useState } from "react";
import { headerInvoiceAccountReportingRow } from "./Helper";
import DetailsListWapper from "../../../common/DetailsListWapper";
import { IInvoiceAccountReport } from "../../../../store/admin/reporting/invoiceAccountReport/reducer";
import { exportInvoiceAccountReport, fetchInvoiceAcountReportData, setInvoiceAccountReportFilter } from "../../../../store/admin/reporting/invoiceAccountReport/action";


const InvoiceAccountReportList = () => {
  const dispatch = useDispatch()
  const { invoiceAccountReportData, totalCount,  invoiceAccountReportFilter } = useSelector<RootState, IInvoiceAccountReport>((state: RootState) => state.web.invoiceAccountReport);
  
  const [columns, setColumns] = useState<IColumn[]>([...headerInvoiceAccountReportingRow]);

  // const handleOnSort = (item: DropdownParams) => {
  //   const params = { ...invoiceAccountReportFilter, ...{ sortField: item?.key, sortOrder: item?.text }, page: 1 };
  //   dispatch(setGSSReportFilter(params));
  //   dispatch(fetchGSSReportData());
  // }

  const loadNextPage = () => {
    const { page = 0 } = invoiceAccountReportFilter
    dispatch(setInvoiceAccountReportFilter({ ...invoiceAccountReportFilter, page: page + 1 }))
    dispatch(fetchInvoiceAcountReportData(undefined, false))
  }

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}>
            {invoiceAccountReportData.length > 0 && (<>Displaying &nbsp; {invoiceAccountReportData.length} / {totalCount}</>)}
          </span>
        </div>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => dispatch(exportInvoiceAccountReport())}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
      </div>

      {invoiceAccountReportData.length > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <DetailsListWapper
                items={invoiceAccountReportData}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={invoiceAccountReportData.length < totalCount}
                onRequestLoadMore={loadNextPage}
                onSort={(field, order) => {
                  const params = { ...invoiceAccountReportFilter, page: 1, sortField: field, sortOrder: order };
                  dispatch(setInvoiceAccountReportFilter(params));
                  dispatch(fetchInvoiceAcountReportData(params))
                }}
              />
              
            </ScrollablePane>
          </div>
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default InvoiceAccountReportList