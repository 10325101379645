import { ActionButton } from "@fluentui/react";
import { Sites } from "@solarforschools/sfs-core/dist/msgraph/types";
import { useDispatch } from "react-redux";
import { updateReportTemplatesInCache } from "../../../store/admin/generateReport/action";


const UpdateReportTemplateCache = () => {

    const dispatch = useDispatch();

    const handleOnClick = (site: Sites) => dispatch(updateReportTemplatesInCache(site))
    
    return (
        <div>
            <small>Update Report Templates in Cache</small>
            <span style={{ cursor: "pointer" }}>
                <ActionButton iconProps={{ iconName: 'Refresh' }} onClick={() => handleOnClick("school")}>
                    School
                </ActionButton>
                <ActionButton iconProps={{ iconName: 'Refresh' }} onClick={() => handleOnClick("project")}>
                    Project
                </ActionButton>
                <ActionButton iconProps={{ iconName: 'Refresh' }} onClick={() => handleOnClick("geo")}>
                    Geo
                </ActionButton>
                <ActionButton iconProps={{ iconName: 'Refresh' }} onClick={() => handleOnClick("calc")}>
                    Calc
                </ActionButton>
            </span>
        </div>
    );
};

export default UpdateReportTemplateCache;
