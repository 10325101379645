import {
  Dropdown,
  IDropdownOption
} from "@fluentui/react/lib/Dropdown";
import {
  Checkbox,
  ComboBox,
  IComboBoxOption,
  IStackTokens,
  Stack
} from "@fluentui/react/lib/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { DropdownParams } from "../common/types";
import { IBillingReadingFilter } from "./types";
import { fetchBillingReadings, setBillingReadingFilter } from "../../../store/admin/billing/action";
import { IBilling } from "../../../store/admin/billing/reducer";
import { ReadingTypes } from "../common/common";
import BillingContractPicker from "../common/BillingContractPicker";
import { ITag } from "office-ui-fabric-react";


const ReadingFilter = () => {
  const dispatch = useDispatch();
  const billing: IBilling = useSelector<RootState, IBilling>((state) => state.web.billing)
  const stackTokens: IStackTokens = { childrenGap: 30 };

  const setFormElement = (filterParams: IBillingReadingFilter, reset = true) => {
    const params = { ...billing.billingReadingFilter, page: 1, ...filterParams };
    dispatch(setBillingReadingFilter(params));
    dispatch(fetchBillingReadings(params, reset));
  };

  const handleOnChangeType = (type: string, value: boolean) => {
    let types = [...billing.billingReadingFilter.type!] || []
    if (value) {
      types = [...types, type]
    } else {
      types = types.filter(t => t !== type)
    }
    setFormElement({ type: types })
  }

  const handleOnChange = (
    type?: string,
    item?: IDropdownOption | IComboBoxOption | DropdownParams | undefined | ITag | any,
  ) => {
    switch (type) {
      case 'site':
      case 'period':
      // case 'contract':
      case 'readingType':
        setFormElement({ [type]: item?.key?.toString()! });
        break;
      case 'selectedContract':
        setFormElement({ contract: item[0]?.key })
        break
      default:
        break;
    }
  }

  const { period, site, readingType, contract } = billing.billingReadingFilter
  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{ width: "-webkit-fill-available", minWidth: "700px", maxWidth: "1300px" }}
    >
      <div className="ms-Grid-row">
        <ComboBox
          label="Site"
          placeholder="Choose Site"
          selectedKey={site || 'all'}
          autoComplete={"on"}
          onChange={(e, item) => handleOnChange("site", item)}
          options={billing.siteFilter as IComboBoxOption[]}
          className="ms-Grid-col ms-lg3"
        />
        <ComboBox
          label="Period"
          placeholder="Choose Period"
          selectedKey={period || 'all'}
          allowFreeform={true}
          autoComplete={"on"}
          onChange={(e, item) => handleOnChange('period', item)}
          options={billing.periodFilter as IComboBoxOption[]}
          className="ms-Grid-col ms-lg3"
        />
        <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
          <BillingContractPicker onChange={handleOnChange} selectedItems={[{ key: 'all', name: 'All' }]} contracts={billing.contractFilter} />
        </div>
        {/* <ExpandableFilters> */}
        <Dropdown
          label="Reading Type"
          selectedKey={readingType || 'all'}
          onChange={(e, item) => handleOnChange('readingType', item)}
          options={ReadingTypes}
          className="ms-Grid-col ms-lg3"
        />
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg3">
            <Stack horizontal horizontalAlign="center" tokens={stackTokens}>
              <Stack.Item style={{ width: 300 }}>
                <Stack horizontal horizontalAlign="center" tokens={stackTokens}>
                  <Stack.Item className='margin-top-sm margin-bottom-sm'>
                    Type
                  </Stack.Item>
                </Stack>
                <Stack horizontal horizontalAlign="center" tokens={stackTokens}>
                  <Stack.Item>
                    <Checkbox label='G' checked={billing.billingReadingFilter.type?.includes('G')!}
                      onChange={(e, value) => handleOnChangeType('G', value!)}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Checkbox label='E' checked={billing.billingReadingFilter.type?.includes('E')!}
                      onChange={(e, value) => handleOnChangeType('E', value!)}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Checkbox label='I' checked={billing.billingReadingFilter.type?.includes('I')!}
                      onChange={(e, value) => handleOnChangeType('I', value!)}
                    />
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
              </Stack.Item>
            </Stack>
          </div>
        </div>

        {/* </ExpandableFilters> */}
      </div>
    </div>
  );
};

export default ReadingFilter;
