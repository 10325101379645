import Card from '../layouts/index'
import CardHeader from '../layouts/CardHeader'
import CardFooter from '../layouts/CardFooter'
import CardContent from '../layouts/CardContent'
import { BackFooter, FrontFooter } from "./footer/TeacherPortalFlipFooter"
import { BackContent, FrontContent } from './contents/TeacherPortalContent'
import { IAdminDashboard } from "@solarforschools/sfs-core";


const TeacherPortalActivity = (props: Partial<IAdminDashboard>) => {

    if(props?.teacherPortalActivity === undefined) return <></>

    
    return (
        <div className="dashboard-layout--teacher-portal-activity">
            <Card teacherPortalActivity={props?.teacherPortalActivity}>
                <CardHeader title={`Teacher's portal tracker`} iconName='TeacherPortal' />
                <CardContent variant="Flipper" frontContent={FrontContent} backContent={BackContent} />
                <CardFooter variant="Flipper" frontFooter={FrontFooter} backFooter={BackFooter} />
            </Card>
        </div>
    )
}

export default TeacherPortalActivity