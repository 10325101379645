const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getDates = (
  startMonthCount: number,
  withYear: { year: boolean }
) => {
 
  let today = new Date();
  let date,
    year,
    month,
    result = [];

  for (var i = startMonthCount; i >= 0; i -= 1) {
    date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = monthNames[date.getMonth()];
    year = date.getFullYear();
    withYear.year ? result.push(`${month} ${year}`) : result.push(`${month}`);
  }
  return result;
};

export function getMonthNameFromWeekNumber(weekNumber: number) {
  if(!isNaN(weekNumber) || weekNumber === undefined) return 0
  const date = new Date();
  date.setMonth(0); // Set to January
  date.setDate(1 + (weekNumber * 7)); // Set to the first day of the week
  return monthNames[date.getMonth()];
}



function isNumeric(str: string): boolean {
  return /^[0-9]+$/.test(str);
}

/**
 * Used to Convert units to values, Ex: 10KW = 10000W
 * @param data
 * @returns converted value
 */
export function getElecGenValues(data: string[]) {
  const comparator: Record<string, number> = {
    K: 1000,
    M: 1000000,
    G: 1000000000,
    T: 1000000000000,
    P: 1000000000000000,
  };
  let number, units, output;
  let res = []; //Final Response To Render Chart

  for (let i = 0; i < data.length; i++) {
    // Check if it contains only numbers
    if (!isNumeric(data[i])) {
      number = parseFloat(data[i]?.match(/[\d\.]+/) as unknown as string); //Extract Numbers
      units = data[i]?.match(/[a-zA-Z]/) as unknown as string; //Extract Units
      const prefix = units[0]?.split("")[0]; //Extract Prefix from Units
      const prefixValue = comparator[prefix]; //Compare and get value
      output = prefixValue * number; //Get Desired Output
    } else {
      output = data[i];
    }
    res.push(output);
  }
  return res;
}

export const getElecGenStatus = (data: Number[]) => {
  let status = { prevMonth: "", isEqual: "" }; //Default Status Value
  //data[4] is previous month and data[5] is current month
  if (data[4] === data[5]) {
    status = { prevMonth: "", isEqual: "yes" };
  } else {
    if (data[4] > data[5]) {
      status = { prevMonth: "high", isEqual: "" };
    } else {
      status = { prevMonth: "low", isEqual: "" };
    }
  }
  return status;
};

export function renderFeedInTarrif(num: any) {
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: "compact",
    compactDisplay: "long",
  });
  return formatter.format(num);
}

export const quarters = {
  1: "Q1",
  2: "Q2",
  3: "Q3",
  4: "Q4",
};

export const convertToTonnes = (number: number): number => {
  return +(number / 1000).toFixed(2);
};

export const conversionInY = (
  value: any,
  renderUnits = ["Wh", "KWh", "MWh", "GWh", "TWh", "PWh"]
) => {
  const units = renderUnits;
  let unitsIndex = 0; //Inital Index is Watt Hour / Peak
  let elecGen = value; //Overall Electricity Generated

  while (elecGen >= 1000 && unitsIndex < units.length - 1) {
    elecGen /= 1000;
    unitsIndex++;
  }
  const resYValue = elecGen.toFixed(0) + units[unitsIndex]; //Resultant Out Ex:  "5.00GWh"
  return resYValue;
};

export const tooltipConversion = (
  context: any,
  renderUnits = ["Wh", "KWh", "MWh", "GWh", "TWh", "PWh"]
) => {
  const datasetIndex = context.datasetIndex;
  const dataset = context.chart.data.datasets[datasetIndex];
  const label = dataset.label || "";
  const yValue = context.parsed.y;

  //Possible Units For Electricity Generation - Watt(W)
  const units = renderUnits;
  let unitsIndex = 0; //Inital Index is Watt Hour / Peak
  let elecGen = yValue; //Overall Electricity Generated
  while (elecGen >= 1000 && unitsIndex < units.length - 1) {
    elecGen /= 1000;
    unitsIndex++;
  }
  const resYValue = elecGen.toFixed(2) + units[unitsIndex]; //Resultant Out Ex:  "5.00GWh"
  return ` ${label}: ${resYValue}`;
};

// export function renderElecGen(num: any) {
//   const UNIT = "Wh";
//   const formatter = new Intl.NumberFormat("en-US", {
//     notation: "compact",
//     compactDisplay: "short",
//     maximumFractionDigits: 2,
//   });
//   return formatter.format(num) + UNIT;
// }
