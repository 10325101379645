import React from 'react';
import BTPagination from 'react-bootstrap/Pagination';

export default function Pagination({
  totalRecords,
  pageSize = 10,
  currentPage = 1,
  linkCount = 10,
  onPaginate,
}: OwnProps) {
  const pages = [];
  const totalPages = Math.ceil(totalRecords / pageSize);
  if (currentPage > totalPages) currentPage = totalPages;

  // start page is half less from current page
  let startPage = currentPage - Math.floor(linkCount / 2);
  let endPage = currentPage + Math.floor(linkCount / 2);

  if (startPage <= 0) {
    endPage -= startPage - 1;
    startPage = 1;
  }

  if (endPage > totalPages) {
    endPage = totalPages;
    if (endPage - linkCount + 1 > 0) {
      startPage = endPage - linkCount + 1;
    } else {
      startPage = 1;
    }
  }

  // Logic to display a few pages before or after the ellipsis
  const beforeEllipsis = currentPage - Math.ceil(linkCount / 2);
  const afterEllipsis = currentPage + Math.ceil(linkCount / 2);

  if (beforeEllipsis > 1) {
    pages.push(
      <BTPagination.Item key={1} onClick={() => onPaginate(1)}>
        1
      </BTPagination.Item>
    );

    if (beforeEllipsis > 2) {
      pages.push(
        <BTPagination.Ellipsis key="beforeEllipsis" disabled />
      );
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <BTPagination.Item
        key={i}
        onClick={() => onPaginate(i)}
        active={i === currentPage}
        className={i === currentPage ? 'active' : ''}
      >
        {i}
      </BTPagination.Item>
    );
  }

  if (afterEllipsis < totalPages) {
    if (afterEllipsis < totalPages - 1) {
      pages.push(
        <BTPagination.Ellipsis key="afterEllipsis" disabled />
      );
    }

    pages.push(
      <BTPagination.Item key={totalPages} onClick={() => onPaginate(totalPages)}>
        {totalPages}
      </BTPagination.Item>
    );
  }

  return (
    <BTPagination className="justify-content-center mt-2">
      {currentPage > 1 && (
        <>
          <BTPagination.First onClick={() => onPaginate(1)} />
          <BTPagination.Prev onClick={() => onPaginate(currentPage - 1)} />
        </>
      )}
      {pages}
      {currentPage !== totalPages && (
        <>
          <BTPagination.Next onClick={() => onPaginate(currentPage + 1)} />
          <BTPagination.Last onClick={() => onPaginate(totalPages)} />
        </>
      )}
    </BTPagination>
  );
}

interface OwnProps {
  totalRecords: number;
  pageSize?: number;
  currentPage?: number;
  linkCount?: number;
  onPaginate: (page: number) => void;
}
