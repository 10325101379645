import React, { useEffect, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../../store/content/reducer";
import Button from "../formElements/Button";
import Label from "../formElements/Label";
import Textarea from "../formElements/Textarea";
import Textbox from "../formElements/Textbox";
import "./question.css";
import {
  IAnswer,
  IQuestionItems,
  IQuestionTemplate,
  QuestionItems,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { changeQuestionTemplate } from "../../../../store/content/action";
import { getName } from "../util";

const Q5 = ({
  content,
  handleSubmit,
  handleTextBoxChange,
  changeQuestionTemplate,
}: Props) => {
  const [dragId, setDragId] = useState("");

  const currentModule = content.modules?.filter(
    (module) => module.id === content.screen?.moduleID
  )[0];

  const thisTemplate = TemplateItems.Q5;

  const handleTips = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let answer: IAnswer = {
      ...content.questionTemplate?.answer,
      tips: e.target.value,
    };
    dispatchEvent(answer);
  };

  const dispatchEvent = (answer: IAnswer) => {
    let questionTemplate: IQuestionTemplate = {
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer,
    };
    changeQuestionTemplate(questionTemplate);
  };

  const dispatchDraggable = (questionItems: IQuestionItems[]) => {
    const answer = questionItems
      .filter((items) => items.type === QuestionItems.OPTIONS)
      .sort((a, b) => a.order! - b.order!)
      .map((obj) => obj.text);
    changeQuestionTemplate({
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      answer: {
        texts: answer as string[],
      },
      items: questionItems,
    });
  };
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const boxes = content.questionTemplate?.items;
    if (boxes) {
      const dragBox = boxes.find((box) => box.order === Number(dragId));
      const dropBox = boxes.find(
        (box) => box.order === Number(e.currentTarget.id)
      );
      if (dragBox && dropBox) {
        const dragBoxOrder = dragBox.order;
        const dropBoxOrder = dropBox.order;
        const newBoxState = boxes.map((box) => {
          if (box.order === Number(dragId)) {
            box.order = dropBoxOrder;
          } else if (box.order === Number(e.currentTarget.id)) {
            box.order = dragBoxOrder;
          }
          return box;
        });
        dispatchDraggable(newBoxState);
      }
    }
  };

  const findOrder = (sequence: number): number => {
    const currentTextbox = content.questionTemplate?.items?.find(
      (cont) => cont.sequence === sequence
    );
    return currentTextbox?.order !== undefined
      ? currentTextbox.order
      : sequence - 1;
  };

  const { submitButtonText } = getName(content, -1);

  return (
    <section className="content-template">
      <aside>
        <Label
          name={currentModule?.name ? currentModule.name : ""}
          fontSize="x-large"
        />
        <div
          id="content-container"
          style={{ paddingBottom: 0, paddingTop: 0, height: "65%" }}
        >
          <Textbox
            placeholder="Enter the Question here"
            maxChar={150}
            style={{ fontSize: "1rem", fontWeight: "normal" }}
            containerStyle={{ margin: "1rem 1rem" }}
            onChange={(e) =>
              handleTextBoxChange(e, 1, thisTemplate, QuestionItems.QUESTION)
            }
            value={
              content.questionTemplate?.items?.find(
                (temp) => temp.sequence === 1
              )?.text
            }
          />

          <div className="q-options-container">
            <Textbox
              placeholder="Enter Option 1"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(
                  e,
                  2,
                  thisTemplate,
                  QuestionItems.OPTIONS,
                  findOrder(2)
                )
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 2
                )?.text
              }
            />
            <Textbox
              placeholder="Enter Option 2"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(
                  e,
                  3,
                  thisTemplate,
                  QuestionItems.OPTIONS,
                  findOrder(3)
                )
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 3
                )?.text
              }
            />
            <Textbox
              placeholder="Enter Option 3"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(
                  e,
                  4,
                  thisTemplate,
                  QuestionItems.OPTIONS,
                  findOrder(4)
                )
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 4
                )?.text
              }
            />
            <Textbox
              placeholder="Enter Option 4"
              maxChar={20}
              width="75%"
              style={{ fontSize: "1rem", fontWeight: "normal" }}
              containerStyle={{ margin: "0rem 2rem" }}
              onChange={(e) =>
                handleTextBoxChange(
                  e,
                  5,
                  thisTemplate,
                  QuestionItems.OPTIONS,
                  findOrder(5)
                )
              }
              value={
                content.questionTemplate?.items?.find(
                  (temp) => temp.sequence === 5
                )?.text
              }
            />
          </div>
        </div>
      </aside>
      <aside>
        <p>
          Rank or Order question type. Enter the options left hand side and drag
          drop those below in order.
        </p>
        <div className="correct-answer">
          <div className="choose-correct-answer">
            <Label name="Order the text below" fontSize="large" />
            <div className="draggable-answer-options">
              {content.questionTemplate?.items
                ?.filter((c) => c.sequence && c.text?.length && c.sequence > 1)
                .sort((a, b) =>
                  a.order !== undefined &&
                  b.order !== undefined &&
                  a.order > b.order
                    ? 1
                    : -1
                )
                .map((options) => {
                  return (
                    <div
                      draggable={true}
                      onDragOver={(ev) => ev.preventDefault()}
                      onDragStart={handleDrag}
                      onDrop={handleDrop}
                      id={options.order ? options.order.toString() : "0"}
                    >
                      <span className="grippy"></span>
                      {options.text}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="answer-comment-box">
            <Label
              name="Correct!"
              fontSize="xx-large"
              style={{ marginTop: 0 }}
            />
            <Textarea
              placeholder="Enter any additional text to appear on the correct / not quite message (optional)"
              maxChar={50}
              width="100%"
              containerStyle={{ height: "55%" }}
              onChange={handleTips}
              value={content.questionTemplate?.answer?.tips}
            />
          </div>
        </div>
        <Button
          width="20%"
          height="8%"
          name={submitButtonText}
          fontSize="1.5rem"
          onClick={handleSubmit}
        />
      </aside>
    </section>
  );
};

interface DispatchProps {
  changeQuestionTemplate: (template: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  handleSubmit: () => void;
  handleTextBoxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems,
    order?: number
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(questionTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Q5);
