import { FontIcon, IColumn, Link, TooltipHost } from "@fluentui/react";
import { IProject } from "@solarforschools/sfs-core/dist/project/types";
import _ from "lodash";
import { websiteHost } from "../../../store/config";
import { IField } from "../../dynamic-form/DynamicForm";
import { ContributionUnitOptions, FundingModelOptions, GranpaUnitOptions, PartnerFeeUnitOptions, Regions } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { stages } from "../schools/common";
import { projectNameChange } from "./helper";

export const projectFields: IField[] = [
  {
    key: 'name',
    label: projectNameChange['Project Name'],
    type: 'string',
    placeholder: 'Enter name',
    className: 'ms-Grid-col ms-lg5',
  },
  {
    key: 'slug',
    label: projectNameChange['Project Slug'],
    type: 'string',
    className: 'ms-Grid-col ms-lg5',
    placeholder: 'Will be auto generated',
    readOnly: true,
  },
  {
    key: 'region',
    label: projectNameChange['Project Region'],
    type: 'dropdown',
    className: 'ms-Grid-col ms-lg2',
    options: Regions.filter(r => r.key !== 'all').map(x => ({ ...x, key: x.key.toLowerCase() })),
  },
];

export const projectStageFields: IField[] = [
  {
    key: 'activeStage.name',
    label: 'Stage',
    type: 'dropdown',
    className: 'ms-Grid-col ms-lg2',
    options: stages.slice(1)
  },
  {
    key: 'activeStage.active',
    label: 'Active',
    type: 'switch',
    className: 'ms-Grid-col ms-lg1',
  },
  {
    key: 'status',
    label: projectNameChange['Project Status'],
    type: 'switch',
    className: 'ms-Grid-col ms-lg1',
    extras: {
      onText: 'Active',
      offText: 'Closed',
    },
  },
]
const notInContributionUnit = ["+/- % Amount",
  "+/- Amount",
  "+/- Amount per kWp"]
export const analysisFields: IField[] = [
  {
    label: 'FundingModel',
    key: 'FundingModel',
    type: 'dropdown',
    options: FundingModelOptions,
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Contribution',
    key: 'Contribution',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Unit',
    key: 'ContributionUnit',
    type: 'dropdown',
    options: ContributionUnitOptions?.filter((elm) => !notInContributionUnit.includes(elm.key)),
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'FundRaising [%] ',
    key: 'FundRaisingP',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'SystemCosts',
    key: 'SystemCosts',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Unit',
    key: 'SystemCostsUnit',
    type: 'dropdown',
    options: ContributionUnitOptions,
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'OpexAdjustment',
    key: 'OpexAdjustment',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Unit',
    key: 'OpexUnit',
    type: 'dropdown',
    options: ContributionUnitOptions,
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Development Fee',
    key: 'DevelopmentFee',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Unit',
    key: 'DevFeeUnit',
    type: 'dropdown',
    options: ContributionUnitOptions,
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Grant',
    key: 'GrantP',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Gran Unit',
    key: 'GrantUnit',
    type: 'dropdown',
    options: GranpaUnitOptions,
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'InterestRate [%]',
    key: 'InterestRate',
    type: 'string',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Inflation [%]',
    key: 'Inflation',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'PricePaid',
    key: 'PricePaid',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Lifetime [Years]',
    key: 'Lifetime',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Yield [kWh/kWp]',
    key: 'Yield',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'PVSizeFactor [kWp/kWh]',
    key: 'PVSizeFactor',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Self Consumption [%]',
    key: 'SelfConsumption',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'PriceExport',
    key: 'PriceExport',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'PartnerFee',
    key: 'PartnerFee',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Unit',
    key: 'PartnerFeeUnit',
    type: 'dropdown',
    options: PartnerFeeUnitOptions,
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Profit Share %',
    key: 'pProfitShare',
    type: 'number',
    className: 'ms-Grid-col ms-lg2',
  },
  {
    label: 'Show ProfitShare',
    key: 'ShowProfitShare',
    type: 'dropdown',
    options: [{ key: 'Yes', text: "Yes" }, { key: "No", text: "No" }],
    className: 'ms-Grid-col ms-lg2',
  },
]

export interface ProjectTabErrors {
  general: boolean
  schools: boolean
  analysis: boolean
}

export const validateProject = (project: Partial<IProject>): StringMap => {
  const errors: StringMap = {};
  const generalFields = [...projectFields, ...projectStageFields];
  generalFields.forEach(field => {
    if (field.readOnly || field.disabled) return;
    const value = _.get(project, field.key);
    if (value !== false && value !== 0 && !value) {
      errors[field.key] = 'Please enter the value';
    }
  })
  if (!project.projectManagers?.length) {
    errors.projectManagers = `Atleast one ${projectNameChange["project manager"]} needs to be assigned`
  }
  return errors;
}

export const getProjectTabErrors = (errors: StringMap): ProjectTabErrors => {
  const taberrors = {
    general: false,
    schools: false,
    analysis: false,
  };
  const generalFields = [...projectFields, ...projectStageFields];
  generalFields.forEach(field => {
    if (errors[field.key]) {
      taberrors.general = true;
    }
  })
  // if (!errors.schools) {
  //   taberrors.schools = true;
  // }
  return taberrors;
}

export const getProjectColumns = (params: {
  actions: {
    icon: string,
    ariaLabel?: string,
    onClick: (task: any) => any
  }[]
}): IColumn[] => {
  return [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 250,
      ...isSortable,
      onRender: (item: IProject) => {
        return (
          <TooltipHost content={item.name} closeDelay={500}>
            <Link rel='noopener noreferrer' target='_blank' href={`${websiteHost}/projects/view/${item.slug}`}>
              {item.name}
            </Link>
          </TooltipHost>
        );
      },
    },
    {
      name: projectNameChange['Project Managers'],
      key: 'projectManagers',
      fieldName: 'projectManagers',
      minWidth: 100,
      maxWidth: 200,
      ...isSortable,
      onRender: (item: IProject) => {
        const value = item['projectManagers']
        const displayValue = Array.isArray(value) ? value.map(x => x.name).join(',') : value
        return (
          <TooltipHost content={displayValue} closeDelay={500} >
            {displayValue}
          </TooltipHost >
        );
      },
    },
    {
      name: 'Schools',
      key: 'schools',
      fieldName: 'schools',
      minWidth: 100,
      ...isSortable,
      onRender: (item: IProject) => {
        const value = item['schools']
        const displayValue = Array.isArray(value) ? value.map(x => x.name).join(',') : value
        return (
          <TooltipHost content={displayValue} closeDelay={500} >
            {displayValue}
          </TooltipHost >
        );
      },
    },
    {
      key: 'activeStage.name',
      name: 'Stage',
      fieldName: 'activeStage.name',
      ariaLabel: "Stage",
      minWidth: 80,
      ...isSortable,
      onRender: (item: any) => <TooltipHost content={item.activeStage?.name} closeDelay={500}>
        <span style={{ color: item.activeStage?.active ? 'green' : 'red' }}>{item.activeStage?.name}</span>
      </TooltipHost>
    },
    {
      key: 'region',
      name: 'Region',
      fieldName: 'region',
      ariaLabel: "Region",
      minWidth: 80,
      ...isSortable,
      onRender: (item: any) => <TooltipHost content={item.region} closeDelay={500}> {item.region}</TooltipHost>
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: '-',
      ariaLabel: "Action",
      minWidth: 150,
      maxWidth: 300,
      onRender: (taskType) => {
        return (
          <div className="action-icons-container">
            {params.actions.map((action: { icon: string | undefined; ariaLabel?: string, onClick: (arg0: any) => void; }) => {
              return <FontIcon
                iconName={action.icon}
                aria-label={action.ariaLabel || ''}
                onClick={() => action.onClick(taskType)} />;
            })}
          </div>
        );
      },
    }
  ];
}

export const initProject: IProject = {
  name: '',
  description: '',
  slug: '',
  status: true,
  region: 'GB',
  projectManagers: [],
  projectMembers: [],
  projectType: {},
  calcParams: {},
  schools: [],
  tasks: [],
  projectData: {},
  sort: {},
  stage: [],
  activeStage: { name: '', active: true }
} as unknown as IProject 
