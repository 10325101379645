import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { IUserSkill } from "@solarforschools/sfs-core/dist/user/types";
import { validateUserSkillApi } from "../../../store/client/userSkill";
import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";

export const getGeneralFields = (options: StringMap): IField[] => {
    return [
        {
            label: "name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg4'
        }, {
            label: "Id",
            key: "id",
            type: "string",
            extras: { required: true, ...options?.userSkill?._id ? { disabled: true } : {} },
            placeholder: "Id",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Active",
            key: "active",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg'
        },
        {
            label: "Permission",
            key: "isPermission",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg'
        },
        {
            label: "Skill",
            key: "isSkill",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg'
        },
        {
            label: "Description",
            key: "description",
            type: "string",
            extras: { multiline: true, rows: 3 },
            placeholder: "Description",
            className: 'ms-Grid-col ms-lg12'
        },
    ];
}
export const validateUserSkill = async (params: IUserSkill) => {
    let errors: StringMap = {};
    const { name, id } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (!id) errors['id'] = requiredMessage;
    if (!Object.keys(errors).length) {
        errors = await validateUserSkillApi(params) as unknown as StringMap
    }
    return errors;
}


export const getUserSkillColumns = (params: {
    actions: {
        icon: string,
        onClick: (task: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'id',
            name: 'Id',
            fieldName: 'id',
            minWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.id} closeDelay={500}> {item.id}</TooltipHost>
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            key: 'active',
            name: 'Active',
            fieldName: 'active',
            minWidth: 90,
            maxWidth: 100,
            ...isSortable,
        },
        {
            key: 'isPermission',
            name: 'Permission',
            fieldName: 'isPermission',
            minWidth: 90,
            maxWidth: 100,
            ...isSortable,
        },
        {
            key: 'isSkill',
            name: 'Skill',
            fieldName: 'isSkill',
            minWidth: 90,
            maxWidth: 100,
            ...isSortable,
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.description} closeDelay={500}> {item.description}</TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (taskType) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(taskType)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export const initUserSkill: IUserSkill = {
    name: '',
    id: '',
    active: false,
} as unknown as IUserSkill
