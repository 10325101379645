import { FontIcon, TagPicker, Text } from "@fluentui/react";
import { ITag } from "office-ui-fabric-react";
import { FunctionComponent, useState } from "react";
import { getSystems } from "../../../store/client/system";
import { IEstablishment, ISystem } from "@solarforschools/sfs-core";
import CustomTagPicker from "./CustomTagPicker";
import { getSchoolsApi } from "../../../store/client/school";

interface SchoolPickerProps {
    filters?: { [key: string]: any; }
    itemLimit?: number // how many item can be picked from drop down
    selected?: ITag[] // if want to send pre selected
    onChange: (key: string, items?: ITag[]) => void
}

const SchoolPicker: FunctionComponent<SchoolPickerProps> = ({
    filters = {},
    itemLimit = 1,
    selected = [],
    onChange
}) => {
    const [selectedSchool, setSelectedSchool] = useState<ITag[]>(selected.length ? selected : [])
    const handleGetSchool = async (
        str: string,
        selectedItems?: ITag[],
    ): Promise<ITag[]> => {
        try {
            const { schools } = await getSchoolsApi({ ...filters, name: str })
            let data = schools?.length ? schools.map((s: IEstablishment) => ({ key: s.slug, name: `${s.name}` })) : []
            return data
        } catch (error) {
            return [] as ITag[]
        }
    }
    const handleOnChangeInput = async (key: string, value: any) => {
        (value.length) ? setSelectedSchool(value) : setSelectedSchool([])
        onChange(key, value)
    }

    return (
        <CustomTagPicker
            itemLimit={itemLimit}
            label="School"
            placeholder="Choose School"
            selectionAriaLabel="Choose School"
            selectedItems={selectedSchool}
            onResolveSuggestions={handleGetSchool}
            onChange={(items?: ITag[]) => handleOnChangeInput('selectedSchool', items)} />
    );
}

export default SchoolPicker;
