import {
  IEthex,
  ILiveMeters,
  IQuartelyDealCount,
  ITPActivity
} from "@solarforschools/sfs-core/dist/adminDashboard/types";
import { createContext, ReactNode, useState } from "react";
import {
  IActiveSchools,
  IFeedInTarrif, IPipeDrive,
  IRegistrations,
  IDesings, ICarbonSavings,
  IElectricityGenerated
} from "@solarforschools/sfs-core/dist/adminDashboard/types";

export type CardContextType = {
  activeSchoolsData?: IActiveSchools;
  registrationData?: IRegistrations;
  designsData?: IDesings;
  isflip: boolean;
  setIsFlip: React.Dispatch<React.SetStateAction<boolean>>;
  electricityGenData?: IElectricityGenerated;
  feedInTarrif?: IFeedInTarrif;
  pipeDrive?: IPipeDrive;
  liveMetersData?: ILiveMeters;
  ethexData?: IEthex;
  dealByInLegals?: IQuartelyDealCount[];
  dealByOfferSent?: IQuartelyDealCount[];
  carbonSavings?: ICarbonSavings;
  teacherPortalActivity?: ITPActivity;
};
export const CardContext = createContext<CardContextType | null>(null);
interface ICard {
  activeSchoolsData?: IActiveSchools;
  registrationData?: IRegistrations;
  designsData?: IDesings;
  children: ReactNode;
  electricityGenData?: IElectricityGenerated;
  feedInTarrif?: IFeedInTarrif;
  pipeDrive?: IPipeDrive;
  liveMetersData?: ILiveMeters;
  ethexData?: IEthex;
  dealByInLegals?: IQuartelyDealCount[];
  dealByOfferSent?: IQuartelyDealCount[];
  carbonSavings?: ICarbonSavings;
  teacherPortalActivity?: ITPActivity;
}
const Card = (props: ICard) => {
  const {
    activeSchoolsData,
    designsData,
    registrationData,
    children,
    electricityGenData,
    feedInTarrif,
    pipeDrive,
    liveMetersData,
    ethexData,
    dealByInLegals,
    dealByOfferSent,
    carbonSavings,
    teacherPortalActivity
  } = props;
  const [isflip, setIsFlip] = useState(false);
  const contextProps = {
    activeSchoolsData,
    designsData,
    registrationData,
    isflip,
    setIsFlip,
    electricityGenData,
    feedInTarrif,
    pipeDrive,
    liveMetersData,
    ethexData,
    dealByInLegals,
    dealByOfferSent,
    carbonSavings,
    teacherPortalActivity
  };

  return (
    <CardContext.Provider value={{ ...contextProps }}>
      <div className="CardLayout">{children}</div>
    </CardContext.Provider>
  );
};
export default Card;
