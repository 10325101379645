import { ThunkAction } from "redux-thunk";
import { IActLogData, IFilterOptions, IOptions } from "./reducers";
import { RootState } from "../..";
import { AnyAction } from "redux";
import { isLoading } from "../action";
import { host } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

export const setActivities = (data: IActLogData) => {
  return { type: "SET_ACTIVITIES", payload: data };
};

export const setFilter = (data: IFilterOptions) => {
  return { type: "SET_FILTER", payload: data };
};

export const setFilterOptions = (data: IOptions) => {
  return { type: "SET_FILTER_OPTIONS", payload: data };
};

export const resetAll = () => {
  return { type: "RESET_ALL" };
};


//Middleware functions

export const fetchActivities = ({
  page = 1,
  server = "",
  type0 = "",
  type1 = "",
  type2 = "",
  user= "",
  date= ""
}): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function fetchActivitiesThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/activity?page=${page}&type2=${type2}&server=${server}&type0=${type0}&type1=${type1}&date=${date}&user=${user}`;
      const res = await axios.get(url);
      dispatch(isLoading(false));

      if (res.status === 200) {
        const buildParams = { ...res.data };
        dispatch(setActivities(buildParams));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};

export const fetchActivitiesFilters = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchActivitiesFiltersThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const url = `${host}/activity/filters`;
      const res = await axios.get(url);
      dispatch(isLoading(false));

      if (res.status === 200) {
        const buildParams = { ...res.data };
        dispatch(setFilterOptions(buildParams));
      } else {
        dispatch(isLoading(false));
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log("Error ", err);
      toast.error("Something went wrong");
      dispatch(isLoading(false));
    }
  };
};
