
import { Action } from "./actionType";
import { IBondTransactionFilter } from './../../../../components/admin/funder/transaction/types.d';
import { ITransaction } from '@solarforschools/sfs-core/dist/funder/transaction/types';


// States' definition
export interface IBondTransationState {
  isLoading: boolean;
  transactions: ITransaction[];
  transactionFilter: IBondTransactionFilter;
  totalCount: number
  bondOffers: any[];
  periods: [],
  logs: [];
  bonds: [];
}
export interface State {
  transaction: IBondTransationState;
}

const transation = (
  state: IBondTransationState = {
    isLoading: false,
    transactions: [],
    transactionFilter: {
      page: 1,
      pageSize: 50,
      sortField: 'certNumber',
      sortOrder: 'desc',
      payment:'all',
      bondOfferId:'all'
    },
    totalCount: 0,
    bondOffers: [],
    periods: [],
    logs: [],
    bonds: [],
  },
  action: Action
): IBondTransationState => {
  switch (action.type) {
    case 'FUNDER/SET_TRANSACTION_FILTER':
      return { ...state, transactionFilter: action.transactionFilter }
    case 'FUNDER/SET_TRANSACTION_LIST':
      const transactions = action.replace ? action.transactions : [...state.transactions, ...action.transactions]
      return { ...state, transactions, totalCount: action.totalCount }
    case "FUNDER/TRANSACTION_SET_BONDOFFER_FILTER":
      return { ...state, bondOffers: action.bondOffers as [] }
    case "FUNDER/TRANSACTION_SET_PERIODS_FILTER":
      return { ...state, periods: action.periods as [] }
    case "FUNDER/TRANSACTION_SET_LOGS_FILTER":
      return { ...state, logs: action.logs as [] }
    case "FUNDER/TRANSACTION_SET_BONDS_FILTER":
      return { ...state, bonds: action.bonds as [] }
    case "FUNDER/TRANSACTION_DELETE":
      {
        const stateObj = { ...state }
        const transactions: ITransaction[] = stateObj.transactions.filter(s => s._id?.toString() !== action.id.toString())
        const filter = { ...stateObj.transactionFilter }
        const { page, pageSize } = filter
        return { ...state, transactions, totalCount: stateObj.totalCount - 1, transactionFilter: { ...filter, page: Math.ceil(transactions.length / page! * pageSize!) } }
      }

    case "FUNDER/UPDATE_TRANSACTION": {
      const transactions = [...state.transactions];
      const index = transactions.findIndex(
        (s) => s._id === action.transaction?._id
      );
      if (index > -1) {
        transactions[index] = action.transaction;
      } else {
        transactions.unshift(action.transaction);
      }
      return {
        ...state,
        transactions,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default transation;
