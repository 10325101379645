import { ComboBox, Dropdown, IDropdownOption, Text } from '@fluentui/react'
import { useEffect, useState } from 'react'
import { dropdownStyles } from '../common/common'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { ICostSummary } from '../../../store/admin/costSummary/reducers'
import { fetchDesignResult, fetchDesignsBasedSchool, fetchRegionBasedSchools, fetchRegionCapex, resetOpex } from '../../../store/admin/costSummary/actions'
import { Link, useHistory, useLocation } from 'react-router-dom'
import CostSummaryTable from "./CostSummaryTable"
import { clearParams, getParams, ReplaceURL } from './Helper'

const defaultOption = { key: 'Select', text: "Select An Option" }


const CostSummaryFilter = () => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()

    const { schoolsDetails, regions, designs } = useSelector<RootState, ICostSummary>((state: RootState) => state.web.costSummary)

    const [buildedRegions, setBuildedRegions] = useState<IDropdownOption[] | []>([])
    const [buildedSchools, setBuildedSchools] = useState<IDropdownOption[] | []>([])
    const [buildedDesigns, setBuildedDesigns] = useState<IDropdownOption[] | []>([])

    const [isQuery, setIsQuery] = useState<boolean>(false)
    const [queryParamsError, setQueryParamsError] = useState<Record<string, boolean>>({ isRegionEmpty: false, isSlugEmpty: false, isDesignIdEmpty: false })

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [waitToRender, setWaitToRender] = useState<boolean>(false)
    const [waitToBuild, setWaitToBuild] = useState(false)

    const [selectedSlug, setSelectedSlug] = useState("")
    const [selectedDesign, setSelectedDesign] = useState("")
    const [selectedRegion, setSelectedRegion] = useState("")

    // Build the regions
    function buildRegionsFilter() {
        if (regions) {
            const sortedRegions = regions.sort()
            let newRegions = sortedRegions.map((region) => {
                return { key: region, text: region === "--" ? "Others" : region }
            })

            if (isQuery && newRegions.length === 0) setQueryParamsError({ ...queryParamsError, isRegionEmpty: true })

            if (newRegions.length > 0) newRegions.unshift(defaultOption)

            const { region: regionValue } = getParams(true, false)

            if (isQuery) {
                const result = newRegions.find((region) => region.key === regionValue)
                if (isQuery && result === undefined) {
                    setQueryParamsError({ ...queryParamsError, isRegionEmpty: true })
                }
                if (result !== undefined) setSelectedRegion(result.key)
            }

            setBuildedRegions(newRegions)
            setIsLoading(false)
        }
    }


    // Build the designsLists
    function buildSchoolsFilter() {
        const newSchoolsDetails = schoolsDetails.map((school) => {
            return { key: school.schoolSlug, text: school.schoolName }
        })

        newSchoolsDetails.unshift(defaultOption)
        setBuildedSchools(newSchoolsDetails)

        if (!isQuery)
            setSelectedDesign("")
        const { slug: slugValue } = getParams(false, true)

        const result = newSchoolsDetails.find((school) => school.key === slugValue)
        if (isQuery && result === undefined) {
            setQueryParamsError({ ...queryParamsError, isSlugEmpty: true })

        }

        if (result !== undefined) {
            setSelectedSlug(result.key);
        }
    }


    // Build the designsLists
    const buildDesignsFilter = () => {
        let res = []

        if (designs.length > 0) {
            for (let design in designs) {
                const aDesignName = designs[design]['name']
                const aDesignkey = designs[design]['designId'] as unknown as string
                res.push({ key: aDesignkey, text: aDesignName })
            }
        }


        res.unshift(defaultOption)
        setBuildedDesigns(res)

        const { designId: designIdValue } = getParams(false, false, true)

        const result = res.find((design) => design.key === designIdValue)
        if (isQuery && result === undefined) setQueryParamsError({ ...queryParamsError, isDesignIdEmpty: true })
        if (result !== undefined) {
            setSelectedDesign(result.key)
        }
    }


    useEffect(() => {
        const { region: regionValue, designId: designIdValue, slug: slugValue } = getParams(true, true, true)

        if ((regionValue && regionValue !== "") && (slugValue && slugValue !== "") && (designIdValue && designIdValue !== "")) {
            setIsQuery(true)
            dispatch(fetchRegionCapex(regionValue, designIdValue)) // fetch the region capex -

            dispatch(fetchRegionBasedSchools(regionValue))
            dispatch(fetchDesignsBasedSchool(slugValue))

            const resValues = { slug: slugValue, designs: designIdValue }
            dispatch(fetchDesignResult(resValues))
        }
    }, [])

    useEffect(() => {
        setIsLoading(true)
        buildRegionsFilter()
    }, [regions])

    useEffect(() => {
        if (buildedRegions.length === 0) setIsLoading(true)
        else setIsLoading(false)
        buildSchoolsFilter()
    }, [schoolsDetails])


    useEffect(() => {
        if (buildedRegions.length === 0) setIsLoading(true)
        else setIsLoading(false)
        buildDesignsFilter()
    }, [designs])



    const handleRegionChange = (key: string, value: any) => {
        if (value === "Select") {
            setSelectedRegion("")
            return
        }
        ReplaceURL(history, location, { region: value })
        setIsQuery(false)

        setSelectedRegion(value) // set region
        setWaitToRender(true)

        // Reset the slug, design
        setBuildedDesigns([defaultOption])
        setSelectedDesign(defaultOption.key)
        setSelectedSlug("")

        dispatch(fetchRegionBasedSchools(value))
    }

    const handleSchoolChange = (key: string, value: any) => {
        if (value === "Select" || value === undefined) return

        const { region: regionValue } = getParams(true)

        ReplaceURL(history, location, { region: regionValue, slug: value })

        setIsQuery(false)
        setWaitToRender(true)
        dispatch(resetOpex())


        setSelectedSlug(value)
        setSelectedDesign("")

        dispatch(fetchDesignsBasedSchool(value))
    }

    const handleDesignChange = (key: string | undefined, value: any) => {
        if (value === "Select" || key === undefined) return

        const { region: regionValue, slug: slugValue } = getParams(true, true)
        ReplaceURL(history, location, { region: regionValue, slug: slugValue, designId: value })

        setWaitToBuild(false)
        dispatch(resetOpex())
        dispatch(fetchRegionCapex(selectedRegion, value)) // dispatch dynamic capex based on design
        setSelectedDesign(value)
        setWaitToRender(true)

        const resValues = { slug: selectedSlug, designs: value }
        dispatch(fetchDesignResult(resValues))
    }

    if (isLoading) return <></>

    if (!isLoading && buildedRegions.length === 0) {
        return (<div className='flex-row-center'>
            <Text variant="mediumPlus"> No Region has capex data, please configure in setting <Link to="/settings/config">Click here</Link> </Text>
        </div>)
    }


    if (isQuery && (queryParamsError.isRegionEmpty || queryParamsError.isSlugEmpty || queryParamsError.isDesignIdEmpty))
        return <>
            {clearParams()}
        </>

    return (
        <>
            <div className='flex-row-center'>
                <Dropdown
                    dropdownWidth="auto"
                    label="Choose a region"
                    options={buildedRegions}
                    styles={dropdownStyles}
                    onChange={(e, elm) => handleRegionChange("Region", elm?.key?.toString())}
                    selectedKey={selectedRegion || defaultOption.key}
                    defaultSelectedKey={selectedRegion || defaultOption.key}
                    className="inlineflex ms-Grid-col ms-lg3"
                    required
                />
                <ComboBox
                    label="Choose a school"
                    placeholder='Choose or enter a school name'
                    onChange={(e, element) =>
                        handleSchoolChange("school", element?.key?.toString())
                    }
                    className="inlineflex ms-Grid-col ms-lg3"
                    allowFreeform
                    autoComplete="on"
                    options={buildedSchools}
                    selectedKey={selectedSlug || defaultOption.key}
                    defaultSelectedKey={selectedSlug || defaultOption.key}
                    required
                />

                <Dropdown
                    dropdownWidth="auto"
                    label="Choose a design (selected)"
                    onChange={(e, element) =>
                        handleDesignChange(element?.text.toString(), element?.key?.toString())
                    }
                    options={buildedDesigns}
                    styles={dropdownStyles}
                    selectedKey={selectedDesign || defaultOption.key}
                    defaultSelectedKey={selectedDesign || defaultOption.key}
                    className="inlineflex ms-Grid-col ms-lg3"
                    required
                />
            </div>

            <CostSummaryTable
                waitToRender={waitToRender}
                setWaitToRender={setWaitToRender}
                selectedSlug={selectedSlug}
                waitToBuild={waitToBuild}
                setWaitToBuild={setWaitToBuild}
            />
        </>

    )
}

export default CostSummaryFilter