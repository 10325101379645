import { ActionButton } from '@fluentui/react'
import React from 'react'
import { openDialog } from './AddRegionDialog'
import { toast } from 'react-toastify'
import axios from 'axios'
import { websiteHost } from '../../../store/config'

const AddRegion = () => {
    const handleAddRegion = async () => {
        await openDialog()
    }

    const updateWebsiteContext = async () => {
        try {
            await axios.patch(`${websiteHost}/api/update-context`)
            toast.success("Context update successfully!")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }
    return (
        <div>
            <div style={{ textAlign: "right" }} >
                <ActionButton
                    iconProps={{ iconName: "Add" }}
                    style={{
                        color: "var(--solar-blue)"
                    }}
                    onClick={updateWebsiteContext}
                >
                    Update Website Context
                </ActionButton>
                <ActionButton
                    iconProps={{ iconName: "Add" }}
                    style={{
                        color: "var(--solar-blue)"
                    }}
                    onClick={handleAddRegion}
                >
                    Add Region
                </ActionButton>
            </div>
        </div>
    )
}

export default AddRegion