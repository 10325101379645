import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { generateQueryString } from "../../../utils/util"
import { host } from "../../config"
import { IGSSReportFilter } from "../../../components/admin/reporting/gssReporting/types"
import { IInvoiceAccountReportFilter } from "../../../components/admin/reporting/invoiceAccountReporting/types"

let GetInvoiceAccountAPiAbortController: AbortController

export const getInvoiceAccountReportDataApi = (query: IInvoiceAccountReportFilter): Promise<any> => {
  if (GetInvoiceAccountAPiAbortController) GetInvoiceAccountAPiAbortController.abort()
  GetInvoiceAccountAPiAbortController = new AbortController()
  return axios.get(`${host}/reports/invoice-account-report/data?${generateQueryString(query as unknown as ParsedUrlQueryInput)}`, { signal: GetInvoiceAccountAPiAbortController.signal }).then(res => res.data)
}

export const getInvoiceAccountReportExportApi = (query: IInvoiceAccountReportFilter): Promise<any> => {
  return axios.get(`${host}/reports/invoice-account-report/excel?${generateQueryString(query as unknown as ParsedUrlQueryInput)}`,  { responseType: 'arraybuffer' }).then(res => res.data)
}
