import { IEstablishment, IParams, IRegionConfig, IUser } from '@solarforschools/sfs-core';
import { IProject, IProjectManager } from '@solarforschools/sfs-core/dist/project/types';
import axios from 'axios';
import { ParsedUrlQueryInput } from 'querystring';
import { StringMap } from '../components/admin/common/types';
import { generateQueryString } from '../utils/util';
import { host } from "./config";

let GetSchoolSearchController: AbortController

export const getProjectManagers = (): Promise<any> => {
  return axios.get(`${host}/projects/project-managers`).then(res => res.data);
}

export const getUsers = (query?: any): Promise<IUser[]> => {
  const queryStr = generateQueryString(query)
  return axios.get(`${host}/users?${queryStr}`).then(res => res.data.users);
}

export const getSchools = (query: any): Promise<IEstablishment[]> => {
  if (GetSchoolSearchController) GetSchoolSearchController.abort()
  const queryStr = generateQueryString(query)
  GetSchoolSearchController = new AbortController()
  return axios.get(`${host}/school?${queryStr}`, { signal: GetSchoolSearchController.signal }).then(res => res.data.schools)
}

export const createProject = (project: Partial<IProject>): Promise<IProject> => {
  return axios.post(`${host}/projects`, project).then(res => res.data);
}

export const updateProject = (project: Partial<IProject>): Promise<IProject> => {
  return axios.put(`${host}/projects`, project).then(res => res.data);
}

export const getProject = (slug: string): Promise<IProject> => {
  return axios.get(`${host}/projects/${slug}`).then(res => res.data);
}

//Get Api call for regionConfig
export const getRegionConfig = (slug: string): Promise<IRegionConfig> => {
  return axios.get(`${host}/settings/config/${slug}`)
}

//Update Api call for regionConfig
export const updateRegionConfig = (regionConfig: Partial<IRegionConfig>): Promise<IRegionConfig> => {
  return axios.put(`${host}/settings/config`, regionConfig).then(res => res.data)
}
export const calcProjectApi = (slug: string, filterBy: string, payload: IParams) => {
  return axios.post(`${host}/projects/${slug}/calc?filterBy=${filterBy}`, payload).then(res => res.data);
}

export const exportProjectToXlsxUrl = (slug: string, calcParams: StringMap) => {
  const query = generateQueryString(calcParams as unknown as ParsedUrlQueryInput);
  return `${host}/projects/${slug}/export?${query}`
}

export const getSchoolsPostApi = (data: any): Promise<IEstablishment[]> => {
  return axios.post(`${host}/school/all`, data).then(res => res.data.schools)
}
