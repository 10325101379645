import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import store from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getGeneralFields, validateMeterReading } from "./helper"
import { IMeterReading } from "@solarforschools/sfs-core/dist/solardb/types"
import { createUpdateMeterReading } from "../../../store/admin/meterReading/action"
import moment from "moment"
import { IMeterReadingState } from './../../../store/admin/meterReading/reducer';
import { RootState } from './../../../store/index';
import { filterSystemsMeterOptions } from "./common"
import { getSystems } from "../../../store/client/system"
import SystemPicker from "../common/SystemPicker"


export const openMeterReadingModal = (props?: any) => {
  return new Promise<IMeterReading | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (data?: IMeterReading) => {
      resolve(data);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add Edit Meter Reading"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditYieldDialog
            {...props}
            onSave={(data: IMeterReading | undefined) => callback(data)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditYieldDialog = (props: MeterReadingProps) => {
  const dispatch = useDispatch();
  const [meterReading, setMeterReading] = useState<IMeterReading>(_.cloneDeep(props.data as IMeterReading))
  const [errors, setErrors] = useState<any>({});
  const [meters, setMeters] = useState<any>([])
  const [msns, setMSNS] = useState<any>([])


  const generalFields = useMemo(() => getGeneralFields({ meterReading, meters, msns }), [meterReading, meters, msns]);



  const handleOnSave = async () => {
    const errors = await validateMeterReading(meterReading);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    const result: any = await dispatch(createUpdateMeterReading(meterReading))
    if (result) return props.onSave(meterReading)
    return false
  }


  const handleOnChangeInput = async (key: string, value: any) => {
    let data: IMeterReading = _.cloneDeep(meterReading);
    switch (key) {
      case 'selectedSystems':
        data = { ...data, name: value[0]?.name, id: value[0]?.key }

        break
      case 'date':
        const d = moment(value).format('YYYY-MM-DD')
        data = { ...data, date: d as unknown as Date }
        break

      default:
        data = _.set({ ...data }, key, value)
        break;
    }
    if (key === 'selectedSystems') {
      if (data.id) {
        const { systems } = await getSystems({ system: data.id })
        const { meters, msns } = filterSystemsMeterOptions(systems, { system: meterReading.id || 'all' }, true)
        setMeters(meters)
        setMSNS(msns)
      } else {
        setMeters([])
        setMSNS([])
      }

    }
    setMeterReading(data)
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className={`ms-Grid-col ms-lg6 margin-top-xsm`}>
            <SystemPicker onChange={handleOnChangeInput} />
          </div>
          <DynamicForm
            fields={generalFields}
            data={meterReading}
            onChange={handleOnChangeInput}
            errors={errors}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface MeterReadingProps {
  onCancel?: () => void;
  onSave: (data?: IMeterReading) => void;
  data?: IMeterReading;
}

export default openMeterReadingModal;

