import { useEffect, useRef, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ProgressIndicator, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider, useDispatch } from 'react-redux';
import ModalDialog from '../../common/ModalDialog';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import store from '../../../../store';
import { Checkbox } from 'office-ui-fabric-react';
import { getBondsApi } from '../../../../store/client/funder/bond';
import { isLoading } from '../../../../store/admin/action';



export const OpenGenCertificateModal = (props: { params: any }) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={`${props.params?.title}` || "Generate Certificate"}
                    onDismiss={() => toast.warn('Please click cancel button to close!')}
                    containerClassName={"modal-size-xs"}
                >
                    <GenCertModal {...props} onSave={(data) => callback(data)} onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}


export const GenCertModal = ({
    params,
    onCancel,
    onSave,
}: Props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState<any[]>([]); // bonds list
    const [counter, setCounter] = useState<number>(0); // total counter 
    const [successCount, setSuccessCounter] = useState<number>(0)
    const [breakLoop, setBreak] = useState<boolean>(false)
    const [genAll, setAll] = useState<boolean>(false)

    const breakLoopRef = useRef<any>();

    useEffect(() => {
        breakLoopRef.current = breakLoop;
    }, [breakLoop]);

    const handleOnSend = async () => {
        dispatch(isLoading(true))
        const { bonds } = await getBondsApi({ bondOffer: params.offerId, all: true, genAll });
        setData(bonds)
        dispatch(isLoading(false))
        for (const obj of bonds || []) {
            try {
                if (breakLoopRef.current) {
                    onCancel()
                    break
                }
                await params.sendAction(obj)
                setSuccessCounter(prevState => (prevState + 1))
            } catch (error) {
                console.log(error, JSON.stringify(obj))
                toast.error(`Something happend wrong `)
            } finally {
                setCounter(prevState => (prevState + 1))
            }
        }
        if (bonds?.length === 0) toast.warning(`Certificates are already generated or no valid bonds found! `)
        dispatch(isLoading(false))
        // onSave({ success: true });
    };
    const handleCancel = () => {
        if (counter === 0 || counter > data.length || counter === data.length) {
            onCancel()
        } else {
            setBreak(true)
        }
    }

    const handleOnChange = async (value?: boolean) => {
        setAll(value!)
    }
    return (
        <div className="edit-record">
            <div className='ms-Grid-row'>
                <p className='ms-Grid-col ms-lg12'>Are you sure, you want to generate certificate?</p>
            </div>
            <div className='ms-Grid-row margin-top-lg'>
                <div className='margin-top-lg'>
                    <p className='ms-Grid-col ms-lg12'>Select checkbox to generate all bonds certificate</p>
                    <Checkbox
                        label="All Bonds"
                        checked={genAll}
                        onChange={(e, value) => handleOnChange(value)}
                        className="ms-Grid-col ms-lg12"
                    />
                </div>
            </div>
            <div className='margin-top-lg'>
                {data?.length && data.length !== counter && (<ProgressIndicator label={`Processing ${counter + 1}/${data?.length}`} barHeight={5} />)}
                {data?.length > 0 && counter > 0 && (<ProgressIndicator description={`Processed ${counter}/${data?.length}`} percentComplete={counter / data?.length} />)}
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>
                        {(counter === 0) && (<>
                            <PrimaryButton
                                text={params.btnTitle || 'Send'}
                                onClick={handleOnSend}
                                className="btn-primary"
                            />
                        </>
                        )}
                        <DefaultButton onClick={handleCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    params: {
        btnTitle: string, // action button like send /save default will send
        modalTitle: string,  // modal title
        offerId: any,
        sendAction: (params: any) => void
    };
    onCancel: () => void;
    onSave: (params: any) => void;
}

type Props = OwnProps;
