
interface IPlainContentProps {
    title?: string;
    children: React.ReactNode
    contentHeight?: string;
}
const PlainContent = (props: IPlainContentProps) => {
    const { title, children } = props;
    const height = { height: props.contentHeight }
    return <>
        <div className="plain-content" style={height}>
            <div className="plain-content--children">
                {children}
            </div>
            <p>{title && title}</p>
        </div>
    </>
}

export default PlainContent