import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";

import Loader from '../common/Loader';
import { fetchProducts } from '../../../store/admin/product/action';
import ProductList from './ProductList';
import ProductFilters from './ProductFilters';

const Index = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProducts())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Products</h1>
        <ProductFilters />
      </header>
      <ProductList actions={['add', 'edit', 'delete']} />
    </div>
  );
}
export default Index;

