import { Checkbox, ChoiceGroup, Dropdown, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IBondHolderState } from '../../../../store/admin/funder/bondHolder/reducer';
import { getBondHolders, setBondHolderFilter } from '../../../../store/admin/funder/bondHolder/action';
import { RootState } from '../../../../store';
import { StringMap } from '../../common/types';
import { Regions, dropdownStyles } from '../../common/common';
import { statusOptions } from '../../user/common';
import { bondHolderStatus } from './TransactionHelper';
import { pyamentPreferencesTypes, TransactionStatus, TransactionTypes } from '../helper';
import { IBondTransationState } from '../../../../store/admin/funder/transaction/reducer';
import { getBondTransactions, setBondTransactionFilter } from '../../../../store/admin/funder/transaction/action';
import BondOfferPicker from '../../common/BondOfferPicker';

const BondTransactionFilters = (props: IBondTransactionFilterProps) => {
    const dispatch = useDispatch()
    const { transactionFilter, periods, logs }: IBondTransationState = useSelector<RootState, IBondTransationState>((state) => state.web.transaction)
    const setFilters = (filters: StringMap) => dispatch(setBondTransactionFilter(filters));

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        let params = { ...transactionFilter, page: 1 };
        switch (key) {
            case 'selectedBondOffer':
                params = { ...params, bondOfferId: value[0]?.key }
                break;
            default:
                params = { ...transactionFilter, page: 1, [key]: value };
                break;
        }
        setFilters(params);
        handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback(
        (filters: any) => {
            dispatch(getBondTransactions(filters));
        },
        []
    );

    const { certMumber, userName, transactionType, status, process, period, payment, isTestTransaction } = transactionFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Cert Number"
                    placeholder="Enter Certificate Number"
                    onChange={(e, value) => handleOnChange('certNumber', value || '')}
                    className="ms-Grid-col ms-lg3"
                    value={certMumber!} />
                <TextField
                    label="User Name"
                    placeholder="Enter Username"
                    onChange={(e, value) => handleOnChange('userName', value || '')}
                    className="ms-Grid-col ms-lg3"
                    value={userName!} />
                <Dropdown
                    dropdownWidth='auto'
                    label="Type"
                    onChange={(e, item) => handleOnChange("transactionType", item?.key?.toString())}
                    selectedKey={transactionType || 'all'}
                    options={TransactionTypes}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg3"
                />
                <Dropdown
                    dropdownWidth='auto'
                    label="Status"
                    onChange={(e, item) => handleOnChange("status", item?.key?.toString())}
                    selectedKey={status || 'all'}
                    options={TransactionStatus}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg3"
                />
                {/* <Dropdown
                    dropdownWidth='auto'
                    label="Audit Log"
                    onChange={(e, item) => handleOnChange("process", item?.key?.toString())}
                    selectedKey={process || 'all'}
                    options={logs}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg3"
                /> */}

                <Dropdown
                    dropdownWidth='auto'
                    label="Payment Period"
                    onChange={(e, item) => handleOnChange("period", item?.key?.toString())}
                    selectedKey={period || 'all'}
                    options={periods}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg3"
                />
                <Dropdown
                    dropdownWidth='auto'
                    label="Payment By"
                    onChange={(e, item) => handleOnChange("payment", item?.key?.toString())}
                    selectedKey={payment || 'all'}
                    options={pyamentPreferencesTypes}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg3"
                />
                <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
                    <BondOfferPicker filters={{}} onChange={handleOnChange} />
                </div>
                <Checkbox
                    label="Test Transactions"
                    checked={isTestTransaction === true}
                    onChange={(e, value) => handleOnChange('isTestTransaction', value)}
                    className="ms-Grid-col ms-lg2 margin-top-lg"
                />
            </div>
        </div>
    );
}

interface IBondTransactionFilterProps {

}

export default BondTransactionFilters;

