import {
  IProject,
  IWorkflow,
} from "@solarforschools/sfs-core/dist/project/types";
import { IProjectFolderLink } from "@solarforschools/sfs-core/dist/types";
import { toast } from "react-toastify";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../..";
import { StringMap } from "../../../components/admin/common/types";
import { IProjectsFilter } from "../../../components/admin/project/types";
import {
  createProject,
  getProject as getProjectApi,
  getProjectManagers as getProjectManagersApi,
  updateProject,
} from "../../client";
import { deleteProjectApi, getProjectsApi } from "../../client/project";
import { unlinkPFApi } from "../../client/projectFolder";
import { getProjectFlowsApi } from "../../client/workflow";
import { isLoading, setAuthToken } from "../action";
import {
  ChangeProjectListFiltersAction,
  LoadNextPageAction, SetProjectManagersAction,
  SetProjectsAction,
  SetProjectsFilter
} from "./ActionTypes";
import axios from "axios";
import { clearTokenParams } from "../../../utils/util";
import { AnyAction } from "redux";
import { host } from "../../config";

const limit = 50;

export const setProjectsFilter = (data: IProjectsFilter): SetProjectsFilter => {
  return { type: "PROJECTS/SET_PROJECTS_FILTER", data };
};

const getProjectAction = (
  projects: IProject[],
  count: number,
  replace: boolean
): SetProjectsAction => {
  return { type: "PROJECTS/SET_PROJECTS_LIST", projects, count, replace };
};

const getProjectManagersAction = (
  projectManagers: any[],
  projectMembers: any[]
): SetProjectManagersAction => {
  return {
    type: "PROJECTS/SET_PROJECT_MANAGERS_LIST",
    projectManagers,
    projectMembers,
  };
};

export const changeFilters = (
  filters: StringMap
): ChangeProjectListFiltersAction => {
  return { type: "PROJECTS/CHANGE_FILTERS", filters };
};

const loadNextPageAction = (skip: number): LoadNextPageAction => {
  return { type: "PROJECTS/LOAD_NEXT_PAGE", skip };
};

export const delProject = (projectSlug: string) => {
  return { type: "PROJECTS/DELETE_PROJECT", projectSlug };
};

export const setProjectWorkflows = (workflows: IWorkflow) => {
  return { type: "PROJECTS/WORKFLOWS", workflows };
};

export const setUpdatedProject = (project: IProject) => {
  return { type: "PROJECTS/UPDATE_PROJECT", project };
};

export const setProjectAnalysisData = (data: any) => {
  return { type: "PROJECTS/ANALYSIS_DESIGNS_DATA", data };
};


export const getProjectWorkflows =
  () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { workflows } = await getProjectFlowsApi({ all: true });
      dispatch(setProjectWorkflows(workflows));
      dispatch(isLoading(false));
    } catch (e: any) {
      dispatch(isLoading(false));
    }
  };

export const getProjects =
  (replace: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    // Add token in axios header, if authorization is undefined
    let isThereToken = axios.defaults.headers.common["authorization"];
    if (isThereToken === undefined) {
      let token = localStorage.getItem("token") as string;
      setAuthToken(token);
    }
    clearTokenParams();
    // const params = new URLSearchParams(window.location.search);
    // const tokenParams = params.get("token");
    // if (tokenParams) {
    //   let url = window.location.href;
    //   let cleanUrl = url.split("?")[0];
    //   window.location.href = cleanUrl;
    // }
    try {
      dispatch(isLoading(true));
      const { projectFilter } = getState().web.project;
      const { projects, count } = await getProjectsApi(projectFilter);
      dispatch(getProjectAction(projects, count, replace));
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const saveProject =
  (project: IProject) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const apiCall = project._id ? updateProject : createProject;
      const saved = await apiCall(project);
      const { projects, count } = getState().web.project;
      if (!project._id) {
        dispatch(getProjectAction([saved, ...projects], count, true));
      } else {
        const index = projects.findIndex((x) => x._id === project._id);
        const updatedProjects = [...projects];
        updatedProjects[index] = saved;
        dispatch(getProjectAction(updatedProjects, count, true));
      }
      dispatch(isLoading(false));
      return saved;
    } catch (e: any) {
      dispatch(isLoading(false));
    }
  };

export const getProjectManagers =
  () => async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      const { projectManagers, projectMembers } = await getProjectManagersApi();
      dispatch(getProjectManagersAction(projectManagers, projectMembers));
    } catch (e) {}
  };

export const getProject =
  (slug: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(isLoading(true));
      const project = await getProjectApi(slug);
      dispatch(isLoading(false));
      return project;
    } catch (e) {
      dispatch(isLoading(false));
    }
  };

export const sortProjects =
  (sort: string, order: string) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({ type: "PROJECTS/SET_SORT", sort: { sort, order } });
    dispatch(getProjects(true));
  };

export const deleteProject =
  (projectSlug: string) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { msg } = await deleteProjectApi(projectSlug);
      dispatch(delProject(projectSlug));
      toast.success(msg);
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const unlinkProjectFolder =
  (project: IProject) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { msg } = await unlinkPFApi(
        project.projectFolder?.driveItemId!,
        project.projectFolder?.driveId!
      );
      const { projects, count } = getState().web.project;
      const index = projects.findIndex((x) => x._id === project._id);
      const updatedProjects = [...projects];
      project.projectFolder = {} as IProjectFolderLink;
      updatedProjects[index] = project;
      dispatch(getProjectAction(updatedProjects, count, true));
      toast.success(msg);
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };


  export const downloadCalculator = (
    params: {
      designSlug: string;
      schoolSlug: string;
    }
  ) => {
    return async function (dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    
  
      dispatch(isLoading(true));

      const apiUrl = `${host}/report/export/calculator`

      try {
        const response = await axios.post(apiUrl, params);
        dispatch(isLoading(false));
       
        if (response.status === 200) {
          const url = response.data.msg;
    
          if (url) {
            window.open(url, "__blank");
            toast.success(`Calculator exported successfully`);
            return;
          }
          toast.error("Calculator failed to export");
          return;
        }
      } catch (error) {
        dispatch(isLoading(false));

        console.log(error);
        toast.error("Something went wrong");
      }
    };
  };