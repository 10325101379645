import { ITag, Stack, TagPicker, Text } from '@fluentui/react';
import { IEstablishment, IUser } from '@solarforschools/sfs-core';
import React, { useEffect, useState } from 'react';
import { getSchools } from '../../../store/client';
import SchoolList from '../schools/ReusableSchoolList';

const UserSchools = (props: UserSchoolsProps) => {
  const [schools, setSchools] = useState<any[]>(props.user?.schools || [])
  const actions = [
    {
      title: 'Un-Assign School',
      icon: "Delete",
      onClick: handleRemoveSchool
    }
  ]
  const loadData = async () => {
    if (props.user?.schools?.length) {
      const data = await getSchools({ slug: props.user.schools.map(s => s.slug), all: true });
      setSchools(data)
    }
  }
  useEffect(() => {

    loadData();
  }, [])

  function handleRemoveSchool(school: IEstablishment) {
    props.onUpdate('schools', props.user?.schools?.filter((x: any) => school.slug !== x.slug));
    setSchools(schools.filter(s => s.slug !== school.slug))
  }

  const handleGetSchools = async (
    filter: string,
    selectedItems?: ITag[],
  ): Promise<ITag[]> => {
    try {
      const schools = await getSchools({ name: filter })
      const selectedMap: { [key: string]: boolean } = {};
      props.user.schools?.forEach((i: any) => selectedMap[i.slug] = true);
      return schools
        .filter(school => !selectedMap[school.slug])
        .map(school => {
          return {
            key: school.slug,
            name: school.name,
            data: school,
          }
        })
    } catch (error) {
      return [] as ITag[]
    }

  }

  const handleAddSchool = async (items?: ITag[]) => {
    if (!items) return;
    const data = props.user.schools ? [...props.user.schools] : []
    const school = (items[0] as any).data
    data.push({
      _id: school._id,
      name: school.name,
      slug: school.slug,
    })
    props.onUpdate('schools', data);
    setSchools([...schools, school]);
  }

  const handleUpdated = (schools: IEstablishment[]) => {
    // props.onUpdateProject('schools', schools);
  }

  return (
    <Stack>
      <Text className='margin-top-md margin-bottom-md' style={{ fontSize: "18px" }}> Search School to Assign to this User</Text>
      <TagPicker
        styles={{ root: { marginTop: 5 } }}
        inputProps={{ placeholder: 'School Search to Assign to User' }}
        onResolveSuggestions={handleGetSchools}
        selectedItems={[]}
        selectionAriaLabel={'Selected Schools'}
        onChange={handleAddSchool}
      />
      <SchoolList
        schools={schools}
        actions={[]}
        extraActions={actions}
        onUpdated={handleUpdated}
      />
    </Stack>
  );
}

interface UserSchoolsProps {
  user: IUser
  onUpdate: (key: string, value: any) => void
}

export default UserSchools;
