import { useEffect } from "react";
import {
  ActionButton,
  ComboBox,
  Stack,
  TextField,
} from "@fluentui/react/lib/index";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { isLoading } from "../../../../store/admin/action";
import {
  fetchInvoicePriceData,
  fetchLiveSchools,
  fetchReportData,
  fetchSites,
  setSchoolReportForm,
} from "../../../../store/admin/reporting/reports/action";

import Loader from "../../common/Loader";

import _ from "lodash";
import { IDatafeed } from "../../../../store/admin/reporting/datafeed/reducer";
import { fetchDataFeedData, fetchDatefeedLiveSchools, setDatafeedData, setDatafeedFilter } from "../../../../store/admin/reporting/datafeed/action";
import { getDatafeedDataExportApi } from "../../../../store/client/reporting/datafeed";
import { downloadFileFromStream } from "../../common/utils";
import { toast } from "react-toastify";

function DataFeed() {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(isLoading(true));
      await dispatch(fetchDatefeedLiveSchools());
      await dispatch(isLoading(false));
    })();
  }, []);

  const datafeed: IDatafeed = useSelector<RootState, IDatafeed>(
    (state) => state.web.datafeed
  );

  const handleOnChange = (item?: any, key?: string): void => {
    if (item) {
      let filters = { ...datafeed.datafeedFilter }
      switch (key) {
        case "schools": {
          let selected = item?.selected;
          if (selected) {
            let selectedSchools = [...filters.schools]
            if (item.key === 'all') {
              selectedSchools = datafeed.schools.map((obj) => obj.key)
            } else {
              const s = datafeed.schools.find(s => s.key === item.key)
              if (s && !selectedSchools.includes(s.key)) selectedSchools.push(s.key)
            }
            filters = { ...filters, schools: selectedSchools }
          } else if (
            !selected &&
            item!.key === "all"
          ) {
            const selectedSchools: any[] = []
            filters = { ...filters, schools: selectedSchools }
            setDatafeedData([])
          } else {
            const selectedSchools: any[] = filters.schools.filter(s => s !== item.key).filter(s => s !== 'all')
            filters = { ...filters, schools: selectedSchools }
          }
        }
          break;
        case "range": {
          filters = { ...filters, range: item }
        }
          break;
        case "interval": {
          filters = { ...filters, interval: item.key }
        }
      }

      dispatch(setDatafeedFilter(filters));
      const { schools } = filters
      if (schools?.length) {
        // dispatch(fetchDataFeedData());
      }
    }
  };

  const handleExport = async () => {
    try {
      dispatch(isLoading(true))
      const data = await getDatafeedDataExportApi(datafeed.datafeedFilter);
      downloadFileFromStream({ data, filename: "datafeed", type: 'csv' })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data || 'Something went wrong')

    } finally {
      dispatch(isLoading(false))
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Datafeed Report</h1>

        <div
          className="ms-Grid ms-Grid-row"
          dir="ltr"
          style={{
            width: "-webkit-fill-available",
            minWidth: "700px",
            maxWidth: "1300px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ComboBox
            label="School Name"
            multiSelect
            selectedKey={datafeed.datafeedFilter.schools}
            placeholder="Type to filter school name"
            allowFreeform={true}
            autoComplete={"on"}
            options={datafeed.schools}
            className="ms-Grid-col ms-lg8"
            onChange={(e, item) => handleOnChange(item, "schools")}
          />
          <ComboBox
            label="Interval"
            selectedKey={datafeed.datafeedFilter.interval || '30'}
            placeholder=""
            allowFreeform={true}
            autoComplete={"on"}
            options={[{ key: '5', text: '5 mins' }, { key: '15', text: '15 mins' }, { key: '30', text: '30 mins' }, { key: '60', text: '1 hour' }, { key: '240', text: '4 hours' },]}
            className="ms-Grid-col ms-lg1"
            onChange={(e, item) => handleOnChange(item, "interval")}
          />
          <TextField
            label="Days Range"
            required
            placeholder="Days Range"
            type="number"
            step="1"
            min={1}
            max={2000}
            onChange={(e, value) => handleOnChange(value && parseFloat(value), 'range')}
            className="ms-Grid-col ms-lg2"
            value={datafeed.datafeedFilter.range?.toString()!} />
        </div>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          style={{ width: "100%" }}
        >
          {datafeed.datafeedFilter.schools.length > 0 &&
            <ActionButton
              iconProps={{ iconName: "ExcelDocument" }}
              onClick={() => handleExport()}
            >
              Export Data Feed({datafeed.datafeedFilter.interval} mins)
            </ActionButton>
          }
        </Stack>
      </header>
    </div>
  );
}

export default DataFeed;
