import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
  addQuestionTemplate,
  changeQuestionTemplate,
} from "../../../../store/content/action";
import {
  IQuestionItems,
  IQuestionTemplate,
  QuestionItems,
  TemplateItems,
} from "../../../../store/content/actionTypes";
import { IContent } from "../../../../store/content/reducer";
import Q1 from "./Q1";
import Q2 from "./Q2";
import Q3 from "./Q3";
import Q4 from "./Q4";
import Q8 from "./Q8";
import Q5 from "./Q5";
import Q6 from "./Q6";
import Q9 from "./Q9";
import Q7 from "./Q7";

const QuestionTemplate = ({
  content,
  name,
  addQuestionTemplate,
  changeQuestionTemplate,
}: Props) => {
  let moduleID: string, unitID: string;
  if (content.screen?.moduleID && content.screen?.unitID) {
    moduleID = content.screen.moduleID;
    unitID = content.screen.unitID;
  }
  const handleSubmit = () => {
    if (content.questionTemplate) {
      addQuestionTemplate(content.questionTemplate);
    }
  };

  const handleTextBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems,
    order?: number
  ) => {
    modifyQuestionTemplate(type, e.target.value, sequence, template, order);
  };

  const handleTextAreaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    sequence: number,
    template: TemplateItems,
    type: QuestionItems,
    order?: number
  ) => {
    modifyQuestionTemplate(type, e.target.value, sequence, template, order);
  };

  const modifyQuestionTemplate = (
    type: QuestionItems,
    value: string,
    sequence: number,
    template: TemplateItems,
    order?: number
  ) => {
    let questionItem: IQuestionItems = {
      type,
      sequence,
      text: value,
    };
    if (
      order !== undefined &&
      (template === TemplateItems.Q5 ||
        template === TemplateItems.Q9 ||
        template === TemplateItems.Q7)
    ) {
      questionItem["order"] = order;
    }
    dispatchQuestion(questionItem, sequence, template);
  };

  const handleOptions = (
    sequence: number,
    texts: string[],
    template: TemplateItems
  ) => {
    const questionItem: IQuestionItems = {
      type: QuestionItems.OPTIONS,
      sequence,
      texts,
    };
    dispatchQuestion(questionItem, sequence, template);
  };

  const dispatchQuestion = (
    questionItem: IQuestionItems,
    sequence: number,
    template: TemplateItems
  ) => {
    let questionTemplate: IQuestionTemplate = {
      ...content.questionTemplate,
      isEditing: !!content.questionTemplate?.isEditing,
      contentId: content.questionTemplate?.contentId,
      module: moduleID,
      unit: unitID,
      template,
      order: 0,
      items:
        content.questionTemplate?.items !== undefined
          ? content.questionTemplate?.items
          : [],
    };

    let newQuestion = questionTemplate.items?.filter(
      (temp) => temp.sequence !== sequence
    );

    if (newQuestion) {
      newQuestion.push(questionItem);
      questionTemplate.items = newQuestion;
    }
    changeQuestionTemplate(questionTemplate);
  };

  const renderTemplate = () => {
    switch (name) {
      case TemplateItems.Q1:
        return (
          <Q1
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
      case TemplateItems.Q2:
        return (
          <Q2
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
      case TemplateItems.Q3:
        return (
          <Q3
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
            handleTextAreaChange={handleTextAreaChange}
            handleOptions={handleOptions}
          />
        );
      case TemplateItems.Q4:
        return (
          <Q4
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
      case TemplateItems.Q5:
        return (
          <Q5
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
      case TemplateItems.Q6:
        return (
          <Q6
            handleSubmit={handleSubmit}
            handleTextAreaChange={handleTextAreaChange}
          />
        );
      case TemplateItems.Q7:
        return (
          <Q7
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
      case TemplateItems.Q8:
        return (
          <Q8
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
      case TemplateItems.Q9:
        return (
          <Q9
            handleSubmit={handleSubmit}
            handleTextBoxChange={handleTextBoxChange}
          />
        );
    }
  };
  return <>{renderTemplate()}</>;
};

interface DispatchProps {
  addQuestionTemplate: (questionTemplate: IQuestionTemplate) => void;
  changeQuestionTemplate: (template: IQuestionTemplate) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  name: string | undefined;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    addQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(addQuestionTemplate(questionTemplate));
    },
    changeQuestionTemplate: async (questionTemplate: IQuestionTemplate) => {
      await dispatch(changeQuestionTemplate(questionTemplate));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(QuestionTemplate);
