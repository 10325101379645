import React from 'react'

interface ICardProps {
    children: React.ReactNode[],
    isPendingList?: boolean
}

/**
 * Custom component (LayoutComponent)
 * @param {children, isPendingList}
 * @render single card of translation list and pending list
 */
const Card = (props: ICardProps) => {
    const { children, isPendingList } = props

    const [LeftPane, RightPane] = children

    return (
        <div className='card-outer mt-10' style={isPendingList ? { cursor: "default" } : {}}>
            <div className='card-left'>
                {LeftPane}
            </div>

            <div className='card-right'>
                {RightPane}
            </div>

        </div>
    )
}

export default Card