import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { getUserFunctions } from '../../../store/admin/userFunction/action';

import Loader from '../common/Loader';
import UserFunctionFilters from './UserFunctionFilters';
import UserFunctionList from './UserFunctionList';

const UserFunctionScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserFunctions())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>User Functions (Roles)</h1>
        <UserFunctionFilters />
      </header>
      <UserFunctionList actions={['add', 'edit', 'delete']} />
    </div>
  );
}
export default UserFunctionScreen;

