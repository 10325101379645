import { ComboBox, DatePicker, TextField } from "@fluentui/react";
import SystemPicker from "../../common/SystemPicker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IGSSReport } from "../../../../store/admin/reporting/gssReport/reducer";
import { fetchGSSReportData, setGSSReportFilter } from "../../../../store/admin/reporting/gssReport/action";
import { dropdownStyles, months } from "../../common/common";
import moment from "moment";



const GSSReportFilter = () => {
  const dispatch = useDispatch();

  const { gssReportFilter }: IGSSReport = useSelector<RootState, IGSSReport>((state) => state.web.gssReport);
  const handleOnChange = (
    key: string,
    value?: any,
  ) => {
    let params = { ...gssReportFilter };

    switch (key) {
      case 'selectedSystems':
        params = { ...params, system: value[0]?.key }
        break
      default: {
        params = { ...params, [key]: value }
      }
    }
    dispatch(setGSSReportFilter(params));
    dispatch(fetchGSSReportData());
  };
  const {
    system,
    ownedBy,
    periodType,
    month,
    year,
    from,
    to
  } = gssReportFilter;
  return (
    <div
      className="ms-Grid ms-Grid-row"
      dir="ltr"
      style={{ width: "-webkit-fill-available", minWidth: "700px", maxWidth: "1300px" }}
    >

      <div className={`ms-Grid-col ms-lg3 margin-top-xsm`}>
        <SystemPicker selectedItems={[{ key: system, name: system }]} onChange={handleOnChange} />
      </div>
      <ComboBox
        label="OwnedBy"
        placeholder="Select OwnedBy"
        allowFreeform={true}
        autoComplete={"on"}
        selectedKey={ownedBy || 'GSS1'}
        options={[
          { key: "CBS", text: "CBS" },
          { key: "GSS1", text: "GSS1" },
          { key: "GSS2", text: "GSS2" },
          { key: "GSS1GSS2", text: "GSS1 & GSS2" }
        ]}
        className="ms-Grid-col ms-lg2"
        onChange={(e, item) => handleOnChange("ownedBy", item?.key)}
      />
      <ComboBox
        label="Period"
        placeholder="Select period"
        allowFreeform={true}
        autoComplete={"on"}
        selectedKey={periodType || 'week'}
        options={[
          { key: "month", text: "Monthly" },
          { key: "year", text: "Yearly" },
          { key: "lifetime", text: "Lifetime" },
          { key: "custom", text: "Custom" },
        ]}
        className="ms-Grid-col ms-lg2"
        onChange={(e, item) => handleOnChange("periodType", item?.key)}
      />
      {periodType === "month" && (
        <>
          <ComboBox
            label="Month"
            placeholder="Select month"
            allowFreeform={true}
            autoComplete={"on"}
            selectedKey={month || moment().format("MMM")}
            options={months}
            className="ms-Grid-col ms-lg2"
            onChange={(e, item) => handleOnChange("month", item?.key)}
          />
        </>
      )}
      {(periodType == "month" || periodType === "year") && (
        <TextField label="Year"
          placeholder="year"
          type="number"
          step="1"
          min={2010}
          className="ms-Grid-col ms-lg2"
          onChange={(e, value) => handleOnChange('year', value && parseFloat(value))}
          value={year?.toString() || moment().format("YYYY")}
        />
      )}
      {periodType === "custom" && (
        <>
          <DatePicker
            label="From"
            placeholder="Start date"
            ariaLabel="Select a date"
            value={
              (from &&
                new Date(from)) ||
              new Date(moment().subtract(1, "month").format("YYYY-MM-DD"))
            }
            onSelectDate={(date) => handleOnChange("from", moment(date).format("YYYY-MM-DD"))}
            styles={dropdownStyles}
            className="ms-Grid-col ms-lg2"
          />
          <DatePicker
            label="To"
            placeholder="End date"
            ariaLabel="Select a date"
            value={
              (to &&
                new Date(to)) ||
              new Date()
            }
            onSelectDate={(date) => handleOnChange("to", moment(date).format("YYYY-MM-DD"))}
            styles={dropdownStyles}
            className="ms-Grid-col ms-lg2"
          />
        </>
      )}
    </div>
  );
};

export default GSSReportFilter;
