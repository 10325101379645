
export const projectNameChange = {
  Project: "Portfolio",
  Projects: "Portfolios",
  "Add Project": "Add Portfolio",
  "Project Managers": "Portfolio Managers",
  "project manager": "portfolio manager",
  "Project Name": "Portfolio Name",
  "Project Slug": "Portfolio Slug",
  "Project Region": "Portfolio Region",
  "Project Folder": "Portfolio Folder",
  "Project Status": "Portfolio Status",
  "Project description": "Portfolio description",
  "Project Members": "Portfolio Members",
};
