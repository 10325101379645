import _ from "lodash"
import { useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import { isLoading } from '../../../store/admin/action';
import { getCustomerFolderAPi, getListItemAPi, selectProjectFolderApi } from "../../../store/client/projectFolder"
import { SP_CLIENT_ID } from "../../../utils/util"
import { toast } from "react-toastify"
import { IProjectFolder, IProjectFolderLink } from "@solarforschools/sfs-core/dist/projectFolder/types"


export const openProjectFolderModal = (props?: any) => {
    return new Promise<IProjectFolderLink | undefined>((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (data?: IProjectFolderLink) => {
            resolve(data);
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <SelectProjectFolder
                    {...props}
                    onSave={(data: any | undefined) => callback(data)}
                    onCancel={() => callback(undefined)}
                />
            </Provider>,
            mountTarget
        )
    })
}

const SelectProjectFolder = (props: IProjectFolderProps) => {
    const dispatch = useDispatch();
    const [pf, setPf] = useState<IProjectFolderLink | null>(null)

    const fetchMyAPI = useCallback(async () => {
        try {
            dispatch(isLoading(true))
            const customerFolder: any = await getCustomerFolderAPi({ region: props.folder?.region || 'GB' })
            dispatch(isLoading(false))
            const odOptions = {
                clientId: SP_CLIENT_ID,
                action: 'query',
                viewType: 'folders',
                multiSelect: false,
                advanced: {
                    endpointHint: `https://solarforschools.sharepoint.com/sites/${customerFolder.site}/Shared%20Documents/Forms/AllItems.aspx?path=${customerFolder.path}`,
                },
                success: async function (files: { value: string | any[]; }) {
                    if (files.value && files.value.length > 0) {
                        try {
                            dispatch(isLoading(true))

                            const data: any = {
                                site: customerFolder.site,
                                driveItemId: files.value[0].id,
                                driveId: files.value[0].parentReference?.driveId
                            }
                            const listItem: any = await getListItemAPi({ driveItemId: data.driveItemId, driveId: data.driveId })

                            if (listItem && listItem.fields?.id) {
                                const folderLink: IProjectFolderLink & any = {
                                    listItemId: listItem.fields.id,
                                    name: listItem.fields?.FileLeafRef || '',
                                    site: data.site,
                                    driveItemId: data.driveItemId,
                                    driveId: data.driveId,
                                    siteId: listItem.sharepointIds.siteId,
                                    listId: listItem.sharepointIds.listId
                                }
                                const folder: IProjectFolder = {
                                    folderType: props.folder.folderType,
                                    name: folderLink.name,
                                    region: props.folder.region,
                                    slug: props.folder.slug,
                                    sfsId: 1,
                                    driveId: data.driveId,
                                    listItemId: folderLink.listItemId,
                                    driveItemId: folderLink.driveItemId
                                }
                                if (props.folder.stage) folder.stage = props.folder?.stage;
                                const { msg }: any = await selectProjectFolderApi({ folder, folderLink })
                                toast.success(msg)
                                props.onSave(folderLink)
                            }
                            dispatch(isLoading(false))
                        } catch (error) {
                            console.log(error)
                            toast.error(JSON.stringify(error))
                            dispatch(isLoading(false))
                        }
                    }
                },
                cancel: function () {
                    props.onCancel && props.onCancel()
                },
                error: function (error: any) {
                    console.log(error)
                    toast.error('Something went wrong')
                    props.onCancel && props.onCancel()
                }
            }
            window.OneDrive.open(odOptions);
        } catch (error) {
            dispatch(isLoading(false))
            console.log(error)
            toast.error('Something went wrong')
            props.onCancel && props.onCancel()
        }
    }, [props.folder]) // if userId changes, useEffect will run again

    useEffect(() => {
        fetchMyAPI()
    }, [fetchMyAPI])


    return (
        <div className="edit-record">
        </div>
    );
};

interface IProjectFolderProps {
    onCancel?: () => void;
    onSave: (data?: any) => void;
    folder?: IProjectFolder | any;
}

export default openProjectFolderModal;
