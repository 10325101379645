import { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { IBuildPieChart } from "./types";
import { addZeroes } from "./Helper";
import { Text } from "@fluentui/react";

interface ICbsViewProps {
    chartData: IBuildPieChart[];
}

const CbsView = (props: ICbsViewProps) => {
    const { chartData } = props;

    const [modifiedData, setModifiedData] = useState<any>({});

    useEffect(() => {
        if (chartData[0] !== undefined) {
            const { actualTotal, budgetTotal, difference } = chartData[0];

            const diff = addZeroes(difference);
            const actTot = actualTotal as number;
            const budTot = budgetTotal as number;

            setModifiedData({
                actualTotal: actTot,
                budgetTotal: budTot,
                difference: diff,
            });
        }
    }, [chartData]);

    if (
        chartData === undefined ||
        chartData.length === 0 ||
        Object.keys(modifiedData).length === 0
    )
        return <></>;

    Chart.register(...registerables);

    const data = {
        labels: ["Total Budget", "Total Actual"],
        datasets: [
            {
                data: [modifiedData.budgetTotal || 10, modifiedData.actualTotal || 10],
                backgroundColor: ["#243f61", "#FFCB30"],
                borderColor: ["#243f61", "#FFCB30"],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        cutout: 50,
        responsive: true,
        maintainAspectRatio: false,
        zindex: -100,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },

            tooltip: {
                callbacks: {
                    label: function (content: any) {
                        const label = content.label;
                        const value = content.formattedValue;
                        return `${label} ${value}`;
                    },
                },
            },
        },
        tooltip: {
            bodyFont: {
                size: 10,
            },
            padding: {
                left: 3,
                right: 3,
                bottom: 5,
                top: 5,
            },
        },
        datalabels: {
            display: false,
        },
        centerLabel: {
            enabled: true,
            fontColor: "#000000",
            fontSize: 20,
            content: "Center Label",
        },
    };

    const plugins = [
        {
            id: "text",

            beforeDraw: function (chart: any, a: any, b: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;

                ctx.restore();
                ctx.font = "0.85" + "rem sans-serif";
                ctx.fillStyle = "#243f61";
                ctx.textBaseline = "middle";
                var text = "Budget",
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;

                ctx.fillText(text, textX, textY);
                ctx.save();
            },
        },
    ];

    return (
        <>
            <div className="flex-column-center">
                <div style={{ marginBottom: "0.5rem" }}>
                    <Text variant="smallPlus" style={{ color: "#243f61" }}>
                        {" "}
                        Total Budget vs Actual Budget
                    </Text>
                </div>
                <div className="flex-row-center">
                    <div style={{ height: "8rem", width: "8rem" }}>
                        <Doughnut data={data} options={options as any} plugins={plugins} />
                    </div>
                    <div style={{ alignSelf: "flex-end" }}>
                        <Text variant="smallPlus" style={{ color: "#243f61" }}>
                            {" "}
                            Difference{" "}
                            <span style={{ fontWeight: "600" } as any}>
                                {" "}
                                {modifiedData?.difference || 0}
                            </span>{" "}
                        </Text>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CbsView;
