import React, { ChangeEventHandler } from 'react'

const UploadWrapper = (props: UploadWrapperProps) => {
  const { accept, onSelect } = props
  const fileBtnRef = React.createRef<HTMLInputElement>()

  const onClick = () => fileBtnRef.current?.click()
  
  return (
    <div style={{ cursor: 'pointer' }}>
      <input
        type='file'
        style={{ display: 'none' }}
        ref={fileBtnRef}
        onChange={onSelect}
        accept={accept}
        multiple
      />
      <div onClick={onClick}>
        {props.children}
      </div>
    </div>
  )
}

interface UploadWrapperProps {
  children: React.ReactNode  
  accept: string
  onSelect: ChangeEventHandler<HTMLInputElement>
}

export default UploadWrapper
