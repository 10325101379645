import { ActionButton, Dropdown, IDropdownOption, Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { changeFilters, fetchAllBlogs, setBlogCatsAndSites } from "../../../store/admin/blog/action";
import { IBlogs } from "../../../store/admin/blog/reducers";
import Loader from "../common/Loader";
import BlogFilters from "./BlogFilters";
import BlogList from "./BlogList";
import { OpenBlogModal } from './AddEditBlogDialog';
import { initBlog } from "./BlogHelper";
import { PageSize } from "../common/common";

const Blog = () => {
  const dispatch = useDispatch();
  const { isLoading, blogs, count, blogFilter } = useSelector<RootState, IBlogs>(
    (state) => state.web.blogs
  );
  useEffect(() => {
    dispatch(fetchAllBlogs(false));
    dispatch(setBlogCatsAndSites())
  }, []);

  const handleCreateNewProject = async () => {
    try {
      const blog = await OpenBlogModal({ params: initBlog })
      if (!blog) return;
      // const res = await createProject(project);
      // console.log(res)
      // dispatch(getProjects())
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 10
    const params = { ...blogFilter, pageSize: size };
    dispatch(changeFilters(params));
    dispatch(fetchAllBlogs(true));
  };


  return (
    <>
      <Loader />
      <Stack
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          position: "relative",
        }}
      >
        <header className="table-header">
          <h1>Blogs</h1>
          <BlogFilters />
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            style={{ width: "100%" }}
          >
            <span
              style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
            >
              {count > 0 &&
                <>
                  Displaying&nbsp;
                  <Dropdown
                    selectedKey={blogFilter.pageSize?.toString() || "10"}
                    options={PageSize}
                    styles={{ dropdown: { width: "80px" } }}
                    onChange={handleOnChangePageSize}
                  />
                  &nbsp;/ {count}
                </>}
            </span>
            <ActionButton
              iconProps={{ iconName: "Add" }}
              onClick={() => OpenBlogModal({ params: initBlog })}
            >
              Create Blog
            </ActionButton>
          </Stack>
        </header>
        {blogs && blogs.length ? <BlogList /> : null}
      </Stack>
    </>
  );
};

export default Blog;
