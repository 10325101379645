

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Loader from '../common/Loader'
import SettingsList from './SettingsList'
import { fetchRegionConfig } from '../../../store/admin/settings/action'
import AddRegion from './AddRegion'
const RegionConfigs = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRegionConfig())
  }, [])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Config Settings</h1>
      </header>
      <AddRegion />
      <SettingsList />
    </div>
  )
}

export default RegionConfigs