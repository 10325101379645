import { DefaultButton, Pivot, PivotItem, PrimaryButton, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import { ITransaction, ITransactionCreateUpdate } from "@solarforschools/sfs-core/dist/funder/transaction/types"
import _ from "lodash"
import moment from "moment"
import { useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch, useSelector } from "react-redux"
import store, { RootState } from "../../../../store"
import { saveTransaction } from "../../../../store/admin/funder/transaction/action"
import { IBondTransationState } from "../../../../store/admin/funder/transaction/reducer"
import DynamicForm from "../../../dynamic-form/DynamicForm"
import ModalDialog from "../../common/ModalDialog"
import { onRenderPivoteItemLink } from "../../common/PivotItemRenderLink"
import { getGeneralFields, getTabErrors, getTypeFields, transactionTabs, validateTransaction } from "./TransactionHelper"




export const OpenAddTransactionModal = (props: OwnProps) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Add Edit Transaction"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-md"
                >
                    <AddTransactionDialog
                        {...props}
                        onSave={transaction => callback(transaction)}
                        onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

export const AddTransactionDialog = ({
    transaction: data,
    onSave,
    onCancel,
}: Props) => {
    const dispatch = useDispatch()
    const { bonds }: IBondTransationState = useSelector<RootState, IBondTransationState>((state: RootState) => state.web.transaction)
    const [transaction, setTransaction] = useState<ITransaction>(_.cloneDeep(data));


    const [errors, setErrors] = useState<any>({});
    const [tabErrors, setTabErrors] = useState({ ...transactionTabs });

    // Get tab fields
    const generalFields = useMemo(() => getGeneralFields({ transaction, bonds }), [transaction, bonds]);
    const typeFields = useMemo(() => getTypeFields({ transaction }), [transaction]);


    const onPivotLinkClick = async (props: any) => {

    };



    const handleOnChangeInput = (key: string, value: any) => {
        let data: ITransaction = _.cloneDeep(transaction);
        if (errors[key]) {
            const err = { ...errors };
            delete err[key];
            const tabErrors = getTabErrors(err, transaction);
            setErrors(err);
            setTabErrors(tabErrors);
        }

        data = _.set({ ...transaction }, key, value);
        setTransaction(data);
    };

    const handleSave = async () => {
        const errors = await validateTransaction(transaction);
        if (Object.keys(errors).length) {
            const tabErrors = getTabErrors(errors, transaction);
            setErrors(errors);
            setTabErrors(tabErrors);
            return;
        }
        const data: ITransactionCreateUpdate = {
            transactionId: transaction._id,
            receiverName: transaction.cheque?.receiver,
            notes: transaction.notes,
            certNumber: transaction.certNumber,
            type: transaction.type,

            bondAmount: transaction.details.bondAmount,
            days: transaction.details.days || null,
            interestEndDate: transaction.details.end || null,
            interestStartDate: transaction.details.start || null,
            amount: transaction.details.amount,
            chequeAmount: transaction.cheque?.amount || null,
            action: transaction.action,
            status: transaction.status,
            cashDate: transaction.cheque?.cashDate || null,
            dueDate: transaction.dueDate,
            isTestTransaction: transaction.isTestTransaction
        }
        dispatch(saveTransaction(data))
        //onSave!(offer);
    };

    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <Pivot linkSize="large" onLinkClick={(item) => onPivotLinkClick(item)}>
                    <PivotItem
                        key="general"
                        headerText="General"
                        onRenderItemLink={onRenderPivoteItemLink(tabErrors.genearl)}
                    >
                        <div className="ms-Grid-row">
                            <DynamicForm
                                fields={generalFields}
                                data={transaction}
                                onChange={handleOnChangeInput}
                                errors={errors}
                            />
                        </div>
                        <div className='ms-Grid-row'>
                            <TextField
                                label="Notes"
                                placeholder=""
                                onChange={(e: any, value: any) => handleOnChangeInput('notes', value)}
                                multiline={true}
                                rows={3}
                                className='ms-Grid-col ms-lg12'
                                value={transaction.notes} />
                        </div>
                    </PivotItem>
                    <PivotItem
                        key="type"
                        headerText="Interest/Amount"
                        onRenderItemLink={onRenderPivoteItemLink(tabErrors.type)}
                    >
                        <div className="ms-Grid-row">
                            <DynamicForm
                                fields={typeFields}
                                data={transaction}
                                onChange={handleOnChangeInput}
                                errors={errors}
                            />
                        </div>
                    </PivotItem>

                    {transaction._id &&
                        <PivotItem
                            key="paymentDetails"
                            headerText="Payment Details"
                        >
                            <div className='ms-Grid-row'>
                                {transaction.action === "account" && (
                                    <>
                                        <TextField
                                            label="Bank Name"
                                            readOnly
                                            className="ms-Grid-col ms-lg6"
                                            value={transaction?.account?.receiver?.bankName!} />
                                        <TextField
                                            label="Account Name"
                                            readOnly
                                            className="ms-Grid-col ms-lg6"
                                            value={transaction?.account?.receiver?.name!} />
                                        <TextField
                                            label="Sort Code"
                                            readOnly
                                            className="ms-Grid-col ms-lg6"
                                            value={transaction?.account?.receiver?.sortCode!} />
                                        <TextField
                                            label="Account Number"
                                            readOnly
                                            className="ms-Grid-col ms-lg6"
                                            value={transaction?.account?.receiver?.number!} />
                                    </>

                                )}
                                {transaction.action === "cheque" && (
                                    <>
                                        <TextField
                                            label="Reciever Name"
                                            readOnly
                                            className="ms-Grid-col ms-lg6"
                                            value={transaction?.cheque?.receiver!} />
                                        <TextField
                                            label="Amount"
                                            readOnly
                                            className="ms-Grid-col ms-lg6"
                                            value={transaction?.cheque?.amount.toString()!} />
                                        <TextField
                                            label="Cashed Date"
                                            readOnly
                                            className="ms-Grid-col ms-lg4"
                                            value={moment(transaction?.cheque?.cashDate).format("YYYY/MM/DD")!} />
                                        <TextField
                                            label="Address"
                                            readOnly
                                            className="ms-Grid-col ms-lg8"
                                            value={transaction?.cheque?.address!} />
                                    </>
                                )}
                                {transaction.action === "reinvest" && (
                                    <TextField
                                        label="Reinvest"
                                        readOnly
                                        className="ms-Grid-col ms-lg8"
                                        value={"Re-Invest"} />)}
                                {transaction.action === "ethex" && (
                                    <TextField
                                        label="Ethex"
                                        readOnly
                                        className="ms-Grid-col ms-lg3"
                                        value={transaction.ethex?.id} />)}
                            </div>

                        </PivotItem>
                    }
                    <PivotItem key="log" headerText="Audit Log">
                        <table style={{ width: "100%" }}>
                            <thead style={{ backgroundColor: "#2e2e2e", color: 'white' }}>
                                <tr>
                                    <th style={{ padding: "5px" }}> Action </th>
                                    <th style={{ padding: "5px" }} >Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transaction?.auditLog?.map((a, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ padding: "5px" }}> {a.action}</td>
                                            <td style={{ padding: "5px" }}>{moment(a.time).format("MMM DD YYYY")}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                </Pivot>
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>
                        <PrimaryButton
                            text="Save"
                            onClick={handleSave}
                            className="btn-primary"
                        />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    onCancel?: () => void
    transaction: ITransaction,
    onSave?: (transaction: ITransaction) => void
}

type Props = OwnProps;
