import React from "react";
import Button from "./formElements/Button";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import { RootState } from "../../../store/";
import { changeScreen } from "../../../store/content/action";
import { Screens, IScreen } from "../../../store/content/actionTypes";
import { TITLE } from "./config";

const AddorEditTemplate = ({ content, changeScreen }: Props) => {
  const handleNewTemplate = (e: React.MouseEvent<HTMLButtonElement> | null) => {
    if (e) {
      e.stopPropagation();
    }
    changeScreen({
      ...content.screen,
      title: TITLE.TEMPLATE_SELECTION,
      currentScreen: Screens.TEMPLATE_SELECTION,
    });
  };

  const handleRearrangeScreen = (
    e: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    if (e) {
      e.stopPropagation();
    }
    changeScreen({
      ...content.screen,
      title: TITLE.REARRANGE_SCREENS,
      currentScreen: Screens.REARRANGE_SCREENS,
    });
  };

  return (
    <section className="add-edit-template">
      <Button
        height="30%"
        width="25%"
        fontSize="xx-large"
        name="Add New Template"
        onClick={handleNewTemplate}
      />
      <Button
        height="30%"
        width="25%"
        fontSize="xx-large"
        name="Edit or Rearrange the screens"
        onClick={handleRearrangeScreen}
      />
    </section>
  );
};

interface DispatchProps {
  changeScreen: (screen: IScreen) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState, ownProps: OwnProps): StateProps => {
  return {
    content: state.learning.content,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeScreen: async (screen: IScreen) => {
      await dispatch(changeScreen(screen));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(AddorEditTemplate);
