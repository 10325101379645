import { ComboBox, DatePicker, Dropdown } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { isLoading } from '../../../store/admin/action';
import { getFitReportingData, setFitReportingFilter } from '../../../store/admin/fitReporting/action';
import { IFitReportingState } from '../../../store/admin/fitReporting/reducer';
import { comboBoxStyles, dropdownStyles, FundingOptions } from '../common/common';

const FitMeterFilters = (props: FitReportFilterProps) => {
    const dispatch = useDispatch()
    const { fitReportingFilter, systems }: IFitReportingState = useSelector<RootState, IFitReportingState>((state) => state.web.fitReport)
    const handleOnChange = async (
        key?: any,
        item?: any
    ) => {
        let filters = {}
        switch (key) {
            default:
                filters = { [key as string]: item };
                break;
        }
        dispatch(isLoading(true))
        let params = { ...fitReportingFilter, page: 1, ...filters };

        dispatch(isLoading(false))
        dispatch(setFitReportingFilter(params));
        dispatch(getFitReportingData(params));
    }



    useEffect(() => { }, []);
    const { date, system, ownedBy } = fitReportingFilter || {}
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <ComboBox
                    label="System"
                    placeholder="Choose System"
                    selectedKey={system?.toString() || "all"}
                    allowFreeform={true}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChange("system", item?.key?.toString() || 'all')}
                    options={[{ key: 'all', text: 'All' }, ...systems]}
                    styles={comboBoxStyles}
                    className="ms-Grid-col ms-lg4"
                />

                <DatePicker
                    label="Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(moment(date).format('YYYY-MM-DD'))!}
                    onSelectDate={(date: any) => handleOnChange('date', moment(date).format('YYYY-MM-DD'))}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg4"
                />
                <Dropdown
                    dropdownWidth='auto'
                    label="Owned By"
                    selectedKey={ownedBy || 'all'}
                    onChange={(e, item) => handleOnChange("ownedBy", item?.key?.toString())}
                    options={[...FundingOptions, { key: "GSS1GSS2", text: "GSS1 & GSS2" }]}
                    styles={dropdownStyles}
                    className="ms-Grid-col ms-lg4"
                />
            </div>
        </div>
    );
}

interface FitReportFilterProps {

}
export default FitMeterFilters;
