import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types";
import { validateUserFunctionApi } from "../../../store/client/userFunction";
import { IField } from "../../dynamic-form/DynamicForm";
import { Regions, requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";
import { userFunctionRoles } from "./common";

export const getGeneralFields = (options: StringMap): IField[] => {
    return [
        {
            label: "name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg4'
        }, {
            label: "Slug",
            key: "slug",
            type: "string",
            extras: { required: true, ...options?.userFunction?._id ? { disabled: true } : {} },
            placeholder: "Slug",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Country",
            key: "country",
            type: "dropdown",
            extras: { required: true },
            options: Regions as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Role",
            key: "role",
            type: "dropdown",
            options: userFunctionRoles as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "Active",
            key: "active",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg'
        },
        {
            label: "Description",
            key: "description",
            type: "string",
            extras: { multiline: true, rows: 3 },
            placeholder: "Description",
            className: 'ms-Grid-col ms-lg12'
        },
    ];
}
export const validateUserFunction = async (params: IUserFunction) => {
    let errors: StringMap = {};
    const { name, slug, country } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (!slug) errors['slug'] = requiredMessage;
    if (!country) errors['country'] = requiredMessage;
    if (!Object.keys(errors).length) {
        errors = await validateUserFunctionApi(params) as unknown as StringMap
    }
    return errors;
}

export const initProduct = {
    productId: '',
    title: '',
    description: '',
    region: 'GB',
    currency: 'GBP',
    revenueFix: 0,
    revenueVar: 0,
    recurring: false,
    scalingType: 'SystemSize'
}
export const getUserFunctionColumns = (params: {
    actions: {
        icon: string,
        onClick: (task: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'slug',
            name: 'Slug',
            fieldName: 'slug',
            minWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.slug} closeDelay={500}> {item.slug}</TooltipHost>
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            name: 'Country',
            key: 'country',
            fieldName: 'country',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IUserFunction) => <TooltipHost content={item.country || ''} closeDelay={500}> {item.country}</TooltipHost>
        },
        {
            name: 'Role',
            key: 'role',
            fieldName: 'role',
            minWidth: 100,
            ...isSortable,
            onRender: (item: IUserFunction) => <TooltipHost content={item.role || ''} closeDelay={500}> {item.role}</TooltipHost>
        },
        {
            key: 'active',
            name: 'Active',
            fieldName: 'active',
            minWidth: 100,
            maxWidth: 200,
            ...isSortable
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (taskType) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(taskType)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export const initUserFunction: IUserFunction = {
    name: '',
    slug: '',
    country: 'all',
    active: false,
} as unknown as IUserFunction
