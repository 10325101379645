import { ThunkAction } from "redux-thunk";
import { RootState } from "../..";
import { AnyAction } from "redux";
import { isLoading } from "../action";
import axios, { Axios, AxiosError } from "axios";
import { host } from "../../config";
import { ITeam } from "@solarforschools/sfs-core/dist/team/types";
import { toast } from "react-toastify";

export const setTeam = (data: ITeam[]) => {
  return {
    type: "SET_TEAM",
    payload: data,
  };
};

export const setTeamMember = (data: ITeam) => {
  return {
    type: "SET_TEAM_MEMBER",
    payload: data,
  };
};

export const createNewTeamMember = (data: ITeam) => {
  return {
    type: "CREATE_NEW_TEAM_MEMBER",
    payload: data,
  };
};

export const resetAll = () => {
  return {
    type: "RESET_ALL",
  };
};

export const fetchTeamMembers = (): ThunkAction<
  Promise<void>,
  RootState,
  {},
  AnyAction
> => {
  return async function fetchTeamMembersThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${host}/team`);
      dispatch(setTeam(res?.data?.data));
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};

export const createTeamMember = (
  data: Partial<ITeam>
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function fetchTeamMemberThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.post(`${host}/team`, data);
      dispatch(isLoading(false));
      if (res.status === 201) {
        toast.success("New Team member added");
        dispatch(createNewTeamMember(res?.data?.data));
      } else {
        toast.success("Failed to create new Team member");
      }
    } catch (err: any) {
      if (err.response.data.msg === "Email address already exists") {
        toast.error("User already exists");
      } else {
        toast.error("Failed to create new Team member");
      }
      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};

export const editTeamMember = (
  data: Partial<ITeam>
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function editTeamMemberThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res = await axios.patch(`${host}/team`, data);
      if (res.status === 200) {
        dispatch(setTeamMember(res?.data?.data));

      } else {
        toast.error("Error in editing team member");
      }
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};
