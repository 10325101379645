import React from "react";
import Button from "./formElements/Button";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import { RootState } from "../../../store/";
import { changeScreen, getModules } from "../../../store/content/action";
import { IScreen, Screens } from "../../../store/content/actionTypes";
import { TITLE } from "./config";

const WhatsNext = ({ content, changeScreen }: Props) => {
  const handleSameModule = () => {
    changeScreen({
      currentScreen: Screens.UNIT,
      moduleID: content.screen?.moduleID,
      title: TITLE.UNIT,
    });
  };
  const handleSameModuleAndUnit = () => {
    changeScreen({
      currentScreen: Screens.TEMPLATE_SELECTION,
      moduleID: content.screen?.moduleID,
      unitID: content.screen?.unitID,
      title: TITLE.TEMPLATE_SELECTION,
      isQuiz: content.screen?.isQuiz,
    });
  };
  const handleStartAgain = () => {
    changeScreen({
      currentScreen: Screens.MODULE,
      title: TITLE.MODULE,
    });
  };
  return (
    <div className="whats-next-container">
      <label>Template Successfully Submitted</label>
      <label>
        Use the buttons below to select where to add a new template from
      </label>
      <div>
        <Button name="Same Module" onClick={handleSameModule} />
        <Button name="Same Module and Unit" onClick={handleSameModuleAndUnit} />
        <Button name="Start Again" onClick={handleStartAgain} />
      </div>
    </div>
  );
};

interface DispatchProps {
  changeScreen: (screen: IScreen) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeScreen: async (screen: IScreen) => {
      await dispatch(changeScreen(screen));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(WhatsNext);
