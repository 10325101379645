import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import InvoiceReportFilter from "./InvoiceReportFilter";
import { fetchInvoiceReportData } from "../../../../store/admin/reporting/invoice/action";
import InvoiceReportList from "./InvoiceReport";


const InvoiceReportScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(fetchInvoiceReportData());
    })();
  }, []);



  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'relative' }}>
      <Loader />
      <header className="table-header">
        <h1>Invoice Report</h1>
        <InvoiceReportFilter />
      </header>
      <InvoiceReportList/>
    </div>
  );
};

export default InvoiceReportScreen;
