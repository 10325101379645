import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { IContent } from "../../../store/content/reducer";
import { changeScreen } from "../../../store/content/action";
import { IScreen, Screens } from "../../../store/content/actionTypes";

const Template = ({ type, changeScreen, content }: Props) => {
  const templateURL =
    type === "content"
      ? [
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C1.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C2.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C3.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C4.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C5.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C6.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C7.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C8.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C9.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C10.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/contents/C11.png",
        ]
      : [
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ1.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ2.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ3.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ4.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ5.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ6.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ7.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ8.png",
          "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/questions/QQ9.png",
        ];

  const handleTemplate = (templateKey: string, className: string) => {
    if (className === "banned") {
      return;
    }
    const screen = {
      ...content.screen,
      currentScreen: Screens.ADDING_CONTENT,
      title: `${templateKey} Template Selected`,
    };
    if (type === "content") {
      screen["currentScreen"] = Screens.ADDING_CONTENT;
      screen["cTemplate"] = templateKey;
    } else {
      screen["currentScreen"] = Screens.ADDING_QUESTION;
      screen["qTemplate"] = templateKey;
    }
    changeScreen(screen);
  };
  return (
    <div className="template-images">
      {templateURL.map((url, index) => {
        const key = type === "content" ? `C${index + 1}` : `Q${index + 1}`;
        const banned = ["C5", "C6", "C11", "Q6", "Q7", "Q8"];
        const className = banned.includes(key) ? "banned" : "clickable";
        return (
          <img
            key={key}
            className={className}
            src={url}
            alt=""
            onClick={() => handleTemplate(key, className)}
          />
        );
      })}
    </div>
  );
};

interface DispatchProps {
  changeScreen: (screen: IScreen) => void;
}

interface StateProps {
  content: IContent;
}
interface IRootState {
  learning: any;
}

interface OwnProps {
  type: string;
}

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  content: state.learning.content,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    changeScreen: async (screen: IScreen) => {
      await dispatch(changeScreen(screen));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(Template);
