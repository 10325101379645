import axios from "axios";
import { host } from "../../config";
import { generateQueryString } from "../../../utils/util";

let GetPipedriveContactAPiAbortController: AbortController

export const getPipedriveContactApi = (query?: any): Promise<any> => {
    if (GetPipedriveContactAPiAbortController) GetPipedriveContactAPiAbortController.abort()
    GetPipedriveContactAPiAbortController = new AbortController()
    const queryStr = generateQueryString(query)
    return axios.get(`${host}/pipedrive/contact?${queryStr}`, { signal: GetPipedriveContactAPiAbortController.signal }).then(res => res.data);
}
export const getPipedriveContactTypesApi = (): Promise<any> => {
    return axios.get(`${host}/pipedrive/contact/types`, {}).then((res) => res.data);
};

export const getPipedriveContactLabelsApi = (): Promise<any> => {
    return axios.get(`${host}/pipedrive/contact/labels`).then((res) => res.data);
};
