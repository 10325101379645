import { Language } from './../../admin/translations/utils/types';
import { toast } from "react-toastify";
import { IContent } from "../../../store/content/reducer";
import { imagePlaceholder } from "./config";
import { host } from "../../../store/config";
import axios from "axios";

export const getName = (content: IContent, imageSequence: number) => {
  const submitButtonText =
    content.contentTemplate?.isEditing || content.questionTemplate?.isEditing
      ? "Update"
      : "Submit";

  let imageURL = getImageURL(content, imageSequence);

  return { submitButtonText, imageURL };
};

export const getImageURL = (content: IContent, imageSequence: number) => {
  let imageURL: string | undefined = imagePlaceholder;
  if (
    (content.contentTemplate?.isEditing ||
      content.questionTemplate?.isEditing) &&
    imageSequence > -1
  ) {
    let contentPath: any = content.contentTemplate?.items;
    if (content.questionTemplate?.isEditing) {
      contentPath = content.questionTemplate?.items;
    }
    imageURL = contentPath?.find(
      (item: any) => item.sequence === imageSequence
    )?.url;
  } else if (
    (!content.contentTemplate?.isEditing ||
      !content.questionTemplate?.isEditing) &&
    imageSequence > -1
  ) {
    const item = content.contentTemplate?.items?.find(
      (item) => item.sequence === imageSequence
    );
    if (item && item.file !== undefined)
      imageURL = URL.createObjectURL(item?.file as File);
  }
  return imageURL;
};

export const Languages = [
  { key: "all", text: "All" },
  { key: "CZ", text: "CZ" },
  { key: "DE", text: "DE" },
  { key: "ES", text: "ES" },
  { key: "GB", text: "GB" },
  { key: "IE", text: "IE" },
  { key: "IN", text: "IN" },
  { key: "NZ", text: "NZ" },
  { key: "EN", text: "EN" }
];