import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../store"
import DynamicForm from "../../dynamic-form/DynamicForm"
import ModalDialog from "../common/ModalDialog"
import { getGeneralFields, initUserFunction, validateUserFunction } from "./helper"
import slugify from "slugify"
import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types"
import { createUpdateUserFunction } from "../../../store/admin/userFunction/action"


export const openUserFunctionModal = (props?: any) => {
  return new Promise<IUserFunction | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (userFunction?: IUserFunction) => {
      resolve(userFunction);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add Edit User Function"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditUserFunctionDialog
            {...props}
            onSave={(userFunction: IUserFunction | undefined) => callback(userFunction)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditUserFunctionDialog = (props: UserFunctionProps) => {
  const dispatch = useDispatch();

  const [userFunction, setUserFunction] = useState<IUserFunction>(_.cloneDeep(props.userFunction || initUserFunction as IUserFunction))
  const [errors, setErrors] = useState<any>({});
  
  const generalFields = useMemo(() => getGeneralFields({ userFunction }), [userFunction]);

  const handleOnSave = async () => {
    const errors = await validateUserFunction(userFunction);
    if (Object.keys(errors).length) {
      // const tabErrors = getTabErrors(errors);
      setErrors(errors);
      // setTabErrors(tabErrors);
      return;
    }
    await dispatch(createUpdateUserFunction(userFunction))
    props.onSave(userFunction)
  }

  const onPivotLinkClick = async (props: any) => { };

  const handleOnChangeInput = (key: string, value: any) => {

    let data: IUserFunction = _.cloneDeep(userFunction);
    switch (key) {
      case 'name':
        if (!data._id) {
          data.slug = slugify(value, {
            replacement: '-',
            remove: /[*+~.()'"!:@]/g,
            lower: true,
            strict: true,
            trim: true
          })
        }
        setUserFunction(_.set({ ...data }, key, value))
        break;
      default:
        setUserFunction(_.set({ ...data }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <DynamicForm
            fields={generalFields}
            data={userFunction}
            onChange={handleOnChangeInput}
            errors={errors}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface UserFunctionProps {
  onCancel?: () => void;
  onSave: (userFunction: IUserFunction) => void;
  userFunction?: IUserFunction;
}

export default openUserFunctionModal;
