import { useState } from 'react';
import { ActionButton, CheckboxVisibility, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { getUserFunctionColumns } from './helper';
import { getTaskTypes } from '../../../store/admin/taskType/action';
import openTaskTypeModal, { openUserFunctionModal } from './UserFunctionDialog';
import DetailsListWapper from '../../common/DetailsListWapper';
import { IUserFunctionState } from '../../../store/admin/userFunction/reducer';
import { IUserFunction } from '@solarforschools/sfs-core/dist/settings/types';
import { deleteUserFunction, getUserFunctions, setUserFunctionFilter } from '../../../store/admin/userFunction/action';
import { PageSize } from '../common/common';
import Pagination from '../common/Pagination';

const UserFunctionList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { userFunctions, totalCount, userFunctionFilter }: IUserFunctionState = useSelector<RootState, IUserFunctionState>((state: RootState) => state.web.userFunction)

  const handleOnDelete = async (userFunction: IUserFunction) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete UserFunction",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${userFunction.name}`,
      },
    });
    if (confirm) dispatch(deleteUserFunction(userFunction));
  };

  const handleOnEdit = async (userFunction: IUserFunction) => {
    await openUserFunctionModal({ userFunction })
  };

  const headerRow = getUserFunctionColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        onClick: handleOnDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...userFunctionFilter, sortField: field, sortOrder: order };
    dispatch(setUserFunctionFilter(params));
    dispatch(getUserFunctions(params))
  }

  const loadNextPage = (p: any) => {
    dispatch(setUserFunctionFilter({ ...userFunctionFilter, ...p }))
    dispatch(getUserFunctions())
  }
  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...userFunctionFilter, page: 1, pageSize: size }
    dispatch(setUserFunctionFilter(filters))
    dispatch(getUserFunctions(filters))
  };

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={userFunctionFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>

        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () => await openUserFunctionModal({})}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp; Add User Function (Role)
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={userFunctions}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={userFunctions.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={userFunctionFilter?.page!}
            pageSize={userFunctionFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default UserFunctionList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

