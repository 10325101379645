import React, { useMemo, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Stack, Sticky, StickyPositionType, TextField } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider, useDispatch } from 'react-redux';
import { IProduct } from '@solarforschools/sfs-core/dist/product/type';
import { StringMap } from '../common/types';
import { getProductFields, initProduct, validateProduct } from './productHelper';
import DynamicForm from '../../dynamic-form/DynamicForm';
import ReactDOM from 'react-dom';
import store from '../../../store';
import ModalDialog from '../common/ModalDialog';
import { createUpdateProduct } from '../../../store/admin/product/action';

export const OpenProductModal = (props: { params: any }) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: IProduct) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={`` || "Add Edit Product"}
                    onDismiss={() => callback(undefined)}
                    containerClassName="modal-size-sm"
                >
                    <ProductModal {...props} onSave={(product) => callback(product)} onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}


export const ProductModal = ({
    params = initProduct as IProduct, onCancel, onSave,
}: Props): JSX.Element => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState<IProduct>({ ...params });
    const [errors, setErrors] = useState<any>({});

    const productFields = useMemo(() => getProductFields({ product }), [product]);

    const handleOnChangeInput = (key: string, value: any) => {
       
        switch (key) {
            case 'productId':
                setProduct({ ...product, [key]: value?.toUpperCase().replace(/\s/g, "") });
                break;

            default:
                setProduct({ ...product, [key]: value });
                break;
        }
    };
    const handleOnSave = async () => {
        const errors: StringMap = await validateProduct(product);
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        await dispatch(createUpdateProduct(product));
        onSave({ ...product });
    };
    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <DynamicForm
                        fields={productFields}
                        data={product}
                        errors={errors}
                        onChange={handleOnChangeInput} />
                </div>
                <div className='ms-Grid-row'>
                    <TextField
                        label="Description"
                        placeholder=""
                        onChange={(e: any, value: any) => handleOnChangeInput('description', value)}
                        multiline={true}
                        rows={3}
                        className='ms-Grid-col ms-lg12'
                        value={product?.description!} />
                </div>
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>
                        <PrimaryButton
                            text="Save"
                            onClick={handleOnSave}
                            className="btn-primary" />
                        <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    params?: IProduct;
    onCancel: () => void;
    onSave: (productParams: IProduct) => void;
}

type Props = OwnProps;
