import { IPipeDriveDealDailyCount } from '@solarforschools/sfs-core/dist/pipedrive/deal/type'
import { useEffect, useState } from 'react'
import Card from '../layouts'
import CardHeader from '../layouts/CardHeader'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
interface IChartData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        borderColor: string;
        backgroundColor: string;
        yAxisID: string;
        pointRadius: 0,
        pointHitRadius: 0,
    }[];
}

const UkActiveDeals = ({ data }: { data: IPipeDriveDealDailyCount[] }) => {

    const [chartsData, setChartsData] = useState<IChartData>({
        labels: [],
        datasets: [
            {
                label: 'Dataset 1',
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
                pointRadius: 0,
                pointHitRadius: 0,
            },
            {
                label: 'Dataset 2',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y1',
                pointRadius: 0,
                pointHitRadius: 0,
            },
        ],

    })

    useEffect(() => {
        if (data) {
            let data1: number[] = [], data2: number[] = [], labels:string[] = [];
            let tempData = data?.reverse()
            tempData?.forEach((elm)=>{
                labels.push(moment(elm?.date).format("DD-MM-YYYY"))
                data1.push(elm.size / 1000)
                data2.push(elm.noOfSchools)
            })
            setChartsData({
                labels,
                datasets: [
                    {
                        label: 'Size',
                        data: data1,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        yAxisID: 'y',
                        pointRadius: 0,
                        pointHitRadius: 0,
                    },
                    {
                        label: 'No of schools',
                        data: data2,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        yAxisID: 'y1',
                        pointRadius: 0,
                        pointHitRadius: 0,
                    },
                ],
            })
        }
    }, [data])



    if (!data || data?.length === 0) return <></>


    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false
            },
            tooltip: {
                intersect: false,       
             
                padding: {
                    left: 3,
                    right: 3,
                    bottom: 5,
                    top: 5
                },
                position: "nearest" as const,
                callbacks: {
                    label: function (context: any) {
                        const label = context.dataset.label;
                        const yValue = context.parsed.y?.toFixed(2);
                        const value = label === "Size" ? `${label} : ${yValue} MWp` : `${label} : ${yValue} `
                        return value;
                    },
                },
            }
        },
        scales: {
            x: {
                display: false
            },
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                }
            },
        },
    };

    return (
        <div className="dashboard-layout--pipedrive-uk-active-deal">
            <Card>
                <CardHeader title='UK deals (active)' iconName='PipeDrive' />
                <div style={{ padding: "10px" }}>
                    <Line options={options} data={chartsData} />
                </div>
            </Card>
        </div>
    )
}

export default UkActiveDeals