import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { isSortable } from "../common/utils";
import { IInvoiceDocument } from "./types";

export const getInvoiceColumns = (params: {
    actions: {
        name?: string;
        icon: any,
        title: string,
        onClick: (opportunity: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'number',
            name: 'Number',
            fieldName: 'number',
            minWidth: 90,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.number} closeDelay={500}> {item.number}</TooltipHost>
        },
        {
            key: 'contract.number',
            name: 'Contract Number',
            fieldName: 'contract.number',
            ariaLabel: "Contract Number",
            minWidth: 80,
            maxWidth: 200,
            data: 'string',
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.contract?.number} closeDelay={500}> {item.contract?.number}</TooltipHost>
        },
        {
            key: 'contract.customerName',
            name: 'Customer Name',
            ariaLabel: "Customer Name",
            fieldName: 'contract.customerName',
            minWidth: 120,
            data: 'string',
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.contract?.customerName} closeDelay={500}> {item.contract?.customerName}</TooltipHost>
        },

        {
            key: 'date',
            name: 'Date',
            fieldName: 'date',
            minWidth: 80,
            data: 'Date',
            ...isSortable,
            onRender: (item: IInvoiceDocument) => {
                return item.date ? moment(item.date).format('YYYY-MM-DD') : item.date
            }
        },
        {
            key: 'dueDate',
            name: 'Due Date',
            fieldName: 'dueDate',
            minWidth: 80,
            data: 'Date',
            ...isSortable,
            onRender: (item: IInvoiceDocument) => {
                return item.dueDate ? moment(item.dueDate).format('YYYY-MM-DD') : item.dueDate
            }
        },
        {
            key: 'cycle.name',
            name: 'Cycle',
            fieldName: 'cycle.name',
            minWidth: 60,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.cycle.name} closeDelay={500}> {item.cycle.name}</TooltipHost>
        },
        {
            key: 'cycle.start',
            name: 'Cycle Start',
            fieldName: 'cycle.start',
            ariaLabel: 'Cycle Start',
            minWidth: 80,
            data: 'Date',
            ...isSortable,
            onRender: (item) => {
                const start = item.cycle?.start ? moment(item.cycle.start).format('YYYY-MM-DD') : ''
                return (
                    <TooltipHost content={start} closeDelay={500}> {start}</TooltipHost>
                )
            }
        },
        {
            key: 'cycle.end',
            name: 'Cycle End',
            fieldName: 'cycle.end',
            ariaLabel: 'Cycle End',
            minWidth: 80,
            data: 'Date',
            ...isSortable,
            onRender: (item) => {
                const end = item.cycle?.end ? moment(item.cycle.end).format('YYYY-MM-DD') : ''
                return (
                    <TooltipHost content={end} closeDelay={500}> {end}</TooltipHost>
                )
            }
        },
        {
            key: 'totals.consumption',
            name: 'Consumption',
            fieldName: 'totals.consumption',
            minWidth: 80,
            data: 'number',
            className: 'text-right',
            headerClassName: 'text-right',
            ...isSortable,
            onRender: (item: IInvoiceDocument) => {
                return Math.round(item.totals?.consumption! * 100) / 100 || ''
            }
        },
        {
            key: 'amounts.total',
            name: 'Amount',
            fieldName: 'amounts.total',
            minWidth: 80,
            data: 'number',
            className: 'text-right',
            headerClassName: 'text-right',
            ...isSortable,
            onRender: (item: IInvoiceDocument) => {
                return Math.round(item.amounts?.total! * 100) / 100 || ''
            }
        }, {
            key: "action",
            name: "Action",
            fieldName: "-",
            minWidth: 90,
            onRender: (item: IInvoiceDocument) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: any) => {
                            if (!action.name || (action.name && item.isGenerated))
                                return <span className="action-icons" >
                                    <FontAwesomeIcon
                                        icon={action.icon}
                                        onClick={() => action.onClick(item)}
                                        title={action.title || ''} />
                                </span>
                        })
                        }
                    </div >
                );
            },
        },
    ];
}