import { ITag, Stack, TagPicker } from '@fluentui/react';
import { IEstablishment } from '@solarforschools/sfs-core';
import { IProject, ISchoolMember } from '@solarforschools/sfs-core/dist/project/types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getSchools, getSchoolsPostApi } from '../../../store/client';
import SchoolList from '../schools/ReusableSchoolList';

const ProjectSchools = (props: ProjectSchoolsProps) => {
  const [schools, setSchools] = useState<any[]>(_.cloneDeep(props.project?.schools || []))
  const actions = [
    {
      icon: "Delete",
      onClick: handleRemoveSchool
    }
  ]
  const loadData = async (schools?: any) => {
    if (props.project?.schools?.length || schools?.length) {
      const slugs = schools?.length ? schools.map((s: { slug: any; }) => s.slug) : props.project.schools.map(s => s.slug)
      const data = await getSchoolsPostApi({ slug: slugs, all: true });
      setSchools(data)
    }
  }
  useEffect(() => {
    loadData();
  }, [])

  function handleRemoveSchool(school: IEstablishment) {
    setSchools(schools => {
      const _schools = schools.filter(s => s.slug !== school.slug);
      props.onUpdateProject('schools', _schools)
      return _schools;
    });
    // //props.onUpdateProject('schools', props.project?.schools?.filter((x: ISchoolMember) => school.slug !== x.slug));
    // setSchools([...schools.filter(s => s.slug !== school.slug)])
    // console.log(schools)
    // props.onUpdateProject('establishments', props.project?.establishments?.filter(x => school.slug !== x.slug));
  }

  const handleGetSchools = async (
    filter: string,
    selectedItems?: ITag[],
  ): Promise<ITag[]> => {
    try {
      const schools = await getSchools({ name: filter, status: "Open" })
      const selectedMap: { [key: string]: boolean } = {};
      props.project.establishments?.forEach((i: IEstablishment) => selectedMap[i.slug] = true);
   

      return schools
        .filter(school => !selectedMap[school.slug])
        .map(school => {
          const {address: {city, zipcode}} = school
          let name: string[] | string = [school.name]
          if(city)  name.push(city)
          if (zipcode) name.push(zipcode)
          name = name.join(", ")
        
          return {
            key: school.slug,
            name,
            data: school,
          }
        })
    } catch (error) {
      return [] as ITag[]
    }

  }

  const handleAddSchool = async (items?: ITag[]) => {
    if (!items) return;
    const data = props.project.schools ? [...props.project.schools] : []
    const school = (items[0] as any).data
    data.push({
      _id: school._id,
      name: school.name,
      slug: school.slug,
    } as ISchoolMember)
    props.onUpdateProject('schools', data);
    setSchools([...schools, school]);
  }

  const handleUpdated = (schools: IEstablishment[]) => {
    setSchools([...schools]);
    loadData(schools)
    //props.onUpdateProject('schools', schools);
  }

  return (
    <Stack>
      <TagPicker
        styles={{ root: { marginTop: 5, marginBottom: 20 } }}
        inputProps={{ placeholder: 'School search' }}
        onResolveSuggestions={handleGetSchools}
        selectedItems={[]}
        selectionAriaLabel={'Selected Schools'}
        onChange={handleAddSchool}
      />
      <p>Total Schools: {schools.length || 0}</p>
      <SchoolList
        schools={schools}
        actions={['opportunity']}
        extraActions={actions}
        onUpdated={handleUpdated}
      />
    </Stack>
  );
}

interface ProjectSchoolsProps {
  project: IProject
  onUpdateProject: (key: string, value: any) => void
}

export default ProjectSchools;
