import { GenerateTypeAction } from "./actionTypes";
import {
  IFileDetails,
  IFilterProjects,
  IFilterSchools,
} from "../../../components/admin/generateReport/types";
import { ITemplate } from "@solarforschools/sfs-core/dist/types";

export interface IGenerateReport {
  schoolReports: {
    regions: string[];
    schools: IFilterSchools[];
    templates: ITemplate[];
  };
  fileDetails: IFileDetails | {};
  projectReports: {
    regions: string[];
    projects: IFilterProjects[];
    templates: ITemplate[];
  };
  calculatorReports: {
    regions: string[];
    projects: IFilterProjects[];
    templates: ITemplate[];
  };
}

const initialState: IGenerateReport = {
  schoolReports: {
    regions: [],
    schools: [],
    templates: [],
  },
  fileDetails: {},
  projectReports: {
    regions: [],
    projects: [],
    templates: [],
  },
  calculatorReports: {
    regions: [],
    projects: [],
    templates: [],
  },
};

const generateReport = (
  state = initialState,
  action: GenerateTypeAction
): IGenerateReport => {
  switch (action.type) {
    case "SET_REGIONS":
      const regionsPayload = action.payload;

      return {
        ...state,
        schoolReports: { ...state.schoolReports, regions: regionsPayload },
      };

    case "SET_SCHOOLS":
      const schoolsPayload = action.payload;

      return {
        ...state,
        schoolReports: { ...state.schoolReports, schools: schoolsPayload },
      };

    case "SET_REGIONS_FOR_CALC":
      const regionPayload = action.payload;

      return {
        ...state,
        calculatorReports: {
          ...state.calculatorReports,
          regions: regionPayload,
        },
      };

    case "SET_PROJECTS_FOR_CALC":
      const payload1 = action.payload;
      console.log("called")

      return {
        ...state,
        calculatorReports: { ...state.calculatorReports, projects: payload1 },
      };

    case "SET_TEMPLATES_FOR_CALC":
      const payload3 = action.payload;

      return {
        ...state,
        calculatorReports: {
          ...state.calculatorReports,
          templates: payload3
        },
      };

    case "SET_REGIONS_FOR_PROJECT":
      const projectRegions = action.payload;

      return {
        ...state,
        projectReports: {
          ...state.projectReports,
          regions: projectRegions as string[],
        },
      };

    case "SET_PROJECTS":
      const projects = action.payload;
      return {
        ...state,
        projectReports: {
          ...state.projectReports,
          projects: projects,
        },
      };
    case "SET_TEMPLATES":
      const templatePayload = action.payload;

      return {
        ...state,
        schoolReports: {
          ...state.schoolReports,
          templates: templatePayload,
        },
      };

    case "SET_TEMPLATES_FOR_PROJECT":
      const templatesPayload = action.payload;
      return {
        ...state,
        projectReports: {
          ...state.projectReports,
          templates: templatesPayload,
        },
      };

    case "SET_FILES":
      const filePayload = action.payload;

      return { ...state, fileDetails: filePayload };

    case "RESET_STATE":
      return initialState;

    default:
      return state;
  }
};

export default generateReport;
