import React, { useState } from "react";

const Label = ({
  name,
  fontSize,
  color = "var(--solar-blue)",
  style = {},
}: labelProps) => {
  const [labelStyle, setLabelStyle] = useState<React.CSSProperties>({
    fontSize,
    fontWeight: "bold",
    color,
    marginTop: "1.5rem",
    ...style,
  });
  return (
    <>
      <label style={labelStyle}>{name}</label>
    </>
  );
};

export default Label;

export interface labelProps {
  name: string;
  fontSize: string;
  color?: string;
  style?: React.CSSProperties;
}
