import { ChoiceGroup } from '@fluentui/react'
import { CSSProperties, useState } from 'react'
import Loader from '../common/Loader'
import ProjectFilter from './ProjectFilter'
import SchoolsFilter from './SchoolsFilter'
import DisplayFiles from './DisplayFiles'
import { useSelector } from 'react-redux'
import { IGenerateReport } from '../../../store/admin/generateReport/reducers'
import { RootState } from '../../../store'
import UpdateReportTemplateCache from './UpdateReportTemplateCach'
import { reportTypes } from './helper'
import CalculatorFilter from './CalculatorFilter'

type reportTypes = "school" | "project"

const GenerateReport = () => {

    const { schoolReports, projectReports } = useSelector<RootState, IGenerateReport>((state: RootState) => state.web.generateReport)

    const [renderFile, setRenderFile] = useState<1 | 2 | 3>(1) // 1-> school, 2-> project and 3-> null
    const [type, setType] = useState<reportTypes | "">("")
 

    const overAllLayout: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
    }


    function renderGeneratedDocuments() {
        if (renderFile === 1) {
            if (schoolReports.regions.length === 0) return <></>
            else return <DisplayFiles reportType="school" />
        } else if (renderFile === 2) {
            if (projectReports.regions.length === 0) return <></>
            else return <DisplayFiles reportType="project" />
        } else return <></>
    }

    const handleReportTypeChange = (key: string, value: any) => {
        if (value === "SELECT" || value === type) {
            return
        }
        setRenderFile((!value || value === "school") ? 1 : (value === "project" ? 2 : 3))
        setType(value)
    }

    // Component maping
    const ReportFilter = {
        "school": <SchoolsFilter />,
        "project": <ProjectFilter />,
        "calculator": <CalculatorFilter />
    }


    return (
        <>
            <div style={overAllLayout}>
                <Loader />
                <div style={{ textAlign: "right" }}>
                    <UpdateReportTemplateCache />
                </div>
                <div>
                    <header className="table-header">
                        <h1>Generate Report</h1>
                    </header>
                </div>

                <div>
                    <div className='flex-row-center'>
                        <ChoiceGroup
                            className='inlineflex'
                            selectedKey={type || reportTypes[0].key}
                            options={reportTypes}
                            onChange={(e, elm) => handleReportTypeChange("reportTypes", elm?.key?.toString())}
                        />
                    </div>
                </div>
                {!type ? <SchoolsFilter /> : ReportFilter[type]}
                {renderGeneratedDocuments()}
            </div>

        </>
    )
}

export default GenerateReport