import { CheckboxVisibility, IColumn, ScrollablePane, ScrollbarVisibility, SelectionMode, Shimmer, Text, TextField } from '@fluentui/react'
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react'
import React, { useCallback, useEffect, useState } from 'react'
import { IComments } from '../../../store/admin/Comments/reducer';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import DetailsListWapper from '../../common/DetailsListWapper';
import { getComments } from './Helpers';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { openViewComment } from './ViewComments';
import { openEditComment } from './EditComments';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComments, removeComment, setCommentFilter, updateCommentData } from '../../../store/admin/Comments/actions';
import { RootState } from '../../../store';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { IComments as IUniqueComment } from "@solarforschools/sfs-core/dist/comment/types";
import Pagination from '../common/Pagination';
import { PageSize } from '../common/common';
import { ICommentsfilter } from './type';


interface InputFormValues {
    schoolName: string;
    type: string | undefined;
}

interface IProps {
    options: any
}

const CommentsForm = (props: IProps) => {

    const { commentsDetails, isLoading, commentFilter }: IComments = useSelector<RootState, IComments>((state: RootState) => state.web.comments)

    const { options } = props

    const dispatch = useDispatch()

    const setData = (filter: ICommentsfilter) => {
        dispatch(setCommentFilter(filter))
        dispatch(fetchComments(filter))
    }

    const handleChange = (e: React.FormEvent<HTMLElement | HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        const { value } = e?.target as HTMLInputElement;
        const filter: ICommentsfilter = { ...commentFilter, schoolName: value, page: 1 }

        setData(filter)
    }

    // View Comment - Modal
    const handleDetailView = async (comment: IUniqueComment) => {
        if (comment._id) {
            await openViewComment(comment) // No further action needed
        }
    }

    // Delete Comment - Modal
    const handleDeleteComment = async (comment: IUniqueComment) => {
        if (comment?._id === undefined) return
        const confirm = await ConfirmDialog({
            dialogContentProps: {
                title: 'Confirm',
                closeButtonAriaLabel: 'Close',
                subText: `Are You Sure To Delete This Comment?`,
            },
            confirmBtnText: 'OK'
        })
        if (confirm) {
            dispatch(removeComment(comment._id))
        }

    }

    // Edit Comment - Modal
    const handleEditComment = async (comment: IUniqueComment) => {
        if (comment._id) {
            const actualComment = cloneDeep(comment)
            const resultantComment = await openEditComment(actualComment)
            if (resultantComment) {
                if (isEqual(comment, resultantComment)) return
                dispatch(updateCommentData(resultantComment))
            }


        }
    }

    // Render Columns
    const [columns] = useState<IColumn[]>(getComments({
        actions: [
            {
                icon: 'RedEye',
                ariaLabel: 'View Comment',
                onClick: handleDetailView,
            },
            {
                icon: 'Edit',
                ariaLabel: 'Edit Comment',
                onClick: handleEditComment,
            },
            {
                icon: 'Delete',
                ariaLabel: 'Delete Comment',
                onClick: handleDeleteComment,
            }
        ]
    }))



    const handleDropDownChange = (e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
        const filter: ICommentsfilter = { ...commentFilter, type: option?.key.toString()!, page: 1 }
        setData(filter)

    }

    // To load next page : only access if loadMore state is true
    const loadNextPage = (page: number) => {
        const filter: ICommentsfilter = { ...commentFilter, page }
        setData(filter)
    }


    // HandleSorting on user click the header
    const handleSort = (field: any, sort: any) => {
        const filter = { ...commentFilter, sortField: field, sortOrder: sort }

        setData(filter)
    }

    // Shows -> Not Found or Loading... text
    const FallBackMsg = () => {
        return (
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
                <Text variant='medium'>
                    {isLoading ? "Please Wait..." : "No Data Found"}
                </Text>
            </div>
        )
    }

    const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: any) => {
        const size: number = (item?.key && parseInt(item?.key.toString())) || 10
        const filter: ICommentsfilter = { ...commentFilter, pageSize: size, page: 1 }
        setData(filter)
    };

    // Count of Comments State with Total Comments
    const getCommentsCount = () => {
        const dataCount = commentsDetails?.comments?.length || 0; // FallBack
        const overAllDataCount = commentsDetails?.total || 0; //Fallback
        return (
            <span
                style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
            >
                {overAllDataCount > 0 &&
                    <>
                        Displaying&nbsp;
                        <Dropdown
                            selectedKey={commentFilter.pageSize?.toString() || "10"}
                            options={PageSize}
                            styles={{ dropdown: { width: "80px" } }}
                            onChange={handleOnChangePageSize}
                        />
                        &nbsp;/ {overAllDataCount}
                    </>}
            </span>
            // <Text variant='medium' style={{ margin: "0.5rem" }}>
            //     Comments: <span style={{ fontWeight: "bold" }}> <Dropdown
            //         selectedKey={pageSize?.toString() || "10"}
            //         options={PageSize}
            //         styles={{ dropdown: { width: "80px" } }}
            //         onChange={handleOnChangePageSize}
            //       />
            //       &nbsp;/{overAllDataCount} </span>
            // </Text>
        )
    }


    return (
        <>
            <div className='flex-row-center'>
                <TextField
                    label='School Name'
                    placeholder='Enter School Name'
                    type='text'
                    className="ms-Grid-col ms-lg3"
                    defaultValue={commentFilter.schoolName}
                    name='schoolName'
                    onChange={handleChange}
                />
                <Dropdown
                    label='Comments Type'
                    options={options}
                    className="ms-Grid-col ms-lg3"
                    defaultValue={commentFilter.type}
                    onChange={handleDropDownChange}
                    selectedKey={commentFilter.type}
                />

            </div>

            {
                (commentsDetails?.comments.length > 0 ? <>
                    {getCommentsCount()}
                    <div className='data-list-container'>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <DetailsListWapper
                                columns={columns}
                                onRenderDetailsHeader={DetailListStickyHeader}
                                selectionMode={SelectionMode.none}
                                compact={true}
                                items={commentsDetails.comments}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                // canLoadMore={loadMore}
                                // onRequestLoadMore={loadNextPage}
                                onSort={(field, sort) => handleSort(field, sort)}
                            />
                        </ScrollablePane>
                    </div>
                    <Pagination
                        totalRecords={commentsDetails.total}
                        onPaginate={(page) => loadNextPage(page)}
                        currentPage={commentFilter.page!}
                        pageSize={commentFilter.pageSize!}
                    />
                </> : <> {FallBackMsg()} </>)
            }
        </>
    )
}

export default CommentsForm
