import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import ModalDialog from "../common/ModalDialog";
import ReactDOM from "react-dom";
import { ILiveMeterDumpsState } from "../../../store/admin/LiveDumps/reducer";
import { DatePicker, DefaultButton, Dropdown, IComboBoxStyles, IDropdownOption, PrimaryButton, Stack, Sticky, StickyPositionType, TimePicker } from "@fluentui/react";
import moment from "moment";
import { dropdownStyles } from "../common/common";
import { useState } from "react";
import { swapIAndETSReadings } from "../../../store/admin/tsReading/action";
import MSNPicker from "../common/MSNPicker";
import _ from "lodash";

interface IProps {
    meterId?: string;
    readingDate?: string;
}

export const OpenSwapIAndEDialog = (props: any) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Swap Import and Export"}
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-sm"}
                >
                    <LiveMeterDialog {...props} onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}

const LiveMeterDialog = (props: any) => {
    const dispatch = useDispatch();
    const [payload, setPayload] = useState({
        start: moment().subtract(2, 'day').format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD"),
        msn: null,
        startTime: moment().minutes(Math.round(moment().minutes() / 5) * 5).format('HH:mm'),
        endTime: moment().minutes(Math.round(moment().minutes() / 5) * 5).format('HH:mm')
    } as any)

    const handleOnSave = async () => {
        try {
            dispatch(swapIAndETSReadings(payload))

        } catch (error) {
            console.log(error)
        } finally {
            // props.onCancel()
        }
    }
    const handleOnChangeInput = (key: string, value: any) => {
        let data = { ...payload }
        switch (key) {
            case 'selectedMSN':
                data = { ...data, msn: value[0]?.key }
                break
            default:
                data = _.set({ ...data }, key, value)
                break;
        }

        setPayload(data)
    }

    const { start, end, MSN } = payload
    const timePickerStyles: Partial<IComboBoxStyles> = {
        optionsContainerWrapper: {
            height: '200px',
        },
        root: {
            width: '200px',
        },
    };

    return (
        <div className="edit-record">
            <div className="ms-Grid" dir="ltr">

                <>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg6 margin-top-xsm">
                            <MSNPicker selectedItems={[]} filters={{ type: ["I", "E"] }} onChange={handleOnChangeInput} />
                        </div>
                        <div className="ms-Grid-col ms-lg6">
                        </div></div>
                    <div className="ms-Grid-row">
                        <DatePicker
                            label="Start Date"
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            value={new Date(moment(start).format('YYYY-MM-DD'))!}
                            onSelectDate={(date: any) => setPayload({ ...payload, start: moment(date).format('YYYY-MM-DD') })}
                            styles={dropdownStyles}
                            className="ms-Grid-col ms-lg6"
                        />
                        <TimePicker
                            label="End Time"
                            className="ms-Grid-col ms-lg2"
                            styles={timePickerStyles}
                            useHour12={false}
                            increments={1}
                            showSeconds={false}
                            onChange={(e, time) => setPayload({ ...payload, startTime: time?.key })}
                        />
                    </div>
                    <div className="ms-Grid-row">
                        <DatePicker
                            label="End Date"
                            placeholder="Select End Date..."
                            ariaLabel="Select  End Date"
                            value={new Date(moment(end).format('YYYY-MM-DD'))!}
                            onSelectDate={(date: any) => setPayload({ ...payload, end: moment(date).format('YYYY-MM-DD') })}
                            styles={dropdownStyles}
                            className="ms-Grid-col ms-lg6"
                        />
                        <TimePicker
                            label="End Time"
                            className="ms-Grid-col ms-lg2"
                            styles={timePickerStyles}
                            useHour12={false}
                            increments={1}
                            showSeconds={false}
                            onChange={(e, time) => setPayload({ ...payload, endTime: time?.key })}
                        />
                    </div>
                    <div className="ms-Grid-row">
                        <i>All time is utc format</i>
                    </div>
                </>
            </div>
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item className="margin-top-lg">
                        {payload.msn &&
                            <PrimaryButton
                                text="Swap"
                                onClick={() => handleOnSave()}
                                className="btn-primary"
                            />}
                        <DefaultButton onClick={() => props.onCancel()} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
}
