import Card from "../layouts";
import CardHeader from "../layouts/CardHeader";
import { IAdminDashboard } from "@solarforschools/sfs-core";
import CardFooter from "../layouts/CardFooter";
import {
  FrontFooter as EthexFrontFooter,
  BackFooter as EthexBackFooter,
} from "./footer/EthexFlipFooter";
import EthexCardContent from "./contents/EthexCardContent";

const Ethex = (props: Partial<IAdminDashboard>) => {

  if (props.ethex === undefined) return <></>;

  const ethex = props.ethex[props.ethex?.length - 1];
  let isAllDefault =
    ethex?.noOfInvestors === "" ||
    ethex?.offer.closeDate === "" ||
    ethex?.offer.openDate === "" ||
    ethex?.raisedSoFar === "" ||
    ethex?.targetAmt === "";
  if (isAllDefault) {
    return <></>;
  }
  const title = `SFS Offer ${ethex?.id}`
  return (
    <div className="dashboard-layout--ethex">
      <Card ethexData={ethex}>
        <CardHeader title={title} iconName="Ethex" />
        {/* <CardContent variant='Plain' children={<EthexCardContent />} /> */}
        <EthexCardContent />
        <CardFooter
          variant="Flipper"
          frontFooter={EthexFrontFooter}
          backFooter={EthexBackFooter}
        />
      </Card>
    </div>
  );
};

export default Ethex;
