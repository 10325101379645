import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { DatePicker, Dropdown, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../../store';
import ModalDialog from '../../common/ModalDialog';
import { bondTabs, validateBondRepayment } from './BondHelper';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import { processBondRepaymet } from '../../../../store/admin/funder/bond/action';
import { dropdownStyles } from '../../common/common';
import { pyamentPreferencesTypes } from '../helper';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import moment from 'moment';
import { format } from 'path';


export const OpenRepaymentModal = (props: OwnProps) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: any) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Bond Repayment"}
          onDismiss={() => callback(undefined)}
          containerClassName="modal-size-sm"
        >
          <RepaymentDialog
            {...props}
            onSave={bond => callback(bond)}
            onCancel={() => callback(undefined)} />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

export const RepaymentDialog = ({
  bond,
  onSave,
  onCancel,
}: Props) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({ dueDate: new Date(), paymentPreference: bond.repaymentPreference, bondId: bond._id });

  const [errors, setErrors] = useState<any>({});
  const handleOnChangeInput = (key: string, value: any) => {
    const _data = _.set({ ...data }, key, value);
    setData(_data);
  };

  const handleSave = async () => {

    const errors = await validateBondRepayment(data);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    dispatch(processBondRepaymet(data))
    onSave!({ success: true });
  };



  return (
    <div className="edit-record">
      <div className="ms-Grid margin-top-md" dir="ltr" style={{ textAlign: 'center' }}>
        <h4 >Warning!</h4>
      </div>
      <div>
        <p>On re payment bond,</p>
        <ul>
          <li>All 'Open' interst transactions will mark "Cancelled".</li>
          <li>New interest transaction will be created till Due date</li>
          <li>Finally new interest and repayment transaction will marked "Closed"</li>
          <li>Note: for early repayment bond payment preference should not be "Reinvest"</li>
        </ul>
      </div>
      <div className="ms-Grid-row">
        <DatePicker
          label="Due Date"
          placeholder="Select a date..."
          ariaLabel="Select a date"
          value={new Date(data?.dueDate!)}
          className="inlineflex ms-Grid-col ms-lg6"
          onSelectDate={(date) => handleOnChangeInput('dueDate', moment(date).format("YYYY-MM-DD") as any)}
          styles={dropdownStyles}
        />
        <Dropdown
          dropdownWidth='auto'
          label="Re Payment Preferences"
          onChange={(e, item) => handleOnChangeInput("paymentPreference", item?.key?.toString())}
          selectedKey={data.paymentPreference || 'cheque'}
          options={pyamentPreferencesTypes.filter(p => !["all", "reinvest"].includes(p.key))}
          styles={dropdownStyles}
          className="inlineflex ms-Grid-col ms-lg6"
          errorMessage={errors?.paymentPreference}
        />
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Re Payment"
              onClick={handleSave}
              className="btn-primary"
            />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface OwnProps {
  onCancel?: () => void
  bond: IBond,
  onSave?: (data: any) => void
}

type Props = OwnProps;
