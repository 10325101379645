import { IAnalysis } from "@solarforschools/sfs-core";
import { Action } from "./actionTypes";
import { IDesingsBasedOnSchool } from "@solarforschools/sfs-core/dist/design/types";
import { ISchools } from "../../../components/admin/costSummary/types";
import { IOpexRes } from "@solarforschools/sfs-core/dist/analysis/types";
import { IModifiedRegionCapex } from "@solarforschools/sfs-core/dist/settings/types";
import { ICapex } from "@solarforschools/sfs-core/dist/analysis/types";
import { IOpex } from "@solarforschools/sfs-core/dist/analysis/types";

export interface ICostSummary {
  regions: string[];
  schoolsDetails: ISchools[];
  designs: IDesingsBasedOnSchool[];
  designsResult: IAnalysis[];
  regionCapexData: IModifiedRegionCapex[];
  regionOpexData: IOpexRes[];
}

const initialState: ICostSummary = {
  schoolsDetails: [],
  regions: [],
  designs: [],
  designsResult: [],
  regionCapexData: [],
  regionOpexData: [],
};

const costSummary = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_SCHOOLS":
      return { ...state, schoolsDetails: action.payload };

    //! For Dynamic Region
    case "SET_REGIONS":
      return { ...state, regions: action.payload };
    case "SET_DESIGNS":
      return { ...state, designs: action.payload };

    case "SET_DESIGN_RESULT":
      return { ...state, designsResult: action.payload };

    case "SET_REGION_CAPEX_OPEX":
      return {
        ...state,
        regionCapexData: action.payload.capex,
        regionOpexData: action.payload.opex,
      };
    case "RESET_STATE":
      return {
        ...state,
        designs: [],
        designsResult: [],
        regionCapexData: [],
        regionOpexData: [],
      };

    case "RESET_OPEX":
      return {
        ...state,
        regionCapexData: [],
      };

    case "UPDATE_DESIGN_RESULT": // -> Update CAPEX, OPEX Actuals in designResult
      const updatedDesignResult = [...state.designsResult];
      (updatedDesignResult[0].actuals as unknown as ICapex[]) =
        action.payload.capex;
      (updatedDesignResult[0].opexActuals as unknown as IOpex) =
        action.payload.opex;
      return { ...state, designsResult: updatedDesignResult };

    case "UPDATE_DESIGN_RESULT_INVOICE":
      const updateThisDesigns = [...state.designsResult];
      updateThisDesigns[0]["invoice"] = action.payload;
      return { ...state, designsResult: updateThisDesigns };

    default:
      return state;
  }
};

export default costSummary;
