import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchInvoices, setInvoiceFilter } from '../../../store/admin/invoice/action';
import { IInvoice } from '../../../store/admin/invoice/reducer';
import { FundingOptions, comboBoxStyles } from '../common/common';
import InvoiceCyclePicker from '../common/InvoiceCyclePicker';
import { IInvoicePrice } from '../../../store/admin/invoicePrice/reducer';
import { fetchInvoicePrices, setInvoicePriceFilter } from '../../../store/admin/invoicePrice/action';

const InvoicePriceFilters = () => {
  const dispatch = useDispatch()
  const { invoicePriceFilter }: IInvoicePrice = useSelector<RootState, IInvoicePrice>((state: { web: { invoicePrice: IInvoicePrice; }; }) => state.web.invoicePrice)

  const handleChange = async (key: string, value: string | undefined | any) => {
    let params = { ...invoicePriceFilter, page: 1 };
    switch (key) {
      case 'selectedCycle':
        params = { ...params }
        break
      default:
        params = { ...params, [key]: value }
        break;
    }
    dispatch(setInvoicePriceFilter(params));
    handleUpdateFilters(params)
  }

  const handleUpdateFilters = useCallback((filters: any) => { dispatch(fetchInvoicePrices(filters)) }, [])

  const { year, customerName, customerNumber, funder} = invoicePriceFilter

  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{ minWidth: "700px", maxWidth: "1300px" }}
    >
      <div className="ms-Grid-row">
        <TextField
          label="Customer Name"
          placeholder='Customer Name'
          type='text'
          onChange={(e, value) => handleChange('customerName', value)}
          value={customerName!}
          className="ms-Grid-col ms-lg3"
        />
        <TextField
          label="Contract Num"
          placeholder='Contract Number'
          type='text'
          onChange={(e, value) => handleChange('customerNumber', value)}
          value={customerNumber!}
          className="ms-Grid-col ms-lg2"
        />
        <TextField
          label="Year"
          placeholder='Year'
          type='number'
          onChange={(e, value) => handleChange('year', value)}
          value={year!}
          className="ms-Grid-col ms-lg2"
        />
        <Dropdown
          label="Funder"
          placeholder="Choose Funder"
          selectedKey={funder || 'all'}
          onChange={(e, item) => handleChange('funder', item?.key?.toString() || 'all')}
          options={FundingOptions as IDropdownOption[]}
          styles={comboBoxStyles}
          className="ms-Grid-col ms-lg3"
        />
      </div>
    </div>
  );
}

interface InvoiceFilterProps {

}

export default InvoicePriceFilters;

