import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IAdmin } from "../../../store/admin/reducer";
import { IContent } from "../../../store/content/reducer";
import Block from "./Block";

const Modules = ({ content }: ModulesProp) => {
  const admin: IAdmin = useSelector<RootState, IAdmin>((state) => state.web.admin)
  return (
    <div className="modules">
      {!admin.isLoading &&
        content.units &&
        content.units.map((unit: any) => {
          const { id, name, url, isQuiz } = unit;
          return (
            <Block
              key={id}
              name={name}
              url={url}
              id={id}
              isQuiz={isQuiz}
              isUnit={true}
            />
          );
        })}
      <Block
        name="Add New"
        url=""
        isNew={true}
        id={new Date().getTime().toString()}
      />
    </div>
  );
};

export interface ModulesProp {
  content: IContent;
}

export default Modules;
