
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ITranslations } from './utils/types';
import { fetchTranslationHistory, handleDraftCreation, setPanelOpen } from '../../../store/admin/translations/actions';
import { DefaultButton, Icon, Shimmer, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { useRef } from 'react';
import useFetch from '../../../hooks/useFetch';
import { IParams } from '@solarforschools/sfs-core/dist/translations/types';
import moment from 'moment';
import { IAdmin } from '../../../store/admin/reducer';

const ViewHistory: React.FunctionComponent<{ panelType: PanelType }> = props => {
    const { panelType } = props;

    const { isPanelOpen, history: { data, pageNumber, hasLoadMore, isLoading } } = useSelector<RootState, ITranslations>((state) => state.web.translations);

    const admin: IAdmin = useSelector<RootState, IAdmin>(
        (state: RootState) => state.web.admin
    );
    const dispatch = useDispatch()
    const elmRef = useRef<HTMLDivElement>(null)
    const hasApprovalPermission = admin.user?.permission?.includes("translationApprover")


    const handleClosePanel = () => {
        dispatch(setPanelOpen(false))
    }

    const handleRestore = ({ key, language, value, docId }: Partial<IParams>) => {
        if (hasApprovalPermission) dispatch(handleDraftCreation({ key, language, value, docId }, false, true))
    }

    // actual fetch logic is here
    const dispatchFunction = () => {
        const { language, _id } = data[0]
        dispatch(fetchTranslationHistory(_id, language, pageNumber))
    }

    // custom hook for infinite scrolling
    useFetch(elmRef, dispatchFunction, hasLoadMore, data)

    return (
        <div>
            <Panel
                isOpen={isPanelOpen}
                onDismiss={handleClosePanel}
                type={panelType}
                closeButtonAriaLabel="Close"
            >
                <div>
                    <h2 style={{ color: "var(--solar-blue)" }}>
                        Translation History
                    </h2>
                    <div className='high-opacity' style={{ margin: "1rem 0 2.5rem 0" }}>
                        <hr />
                    </div>
                </div>

                {
                    isLoading && <div className='flex-row-center' style={{ height: "60vh" }}> <Spinner size={SpinnerSize.medium} /></div>
                }
                {
                    !isLoading && (
                        data.length > 0 ? (
                            <>
                                <div className='history-panel'>
                                    {
                                        data.map((elm, index) => {
                                            return (<div key={index} className='card-layout'>

                                                <div className='card-content'>
                                                    <div style={{ alignSelf: "flex-start" }}>
                                                        <svg fill="#243f61" width="45px" height="45px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M228,128A100,100,0,1,0,60.71,201.90967a3.97048,3.97048,0,0,0,.842.751,99.79378,99.79378,0,0,0,132.8982-.00195,3.96558,3.96558,0,0,0,.83813-.74756A99.76267,99.76267,0,0,0,228,128ZM36,128a92,92,0,1,1,157.17139,64.87207,75.616,75.616,0,0,0-44.50782-34.04053,44,44,0,1,0-41.32714,0,75.61784,75.61784,0,0,0-44.50782,34.04A91.70755,91.70755,0,0,1,36,128Zm92,28a36,36,0,1,1,36-36A36.04061,36.04061,0,0,1,128,156ZM68.86475,198.417a68.01092,68.01092,0,0,1,118.27.00049,91.80393,91.80393,0,0,1-118.27-.00049Z" />
                                                        </svg>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div style={{ textAlign: "justify" }}>
                                                            <Text variant='medium' className='solar-blue'>
                                                                {elm.translation}
                                                            </Text>
                                                        </div>

                                                        <div>
                                                            <div className='history-card-divider'>
                                                                <hr />
                                                            </div>

                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <div className='medium-opacity'>
                                                                    <div>
                                                                        <Text variant='medium' className='solar-blue'> {elm.isDeleted ? "Deleted by " : "Updated by "}<span style={{ fontWeight: 600 }}>{elm.createdBy}</span></Text>
                                                                    </div>
                                                                    <div>
                                                                        <Text variant='medium' className='solar-blue'>{moment(elm.createdAt).local().format("D MMM YYYY HH:mm:ss")}</Text>
                                                                    </div>

                                                                </div>

                                                                {
                                                                    hasApprovalPermission &&
                                                                    <div title='Restore' style={index === 0 ? { visibility: "hidden" } : { visibility: "visible", cursor: "pointer" }} >
                                                                        <DefaultButton
                                                                            style={{
                                                                                borderRadius: "0.25rem",
                                                                                border: "1px solid var(--solar-blue)"
                                                                            }}
                                                                            className='solar-blue'
                                                                            onClick={
                                                                                () => handleRestore({ key: elm.key, docId: elm._id, value: elm.translation, language: elm.language })}>Restore</DefaultButton>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    (index !== data.length - 1) && <div className='text-center'>
                                                        <Icon iconName='SortUp' style={{
                                                            margin: "1rem",
                                                            fontSize: "1.5rem",
                                                            color: "#ccc"
                                                        }} />
                                                    </div>
                                                }

                                            </div>)
                                        })
                                    }

                                    {hasLoadMore && <div ref={elmRef}>
                                        {
                                            data.length > 0 && <Shimmer width="100%" />
                                        }
                                    </div>}

                                </div>

                            </>
                        ) : <div className='text-center solar-blue' style={{ margin: "2rem" }}>
                            <Text variant='medium'>No History Found</Text>
                        </div>
                    )
                }
            </Panel>
        </div>
    );
};


export const HistoryPanel = () => {

    return (
        <div>
            <ViewHistory panelType={Number(String(PanelType.medium))} />
        </div>
    );
};
