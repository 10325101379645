import Card from '../layouts/index'
import CardHeader from '../layouts/CardHeader'
import CardFooter from '../layouts/CardFooter'
import CardContent from '../layouts/CardContent'
import { BackFooter, FrontFooter } from "./footer/ElecGenFlipFooter"
import { BackContent, FrontContent } from './contents/ElecGenFlipContent'
import { IAdminDashboard } from "@solarforschools/sfs-core";

const TeacherPortalActivity = (props: Partial<IAdminDashboard>) => {

    if(props?.electricityGenerated === undefined) return <></>
    
    return (
        <div className="dashboard-layout--electrictyGenerated">
            <Card electricityGenData={props?.electricityGenerated}>
                <CardHeader title='Electricity Generated' iconName='Electricity' />
                <CardContent variant="Flipper" title='Overall Electricity Generated' frontContent={FrontContent} backContent={BackContent} />
                <CardFooter variant="Flipper" frontFooter={FrontFooter} backFooter={BackFooter} />
            </Card>
        </div>
    )
}

export default TeacherPortalActivity