import { IWorkflow } from "@solarforschools/sfs-core/dist/workflow/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

export const getProjectFlowsApi = (query: any): Promise<any> => {
  return axios
    .get(
      `${host}/project/workflow?${generateQueryString(
        query as ParsedUrlQueryInput
      )}`
    )
    .then((res) => res.data);
};

export const createWorkflowAPi = (workflow: IWorkflow): Promise<any> => {
  return axios
    .post(`${host}/project/workflow`, workflow)
    .then((res) => res.data);
};

export const updateWorkflowAPi = (workflow: IWorkflow): Promise<any> => {
  return axios
    .put(`${host}/project/workflow/${workflow._id}`, workflow)
    .then((res) => res.data);
};

export const deleteWorkflowAPi = (workflow: IWorkflow): Promise<any> => {
  return axios
    .delete(`${host}/project/workflow/${workflow._id}`)
    .then((res) => res.data);
};

export const validateWorkflowIdAPi = (workflow: IWorkflow): Promise<any> => {
  return axios
    .put(`${host}/project/workflow/validate/workflowId`, workflow)
    .then((res) => res.data);
};
