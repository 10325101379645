import Moment from 'moment'
import { ReadingTypes } from '../common/common'

export const MapReadingTyp = (readingType: string) => {
    const rt = ReadingTypes.find(rt => rt.key === readingType.toString())
    return rt?.text || ''
}

export const InitBillingCycle = () => {
    return {
        period: `${Moment().year()}Q${Moment().quarter()}`,
        start: new Date(Moment().startOf('quarter').format('YYYY-MM-DD')),
        end: new Date(Moment().endOf('quarter').format('YYYY-MM-DD')),
        readingType: 5
    }
}

export const opportunityTypes = [
    { key: 'all', text: 'All'} ,
    { key: 'School', text: 'School'} ,
    { key: 'Project', text: 'Project'} ,
]
