import { Line } from "react-chartjs-2";

interface IChartProps {
    chartLabels?: string[]
    chartData?: number[]
    value?: number
}

const LineChart = (props: IChartProps) => {

    const { chartLabels, chartData, value } = props

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: "Count",
                data: chartData,
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                borderColor: "#909090",
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#EC6B56",
                    "#47B39C",
                    "#9552EA",
                    "#378AFF",
                    "#BE61CA",
                    "#F13C59"
                ],
                pointRadius: 3,
                pointHitRadius: 10,
                borderWidth: 1,
                lineTension: 0.125
            }
        ]
    };
    let options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            scales: {
                x: {
                    ticks: {
                        display: false
                    }
                }
            }
        },

    };


    return <>
        <div className="flex-column-center">
            <div style={{ fontSize: "1.5rem", color: "#243f61" }}>{value}</div>
            <div style={{ height: "130px", width: "280px" }}>
                <Line data={data} options={options} />
            </div>


        </div>
    </>;
}

export default LineChart;