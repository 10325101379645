import { Checkbox, CheckboxVisibility, DetailsList, Dropdown, Link, PrimaryButton, SelectionMode, Text } from '@fluentui/react';
import { IProject } from '@solarforschools/sfs-core/dist/project/types';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { calcProjectApi, exportProjectToXlsxUrl } from '../../../store/client';
import { websiteHost } from '../../../store/config';
import { generateColumns } from '../../common/DetailListHelper';
import DynamicForm from '../../dynamic-form/DynamicForm';
import { analysisFields as DefaultAnalysisField } from './ProjectHelper';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading } from './../../../store/admin/action';
import { LockDesignApi, multipleLockDesignApi } from '../../../store/client/school';
import { toast } from 'react-toastify';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { RenderListRow } from '../common/RenderListRow';
import { IColumn, Selection } from "@fluentui/react/lib/index";
import { SetDetailListHeaderSortDirection } from '../common/utils';
import { downloadCalculator, setProjectAnalysisData } from '../../../store/admin/projects/action';
import { RootState } from '../../../store'
import { IProjectState } from '../../../store/admin/projects/reducer'
import { IParams } from '@solarforschools/sfs-core';
import { getProjectFundingModelsApi } from '../../../store/client/project';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';
import Loader from '../common/Loader';

const ProjectAnalysis = (props: ProjectAnalysisProps) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState("all");
  const [calcParams, setCalcParams] = useState({ ...props.project.calcParams || {} })
  const { designAnalysis } = useSelector<RootState, IProjectState>(state => state.web.project)
  const [lockData, setLockData] = useState<any>({ ids: [], value: null })
  const [selectedDesigns, setSelectedDesigns] = useState<any>([])
  const [analysisFields, setAnalysisFields] = useState(DefaultAnalysisField)
  const [columns, setColumns] = useState(
    generateColumns([
      { name: 'School', key: 'schoolName' },
      {
        name: 'Name',
        key: 'name',
        onRender: (item) => {
          return (
            <Link rel='noopener noreferrer' target='_blank' href={`${websiteHost}/schools/${item.slug}/panelizer?designId=${item.designId}`}>
              {item.name}
            </Link>
          )
        },
      },
      { name: 'Calculator download', key: 'calc', minWidth: 20,
        onRender: (item) => {
          if (!item.designId) return  <div></div>
          async function handleClick() {
         
            const body = { schoolSlug: item.slug, designSlug: item._id }
            
            dispatch(downloadCalculator(body))
          }

          return (
            <div>
              <BsFileEarmarkExcelFill 
              onClick={handleClick}
              style={ { fontSize: "1.5rem", color: "#243f61", paddingLeft: "0.5rem", cursor: "pointer" }} />
            </div>
          )
        }
       },
      {
        name: 'isLocked',
        key: 'isLocked',
        minWidth: 50,
        onRender: function (item) {
          if (item.designId) {
            return (
              <Checkbox checked={item.isLocked}
                onChange={function (e: any, value) {
                  handleLockDesing(item.designId, value!)
                }}
              />
            )
          } else {
            return ''
          }

        },
      },
      { name: 'System Size', key: 'SystemSize', minWidth: 50 },
      { name: 'Consumption [kWh]', key: 'Consumption', minWidth: 70 },
      { name: 'Total Generation', key: 'Generation', minWidth: 70 },
      { name: 'Capex', key: 'Capex', minWidth: 50 },
      { name: 'Opex', key: 'Opex', minWidth: 50 },
      { name: 'Yield [kWh/kWp]', key: 'Yield', minWidth: 60 },
      { name: 'Price', key: 'PPA', minWidth: 50},
      {
        name: 'Investment',
        key: 'investment',
        minWidth: 60,
        onRender: (data) => {
          return (
            <Text>
              {
                data.results.CurrencySymbol + " " + data.Investment?.toFixed(2)
              }
            </Text>
          )
        }
      },
      { name: 'SC %', key: 'SelfConsumption', minWidth: 40 },
      { name: 'FundingModel', key: 'FundingModel', minWidth: 50 },
      { name: 'Saving 1st year', key: 'BenefitsFirstYear', minWidth: 80 },
      { name: 'Saving lifetime', key: 'BenefitsLifetime', minWidth: 80 },
      { name: 'Funding needed', key: 'FundingNeeded', minWidth: 80, 
        onRender: (data) => {
          return (
            <Text>
              {
                data?.results?.FundingNeeded?.toFixed(2) || ""
              }
            </Text>
          )
        }
       },
      // { name: 'Show', key: 'isShow', },
      // { name: 'Selected', key: 'isSelected', },
    ])
  );
  const handleSort = (field: string, order: 'asc' | 'desc') => {
    const updated = _.cloneDeep(designAnalysis);
    const sorted = _.orderBy(updated, field, order)
    dispatch(setProjectAnalysisData(sorted))
  };
  const fetchCalc = async () => {
    dispatch(isLoading(true))

    if (props.project._id) {
      await fetchFundingModels()
      const response = await calcProjectApi(props.project.slug!, filter, props.project.calcParams!);
      dispatch(setProjectAnalysisData(response.map((r: any) => {
        return {
          ...r,
          ...r.results,
        }
      })))
    }

    dispatch(isLoading(false))
  }

  const fetchFundingModels = async () => {
    const resp = await getProjectFundingModelsApi(props.project.region || "-")
    if (!resp) {
      toast.error("Error in getting funding models")
      return
    }
    setAnalysisFields((prev) => {
      return prev.map((elm) => {
        if (elm.key !== "FundingModel") return { ...elm }
        return {
          ...elm,
          options: resp
        }
      })
    })
  }

  useEffect(() => {
    fetchCalc()
  }, [])

  useEffect(() => {
    dispatch(setProjectAnalysisData(designAnalysis.map((d: any) => {
      if (lockData.ids.includes(d.designId)) {
        d.isLocked = lockData.value
      }
      return { ...d }
    })))
  }, [lockData])

  async function handleLockDesing(designId: string, isLocked: boolean) {
    try {
      setLockData({ ids: [designId], value: isLocked })
      const { msg } = await LockDesignApi(designId, isLocked)
      toast.success(msg)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleLockDesingMultipe(isLocked: boolean) {
    try {
      setLockData({ ids: selectedDesigns, value: isLocked })
      const { msg } = await multipleLockDesignApi(selectedDesigns, isLocked)
      toast.success(msg)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChange = (property: string, value: any) => {
    const project = _.cloneDeep(props.project);
    let calcParams = project.calcParams ? { ...project?.calcParams } : {}
    if (value === "") {
      calcParams = { ...calcParams, [property]: null }
    } else {
      calcParams = { ...calcParams, [property]: value }
    }
    calcParams = _.omitBy(calcParams, _.isNil)
    project.calcParams = calcParams as IParams
    props.onChangeProject(project);
  }

  const calculateProject = async (filter: string) => {
    dispatch(isLoading(true))
    if (props.project._id) {
      const calcParams = _.omitBy(props.project.calcParams, _.isNil) as IParams
      const response = await calcProjectApi(props.project.slug!, filter, calcParams!);
      dispatch(setProjectAnalysisData(response.map((r: any) => {
        return {
          ...r,
          ...r.results,
        }
      })));
    }

    dispatch(isLoading(false))
  }

  const handleSetFilter = (value?: string | number) => {
    const val = (value || "all").toString();
    setFilter(val);
    calculateProject(val);
  }

  const handleOnHeaderClick = (column?: IColumn): void => {
   
    if (column?.key === 'action' || column?.key === 'calc') return
    const newColumns = SetDetailListHeaderSortDirection(column, columns)
    setColumns(newColumns)
    const index = newColumns.findIndex(c => c.key === column?.key)
    handleSort(newColumns[index].key, newColumns[index].isSortedDescending ? 'desc' : 'asc')
  };

  const onRenderMissingItem = () => {
    return <></>
  }

  const selection = new Selection({
    onSelectionChanged: () => {
      let data: any = selection.getSelection()
      data = data.filter(Boolean)
      const d = data.length > 0 ? data.map((d: { designId: any; }) => d.designId).filter((d: any) => d) : []
      setSelectedDesigns(d)
    }
  })
console.log("DA ", designAnalysis)
  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <DynamicForm
          fields={analysisFields}
          data={props.project.calcParams}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          errors={{}}
        />
      </div>
      <div className="ms-Grid-row">
        <Dropdown
          style={{ minWidth: 100, marginTop: '15px' }}
          options={[
            { text: "All", key: "all" },
            { text: "Default", key: "default" },
            { text: "Designs", key: "designs" },
            { text: "Show", key: "Show" },
            { text: "Selected", key: "Selected" },
          ]}
          selectedKey={filter}
          onChange={(e, item) => handleSetFilter(item?.key)}
          className="ms-Grid-col ms-lg1"
        />
        {selectedDesigns.length > 0 && (
          <>
            <PrimaryButton
              style={{ minWidth: 80, maxWidth: 130 }}
              text="Lock Designs"
              onClick={() => handleLockDesingMultipe(true)}
              className="btn-primary ms-Grid-col ms-lg1"
            />
            <PrimaryButton
              style={{ minWidth: 80, maxWidth: 160 }}
              text="Unlock Designs"
              onClick={() => handleLockDesingMultipe(false)}
              className="btn-primary ms-Grid-col ms-lg1"
            />
          </>
        )
        }
        <div className={`ms-Grid-col ms-lg${selectedDesigns.length > 0 ? 6 : 8}`}></div>

        <PrimaryButton
          style={{ minWidth: 80, maxWidth: 80 }}
          text="Export"
          as="a"
          href={exportProjectToXlsxUrl(props.project.slug, { ...props.project.calcParams, filterBy: filter })}
          target="_blank"
          className="btn-primary ms-Grid-col ms-lg1"
        />
        <PrimaryButton
          style={{ minWidth: 80, maxWidth: 80 }}
          text="Calculate"
          onClick={() => calculateProject(filter)}
          className="btn-primary ms-Grid-col ms-lg1"
        />
        <p >
          <p className='padding-top-sm'>Calc Version: {designAnalysis?.find(d => !d.isLocked)?.results?.version} </p>
        </p>
      </div>
      <DetailsList
        items={designAnalysis}
        columns={columns}
        selectionMode={SelectionMode.multiple}
        onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
        onRenderDetailsHeader={DetailListStickyHeader}
        compact={true}
        selection={selection}
        checkboxVisibility={CheckboxVisibility.always}
        onRenderRow={(props) => RenderListRow(props!)}
        onRenderMissingItem={onRenderMissingItem}
      />
    </div>
  );
}

interface ProjectAnalysisProps {
  project: IProject
  onChangeProject: Dispatch<SetStateAction<IProject>>
}

export default ProjectAnalysis;

