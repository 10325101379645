import { toast } from "react-toastify";
import axios from "axios";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import {
  IInvoiceReportData,
} from "./actionTypes";
import { host } from "../../../config";
import { RootState } from "../../../index";
import { isLoading } from "../../action";
import { IInvoiceReportFilter } from "../../../../components/admin/reporting/invoice/types";
import { getInvoiceReportDataApi, getInvoiceReportExportApi } from "../../../client/reporting/invoice";
import { downloadFileFromStream } from "../../../../components/admin/common/utils";

// Action Creators
export const setInvoiceReportFilter = (invoiceReportFilter: IInvoiceReportFilter) => {
  return { type: "REPORT/SET_INVOICE_REPORT_FILTER", invoiceReportFilter };
};

export const setInvoiceReportData = (
  invoiceReportDate: IInvoiceReportData[]
) => {
  return { type: "REPORT/SET_INVOICE_REPORT_LIST", invoiceReportDate };
};



export const fetchInvoiceReportData = (isShowLoader: boolean = true) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(setInvoiceReportData([]))
      if (isShowLoader) dispatch(isLoading(true));
      const { invoiceReportFilter } = getState().web.invoiceReport;
      const params = { ...invoiceReportFilter };
      const { data } = await getInvoiceReportDataApi(params);
      await dispatch(setInvoiceReportData(data));
      if (isShowLoader) dispatch(isLoading(false));
      return data
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

export const exportInvoiceReport = () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));
      const { invoiceReportFilter } = getState().web.invoiceReport;
      const params = { ...invoiceReportFilter };
      const data  = await getInvoiceReportExportApi(params);
      downloadFileFromStream({ data, filename: "invoice-report", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };