import { Dropdown } from "@fluentui/react";
import { debounce, throttle } from "lodash";
import { PrimaryButton } from "office-ui-fabric-react";
import { useCallback, useState } from "react";
import { dropdownStyles } from "../common/common";
import { executeScript, ScriptsDropDown } from "./helper";

const ScriptsFilter = () => {
  const [scriptName, setScriptName] = useState("");

  const handleChange = async (key: string, value: any) => {
    setScriptName(value);
  };

  const handleClick = () => {
    if (scriptName === "Choose Scripts" || scriptName === "") return;
    else {
      window.history.replaceState(null, "", `/settings/scripts/${scriptName}`);
      executeScript(scriptName);
    }
  };

  const throttleClick = useCallback(debounce(handleClick, 1000), [scriptName])




  return (
    <div className="center-dropDown">
      <Dropdown
        dropdownWidth="auto"
        label="Choose Scripts and Run"
        onChange={(e, el) => {
          handleChange("scripts", el?.key?.toString());
        }}
        options={ScriptsDropDown}
        styles={dropdownStyles}
        defaultSelectedKey={ScriptsDropDown[0].key}
        className="inlineflex ms-Grid-col ms-lg3"
      />
      <div className="scripts-save">
        <PrimaryButton
          text="Run"
          onClick={throttleClick}
          className="btn-primary"
        />
      </div>
    </div>
  );
};

export default ScriptsFilter;
