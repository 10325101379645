import { IColumn, TooltipHost } from "@fluentui/react";
import { isSortable } from "../../common/utils";

export const headerRowNormal = (params: any): IColumn[] => {
  return [
    {
      key: "siteName",
      name: "Name",
      fieldName: "siteName",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "siteId",
      name: "Site ID",
      fieldName: "siteId",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "generated",
      name: "G",
      fieldName: "generated",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "exported",
      name: "E",
      fieldName: "exported",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "imported",
      name: "I",
      fieldName: "imported",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "selfConsumption",
      name: "SC",
      fieldName: "selfConsumption",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "consumption",
      name: "C",
      fieldName: "consumption",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "target",
      name: "Exp Yield",
      fieldName: "target",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "selfConsumptionTarget",
      name: "Exp SC",
      fieldName: "selfConsumptionTarget",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "perfRatio",
      name: "PR %",
      fieldName: "perfRatio",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "avoidedCO2",
      name: "CO2",
      fieldName: "avoidedCO2",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "trees",
      name: "Trees",
      fieldName: "trees",
      minWidth: 70,
      data: "string",
      ...isSortable,
    },
    {
      key: "savings",
      name: "Savings",
      fieldName: "Savings",
      minWidth: 70,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item?.savings} closeDelay={500}>
            {item?.savings?.toFixed(3)}
          </TooltipHost>
        );
      }
    }
  ];
}

export const headerRowCompare: IColumn[] = [
  {
    key: "schoolName",
    name: "Name",
    fieldName: "schoolName",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "siteId",
    name: "Site ID",
    fieldName: "siteId",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "generated",
    name: "G",
    fieldName: "generated",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "generatedc",
    name: "G*",
    fieldName: "generatedc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "exported",
    name: "E",
    fieldName: "exported",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "exportedc",
    name: "E*",
    fieldName: "exportedc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "imported",
    name: "I",
    fieldName: "imported",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "importedc",
    name: "I*",
    fieldName: "importedc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "selfConsumption",
    name: "SC",
    fieldName: "selfConsumption",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "selfConsumptionc",
    name: "SC*",
    fieldName: "selfConsumptionc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "consumption",
    name: "C",
    fieldName: "consumption",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "consumptionc",
    name: "C*",
    fieldName: "consumptionc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "target",
    name: "Exp Yield",
    fieldName: "target",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "targetc",
    name: "Exp Yield*",
    fieldName: "targetc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "selfConsumptionTarget",
    name: "Exp SC",
    fieldName: "selfConsumptionTarget",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "selfConsumptionTargetc",
    name: "Exp SC*",
    fieldName: "selfConsumptionTargetc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "perfRatio",
    name: "PR %",
    fieldName: "perfRatio",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "perfRatioc",
    name: "PR %*",
    fieldName: "perfRatioc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "avoidedCO2",
    name: "CO2",
    fieldName: "avoidedCO2",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "avoidedCO2c",
    name: "CO2*",
    fieldName: "avoidedCO2c",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "trees",
    name: "Trees",
    fieldName: "trees",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "treesc",
    name: "Trees*",
    fieldName: "treesc",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
];


export const headerInvoiceRowNormal: IColumn[] = [
  {
    key: "customerNr",
    name: "Customer No",
    fieldName: "customerNr",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "supplySite",
    name: "Supply Site",
    fieldName: "supplySite",
    minWidth: 90,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.supplySite} closeDelay={500}>{item.supplySite}</TooltipHost>
  },
  {
    key: "billTo",
    name: "Bill To",
    fieldName: "billTo",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.billTo} closeDelay={500}>{item.billTo}</TooltipHost>
  },
  {
    key: "quarter",
    name: "Quarter",
    fieldName: "quarter",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "mpan",
    name: "MPAN",
    fieldName: "mpan",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.mpan} closeDelay={500}>{item.mpan}</TooltipHost>
  },
  {
    key: "invoiceNum",
    name: "Invoice No",
    fieldName: "invoiceNum",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.invoiceNum} closeDelay={500}>{item.invoiceNum}</TooltipHost>
  },
  {
    key: "date",
    name: "Invoice Date",
    fieldName: "date",
    minWidth: 75,
    data: "string",
    ...isSortable,
  },
  {
    key: "dueDate",
    name: "Due Date",
    fieldName: "dueDate",
    minWidth: 75,
    data: "string",
    ...isSortable,
  },
  {
    key: "periodStart",
    name: "Period Start",
    fieldName: "periodStart",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.periodStart} closeDelay={500}>{item.periodStart}</TooltipHost>
  },
  {
    key: "periodEnd",
    name: "Period End",
    fieldName: "periodEnd",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.periodEnd} closeDelay={500}>{item.periodEnd}</TooltipHost>
  },
  {
    key: "generation",
    name: "G (kWh)",
    fieldName: "generation",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "export",
    name: "E",
    fieldName: "export",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "consumption",
    name: "C",
    fieldName: "consumption",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "co2Saved",
    name: "Co2Saved(kg)",
    fieldName: "co2Saved",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "trees",
    name: "Trees",
    fieldName: "trees",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "energy",
    name: "Energy",
    fieldName: "energy",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "totalNet",
    name: "Total Net (£)",
    fieldName: "totalNet",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "vat",
    name: "vat(%)",
    fieldName: "vat",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "total",
    name: "Total(£)",
    fieldName: "total",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "costs",
    name: "Costs(£)",
    fieldName: "costs",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "savings",
    name: "Savings(£)",
    fieldName: "savings",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "ppa",
    name: "ppa",
    fieldName: "ppa",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "pricePaid",
    name: "pricePaid",
    fieldName: "pricePaid",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
];


export const headerInvoiceRowCompare: IColumn[] = [
  ...headerInvoiceRowNormal,



  {
    key: "quarter1",
    name: "Quarter1",
    fieldName: "quarter1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "mpan1",
    name: "MPAN1",
    fieldName: "mpan1",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.mpan1} closeDelay={500}>{item.mpan1}</TooltipHost>
  },
  {
    key: "invoiceNum1",
    name: "Invoice No1",
    fieldName: "invoiceNum1",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.invoiceNum1} closeDelay={500}>{item.invoiceNum1}</TooltipHost>
  },
  {
    key: "date1",
    name: "Invoice Date1",
    fieldName: "date1",
    minWidth: 75,
    data: "string",
    ...isSortable,
  },
  {
    key: "dueDate1",
    name: "Due Date1",
    fieldName: "dueDate1",
    minWidth: 75,
    data: "string",
    ...isSortable,
  },
  {
    key: "periodStart1",
    name: "Period Start1",
    fieldName: "periodStart1",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.periodStart1} closeDelay={500}>{item.periodStart1}</TooltipHost>
  },
  {
    key: "periodEnd1",
    name: "Period End1",
    fieldName: "periodEnd1",
    minWidth: 70,
    data: "string",
    ...isSortable,
    onRender: (item: any) => <TooltipHost content={item.periodEnd1} closeDelay={500}>{item.periodEnd1}</TooltipHost>
  },
  {
    key: "generation1",
    name: "G1(kWh)",
    fieldName: "generation1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "export1",
    name: "E1",
    fieldName: "export1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "consumption1",
    name: "C1",
    fieldName: "consumption1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "co2Saved1",
    name: "Co2Saved1(kg)",
    fieldName: "co2Saved1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "trees1",
    name: "Trees1",
    fieldName: "trees1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "energy1",
    name: "Energy1",
    fieldName: "energy1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "totalNet1",
    name: "Total Net (£)1",
    fieldName: "totalNet1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "vat1",
    name: "vat(%)1",
    fieldName: "vat1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "total1",
    name: "Total(£)1",
    fieldName: "total1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "costs1",
    name: "Costs(£)1",
    fieldName: "costs1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "savings1",
    name: "Savings(£)1",
    fieldName: "savings1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "ppa1",
    name: "ppa1",
    fieldName: "ppa1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
  {
    key: "pricePaid1",
    name: "pricePaid1",
    fieldName: "pricePaid1",
    minWidth: 70,
    data: "string",
    ...isSortable,
  },
]