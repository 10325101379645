export const imagePlaceholder =
  "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/imagePlaceholder.png";
export const videoPlaceholder =
  "https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/videoPlaceholder.png";

export const TITLE = {
  MODULE: "Which module would you like to add a screen for ?",
  UNIT: "Which unit ?",
  ADD_EDIT_TEMPLATE: "What would you like to do ?",
  TEMPLATE_SELECTION: "Which Template ?",
  CONTENT_TEMPLATE_SELECTION: "Which Content Template ?",
  QUESTION_TEMPLATE_SELECTION: "Which Question Template ?",
  REARRANGE_SCREENS: "Order of screens",
};
