import { ConfigsDropDown } from "./../OtherConfig/helper";
import axios from "axios";
import { websiteHost } from "../../../store/config";
import { toast } from "react-toastify";

export const dropDown = [
  { key: "Select Options", text: "Select Options" },
  { key: "Sync Translation", text: "Sync Translation" },
  { key: "Upload Translation", text: "Upload Translation" },
];

export const syncTranslation = async (
  optionName: string,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const urlName = `${websiteHost}/api/sync/all/translations`;
  axios
    .post(urlName)
    .then((res) => {
      setIsDisabled(false);
      if (res.status === 200) {
        toast.success(`${optionName} completed successfully`);
      } else {
        console.log("Error ", res.data);
        toast.error("Something Went Wrong");
      }
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.log(err);
      setIsDisabled(false);
    });
};

export const uploadTranslation = (
  optionName: string,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const urlName = `${websiteHost}/api/upload/translations`;
  axios
    .post(urlName)
    .then((res) => {
      setIsDisabled(false);
      if (res.status === 200) {
        toast.success(`${optionName} completed successfully`);
      } else {
        console.log("Error ", res.data);
        toast.error("Something Went Wrong");
      }
    })
    .catch((err) => {
      toast.error("Something Went Wrong");
      console.log(err);
      setIsDisabled(false);
    });
};
