import { IYield } from "@solarforschools/sfs-core/dist/solardb/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../../utils/util";
import { host } from "../../config";

let GetPortfolioAPiAbortController: AbortController


export const getPortfolioApi = (query: any): Promise<any> => {
  if (GetPortfolioAPiAbortController) GetPortfolioAPiAbortController.abort()
  GetPortfolioAPiAbortController = new AbortController()
  return axios.get(`${host}/funder/portfolio?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetPortfolioAPiAbortController.signal }).then(res => res.data)
}

export const exportPortfolioApi = (query: any): Promise<any> => {
  return axios.get(`${host}/funder/portfolio/export-portfolioes?${generateQueryString(query as ParsedUrlQueryInput)}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const getReGeneratePortfolioApi = (): Promise<any> => {
  return axios.get(`${host}/funder/portfolio/regen`).then(res => res.data)
}
