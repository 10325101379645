import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IYieldsFilter } from "../../../components/admin/yields/types";
import { IYield } from "@solarforschools/sfs-core/dist/solardb/types";
import { deleteYieldsApi, getyieldsApi, updateYieldsApi, createYieldsAPi, genYieldsAPi, exportYieldsApi, addYieldsAPi } from "../../client/yields";
import { downloadFileFromStream } from "../../../components/admin/common/utils";

// Action Creators

export const setYieldsFilter = (yieldsFilter: IYieldsFilter) => {
  return { type: "SET_YIELDS_FILTER", yieldsFilter };
};

export const setYieldsList = (
  yields: IYield[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_YIELDS_LIST", yields, totalCount, reset };
};

export const updateYIeldsList = (yields: IYield) => {
  return { type: "UPDATE_YIELDS", yields };
};

export const delYields = (yields: IYield) => {
  return { type: "DELETE_YIELDS", yields };
};

export const getYields =
  (params?: IYieldsFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IYieldsFilter =
            getState().web.yields.yieldsFilter;
          reqBody = { ...filters };
        }
        const { yields, totalCount } = await getyieldsApi(reqBody);
        dispatch(setYieldsList(yields, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateYields =
  (params: IYield) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { yields, msg } = params._id
          ? await updateYieldsApi(params)
          : await createYieldsAPi(params);
        dispatch(updateYIeldsList(yields));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteYields =
  (data: IYield) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteYieldsApi(data);
        dispatch(delYields(data));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
export const genYields =
  (params: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { msg } = await genYieldsAPi(params)
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
export const addYield =
  (params: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { msg } = await addYieldsAPi(params)
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


export const exportDailYields = () =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    try {
      dispatch(isLoading(true));

      const filters: IYieldsFilter =
        getState().web.yields.yieldsFilter;

      const data: string = await exportYieldsApi({ ...filters, all: true });
      downloadFileFromStream({ data, filename: "daily-yield", type: 'xlsx' })
      dispatch(isLoading(false));
    } catch (e: any) {
      console.log(e);
      dispatch(isLoading(false));
    }
  };

