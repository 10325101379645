import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { RootState } from '../../../../store';
import { getGlossary } from '../../../../store/content/action';
import { IContent } from '../../../../store/content/reducer';
import Loader from '../../../admin/common/Loader';
import "./glossary.css";
import GlossaryItem from './GlossaryItem';
import { showGlossaryForm } from './GlossaryItemForm';

const Glossary = () => {
  const dispatch = useDispatch()
  const content = useSelector<RootState, IContent>(state => state.learning.content)
  const { glossary: { glossaryItems }, isLoading } = content;

  useEffect(() => {
    dispatch(getGlossary())
  }, [])

  return (
    <>
      <div className="glossary-root">
        <main>
          <header>
            <Link className="back-btn" to="/content">
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
            <h4>Glossary</h4>
            <FontAwesomeIcon
              className="new-glossary-item" icon={faPlus}
              onClick={() => showGlossaryForm({ initialData: { word: '', description: '' } })}
            />
          </header>
          <div className="glossary-content">
            {glossaryItems.map(item => {
              return (
                <GlossaryItem
                  glossaryItem={item}
                  onEdit={() => showGlossaryForm({ initialData: item, id: item._id })}
                />
              )
            })}
          </div>
        </main>
      </div>
      {isLoading && <Loader/>}
    </>
  )
}

export default Glossary
