import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../../common/Loader';
import { ActionButton, Dropdown, IDropdownOption, Stack, Text } from '@fluentui/react';
import { RootState } from '../../../../store';
import { IPortfolioState } from '../../../../store/admin/funder/portfolio/reducer';
import { exportPortfolioes, getPortfolioes, setPortfolioFilter } from '../../../../store/admin/funder/portfolio/action';
import PortfolioList from './PortfolioList';
import PortfolioFilters from './PortfolioFilters';
import { PageSize } from '../../common/common';

const PortfolioScreen = () => {
  const dispatch = useDispatch()
  const { portfolioes, totalCount, portfolioFilter }: IPortfolioState = useSelector<RootState, IPortfolioState>((state: RootState) => state.web.portfolio)
  useEffect(() => {
    dispatch(getPortfolioes())
  }, []);

  function rerunCron(): void | PromiseLike<void> {
    throw new Error('Function not implemented.');
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...portfolioFilter, page: 1, pageSize: size }
    dispatch(setPortfolioFilter(filters))
    dispatch(getPortfolioes())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Portfolio</h1>
        <PortfolioFilters />
      </header>
      <Stack tokens={{ padding: '0 15px' }} horizontal verticalAlign='center'>
        <span style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}>
          {totalCount > 0 &&
            <>
              Displaying&nbsp;
              <Dropdown
                selectedKey={portfolioFilter.pageSize?.toString() || "50"}
                options={PageSize}
                styles={{ dropdown: { width: "80px" } }}
                onChange={handleOnChangePageSize}
              />
              &nbsp;/ {totalCount}
            </>}
        </span>
        <div style={{ display: 'flex', flex: 1 }} />
        <ActionButton iconProps={{ iconName: 'ExcelDocument' }} onClick={async () => dispatch(exportPortfolioes())}>
          Export
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={async () => await rerunCron()}>
          Re Generate
        </ActionButton>
      </Stack>
      <PortfolioList actions={['edit']} />
    </div>
  );
}
export default PortfolioScreen;

