import axios from "axios";
import { host } from "../../../store/config";
import { toast } from "react-toastify";

export const ScriptsDropDown = [
  { key: "Choose Scripts", text: "Choose Scripts" },
  { key: "calculateAnalysis", text: "calculateAnalysis" },
  { key: "calculateImpact", text: "calculateImpact" },
  { key: "dataFeedES", text: "dataFeedES" },
  { key: "edubaseAll", text: "edubaseAll" },
  { key: "edubaseChildren", text: "edubaseChildren" },
  { key: "fixAnalysis", text: "fixAnalysis" },
  { key: "fixDesigns", text: "fixDesigns" },
  { key: "fixProjects", text: "fixProjects" },
  { key: "fixReadings", text: "fixReadings" },
  { key: "fixSchools", text: "fixSchools" },
  { key: "fixSystems", text: "fixSystems" },
  { key: "fixTransactions", text: "fixTransactions" },
  { key: "fixYields", text: "fixYields" },
  { key: "fixYieldTarget", text: "fixYieldTarget" },
  { key: "genData", text: "genData" },
  { key: "genDataBeta", text: "genDataBeta" },
  { key: "genPortfolio", text: "genPortfolio" },
  { key: "genReadings", text: "genReadings" },
  { key: "genRTReadings", text: "genRTReadings" },
  { key: "genRTYields", text: "genRTYields" },
  { key: "genYields", text: "genYields" },
  { key: "genYuderaData", text: "genYuderaData" },
  { key: "pipedriveOrgnLink", text: "pipedriveOrgnLink" },
  { key: "starkDataImport", text: "starkDataImport" },
  { key: "syncMailjet", text: "syncMailjet" },
  { key: "syncRegistrations", text: "syncRegistrations" },
];

export const executeScript = (scriptName: string) => {
  axios
    .get(`${host}/scripts/settings/${scriptName}`)
    .then((res) => {
      if (res.status === 200) {
        toast.success(`${scriptName} is executing`);
      }
    })
    .catch((err) => {
      if (
        err?.response?.status === 400 ||
        err?.response?.status === 500 ||
        err?.response?.status === 401
      ) {
        toast.error(err.response.data?.error);
      }
    });
  return;
};
