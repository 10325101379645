import { Checkbox, ChoiceGroup, Dropdown } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { StringMap } from '../../common/types';
import { dropdownStyles } from '../../common/common';
import { bondHolderStatus } from './BondOfferHelper';
import { IBondOfferState } from './../../../../store/admin/funder/bondOffer/reducer';
import { setBondOfferFilter, getBondOffers } from './../../../../store/admin/funder/bondOffer/action';
import BondOfferPicker from '../../common/BondOfferPicker';

const BondOfferFilters = (props: IBondOfferFilterProps) => {
    const dispatch = useDispatch()
    const { bondOfferFilter, bondOffersFilterData }: IBondOfferState = useSelector<RootState, IBondOfferState>((state) => state.web.bondOffer)
    const setFilters = (filters: StringMap) => dispatch(setBondOfferFilter(filters));

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        let params = { ...bondOfferFilter, page: 1, [key]: value };
        switch (key) {
            case 'selectedBondOffer':
                params = { ...params, bondOffer: value[0]?.key }
                break;
            default:
                params = { ...bondOfferFilter, page: 1, [key]: value };
                break;
        }
        setFilters(params);
        handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback(
        (filters: any) => {
            dispatch(getBondOffers(filters));
        },
        []
    );

    const { bondOffer, status, isTestOffer } = bondOfferFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <div className={`ms-Grid-col ms-lg4 margin-top-xsm`}>
                    <BondOfferPicker filters={{}} onChange={handleOnChange} />
                </div>
                <ChoiceGroup
                    className="inlineflex ms-Grid-col ms-lg6"
                    label="Status"
                    selectedKey={status || "all"}
                    onChange={(e, option) => handleOnChange("status", option?.key)}
                    options={bondHolderStatus}
                />
                <Checkbox
                    label="Test Offers"
                    checked={isTestOffer === true}
                    onChange={(e, value) => handleOnChange('isTestOffer', value)}
                    className="ms-Grid-col ms-lg2 margin-top-lg"
                />
            </div>
        </div>
    );
}

interface IBondOfferFilterProps {

}

export default BondOfferFilters;

