import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { ISystem } from "@solarforschools/sfs-core";
import { IMeter } from "@solarforschools/sfs-core/dist/solardb/types";
import { IField } from "../../dynamic-form/DynamicForm";
import { countries, FundingOptions, requiredMessage } from "../common/common";
import { IAddSystemParams, StringMap } from "../common/types";
import { fileldsFilter } from "../schools/common";
import { DataFormats, DeviceTypes, MeterTypes, Providers, TimeZones } from "./common";

const tz = TimeZones();
export const getMeterFields = (options: { ftpFolders: IDropdownOption[]; viewFolderName: any; viewDataFormat: any; viewFtpFile: any, provider: string }): IField[] => {
    let fields: IField[] = [
        {
            label: "Mid",
            key: "mid",
            type: "string",
            placeholder: "mid",
        }, {
            label: "MSN",
            key: "MSN",
            type: "string",
            placeholder: "MSN",
        },
        {
            label: "Device Type",
            key: "deviceType",
            type: "dropdown",
            options: DeviceTypes,
        },
        {
            label: "Type",
            key: "type",
            type: "dropdown",
            options: MeterTypes,
        },
        {
            label: "Peak Power",
            key: "peakPower",
            type: "number",
            placeholder: "peak power",
        },
        {
            label: "Factor",
            key: "factor",
            type: "number",
            step: "1",
            placeholder: "Factor",
        }]
    if (options.provider !== 'solaredge') {
        fields = [...fields, {
            label: "Data Format",
            key: "dataFormat",
            type: "dropdown",
            options: DataFormats,
            extras: { onRenderLabel: options.viewDataFormat }
        }, {
            label: "Folder Name",
            key: "folderName",
            type: "dropdown",
            placeholder: "Folder Name",
            options: [{ key: 'select', text: 'Select' }, ...options.ftpFolders] as IDropdownOption[],
            extras: { onRenderLabel: options.viewFolderName }
        },
        {
            label: "Channel",
            key: "channel",
            type: "string",
            placeholder: "Channel",
            extras: { onRenderLabel: options.viewFtpFile }
        }]
    }
    fields = [...fields,
    {
        label: "FiT",
        key: "FiT",
        type: "checkbox",
    },
    {
        label: "Include",
        key: "include",
        type: "checkbox",
    },
    {
        label: "Active",
        key: "active",
        type: "checkbox",
    },
    {
        label: "FiT Client_ID",
        key: "FiTClientId",
        type: "string",
        placeholder: "FiT Client_Id",
    }, {
        label: "FiT Client_RGS_ID",
        key: "FiTGeneratorAccNR",
        type: "string",
        placeholder: "FiT Client_RGS_ID",
    }, {
        label: "FiT Accreditation_ID",
        key: "FiTAccreditation",
        type: "string",
        placeholder: "FiT Accreditation_ID",
    },
    ];
    return fields;
}

export const getSystemGeneralFields = ({ selectedSystem }: StringMap): IField[] => [
    {
        key: 'name',
        label: 'System Name',
        type: 'string',
        extras: { required: true },
        className: 'ms-Grid-col ms-lg4',
    }, {
        key: 'id',
        label: 'System Id',
        type: ['orsis', "huawei", "SMA"].includes(selectedSystem?.provider) ? 'string' : 'number', //!Number.isNaN(selectedSystem.id) ? parseInt(selectedSystem?.id.toString()) : selectedSystem?.id,
        className: 'ms-Grid-col ms-lg3',
        extras: { required: true, ...selectedSystem?._id && selectedSystem._id !== "new" ? { readOnly: "readOnly" } : {} }
    }, {
        key: 'siteId',
        label: 'SiteId',
        type: selectedSystem?.provider === 'orsis' ? 'string' : 'number',
        className: 'ms-Grid-col ms-lg1',
        step: "1",
        extras: { required: false }
    }, {
        key: 'contractId',
        label: 'ContractId',
        type: 'string',
        className: 'ms-Grid-col ms-lg1'
    }, {
        key: 'provider',
        label: 'Provider',
        type: 'dropdown',
        options: Providers.filter((p) => p.key !== "all"),
        extras: { required: true },
        className: 'ms-Grid-col ms-lg2',
    }, {
        key: 'peakPower',
        label: 'Peak Power (kWp)',
        type: 'number',
        step: "0.01",
        placeholder: 'Peak Power (kWp)',
        className: 'ms-Grid-col ms-lg2',
        extras: { required: true },
    }, {
        key: 'installDate',
        label: 'Install Date',
        type: 'date',
        placeholder: 'Pick Installed date',
        className: 'ms-Grid-col ms-lg2',
        extras: { required: true },
    }, {
        key: 'dataStart',
        label: 'Data Start',
        type: 'date',
        placeholder: 'Data Start date',
        className: 'ms-Grid-col ms-lg2',
        extras: { required: true },
    }, {
        key: 'panels',
        label: '#Panels',
        type: 'number',
        placeholder: '# panels',
        step: "1",
        className: 'ms-Grid-col ms-lg1',
        extras: { required: true },
    }, {
        key: 'MPAN.import',
        label: 'Import (MPAN)',
        type: 'string',
        placeholder: 'Import (MPAN)',
        className: 'ms-Grid-col ms-lg2',
    },
    {
        key: 'MPAN.export',
        label: 'Export (MPAN)',
        type: 'string',
        placeholder: 'Export (MPAN)',
        className: 'ms-Grid-col ms-lg2',
    },
]

export const targetFields: IField[] = [
    {
        key: 'target.yield',
        label: 'Yield (kWh/kWp)',
        type: 'number',
        extras: { required: true },
        step: "0.01",
        placeholder: 'Target yield',
        className: 'ms-Grid-col ms-lg2',
    }, {
        key: 'target.SC',
        label: 'Self-Consumption (%)',
        placeholder: 'SC',
        type: 'number',
        extras: { required: true },
        step: "0.01",
        className: 'ms-Grid-col ms-lg2',
    }
]
const commonMonthlyTargetProps: any = {
    type: 'number',
    step: "0.01",
    className: 'ms-Grid-col ms-lg1',
}
export const monthlyTargetFields: IField[] = [
    {
        key: 'target.yieldPerMonth.Jan',
        label: 'Jan',
        placeholder: 'Jan',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Feb',
        label: 'Feb',
        placeholder: 'Feb',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Mar',
        label: 'Mar',
        placeholder: 'Mar',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Apr',
        label: 'Apr',
        placeholder: 'Apr',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.May',
        label: 'May',
        placeholder: 'May',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Jun',
        label: 'Jun',
        placeholder: 'Jun',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Jul',
        label: 'Jul',
        placeholder: 'Jul',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Aug',
        label: 'Aug',
        placeholder: 'Aug',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Sep',
        label: 'Sep',
        placeholder: 'Sep',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Oct',
        label: 'Oct',
        placeholder: 'Oct',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Nov',
        label: 'Nov',
        placeholder: 'Nov',
        ...commonMonthlyTargetProps
    }, {
        key: 'target.yieldPerMonth.Dec',
        label: 'Dec',
        placeholder: 'Dec',
        ...commonMonthlyTargetProps
    }

]

export const ownershipFields: IField[] = [
    {
        key: 'ownedBy',
        label: 'Owned By',
        type: 'dropdown',
        options: FundingOptions.filter((o) => o.key !== "all"),
        extras: { required: true },
        className: 'ms-Grid-col ms-lg1',
    },
    {
        key: 'billedBy',
        label: 'Billed By',
        type: 'dropdown',
        options: FundingOptions.filter((o) => o.key !== "all"),
        extras: { required: true },
        className: 'ms-Grid-col ms-lg1',
    },
    {
        key: 'pricePaid',
        label: 'Price Paid (ct/kWh, p/kWh)',
        type: 'number',
        placeholder: 'Price Paid',
        step: "0.1",
        className: 'ms-Grid-col ms-lg2',
    }, {
        key: 'priceSolar',
        label: 'Price Solar (ct/kWh, p/kWh)',
        type: 'number',
        placeholder: 'Price Solar',
        step: "0.1",
        className: 'ms-Grid-col ms-lg2',
    },
    {
        key: 'priceExport',
        label: 'Price Export (ct/kWh, p/kWh)',
        type: 'number',
        placeholder: 'Price Export',
        step: "0.1",
        className: 'ms-Grid-col ms-lg2',
    },
    {
        label: "ForceConsumptionCalculation",
        key: "forceConsumptionCalculation",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg2 margin-top-lg',
    },
]

export const displaySettingField: IField[] = [
    {
        label: "Include",
        key: "include",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    },
    {
        label: "Active",
        key: "active",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    }, {
        label: "G",
        key: "showMeters.G",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    }, {
        label: "E",
        key: "showMeters.E",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    }, {
        label: "I",
        key: "showMeters.I",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    }, {
        label: "C",
        key: "showMeters.C",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    }, {
        label: "SC",
        key: "showMeters.SC",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    }, {
        label: "EV",
        key: "showMeters.EV",
        type: "checkbox",
        className: 'ms-Grid-col ms-lg1',
    }
]

export const getSchoolFields = (options: StringMap): IField[] => {
    const huaweiCredntials: IField[] = [
        {
            key: 'huaweiUserName',
            label: 'Huawei UserName',
            placeholder: 'Huawei UserName',
            className: 'ms-Grid-col ms-lg2',
            type: "string",
        }, {
            key: 'huaweiSystemCode',
            label: 'Huawei SystemCode',
            placeholder: 'Huawei SystemCode',
            className: 'ms-Grid-col ms-lg2',
            type: "string",
        },
        {
            key: 'huaweiUrl',
            label: 'Huawei Api URL',
            placeholder: 'Huawei Api Url',
            className: 'ms-Grid-col ms-lg4',
            type: "string",
        },
        {
            label: "IsNewApi(After June 30 2022 api will be new one)",
            key: "isNewHuaweiApi",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg4  margin-top-lg',
        }
    ]
    const solaredgeApiKey: IField[] = [{
        key: 'APIKey',
        label: 'ApIkey',
        placeholder: 'SE APi key',
        className: 'ms-Grid-col ms-lg4',
        type: "string",
    }]
    let schoolFileds: IField[] = [{
        key: 'country',
        label: 'Country',
        type: 'combobox',
        placeholder: 'Slecte country',
        options: countries.filter(r => r.key !== 'all'),
        extras: { required: true },
        className: 'ms-Grid-col ms-lg2',
    }, {
        key: 'TZ',
        label: "Time Zone",
        type: 'combobox',
        extras: { required: true, autoComplete: "on" },
        placeholder: "Select TZ",
        options: tz as IComboBoxOption[],
        className: 'ms-Grid-col ms-lg2',
    }, {
        key: 'schools',
        label: "Select School",
        type: 'combobox',
        placeholder: "Select School",
        options: [{ key: 'select', text: 'Select' }, ...options?.schools.filter((s: { key: string; }) => s.key !== "all")] as IComboBoxOption[],
        className: 'ms-Grid-col ms-lg4',
    }, {
        key: 'invUrl',
        label: 'Inverter URL (Power Bi)',
        placeholder: 'Inverter URL (Power Bi)',
        className: 'ms-Grid-col ms-lg4',
        type: "string",
    },
    ]
    if (options?.selectedSystem?.provider === "huawei") schoolFileds = schoolFileds.concat(huaweiCredntials)
    if (options?.selectedSystem?.provider === "solaredge") schoolFileds = schoolFileds.concat(solaredgeApiKey)
    if (options?.selectedSystem?.provider === "SMA") schoolFileds = schoolFileds.concat([{
        key: 'smaPlantId',
        label: 'Plant Id',
        type: 'string',
        placeholder: 'Plant Id',
        className: 'ms-Grid-col ms-lg4',
    }])
    return schoolFileds
}

export const seSystemFields: IField[] = [
    {
        key: 'id',
        label: 'System Id',
        type: 'number',
        placeholder: 'id',
        step: "1",
        className: 'ms-Grid-col ms-lg12',
    },
    {
        key: 'APIKey',
        label: 'APIKey',
        placeholder: 'APIKey',
        className: 'ms-Grid-col ms-lg12',
        type: "string",
    }
]
export const validateSeSystem = (params: { id: number, APIKey: string }): StringMap => {
    const errors: StringMap = {};
    const { APIKey, id } = params || {}
    if (APIKey?.trim() === "") errors['APIKey'] = requiredMessage;
    if (!id) errors['id'] = requiredMessage;
    return errors;
}
export const validateMeter = (meter: IMeter): StringMap => {
    const errors: StringMap = {};
    const { factor, dataFormat, channel, folderName } = meter || {}
    if (!factor) errors['factor'] = requiredMessage;
    if (dataFormat && dataFormat?.toString() !== "" && meter.deviceType === 'meter' && dataFormat !== "LIVE_DUMP") {
        if (channel?.toString().trim() === "") errors['channel'] = requiredMessage;
        if (folderName?.trim() === "") errors['folderName'] = requiredMessage;
    }
    return errors;
}

export const validateSystem = (system: ISystem): StringMap => {
    const errors: StringMap = {};
    const { smaPlantId, name, TZ, panels, peakPower, country, installDate, dataStart, ownedBy, billedBy, APIKey, provider, id } = system || {}
    if (!name) errors['name'] = requiredMessage;
    if (!TZ) errors['TZ'] = requiredMessage;
    if (!panels) errors['panels'] = requiredMessage;
    if (!peakPower) errors['peakPower'] = requiredMessage;
    if (!country) errors['country'] = requiredMessage;
    if (!installDate) errors['installDate'] = requiredMessage;
    if (!dataStart) errors['dataStart'] = requiredMessage;
    if (!ownedBy) errors['ownedBy'] = requiredMessage;
    if (!billedBy) errors['billedBy'] = requiredMessage;
    const { yield: Yield, yieldPerMonth } = system?.target || {}
    if (!Yield) errors['target.yield'] = requiredMessage;
    if (provider === 'solaredge' && !APIKey) errors['APIKey'] = requiredMessage
    const monthlyData: any = yieldPerMonth || {}
    for (const key in monthlyData) {
        if (Object.prototype.hasOwnProperty.call(monthlyData, key)) {
            if (!monthlyData[key as string]) errors[`target.yieldPerMonth.${key}`] = requiredMessage;;
        }
    }
    if (['huawei', "SMA"].includes(provider)) {
        if (!system?.id) errors['id'] = requiredMessage;
    }
    if (provider === 'SMA' && !smaPlantId) {
        errors['smaPlantId'] = requiredMessage
    }
    return errors;
}

export const validateAddSystem = (addSystemParams: IAddSystemParams): StringMap => {
    const errors: StringMap = {};
    const { provider, pvamSite, seParams } = addSystemParams || {}
    if (!pvamSite?.siteId && !['solaredge', 'orsis'].includes(provider)) errors['pvamSite'] = requiredMessage;
    if (provider === 'solaredge') {
        if (!seParams?.id) errors['systemId'] = requiredMessage;
        if (!seParams?.apiKey) errors['apiKey'] = requiredMessage;
    }

    return errors;
}
