import axios from "axios"
import { ParsedUrlQueryInput } from "querystring"
import { generateQueryString } from "../../../utils/util"
import { host } from "../../config"
import { IGSSReportFilter } from "../../../components/admin/reporting/gssReporting/types"

let GetGSSAPiAbortController: AbortController

export const getGSSReportDataApi = (query: IGSSReportFilter): Promise<any> => {
  if (GetGSSAPiAbortController) GetGSSAPiAbortController.abort()
  GetGSSAPiAbortController = new AbortController()
  return axios.get(`${host}/reports/gss-report/data?${generateQueryString(query as unknown as ParsedUrlQueryInput)}`, { signal: GetGSSAPiAbortController.signal }).then(res => res.data)
}

export const getGSSReportExportApi = (query: IGSSReportFilter): Promise<any> => {
  return axios.get(`${host}/reports/gss-report/excel?${generateQueryString(query as unknown as ParsedUrlQueryInput)}`,  { responseType: 'arraybuffer' }).then(res => res.data)
}
