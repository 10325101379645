import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IUser } from "@solarforschools/sfs-core";
import { IUserFilter } from "../../../components/admin/user/types";
import { createUserAPi, deleteUserApi, getUsersApi, updateUserApi } from "../../client/user";
import { getUserSkillsApi } from "../../client/userSkill";
import { IFaqFilter } from './../../../components/admin/faqs/types.d';
import { SetFaqCategories, SetFaqSites } from "./actionTypes";
import { setUserSkills, setUserPermissions } from "../users/action";
import faqs from './reducer';
import { IFaq } from '@solarforschools/sfs-core/dist/faq/types';
import { createFaqAPi, deleteFaqApi, getFaqsApi, updateFaqApi } from './../../client/faqs';
import { getBlogSitesAndCategoriesApi } from "../../client/blog";

// Action Creators

export const setFaqFilter = (faqFilter: IFaqFilter) => {
  return { type: "SET_FAQ_FILTER", faqFilter };
};

export const setCategories = (categories: any): SetFaqCategories => {
  return { type: "SET_FAQ_CATEGORIES", categories };
};

export const setSites = (sites: any): SetFaqSites => {
  return { type: "SET_FAQ_SITES", sites };
};

export const setFaqsList = (
  faqs: IFaq[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_FAQ_LIST", faqs, totalCount, reset };
};

export const getFaqs =
  (params?: IFaqFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IFaqFilter =
            getState().web.faqs.faqFilter;
          reqBody = { ...filters };
        }
        const { faqs, totalCount } = await getFaqsApi(reqBody);
        dispatch(setFaqsList(faqs, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateFaq =
  (params: IFaq) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { faq, msg } = params._id
          ? await updateFaqApi(params)
          : await createFaqAPi(params);
        const { faqs, totalCount } = getState().web.faqs
        if (!params._id) {
          dispatch(setFaqsList([faq, ...faqs], totalCount + 1, true));
        } else {
          const index = faqs.findIndex(x => x._id?.toString() === faq._id?.toString());
          const updatedFaqs = [...faqs];
          updatedFaqs[index] = faq;
          dispatch(setFaqsList([...updatedFaqs], totalCount, true));
        }
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteFaq =
  (faq: IFaq) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteFaqApi(faq);
        const { faqs, totalCount } = getState().web.faqs
        const data = faqs.filter(x => x._id !== faq._id);
        dispatch(setFaqsList([...data], totalCount - 1, true));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

    export const setFaqCatsAndSites =
    () =>
      async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
        try {
          dispatch(isLoading(true));
          const { sites, categories } = await getBlogSitesAndCategoriesApi()
          dispatch(setCategories(categories))
          dispatch(setSites(sites))
          dispatch(isLoading(false));
        } catch (e: any) {
          dispatch(isLoading(false));
        }
      };
