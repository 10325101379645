import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import ModalDialog from "../common/ModalDialog";
import ReactDOM from "react-dom";
import { ILiveMeterDumpsState } from "../../../store/admin/LiveDumps/reducer";
import { DatePicker, DefaultButton, DetailsList, Dropdown, IComboBoxStyles, IDropdownOption, PrimaryButton, SelectionMode, Stack, Sticky, StickyPositionType, Text, TimePicker, TooltipHost } from "@fluentui/react";
import moment from "moment";
import { dropdownStyles } from "../common/common";
import { useState } from "react";
import { swapIAndETSReadings } from "../../../store/admin/tsReading/action";
import MSNPicker from "../common/MSNPicker";
import _ from "lodash";



export const TSReadingViewDialog = (props: any) => {
  return new Promise((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (result?: any) => {
      resolve(result)
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title={"Reading View"}
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-lg"}
        >
          <LiveMeterDialog {...props} onCancel={() => callback(undefined)} />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const LiveMeterDialog = (props: any) => {
  const { name, id, date, msn, type, readings } = props.data || {}
  return (
    <div className="edit-record">
      <div className="ms-Grid margin-top-lg" dir="ltr">
        <div className="ms-Grid-row">
          <Text className="ms-Grid-col ms-lg2"> <b>Name</b></Text>
          <Text className="ms-Grid-col ms-lg4"> {name}</Text>
          <Text className="ms-Grid-col ms-lg2"> <b>Date</b></Text>
          <Text className="ms-Grid-col ms-lg4"> {moment(date).format("YYYY-MM-DD")}</Text>
        </div>
        <div className="ms-Grid-row">
          <Text className="ms-Grid-col ms-lg2"> <b>MSN</b></Text>
          <Text className="ms-Grid-col ms-lg4"> {msn}</Text>
          <Text className="ms-Grid-col ms-lg2"> <b>Type</b></Text>
          <Text className="ms-Grid-col ms-lg4"> {type}</Text>
        </div>
        <div className="ms-Grid-row"></div>
        <div className="ms-Grid-row"></div>
        <div className="ms-Grid-row margin-top-lg">
          <div className="ms-Grid-col ms-lg2"> <b>Total</b>: {readings.length}</div>
        </div>
      </div>
      <DetailsList
        items={readings}
        columns={[{
          key: 'createdAt',
          name: 'Time',
          fieldName: 'createdAt',
          minWidth: 150,
          maxWidth: 150,
          onRender: (item) => <TooltipHost content={item.createdAt.toString()} closeDelay={500}> {moment(item.createdAt).utc().format("HH:mm")}</TooltipHost>
        },
        {
          key: 'reading',
          name: 'Reading',
          fieldName: 'reading',
          minWidth: 150,
          maxWidth: 250,
          onRender: (item) => <TooltipHost content={item.reading} closeDelay={500}> {item.reading * 1000}</TooltipHost>
        },]}
        selectionMode={SelectionMode.none}
        compact={true} />
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item className="margin-top-lg">
            <DefaultButton onClick={() => props.onCancel()} text="Close" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
}
