import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProjects, fetchRegionsForProject, fetchTemplates, generateReportForCalc, resetState } from '../../../store/admin/generateReport/action'
import { ComboBox, Dropdown, IComboBoxOption } from '@fluentui/react'
import { IDropdownOption } from 'office-ui-fabric-react'
import { GenReportButton, ViewCalculatorTemplate, buildProjectOption, buildRegionOption, buildTemplateOption, defaultRegionsOption, defaultProjectsOption, defaultTemplatesOption, dropdownLayoutStyles, errorsDetails, regionDropDownStyle, schoolDropDownStyle, templateDropDownStyle } from './helper'
import { dropdownStyles } from '../common/common'
import { RootState } from '../../../store'
import { IGenerateReport } from '../../../store/admin/generateReport/reducers'
import { toast } from 'react-toastify'

const CalculatorFilter = () => {
    const dispatch = useDispatch()
    const { calculatorReports } = useSelector<RootState, IGenerateReport>((state: RootState) => state.web.generateReport)


    const [selectedRegion, setSelectedRegion] = useState("")

    const [regionsFilter, setRegionsFilter] = useState<IDropdownOption[] | []>([])
    const [projectsFilter, setProjectsFilter] = useState<IComboBoxOption[] | []>([defaultProjectsOption])

    const [templatesFilter, setTemplatesFilter] = useState<IDropdownOption[] | []>([defaultTemplatesOption])



    const [selectedProjectSlug, setSelectedProjectSlug] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState("")
    const [errors, setErrors] = useState(errorsDetails)

    useEffect(() => {
        dispatch(fetchRegionsForProject(true))
        return () => {
            dispatch(resetState())
        }
    }, [])

    useMemo(() => {
        if (calculatorReports.regions && calculatorReports.regions.length > 0 && Array.isArray(calculatorReports.regions)) {
            let result = buildRegionOption(calculatorReports.regions)
            result.unshift(defaultRegionsOption)
            setRegionsFilter(result)
        }
    }, [calculatorReports.regions])


    useMemo(() => {
        if (calculatorReports.projects && calculatorReports.projects.length > 0 && Array.isArray(calculatorReports.projects)) {
            let result = buildProjectOption(calculatorReports.projects)
            result.unshift(defaultProjectsOption)
            setProjectsFilter(result)
        }

    }, [calculatorReports.projects])


    useMemo(() => {
        if (calculatorReports.templates && calculatorReports.templates.length > 0 && Array.isArray(calculatorReports.templates)) {
            let result = buildTemplateOption(calculatorReports.templates)
            result.unshift(defaultTemplatesOption)
            setTemplatesFilter(result)
        }

    }, [calculatorReports.templates])



    const handleRegionChange = (key: string, value: any) => {
        if (value === "SELECT" || value === undefined || value === "") {
            return
        }
        setSelectedRegion(value)
        setErrors({ ...errors, isRegionError: false, regionErrorMsg: "" })

        dispatch(fetchProjects(value, true))
        dispatch(fetchTemplates(value, true))
    }

    const handleProjectChange = (key: string | undefined, value: any) => {
        if (value === "SELECT" || value === undefined || value === "") {
            return
        }
        setSelectedProjectSlug(value)
        setErrors({ ...errors, isSchoolError: false, schoolErrMsg: "" })
    }


    const handleTemplateChange = (key: string | undefined, value: any) => {
        if (value === "SELECT" || value === selectedTemplate) {
            return
        }
        setSelectedTemplate(value)
        setErrors({ ...errors, isTemplateError: false, templateErrMsg: "" })

    }


    const validateInput = () => {

        let valid = true
        if (selectedRegion === "") {
            setErrors({ ...errors, isRegionError: true, regionErrorMsg: "Please select a region" })
            valid = false
        }
        if (selectedRegion !== "" && (selectedProjectSlug === undefined || selectedProjectSlug === "" || selectedProjectSlug === "SELECT")) {
            setErrors({ ...errors, isSchoolError: true, schoolErrMsg: "Please select a project" })
            valid = false
        }

        if (selectedRegion && selectedProjectSlug && (!selectedTemplate || selectedTemplate === "SELECT")) {
            setErrors({ ...errors, isTemplateError: true, templateErrMsg: "Please select a template" })
            valid = false
        }

        return valid
    }

    const handleGeneratePdf = async () => {
        const isValid = validateInput()
        if (!isValid) return
        const findATemplate = calculatorReports?.templates?.find((elm, index) => {
            const { name } = elm
            return (selectedTemplate === `${name}-${index + 1}`)
        })
        if (!findATemplate) {
            toast.error("Error in template selection")
            return
        }
        dispatch(generateReportForCalc({ region: selectedRegion, projectSlug: selectedProjectSlug, reportTemplate: findATemplate }))
    }

    const FilterOptions = () => (
        <>
            <div style={dropdownLayoutStyles}>
                <div style={{ ...regionDropDownStyle, width: "12vw" }}>
                    <Dropdown
                        dropdownWidth="auto"
                        label="Choose region"
                        options={regionsFilter}
                        styles={dropdownStyles}
                        onChange={(e, elm) => handleRegionChange("Region", elm?.key?.toString())}
                        selectedKey={selectedRegion || defaultRegionsOption.key}
                        defaultSelectedKey={selectedRegion || defaultRegionsOption.key}
                        className="inlineflex ms-Grid-col ms-sm"
                        required
                        errorMessage={errors.isRegionError ? errors.regionErrorMsg : undefined}
                    />
                </div>
                <div style={schoolDropDownStyle}>
                    <ComboBox
                        label="Choose a project"
                        placeholder='Project name'
                        selectedKey={selectedProjectSlug}
                        defaultSelectedKey={projectsFilter[0].key}
                        allowFreeform={true}
                        autoComplete="on"
                        options={projectsFilter}
                        dropdownMaxWidth={500}
                        useComboBoxAsMenuWidth
                        required
                        errorMessage={errors.isSchoolError ? errors.schoolErrMsg : ""}
                        onChange={(e, element) =>
                            handleProjectChange("project", element?.key?.toString())
                        }
                    />
                </div>


                <div style={templateDropDownStyle}>
                    <Dropdown
                        label="Choose a template"
                        dropdownWidth="auto"
                        options={templatesFilter}
                        styles={dropdownStyles}
                        onChange={(e, elm) => handleTemplateChange("Template", elm?.key?.toString())}
                        selectedKey={selectedTemplate || defaultTemplatesOption.key}
                        defaultSelectedKey={selectedTemplate || defaultTemplatesOption.key}
                        required
                        errorMessage={errors.isTemplateError ? errors.templateErrMsg : ""}
                    />
                </div>
            </div>
        </>
    )


    return (
        <>
            <FilterOptions />
            <GenReportButton onClickFn={handleGeneratePdf} />
            <ViewCalculatorTemplate />
        </>

    )
}

export default CalculatorFilter