import { ActionButton, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility, Stack, Text } from '@fluentui/react';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Loader from "../../common/Loader";
import BondHolderFilters from "./BondFilters";
import BondList from './BondList';
import { exportBonds, getBondFilter, getBondBondHolders, getBondBondoffers, getBonds } from '../../../../store/admin/funder/bond/action';
import { IBondState } from '../../../../store/admin/funder/bond/reducer';
import { setBondsFilter } from './../../../../store/admin/funder/bond/action';
import { defaultBondFilter, initBond } from './BondHelper';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';
import { OpenAddBondModal } from './AddBondDialog';
import { OpenImportBondModal } from './ImportBondDialog';
import { StringMap } from '../../common/types';
import { useParams } from 'react-router-dom';
import { IBondFilter } from './types';
import Pagination from '../../common/Pagination';
import { PageSize } from '../../common/common';


const BondsScreen = () => {
  const dispatch = useDispatch()
  const { bonds, totalCount, bondFilter }: IBondState = useSelector<RootState, IBondState>((state: RootState) => state.web.bonds)
  const params: StringMap = useParams() || {}

  useEffect(() => {
    let filter: IBondFilter = { ...defaultBondFilter }
    if (Object.keys(params).length && params.cert) filter = { ...defaultBondFilter, certNumber: params.cert }
    dispatch(setBondsFilter({ ...filter }))
    dispatch(getBonds())
    dispatch(getBondBondoffers())
    dispatch(getBondFilter())
    dispatch(getBondBondHolders())
  }, []);

  async function handleAddBond() {
    const bond = initBond
    const result = await OpenAddBondModal({
      bond,
      onCancel: () => null,
      onSave: () => null,
    });
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...bondFilter, page: 1, pageSize: size }
    dispatch(setBondsFilter(filters))
    dispatch(getBonds())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Bonds</h1>
        <BondHolderFilters />
      </header>
      <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }} className="margin-top-md">
        <div style={{ flex: "20%" }}>
        <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={bondFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        <ActionButton iconProps={{ iconName: 'BulkUpload' }} onClick={() => OpenImportBondModal({})}>
          Upload Bonds
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'DrillDown' }} onClick={() => dispatch(exportBonds())} >
          Export Bonds
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={() => handleAddBond()}>
          Add Bond
        </ActionButton>

      </div>
      <BondList actions={['edit', 'delete']} />
    </div>
  );
}
export default BondsScreen;
