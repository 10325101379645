import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { IRTYield } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";
import { IField } from "../../dynamic-form/DynamicForm";
import { requiredMessage } from "../common/common";
import { StringMap } from "../common/types";
import { isSortable } from "../common/utils";

export const getGeneralFields = (options: StringMap): IField[] => {
    return [
        {
            label: "Id",
            key: "id",
            type: "number",
            extras: { required: true },
            placeholder: "system Id",
            className: 'ms-Grid-col ms-lg3'
        }, {
            label: "Name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg6'
        },
        {
            label: "Date",
            key: "date",
            type: "date",
            extras: { required: true },
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "G",
            key: "data.G",
            type: "number",
            placeholder: "Generation",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "I",
            key: "data.I",
            type: "number",
            placeholder: "Import",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "E",
            key: "data.E",
            type: "number",
            placeholder: "Export",
            className: 'ms-Grid-col ms-lg2'
        },
        {
            label: "C",
            key: "data.C",
            type: "number",
            placeholder: "Consumption",
            className: 'ms-Grid-col ms-lg3'
        },
        {
            label: "SC",
            key: "data.SC",
            type: "number",
            placeholder: "Self Consumption",
            className: 'ms-Grid-col ms-lg2'
        },
    ];
}
export const validateRTYield = async (params: IRTYield) => {
    let errors: StringMap = {};
    const { id, date } = params || {}
    if (!id) errors['id'] = requiredMessage;
    if (!date) errors['date'] = requiredMessage;
    return errors;
}

const renderData = (data: any) => {
    return (
        <>
            {data.map((d: any) => <p style={{ width: '50px' }}>{d}</p>)}
        </>
    )
}

export const getRTYieldsColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'id',
            name: 'Id',
            fieldName: 'id',
            minWidth: 150,
            maxWidth: 150,
            ...isSortable
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            name: 'Date',
            key: 'date',
            fieldName: 'date',
            minWidth: 100,
            maxWidth: 100,
            ...isSortable,
            onRender: (item: IRTYield) => <TooltipHost content={moment(item.date).format('YYYY-MM-DD')} closeDelay={500}> {moment(item.date).format('YYYY-MM-DD')}</TooltipHost>
        },
        {
            name: 'Time',
            key: 'time',
            fieldName: 'time',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'G',
            name: 'G',
            fieldName: 'G',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'I',
            name: 'I',
            fieldName: 'I',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'E',
            name: 'E',
            fieldName: 'E',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'C',
            name: 'C',
            fieldName: 'C',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'SC',
            name: 'SC',
            fieldName: 'SC',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'EV',
            name: 'EV',
            fieldName: 'EV',
            minWidth: 100,
            maxWidth: 100,
        }
    ];
}
