import React, { CSSProperties, useEffect } from 'react'
import Loader from '../common/Loader'
import Filter from './Filter'
import Lists from './Lists'
import { useDispatch, useSelector } from 'react-redux'
import { fetchActivities, fetchActivitiesFilters, resetAll } from '../../../store/admin/activityLogger/actions'
import { IActLogInitial } from '../../../store/admin/activityLogger/reducers'
import { RootState } from '../../../store'

const ActivityLogger = () => {

    const { isLoading, data } = useSelector<RootState, IActLogInitial>((state) => state.web.activityLogger);

    const dispatch = useDispatch()

    const layoutStyles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
    }

    useEffect(() => {
        dispatch(fetchActivitiesFilters())
        dispatch(fetchActivities({}))

        // clean up
        return () => {
            dispatch(resetAll())
        }
    }, [])

    return (
        <div style={layoutStyles} className='activity-logger'>
            <Loader />
            <div>
                <header className="text-center">
                    <h1>Activity Log</h1>
                </header>
            </div>
            {
                !isLoading && (
                    <>
                        <Filter />
                        <Lists />
                    </>
                )
            }

        </div>
    )
}

export default ActivityLogger