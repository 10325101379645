import { useState, useCallback, useMemo } from "react";
import SimpleMdeReact from "react-simplemde-editor";

export const MdeEditor = (props: Props) => {
    const [field] = useState(props.field)
    const onChange = (value: string) => {
        props.onChange(field, value)
    }

    const autofocusNoSpellcheckerOptions = useMemo(() => {
        return {
            autofocus: true,
            ...props.options
        }
    }, []);

    return (
        <SimpleMdeReact
            options={autofocusNoSpellcheckerOptions}
            value={props.value}
            onChange={onChange}
        />
    );
};

interface Props {
    field: string;
    value?: string;
    onChange: (key: string, value: string) => void;
    options?: any;
}
