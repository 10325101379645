import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../store';
import { DetailListStickyHeader } from '../common/DetailListStickyHeader';
import { ConfirmDialog } from '../common/ConfirmDialog';
import DetailsListWapper from '../../common/DetailsListWapper';
import { getFaqColumns } from './FaqHelper';
import { IFaqState } from '../../../store/admin/faqs/reducer';
import { deleteFaq, getFaqs, setFaqFilter } from '../../../store/admin/faqs/action';
import { IFaq } from '@solarforschools/sfs-core/dist/faq/types';
import OpenFaqModal from './AddEditFaqDialog';
import Pagination from '../common/Pagination';

const FaqsList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { faqs, totalCount, faqFilter }: IFaqState = useSelector<RootState, IFaqState>((state: RootState) => state.web.faqs)

  const handleOnDelete = async (data: IFaq) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete User",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete`,
      },
    });
    if (confirm) dispatch(deleteFaq(data));

  };

  const handleOnEdit = async (data: IFaq) => {
    await OpenFaqModal({ faq: data })
  };

  const headerRow = getFaqColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      {
        icon: "Trash",
        onClick: handleOnDelete
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...faqFilter, sortField: field, sortOrder: order };
    dispatch(setFaqFilter(params));
    dispatch(getFaqs(params))
  }
  const setFormElement = (p:any) => {
    dispatch(setFaqFilter({ ...faqFilter, ...p }))
    dispatch(getFaqs(undefined, false))
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={faqs}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={faqs.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => setFormElement({ page })}
            currentPage={faqFilter?.page!}
            pageSize={faqFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default FaqsList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

