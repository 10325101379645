import { Dropdown } from "@fluentui/react";
import { orderBy } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchOtherConfigs, setOtherConfigs } from "../../../store/admin/otherConfig/action";
import { dropdownStyles } from "../common/common";
import { sortedConfigsDropDown } from "./helper";

const OtherConfigFilters = () => {
  const dispatch = useDispatch();

  const handleChange = async (key: string, value: any) => {
    fetchOtherConfigsFn(value);
  };

  const fetchOtherConfigsFn = useCallback((value: string) => {
    dispatch(fetchOtherConfigs(value));
  }, []);

  useEffect(()=>{
    return ()=>{
      dispatch(setOtherConfigs([]))
    }
  },[])

  return (
    <div className="center-dropDown">
      <Dropdown
        dropdownWidth="auto"
        label="Choose Config Type"
        onChange={(e, element) =>
          handleChange("config", element?.key?.toString())
        }
        options={sortedConfigsDropDown}
        styles={dropdownStyles}
        defaultSelectedKey={sortedConfigsDropDown[0].key}
        className="inlineflex ms-Grid-col ms-lg3"
      />
    </div>
  );
};

export default OtherConfigFilters;
