import _ from 'lodash';
import { Action } from "./actionTypes";
import { IBillingReadingDocument, IBillingReadingFilter } from '../../../components/admin/billing/types';
import { DropdownParams } from "../../../components/admin/common/types";

// States' definition
export interface IBilling {
  isLoading: boolean;
  billingReadings: IBillingReadingDocument[];
  billingReadingFilter: IBillingReadingFilter;
  siteFilter: DropdownParams[];
  periodFilter: DropdownParams[];
  contractFilter: DropdownParams[];
  totalCount: number
}
export interface State {
  billing: IBilling;
}

const billing = (
  state: IBilling = {
    isLoading: false,
    billingReadings: [],
    billingReadingFilter: {
      period: 'all',
      site: 'all',
      type: ['G', "E", 'I'],
      readingType: 'all',
      contract: 'all',
      page: 1,
      funder: "CBS",
      pageSize: 50,
      sortField: 'date',
      sortOrder: 'desc'
    },
    siteFilter: [],
    periodFilter: [],
    contractFilter: [],
    totalCount: 0
  },
  action: Action
): IBilling => {
  switch (action.type) {
    case 'SET_PERIODS_FILTER':
      return { ...state, periodFilter: action.periods }
    case 'SET_CONTRACTS_FILTER':
      return { ...state, contractFilter: action.contracts }
    case 'SET_SITE_FILTER':
      return { ...state, siteFilter: action.sites }
    case 'BILLING/DELETE_READING': {
      const stateObj = { ...state }
      const billingReadings: IBillingReadingDocument[] = stateObj.billingReadings.filter(s => s._id?.toString() !== action.readingId.toString())
      const billingReadingFilter = { ...stateObj.billingReadingFilter }
      const { page, pageSize } = billingReadingFilter
      return { ...state, billingReadings, totalCount: stateObj.totalCount - 1, billingReadingFilter: { ...billingReadingFilter, page: Math.ceil(billingReadings.length / page! * pageSize!) } }
    }
    case 'BILLING/UPDATE_READING': {
      const billingReadings = [...state.billingReadings]
      const index = billingReadings.findIndex(s => s._id?.toString() === action.reading?._id?.toString())
      if (index > -1) {
        billingReadings[index] = action.reading
      } else {
        billingReadings.unshift(action.reading)
      }
      return { ...state, billingReadings, totalCount: index > -1 ? state.totalCount : state.totalCount + 1 }
    }
    case 'SET_BILLING_READING_LIST':
      const billingReadings = action.reset ? action.billingReadings : [...state.billingReadings, ...action.billingReadings]
      return { ...state, billingReadings }
    case 'SET_BILLING_READING_FILTER':
      return { ...state, billingReadingFilter: action.data }
    case 'SET_READINGS_TOTALS_COUNT': {
      return { ...state, totalCount: action.totalCount }
    }
    default:
      return state;
  }
};

export default billing;
