import { IAdminDashboard } from '@solarforschools/sfs-core'
import Card from '../layouts'
import CardContent from '../layouts/CardContent'
import CardHeader from '../layouts/CardHeader'

const DevSchools = (props: Partial<IAdminDashboard>) => {

    if (props.devSchools === undefined) return <></>
    
    const chartLabels: string[] = Object.keys(props?.devSchools?.devSchoolsStages).map(label => label.charAt(0).toUpperCase() + label.substr(1));
    const chartData: number[] = Object.values(props?.devSchools?.devSchoolsStages)
    const devSchoolsCount = props?.devSchools?.count
    return (
        <div className="dashboard-layout--devSchools">
            <Card>
                <CardHeader title="Schools" iconName="Schools" />
                <CardContent variant="LineChart" labels={chartLabels} data={chartData} value={devSchoolsCount} />
            </Card>
        </div>
    )
}

export default DevSchools