import { AnyAction } from "redux";
import { RootState } from "./../../index";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { TOtherConfigs } from "@solarforschools/sfs-core";
import { isLoading } from "../action";
import axios, { AxiosResponse } from "axios";
import { host } from "../../config";
import { toast } from "react-toastify";

export const setOtherConfigs = (otherConfig: TOtherConfigs[]) => {
  return { type: "SET_OTHER_CONFIG", otherConfig };
};

export const updateOtherConfigs = (otherConfig: TOtherConfigs) => {
  return { type: "UPDATE_OTHER_CONFIG", otherConfig };
};


export const updateOtherConfigData = (
  otherConfig: TOtherConfigs | undefined
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => {
    try {
      dispatch(isLoading(false));
      const res: AxiosResponse<any> | undefined =
        otherConfig?._id &&
        (await axios.put(`${host}/configs/others/update`, otherConfig));
      res?.data.msg && toast.success("Configuration updated successfully");
      if (res?.data.hasOwnProperty("error")) {
        dispatch(isLoading(false));
        return;
      }
      dispatch(updateOtherConfigs(res?.data.msg));
      dispatch(isLoading(false));
    } catch (err) {
      console.error(err);
      dispatch(isLoading(false));
    }
  };
};

export const fetchOtherConfigs = (
  otherConfigId: any
): ThunkAction<Promise<void>, RootState, {}, AnyAction> => {
  return async function fetchOtherConfigThunk(dispatch) {
    try {
      dispatch(isLoading(true));
      const res: AxiosResponse<any> | undefined = await axios.get(
        `${host}/configs/others/${otherConfigId}`
      );
      dispatch(setOtherConfigs(res?.data));
      dispatch(isLoading(false));
    } catch (err) {
      console.log("Error is ", err);
      dispatch(isLoading(false));
    }
  };
};
