import { IAdminDashboard } from "@solarforschools/sfs-core";
import { Action } from "./actionTypes";
export interface IAdminDashboardDataState {
  isLoading: boolean;
  adminDashboardData: IAdminDashboard[];
  error: string | null;
}
const initialState = {
  isLoading: true,
  adminDashboardData: [],
  error: null,
};

const adminDashboard = (
  state: IAdminDashboardDataState = initialState,
  action: Action
) => {
  switch (action.type) {
    case "FETCH_DATA_REQUEST":
      return { ...state, isLoading: true };
    case "FETCH_DATA_SUCCESS":
      return { ...state, isLoading: false, adminDashboardData: action.payload };
    case "FETCH_DATA_FAILURE":
      return { ...state, isLoading: false, error: action.payload };
    case "UPDATE_LIVE_METER_CONDITIONS":
      // const payloadData = action.payload;
      const payloadConditions = action.payload.conditions;
      const payloadFalsyMeters = action.payload.falsyMeters;

      const oldAdminDashboardData = state.adminDashboardData[0];
      oldAdminDashboardData.liveMeters.conditions = payloadConditions;
      oldAdminDashboardData.liveMeters.falsyMeters = payloadFalsyMeters;
      return {
        ...state,
        isLoading: false,
        adminDashboardData: [oldAdminDashboardData],
      };
    default:
      return state;
  }
};
export default adminDashboard;
