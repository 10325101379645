import { HtmlHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ProgressIndicator, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import store from '../../../store';
import ModalDialog from './ModalDialog';
import { toast } from 'react-toastify';
import DynamicForm, { IField } from '../../dynamic-form/DynamicForm';
import _ from 'lodash';
import { promises } from 'dns';


export const OpenEmailPreviewModal = (props: { params: any }) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <EmailPreviewModal {...props} onSave={(data) => callback(data)} onCancel={() => callback(undefined)} />
            </Provider>,
            mountTarget
        )
    })
}


export const EmailPreviewModal = ({
    params,
    onCancel,
}: Props) => {

    const [email] = useState<any>(params.html);
    const [data, setData] = useState<any[]>([...params.data]);
    const [counter, setCounter] = useState<number>(0); // total counter 
    const [successCount, setSuccessCounter] = useState<number>(0)
    const [breakLoop, setBreak] = useState<boolean>(false)
    const [isClicked, setIsClicked] = useState<boolean>(false)
    const [extraFilter, SetExtraFilter] = useState<any>({})
    const parallelRequestCount = data.length > 10 ? 10 : data.length

    const breakLoopRef = useRef<any>();
    const form = params.externalForm ? params.externalForm({ extraFilter }) : [];

    useEffect(() => {
        breakLoopRef.current = breakLoop;
    }, [breakLoop]);

    const handleOnSend = async () => {
        setIsClicked(true)
        const _data = _.chunk(data || [], parallelRequestCount) // send 10 emails in parallel
        for (const d of _data || []) {
            try {
                if (breakLoopRef.current) {
                    onCancel()
                    break
                }
                let promises = [];
                for (const obj of d) {
                    promises.push(params.sendAction({ ...obj }))
                }
                try {
                    await Promise.all(promises);

                } catch (error) {
                    console.log(error, JSON.stringify(d))
                    toast.error(`Something went wrong `)
                } finally {
                    setCounter(prevState => (prevState + d.length))
                    if (counter === data.length) toast.success("Processed all emails!")
                }

            } catch (error) {
                toast.error(`Something went wrong `)
            }
        }
        // onSave({ success: true });
    };
    const handleCancel = () => {
        if (counter === 0 || counter > data.length || counter === data.length) {
            onCancel()
        } else {
            setBreak(true)
            toast.success("Cancelling ...")
        }
    }

    const handleOnChangeInput = async (key: _.PropertyPath, value: any) => {
        if (isClicked) {
            toast.warning(`You have already click on ${params.btnTitle || 'Send'}`)
            return
        }
        const filter = _.set({ ...extraFilter }, key, value)
        SetExtraFilter(filter)
        const data = await params.reloadData(filter)
        setData(data)
    }
    return (
        <ModalDialog
            isModalOpen={true}
            title={`${params?.title}` || "Email Preview"}
            onDismiss={handleCancel}
        >
            <div className="edit-record">
                {form.length > 0 && (
                    <div className="ms-Grid" dir="ltr">
                        <div className='ms-Grid-row'>
                            <div className="ms-Grid-col ms-lg3"></div>
                            <div className="ms-Grid-col ms-lg6">
                                <div className='ms-Grid-row'>
                                    <div className='margin-bottom-md'>
                                        <DynamicForm
                                            fields={form}
                                            data={extraFilter}
                                            onChange={handleOnChangeInput}
                                            errors={{}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='ms-Grid-row'>
                    <div dangerouslySetInnerHTML={{ __html: email }}></div>
                </div>
                {data?.length && data.length !== counter && isClicked && (<ProgressIndicator label={`Processing ${counter + parallelRequestCount}/${data?.length}`} barHeight={5} />)}
                {data?.length > 0 && counter > 0 && (<ProgressIndicator description={`Processed ${counter}/${data?.length}`} percentComplete={counter / data?.length} />)}
                <Sticky stickyPosition={StickyPositionType.Footer}>
                    <Stack horizontal horizontalAlign="center">
                        <Stack.Item>
                            {!isClicked && data.length > 0 && (<>
                                <PrimaryButton
                                    text={params.btnTitle || 'Send'}
                                    onClick={handleOnSend}
                                    className="btn-primary"
                                />
                            </>
                            )}
                            <DefaultButton onClick={handleCancel} text="Cancel" />
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </div>
        </ModalDialog>
    );
};

interface OwnProps {

    params: {
        btnTitle: string, // action button like send /save default will send
        title: string,  // modal title
        data: any[], // where email need to send
        html: string // email template
        externalForm: (params: any) => IField[]
        reloadData: (params: any) => any[]
        sendAction: (params: any) => void
    };
    onCancel: () => void;
    onSave: (params: any) => void;
}

type Props = OwnProps;
