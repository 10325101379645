import { IFaq } from "@solarforschools/sfs-core/dist/faq/types";
import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../utils/util";
import { host } from "../config";

let GetFaqsAPiAbortController: AbortController

export const getFaqsApi = (query: any): Promise<any> => {
  if (GetFaqsAPiAbortController) GetFaqsAPiAbortController.abort()
  GetFaqsAPiAbortController = new AbortController()
  return axios.get(`${host}/faqs?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetFaqsAPiAbortController.signal }).then(res => res.data)
}
export const createFaqAPi = (data: IFaq): Promise<any> => {
  return axios
    .post(`${host}/faqs`, data)
    .then((res) => res.data);
};

export const updateFaqApi = (data: IFaq): Promise<any> => {
  return axios
    .put(`${host}/faqs/${data._id}`, data)
    .then((res) => res.data);
};

export const deleteFaqApi = (data: IFaq): Promise<any> => {
  return axios
    .delete(`${host}/faqs/${data._id}`)
    .then((res) => res.data);
};

export const validateUserApi = (user: IFaq): Promise<any> => {
  return axios
    .put(`${host}/faqs/validate/faq`, user)
    .then((res) => res.data);
};
