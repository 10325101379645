
import { generateQueryString } from '../../../utils/util';
import axios from 'axios';
import { host } from '../../config';
import { IBondFilter } from '../../../components/admin/funder/bond/types';
import { ParsedUrlQueryInput } from 'querystring';
import { IBond } from '@solarforschools/sfs-core/dist/funder/bond/types';

let GetBondAPiAbortController: AbortController

export const getBondsApi = (params: IBondFilter): Promise<{ bonds: IBond[]; totalCount: number }> => {
  if (GetBondAPiAbortController) GetBondAPiAbortController.abort()
  GetBondAPiAbortController = new AbortController()
  const queryStr = generateQueryString({ ...params });
  return axios.get(`${host}/funder/bonds?${queryStr}`, { signal: GetBondAPiAbortController.signal }).then((res) => res.data);
};

export const downloadBondCertificateApi = (bondId: any): Promise<any> => {
  return axios.get(`${host}/funder/bonds/downloadCertificate/${bondId}`, { responseType: 'arraybuffer' }).then(res => res)
}

export const createBondTransactionsApi = (bondId: any): Promise<any> => {
  return axios.post(`${host}/funder/bonds/create-transaction/${bondId}`).then(res => res.data)
}

export const exportBondsApi = (query: IBondFilter): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/funder/bonds/export-bonds?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}

export const deleteBondApi = (bondId: string): Promise<{ bond: IBond, msg: string }> => {
  return axios.delete(`${host}/funder/bonds/${bondId}`).then((res) => res.data);
};


export const createBondAPi = (data: IBond): Promise<any> => {
  return axios
    .post(`${host}/funder/bonds`, data)
    .then((res) => res.data);
};

export const updateBondApi = (data: IBond): Promise<any> => {
  return axios
    .put(`${host}/funder/bonds/${data._id}`, data)
    .then((res) => res.data);
};

export const processBondRepaymentApi = (data: any): Promise<any> => {
  return axios
    .put(`${host}/funder/bonds/repayment/${data.bondId}`, data)
    .then((res) => res.data);
};

export const transferBondApi = (data: any): Promise<any> => {
  return axios
    .put(`${host}/funder/bonds/transfer/${data.bondId}`, data)
    .then((res) => res.data);
};

export const reGenCertificateApi = (certNumber: number): Promise<any> => {
  return axios.get(`${host}/funder/bonds/certificate/${certNumber}`).then(res => res.data)
}

export const getBondEmailPreview = (id: string, query: ParsedUrlQueryInput): Promise<any> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/funder/bonds/email/${id}?${queryStr}`).then(res => res.data)
}

export const sendBondEmailApi = (id: string, query: ParsedUrlQueryInput): Promise<any[]> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.post(`${host}/funder/bonds/email/${id}?${queryStr}`).then(res => res.data)
}

export const importBondsApi = (formData: FormData): Promise<any[]> => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(`${host}/funder/bonds/import-bonds`, formData, config).then(res => res.data)
}


export const getBondFilterApi = (): Promise<{ auditLogs: any[], periods: any[] }> => {
  return axios.get(`${host}/funder/bonds/filter`).then((res) => res.data);
};
