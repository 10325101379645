import { IAdminDashboardDataState } from "../../../../store/admin/adminDashboard/reducers"
import {
    Schools,
    Designs,
    Registrations,
    Projects,
    CarbonSavings,
    ElectricityGenerated,
    FitIncome,
    DevSchools,
    PipeDrive,
    LiveMeters,
    MapSchools,
    Ethex,
    DealsWon,
    Leads,
    OffersSent,
    UkActiveDeals,
    Deals,
    DealOfferAvgDays,
    TeacherPortalActivity,
  } from "../tiles"

  export const generateTilesMetaData = (data:IAdminDashboardDataState) => {

    const tiles:ITile[] = [
        {
          component: MapSchools, props: {
            key: "activeSchools",
            value: data?.adminDashboardData[0]?.activeSchools
          }
        },
        {
          component: Schools, props: {
            key: "activeSchools",
            value: data?.adminDashboardData[0]?.activeSchools
          }
        },
    
        {
          component: Registrations, props: {
            key: "registrations",
            value: data?.adminDashboardData[0]?.registrations
          }
        },
        {
          component: ElectricityGenerated, props: {
            key: "electricityGenerated",
            value: data?.adminDashboardData[0]?.electricityGenerated
          }
        },
        {
          component: FitIncome, props: {
            key: "feedInTarrif",
            value: data?.adminDashboardData[0]?.feedInTarrif
          }
        },
    
        {
          component: Projects, props: {
            key: "activeProjects",
            value: data?.adminDashboardData[0]?.activeProjects
          }
        },
        {
          component: Designs, props: {
            key: "designs",
            value: data?.adminDashboardData[0]?.designs
          }
        },
        {
          component: CarbonSavings, props: {
            key: "carbonSavings",
            value: data?.adminDashboardData[0]?.carbonSavings
          }
        },
    
        {
          component: DevSchools, props: {
            key: "devSchools",
            value: data?.adminDashboardData[0]?.devSchools
          }
        },
        {
          component: PipeDrive, props: {
            key: "pipeDrive",
            value: data?.adminDashboardData[0]?.pipeDrive
          }
        },
        {
          component: LiveMeters, props: {
            key: "liveMeters",
            value: data?.adminDashboardData[0]?.liveMeters
          }
        },
    
        {
          component: Ethex, props: {
            key: "ethex",
            value: data?.adminDashboardData[0]?.ethex
          }
        },
        {
          component: DealsWon, props: {
            key: "dealsWon",
            value: data?.adminDashboardData[0]?.pipeDrive?.quartelyDealByInLegals
          }
        },
        {
          component: OffersSent, props: {
            key: "dealByOfferSent",
            value: data?.adminDashboardData[0]?.pipeDrive?.quartelyDealByOffersent
          }
        },
    
        {
          component: Leads, props: {
            key: "leadFromSorces",
            value: data?.adminDashboardData[0]?.pipeDrive?.quartelyLeadBySource
          }
        },
        {
          component: Deals, props: {
            key: "DealFromSorces",
            value: data?.adminDashboardData[0]?.pipeDrive?.quartelyDealBySource
          }
        },
        {
          component: UkActiveDeals, props: {
            key: "data",
            value: data?.adminDashboardData[0]?.pipeDrive?.ukActiveDailyDealCount
          }
        },
        {
          component: DealOfferAvgDays, props: {
            key: "data",
            value: data?.adminDashboardData[0]?.pipeDrive?.quartelyDealOffersentToInLegalsAvgDays
          }
        },
        {
          component: TeacherPortalActivity, props: {
            key: "teacherPortalActivity",
            value: data?.adminDashboardData[0]?.teacherPortalActivity
          }
        },
    ]
    return tiles;
  }
  
  


export interface ITile {
    component: React.FC<any>;
    props: {
        key: string;
        value: any;
    };
  }

