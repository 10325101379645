import React from 'react'
import { useContext, useEffect, useState } from "react";
import { CardContext, CardContextType } from "../../layouts";

export const FrontContent = () => {
    const { pipeDrive } = useContext(CardContext) as CardContextType;

    const [overallPipeDriveLeads, setOverAllPipeDriveLeads] = useState(pipeDrive?.leads.count)

    const getStatus = () => {
        if (overallPipeDriveLeads !== undefined) {
            setOverAllPipeDriveLeads(pipeDrive?.leads.count)
        }
    }

    useEffect(() => {
        getStatus()
    }, [pipeDrive])
    return (
        <>
            <div className="card-content" style={{ display: "flex", flexDirection: "column" }}>
                <div className="card-content--count" style={{ color: "#243f61", fontSize: "4rem" }} >
                    {overallPipeDriveLeads !== undefined && new Intl.NumberFormat('en-GB').format(overallPipeDriveLeads)}
                </div>
                <div>
                    <p style={{ color: "#243f61", fontSize: " 0.85rem" }}>Overall Leads </p>
                </div>
            </div>
        </>
    )
}


export const BackContent = () => {
    const { pipeDrive } = useContext(CardContext) as CardContextType;

    const [overallPipeDriveDeals, setOverAllPipeDriveDeals] = useState(pipeDrive?.deals.count)

    const getStatus = () => {
        if (overallPipeDriveDeals !== undefined) {
            setOverAllPipeDriveDeals(pipeDrive?.deals.count)
        }
    }

    useEffect(() => {
        getStatus()
    }, [pipeDrive])
    return (
        <>
            <div className="card-content" style={{ display: "flex", flexDirection: "column" }}>
                <div className="card-content--count" style={{ color: "#243f61", fontSize: "4rem" }} >
                    {overallPipeDriveDeals !== undefined && new Intl.NumberFormat('en-GB').format(overallPipeDriveDeals)}
                </div>
                <div>
                    <p style={{ color: "#243f61", fontSize: "0.85rem" }}>Overall Deals </p>
                </div>
            </div>

        </>
    )
}

