import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const FileUpload = ({
  name = "Upload",
  onChangeCallback,
  url,
  width,
  height,
  margin,
  fileType,
  isDirectUpload = false,
}: fileUploadProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [fileName, setfileName] = useState<string | null>(null);
  const [buttonName, setButtonName] = useState<string>(name);
  const [isRemove, setisRemove] = useState(false);
  const handleUpload = () => {
    if (!isRemove) {
      setisRemove(true);
      fileUploadRef?.current?.click();
    }
    if (isRemove) {
      setfileName(null);
      setisRemove(false);
      setButtonName(name);
      if (onChangeCallback) onChangeCallback(null);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e?.target?.files;
    if (selectedFile && selectedFile[0]?.name) {
      if (!isDirectUpload) {
        setfileName(selectedFile[0].name);
        setButtonName("Remove");
      } else {
        setisRemove(false);
      }
      if (onChangeCallback) {
        let file = !isRemove ? null : selectedFile[0];
        onChangeCallback(file);
      }
    }
  };

  const [uploadContainerStyle, setUploadContainerStyle] =
    useState<React.CSSProperties>({
      position: "relative",
      margin: margin ? margin : "1rem 0 2rem 0",
      width,
      height,
      background: `url(${url}) no-repeat center center`,
      backgroundSize: "cover",
      borderRadius: "0",
      border: "3px dashed var(--solar-blue)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    });

  const [labelStyle, setLabelStyle] = useState<React.CSSProperties>({
    width: "fit-content",
    height: "fit-content",
    marginBottom: "1rem",
    backgroundColor: "white",
    borderRadius: "0.4rem",
    padding: "0.2rem 0.4rem",
  });

  const [buttonStyle, setButtonStyle] = useState<React.CSSProperties>({
    background: "var(--solar-blue)",
    color: "#fff",
    border: "0",
    padding: "0.5rem 0.7rem",
    borderRadius: "5px",
    outline: "0",
    cursor: "pointer",
  });

  const handleButtonMouseHover = () => {
    setButtonStyle({
      ...buttonStyle,
      transform: "scale(1.04)",
    });
  };

  const handleButtonMouseOut = () => {
    setButtonStyle({
      ...buttonStyle,
      transform: "scale(1)",
    });
  };

  return (
    <div style={uploadContainerStyle}>
      {fileName ? (
        <label style={labelStyle}>
          {`${fileName} `}
          <FontAwesomeIcon icon={faCheckCircle} color="#243f61" />
        </label>
      ) : null}
      <button
        style={buttonStyle}
        onClick={handleUpload}
        onMouseOut={handleButtonMouseOut}
        onMouseEnter={handleButtonMouseHover}
      >
        {buttonName}
      </button>
      <input
        id="file-input"
        onChange={handleFileChange}
        type="file"
        ref={fileUploadRef}
        accept={fileType}
        style={{ display: "none" }}
      />
    </div>
  );
};

export interface fileUploadProps {
  name?: string;
  onChangeCallback?: Function;
  url?: string;
  width?: string;
  height?: string;
  margin?: string;
  fileType?: string;
  isDirectUpload?: boolean;
}

export default FileUpload;
