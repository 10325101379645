import { DatePicker } from "@fluentui/react";
import { IActivity } from "@solarforschools/sfs-core/dist/activityLogger/types";
import { Action } from "./actionTypes";

export interface IFilterOptions {
  type0: string;
  type1: string;
  type2: string;
  user: string;
  date: string;
}

export interface IActLogData {
  totalCount: number;
  page: number;
  limit: number;
  skip: number;
  data: IActivity[];
}

interface IOption {
  key: string;
  text: string;
}
export interface IOptions {
  type0: IOption[];
  type1: IOption[];
  type2: IOption[];
  users: IOption[];
}

export interface IActLogInitial {
  filter: IFilterOptions;
  options: IOptions;
  pageNumber: number;
  totalCount: number;
  hasLoadMore: boolean;
  data: IActivity[];
  isLoading: boolean;
  limit: number;
}

const initialState: IActLogInitial = {
  filter: {
    type0: "",
    type1: "",
    type2: "",
    user: "",
    date: "",
  },
  options: {
    type0: [],
    type1: [],
    type2: [],
    users: [],
  },
  pageNumber: 1,
  totalCount: 0,
  hasLoadMore: true,
  data: [],
  isLoading: true,
  limit: 20,
};

const activityLogger = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_FILTER_OPTIONS":
      const filterOptions = action.payload;
      const { type0 = [], type1 = [], type2 = [], users } = filterOptions;

      return {
        ...state,
        options: {
          ...state.options,
          type0,
          type1,
          type2,
          users: users.filter((e) => Object.keys(e).length),
        },
      };

    case "SET_FILTER":
      const filter = action.payload;
      const {
        type0: Type0 = "",
        type1: Type1 = "",
        type2: Type2 = "",
        date,
        user,
      } = filter;

      return {
        ...state,
        filter: {
          ...state.filter,
          type0: Type0,
          type1: Type1,
          type2: Type2,
          user,
          date,
        },
      };

    case "SET_ACTIVITIES":
      const {
        data: payloadData,
        page: currentPage,
        totalCount,
        limit,
      } = action.payload;

      let isLoadMore = true,
        page = currentPage;

      let isLimitReached = currentPage * limit === totalCount;

      if (payloadData.length === 0 || isLimitReached) isLoadMore = false;

      return {
        ...state,
        hasLoadMore: isLoadMore,
        pageNumber: page,
        totalCount,
        isLoading: false,
        limit,
        data: [...payloadData],
      };

    case "RESET_ALL":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default activityLogger;
