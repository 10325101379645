import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import MeterFilters from './fitMeterFilters';
import MeterList from './fitReportReading';
import { ActionButton, Stack, Text } from '@fluentui/react';
import { RootState } from '../../../store';
import { IFitReportingState } from '../../../store/admin/fitReporting/reducer';
import { exportFitReadings, getFitReportingData, getFitSystemList } from '../../../store/admin/fitReporting/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const FitReportScreen = () => {
  const dispatch = useDispatch()
  const { fitReadings, totalCount }: IFitReportingState = useSelector<RootState, IFitReportingState>((state: RootState) => state.web.fitReport)

  useEffect(() => {
    dispatch(getFitSystemList())
    dispatch(getFitReportingData())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Fit Meter Report</h1>
        <MeterFilters />
      </header>
      <Stack tokens={{ padding: '0 15px' }} horizontal verticalAlign='center'>
        <div style={{ display: 'flex', flex: 1 }} />
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => dispatch(exportFitReadings())}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
      </Stack>
      <MeterList />
    </div>
  );
}
export default FitReportScreen;

