import { useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { DatePicker, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Provider, useDispatch } from 'react-redux';
import ModalDialog from '../../common/ModalDialog';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import store from '../../../../store';
import { Checkbox } from 'office-ui-fabric-react';
import { isLoading } from '../../../../store/admin/action';
import { ITransaction } from '@solarforschools/sfs-core/dist/funder/transaction/types';
import { processPaymentByTidApi } from '../../../../store/client/funder/transaction';
import { getBondTransactions } from '../../../../store/admin/funder/transaction/action';
import moment from 'moment';
import { dropdownStyles } from '../../common/common';
import { downloadFileFromStream } from '../../common/utils';



export const OpenPaymentProcessByTransactionModal = (props: { transaction: ITransaction }) => {
    return new Promise((resolve, reject) => {
        const mountTarget = document.createElement('div')
        document.body.appendChild(mountTarget)
        const callback = (result?: any) => {
            resolve(result)
            ReactDOM.unmountComponentAtNode(mountTarget)
            mountTarget.remove()
        }
        ReactDOM.render(
            <Provider store={store}>
                <ModalDialog
                    isModalOpen={true}
                    title={"Confirm Payment Process"}
                    onDismiss={() => callback(undefined)}
                    containerClassName={"modal-size-xs"}
                >
                    <PaymentProcessModal {...props} onSave={(data) => callback(data)} onCancel={() => callback(undefined)} />
                </ModalDialog>
            </Provider>,
            mountTarget
        )
    })
}


export const PaymentProcessModal = ({
    transaction,
    onCancel,
    onSave,
}: Props) => {
    const dispatch = useDispatch()

    const [payload, setPayload] = useState<any>({
        statusClosed: false,
        lastInterestDate: moment().format("YYYY-MM-DD"),
        isCustomDate: false
    })
    const handleOnChange = async (key: string, value?: boolean | string) => {
        setPayload({ ...payload, [key]: value })
    }
    const handleProcess = async () => {
        try {
            dispatch(isLoading(true))
            const data = await processPaymentByTidApi(transaction._id, payload);
            downloadFileFromStream({ data, filename: "Bond-Transactions", type: 'csv' })
            // dispatch(getBondTransactions())
        } catch (error: any) {
            console.log(error)
            toast.error(error?.response?.data || 'Something went wrong')

        } finally {
            dispatch(isLoading(false))
        }
    };
    const handleCancel = () => {
        onCancel()
    }

    const { statusClosed, lastInterestDate, isCustomDate } = payload
    return (
        <div className="edit-record">
            <div className='ms-Grid-row'>
                <div className='margin-top-sm'>
                    <p className='ms-Grid-col ms-lg12' >Mark to close transactions in final run.</p>
                </div>
            </div>
            <div className='ms-Grid-row'>
                <Checkbox
                    label="Close"
                    checked={statusClosed}
                    onChange={(e, value) => handleOnChange("statusClosed", value)}
                    className="ms-Grid-col ms-lg12 margin-top-md"
                />
            </div>
            <br></br>
            <div className='ms-Grid-row margin-top-md'>
                <p>If you want to overwrite interest end date please mark this checkbox and select date below</p>
                <Checkbox
                    label="Is Custom Date"
                    checked={isCustomDate}
                    onChange={(e, value) => handleOnChange("isCustomDate", value)}
                    className="ms-Grid-col ms-lg12 margin-top-md"
                />
            </div>
            {isCustomDate && (
                <div className='ms-Grid-row margin-top-md' >
                    <DatePicker
                        label="Select Last Interest Date"
                        placeholder="Select Last Interest Date."
                        ariaLabel="Select Last Interest Date"
                        value={new Date(lastInterestDate!)}
                        onSelectDate={(date) => handleOnChange('lastInterestDate', moment(date).format('YYYY-MM-DD'))}
                        styles={dropdownStyles}
                    />
                </div>
            )}
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack horizontal horizontalAlign="center">
                    <Stack.Item>
                        <PrimaryButton
                            text={'Process'}
                            onClick={handleProcess}
                            className="btn-primary"
                        />
                        <DefaultButton onClick={handleCancel} text="Cancel" />
                    </Stack.Item>
                </Stack>
            </Sticky>
        </div>
    );
};

interface OwnProps {
    transaction: ITransaction;
    onCancel: () => void;
    onSave: (params: any) => void;
}

type Props = OwnProps;
