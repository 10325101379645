import { Action } from "./actionTypes";
import { IUser } from "@solarforschools/sfs-core";
import moment from "moment";
import { IUserFilter } from "../../../components/admin/user/types";

// States' definition
export interface IUserState {
  isLoading: boolean;
  users: IUser[];
  userFilter: IUserFilter;
  totalCount: number;
  skills: any[];
  permissions: any[];
}
export interface State {
  user: IUserState;
}

const user = (
  state: IUserState = {
    isLoading: false,
    users: [],
    userFilter: {
      page: 1,
      pageSize: 50,
      sortField: "date",
      sortOrder: "desc",
    },
    totalCount: 0,
    skills: [],
    permissions: []
  },
  action: Action
): IUserState => {
  switch (action.type) {
    case "SET_USER_FILTER":
      return { ...state, userFilter: action.userFilter };
    case "SET_USER_SKILLS":
      return { ...state, skills: action.skills };
    case "SET_USER_PERMISSIONS":
      return { ...state, permissions: action.permissions };
    case "SET_USER_LIST":
      const users = action.reset
        ? action.users
        : [...state.users, ...action.users];
      return { ...state, users, totalCount: action.totalCount };
    case "DELETE_USER": {
      const stateObj = { ...state };
      const users: IUser[] = stateObj.users.filter(
        (s) => s._id !== action.user._id
      );
      const userFilter = { ...stateObj.userFilter };
      const { page, pageSize } = userFilter;
      return {
        ...state,
        users,
        totalCount: stateObj.totalCount - 1,
        userFilter: {
          ...userFilter,
          page: Math.ceil((users.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_USER": {
      const users = [...state.users];
      const index = users.findIndex(
        (s) => s._id === action.user?._id
      );
      if (index > -1) {
        users[index] = action.user;
      } else {
        users.unshift(action.user);
      }
      return {
        ...state,
        users,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default user;
