import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchOtherConfigs } from "../../../store/admin/otherConfig/action";
import Loader from "../common/Loader";
import { sortedConfigsDropDown } from "./helper";
import OtherConfigFilters from "./OtherConfigFilters";
import OtherConfigLists from "./OtherConfigLists";

const OtherConfig = () => {
  const dispatch = useDispatch();
  const initialKey = sortedConfigsDropDown[0].key;

  useEffect(() => {
    dispatch(fetchOtherConfigs(initialKey));
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
        scrollBehavior: "smooth",
      }}
    >
      <Loader />
      <div>
        <header className="table-header">
          <h1>Other Configuration Settings </h1>
        </header>
        <OtherConfigFilters />
      </div>

      <OtherConfigLists />
    </div>
  );
};

export default OtherConfig;
